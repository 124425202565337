import { OnInit } from '@angular/core';
import { environment } from "@myenv/environment";
var LayoutComponent = /** @class */ (function () {
    function LayoutComponent() {
        this.showFooter = environment.showFooter;
    }
    LayoutComponent.prototype.ngOnInit = function () {
    };
    return LayoutComponent;
}());
export { LayoutComponent };
