
import { throwError as observableThrowError, Observable } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { Router } from '@angular/router';

import { environment } from '@myenv/environment';
@Injectable()
export class UsersService {

  d = new Date();
  offsetInMinutes = this.d.getTimezoneOffset() * -1;
  timeZone = `?dateFormat=${environment.isoDateTimeFormat}&offsetInMinutes=${this.offsetInMinutes}`;

  public apiUrl = environment.apiUrl;
  reportModule: any = [];
  private httpClient: HttpClient;
  constructor(private http: HttpClient, private router: Router, private handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
    let permissionmodules = localStorage.getItem('permissionModules');
    this.reportModule = permissionmodules.includes('REPORT');
  }

  getUsers(roleId?): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    let tempUrl = this.apiUrl + 'users';
    if (roleId) tempUrl += `?roleId=${roleId}`;
    return this.http.get(tempUrl, { headers }).pipe(catchError(this.handleError));
  }

  userSearch(key, value, roleId): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    let tempUrl = this.apiUrl + 'users/user/search';
    if (key && value) tempUrl += `?key=${key}&value=${value}`;
    if (roleId) tempUrl += `&roleId=${roleId}`;
    return this.http.get(tempUrl, { headers }).pipe(catchError(this.handleError));
  }

  createUserReports(user): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    return this.http.get(this.apiUrl + 'reports/user/' + user, { headers }).pipe(
      catchError(this.handleError));
  }

  getReports(page): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    return this.http.get(this.apiUrl + 'reports/all?page=' + page, { headers }).pipe(
      catchError(this.handleError));
  }

  updateUserStatus(userId, enable): Observable<any> {
    return this.http.patch(`${this.apiUrl}users/${userId}/active/${enable}`, {}).pipe(
      catchError(this.handleError));
  }

  getUserGroups(user): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    return this.http.get(this.apiUrl + 'groups/assigned/user/' + user, { headers }).pipe(
      catchError(this.handleError));
  }

  createUser(data): Observable<any> {
    return this.http.post(this.apiUrl + 'users', data).pipe(
      catchError(this.handleError));
  }

  updateUser(data): Observable<any> {
    return this.http.put(this.apiUrl + 'users/' + data.userId, data).pipe(
      catchError(this.handleError));
  }

  deleteUser(userId): Observable<any> {
    return this.http.delete(this.apiUrl + 'users/' + userId).pipe(
      catchError(this.handleError));
  }

  assignReports(userId, payload): Observable<any> {
    return this.http.put(this.apiUrl + `reports/user/${userId}`, payload)
    .pipe(catchError(this.handleError));
  }

  unassignReports(userId, reportId): Observable<any> {
    return this.http.delete(this.apiUrl + `reports/user/${userId}/report/${reportId}`)
    .pipe(catchError(this.handleError));
  }

  assignGroups(userId, payload): Observable<any> {
    return this.http.post(this.apiUrl + `groups/assign/user/${userId}`, payload)
    .pipe(catchError(this.handleError));
  }

  unassignGroups(userId, groupId): Observable<any> {
    return this.http.delete(this.apiUrl + `groups/user/${userId}/group/${groupId}`)
    .pipe(catchError(this.handleError));
  }

  downloadUserCSV(searchData, roleId?): Observable<any> {
    let tempUrl = this.apiUrl + 'users/download' + this.timeZone;
    if (searchData.userTypeVal) {
      tempUrl += '&key=' + searchData.searchType + '&value=' + searchData.userTypeVal;
    }
    if (roleId) tempUrl += `&roleId=${roleId}`;
    return this.http.get(tempUrl, { responseType: 'arraybuffer', observe: 'response' }).pipe(
      catchError(this.handleError));
  }

  handleError(error: any | any) {
    console.log(error.error || error.json() || error);
    return observableThrowError(error.error || error.json() || error || 'Server error');
  }
}
