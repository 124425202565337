import { Component, OnInit } from '@angular/core';
import { SharedService } from '@shared/shared.service';
import { environment } from '@myenv/environment';

@Component({
  selector: 'app-page-not-found',
  template: `<ng-container *ngIf="isSession">
  <div class="body-wrap">
      <div id="sm-container" class="sm-container">

          <div class="sm-pusher">
              <div class="sm-content">
                  <div class="sm-content-inner">
  <app-header></app-header>

  <section id="main_content" class="bg slice-sm sct-color-1">
          <div class="col-md-12 alertComp">
          <div class="row">

              <div class="col-12">

                 <div class="text-center">
                          <h1 class="darbyColor m-t-30">WHOOPS!</h1>
                          <p class="pageNotFoundWe">We can’t seem to find this page</p>
                         <img src="{{cmImageUrl}}assets/img/404.png" alt="404 page not found" class="pageNotFound">
                         <div class="pageNotFoundSorry"><p>Sorry, the page you’re looking for cannot be accessed.</p>
                         <p>Either check the URL, <a routerLink="{{defaultUrl}}">go home</a>, or feel free to <a href="javascript:void(0);">report this issue</a></p>
                         </div>
                  </div>
              </div>


              </div>

              </div>
          </section>
      <app-footer></app-footer>
  </div>
  </div>
  </div>
  </div>
  </div>
  </ng-container>

  <ng-container *ngIf="!isSession">
      <section id="main_content" class="bg slice-sm sct-color-1">
          <div class="col-md-12 alertComp">
          <div class="row">
              <div class="col-12">
                          <div class="login-header">
                                          <div class="brand text-center">
                                              <img src="{{cmImageUrl}}assets/img/customer-logo.png" alt="logo" class="pageNotFoundLogo">
                                          </div>
  </div>
                 <div class="text-center">
                    <h1 class="darbyColor m-t-30">WHOOPS!</h1>
                    <p class="pageNotFoundWe">We can’t seems to find this page</p>
                    <img src="{{cmImageUrl}}assets/img/404.png" alt="404 page not found" class="pageNotFound">
                    <div class="pageNotFoundSorry"><p>Sorry, the page you’re looking for cannot be accessed.</p>
                    <p>Either check the URL, 
                        <a routerLink="/">go home</a>, or feel free to <a href="javascript:void(0);">report this issue</a>
                    </p>
                </div>
                  </div>
              </div>


              </div>

              </div>
          </section>
  </ng-container>
  `
})
export class PageNotFoundComponent implements OnInit {
    public cmImageUrl = environment.cmImageUrl;
 isSession : boolean = false;
 defaultUrl : string;
  constructor(private sharedService : SharedService) {

  }

  ngOnInit() {
    if(localStorage.getItem('defaultUrl')){
        this.defaultUrl = localStorage.getItem('defaultUrl');
    }else{
        this.defaultUrl = '/'
    }
      if(this.sharedService.getCurrentUser()){
          this.isSession = true;

      }

  }

}
