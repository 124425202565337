<!-- <app-notify-message></app-notify-message> -->
<div class="container-fluid width100 trips-page p-0">
<app-data-tab-header></app-data-tab-header>
  <div id="toggleRow" class="row no-gutters limit-width trips-page-header action-row align-items-center">
    <!-- back -->
    <div class="col-md-1 col-sm-2 pl-2 animated fadeIn">
      <!-- <a class="link-color text-bold" style="cursor: pointer;" (click)="backToLive()">
        <i class="fa fa-chevron-left"></i> Back
      </a> -->
    </div>
    <div class="col-md-3 col-sm-10 vehicle-select">
      <div class="row">
        <!-- toggles column -->
        <div class="col-md-4 col-sm-10 vehicle-select">
          <!-- trips view toggle -->
          <!-- <ng-template #TripTooltip>
            <div class="text-left" style="width: 580px">
              <h6>Trips</h6>
              <p>
                If enabled, the data will be displayed as individual trips. <br>
                If disabled, the data displayed will be over the given time period based on the selections on the upper right of the screen. <br>
              </p>
            </div>
          </ng-template>
          <label class="d-inline" style="font-size: 0.875rem;">Trips &nbsp;</label>
          <label class="toggle-switch m-0" [tooltip]="TripTooltip" placement="top" content-type="template" width="580" max-width="580">
            <input type="checkbox" name="toggle-ingestionTimeStamp" autocomplete="off" [checked]="selectedTrips"
              (change)="goBackToLocationHistory()" />
            <span class="toggle-switch-slider round"></span>
          </label> -->
        </div>
        <div class="col-md-6 col-sm-10 pl-0 vehicle-select">
          <!-- trips view toggle -->
          <!-- <ng-template #LatencyTooltip>
            <div class="text-left" style="width: 580px;">
              <h6>Network Latency</h6>
              <p>
                If disabled, the map will display a line based on the data displayed. <br>
                If enabled, each data element displayed will have a corresponding dot on the map to show upload latency based on the events trigger time and the time the server received the event. <br>
              </p>
            </div>
          </ng-template>
          <label class="d-inline" style="font-size: 0.875rem;">Network &nbsp;</label>
          <label class="toggle-switch m-0" [tooltip]="LatencyTooltip" content-type="template" placement="top" width="580" max-width="580">
            <input type="checkbox" name="toggle-ingestionTimeStamp" autocomplete="off" [checked]="selectedNetwork"
              (change)="networkToggle()" />
            <span class="toggle-switch-slider round"></span>
          </label> -->
        </div>
      </div>
    </div>



    <!-- device, calendar column -->
    <div class="col-md-8 col-sm-12 text-right select-operations vehicleContainer">
      <!-- group name display -->
      <span *ngIf="!tripViewToggle && selectedDevice" class="btn btn-custom groupBtn disabled mr-1 groupInfo"
        tooltip="Group: {{ selectedDevice.groupName }}">
        Group: {{ selectedDevice.groupName }}
      </span>
      <!-- message type select dropdown -->
      <div *ngIf="!tripViewToggle" class="d-inline-block message-select ng-select-row mr-1" style="width: 8rem;">
        <ng-select [items]="messageTypeArray" bindLabel="displayName" bindValue="messageType" [multiple]="false"
          [closeOnSelect]="true" name="messageType" #messageType="ngModel" [(ngModel)]="searchModel.messageType"
          (change)="getFilteredMessage()" class="btn btn-custom p-0 text-left" placeholder="Select Message">
        </ng-select>
      </div>
      <!-- device select dropdown -->
      <div class="d-inline-block mr-1" style="width: 15rem;">
        <app-search-select-vehicle (vehicleChangeEvent)="changeVehicle($event)"
          [querySelectVehicle]="querySelectVehicle" [autoSelect]="true" [clearable]="false"
          [defaultselectvehicle]="true">
        </app-search-select-vehicle>
      </div>
      <!-- calendar date time select -->
      <div class="d-inline-block mr-1" (clickOutside)="hideCalendar=!hideCalendar;">
        <app-date-time-calendar
         [messageView]="accordion.msg_view"
          [clickOutside]="hideCalendar"
          [selectPreset]="'eventTime'" 
          [vehicle]="selecteddeviceId"
          [maxRangeDays]="tripViewToggle ? 0 : 1"
          [validateHours]="tripViewToggle ? 0 : 24"
          [hideLinks]="tripViewToggle ? [] : ['7days', '30days', 'week', 'month', 'todaysofar', 'weektodate', 'monthtodate', '90days', '180days', '365days']"
          [displayEventTime]="{ start: pickerStart, end: pickerEnd }"
          (timeChangeEvent)="onTimeChange($event)">
        </app-date-time-calendar>
      </div>
    </div>
  </div>
  <!-- <div class="row no-gutters limit-width trips-page-header action-row align-items-center" [style.display]="currentView === 2 ? 'flex' : 'none'">
    <ul id="tskr" class="col-md-12 nav detailed-tabs relative page-sub-heading" style="padding: 3px 12px;" role="tablist">
      <app-time-seeker [selectedDevice]="selecteddeviceId" [tsTime]="pickerStart" [teTime]="pickerEnd"
            (onTimeSel)="onTimeSelection($event)"></app-time-seeker>
    </ul>
  </div> -->
  <!-- table row -->
  <div class="row no-gutters" style="padding-left: 0.5rem;">
    <div class="col-md-4 m-0 p-0 left-container" id="leftsctn">
      <!-- <div class="left-sec">
        <div class="pid-label" id="tripView">
          <i class="fa fa-arrow-left" (click)="currentView = currentView === 0 ? 2 : 0" aria-hidden="true"></i>
          {{ currentView === 0 ? 'Trip View' : 'Messages View' }}
          <i class="fa fa-arrow-right" aria-hidden="true" (click)="currentView = currentView === 0 ? 2 : 0"></i>
        </div>
      </div> -->
      <div class="left-sec" [ngStyle]="{'display': ((tripViewToggle) ? 'block' : 'none')}">
        <div class="pid-label" id="tripView" [class.active]="accordion.trip_view">
          <div class="accordion">
            <!-- <div (click)="triggerDynamicService(0);" class="adn1"> -->
            <span class="adn1" (click)="controlAccordionActions('trip');">
              Trip View
            </span>
            <!-- </div> -->
            <div class="adn2">
              <div class="arrow">
                <i class="fa fa-plus-circle fa-lg" (click)="controlAccordionActions('trip');"
                  [class.fa-minus-circle]="accordion.trip_view" aria-hidden="true"></i>
              </div>
              <span class="toggle">
                <ng-template #TripTooltip>
                  <div class="text-left" style="width: 580px">
                    <h6>Trips / Message View</h6>
                    <p>
                      If enabled, the data will be displayed as individual trips. <br>
                      If disabled, the data will be displayed as individual messages. <br>
                    </p>
                  </div>
                </ng-template>
                <label class="toggle-switch m-0" [tooltip]="TripTooltip" placement="top" content-type="template"
                  width="580" max-width="580">
                  <input type="checkbox" name="toggle-ingestionTimeStamp" autocomplete="off"
                    (change)="tripViewSwitch(false)" [checked]="tripViewToggle ? true : false" />
                  <span class="toggle-switch-slider round"></span>
                </label>
              </span>
            </div>
          </div>
        </div>
        <div class="" tripViewContainer [@slideInOut]="accordion.trip_view ? 'out' : 'in'"
          [ngStyle]="{'display': ((accordion.trip_view) ? 'block' : 'none')}">
          <!-- distance and shortcut actions row -->
          <div class="row no-gutters align-items-center" [ngStyle]="{'display': ((trips.length) ? 'flex' : 'none')}">
            <!-- distance display column -->
            <!-- <div class="col-md-6 pl-1 animated fadeIn">
                                  <div class="events-div div1 m-1">
                                    <span class="title distance-title">Distance</span>
                                    <h5 class="value distance-value">
                                      {{ distanceTravelled }}
                                      <span class="distance-units">{{distanceMetrics}}</span>
                                    </h5>
                                  </div>
                                </div> -->
            <!-- shortcut column -->
            <div class="col-md-12 text-right pr-2 animated fadeIn mb-1 mt-1" id="trip_info">
              <!-- <div class="col-md-6 col-sm-10 pl-0 vehicle-select"> -->
              <!-- trips view toggle -->
              <!-- <ng-template #LatencyTooltip>
                  <div class="text-left" style="width: 580px;">
                    <h6>Network Latency</h6>
                    <p>
                      If disabled, the map will display a line based on the data displayed. <br>
                      If enabled, each data element displayed will have a corresponding dot on the map to show upload latency based on the events trigger time and the time the server received the event. <br>
                    </p>
                  </div>
                </ng-template>
                <label class="d-inline" style="font-size: 0.875rem;">Network &nbsp;</label>
                <label class="toggle-switch m-0" [tooltip]="LatencyTooltip" content-type="template" placement="top" width="580" max-width="580">
                  <input type="checkbox" name="toggle-ingestionTimeStamp" autocomplete="off" [checked]="dottedEvents.tripChecked"
                    (change)="networkToggle()" />
                  <span class="toggle-switch-slider round"></span>
                </label> -->
              <ng-template #LatencyTooltip1>
                <div class="text-left" style="width: 580px;">
                  <h6>Map View</h6>
                  <p>
                    Map will display a line based on the data displayed. <br>
                  </p>
                </div>
              </ng-template>
              <ng-template #LatencyTooltip2>
                <div class="text-left" style="width: 580px;">
                  <h6>Network Latency</h6>
                  <p>
                    Each data element displayed will have a corresponding dot on the map to show upload latency based on
                    the events trigger time and the time the server received the event. <br>
                  </p>
                </div>
              </ng-template>
              <span class="btn btn-icon mx-1 nk" [tooltip]="LatencyTooltip1" content-type="template" placement="top"
                width="580" max-width="580" (click)="networkToggle(false);"
                [class.active-nk]="!dottedEvents.tripChecked">
                <i class="fa fa-compass" aria-hidden="true"></i>
              </span>
              <span class="btn btn-icon mx-1 nk" [tooltip]="LatencyTooltip2" content-type="template" placement="top"
                width="580" max-width="580" (click)="networkToggle(true);" [class.active-nk]="dottedEvents.tripChecked">
                <i class="fa fa-map-pin" aria-hidden="true"></i>
              </span>
              <!-- </div> -->
              <!-- <button *ngIf="permissionModules.includes('REPORT')" class="btn btn-icon mx-1" tooltip="Go to reports"
                placement="top" (click)="showReportsModal()">
                <i class="fa fa-bar-chart"></i>
              </button> -->
              <!--</div>-->
              <!-- <button
                                    class="btn btn-icon ml-1 filter-button"
                                    (click)="togglePlacesFilter()"
                                    tooltip="Filter Places"
                                    placement="top"
                                    >
                                    <i [class.text-danger]="filterplace" class="fa fa-filter" aria-hidden="true"></i>
                                    <span class="mob-access-text d-none">&nbsp;Filter</span>
                                  </button> -->
              <button class="btn btn-icon mx-1 nk" tooltip="Download Trips CSV" placement="top" [disabled]="!trips.length"
                (click)="downloadTripCSV()">
                <i class="fa fa-cloud-download"></i>
              </button>
            </div>
          </div>
          <!-- table row -->
          <div class="row no-gutters">
            <div class="col-md-12">
              <div class="mb-0 pr-0" id="tripsContainer">
                <div class="page-loading" *ngIf="pageLoading">
                  <img src="../../../assets/img/darby_loader_small.gif" />
                </div>
                <div *ngIf="!trips.length && !pageLoading" class="text-center mt-2">
                  <img width="170" src="../../../assets/img/Trip_nodata_256.png" />
                  <h5 class="">No trips to show</h5>
                </div>
                <ngx-datatable #trip_msg_view *ngIf="trips.length"
                  class="bootstrap alertTable default-background data-table active-table reportsTable wrapcell trips-obd"
                  [rows]="trips" [columnMode]="'force'" [headerHeight]="40" [scrollbarH]="true" [rowHeight]="40"
                  [selected]="selected" [selectionType]="'single'" (select)="selectTrip($event)"
                  [externalSorting]="true" [sorts]="[{dir: sort, prop: 'tripStart'}]" (sort)="sortTrips($event)">
                  <ngx-datatable-column [resizeable]="false" name="Trip #" [width]="50">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      {{row.tripNumber}}
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column [resizeable]="false" [sortable]="false" name="Distance" [width]="90">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      {{row.distanceTravelled | distanceMetricspipe:'1'}}
                      <span *ngIf="row.distanceTravelled">{{distanceMetrics}}</span>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column [resizeable]="false" [sortable]="true" name="Trip Start" [width]="110">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      {{ row.tsTime | date: dateFormat}}
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column [resizeable]="false" [sortable]="false" name="Trip End" [width]="110">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      {{row.teTime | date: dateFormat}}
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column [resizeable]="false" [sortable]="false" name="Duration" [width]="80">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      {{ row.tripDuration }}
                      <!-- <i class="fa fa-video-camera" *ngIf="row.videosCount" aria-hidden="true"></i> {{ row.videosCount ? row.videosCount : '' }} -->
                    </ng-template>
                  </ngx-datatable-column>
                </ngx-datatable>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="left-sec" [ngStyle]="{'display': ((!tripViewToggle) ? 'block' : 'none')}">
        <div class="pid-label" id="messageView" [class.active]="accordion.msg_view">
          <div class="accordion">
            <!-- <div class="adn1" (click)="triggerDynamicService(2);"> -->
            <span class="adn1" (click)="controlAccordionActions('msg')">Message View</span>
            <!-- </div> -->
            <div class="adn2">
              <div class="arrow">
                <i class="fa fa-plus-circle fa-lg" (click)="controlAccordionActions('msg');"
                  [class.fa-minus-circle]="accordion.msg_view" aria-hidden="true"></i>
              </div>
              <span class="toggle">
                <ng-template #TripTooltip>
                  <div class="text-left" style="width: 580px">
                    <h6>Trips / Message View</h6>
                    <p>
                      If enabled, the data will be displayed as individual trips. <br>
                      If disabled, the data will be displayed as individual messages. <br>
                    </p>
                  </div>
                </ng-template>
                <!-- <label class="d-inline" style="font-size: 0.875rem;">Trips &nbsp;</label> -->
                <label class="toggle-switch m-0" [tooltip]="TripTooltip" placement="top" content-type="template"
                  width="580" max-width="580">
                  <input type="checkbox" name="toggle-ingestionTimeStamp" autocomplete="off"
                    (change)="tripViewSwitch(true)" [checked]="tripViewToggle ? true : false" />
                  <span class="toggle-switch-slider round"></span>
                </label>
              </span>
            </div>
          </div>
        </div>
        <div class="col-12 p-0" messageViewContainer [ngStyle]="{'display': (accordion.msg_view ? 'block' : 'none')}"
          [@slideInOut]="accordion.msg_view ? 'out' : 'in'">
          <div class="row no-gutters align-items-center" id="msg_info">
            <!-- distance display column -->
            <div class="col-md-6 pl-1 animated fadeIn">
              <div class="events-div div1 m-1">
                <span class="title distance-title">Distance</span>
                <span class="title distance-title">
                  {{ totalDistanceTravelled | distanceMetricspipe }}
                  <small class="">{{ distanceMetrics }}</small>
                </span>
              </div>
            </div>
            <!-- shortcut column -->
            <div class="col-md-6 text-right pr-2 animated fadeIn">
              <!-- <button *ngIf="permissionModules.includes('REPORT')" class="btn btn-icon mx-1" tooltip="Go to reports"
                placement="top" (click)="showReportsModal()">
                <i class="fa fa-bar-chart"></i>
              </button> -->
              <span class="btn btn-icon mx-1 nk" *ngIf="historyRows.length" [tooltip]="LatencyTooltip1" content-type="template" placement="top"
                width="580" max-width="580" (click)="switchMapForLatency(false)" [class.active-nk]="!dottedEvents.tripChecked">
                <i class="fa fa-compass" aria-hidden="true"></i>
              </span>
              <span class="btn btn-icon mx-1 nk" *ngIf="historyRows.length" [tooltip]="LatencyTooltip2" content-type="template" placement="top"
                width="580" max-width="580" (click)="switchMapForLatency(true)" [class.active-nk]="dottedEvents.tripChecked">
                <i class="fa fa-map-pin" aria-hidden="true"></i>
              </span>
              <button class="btn btn-icon ml-1 filter-button nk" (click)="togglePlacesFilter()" tooltip="Filter Places"
                placement="top" [class.active-nk]="filterplace">
                <i class="fa fa-filter" aria-hidden="true"></i>
                <span class="mob-access-text d-none">&nbsp;Filter</span>
              </button>
              <button *ngIf="viewPage && historyRows.length" class="btn btn-icon mx-1 nk" tooltip="Download CSV" placement="top"
                (click)="downloadViewPage()">
                <i class="fa fa-cloud-download"></i>
              </button>
            </div>
          </div>
          <!-- <div class="text-right" id="msg-ntwk">
            <span class="btn btn-icon mx-1 nk" [tooltip]="LatencyTooltip1" content-type="template" placement="top" width="580" max-width="580" (click)="switchMap(false)" [class.active-nk]="!dottedEvents.tripChecked">
              <i class="fa fa-compass" aria-hidden="true"></i>
            </span>
            <span class="btn btn-icon mx-1 nk" [tooltip]="LatencyTooltip2" content-type="template" placement="top" width="580" max-width="580" (click)="switchMap(true)" [class.active-nk]="dottedEvents.tripChecked">
              <i class="fa fa-map-pin" aria-hidden="true"></i>
            </span>
          </div> -->
          <div id="messageContainer">
            <ngx-datatable
              *ngIf="historyRows.length"
              class="bootstrap alertTable reportsTable wrapcell historyTable animated fadeIn location-hisory-ngxtable"
              [loadingIndicator]="loadingIndicator" [rows]="historyRows" [columns]="" [columnMode]="'force'" [headerHeight]="20"
              [footerHeight]="historyRows.length > 10 ? 50 : 0" [offset]="pageInfo.offset" [count]="pageInfo.count"
              [limit]="pageInfo.limit" [rowHeight]="30" [summaryRow]="false" [scrollbarH]="true" [scrollbarV]="false"
              (page)="pageCallback($event)" [reorderable]="true">
              <ngx-datatable-column [resizeable]="false" name="Event Time" [sortable]="false">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.eventTime | date: msgDateFormat }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [resizeable]="false" name="Ingestion Time" [sortable]="false">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.ingestionTimestamp | date: msgDateFormat }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [resizeable]="false" name="Activity" [sortable]="false">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <a *ngIf="row.messageId" (click)="getMessageJSON(row.messageId);" data-toggle="modal"
                    data-target="#messageJSON">
                    <span class="success">{{ row.activity }}</span>
                  </a>
                  <a *ngIf="!row.messageId" class="place-activity-cursor">
                    <span *ngIf="!row.messageId">{{ row.activity }}</span>
                  </a>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
            <div class="total-count" *ngIf="locationTotalCount && locationTotalCount.count && historyRows.length"
              style="bottom: -20px;">
              Showing
              <span>
                {{ currentPageCount.start }}
              </span>
              -
              <span>
                {{ currentPageCount.end }}
              </span> of {{ locationTotalCount.count }} results.
            </div>
            <div *ngIf="historyRowsEmpty" class="text-center my-2 pb-2">
              <img width="170" src="../../../assets/img/info_nodata_256.png" />
              <h5 class="mt-1">No data to show</h5>
            </div>
          </div>
        </div>
      </div>
      <div class="left-sec">
        <div class="pid-label" (click)="controlAccordionActions('pidView')" id="pidView" [class.active]="accordion.pid_view">
          <div class="accordion">
            <span class="adn1">Data View</span>
            <div class="adn2">
              <div class="arrow">
                <i class="fa fa-plus-circle fa-lg" [class.fa-minus-circle]="accordion.pid_view" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 p-0" obdPidViewContainer [ngStyle]="{'display': (accordion.pid_view ? 'block' : 'none')}"
          [@slideInOut]="(accordion.pid_view) ? 'out' : 'in'">
          <app-obd-pid-view style="height: inherit;" [isHistoryToggleOn]="true" [onDateChange]="actionDateChange"
            [selectedDevice]="selectedDevice" [tsTime]="this.obd.ts" [teTime]="this.obd.te" (update)="onPIDClick($event);">
          </app-obd-pid-view>
        </div>
      </div>
    </div>
    <div id="mapContainer" class="col-md-8 m-0 p-0">
      <div id="tskr" class="row m-0 p-0 events-header align-items-center background-white">
        <div class="col-12 p-0" *ngIf="!tripViewToggle">
          <!-- <span class="seeker seeker-span"><small>Time Seeker: </small></span> -->
          <app-time-seeker class="seeker" [selectedDevice]="selecteddeviceId" [showLabel]="false" [tsTime]="pickerStart"
            [teTime]="pickerEnd" (onTimeSel)="onTimeSelection($event)"></app-time-seeker>
        </div>
        <!-- <div class="col-12 p-0" *ngIf="tripViewToggle">
          <hr class="m-0" style="margin-bottom: 10px !important;">
        </div> -->
        <div class="col-4 text-center border-right" *ngIf="tripViewToggle">
          <svg class="event-svg harsh" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 40 40"
            style="enable-background:new 0 0 40 40;" xml:space="preserve">
            <path class="st0"
              d="M20,0C9,0,0,9,0,20s9,20,20,20s20-9,20-20S31,0,20,0z M20.1,33.1L8.8,13.5h22.6L20.1,33.1z" />
          </svg>
          <span class="text-blue-grey font-14 pl-2">
            Hard Braking :
          </span>
          <span class="font-14 pl-4">
            <span *ngIf="selectedTrip.hardBreakingCounts>=0"
              class="link-color">{{selectedTrip.hardBreakingCounts}}</span>
            <span *ngIf="!selectedTrip.hardBreakingCounts && selectedTrip.hardBreakingCounts!=0"
              class="text-blue-grey">--</span>
          </span>
        </div>

        <div class="col-4 text-center border-right" *ngIf="tripViewToggle">
          <svg class="event-svg speeding" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 40 40"
            style="enable-background:new 0 0 40 40;" xml:space="preserve">
            <path class="st0" d="M20,0C9,0,0,9,0,20s9,20,20,20s20-9,20-20S31,0,20,0z M25.6,28.8c-1.5,0-2.9-0.4-4.1-1.1l-9.6-4.5h5.6L6.8,18
            h7.8l-9.4-5h20c0.1,0,0.3,0,0.4,0c4.4,0,7.9,3.6,7.9,7.9C33.5,25.2,30,28.8,25.6,28.8z" />
          </svg>
          <span class="text-blue-grey font-14 pl-2">
            Speeding :
          </span>
          <span class="font-14 pl-4">
            <span *ngIf="selectedTrip.highSpeedEventCounts>=0"
              class="link-color">{{selectedTrip.highSpeedEventCounts}}</span>
            <span *ngIf="!selectedTrip.highSpeedEventCounts && selectedTrip.highSpeedEventCounts!=0"
              class="text-blue-grey">--</span>
          </span>
        </div>

        <div class="col-4 text-center border-right" *ngIf="tripViewToggle">
          <svg class="event-svg rapid" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 40 40"
            style="enable-background:new 0 0 40 40;" xml:space="preserve">
            <g>
              <circle class="st0" cx="24.6" cy="20.1" r="2.9" />
              <path class="st0"
                d="M20,0C9,0,0,9,0,20s9,20,20,20s20-9,20-20S31,0,20,0z M24.6,28.1H7.9v-1.4h12.3c-2.1-1.5-3.5-3.9-3.5-6.6
            c0-1.1,0.2-2.1,0.6-3.1h-10v-1.3H18c0.6-0.8,1.3-1.5,2.1-2.1H12v-1.4h12.6c4.4,0,7.9,3.7,7.9,8S29,28.1,24.6,28.1z" />
            </g>
          </svg>
          <span class="text-blue-grey font-14 pl-2">
            Hard Acceleration :
          </span>
          <span class="font-14 pl-4">
            <span *ngIf="selectedTrip.hardAcclCounts>=0" class="link-color">{{selectedTrip.hardAcclCounts}}</span>
            <span *ngIf="!selectedTrip.hardAcclCounts && selectedTrip.hardAcclCounts!=0"
              class="text-blue-grey">--</span>
          </span>
        </div>
      </div>
      <!-- <div class="row m-0 p-0 map-wrapper" style="height: 83%"> -->
      <div class="row m-0 p-0 map-wrapper" style="height: auto;">
        <div class="col-12 p-0" id="rightSctn">
          <!-- <div class="map-wrapper"> -->
          <div #gmap id="gmap" style="width: 100%;"></div>
          <div #infowindowData class="infowindowData" style="height: 10px;">
            <ng-container *ngIf="latencyData?.ingestionTimestamp && accordion.trip_view">
              <h5 style="border-bottom: 2px solid #5ec5e2">GPS Message</h5>
              <p><span class="font-weight-bold">GsmStrength: </span><span>{{latencyData.gsmStrength? latencyData.gsmStrength : '--'}}</span></p>
              <p><span class="font-weight-bold">Event Time: </span><span>{{latencyData?.eventTime | date:dateFormat}}</span></p>
              <p><span class="font-weight-bold">Ingestion Time: </span><span>{{latencyData?.ingestionTimestamp | date:dateFormat}}</span></p>
              <br>
              <!-- <p class="mb-0 font-14"> {{latencyData.messageType?'Event:'+latencyData.messageType:''}}</p> -->
            </ng-container>
            <ng-container *ngIf="latencyData?.ingestionTimestamp && accordion.msg_view">
            <div *ngIf="dottedEvents.tripChecked">
              <h5 style="border-bottom: 2px solid #5ec5e2">GPS Message</h5>
              <p><span class="font-weight-bold">GsmStrength: </span><span>{{latencyData.gsmStrength? latencyData.gsmStrength : '--'}}</span></p>
              <p><span class="font-weight-bold">Event Time: </span><span>{{latencyData?.eventTime | date:dateFormat}}</span></p>
              <p><span class="font-weight-bold">Ingestion Time: </span><span>{{latencyData?.ingestionTimestamp | date:dateFormat}}</span></p>
              <!-- <p class="mb-0 font-14"> {{latencyData.messageType?'Event:'+latencyData.messageType:''}}</p> -->
              <br>
          </div>
        <div *ngIf="!dottedEvents.tripChecked">
          <h5 style="border-bottom: 2px solid #5ec5e2">GPS Message</h5>
          <p><span class="font-weight-bold">Latitude: </span><span>{{latencyData.latitude? latencyData.latitude : '--'}}</span></p>
          <p><span class="font-weight-bold">Longitude: </span><span>{{latencyData.longitude? latencyData.longitude : '--'}}</span></p>
          <p><span class="font-weight-bold">Message Time: </span><span>{{latencyData?.eventTime | date:dateFormat}}</span></p>
          <p><span class="font-weight-bold">Ingestion Time: </span><span>{{latencyData?.ingestionTimestamp | date:dateFormat}}</span></p>
          <br>
        </div>
              <!-- <p class="mb-0 font-14">GsmStrength: {{latencyData.gsmStrength? latencyData.gsmStrength : '--'}}</p> -->
            
            </ng-container>
          </div>
          <hr style="margin: 0;">
          <app-obdpid [params]="graphContainerParams" (updatePointerEvents)="updatePointerEvents($event);"></app-obdpid>
          <!-- </div> -->
        </div>
      </div>
    </div>
    <!-- <div class="col-md-12 no-gutters"> -->
    <!-- <hr style="margin: 0;"> -->
    <!-- <div class="draggable" draggable>
        <div class="" id="draggable">
          <i class="fa fa-ellipsis-h" aria-hidden="true" style="position: absolute; margin-top: -4px; font-size: 20px; color: white;"></i>
        </div>
      </div> -->
    <!-- <app-obdpid [selectedDevice]="selectedDevice" [selectedTrip]="selectedTrip" [tsTime]="this.obd.ts"
        [nonTripMode]="false" [teTime]="this.obd.te" [sort]="sort" [trips]="trips" [hasTrip]="this.trips.length"
        [location]="obdLocation" [updateChart]="updateChrt"></app-obdpid> -->
    <!-- </div> -->
  </div>
</div>

<!-- message JSON popup start -->
<div id="messageJSON" data-keyboard="false" data-backdrop="static" class="modal fade">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content bd-0 bg-transparent overflow-hidden">
      <div class="modal-body p-0">
        <div class="row no-gutters">
          <div class="col-12">
            <span class="pull-right closeBtn" data-dismiss="modal">
              <i class="fa fa-times" aria-hidden="true"></i>
            </span>
            <div class="modal-box p-3 mt-4">
              <div class="reply-header">
                <h5>Message Detail</h5>
              </div>
              <div *ngIf="messageJsonLoader" class="text-center mt-5 pt-5">
                <img src="../../../assets/img/darby_loader_small.gif" />
              </div>
              <div *ngIf="!messageJsonLoader" class="scrollable-modal-body">
                <pre class="bench-device-data mt-4" [innerHTML]="messageJsonFormattedString"> </pre>
              </div>
              <div class="row pt-3">
                <div class="col-lg-6"></div>
                <div class="col-lg-6 sm-form-design">
                  <div class="login-buttons width100 text-right">
                    <button class="btn btn-outline-primary small-screen-btn mr-2"
                      (click)="downloadMessageJSON()">Download JSON</button>
                    <button class="btn btn-outline-secondary small-screen-btn mr-2" data-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- message JSON popup end -->

<!-- start popup go to reports -->
<div id="goToReportsModal" data-keyboard="false" data-backdrop="static" class="modal fade">
  <div class="modal-dialog" role="document">
    <div class="modal-content bd-0 bg-transparent overflow-hidden">
      <div class="modal-body p-0">
        <div class="row no-gutters">
          <div class="col-12">
            <span #closeReportsModal class="pull-right closeBtn" data-dismiss="modal">
              <i class="fa fa-times" aria-hidden="true"></i>
            </span>
            <div class="modal-box p-3 mt-4">
              <div class="reply-header">
                <h5>Go to Reports</h5>
              </div>
              <div *ngIf="customLoader" class="text-center tooltip-address-text">
                <img src="../../../assets/img/darby_loader_small.gif" />
              </div>
              <div class="row no-gutters mt-3">
                <div class="col-sm-12">
                  <div class="card">
                    <ul class="list-group list-group-flush" style="max-height: 400px; overflow: auto;">
                      <li class="list-group-item">
                        <form #reportSearchForm="ngForm">
                          <input #reportSearch type="text" class="form-control p-2" placeholder="Search Report"
                            (click)="$event.stopPropagation();" (keyup)="filterReport($event)" />
                        </form>
                      </li>
                      <li class="list-group-item text-left cursor-pointer" *ngFor="let data of reports"
                        (click)="redirectToReports(data)">
                        <i class="fa fa-bar-chart report-icon"></i> {{data.name}}
                      </li>
                      <li class="list-group-item text-center" *ngIf="!reports.length">
                        <b>No reports found</b>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- end popup go to reports -->
<button id="goToReportsBtn" type="button" hidden data-toggle="modal" data-target="#goToReportsModal"></button>