import { Component, OnInit } from '@angular/core';
import { SharedService } from '@myproject/shared/shared.service';
import Chart from 'chart.js';
import { GraphicReportsService } from '../../graphic-reports.service';
import { DatePipe } from '@angular/common';
import {Title} from '@angular/platform-browser';
import { environment } from '@myenv/environment';

@Component({
  selector: 'app-processing-time-report',
  templateUrl: './processing-time-report.component.html',
  styleUrls: ['./processing-time-report.component.scss']
})
export class ProcessingTimeReportComponent implements OnInit {
  startTime: number;
  endTime: number;
  page: number;
  isLoading = false;
  vehicles: any = [];
  selectedVehicle: string;
  vehiclesLoading = false;
  lineChart: any;
  highestCount = 0;

  constructor(
    private sharedService: SharedService,
    private reportsService: GraphicReportsService,
    private title: Title
  ) { }

  ngOnInit() {
    this.title.setTitle('Processing Time Report'+ environment.title_text);
  }

  changeVehicle(e) {
    this.selectedVehicle = e ? e.deviceId : null;
    this.onSelectVehicle();
  }

  refresh() {
    this.onSelectVehicle();
  }

  onSelectVehicle() {
    this.isLoading = true;
    if (this.selectedVehicle === null) {
      this.getAllProcessingTime();
    } else {
      this.getAllProcessingTime(this.selectedVehicle);
    }
  }

  getAllProcessingTime(deviceId?) {
    this.reportsService.getAllProcessingTime(deviceId).subscribe({
      next: res => {
        if (res.length) {
          this.sortLineData(res);
        } else {
          this.drawLineGraph([], [], []);
          this.isLoading = false;
        }
      },
      error: error => {
        this.isLoading = false;
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  sortLineData(res) {
    this.highestCount = 0;
    const labels = [];
    const processingTime = [];
    const networkTime = [];
    res.reverse();
    res.map(item => {
      var datePipe = new DatePipe('en-US');
      let eventDate = datePipe.transform(item.eventTime, 'dd MMM hh:mm a');
      labels.push(eventDate);
      networkTime.push(item.networkTime);
      processingTime.push(item.processingTime);
    });
    this.highestCount = Math.max(Math.max(...networkTime), Math.max(...processingTime));
    this.drawLineGraph(labels, networkTime, processingTime);
    this.isLoading = false;
  }

  drawLineGraph(labels, networkTime, processingTime) {
    console.log(this.highestCount)
    if (this.lineChart) {
      this.lineChart.destroy();
    }
    var ctx = document.getElementById('lineChart');
    this.lineChart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [{
          label: 'Network Time',
          backgroundColor: 'rgb(54, 162, 235, 0.2)',
          borderColor: 'rgb(54, 162, 235, 1)',
          data: networkTime
        }, {
          label: 'Processing Time',
          backgroundColor: 'rgb(255, 99, 132, 0.2)',
          borderColor: 'rgb(255, 99, 132, 1)',
          data: processingTime
        }]
      },
      options: {
        legend: {
          position: 'bottom',
          display: true
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              suggestedMin: 0,
              suggestedMax: 10,
              stepSize: this.highestCount > 9 ? null : 1
            }
          }],
          xAxes: [{
            ticks: {
              autoSkip: true,
              maxTicksLimit: 12
            }
          }]
        },
      }
    });
  }
}
