import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '@myenv/environment';
var TripsNewService = /** @class */ (function () {
    function TripsNewService(http) {
        this.http = http;
        this.apiUrl = environment.apiUrl;
    }
    // searchVehicles(searchValue) {
    //   return this.http.get(this.apiUrl + `messages/latest/search?assetName=${searchValue}`)
    //   .catch(this.handleError);
    // }
    // To get the trips
    TripsNewService.prototype.getTrips = function (deviceId, endTime, startTime, sort) {
        return this.http.get(this.apiUrl + 'trips/device/' + deviceId + '?startTime=' + startTime + '&endTime=' + endTime + '&sort=' + sort);
    };
    TripsNewService.prototype.getPolylineData = function (deviceId, startTime, endTime) {
        return this.http
            .get(this.apiUrl +
            ("messages/valid/device/" + deviceId) +
            "?attributes=eventTime,latitude,longitude,messageType,messageId," +
            "maxSpeed,avgSpeed,initialSpeed,finalSpeed,maxBraking,initialSpeed,finalSpeed,maxAcceleration" +
            ("&startTime=" + startTime + "&endTime=" + endTime + "&limit=10000")).pipe(catchError(this.handleError));
    };
    TripsNewService.prototype.handleError = function (error) {
        return observableThrowError(error.error || error.json() || error || 'Server error');
    };
    return TripsNewService;
}());
export { TripsNewService };
