<div class="body-wrap">
        <app-notify-message></app-notify-message>
    <div class="login login-v3">
        <!-- begin news-feed -->
        <div class="news-feed">
            <div class="news-image">

            </div>

        </div>
        <!-- end news-feed -->
        <!-- begin right-content -->
        <div class="right-content fullvh">

            <!-- begin login-header -->
            <div class="login-header">
                <div class="brand text-center darby-logo-extra">
                        <img src="{{cmImageUrl}}assets/img/login-logo.png" alt="logo">
                </div>

            </div>
            <!-- end login-header -->
            <!-- begin login-content -->
            <div class="login-content">
                <form action="javascript:void(0);" method="POST" class="margin-bottom-0"  #f="ngForm" (ngSubmit)="onClickForgotPassword(f.valid)" novalidate>
                    <h3 class="loginC">Forgot Password</h3>

                    <div class="row mb-3">
                        <div class="col-lg-12 sm-form-design">
                            <input type="text" name="userName" id="userName"
                                   class="form-control h5-email"
                                   placeholder="Please enter your User Name"
                                   value=""
                                   autocomplete="off"
                                   tabindex="1"
                                   maxlength="35" [(ngModel)]="forgot.userName" #userNameText="ngModel" required>
                            <label class="control-label">User Name <span class="text-danger">*</span></label>
                            <div [hidden]="userNameText.valid || (userNameText.pristine && !f.submitted)">
                                <small class="text-danger">Please enter your user name</small>
                            </div>
                        </div>
                    </div>

                    <div class="login-buttons loginSubmit">
                        <button type="submit" class="btn btn-peak btn-block btn-lg border-0" [disabled]="disable">Reset Password
                        </button>
                    </div>
                     <div class="row m-b-10 m-t-15">
                        <div class="col-lg-12 text-center">
                            <label for="chkRemember">Remembered? <a routerLink="/">Click here to </a>login</label>
                        </div>
                     </div>
                     <div class="loginFooter">
                        <hr class="margTop20" />
                         <p class="text-center f-s-12">
                            &copy; {{footerText}}
                         </p>
                     </div>
                </form>
            </div>
            <!-- end login-content -->
        </div>
    </div>
</div>
