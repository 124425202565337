import { OnChanges, OnInit, SimpleChanges, NgZone, ElementRef, EventEmitter } from '@angular/core';
import { configMessage } from '@shared/config-message';
import { SharedService } from '@myproject/shared/shared.service';
import { LiveService } from '@components/live/live.service';
var ObdPidViewComponent = /** @class */ (function () {
    function ObdPidViewComponent(liveService, sharedService, ngZone) {
        this.liveService = liveService;
        this.sharedService = sharedService;
        this.ngZone = ngZone;
        this.update = new EventEmitter();
        this.drawerIsOpen = false;
        this.allConstraints = [];
        this.tempAllConstraints = configMessage.allPids;
        this.supConstraints = [];
        this.supIconConstraints = [];
        this.supportedPidOrder = [13, 12, 17, 166, 5, 15, 1];
        this.activePids = [];
        this.smallContainer = true;
        // Chart Variables
        this.visualizing = false;
        this.chartLoading = false;
        // lineChart: any = null;
        this.pidLabel = [];
        this.pid1Data = [];
        this.pid2Data = [];
        this.durationOfTrip = '';
        // initiated = false;
        // obd search
        this.searchModel = {
            value: '',
            search: false,
            searchBy: 'pidNo',
            hidden: []
        };
        this.searchheader = [
            { displayName: 'PID Number', name: 'pidNo' },
            { displayName: 'OBD Message', name: 'msg' }
        ];
        this.isLoading = false;
        this.tabPos = 0;
        this.op = null;
        this.selectedPID = [];
        this.lastDevice = {
            deviceId: {
                prev: null,
                cur: null
            },
            ts: {
                prev: null,
                cur: null
            },
            te: {
                prev: null,
                cur: null
            }
        };
        this.onSearchNoDF = false;
        this.noGraphData = false;
        this.iconPath = "../../../assets/img/OBD_Icons/";
        this.subscribedReq = [];
        this.subscribedReqOnLoad = [];
        // gps tab variables
        this.activeGPS = [];
        this.tempGPSConstraints = configMessage.gpsPids;
        // dashCam
        this.dashCamPos = 0;
        this.dashCamArr = [];
        this.selVid = {
            eventName: null,
            videoURL: null,
            initSpeed: null,
            FinalSpeed: null,
            maxBraking: null,
            camType: null,
            msgId: null
        };
        this.frontCamCount = 0;
        this.backCamCount = 0;
        this.inCabinCount = 0;
        this.videoCount = 0;
        this.dashCamVideoAvailable = true;
        this.selected = [];
        this.pidAllConstraints = [];
        this.prevPidRes = {
            collected: [],
            supported: []
        };
        this.filteredData = [];
        this.loading = {
            getSupported: true,
            graph: true
        };
        this.isHavingTrips = true;
        this.change = false;
        this.noDevices = false;
        // parameters declaring for trip component
        this.isHeightHighResolution = {
            isHigh: false,
            trip_view: true,
            msg_view: false,
            pid_view: true
        };
        this.tripViewToggle = true;
        this.disablePointerEvents = false;
    }
    ObdPidViewComponent.prototype.ngOnInit = function () {
        window["obdPid"] = this;
        this.getAllPids();
    };
    ObdPidViewComponent.prototype.ngOnChanges = function (changes) {
        // if (this.selectedDevice && this.tsTime && this.teTime && ((this.lastDevice.deviceId.prev === null || this.lastDevice.deviceId.prev !== this.selectedDevice.deviceId) || (this.lastDevice.ts.prev === null || this.lastDevice.ts.prev !== this.tsTime) || (this.lastDevice.te.prev === null || this.lastDevice.te.prev !== this.teTime))) {
        if (changes.hasOwnProperty('onDateChange') && changes.onDateChange.currentValue !== 0 && changes.onDateChange.previousValue !== changes.onDateChange.currentValue) {
            this.tsTime = null;
            this.teTime = null;
            this.isHavingTrips = false;
            document.getElementById('legend').innerHTML = '';
            document.getElementById('chartcontainer').innerHTML = '';
            if (this.selected.length) {
                this.resetOBDPids();
                this.defaultOBDPids();
                this.searchModel.value = '';
            }
            if (this.activeGPS.length) {
                this.resetGPS();
            }
        }
        if (changes.hasOwnProperty('tsTime') && changes.tsTime.currentValue !== null && changes.tsTime.previousValue !== changes.tsTime.currentValue) {
            this.tsTime = changes.tsTime.currentValue;
        }
        if (changes.hasOwnProperty('teTime') && changes.teTime.currentValue !== null && changes.teTime.previousValue !== changes.teTime.currentValue) {
            this.teTime = changes.teTime.currentValue;
        }
        if (changes.hasOwnProperty('selectedDevice') && changes.selectedDevice.currentValue && Object.keys(changes.selectedDevice.currentValue).length !== 0 && ((changes.selectedDevice.firstChange) || (changes.selectedDevice.previousValue.deviceId !== changes.selectedDevice.currentValue.deviceId))) {
            this.selectedDevice = changes.selectedDevice.currentValue;
            if (!changes.selectedDevice.firstChange && this.isHistoryToggleOn) {
                this.tsTime = null;
                this.teTime = null;
            }
        }
        // if (changes.hasOwnProperty('vehicleChange') && changes.vehicleChange.currentValue !== 0 && changes.vehicleChange.previousValue !== changes.vehicleChange.currentValue) {
        //   this.tsTime = null;
        //   this.teTime = null;
        // }
        // this.tsTime = 1663116370000;
        // this.teTime = 1663202770000;
        if (this.areParametersValid()) {
            this.isHavingTrips = true;
            if (this.subscribedReqOnLoad.length) {
                this.subscribedReqOnLoad[0].unsubscribe();
                this.subscribedReqOnLoad = [];
            }
            this.noGraphData = false;
            this.loading.graph = true;
            this.dashCamPos = 0;
            this.filteredData = [];
            document.getElementById('legend').innerHTML = '';
            document.getElementById('chartcontainer').innerHTML = '';
            // const chart = document.getElementById('chartcontainer');
            // if (chart) { chart.style.display = 'none'; }
            if (this.activePids.length) {
                this.resetOBDPids();
                this.searchModel.value = '';
            }
            if (this.activeGPS.length) {
                this.resetGPS();
            }
            // this.initiated = !this.initiated;
            this.lastDevice.deviceId.prev = this.selectedDevice.deviceId;
            this.lastDevice.ts.prev = this.tsTime;
            this.lastDevice.te.prev = this.teTime;
            this.getDashcam();
            if (this.tabPos === 1) {
                this.activeGPS.push('ALTITUDE');
                this.activeGPS.push('COG');
                this.update.emit({
                    tabPos: this.tabPos,
                    selected: this.activeGPS,
                    device: this.selectedDevice,
                    tsTime: this.tsTime,
                    teTime: this.teTime
                });
                // this.renderGraphOnTab(); 
            }
            else if (this.tabPos === 0) {
                this.getSupportedPids();
            }
        }
        else if (changes.hasOwnProperty('selectedDevice') && (changes.selectedDevice.currentValue === null || changes.selectedDevice.currentValue === undefined)) {
            this.noDevices = true;
            this.tabPos = -1;
        }
    };
    ObdPidViewComponent.prototype.areParametersValid = function () {
        if (!this.selectedDevice || !Object.keys(this.selectedDevice).length) {
            return false;
        }
        else if ((!this.tsTime || !this.teTime)) {
            // alert('No Active Trips on selected Date Range');
            return false;
        }
        return true;
    };
    ObdPidViewComponent.prototype.renderGraphOnTab = function () {
        if (this.areParametersValid()) {
            this.loading.graph = true;
            if (this.tabPos === 0) {
                this.getSupportedPids();
            }
            else if (this.tabPos === 1) {
                this.activeGPS = [];
                this.activeGPS.push('ALTITUDE');
                this.activeGPS.push('COG');
                this.update.emit({
                    tabPos: this.tabPos,
                    selected: this.activeGPS,
                    device: this.selectedDevice,
                    tsTime: this.tsTime,
                    teTime: this.teTime
                });
                // this.getChartData('gps');
            }
            else {
                this.loading.graph = false;
                this.dashCamVideoAvailable = this.checkVideoAvailable('FRONT');
                this.update.emit({
                    tabPos: this.tabPos,
                    selected: [],
                    selectedPidIds: [],
                    device: this.selectedDevice,
                    tsTime: this.tsTime,
                    teTime: this.teTime,
                    dashCam: this.dashCamArr.length ? this.dashCamArr[0] : undefined
                });
            }
        }
    };
    // Open Close Drawer
    ObdPidViewComponent.prototype.toggleDrawer = function () {
        this.drawerIsOpen = !this.drawerIsOpen;
        this.drawerIsOpen ? $('#drawerSlideButton').css('top', '10.5rem') : $('#drawerSlideButton').css('top', '15.3rem');
        this.smallContainer = true;
    };
    ObdPidViewComponent.prototype.getAllPids = function () {
        var _this = this;
        Object.keys(this.tempAllConstraints).map(function (key) {
            _this.allConstraints.push(_this.tempAllConstraints[key]);
        });
        localStorage.setItem('allPids', JSON.stringify(this.allConstraints));
    };
    ObdPidViewComponent.prototype.getDashcam = function () {
        var _this = this;
        this.loading.graph = true;
        this.dashCamVideoAvailable = true;
        this.sharedService.getDashcam(this.selectedDevice.deviceId, this.tsTime, this.teTime)
            .subscribe(function (res) {
            _this.loading.graph = false;
            if (res && res.length) {
                _this.dashCamArr = res;
                _this.dashCamVideoAvailable = true;
                _this.videoCount = res.length;
                _this.onVideoClick(res[0]);
                _this.frontCamCount = 0;
                _this.backCamCount = 0;
                _this.inCabinCount = 0;
                for (var i = 0; i < res.length; i++) {
                    if (res[i].camType === 'FRONT') {
                        _this.frontCamCount++;
                    }
                    else if (res[i].camType === 'BACK') {
                        _this.backCamCount++;
                    }
                    else if (res[i].camType === 'INCABIN') {
                        _this.inCabinCount++;
                    }
                }
            }
            else {
                _this.dashCamArr = [];
                _this.frontCamCount = 0;
                _this.backCamCount = 0;
                _this.inCabinCount = 0;
                _this.videoCount = 0;
                _this.dashCamVideoAvailable = false;
            }
            _this.update.emit({
                tabPos: _this.tabPos,
                selected: _this.tabPos === 1 ? _this.activeGPS : _this.selected,
                selectedPidIds: !_this.selected.length ? [] : _this.selected.length === 1 ? [_this.selected[0].pid] : [_this.selected[0].pid, _this.selected[1].pid],
                device: _this.selectedDevice,
                tsTime: _this.tsTime,
                teTime: _this.teTime,
                dashCam: res[0]
            });
        }, function (error) {
            _this.loading.graph = false;
            _this.dashCamVideoAvailable = false;
            _this.dashCamArr = [];
            _this.frontCamCount = 0;
            _this.backCamCount = 0;
            _this.videoCount = 0;
        });
    };
    ObdPidViewComponent.prototype.getSupportedPids = function () {
        var _this = this;
        this.loading.graph = true;
        var req = this.liveService.getSupportedPids(this.selectedDevice.deviceId, this.tsTime, this.teTime)
            .subscribe(function (res) {
            var supported = res.supported;
            var collected = res.collected;
            _this.pidAllConstraints = [];
            _this.filteredData = [];
            _this.selected = [];
            _this.loading.getSupported = false;
            _this.loading.graph = false;
            if (_this.prevPidRes.collected.length) {
                _this.patchOBDJson(_this.prevPidRes.collected, 'collected', false);
            }
            if (_this.prevPidRes.supported.length) {
                _this.patchOBDJson(_this.prevPidRes.supported, 'supported', false);
            }
            if (supported && supported.length) {
                _this.patchOBDJson(supported, 'supported', true);
            }
            if (collected && collected.length) {
                var tempArr = [];
                _this.patchOBDJson(collected, 'collected', true);
                for (var i = 0; i < _this.supportedPidOrder.length; i++) {
                    var val = _this.supportedPidOrder[i];
                    var id = collected.indexOf(val);
                    if (id >= 0) {
                        tempArr.push(val);
                        collected.splice(id, 1);
                    }
                }
                for (var k = (tempArr.length - 1); k >= 0; k--) {
                    collected.unshift(tempArr[k]);
                }
                collected.map(function (item) {
                    var obj = _this.tempAllConstraints["PID_" + item];
                    _this.pidAllConstraints.push(obj);
                });
                for (var pid in _this.tempAllConstraints) {
                    if (collected.indexOf(_this.tempAllConstraints[pid].pid) === -1) {
                        _this.pidAllConstraints.push(_this.tempAllConstraints[pid]);
                    }
                }
                if (collected.length > 1) {
                    _this.selected.push(_this.pidAllConstraints[0]);
                    _this.selected.push(_this.pidAllConstraints[1]);
                }
                else {
                    _this.selected.push(_this.pidAllConstraints[0]);
                }
                _this.filteredData = _this.pidAllConstraints;
                // this.getChartData(this.tabPos === 0 ? 'pids' : 'gps');
            }
            else {
                _this.defaultOBDPids();
                // const responseMessage = { success: false, message: `No Supported PID's for this trip!` };
                // this.sharedService.setResponseMessage(responseMessage);
            }
            _this.update.emit({
                tabPos: _this.tabPos,
                selected: _this.selected,
                selectedPidIds: !_this.selected.length ? [] : _this.selected.length === 1 ? [_this.selected[0].pid] : [_this.selected[0].pid, _this.selected[1].pid],
                device: _this.selectedDevice,
                tsTime: _this.tsTime,
                teTime: _this.teTime
            });
        }, function (error) {
            _this.loading.getSupported = false;
            _this.loading.graph = false;
            // const responseMessage = { success: false, message: 'Something went wrong while retrieving Supported Pids!' };
            // this.sharedService.setResponseMessage(responseMessage);
        });
        this.subscribedReqOnLoad.push(req);
    };
    ObdPidViewComponent.prototype.defaultOBDPids = function () {
        this.allConstraints = JSON.parse(localStorage.getItem('allPids'));
        this.noGraphData = true;
        for (var pid in this.tempAllConstraints) {
            this.tempAllConstraints[pid]['supported'] = false;
            this.tempAllConstraints[pid]['collected'] = false;
            this.filteredData.push(this.tempAllConstraints[pid]);
        }
        this.pidAllConstraints = this.filteredData;
    };
    ObdPidViewComponent.prototype.patchOBDJson = function (obj, param, reset) {
        var _this = this;
        var prevLen = this.prevPidRes[param].length;
        obj.map(function (item) {
            var obj = _this.tempAllConstraints["PID_" + item];
            obj[param] = reset;
            if (reset) {
                _this.prevPidRes[param].push(item);
            }
        });
        if (!reset) {
            this.prevPidRes[param].splice(0, prevLen);
        }
    };
    ObdPidViewComponent.prototype.activateTablePid = function (_a) {
        var selected = _a.selected;
        if (this.selected.length === 3) {
            this.selected.splice(0, 1);
        }
        if (this.areParametersValid()) {
            this.update.emit({
                tabPos: this.tabPos,
                selected: this.selected,
                selectedPidIds: !this.selected.length ? [] : this.selected.length === 1 ? [this.selected[0].pid] : [this.selected[0].pid, this.selected[1].pid],
                device: this.selectedDevice,
                tsTime: this.tsTime,
                teTime: this.teTime
            });
        }
        else {
            this.selected = [];
        }
    };
    ObdPidViewComponent.prototype.compareStrings = function (a, b) {
        a = a.toLowerCase();
        b = b.toLowerCase();
        return (a < b) ? -1 : (a > b) ? 1 : 0;
    };
    ObdPidViewComponent.prototype.loadFirstVideo = function (p1) {
        this.loading.graph = false;
        var str = p1 === 0 ? 'FRONT' : p1 === 1 ? 'BACK' : 'INCABIN';
        this.dashCamVideoAvailable = this.checkVideoAvailable(str);
        if (!this.dashCamVideoAvailable) {
            this.update.emit({
                tabPos: this.tabPos,
                selected: this.selected,
                selectedPidIds: !this.selected.length ? [] : this.selected.length === 1 ? [this.selected[0].pid] : [this.selected[0].pid, this.selected[1].pid],
                device: this.selectedDevice,
                tsTime: this.tsTime,
                teTime: this.teTime,
                dashCam: undefined
            });
        }
    };
    ObdPidViewComponent.prototype.checkVideoAvailable = function (str) {
        for (var d = 0; d < this.dashCamArr.length; d++) {
            if (this.dashCamArr[d].camType === str) {
                this.onVideoClick(this.dashCamArr[d]);
                return true;
            }
        }
        return false;
    };
    ObdPidViewComponent.prototype.onVideoClick = function (p1) {
        this.selVid.eventName = p1.eventName;
        this.selVid.msgId = p1.messageId;
        // this.selVid.videoURL = p1.videoURL;
        // this.selVid.initSpeed = p1.initSpeed;
        // this.selVid.FinalSpeed = p1.FinalSpeed;
        // this.selVid.maxBraking = p1.maxBraking;
        // this.currentPlaying.nativeElement.src = p1.videoURL;
        // this.currentPlaying.nativeElement.playsInline();
        // this.currentPlaying.nativeElement.play();
        this.selVid.camType = p1.camType === 'FRONT' ? 'Front' : p1.camType === 'BACK' ? 'Back' : 'In Cabin';
        this.update.emit({
            tabPos: this.tabPos,
            selected: this.selected,
            selectedPidIds: !this.selected.length ? [] : this.selected.length === 1 ? [this.selected[0].pid] : [this.selected[0].pid, this.selected[1].pid],
            device: this.selectedDevice,
            tsTime: this.tsTime,
            teTime: this.teTime,
            dashCam: p1
        });
    };
    ObdPidViewComponent.prototype.getDashcamTitle = function (p1) {
        if (p1) {
            var str = p1.split('_');
            return str[0] + ' ' + str[1];
        }
    };
    ObdPidViewComponent.prototype.resetOBDPids = function () {
        this.activePids = [];
        this.pid1Data = [];
        this.pid2Data = [];
        this.pidLabel = [];
        this.loading.graph = false;
        this.durationOfTrip = '';
        this.selected = [];
        // if (this.lineChart) { this.lineChart.destroy(); document.getElementById('legend').innerHTML = ''; }
    };
    ObdPidViewComponent.prototype.resetGPS = function () {
        this.activeGPS = [];
        this.pid1Data = [];
        this.pid2Data = [];
        this.pidLabel = [];
        this.loading.graph = false;
        this.durationOfTrip = '';
        // if (this.lineChart) { this.lineChart.destroy(); document.getElementById('legend').innerHTML = ''; }
    };
    ObdPidViewComponent.prototype.getId = function (i, item) {
        return item.title + i.toString();
    };
    ObdPidViewComponent.prototype.setHeight = function () {
        var leftColHeight = $('#leftCol' + (this.activeTab + 1)).height();
        var navHeight = $('.nav.nav-tabs.detailed-tabs').height();
        var footer = $('footer').height();
        if (this.tabPos === 0) {
            var searchBarHeight = $('.input-group.input-group-lg.searchVeh.liveSearch').height();
            $('#pidTable > div > .datatable-body').css('height', leftColHeight - (navHeight + searchBarHeight + footer + 30));
        }
        else if (this.tabPos === 1) {
            $('#gps').css('height', leftColHeight - (navHeight));
        }
        else {
            var camRow = $('.cambtn').height();
            $('#cam').css('height', leftColHeight - (camRow + 7));
        }
    };
    ObdPidViewComponent.prototype.onSearch = function () {
        this.onSearchNoDF = false;
        var inputVal = this.searchModel.value.trim();
        if (inputVal) {
            var val = inputVal.toLowerCase();
            var searchStr = val.replace(/[^a-z]/gi, '');
            var searchNo = parseInt(val, 10);
            var searchModel = this.searchModel;
            if (searchModel.searchBy === 'pidNo') {
                this.filterOnSearch(searchNo, true);
            }
            else if (searchModel.searchBy === 'msg') {
                this.filterOnSearch(val, false);
            }
        }
        else {
            this.filteredData = this.pidAllConstraints;
        }
    };
    ObdPidViewComponent.prototype.filterOnSearch = function (searchParam, searchByPidNo) {
        var _this = this;
        this.filteredData = [];
        // this.selected = [];
        this.pidAllConstraints.map(function (supPid) {
            var pidNoStr = supPid.pid.toString();
            if ((searchByPidNo && pidNoStr.includes(searchParam)) || (!searchByPidNo && (supPid.description.toLowerCase()).includes(searchParam))) {
                _this.filteredData.push(supPid);
            }
        });
        if (!this.filteredData.length) {
            this.onSearchNoDF = true;
        }
    };
    ObdPidViewComponent.prototype.revertHiddenPids = function () {
        this.searchModel.hidden.map(function (id) {
            $('#pid' + id).show();
        });
        this.searchModel.hidden = [];
    };
    // methods for gps tab
    ObdPidViewComponent.prototype.activeGPSHndlr = function (p1) {
        if (this.areParametersValid()) {
            this.disablePointerEvents = true;
            if (this.activeGPS.includes(p1)) {
                // if (this.lineChart) { this.lineChart.destroy(); document.getElementById('legend').innerHTML = ''; }
                var index = this.activeGPS.indexOf(p1);
                this.activeGPS.splice(index, 1);
                // if (this.activeGPS.length)
                // this.getChartData('gps');
            }
            else if (this.activeGPS.length < 2) {
                this.activeGPS.push(p1);
                // if (this.lineChart) { this.lineChart.destroy(); document.getElementById('legend').innerHTML = ''; }
                // this.getChartData('gps');
            }
            else if (this.activeGPS.length === 2) {
                this.activeGPS.splice(0, 1);
                this.activeGPS.push(p1);
                // if (this.lineChart) { this.lineChart.destroy(); document.getElementById('legend').innerHTML = ''; }
                // this.getChartData('gps');
            }
        }
        else {
            this.activeGPS = [];
        }
        if (!this.activeGPS.length) {
            this.disablePointerEvents = false;
        }
        this.update.emit({
            tabPos: this.tabPos,
            selected: this.activeGPS,
            device: this.selectedDevice,
            tsTime: this.tsTime,
            teTime: this.teTime,
            disablePointerEvents: this.disablePointerEvents
        });
    };
    ObdPidViewComponent.prototype.resetSearch = function () {
        this.searchModel.value = '';
        this.filteredData = this.pidAllConstraints;
        this.onSearchNoDF = false;
    };
    return ObdPidViewComponent;
}());
export { ObdPidViewComponent };
