<app-notify-message></app-notify-message>
<app-reports-header></app-reports-header>
<div class="row no-gutters reports-page">
  <div class="col-md-2 col-sm-0 p-0">
    <app-reports-side-menu></app-reports-side-menu>
  </div>
  <div class="reports-body col-md-10 col-sm-12 p-0">
    <div class="row no-gutters action-row align-items-center px-2">
      <div class="col-lg-3 col-md-12">
        <h4 class="report-heading page-heading"><i class="fa fa-bar-chart"></i> Log-in Activity</h4>
      </div>
      <div class="col-lg-9 col-md-12">
        <div class="row align-items-center">
          <div class="col-4 col-md-2 text-right pr-0">
            <span class="d-inline-block">Select User</span>
          </div>
          <div class="col-8 col-md-3">
            <ng-select
              class="width100 select-radius"
              [items]="users"
              [multiple]="false"
              [closeOnSelect]="true"
              name="user"
              bindLabel="firstName"
              bindValue="userId"
              [(ngModel)]="searchModel.userId"
              placeholder="Select User"
              #userId="ngModel"
              (change)="getData(0)"
              required="">
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <i class="fa fa-user pr-1"></i>
                <b>{{item.firstName}} {{item.lastName}}</b>
              </ng-template>
            </ng-select>
          </div>
          <div class="col-md-7 d-flex mobile-design justify-content-end">
            <div class="calendar d-inline-block ml-2">
              <div class="d-inline-block" (clickOutside)="hideCalendar=!hideCalendar;">
                <app-date-time-calendar
                  [clickOutside]="hideCalendar"
                  [selectPreset]="'30days'"
                  (timeChangeEvent)="onTimeChange($event)"
                  >
                </app-date-time-calendar>
              </div>
              <button
                class="btn btn-icon ml-2 refresh"
                (click)="refresh()"
                tooltip="Refresh"
                placement="top"
                [disabled]="pageLoading">
                <i class="fa fa-refresh" aria-hidden="true"></i>
                <span class="mob-access-text d-none">&nbsp;Refresh</span>
              </button>
              <button
                class="btn btn-icon ml-2 download"
                tooltip="Download" placement="top"
                (click)="downloadCsv()"
                [disabled]="!activity.length">
                <i class="fa fa-cloud-download"></i>
                <span class="mob-access-text d-none">&nbsp;Download</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-md-12 px-2 py-3">
        <div class="report-item-container">
          <div class="page-loading" *ngIf="pageLoading">
            <img src="../../../assets/img/darby_loader_small.gif"/>
          </div>
          <div *ngIf="!activity.length && !pageLoading" class="text-center mt-5 py-5">
            <img width="170" src="../../../assets/img/info_nodata_256.png"/>
            <h4 class="mt-3">No data to show</h4>
          </div>
          <ngx-datatable *ngIf="activity.length"
            id="tableau" #dataTable
            class="bootstrap alertTable reportsTable wrapcell"
            [rows]="activity"
            [columnMode]="'force'"
            [headerHeight]="50"
            [footerHeight]="50"
            [rowHeight]="'auto'"
            [summaryRow]="false"
            [scrollbarH]="true"
            [reorderable]="true"
            [limit]="pageInfo.limit"
            [offset]="pageInfo.offset"
            [count]="pageInfo.count"
            (page)="pageCallback($event)"
            >
            <ngx-datatable-column [resizeable]="false" [sortable]="false" name="#" [width]="50">
              <ng-template let-rowIndex="rowIndex" ngx-datatable-cell-template>
                {{rowIndex+1}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Session Id">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.sessionId}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Login Time" >
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.loginTime | date: dateFormat}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Logout Time">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.logOutTime | date: dateFormat}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Duration" >
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.duration | secondsToHms: 'milliseconds'}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Source" >
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.source}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Client">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.client}}
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
