<!-- BEGIN MAIN WRAPPER -->
<div class="body-wrap">
        <app-notify-message></app-notify-message>
    <div class="login login-v3">
        <!-- begin news-feed -->
        <div class="news-feed">
            <div class="news-image">

            </div>

        </div>
        <!-- end news-feed -->
        <!-- begin right-content -->
        <div class="right-content fullvh">
            <!-- begin login-header -->
            <div class="login-header">
                <div class="brand text-center darby-logo-extra">
                    <img src="{{cmImageUrl}}assets/img/login-logo.png" alt="logo">
                </div>

            </div>
            <!-- end login-header -->
            <!-- begin login-content -->
            <div class="login-content">
                <form action="javascript:void(0);" method="POST" #f="ngForm" class="margin-bottom-0" (ngSubmit)="onClickResetPassword(f.valid)" novalidate>
                    <h3 class="loginC">SET PASSWORD</h3>

                    <div class="row mb-3">
                        <div class="col-lg-12 sm-form-design">
                            <i (click)="viewNewPassword()" class="fa fa-eye show-password"></i>
                            <input type="{{ NewPassword ? 'text' : 'password' }}" name="newPassword" id="newPassword"
                                   class="form-control h5-email"
                                   placeholder="Please enter your new password"
                                   value=""
                                   autocomplete="off"
                                   tabindex="2"
                                   maxlength="46" [(ngModel)]="userDetails.newPassword" #newPassword="ngModel" required="required">
                            <label class="control-label" >NEW PASSWORD <span class="text-danger">*</span></label>
                            <div [hidden]="newPassword.valid || (newPassword.pristine && !f.submitted)">
                                <small class="text-danger">Please enter new Password</small>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-12 sm-form-design">
                            <i (click)="viewConfirmPassword()" class="fa fa-eye show-password"></i>
                            <input type="{{ ConfirmPassword ? 'text' : 'password' }}" name="password" id="cf_password"
                                   class="form-control h5-email"
                                   placeholder="Please enter your confirm password"
                                   value=""
                                   autocomplete="off"
                                   tabindex="2"
                                   (keyup) = confirmPass();
                                   maxlength="46" [(ngModel)]="userDetails.confirmPassword" #confirmPassword="ngModel" required>
                            <label class="control-label">CONFIRM PASSWORD <span class="text-danger">*</span></label>
                            <div [hidden]="confirmPassword.valid || (confirmPassword.pristine && !f.submitted)">
                                <small class="text-danger">Please enter Confirm Password</small>
                            </div>
                          <div *ngIf="confirmNewPassword && userDetails.confirmPassword">
                            <small class="text-danger">Password does not match</small>
                          </div>
                        </div>
                    </div>

                    <div class="login-buttons loginSubmit">
                        <input type="submit" class="btn btn-primary btn-peak btn-block btn-lg border-0" value="SET PASSWORD">
                    </div>

                    <div class="loginFooter">
                        <hr class="margTop20" />
                         <p class="text-center f-s-12">
                            &copy; {{footerText}}
                         </p>
                     </div>
                </form>
            </div>
            <!-- end login-content -->
        </div>
        <!-- end right-container -->
    </div>
</div>
<!-- END MAIN WRAPPER -->
