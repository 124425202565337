import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '@myenv/environment';
var VehicleService = /** @class */ (function () {
    // sideData = [];
    // uploadVehicle = {
    //   name: 'menu.AddNewVehicle',
    //   img: 'plus',
    //   route: '/vehicles/upload'
    // };
    // uploadLogs = {
    //   name: 'menu.UploadLogs',
    //   img: 'history',
    //   route: '/vehicles/upload-logs'
    // };
    // addedVehicle = {
    //   name: 'menu.RegisteredVehicles',
    //   img: 'clipboard',
    //   route: '/vehicles'
    // };
    // getSideMenu() {
    //   this.sideData = [];
    //   const moduleName = 'VEHICLE';
    //   const modules = JSON.parse(localStorage.getItem('accessRoleModules'));
    //   const permissionAuthorities = modules.find(module => module.name === moduleName);
    //   const write = permissionAuthorities.authorities.includes('WRITE');
    //   if (write) {
    //     this.sideData.push(this.addedVehicle);
    //     this.sideData.push(this.uploadVehicle);
    //     this.sideData.push(this.uploadLogs);
    //   } else {
    //     this.sideData.push(this.addedVehicle);
    //   }
    //   return this.sideData;
    // }
    function VehicleService(http, router, handler) {
        this.http = http;
        this.router = router;
        this.handler = handler;
        this.apiUrl = environment.apiUrl;
        this.httpClient = new HttpClient(handler);
    }
    VehicleService.prototype.getAllVehicles = function (page, data, key, value) {
        var tempUrl = "vehicles?page=" + page;
        if (key && value) {
            tempUrl = tempUrl + ("&key=" + key + "&value=" + value);
        }
        if (data.prop) {
            tempUrl = tempUrl + ("&sortingKey=" + data.prop + "&direction=" + data.dir);
        }
        return this.http.get(this.apiUrl + tempUrl).pipe(catchError(this.handleError));
    };
    VehicleService.prototype.downloadUploadLog = function (logId) {
        return this.http.get(this.apiUrl + 'vehicles/upload/logs/' + logId + '/download', {
            responseType: 'arraybuffer',
            observe: 'response'
        }).pipe(catchError(this.handleError));
    };
    VehicleService.prototype.downloadVehicleXML = function (searchData, vehicleStatusFilterObj, vehicleGroupFilterkey) {
        var tempUrl = this.apiUrl + 'vehicles/download?type=xml';
        if (searchData.vehicleTypeVal) {
            tempUrl += '&key=' + searchData.deviceId + '&value=' + searchData.vehicleTypeVal;
        }
        if (vehicleStatusFilterObj) {
            tempUrl += '&status=' + vehicleStatusFilterObj;
        }
        if (vehicleGroupFilterkey) {
            tempUrl += '&groups=' + vehicleGroupFilterkey;
        }
        return this.http.get(tempUrl, { responseType: 'arraybuffer', observe: 'response' }).pipe(catchError(this.handleError));
    };
    VehicleService.prototype.getDeviceCount = function () {
        return this.http.get(this.apiUrl + 'vehicles/all/count').pipe(catchError(this.handleError));
    };
    VehicleService.prototype.downloadVehicleCSV = function (searchData, vehicleStatusFilterObj, vehicleGroupFilterkey) {
        var tempUrl = this.apiUrl + 'vehicles/download?type=csv';
        if (searchData.vehicleTypeVal) {
            tempUrl += '&key=' + searchData.deviceId + '&value=' + searchData.vehicleTypeVal;
        }
        if (vehicleStatusFilterObj) {
            tempUrl += '&status=' + vehicleStatusFilterObj;
        }
        if (vehicleGroupFilterkey) {
            tempUrl += '&groups=' + vehicleGroupFilterkey;
        }
        return this.http.get(tempUrl, { responseType: 'arraybuffer', observe: 'response' }).pipe(catchError(this.handleError));
    };
    VehicleService.prototype.updateVehicle = function (vehicle) {
        return this.http.put(this.apiUrl + 'vehicles/' + vehicle.deviceId, vehicle).pipe(catchError(this.handleError));
    };
    VehicleService.prototype.downloadActivityCSV = function (deviceId) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
        });
        return this.http.get(this.apiUrl + 'activity/download/identifier/' + deviceId, { responseType: 'arraybuffer', observe: 'response', headers: headers }).pipe(catchError(this.handleError));
    };
    VehicleService.prototype.handleError = function (error) {
        return observableThrowError(error.error || error.json() || error || 'Server error');
    };
    return VehicleService;
}());
export { VehicleService };
