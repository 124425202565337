<div class="container-fluid">
  <div class="row">
    <div class="col-sm-10 mx-auto">
      <div class="widget-container mt-5">
        <h5 class="headingAdd font400 widget-heading">Contact Us</h5>
        <div class="row">
          <div class="col-sm-4 img-container">
            <img src="assets/img/contact-us.jpg">
          </div>
          <div class="col-sm-8">
            <h5 class="page-heading mt-3">
              Let us help you!
            </h5>
            <p>We're in this together and personalized care is just a call or click away!</p>
            <p><b>Drop us an email:</b></p>
            <p><a class="cursor-pointer color" [href]="'mailto:'+contactEmail">{{contactEmail}}</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
