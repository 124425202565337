import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "@myenv/environment";
var FooterComponent = /** @class */ (function () {
    function FooterComponent(http) {
        this.http = http;
        this.footerImageUrl = environment.cmImageUrl;
        this.footerText = environment.footerText;
        this.footerLeft = environment.footerLeft;
        this.footerRight = environment.footerRight;
        this.footerText1 = environment.footerText1;
        this.footerText2 = environment.footerText2;
        this.footerText3 = environment.footerText3;
        this.randomNumber = Math.random();
        this.changeImg = true;
        this.versionnumber = environment.version;
    }
    FooterComponent.prototype.ngOnInit = function () {
        // const content = require('./../../../assets/version.json');
        // this.http.get('assets/version.txt', {responseType: 'text'})
        // .subscribe(data => {
        //   this.buildInfo = data;
        // });
        var autogenerateversion = require('./../../../assets/buildversion.json');
        this.buildInfo = autogenerateversion.buildversion;
        // this.buildInfo = content.buildInfo;
        this.supportNumber = environment.supportNumber;
        var data = localStorage.getItem('currentUser');
        var x = JSON.parse(data);
        this.accountKey = x.accountKey;
    };
    return FooterComponent;
}());
export { FooterComponent };
