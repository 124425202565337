import { Injectable } from '@angular/core';

@Injectable()
export class ScriptService {

  constructor() { }

  public loadScript(dynamicScripts) {
    var isFound = false;
    var scripts = document.getElementsByTagName("script")
    for (var i = 0; i < scripts.length; ++i) {
        if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
            isFound = true;
        }
    }

    if (!isFound) {
        for (var i = 0; i < dynamicScripts .length; i++) {
            let node = document.createElement('script');
            node.type = 'text/javascript';
            node.charset = 'utf-8';
            node.async = true;
            node.src = dynamicScripts [i];
            document.getElementsByTagName('head')[0].appendChild(node);
        }

    }

}

loadCSS(urls) {

    for (var url = 0; i < urls.length; ++i) {
    // Create link
    let link = document.createElement('link');
    link.href = url[i];
    link.rel = 'stylesheet';
    link.type = 'text/css';

    let head = document.getElementsByTagName('head')[0];
    let links = head.getElementsByTagName('link');
    let style = head.getElementsByTagName('style')[0];

    // Check if the same style sheet has been loaded already.
    let isLoaded = false;
    for (var i = 0; i < links.length; i++) {
      var node = links[i];
      if (node.href.indexOf(link.href) > -1) {
        isLoaded = true;
      }
    }
    if (isLoaded) return;
    head.insertBefore(link, style);
  }
}

}
