<app-notify-message></app-notify-message>
<app-reports-header></app-reports-header>
<div class="tab-content">
  <div role="tabpanel" class="tab-pane fade in active show relative" id="general">
    <div class="row no-gutters reports-page">
      <div class="p-0 col-sm-0" [ngClass]="{'col-md-2': !isSideMenuClose, 'no-width': isSideMenuClose}">
        <app-reports-side-menu></app-reports-side-menu>
      </div>
      <div class="reports-body col-sm-12 p-0" [ngClass]="{'col-md-10': !isSideMenuClose, 'col-md-12': isSideMenuClose}">
        <!-- Collapse/Expand reports side menu -->
        <div class="toggle-collapse-wrapper">
          <span
            *ngIf="!isSideMenuClose"
            tooltip="Collapse Side Panel"
            placement="right"
            (click)="onSideMenuToggle()"
            class="toggleBtn badge badge-secondary">
            <i class="fa fa-angle-double-left font-14" aria-hidden="true"></i>
          </span>
          <span
            *ngIf="isSideMenuClose"
            tooltip="Expand Side Panel"
            placement="right"
            (click)="onSideMenuToggle()"
            class="toggleBtn badge badge-secondary">
            <i class="fa fa-angle-double-right font-14" aria-hidden="true"></i>
          </span>
        </div>
        <!-- Main report content with the action row -->
        <div class="row no-gutters action-row align-items-center px-2">
          <div class="col-md-2">
            <h4 class="report-heading page-heading"><i class="fa fa-bar-chart"></i> Messages</h4>
          </div>
          <div class="col-md-10 text-right">
            <app-search-select-device
              [defaultselectdevice]="true"
              [autoSelect]="true"
              [selectSearchCategory]="true"
              [querySelectDevice]="querySelectVehicle"
              (deviceChangeEvent)="changeVehicle($event)"
              >
            </app-search-select-device>
            <div class="d-inline-block calendar ml-2" (clickOutside)="hideCalendar=!hideCalendar;">
              <app-date-time-calendar
                [clickOutside]="hideCalendar"
                [selectPreset]="'30days'"
                [vehicle]="selectedVehicle"
                [timeSelection]="false"
                [hideLinks]="['today', '15mins', '30mins', '1hour', '4hours', '12hours', '90days', '180days', '365days']"
                (timeChangeEvent)="onTimeChange($event)"
              >
              </app-date-time-calendar>
            </div>
            <button
              class="btn btn-icon ml-2 download"
              (click)="downloadCSV()"
              tooltip="Download CSV"
              placement="top"
              [disabled]="isLoading || !selectedVehicle">
              <i class="fa fa-cloud-download" aria-hidden="true"></i>
              <span class="mob-access-text d-none">&nbsp;Download</span>
            </button>
            <button
              class="btn btn-icon ml-2 refresh"
              (click)="refresh()"
              tooltip="Refresh"
              placement="top"
              [disabled]="isLoading">
              <i class="fa fa-refresh" aria-hidden="true"></i>
              <span class="mob-access-text d-none">&nbsp;Refresh</span>
            </button>
          </div>
        </div>
        <div class="row no-gutters">
          <div class="col-md-12 px-2 py-3">
            <div class="report-item-container">
              <div class="d-inline-block pull-left">
                <h6>Messages Count</h6>
              </div>
              <div class="d-inline-block pull-right">
                <label class="d-inline">Show Totals &nbsp;</label>
                <label class="toggle-switch m-0">
                  <input
                    type="checkbox"
                    name="enabled"
                    autocomplete="off"
                    [checked]="showTotalChart"
                    [disabled]="isLoading || !selectedVehicle"
                    [(ngModel)]="showTotalChart"
                    (change)="toggleChart()"
                  />
                  <span class="toggle-switch-slider round"></span>
                </label>
              </div>
              <canvas id="stackedBarGraph" height="150" role="img"></canvas>
              <!-- <div class="mt-2">
                <div id="js-legend" class="chart-legend"></div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <app-notify-message></app-notify-message>
<div class="row no-gutters reports-page">
  <div class="col-md-2 col-sm-0 p-0">
    <app-reports-side-menu></app-reports-side-menu>
  </div>
  <div class="reports-body col-md-10 col-sm-12 p-0">
    
  </div>
</div> -->
