import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '@myenv/environment';

@Injectable({
  providedIn: 'root'
})
export class AlReportGuard implements CanActivate {

  public AL_SystemReport = environment.AL_SystemReport;

  constructor(private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.AL_SystemReport) {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }

}
