import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SharedService } from '@shared/shared.service';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

import { configMessage } from '@shared/config-message';
import { environment } from '@myenv/environment';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { GraphicReportsService } from "@components/graphic-reports/graphic-reports.service";

@Component({
  selector: 'app-message-report',
  templateUrl: './message-report.component.html',
  styleUrls: ['./message-report.component.scss']
})
export class MessageReportComponent implements OnInit {
  public apiUrl = environment.apiUrl;
  public dateFormat = environment.largeDateFormat;
  settings: any;
  dateAttributes = configMessage.dateAttributes;
  maxDate: Date = new Date();
  data: any = [];
  groups: any;
  temp = [];
  reportsMenu: any = [];
  vehicles: any = [];
  devices: any = [];
  currentReport: any = {};
  querySelectDevice = null;
  querySelectVehicle = null;
  page = 0;
  isLoading = false;
  noReports = false;
  public report: any = {};
  public columMenu: any = [];
  public displayColumns: any = [];
  currentPageCount: any = {
    start: 0,
    end: 0
  };
  startTime: any;
  endTime: any;
  searchModel: any = {
    value: null,
    vehicle: null,
    device: null
  };
  selectedItem: any;
  public reportId: string = null;
  pageInfo = {
    offset: 0,
    count: 0,
    limit: Number(localStorage.getItem('messagesReportRows')) || 10,
    pageSize: Number(localStorage.getItem('messagesReportRows')) || 10,
    lastPage: 0,
  };
  prevResponse = 0;
  newPage = true;
  public reports: any = [];
  public total: number;
  public itemsPerPage: any = 10; // we are showing 10 items per page
  public lastPage = 0;
  disable = false;
  totalMessageCount: any;
  nextPage: any;
  selectItemError = false;
  value: string;
  displayName: string;
  permissionModules = [];
  permissionAuthorities: any = {};
  moduleName = 'REPORT';
  modules = [];
  hideCalendar = false;
  columnPopup = false;
  columnCounts = {
    selectCount: 0,
    selectedKeys: []
  };
  isSideMenuClose = false;

  constructor(
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private reportsService: GraphicReportsService,
    private title: Title,
    private router: Router
  ) { }

  ngOnInit() {
    this.title.setTitle('Reports' + environment.title_text);
    this.permissionModules = JSON.parse(localStorage.getItem('permissionModules'));
    this.modules = JSON.parse(localStorage.getItem('accessRoleModules'));
    this.permissionAuthorities = this.modules.find(module => module.name == this.moduleName);
    this.setReport();
  }

  onTimeChange(e) {
    this.startTime = e.startTime;
    this.endTime = e.endTime;
    this.page = 0;
    if (this.selectedItem) {
      this.getViewReports('0');
    }
  }

  changeVehicle(e) {
    if (localStorage.getItem('reportsVehicle') && this.querySelectVehicle == null) {
      this.setItem();
    } else {
      localStorage.setItem('reportsVehicle', JSON.stringify(e));
      this.searchModel.value = e ? e.deviceId : null;
      this.searchModel.vehicle = e ? e.deviceId : null;
      this.selectedItem = e;
      this.querySelectVehicle = e;
    }
    if (this.startTime && this.endTime) {
      this.getViewReports('0');
    }
  }

  changeDevice(e) {
    if (localStorage.getItem('reportsDevice') && this.querySelectDevice == null) {
      this.setItem();
    } else {
      this.selectedItem = e;
      localStorage.setItem('reportsDevice', JSON.stringify(e));
      this.searchModel.value = e ? e.deviceId : null;
      this.searchModel.device = e ? e.serialNumber : null;
      this.querySelectDevice = e;
    }
    if (this.startTime && this.endTime) {
      this.getViewReports('0');
    }
  }

  onSideMenuToggle() {
    this.isSideMenuClose = !this.isSideMenuClose;
    const temp = JSON.parse(JSON.stringify(this.reports));
    this.reports.length = 0;
    this.reports = temp;
  }

  getReport(report) {
    this.currentReport = report;
    this.setReport();
    if (this.selectedItem && this.startTime && this.endTime) {
      this.getViewReports('0');
    }
    this.pageInfo = {
      offset: 0,
      count: 0,
      limit: Number(localStorage.getItem('messagesReportRows')) || 10,
      pageSize: Number(localStorage.getItem('messagesReportRows')) || 10,
      lastPage: 0,
    };
    this.page = 0;
  }

  refresh() {
    this.getViewReports('0');
    this.page = 0;
    this.pageInfo = {
      offset: 0,
      count: 0,
      limit: Number(localStorage.getItem('messagesReportRows')) || 10,
      pageSize: Number(localStorage.getItem('messagesReportRows')) || 10,
      lastPage: 0,
    };
  }

  setReport() {
    if (this.currentReport && this.currentReport.reportId) {
      localStorage.setItem('currentReport', JSON.stringify(this.currentReport));
      this.report.reportId = this.currentReport.reportId;
    } else {
      if (JSON.parse(localStorage.getItem('currentReport'))) {
        let localReport = JSON.parse(localStorage.getItem('currentReport'));
        if (localReport.type != 'VISUALIZATION' && !localReport.downloadable) {
          this.currentReport = localReport;
          this.report.reportId = localReport.reportId;
        } else {
          let reports = JSON.parse(localStorage.getItem('reports'));
          let global = this;
          reports.forEach(item => {
            if (item.type != 'VISUALIZATION' && !item.downloadable && !global.currentReport.reportId) {
              global.currentReport = item;
            }
          })
          localStorage.setItem('currentReport', JSON.stringify(this.currentReport));
        }
      } else {
        let reports = JSON.parse(localStorage.getItem('reports'));
        let global = this;
        reports.forEach(item => {
          if (item.type != 'VISUALIZATION' && !item.downloadable && !global.currentReport.reportId) {
            global.currentReport = item;
          }
        });
        localStorage.setItem('currentReport', JSON.stringify(this.currentReport));
      }
    }
    this.columMenu = this.currentReport.columns;
    this.setAllColumns(this.columMenu);
    this.title.setTitle(this.currentReport.name + environment.title_text);
    // this.getSelectionType();
  }

  setItem() {
    if (this.currentReport.type != 'VIN_CHANGE_2G2K' && localStorage.getItem('reportsVehicle')) {
      let reportsItem = JSON.parse(localStorage.getItem('reportsVehicle'));
      this.querySelectVehicle = reportsItem;
      this.searchModel.value = reportsItem.deviceId;
      this.searchModel.vehicle = reportsItem.deviceId;
      this.selectedItem = reportsItem;
    } else {
      let reportsItem = JSON.parse(localStorage.getItem('reportsDevice'));
      this.querySelectDevice = reportsItem;
      this.searchModel.value = reportsItem.deviceId;
      this.searchModel.device = reportsItem.serialNumber;
      this.selectedItem = reportsItem;
    }
  }

  // to handle disabled buttons
  disableBtn() {
    if (
      !this.searchModel.value ||
      !this.startTime || !this.endTime
    ) {
      this.disable = true;
    } else {
      this.disable = false;
    }
  }

  getViewReports(page) {
    this.getMessageCount();
    if (this.currentReport.type == 'VIN_CHANGE_2G2K') {
      this.getDeviceView(true, 0);
    } else {
      this.getNewReports(true, 0);
    }
  }

  // To get a report
  getNewReports(isValid: boolean, page) {
    if (this.router.url.includes('/reports/device/messages')) {
      if (this.pageInfo.pageSize === 0) {
        this.pageInfo.pageSize = 10;
      }
      if (isValid) {
        if (this.currentReport.multiVehicle) {
          this.report.deviceId = '';
          this.disable = false;
        }
        this.newPage = false;
        this.reportsService.getSingleReportByReportId(
          this.currentReport.reportId, this.searchModel.value, this.startTime, this.endTime, page
        ).subscribe(res => {
          if (res == null) {
            this.reports = [];
            this.disable = false;
          } else {
            this.prevResponse = res.length;
            if (page == 0) {
              this.reports = res;
              this.pageInfo = {
                offset: 0,
                count: 0,
                limit: Number(localStorage.getItem('messagesReportRows')) || 10,
                pageSize: Number(localStorage.getItem('messagesReportRows')) || 10,
                lastPage: 0,
              };
            } else {
              this.reports = [...this.reports, ...res];
            }
            this.pageInfo.count = this.reports.length;
            const pagecount = this.pageInfo.count / this.pageInfo.limit;
            this.pageInfo.lastPage = Math.floor(pagecount);
            const decimal = pagecount - this.pageInfo.lastPage;
            this.disable = false;
            if (!Number.isInteger(decimal)) {
              this.pageInfo.lastPage = this.pageInfo.lastPage + 1;
            }
            if (page == 0) {
              if (res.length) {
                this.currentPageCount.start = (this.pageInfo.offset * this.pageInfo.pageSize) + 1;
                if (this.reports.length <= 10) {
                  this.currentPageCount.end = this.pageInfo.count;
                } else {
                  this.currentPageCount.end = (this.pageInfo.offset * this.pageInfo.pageSize) + this.pageInfo.limit;
                }
              }
            }
            this.disable = false;
          }
        }, error => {
          this.sharedService.getErrorMsg(error);
          this.disable = false;
        });
      }
    }
  }

  // To get a report
  getDeviceView(isValid: boolean, page) {
    if (this.router.url.includes('/reports/device/messages')) {
      if (this.pageInfo.pageSize === 0) {
        this.pageInfo.pageSize = 10;
      }
      if (isValid) {
        this.newPage = false;
        if (this.report.startDate == null || this.report.endDate == null) return;
        this.reportsService.getDeviceView(this.currentReport.reportId, this.startTime, this.endTime, page, this.searchModel.value)
          .subscribe(res => {
            if (res == null) {
              this.reports = [];
              this.disable = false;
            } else {
              this.prevResponse = res.length;
              if (page == 0) {
                this.reports = res;
                this.pageInfo = {
                  offset: 0,
                  count: 0,
                  limit: Number(localStorage.getItem('messagesReportRows')) || 10,
                  pageSize: Number(localStorage.getItem('messagesReportRows')) || 10,
                  lastPage: 0,
                };
              } else {
                this.reports = [...this.reports, ...res];
              }
              this.pageInfo.count = this.reports.length;
              const pagecount = this.pageInfo.count / this.pageInfo.limit;
              this.pageInfo.lastPage = Math.floor(pagecount);
              const decimal = pagecount - this.pageInfo.lastPage;
              this.disable = false;
              if (!Number.isInteger(decimal)) {
                this.pageInfo.lastPage = this.pageInfo.lastPage + 1;
              }
              if (page == 0) {
                if (res.length) {
                  this.currentPageCount.start = (this.pageInfo.offset * this.pageInfo.pageSize) + 1;
                  if (this.reports.length <= 10) {
                    this.currentPageCount.end = this.pageInfo.count;
                  } else {
                    this.currentPageCount.end = (this.pageInfo.offset * this.pageInfo.pageSize) + this.pageInfo.limit;
                  }
                }
              }
              this.disable = false;
            }
          }, error => {
            this.sharedService.getErrorMsg(error);
            this.disable = false;
          });
      }
    }
  }

  // To get total reports count
  getMessageCount() {
    if (this.router.url.includes('/reports/device/messages')) {
      if (this.currentReport.type == 'VIN_CHANGE_2G2K') {
        this.reportsService.getDeviceMeessageCount(
          this.currentReport.reportId, this.startTime, this.endTime, this.searchModel.value
        ).subscribe(res => {
          this.totalMessageCount = res;
        }, error => {
          this.sharedService.getErrorMsg(error);
        });
      } else {
        this.reportsService.getMessageCount(
          this.currentReport.reportId, this.searchModel.value, this.startTime, this.endTime
        ).subscribe(res => {
          this.totalMessageCount = res;
        }, error => {
          this.sharedService.getErrorMsg(error);
        });
      }
    }

  }

  pageCallback(value) {
    this.pageInfo.offset = value.offset;
    this.currentPageCount.start = (value.offset * value.pageSize) + 1;
    var lastPage = Math.floor(this.reports.length / this.pageInfo.limit);
    this.pageInfo.lastPage = lastPage;
    if (lastPage == value.offset) {
      this.currentPageCount.end = value.count;
    } else {
      this.currentPageCount.end = (value.offset * value.pageSize) + value.limit;
    }
    if (this.pageInfo.count % 100 === 0) {
      if (this.pageInfo.lastPage === (this.pageInfo.offset + 1)) {
        if (this.prevResponse) {
          this.page = ++this.page;
          if (this.currentReport.type == 'VIN_CHANGE_2G2K') {
            this.getDeviceView(true, this.page);
          } else {
            this.getNewReports(true, this.page);
          }
        }
      }
    }
  }

  changeRowCount(rows) {
    this.pageInfo.limit = rows;
    this.pageInfo.pageSize = rows;
    if (rows !== 100) {
      localStorage.setItem('messagesReportRows', rows);
    }
    this.pageCallback(this.pageInfo);
  }

  // To download report as a CSV File
  downloadReportCSV() {
    this.reportsService.downloadReportCSV(
      this.currentReport.reportId, this.searchModel.value, this.startTime, this.endTime
    ).subscribe(res => {
      var win = window.open(this.apiUrl + 'reports/download?access_key=' + res.token, '_blank');
      win.focus();
      this.disable = false;
    }, error => {
      this.sharedService.getErrorMsg(error);
      this.disable = false;
    });
  }

  downloadDeviceReportCSV() {
    this.reportsService.downloadDeviceReportCSV(
      this.currentReport.reportId, this.startTime, this.endTime, this.searchModel.value
    ).subscribe(res => {
      var win = window.open(this.apiUrl + 'reports/vin-change/download?access_key=' + res.token + '&compress=true', '_blank');
      win.focus();
      this.disable = false;
    }, error => {
      this.sharedService.getErrorMsg(error);
      this.disable = false;
    });
  }

  togglePopup() {
    this.columnPopup = !this.columnPopup;
  }

  setAllColumns(columns) {
    this.displayColumns = [];
    this.columnCounts.selectedKeys = [];
    this.columnCounts.selectCount = 0;
    columns.map(item => {
      item.checked = true;
      this.columnCounts.selectCount++;
      this.displayColumns.push(item);
      this.columnCounts.selectedKeys.push(item.key);
    });
  }

  selectColumns(e, column, i) {
    if (e.target.checked) {
      this.columnCounts.selectCount++;
    } else {
      this.columnCounts.selectCount--;
    }
    this.displayColumns.map(item => {
      if (column.key == item.key) {
        item.checked = e.target.checked;
      }
    });
  }

  selectDeSelectAllColumns(e) {
    if (e.target.checked) {
      this.columnCounts.selectCount = this.displayColumns.length;
    } else {
      this.columnCounts.selectCount = 0;
    }
    this.displayColumns.map(item => {
      item.checked = e.target.checked;
    });
  }

  updateColumns() {
    const temp = [];
    this.columnCounts.selectedKeys = [];
    this.searchModel.columnVal = '';
    this.displayColumns = this.currentReport.columns;
    this.displayColumns.map(item => {
      if (item.checked) {
        temp.push(item);
        this.columnCounts.selectedKeys.push(item.key);
      }
    });
    this.columMenu = temp;
    this.columnPopup = false;
  }

  cancelSelection() {
    this.columnPopup = false;
    this.columnCounts.selectCount = 0;
    this.searchModel.columnVal = '';
    this.displayColumns = this.currentReport.columns;
    this.displayColumns.map(item => {
      if (this.columnCounts.selectedKeys.includes(item.key)) {
        this.columnCounts.selectCount++;
        item.checked = true;
      } else {
        item.checked = false;
      }
    });
  }

  onColumnSearch() {
    const inputVal = this.searchModel.columnVal.trim();
    const val = inputVal.toLowerCase();
    if (val === '') {
      this.displayColumns = this.columMenu;
      return;
    } else {
      if (this.columMenu.length) {
        this.displayColumns = this.currentReport.columns.filter(function (item) {
          return item.displayName.toLowerCase().includes(val);
        });
      }
    }
  }
}
