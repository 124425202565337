<app-data-tab-header></app-data-tab-header>
<div class="width100 p-0">
  <!-- <div class="row limit-width trips-page-header action-row align-items-center">
    <div class="col-md-3">
    </div>
    <div class="col-md-2 text-right"></div>
    <div class="my-auto col-md-3 relative vehicleSelect">
      <app-search-select-vehicle
        (vehicleChangeEvent)="changeVehicle($event)"
        [autoSelect]="true"
        [clearable]="false"
        [defaultselectvehicle]="true"
      >
      </app-search-select-vehicle>
    </div>
    <div class="col-md-4 my-auto text-right">
      <div class="d-inline-block" (clickOutside)="hideCalendar=!hideCalendar;">
        <app-date-time-calendar
          [clickOutside]="hideCalendar"
          [selectPreset]="'eventTime'"
          [displayEventTime]="{start: devicestartTime, end: deviceendTime}"
          [maxRangeDays]="1"
          [validateHours]="24"
          [vehicle]="selecteddeviceId"
          [hideLinks]="['7days', '30days', 'week', 'month', 'todaysofar', 'weektodate', 'monthtodate', '90days', '180days', '365days']"
          (timeChangeEvent)="onTimeChange($event)"
        >
        </app-date-time-calendar>
      </div>
    </div>
  </div> -->
</div>
<!-- tab selection start -->
<div class="row support-content">
  <ul class="col-md-5 nav nav-tabs detailed-tabs relative page-sub-heading" role="tablist">
    <li class="nav-item active">
      <a id="messagesTab" (click)="toggleEffect = 'all'; getallMessages('asc', '0');" class="nav-link text-center mt-1 ml-4 active" role="tab" data-toggle="tab" href="#messages">
        <i class="fa fa-envelope"></i> All Messages
      </a>
    </li>
    <li class="nav-item ">
      <a id="tripsTab" (click)="toggleEffect = 'trips'; getTrips('asc');" class="nav-link text-center mt-1" role="tab" data-toggle="tab" href="#trips">
        <i class="fa fa-map-o"></i> Trips
      </a>
    </li>
    <!-- <li class="nav-item">
      <a (click)="toggleEffect = 'query'; getMessageType()" class="nav-link text-center mt-1" role="tab" data-toggle="tab" href="#query">
        <i class="fa fa-filter"></i> Query
      </a>
    </li> -->
  </ul>
  <div class="my-auto col-md-3 relative vehicleSelect">
    <app-search-select-vehicle (vehicleChangeEvent)="changeVehicle($event)" [autoSelect]="true" [clearable]="false"
      [defaultselectvehicle]="true">
    </app-search-select-vehicle>
  </div>
  <div class="col-md-4 my-auto text-right">
    <div class="d-inline-block" (clickOutside)="hideCalendar=!hideCalendar;">
      <app-date-time-calendar [clickOutside]="hideCalendar" [selectPreset]="'eventTime'"
        [displayEventTime]="{start: devicestartTime, end: deviceendTime}" [maxRangeDays]="1" [validateHours]="24"
        [vehicle]="selecteddeviceId"
        [hideLinks]="['7days', '30days', 'week', 'month', 'todaysofar', 'weektodate', 'monthtodate', '90days', '180days', '365days']"
        (timeChangeEvent)="onTimeChange($event)">
      </app-date-time-calendar>
    </div>
  </div>
</div>
<!-- tab selection end -->
<!-- Tab content start -->
<div class="tab-content">
  <!-- All Messages Tab -->
  <div role="tabpanel" class="tab-pane fade in active show relative mt-3 mx-3" id="messages">
    <div class="container-fluid width100 trips-page p-0 mb-3">
      <div class="page-loading" *ngIf="pageLoading">
        <img src="../../../assets/img/darby_loader_small.gif" />
      </div>
      <div class="row mt-3">
        <div class="col-md-12">
          <app-time-seeker [selectedDevice]="selecteddeviceId" [tsTime]="startTime" [teTime]="endTime"
            (onTimeSel)="onTimeSelection($event)"></app-time-seeker>
        </div>
      </div>
      <!-- <div *ngIf="!pageLoading" style="width: 100%;"> -->
        <div class="row mt-3" *ngIf="!pageLoading">
          <div *ngIf="(!allMessages.length && !singleMessages) && !messageTable" class="text-center col-md-12 py-5">
            <img width="130" src="../../../assets/img/datapage_nodata_256.png" />
            <h4 class="mt-3">No data to show</h4>
          </div>
          <div *ngIf="messageTable" class="col-md-5 px-1 allMessages-container">
            <div class="widget-container trip-container">
              <h5 class="headingAdd font400 widget-heading block-1 pl-4 pt-1 pb-1">
                <span class="mt-1 bold page-sub-heading">Messages</span>
                <span class="relative">
                  <input class="ml-4 search-msg" type="text" name="value" required [(ngModel)]="searchModel.value"
                    #value="ngModel" (keyup)="onSearch()" autofocus="true" placeholder="Msg ID / Msg Type" />
                  <button style="z-index: 9;" (click)="apiSearch();selectedFlag=true;"
                    class="btn data-icon  fa fa-search img-responsive"
                    style="z-index: 9;right: 0;top: -5px;width: 31px;position: absolute;height: 30px;margin-right: 5px;padding: 2px;"
                    placement="top">
                  </button>
                </span>
                <button class="btn btn-icon mx-1" placement="top"
                  tooltip="Search with exact message type id or partial/full content of message type">
                  <i class="fa fa-info-circle"></i></button>
                <a (click)="downloadMsgCSV()" *ngIf="allMessages.length" tooltip="Download trip data" placement="top"
                  class="toggle-btn pull-right relative btn btn-outline-primary mt-1">
                  <i class="fa fa-cloud-download"></i>
                </a>
              </h5>
              <hr *ngIf="!allMessages.length">
              <div class="relative">
                <div *ngIf="!allMessages.length" class="text-center mt-5 py-5">
                  <img width="130" src="../../../assets/img/datapage_nodata_256.png" />
                  <h4 class="mt-3">No data to show</h4>
                </div>
                <ngx-datatable *ngIf="allMessages.length"
                  class="bootstrap alertTable default-background active-table reportsTable wrapcell data-table mb-3 pt-3"
                  [rows]="allMessages" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
                  [rowHeight]="'auto'" [selected]="selectedMessage" [selectionType]="'single'"
                  (activate)="getData($event)" [externalSorting]="true"
                  [sorts]="[{dir: messageSort, prop: 'eventTime'}]" (sort)="sortMessages($event)"
                  [limit]="pageInfo.limit" [offset]="pageInfo.offset" [count]="pageInfo.count"
                  (page)="pageCallback($event)">
                  <ngx-datatable-column [resizeable]="false" [sortable]="false" name="#" [width]="50">
                    <ng-template let-rowIndex="rowIndex" ngx-datatable-cell-template>
                      {{rowIndex+1}}
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column [resizeable]="false" [sortable]="true" prop="eventTime" name="Time">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      {{row.eventTime | date: dateFormat}}
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column [resizeable]="false" [sortable]="false" name="Msg ID">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      {{row.messageTypeId}}
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column [resizeable]="false" [sortable]="false" name="Msg Type">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      {{row.messageType}}
                      <span *ngIf="['TCP', 'UDP'].includes(row.deviceCommProtocol)"
                        [class]="'badge badge-'+row.deviceCommProtocol">
                        {{row.deviceCommProtocol}}
                      </span>
                      <span *ngIf="!['TCP', 'UDP'].includes(row.deviceCommProtocol)"
                        class="badge badge-secondary">SYS</span>
                    </ng-template>
                  </ngx-datatable-column>
                </ngx-datatable>
                <div class="total-count" *ngIf="currentPageCount.totalCount && allMessages.length"
                  style="bottom: -20px;">
                  Showing
                  <span>
                    {{ currentPageCount.start }}
                  </span>
                  -
                  <span>
                    {{ currentPageCount.end }}
                  </span> of {{ currentPageCount.totalCount }} results.
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="messageTable && singleMessages" class="col-md-7 pr-4 pb-1 pl-1">
            <div class="widget-container trip-container">
              <h5 class="headingAdd font400 widget-heading block-2 pl-4 pb-3 page-sub-heading">
                Data
                <span *ngIf="singleMessages">
                  <span class="ml-3 message-text">
                    {{singleMessages.messageType}}({{singleMessages.messageTypeId}})</span>
                  <span class="ml-3">{{singleMessages.ingestionTimestamp | date: dateFormat}}</span>
                  <a *ngIf="singleMessages" (click)="downloadJson()" id="container" tooltip="Download Message Data"
                    class="toggle-btn pull-right relative btn btn-outline-primary">
                    <i class="fa fa-cloud-download font-14"></i>
                  </a>
                  <span class="toggle-btn mr-3 relative btn btn-outline-primary pull-right"
                    tooltip="Toggle Table/Code View">
                    <i style="vertical-align: center;" *ngIf="!table" (click)="table = !table" class="fa fa-table"></i>
                    <i *ngIf="table" (click)="table = !table" class="fa fa-code"></i>
                  </span>
                </span>
              </h5>
              <div class="clearfix"></div>
              <div class="mt-3 message-container" *ngIf="singleMessages && table">
                <div class="row table-header no-bg">
                  <div class="col-sm-6 header pl-4">Key</div>
                  <div class="col-sm-6 header">Value</div>
                </div>
                <div class="message-table">
                  <div *ngIf="deviceDetails.length || vehicleDetails.length">
                    <div class="row no-bg">
                      <div class="col-12 px-2">
                        <h5 class="json-heading mb-0">Data</h5>
                      </div>
                    </div>
                    <div class="row" *ngFor="let obj of deviceDetails">
                      <div class="col-sm-6">{{obj.key}}</div>
                      <div class="col-sm-6">{{obj.value}}</div>
                    </div>
                    <div class="row" *ngFor="let obj of vehicleDetails">
                      <div class="col-sm-6">{{obj.key}}</div>
                      <div class="col-sm-6">{{obj.value}}</div>
                    </div>
                  </div>
                  <div *ngIf="platformDetails.length">
                    <div class="row">
                      <div class="col-12 px-2">
                        <h5 class="json-heading mb-0">Platform Details</h5>
                      </div>
                    </div>
                    <div class="row" *ngFor="let obj of platformDetails">
                      <div class="col-sm-6">{{obj.key}}</div>
                      <div class="col-sm-6">{{obj.value}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-2" *ngIf="singleMessages && !table">
                <div class="col-sm-12">
                  <pre class="bench-device-data mt-4 message-table" [innerHTML]="messageFormattedString"> </pre>
                </div>
              </div>
            </div>
          </div>
        </div>
      <!-- </div> -->
    </div>
  </div>
  <!-- trips tab -->
  <div role="tabpanel" class="tab-pane fade in relative mt-3 mx-3" id="trips">
    <div class="container-fluid width100 trips-page p-0 mb-3">
      <div class="page-loading" *ngIf="pageLoading">
        <img src="../../../assets/img/darby_loader_small.gif" />
      </div>
      <!-- <div class="row mt-3">
        <div class="col-md-12">
          <app-time-seeker [selectedDevice]="selecteddeviceId" [tsTime]="startTime" [teTime]="endTime"
            (onTimeSel)="onTimeSelection($event)"></app-time-seeker>
        </div>
      </div> -->
      <div *ngIf="!pageLoading">
        <div class="row">
          <div *ngIf="(!trips.length && !allMessages.length && !singleMessages)"
            class="col-sm-12 text-center no-data-found">
            <img width="130" src="../../../assets/img/datapage_nodata_256.png" />
            <h4 class="mt-3">No data to show</h4>
          </div>
          <div *ngIf="trips.length" class="col-md-3 pl-4 pr-1">
            <div class="widget-container trip-container">
              <div *ngIf="!trips.length" class="text-center mt-3 py-5">
                <img width="130" src="../../../assets/img/datapage_nodata_256.png" />
                <h4 class="mt-3">No data to show</h4>
              </div>
              <ngx-datatable *ngIf="trips.length"
                class="bootstrap alertTable default-background data-table active-table reportsTable wrapcell mb-3 pt-3"
                [rows]="trips" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [scrollbarH]="true"
                [rowHeight]="'auto'" [selected]="selected" [selectionType]="'single'" (activate)="getMessages($event)"
                [externalSorting]="true" [sorts]="[{dir: tripSort, prop: 'tsTime'}]" (sort)="sortTrips($event)">
                <!--<ng-template *ngIf="trips.length && selectedTrip" let-row="row"">-->
                <ngx-datatable-column [resizeable]="false" [sortable]="false" [width]="70" name="Trip #">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.tripNumber}}
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [resizeable]="false" [sortable]="true" prop="tsTime" name="Trip Start">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.tsTime | date: dateFormat}}
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [resizeable]="false" [sortable]="false" name="Trip End">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.teTime | date: dateFormat}}
                  </ng-template>
                </ngx-datatable-column>
                <!--</ng-template>-->
              </ngx-datatable>
            </div>
          </div>
          <!-- <div *ngIf="(!allMessages.length && !singleMessages && !messageTable)" class="text-center col-sm-12 py-5 no-data-found no-trip-message-found">
            <img width="130" src="../../../assets/img/datapage_nodata_256.png" />
            <h4 class="mt-3">No data to show</h4>
          </div> -->
          <div *ngIf="messageTable" class="col-md-4 px-1 allMessages-container">
            <div class="widget-container trip-container">
              <h5 class="headingAdd font400 widget-heading block-1 pl-4 pt-1 pb-1">
                <span class="mt-1 bold page-sub-heading">Messages</span>
                <span class="relative">
                  <input class="ml-4 search-msg" type="text" name="value" required [(ngModel)]="searchModel.value"
                    #value="ngModel" (keyup)="onSearch()" autofocus="true" placeholder="Msg ID / Msg Type">

                  <!-- <i style="z-index: 9;" class="fa fa-search img-responsive search-icon" (click)="apiSearch()"
                    aria-hidden="true"></i> -->
                  <button style="z-index: 9;" (click)="apiSearch();selectedFlag=true;"
                    class="btn data-icon  fa fa-search img-responsive"
                    style="z-index: 9;right: 0;top: -5px;width: 31px;position: absolute;height: 30px;margin-right: 4px;padding: 2px;"
                    placement="top">
                  </button>
                </span>
                <button class="btn btn-icon mx-1" placement="top"
                  tooltip="Search with exact message type id or partial/full content of message type">
                  <i class="fa fa-info-circle"></i></button>
                <a (click)="downloadMsgCSV()" *ngIf="allMessages.length" tooltip="Download trip data"
                  class="toggle-btn pull-right relative btn btn-outline-primary mt-1"><i
                    class="fa fa-cloud-download"></i></a>
                <!--</div>-->
              </h5>
              <hr *ngIf="!allMessages.length">
              <div class="relative">
                <div *ngIf="!allMessages.length" class="text-center mt-5 py-5">
                  <img width="130" src="../../../assets/img/datapage_nodata_256.png" />
                  <h4 class="mt-3">No data to show</h4>
                </div>
                <ngx-datatable *ngIf="allMessages.length"
                  class="bootstrap alertTable default-background active-table reportsTable wrapcell data-table mb-3 pt-3"
                  [rows]="allMessages" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
                  [rowHeight]="'auto'" [selected]="selectedMessage" [selectionType]="'single'"
                  (activate)="getData($event)" [externalSorting]="true"
                  [sorts]="[{dir: tripMsgSort, prop: 'eventTime'}]" (sort)="sortMessagesWithTrips($event)"
                  [limit]="pageInfo.limit" [offset]="pageInfo.offset" [count]="pageInfo.count"
                  (page)="pageCallback($event)">
                  <ngx-datatable-column [resizeable]="false" [sortable]="false" name="#" [width]="50">
                    <ng-template let-rowIndex="rowIndex" ngx-datatable-cell-template>
                      {{rowIndex+1}}
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column [resizeable]="false" [sortable]="true" prop="eventTime" name="Time">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      {{row.eventTime | date: dateFormat}}
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column [resizeable]="false" [sortable]="false" name="Msg ID" [width]="70">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      {{row.messageTypeId}}
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column [resizeable]="false" [sortable]="false" name="Msg Type">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      {{row.messageType}}
                      <span *ngIf="['TCP', 'UDP'].includes(row.deviceCommProtocol)"
                        [class]="'badge badge-'+row.deviceCommProtocol">
                        {{row.deviceCommProtocol}}
                      </span>
                      <span *ngIf="!['TCP', 'UDP'].includes(row.deviceCommProtocol)"
                        class="badge badge-secondary">SYS</span>
                    </ng-template>
                  </ngx-datatable-column>
                </ngx-datatable>
                <div class="total-count" *ngIf="currentPageCount.totalCount && allMessages.length"
                  style="bottom: -20px;">
                  Showing
                  <span>
                    {{ currentPageCount.start }}
                  </span>
                  -
                  <span>
                    {{ currentPageCount.end }}
                  </span> of {{ currentPageCount.totalCount }} results.
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="singleMessages" class="col-md-5 pr-4 pb-1 pl-1">
            <div class="widget-container trip-container">
              <h5 class="headingAdd font400 widget-heading block-2 pl-4 pb-3 page-sub-heading">
                Data
                <span *ngIf="singleMessages">
                  <span class="ml-3 message-text">
                    {{singleMessages.messageType}}({{singleMessages.messageTypeId}})</span>
                  <span class="ml-3">{{singleMessages.ingestionTimestamp | date: dateFormat}}</span>
                  <a *ngIf="singleMessages" (click)="downloadJson()" id="container" tooltip="Download Message Data"
                    class="toggle-btn pull-right relative btn btn-outline-primary">
                    <i class="fa fa-cloud-download font-14"></i>
                  </a>
                  <span class="toggle-btn mr-1 relative btn btn-outline-primary pull-right"
                    tooltip="Toggle Table/Code View">
                    <i style="vertical-align: center;" *ngIf="!table" (click)="table = !table" class="fa fa-table"></i>
                    <i *ngIf="table" (click)="table = !table" class="fa fa-code"></i>
                  </span>
                </span>
              </h5>
              <div class="clearfix"></div>
              <div class="mt-3 message-container" *ngIf="singleMessages && table">
                <!--<h5>HEADER</h5>-->
                <div class="row table-header no-bg">
                  <div class="col-sm-6 header pl-4">Key</div>
                  <div class="col-sm-6 header">Value</div>
                </div>
                <div class="message-table">
                  <div *ngIf="deviceDetails.length || vehicleDetails.length">
                    <div class="row no-bg">
                      <div class="col-12 px-2">
                        <h5 class="json-heading mb-0">Data</h5>
                      </div>
                    </div>
                    <div class="row" *ngFor="let obj of deviceDetails">
                      <div class="col-sm-6">{{obj.key}}</div>
                      <div class="col-sm-6">{{obj.value}}</div>
                    </div>
                    <div class="row" *ngFor="let obj of vehicleDetails">
                      <div class="col-sm-6">{{obj.key}}</div>
                      <div class="col-sm-6">{{obj.value}}</div>
                    </div>
                  </div>
                  <!--<div *ngIf="vehicleDetails.length">-->
                  <!--<div class="row">-->
                  <!--<div class="col-12 px-2">-->
                  <!--<h5 class="json-heading">Vehicle Details</h5>-->
                  <!--</div>-->
                  <!--</div>-->
                  <!---->
                  <!--</div>-->
                  <div *ngIf="platformDetails.length">
                    <div class="row">
                      <div class="col-12 px-2">
                        <h5 class="json-heading">Platform Details</h5>
                      </div>
                    </div>
                    <div class="row" *ngFor="let obj of platformDetails">
                      <div class="col-sm-6">{{obj.key}}</div>
                      <div class="col-sm-6">{{obj.value}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-2" *ngIf="singleMessages && !table">
                <div class="col-sm-12">
                  <pre class="bench-device-data mt-4 message-table" [innerHTML]="messageFormattedString"> </pre>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div role="tabpanel" class="tab-pane fade in relative mt-3 mx-3" id="query">
    <div class="custom-btns">
      <button class="btn btn-icon ml-2 refresh" (click)="refresh()" tooltip="Refresh" placement="top"
        [disabled]="!queryMessage.length">
        <i class="fa fa-refresh" aria-hidden="true"></i>
        <span class="mob-access-text d-none">&nbsp;Refresh</span>
      </button>
      <button class="btn btn-icon ml-2 download" tooltip="Download" placement="top" type="button"
        (click)="downloadQueryJson()" [disabled]="!queryMessage.length">
        <i class="fa fa-cloud-download"></i>
        <span class="mob-access-text d-none">&nbsp;Download</span>
      </button>
    </div>
    <div class="container-fluid width100 trips-page p-0 mb-3">
      <div class="page-loading" *ngIf="pageLoading">
        <img src="../../../assets/img/darby_loader_small.gif" />
      </div>
      <div *ngIf="!pageLoading">
        <div class="row mt-3">
          <div class="col-sm-4">
            <form action="javascript:void(0);" #f="ngForm" (ngSubmit)="generateQueryMessage(f.valid)" method="POST"
              class="margin-bottom-0">
              <div class="row">
                <div class="col-sm-12 relative text-line">
                  <span class="bold font-14">
                    Message Type <i class="fa fa-question-circle-o" aria-hidden="true"></i>
                  </span>
                  <hr>
                </div>
                <div class="col-sm-12 sm-form-design mt-2">
                  <ng-select [items]="messageTypeArray" bindLabel="displayName" bindValue="messageType"
                    [multiple]="false" [closeOnSelect]="true" name="messageType" #messageType="ngModel"
                    [(ngModel)]="searchModel.messageType" [clearable]="false" placeholder="Select Message">
                  </ng-select>
                </div>
                <div class="col-sm-12 relative text-line">
                  <span class="bold font-14">
                    Query <i class="fa fa-question-circle-o" aria-hidden="true"></i>
                  </span>
                  <hr>
                </div>
                <div class="col-12">
                  <p class="text-muted mb-0 small"><i class="fa fa-info-circle" aria-hidden="true"></i> <span
                      class="bold"> Operations:</span> EqualTo <code>.eq()</code>, NotEqualTo <code>.ne()</code>,
                    LessThanEqualTo <code>.lte()</code>, GreaterThanEqualTo <code>.gte()</code>, and Boolean
                    <code>.is()</code>
                  </p>
                  <hr class="my-1 mx-0 info-line">
                  <p class="text-muted mb-0 small"><i class="fa fa-info-circle" aria-hidden="true"></i> <span
                      class="bold"> Data Types:</span> <code>String</code>, <code>int</code>, <code>float</code>,
                    <code>double</code>, and <code>boolean</code>
                  </p>
                  <hr class="my-1 mx-0 info-line">
                  <p class="text-muted mb-0 small"><i class="fa fa-info-circle" aria-hidden="true"></i> <span
                      class="bold"> Example1: </span><span>&#123;latitude.eq(12.2345) AND offset.gte(330)&#125; OR
                      &#123;packetStatus.eq(V) AND altitude.lte(234.4)&#125;</span> </p>
                  <hr class="my-1 mx-0 info-line">
                  <p class="text-muted small"><i class="fa fa-info-circle" aria-hidden="true"></i> <span class="bold">
                      Example2:</span> <span>&#123;latitude.eq(12.2345) AND offset.gte(330)&#125;</span></p>
                </div>
                <div class="col-sm-12 sm-form-design mt-2">
                  <textarea id="filter" class="form-control h5-email" placeholder="Please enter Query" value=""
                    autocomplete="off" tabindex="1" rows="5" name="filter" [(ngModel)]="searchModel.filter"
                    #filter="ngModel" required>
                  </textarea>
                  <label class="control-label">Query</label>
                  <div [hidden]="filter.valid || (filter.pristine && !f.submitted)">
                    <small class="text-danger">Please enter Query</small>
                  </div>
                </div>
                <div class="col-sm-12 relative text-line">
                  <span class="bold font-14">
                    Message Fields <i class="fa fa-question-circle-o" aria-hidden="true"></i>
                  </span>
                  <hr>
                </div>
                <div class="col-12">
                  <label class="small mb-0"><i class="fa fa-info-circle" aria-hidden="true"></i> Give the message field
                    name in comma-separated to get only these like eventTime,batteryPotential,packetStatus...</label>
                </div>
                <div class="col-sm-12 sm-form-design mt-2">
                  <input class="form-control" placeholder="Add Message Fields" type="text" name="attributes"
                    [(ngModel)]="searchModel.attributes" #attributes="ngModel" />
                  <label class="control-label">Message Fields</label>
                </div>
                <div class="col-12 mt-2 mb-5">
                  <button type="submit" class="btn btn-success small-screen-btn btn-icon-left pull-right mt-2">
                    Execute Query
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div class="col-8">
            <div class="scrollable-body" *ngIf="queryMessage.length">
              <pre class="bench-device-data" [innerHTML]="queryFormattedString"> </pre>
            </div>
            <p *ngIf="queryMessage.length" class="my-3">
              Total Count: {{queryMessage.length}}
            </p>
            <div class="scrollable-body text-center py-5" *ngIf="!queryMessage.length">
              <img class="mt-5 pt-4" width="130" src="../../../assets/img/datapage_nodata_256.png" />
              <h4 class="mt-3">No data to show</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>