
import {throwError as observableThrowError,  BehaviorSubject ,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { environment } from '@myenv/environment';

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  public apiUrl = environment.apiUrl;
  // private httpClient: HttpClient;

  constructor(
    private http: HttpClient,
    private handler: HttpBackend
  ) {
    // this.httpClient = new HttpClient(handler);
  }

  getFaq(): Observable<any> {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    return this.http.get(this.apiUrl + `faq`, { headers }).pipe(
      catchError(this.handleError));
  }

  handleError(error: any | any) {
    console.log(error.error || error.json() || error);
    return observableThrowError(error.error || error.json() || error || 'Server error');
  }
}
