import { PermissionGuard } from './../../perimission.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GraphicReportsComponent } from './graphic-reports.component';
import { ProvisioningReportComponent } from './device-reports/provisioning-report/provisioning-report.component';
import { VersionDrilldownReportComponent } from './device-reports/version-drilldown-report/version-drilldown-report.component';
import { UsageReportComponent } from './system-reports/usage-report/usage-report.component';
import { NotCommunicatingReportComponent } from './device-reports/not-communicating-report/not-communicating-report.component';
import { MessageReportComponent } from '@components/graphic-reports/message-report/message-report.component';
import { VisualizationReportComponent } from '@components/graphic-reports/visualization-report/visualization-report.component';
import { CustomReportComponent } from '@components/graphic-reports/custom-report/custom-report.component';
import { MetricsReportComponent } from './vehicle-reports/metrics-report/metrics-report.component';
import { LogInOutReportComponent } from './user-reports/log-in-out-report/log-in-out-report.component';
import { TripSummaryReportComponent } from './vehicle-reports/trip-summary-report/trip-summary-report.component';
import { DestinationReportComponent } from './system-reports/destination-report/destination-report.component';
import { ProcessingTimeReportComponent } from './system-reports/processing-time-report/processing-time-report.component';
import {DeviceViewReportComponent} from '@components/graphic-reports/device-reports/device-view-report/device-view-report.component';
import {VehicleViewReportComponent} from '@components/graphic-reports/vehicle-reports/vehicle-view-report/vehicle-view-report.component';
import {CommunicatingReportComponent} from '@components/graphic-reports/device-reports/communicating-report/communicating-report.component';
import { LiveStatusReportComponent } from './system-reports/live-status-report/live-status-report.component';
import {MultiVehicleDownloadReportComponent} from '@components/graphic-reports/multi-vehicle-download-report/multi-vehicle-download-report.component';
import {AlPlantModeReportComponent} from '@components/graphic-reports/al-plant-mode-report/al-plant-mode-report.component';
import { CommunicatingCalendarReportComponent } from './system-reports/communicating-calendar-report/communicating-calendar-report.component';
import { CTriggerReportComponent } from './c-trigger-report/c-trigger-report.component';
import { STriggerReportComponent } from './s-trigger-report/s-trigger-report.component';
import { environment } from '@myenv/environment';
import { AlReportGuard } from '@myproject/al-report.guard';
// import { ProvisionBillingComponent } from './billing-reports/provision-billing/provision-billing.component';
// import { GeneralComponent } from './general/general.component';
import { DeepViewComponent } from './deep-view/deep-view.component';
import { ScoreComponent } from './score/score.component';
import { PacketHistoryReportComponent } from './packet-history-report/packet-history-report.component';
import { UsageComponent } from './usage/usage.component';
import { BillingReportComponent } from './system-reports/billing-report/billing-report.component';
import { DiscoveryReportComponent } from './system-reports/discovery-report/discovery-report.component';
import { DiagnosticsReportComponent } from './system-reports/diagnostics-report/diagnostics-report.component';

const AL_SystemReport = environment.AL_SystemReport;
const routes: Routes = [
  {
    path: 'system/not-communicating', component: NotCommunicatingReportComponent,
    data: { moduleName: 'REPORT' },
    canActivate: [PermissionGuard]
  },
  {
    path: 'system/communicating', component: CommunicatingReportComponent,
    data: { moduleName: 'REPORT' },
    canActivate: [PermissionGuard]
  },
  {
    path: 'system/provisioning', component: ProvisioningReportComponent,
    data: { moduleName: 'REPORT' },
    canActivate: [PermissionGuard]
  },
  {
    path: '', component: MetricsReportComponent,
    data: { moduleName: 'REPORT' },
    canActivate: [PermissionGuard]
  },
  // {
  //   path: 'general', component: GeneralComponent,
  //   data: { moduleName: 'REPORT' },
  //   canActivate: [PermissionGuard]
  // },
  {
    path: 'deep-view', component: DeepViewComponent,
    data: { moduleName: 'DEEP-VIEW' },
    canActivate: [PermissionGuard]
  },
  // {
  //   path: 'score', component: ScoreComponent,
  //   data: { moduleName: 'SCORE' },
  //   canActivate: [PermissionGuard]
  // },
  // {
  //   path: 'system/not-communicating', component: NotCommunicatingReportComponent,
  //   data: { moduleName: 'REPORT' },
  //   canActivate: [PermissionGuard]
  // },
  // {
  //   path: 'system/communicating', component: CommunicatingReportComponent,
  //   data: { moduleName: 'REPORT' },
  //   canActivate: [PermissionGuard]
  // },
  // {
  //   path: 'system/provisioning', component: ProvisioningReportComponent,
  //   data: { moduleName: 'REPORT' },
  //   canActivate: [PermissionGuard]
  // },
  {
    path: 'system/version-drilldown', component: VersionDrilldownReportComponent,
    data: { moduleName: 'REPORT' },
    canActivate: [PermissionGuard]
  },
  {
    path: 'device/device-report', component: DeviceViewReportComponent,
    data: { moduleName: 'REPORT' },
    canActivate: [PermissionGuard]
  },
  {
    path: 'device/message-count', component: MetricsReportComponent,
    data: { moduleName: 'REPORT' },
    canActivate: [PermissionGuard]
  },
  {
    path: 'device/vehicle-report', component: VehicleViewReportComponent,
    data: { moduleName: 'REPORT' },
    canActivate: [PermissionGuard]
  },
  {
    path: 'device/packet-history', component: PacketHistoryReportComponent,
    data: { moduleName: 'REPORT' },
    canActivate: [PermissionGuard]
  },
  {
    path: 'device/trip-summary', component: TripSummaryReportComponent,
    data: { moduleName: 'REPORT' },
    canActivate: [PermissionGuard]
  },
  // {
  //   path: 'device/vehicle-report', component: VehicleViewReportComponent,
  //   data: { moduleName: 'REPORT' },
  //   canActivate: [PermissionGuard]
  // },
  {
    path: 'device/trip-summary', component: TripSummaryReportComponent,
    data: { moduleName: 'REPORT' },
    canActivate: [PermissionGuard]
  },
  {
    path: 'device/messages',
    component: MessageReportComponent,
    data: { moduleName: 'REPORT' },
    canActivate: [PermissionGuard]
  },
  // {
  //   path: 'device/visualization',
  //   component: VisualizationReportComponent,
  //   data: { moduleName: 'REPORT' },
  //   canActivate: [PermissionGuard]
  // },
  {
    path: 'device/custom',
    component: CustomReportComponent,
    data: { moduleName: 'REPORT' },
    canActivate: [PermissionGuard]
  },
  {
    path: 'device/multiVehicleDownload',
    component: MultiVehicleDownloadReportComponent,
    data: { moduleName: 'REPORT' },
    canActivate: [PermissionGuard]
  },
  {
    path: 'device/alPlantMode',
    component: AlPlantModeReportComponent,
    data: { moduleName: 'REPORT' },
    canActivate: [PermissionGuard]
  },
  {
    path: 'user/in-out-activity', component: LogInOutReportComponent,
    data: { moduleName: 'REPORT' },
    canActivate: [PermissionGuard]
  },
  // {
  //   path: 'system/message-published', component: DestinationReportComponent,
  //   data: { moduleName: 'REPORT' },
  //   canActivate: [PermissionGuard]
  // },
  {
    path: 'system/processing-time', component: ProcessingTimeReportComponent,
    data: { moduleName: 'REPORT' },
    canActivate: [PermissionGuard]
  },
  {
    path: 'system/live-status', component: LiveStatusReportComponent,
    data: { moduleName: 'REPORT' },
    canActivate: [PermissionGuard]
  },
  {
    path: 'system/billing-report', component: BillingReportComponent,
    data: { moduleName: 'REPORT' },
    canActivate: [PermissionGuard]
  },
  // {
  //   path: 'system/usage', component: UsageReportComponent,
  //   data: { moduleName: 'REPORT' },
  //   canActivate: [PermissionGuard]
  // },
  {
    path: 'system/device-timeline', component: CommunicatingCalendarReportComponent,
    data: { moduleName: 'REPORT' },
    canActivate: [PermissionGuard]
  },
  {
    path: 'system/c-trigger-report', component: CTriggerReportComponent,
    data: { moduleName: 'REPORT' },
    canActivate: [PermissionGuard, AlReportGuard]
  },
  {
    path: 'system/s-trigger-report', component: STriggerReportComponent,
    data: { moduleName: 'REPORT' },
    canActivate: [PermissionGuard, AlReportGuard]
  },
  {
    path: "system/usage",
    component: UsageComponent,
    data: { moduleName: "REPORT" },
    canActivate: [PermissionGuard]
  },
  // {
  //   path: 'billing/provision-billing', component: ProvisionBillingComponent,
  //   data: { moduleName: 'REPORT' },
  //   canActivate: [PermissionGuard]
  // },
  {
    path: 'system/discovery', component: DiscoveryReportComponent,
    data: { moduleName: 'REPORT' },
    canActivate: [PermissionGuard]
  },
  {
    path: 'system/diagnostics', component: DiagnosticsReportComponent,
    data: { moduleName: 'REPORT' },
    canActivate: [PermissionGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GraphicReportsRoutingModule { }

