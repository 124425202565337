import { OnInit } from '@angular/core';
import { ScoreService } from './score.service';
import { SharedService } from '@myproject/shared/shared.service';
import Chart from 'chart.js';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { environment } from '@myenv/environment';
var ScoreComponent = /** @class */ (function () {
    function ScoreComponent(scoreService, sharedService, title) {
        this.scoreService = scoreService;
        this.sharedService = sharedService;
        this.title = title;
        this.vehicles = [];
        this.vehiclesLoading = false;
        this.titleText = '';
        this.dailyScore = {};
        this.monthlyScore = [];
        this.selectedScore = '';
        this.permissionModules = [];
        var url = localStorage.getItem('lastReportUrl');
        this.lastReportUrl = url ? url : 'reports/system/live-status';
    }
    ScoreComponent.prototype.ngOnInit = function () {
        this.title.setTitle('Score' + environment.title_text);
        this.permissionModules = JSON.parse(localStorage.getItem('permissionModules'));
    };
    ScoreComponent.prototype.changeVehicle = function (e) {
        this.selecteddeviceId = e ? e.deviceId : null;
        this.getAllScores();
        this.getSingleScoreData('harshBraking');
    };
    ScoreComponent.prototype.getAllScores = function () {
        var _this = this;
        if (this.selecteddeviceId === null) {
            return;
        }
        // const endTime = Date.parse(new Date().toUTCString());
        // const startTime = endTime - (24 * 60 * 60 * 1000);
        this.scoreService.getVehicleScores(this.selecteddeviceId).subscribe({
            next: function (res) {
                if (res.length) {
                    _this.dailyScore = res[0];
                    // this.getSingleScoreData('harshBraking');
                }
                else {
                    _this.dailyScore = {};
                    _this.dailyScore.harshBraking = 0;
                    _this.dailyScore.harshAcceleration = 0;
                    _this.dailyScore.overSpeed = 0;
                    _this.dailyScore.idling = 0;
                    _this.dailyScore.exposure = 0;
                    _this.dailyScore.behaviour = 0;
                    _this.dailyScore.risk = 0;
                }
            },
            error: function (error) {
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    ScoreComponent.prototype.getSingleScoreData = function (scoreType) {
        var _this = this;
        this.selectedScore = scoreType;
        var endTime = Date.parse(new Date().toUTCString());
        var startTime = endTime - (30 * 24 * 60 * 60 * 1000);
        this.scoreService.getVehicleScores(this.selecteddeviceId, startTime, endTime, scoreType).subscribe({
            next: function (res) {
                // console.log(res);
                if (res.length) {
                    // this.monthlyScore = res;
                    _this.sortData(res, scoreType);
                }
                else {
                    _this.clearChart(scoreType);
                }
            },
            error: function (error) {
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    ScoreComponent.prototype.changeScore = function (scoreType) {
        if (this.scoreChart) {
            this.scoreChart.destroy();
        }
        this.getSingleScoreData(scoreType);
    };
    ScoreComponent.prototype.sortData = function (res, scoreType) {
        var labels = [];
        var data = [];
        res.reverse();
        res.map(function (item) {
            var datePipe = new DatePipe('en-US');
            var eventDate = datePipe.transform(item.localDate, 'dd MMM');
            labels.push(eventDate);
            data.push(item[scoreType]);
        });
        this.drawChart(labels, data, scoreType);
    };
    ScoreComponent.prototype.clearChart = function (scoreType) {
        if (this.scoreChart) {
            this.scoreChart.destroy();
        }
        this.drawChart([], [], scoreType ? scoreType : '');
    };
    ScoreComponent.prototype.drawChart = function (labels, data, scoreType) {
        var label;
        if (scoreType === 'harshBraking') {
            label = 'Harsh Braking Score';
            this.titleText = 'Harsh Braking';
        }
        else if (scoreType === 'harshAcceleration') {
            label = 'Harsh Acceleration Score';
            this.titleText = 'Harsh Acceleration';
        }
        else if (scoreType === 'overSpeed') {
            label = 'Over Speeding Score';
            this.titleText = 'Over Speeding';
        }
        else if (scoreType === 'idling') {
            label = 'Idling Score';
            this.titleText = 'Idling';
        }
        else if (scoreType === 'exposure') {
            label = 'Exposure Score';
            this.titleText = 'Exposure';
        }
        else if (scoreType === 'behaviour') {
            label = 'Behavior Score';
            this.titleText = 'Behavior';
        }
        else if (scoreType === 'risk') {
            label = 'Risk Score';
            this.titleText = 'Risk';
        }
        else {
            label = 'Score';
            this.titleText = 'Score';
        }
        var ctx = document.getElementById('lineChart');
        this.scoreChart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: labels,
                datasets: [{
                        label: label,
                        backgroundColor: 'rgba(54, 162, 235, 0.2)',
                        borderColor: 'rgba(54, 162, 235, 1)',
                        data: data
                    }]
            },
            options: {
                legend: {
                    position: 'bottom'
                },
                scales: {
                    yAxes: [{
                            gridLines: {
                                drawBorder: false,
                            },
                            ticks: {
                                suggestedMin: 0,
                                suggestedMax: 100
                            }
                        }]
                },
                xAxes: [{
                        gridLines: {
                            drawBorder: false,
                        },
                    }]
            }
        });
    };
    return ScoreComponent;
}());
export { ScoreComponent };
