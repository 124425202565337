import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { SupportService } from '../support.service';
import { SharedService } from '@myproject/shared/shared.service';
import { environment } from '@myenv/environment';
var NotCommunicatingComponent = /** @class */ (function () {
    function NotCommunicatingComponent(supportService, sharedService) {
        this.supportService = supportService;
        this.sharedService = sharedService;
        this.devices = [];
        this.hours = 24;
        this.page = 0;
        this.loadingIndicator = true;
        this.currentPageCount = {
            start: 0,
            end: 0
        };
        this.dateFormat = environment.smallDateFormat;
        this.pageInfo = {
            offset: 0,
            count: 0,
            limit: 10,
            pageSize: 0,
            lastPage: 0,
        };
        this.error = false;
        this.customers = [];
        this.searchModel = {};
    }
    NotCommunicatingComponent.prototype.ngOnInit = function () {
        this.getCustomers();
        // this.getNotCommDevices(this.page);
    };
    NotCommunicatingComponent.prototype.getCustomers = function () {
        var _this = this;
        this.sharedService.getAllCustomer().subscribe(function (res) {
            _this.customers = res;
            _this.searchModel.customerId = res[0].customerId;
            _this.selectCustomer();
        }, function (error) {
            _this.sharedService.getErrorMsg(error);
        });
    };
    NotCommunicatingComponent.prototype.selectCustomer = function () {
        if (this.searchModel.customerId !== null) {
            this.devices = [];
            this.page = 0;
            this.devicesCount = {};
            this.getNotCommDevices(this.page);
            this.getCount();
        }
    };
    NotCommunicatingComponent.prototype.getNotCommDevices = function (page) {
        var _this = this;
        this.supportService.getNotCommDevices(page, this.hours, this.searchModel.customerId).subscribe(function (res) {
            _this.devices = tslib_1.__spread(_this.devices, res);
            _this.loadingIndicator = false;
            _this.pageInfo.count = _this.devices.length;
            var pagecount = _this.pageInfo.count / _this.pageInfo.limit;
            _this.pageInfo.lastPage = Math.floor(pagecount);
            var decimal = pagecount - _this.pageInfo.lastPage;
            //
            if (!Number.isInteger(decimal)) {
                _this.pageInfo.lastPage = _this.pageInfo.lastPage + 1;
            }
            if (page == 0) {
                if (res.length) {
                    _this.currentPageCount.start = (_this.pageInfo.offset * _this.pageInfo.pageSize) + 1;
                    if (_this.devices.length <= 10) {
                        _this.currentPageCount.end = _this.pageInfo.count;
                    }
                    else {
                        _this.currentPageCount.end = (_this.pageInfo.offset * _this.pageInfo.pageSize) + _this.pageInfo.limit;
                    }
                }
            }
        });
    };
    NotCommunicatingComponent.prototype.getCount = function () {
        var _this = this;
        this.supportService.getDevicesCount(this.hours, this.searchModel.customerId).subscribe(function (res) {
            _this.devicesCount = res;
        });
    };
    // To get the devices for each page in the table
    NotCommunicatingComponent.prototype.pageCallback = function (value) {
        this.pageInfo.offset = value.offset;
        this.currentPageCount.start = (value.offset * value.pageSize) + 1;
        var lastPage = Math.floor(this.devices.length / 10);
        if (lastPage == value.offset) {
            this.currentPageCount.end = value.count;
        }
        else {
            this.currentPageCount.end = (value.offset * value.pageSize) + value.limit;
        }
        if (this.pageInfo.lastPage === (this.pageInfo.offset + 1)) {
            // tslint:disable-next-line:no-unused-expression
            this.page === ++this.page;
            this.getNotCommDevices(this.page);
        }
    };
    NotCommunicatingComponent.prototype.changeTime = function (f) {
        if (f.valid && this.hours != 0) {
            this.error = false;
            this.page = 0;
            this.devices = [];
            this.loadingIndicator = true;
            this.getNotCommDevices(this.page);
        }
        else {
            this.error = true;
        }
    };
    return NotCommunicatingComponent;
}());
export { NotCommunicatingComponent };
