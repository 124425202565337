import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '@myenv/environment';
var TripsService = /** @class */ (function () {
    function TripsService(http) {
        this.http = http;
        this.apiUrl = environment.apiUrl;
    }
    // To get the trips
    TripsService.prototype.getTrips = function (deviceId, page, limit) {
        return this.http.get(this.apiUrl +
            'trips/device/' +
            deviceId +
            '?page=' +
            page +
            '&limit=' +
            limit);
    };
    TripsService.prototype.getPolylineData = function (deviceId, startTime, endTime) {
        return this.http
            .get(this.apiUrl +
            ("messages/device/" + deviceId) +
            "?attributes=eventTime,latitude,longitude,messageType,messageId," +
            "maxSpeed,avgSpeed,initialSpeed,finalSpeed,maxBraking,initialSpeed,finalSpeed,maxAcceleration" +
            ("&startTime=" + startTime + "&endTime=" + endTime)).pipe(catchError(this.handleError));
    };
    TripsService.prototype.getAllPids = function () {
        return this.http
            .get(this.apiUrl + 'messages/datalogger/pids/supported').pipe(catchError(this.handleError));
    };
    TripsService.prototype.getSupportedPids = function (tripId, tsTime) {
        return this.http
            .get(this.apiUrl +
            ("messages/datalogger/pids/supported/device?tripId=" + tripId + "&startTime=" + tsTime)).pipe(catchError(this.handleError));
    };
    TripsService.prototype.getallMessages = function (deviceId, startTime, endTime, attrubute1, attribute2) {
        var tempUrl = "&attributes=eventTime," + attrubute1;
        if (attribute2) {
            tempUrl += "," + attribute2;
        }
        return this.http
            .get(this.apiUrl +
            'messages/device/' +
            deviceId +
            '?startTime=' +
            startTime +
            '&endTime=' +
            endTime + tempUrl).pipe(catchError(this.handleError));
    };
    TripsService.prototype.handleError = function (error) {
        return observableThrowError(error.error || error.json() || error || 'Server error');
    };
    return TripsService;
}());
export { TripsService };
