import { throwError as observableThrowError, BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '@myenv/environment';
import { HttpHeaders, HttpClient, HttpBackend } from '@angular/common/http';
var AdminService = /** @class */ (function () {
    function AdminService(http, handler) {
        this.http = http;
        this.handler = handler;
        this.sideData = [];
        this.messageSource = new BehaviorSubject(this.sideData);
        this.currentMessage = this.messageSource.asObservable();
        this.apiUrl = environment.apiUrl;
        this.httpClient = new HttpClient(handler);
    }
    // To get the side menu
    AdminService.prototype.getSideMenu = function () {
        var users = {
            name: 'menu.USER',
            img: 'user-circle-o',
            route: '/admin/users'
        };
        // const groups = {
        //   name: 'menu.GROUP',
        //   img: 'object-ungroup',
        //   route: '/admin/groups'
        // };
        var roles = {
            name: 'menu.ROLE',
            img: 'unlock-alt',
            route: '/admin/roles'
        };
        // const device = {
        //   name: 'menu.DEVICES',
        //   img: 'microchip',
        //   route: '/admin/device'
        // };
        // const settings = {
        //   name: 'menu.SETTINGS',
        //   img: 'cog',
        //   route: '/admin/settings'
        // };
        var developer = {
            name: 'menu.DEVELOPER',
            img: 'code-fork',
            route: '/admin/developer'
        };
        var alerts = {
            name: 'menu.ALERTS',
            img: 'warning',
            route: '/admin/alerts'
        };
        var place = {
            name: 'menu.PLACE',
            img: 'globe',
            route: '/admin/place'
        };
        var whitelabel = {
            name: 'menu.WHITELABEL',
            img: 'tachometer',
            route: '/admin/whitelabel'
        };
        // const headers = {
        //   name: 'menu.HEADERS',
        //   img: 'sun-o',
        //   route: '/admin/headers'
        // };
        var cache = {
            name: 'menu.CACHE-ATTRIBUTES',
            img: 'cubes',
            route: '/admin/cache-attributes'
        };
        var rules = {
            name: 'menu.RULES',
            img: 'gavel',
            route: '/admin/rules'
        };
        // const destination = {
        //   name: 'menu.DESTINATION',
        //   img: 'crosshairs',
        //   route: '/admin/destination'
        // };
        var audit = {
            name: 'menu.AUDIT-TRIAL',
            img: 'exchange',
            route: '/admin/audit-trial'
        };
        var marketplace = {
            name: 'menu.MARKETPLACE',
            img: 'university',
            route: '/marketplace'
        };
        this.sideData = [];
        var permissionModules = JSON.parse(localStorage.getItem('permissionModules'));
        var sidedata = this.sideData;
        if (permissionModules.includes('USER'))
            sidedata.push(users);
        if (permissionModules.includes('ROLE'))
            sidedata.push(roles);
        if (permissionModules.includes('PLACE'))
            sidedata.push(place);
        if (permissionModules.includes('ALERT'))
            sidedata.push(alerts);
        if (permissionModules.includes('SETTING'))
            sidedata.push(whitelabel);
        if (permissionModules.includes('API'))
            sidedata.push(developer);
        // if (permissionModules.includes('DESTINATION')) sidedata.push(destination);
        if (permissionModules.includes('RULE'))
            sidedata.push(rules);
        if (permissionModules.includes('AUDIT-TRIAL'))
            sidedata.push(audit);
        if (permissionModules.includes('CACHE-ATTRIBUTES'))
            sidedata.push(cache);
        // if (permissionModules.includes('DEVICE')) sidedata.push(device);
        // if (permissionModules.includes('GROUP')) sidedata.push(groups);
        // if (permissionModules.includes('HEADER')) sidedata.push(headers);
        sidedata.push(marketplace);
        return sidedata;
    };
    AdminService.prototype.changeMessage = function (message) {
        this.messageSource.next(message);
    };
    AdminService.prototype.uploadLogo = function (data) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
        });
        return this.httpClient.post(this.apiUrl + 'users/logo', data, { headers: headers }).pipe(catchError(this.handleError));
    };
    AdminService.prototype.handleError = function (error) {
        return observableThrowError(error.error || error || 'Server error');
    };
    return AdminService;
}());
export { AdminService };
