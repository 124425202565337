import { OnInit } from '@angular/core';
import { environment } from '@myenv/environment';
import { TranslateService } from '@ngx-translate/core';
var SideMenuComponent = /** @class */ (function () {
    function SideMenuComponent(translate) {
        this.translate = translate;
        this.modules = [];
        this.permissionAuthorities = {};
        this.cmImageUrl = environment.cmImageUrl;
    }
    SideMenuComponent.prototype.ngOnInit = function () {
        var _this = this;
        var url = window.location.pathname;
        var array = url.split('/');
        this.moduleName = array[array.length - 1].toUpperCase();
        this.modules = JSON.parse(localStorage.getItem('accessRoleModules'));
        this.permissionAuthorities = this.modules.find(function (module) { return module.name === _this.moduleName; });
    };
    return SideMenuComponent;
}());
export { SideMenuComponent };
