import { ErrorHandler } from '@angular/core';
var GlobalErrorHandler = /** @class */ (function () {
    function GlobalErrorHandler() {
    }
    GlobalErrorHandler.prototype.handleError = function (error) {
        var chunkFailedMessage = /Loading chunk [\d]+ failed/;
        // console.error(error);
        if (chunkFailedMessage.test(error.message)) {
            window.location.reload();
        }
    };
    return GlobalErrorHandler;
}());
export { GlobalErrorHandler };
