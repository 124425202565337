import * as enmessage from '../../assets/i18n/en-US.json';

const message: any = {}
for (const key in enmessage) {
    // if (enmessage.hasOwnProperty(key)) {
        message[key] = enmessage[key];

    // }
}
export const enMessage: any = message.default;
