
import { throwError as observableThrowError, Observable } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '@myenv/environment';

@Injectable()
export class DashboardService {
  public apiUrl = environment.apiUrl;
  reportModule: any = [];
  constructor(private http: HttpClient, private router: Router) {
    const permissionmodules = localStorage.getItem('permissionModules');
    this.reportModule = permissionmodules.includes('REPORT');
  }

  getDashboards(): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    return this.http.get(this.apiUrl + 'dashboard/assigned', { headers }).pipe(
      catchError(this.handleError));
  }

  getVindecoder(vin) {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    return this.http.get(this.apiUrl + `devices/vinSearch/${vin}`, { headers }).pipe(
      catchError(this.handleError));
  }

  getEVStats(deviceId): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    return this.http.get(this.apiUrl + `messages/ebike/device/${deviceId}?messageType=ExtendedVehicleSnapshot&attributes=evbike,customerId,deviceId,eventTime&sort=DSC`, { headers }).pipe(
      catchError(this.handleError));
  }

  getTripsDetails(deviceId, limit): Observable<any> {
    return this.http.get(this.apiUrl + 'trips/device/' + deviceId + '?limit=' + limit);
  }
  getallWidgets(currentDashboard): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    return this.http.get(this.apiUrl + 'dashboard/' + currentDashboard, { headers }).pipe(
      catchError(this.handleError));
  }

  getVehicleDetail(deviceId, attributes?): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    let tempUrl = `messages/device/${deviceId}/snapped`;
    tempUrl += `?attributes=${attributes}`;
    return this.http.get(this.apiUrl + tempUrl, { headers }).pipe(
      catchError(this.handleError));
  }

  getSingleWidgetData(widgetId, data): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    if (!data) { data = {}; }
    return this.http.post(this.apiUrl + 'dashboard/widget/' + widgetId + '/data/generate', data, { headers }).pipe(
      catchError(this.handleError));
  }

  getEVDetail(deviceId): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    return this.http.get(this.apiUrl + `messages/latest/ebike/device/${deviceId}`, { headers }).pipe(
      catchError(this.handleError));
  }

  updateLayout(data): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    return this.http.put(this.apiUrl + 'dashboard/' + data.dashboardId + '/update/layout?layout=' + data.layout, { headers }).pipe(
      catchError(this.handleError));
  }

  // To get the trips
  getTrips(deviceId, start, end, limit): Observable<any> {
    return this.http.get(this.apiUrl + `trips/device/${deviceId}?startTime=${start}&endTime=${end}&limit=${limit}&sort=desc`);
  }

  // To get the driving behaviour
  getDrivingBehaviour(deviceId, endTime, startTime) {
    return this.http.get(
      this.apiUrl +
      `analytics/driver/behaviour/device/${deviceId}/count` +
      `?startTime=${startTime}&endTime=${endTime}`
    );
  }

  // To get the driving behaviour by message
  getIndividualDrivingBehaviour(deviceId, endTime, startTime, messageType): Observable<any> {
    return this.http.get(
      this.apiUrl +
      `analytics/driver/behaviour/device/${deviceId}/message/${messageType}` +
      `?startTime=${startTime}&endTime=${endTime}`
    );
  }

  // To get the Vehicle Status
  getVehicleStatus(deviceId, limit): Observable<any> {
    return this.http.get(this.apiUrl + 'analytics/dtc/device/' + deviceId + '?limit=' + limit);
  }

  // To get the device Events
  getDeviceEvents(deviceId, limit): Observable<any> {
    return this.http.get(this.apiUrl + 'analytics/device/' + deviceId + '/activity?limit=' + limit);
  }

  // To get the vehicle count
  getVehicleCount(deviceId, limit): Observable<any> {
    return this.http.get(this.apiUrl + 'analytics/dtc/device/' + deviceId + '/count?limit=' + limit);
  }

  // Get battery metrics grapg data
  getBatteryMetrics(deviceId, startTime, endTime, limit): Observable<any> {
    return this.http.get(
      this.apiUrl +
      `analytics/battery/metrics/device/${deviceId}` +
      `?startTime=${startTime}&endTime=${endTime}&limit=${limit}`
    )
      .pipe(catchError(this.handleError));
  }

  getDeviceCommunicationTrend(startTime, endTime, key, value): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    return this.http.get(
      this.apiUrl +
      `analytics/communication/trend` +
      `?startTime=${startTime}&endTime=${endTime}&type=${key}&typeValue=${value}`,
      { headers }
    ).pipe(catchError(this.handleError));
  }

  getKeyCounts(key): Observable<any> {
    return this.http.get(this.apiUrl + `devices/count/key/${key}`).pipe(catchError(this.handleError));
  }

  getAllWarningCounts(startDate, endDate): Observable<any> {
    return this.http.get(this.apiUrl + `devices/warning/stats/count?startDate=${startDate}&endDate=${endDate}`)
      .pipe(catchError(this.handleError));
  }
  getDeviceData(deviceId): Observable<any> {
    return this.http.get(this.apiUrl + `messages/messageId/${deviceId}`)
  }

  getExtendedVehicleSnapshotData(deviceId): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    let tempUrl = `messages/device/${deviceId}/extendedData`;
    return this.http.get(this.apiUrl + tempUrl, { headers }).pipe(
      catchError(this.handleError));
  }

  getDeviceRecentEvents(deviceId, page, pagelimit, messageTypes?): Observable<any> {
    let tempUrl = `messages/device/${deviceId}?page=${page}&limit=${pagelimit}`;

    if (messageTypes) {
      tempUrl = tempUrl + `&messageTypes=${messageTypes}`;
    }

    return this.http.get(this.apiUrl + tempUrl).pipe(
      catchError(this.handleError));
  }

  getVinDecodedDetails(vin) {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    return this.http.get(this.apiUrl + `devices/decodeVIN/${vin}`, { headers }).pipe(
      catchError(this.handleError));
  }

  handleError(error: any | any) {
    console.log(error.error || error.json() || error);
    return observableThrowError(error.error || error.json() || error || 'Server error');
  }
}
