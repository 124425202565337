import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SharedService } from '@myproject/shared/shared.service';
import { GraphicReportsService } from '../graphic-reports.service';
import { environment } from '@myenv/environment';
import { configMessage } from '@shared/config-message';
import { DataService } from '@myproject/components/data/data.service';
import { LiveService } from '@myproject/components/live/live.service';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-packet-history-report',
  templateUrl: './packet-history-report.component.html',
  styleUrls: ['./packet-history-report.component.scss']
})
export class PacketHistoryReportComponent implements OnInit {

  attributes = 'messageType,eventTime,ingestionTimestamp,sequenceNumber,latitude,longitude,altitude,heading,packetStatus,gpsSpeed,gpsOdometer,fixQuality,numberOfSatellites,configVersion,firmwareVersion';
  public dateFormat = environment.smallDateFormat;
  permissionModules = [];
  messageTypeArray: any = [];
  pageInfo = {
    offset: 0,
    count: 0,
    limit: 10,
    pageSize: 0,
    lastPage: 0,
  };
  prevResponse = 0;
  page = 0;
  currentPageCount: any = {
    start: 0,
    end: 0
  };
  packetHistory: any[] = [];
  totalCount: any;
  isLoading = false;
  startTime: number;
  endTime: number;
  selectedVehicle: string = null;
  selectedDevice: any = {};
  querySelectVehicle = null;
  searchModel: any = {
    value: null,
    messageType: null
  };
  hideCalendar = false;
  isSideMenuClose = false;
  // message JSON popup
  messageJsonLoader = false;
  messageJsonRaw: any;
  messageJsonFormattedString: any;

  constructor(
    private title: Title,
    private sharedService: SharedService,
    private dataService: DataService,
    private liveService: LiveService,
    private reportsService: GraphicReportsService,
  ) { }

  ngOnInit() {
    this.title.setTitle('Packet History' + environment.title_text);
    this.permissionModules = JSON.parse(localStorage.getItem('permissionModules'));
    // this.getMessageType();
    this.isLoading = true;
  }

  onTimeChange(e) {
    this.startTime = e.startTime;
    this.endTime = e.endTime;
    if (this.selectedVehicle) this.refresh();
  }

  changeVehicle(e) {
    if (localStorage.getItem('reportsVehicle') && this.selectedVehicle == null) {
      this.setItem();
    } else {
      // if (e !== null) {
      localStorage.setItem('reportsVehicle', JSON.stringify(e));
      this.selectedVehicle = e.deviceId;
      this.selectedDevice = e;
    }
    this.searchModel.messageType = null;
    this.messageTypeArray = [];
    if (this.startTime && this.endTime) this.refresh();
  }

  onSideMenuToggle() {
    this.isSideMenuClose = !this.isSideMenuClose;
    const temp = JSON.parse(JSON.stringify(this.packetHistory));
    this.packetHistory.length = 0;
    this.packetHistory = temp;
  }

  getMessageType(architectureType) {
    this.dataService.getMessageType(architectureType).subscribe({
      next: res => {
        this.messageTypeArray = res;
        // this.searchModel.messageType = this.messageTypeArray[0].messageType;
      },
      error: error => {
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  refresh() {
    this.page = 0;
    this.pageInfo.offset = 0;
    this.isLoading = true;
    if (this.selectedDevice.architectureType) this.getMessageType(this.selectedDevice.architectureType);
    this.getPacketHistory(this.selectedVehicle, this.startTime, this.endTime, this.searchModel.messageType);
    this.getCounts(this.selectedVehicle, this.startTime, this.endTime, this.searchModel.messageType);
  }

  setItem() {
    const reportsItem = JSON.parse(localStorage.getItem('reportsVehicle'));
    this.querySelectVehicle = reportsItem;
    this.selectedVehicle = reportsItem.deviceId;
    this.selectedDevice = reportsItem;
  }

  // refresh() {
  //   this.isLoading = true;
  //   this.getPacketHistory(this.selectedVehicle, this.startTime, this.endTime, this.searchModel.messageType);
  //   this.getCounts(this.selectedVehicle, this.startTime, this.endTime, this.searchModel.messageType);
  // }

  getPacketHistory(deviceId, startTime, endTime, messageType) {
    this.liveService.getSingleVehicleMessage(deviceId, this.page, this.attributes, startTime, endTime, 100, messageType)
      .subscribe(res => {
      if (this.page === 0) this.packetHistory = res;
      else this.packetHistory = [...this.packetHistory, ...res];
      this.prevResponse = res.length;
      this.isLoading = false;
      // for pagination count
      if (this.page === 0) {
        if (res.length) {
          this.currentPageCount.start = (this.pageInfo.offset * this.pageInfo.pageSize) + 1;
          if (this.packetHistory.length <= 10) {
            this.currentPageCount.end = this.packetHistory.length;
          } else {
            this.currentPageCount.end = (this.pageInfo.offset * this.pageInfo.pageSize) + this.pageInfo.limit;
          }
        }
      }
    }, error => {
      this.isLoading = false;
      this.sharedService.getErrorMsg(error);
    });
  }

  getCounts(deviceId, startTime, endTime, messageType) {
    // if(!this.searchModel.messageType) {
    this.isLoading = true;
    this.liveService.getPacketCount(deviceId, startTime, endTime, this.searchModel.messageType)
    .subscribe(res => {
      this.totalCount = res;
    }, error => {
      this.sharedService.getErrorMsg(error);
    });
    // }
  }

  getMessageJSON(messageId) {
    this.messageJsonLoader = true;
    this.dataService.getMessage(messageId).subscribe((res: any) => {
      this.messageJsonLoader = false;
      this.messageJsonRaw = JSON.stringify(res, null, 4);
      this.messageJsonFormattedString = this.sharedService.syntaxHighlight([res]);
    }, error => {
      this.messageJsonLoader = false;
      this.sharedService.getErrorMsg(error);
    });
  }

  downloadMessageJSON() {
    const blob = new Blob([this.messageJsonRaw], {
      type: 'text/json;charset=utf-8'
    });
    FileSaver.saveAs(blob, 'message.json');
  }

  pageCallback(value) {
    this.pageInfo.offset = value.offset;
    this.currentPageCount.start = (value.offset * value.pageSize) + 1;
    const lastPage = Math.floor(this.packetHistory.length / 10);
    if (lastPage == value.offset) {
      this.currentPageCount.end = this.totalCount.count;
    } else {
      this.currentPageCount.end = (value.offset * value.pageSize) + value.limit;
    }
    if (lastPage === (this.pageInfo.offset + 1)) {
      if (this.prevResponse) {
        this.page = ++this.page;
        this.getPacketHistory(this.selectedVehicle, this.startTime, this.endTime, this.searchModel.messageType);
      }
    }
  }

  downloadCSV() {
    this.reportsService.downloadPacketHistoryCSV(this.selectedVehicle, this.startTime, this.endTime, this.searchModel.messageType)
    .subscribe({
      next: res => {
        const contentDisposition = res.headers.get('Content-Disposition');
        let filename;
        if (contentDisposition == null) {
          const date = new Date();
          filename = 'Packet_History_' + date + '.zip';
        } else {
          filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
        }
        const blob = new Blob([res.body], {
          type: 'application/zip'
        });
        FileSaver.saveAs(blob, filename);
      },
      error: err => {
        this.sharedService.getErrorMsg(err);
      }
    });
  }

}
