import { Component, Input, OnChanges, OnInit, SimpleChanges, NgZone, ViewChild, ViewChildren, QueryList, ElementRef, Output, EventEmitter } from '@angular/core';
import { configMessage } from '@shared/config-message';
import { SharedService } from '@myproject/shared/shared.service';
import { LiveService } from '@components/live/live.service';
import { TripsComponent } from '@components/live/trips/trips.component';
declare var $: any;
import Chart from 'chart.js';
import * as _ from 'lodash';
@Component({
  selector: 'app-obd-pid-view',
  templateUrl: './obd-pid-view.component.html',
  styleUrls: ['./obd-pid-view.component.scss']
})
export class ObdPidViewComponent implements OnInit, OnChanges {
  @Input() selectedDevice: any;
  @Input() tsTime: any;
  @Input() teTime: any;
  @Input() activeTab: any;
  @Input() isHistoryToggleOn: boolean;
  // applicable for only history page toggle mode on TripsComponent
  // as this page have trips first trip time start and end will be passed
  @Input() onDateChange: any;
  @Output() update = new EventEmitter<{}>();
  drawerIsOpen = false;
  allConstraints = [];
  tempAllConstraints = configMessage.allPids;
  supConstraints = [];
  supIconConstraints = [];
  supportedPidOrder = [13, 12, 17, 166, 5, 15, 1];
  activePids = [];
  smallContainer = true;
  // Chart Variables
  visualizing = false;
  chartLoading = false;
  // lineChart: any = null;
  pidLabel = [];
  pid1Data = [];
  pid2Data = [];
  durationOfTrip: string = '';
  // initiated = false;

  // obd search
  searchModel: any = {
    value: '',
    search: false,
    searchBy: 'pidNo',
    hidden: []
  };
  searchheader = [
    { displayName: 'PID Number', name: 'pidNo' },
    { displayName: 'OBD Message', name: 'msg' }
  ];
  isLoading = false;
  tabPos = 0;
  op = null;
  selectedPID = [];
  lastDevice = {
    deviceId: {
      prev: null,
      cur: null
    },
    ts: {
      prev: null,
      cur: null
    },
    te: {
      prev: null,
      cur: null
    }
  };
  onSearchNoDF = false;
  noGraphData = false;
  iconPath = "../../../assets/img/OBD_Icons/";
  subscribedReq = [];
  subscribedReqOnLoad = [];

  // gps tab variables
  activeGPS = [];
  tempGPSConstraints = configMessage.gpsPids;

  // dashCam
  dashCamPos = 0;
  dashCamArr = [];
  selVid = {
    eventName: null,
    videoURL: null,
    initSpeed: null,
    FinalSpeed: null,
    maxBraking: null,
    camType: null,
    msgId: null
  };
  frontCamCount = 0;
  backCamCount = 0;
  inCabinCount = 0;
  videoCount = 0;
  dashCamVideoAvailable = true;
  selected = [];
  pidAllConstraints = [];
  prevPidRes = {
    collected: [],
    supported: []
  };
  @ViewChild('currentPlaying', { static: true }) currentPlaying: ElementRef;

  filteredData = [];
  loading = {
    getSupported: true,
    graph: true
  };
  isHavingTrips = true;
  change = false;
  noDevices = false;
   // parameters declaring for trip component
   isHeightHighResolution = {
     isHigh: false,
     trip_view: true,
     msg_view: false,
     pid_view: true
   };
   tripViewToggle = true;
  disablePointerEvents = false;
  constructor(
    private liveService: LiveService,
    private sharedService: SharedService,
    private ngZone: NgZone
  ) { }

  ngOnInit() {
    window["obdPid"] = this;
    this.getAllPids();
  }
  ngOnChanges(changes: SimpleChanges) {
    // if (this.selectedDevice && this.tsTime && this.teTime && ((this.lastDevice.deviceId.prev === null || this.lastDevice.deviceId.prev !== this.selectedDevice.deviceId) || (this.lastDevice.ts.prev === null || this.lastDevice.ts.prev !== this.tsTime) || (this.lastDevice.te.prev === null || this.lastDevice.te.prev !== this.teTime))) {
    if (changes.hasOwnProperty('onDateChange') && changes.onDateChange.currentValue !== 0 && changes.onDateChange.previousValue !== changes.onDateChange.currentValue) {
      this.tsTime = null;
      this.teTime = null;
      this.isHavingTrips = false;
      document.getElementById('legend').innerHTML = '';
      document.getElementById('chartcontainer').innerHTML = '';
      if (this.selected.length) { this.resetOBDPids(); this.defaultOBDPids(); this.searchModel.value = ''; }
      if (this.activeGPS.length) { this.resetGPS(); }
    }
    if (changes.hasOwnProperty('tsTime') && changes.tsTime.currentValue !== null && changes.tsTime.previousValue !== changes.tsTime.currentValue) {
      this.tsTime = changes.tsTime.currentValue;
    }
    if (changes.hasOwnProperty('teTime') && changes.teTime.currentValue !== null && changes.teTime.previousValue !== changes.teTime.currentValue) {
      this.teTime = changes.teTime.currentValue;
    }
    if (changes.hasOwnProperty('selectedDevice') && changes.selectedDevice.currentValue && Object.keys(changes.selectedDevice.currentValue).length !== 0 && ((changes.selectedDevice.firstChange) || (changes.selectedDevice.previousValue.deviceId !== changes.selectedDevice.currentValue.deviceId))) {
      this.selectedDevice = changes.selectedDevice.currentValue;
      if (!changes.selectedDevice.firstChange && this.isHistoryToggleOn) {
        this.tsTime = null;
        this.teTime = null;
      }
    }
    // if (changes.hasOwnProperty('vehicleChange') && changes.vehicleChange.currentValue !== 0 && changes.vehicleChange.previousValue !== changes.vehicleChange.currentValue) {
    //   this.tsTime = null;
    //   this.teTime = null;
    // }
    // this.tsTime = 1663116370000;
    // this.teTime = 1663202770000;
    if (this.areParametersValid()) {
      this.isHavingTrips = true;
      if (this.subscribedReqOnLoad.length) { this.subscribedReqOnLoad[0].unsubscribe(); this.subscribedReqOnLoad = []; }
      this.noGraphData = false;
      this.loading.graph = true;
      this.dashCamPos = 0;
      this.filteredData = [];
      document.getElementById('legend').innerHTML = '';
      document.getElementById('chartcontainer').innerHTML = '';
      // const chart = document.getElementById('chartcontainer');
      // if (chart) { chart.style.display = 'none'; }
      if (this.activePids.length) { this.resetOBDPids(); this.searchModel.value = ''; }
      if (this.activeGPS.length) { this.resetGPS(); }
      // this.initiated = !this.initiated;
      this.lastDevice.deviceId.prev = this.selectedDevice.deviceId;
      this.lastDevice.ts.prev = this.tsTime;
      this.lastDevice.te.prev = this.teTime;
      this.getDashcam();
      if (this.tabPos === 1) {
        this.activeGPS.push('ALTITUDE');
        this.activeGPS.push('COG');
        this.update.emit({
          tabPos: this.tabPos,
          selected: this.activeGPS,
          device: this.selectedDevice,
          tsTime: this.tsTime,
          teTime: this.teTime
        });
        // this.renderGraphOnTab(); 
      }
      else if (this.tabPos === 0) { this.getSupportedPids(); }
    } else if (changes.hasOwnProperty('selectedDevice') && (changes.selectedDevice.currentValue === null || changes.selectedDevice.currentValue === undefined)) {
      this.noDevices = true;
      this.tabPos = -1;
    }
  }
  areParametersValid() {
    if (!this.selectedDevice || !Object.keys(this.selectedDevice).length) {
      return false;
    } else if ((!this.tsTime || !this.teTime)) {
      // alert('No Active Trips on selected Date Range');
      return false;
    }
    return true;
  }
  renderGraphOnTab() {
    if (this.areParametersValid()) {
      this.loading.graph = true;
      if (this.tabPos === 0) {
        this.getSupportedPids();
      } else if (this.tabPos === 1) {
        this.activeGPS = [];
        this.activeGPS.push('ALTITUDE');
        this.activeGPS.push('COG');
        this.update.emit({
          tabPos: this.tabPos,
          selected: this.activeGPS,
          device: this.selectedDevice,
          tsTime: this.tsTime,
          teTime: this.teTime
        });
        // this.getChartData('gps');
      } else {
        this.loading.graph = false;
        this.dashCamVideoAvailable = this.checkVideoAvailable('FRONT');
        this.update.emit({
          tabPos: this.tabPos,
          selected: [],
          selectedPidIds: [],
          device: this.selectedDevice,
          tsTime: this.tsTime,
          teTime: this.teTime,
          dashCam: this.dashCamArr.length ? this.dashCamArr[0] : undefined
        });
      }
    }
  }
  // Open Close Drawer
  toggleDrawer() {
    this.drawerIsOpen = !this.drawerIsOpen;
    this.drawerIsOpen ? $('#drawerSlideButton').css('top', '10.5rem') : $('#drawerSlideButton').css('top', '15.3rem');
    this.smallContainer = true;
  }
  getAllPids() {
    Object.keys(this.tempAllConstraints).map(key => {
      this.allConstraints.push(this.tempAllConstraints[key]);
    });
    localStorage.setItem('allPids', JSON.stringify(this.allConstraints));
  }
  getDashcam() {
    this.loading.graph = true;
    this.dashCamVideoAvailable = true;
    this.sharedService.getDashcam(this.selectedDevice.deviceId, this.tsTime, this.teTime)
      .subscribe(res => {
        this.loading.graph = false;
        if (res && res.length) {
          this.dashCamArr = res;
          this.dashCamVideoAvailable = true;
          this.videoCount = res.length;
          this.onVideoClick(res[0]);
          this.frontCamCount = 0;
          this.backCamCount = 0;
          this.inCabinCount = 0;
          for (let i = 0; i < res.length; i++) {
            if (res[i].camType === 'FRONT') {
              this.frontCamCount++;
            } else if (res[i].camType === 'BACK') {
              this.backCamCount++;
            } else if (res[i].camType === 'INCABIN') {
              this.inCabinCount++;
            }
          }
        } else {
          this.dashCamArr = [];
          this.frontCamCount = 0;
          this.backCamCount = 0;
          this.inCabinCount = 0;
          this.videoCount = 0;
          this.dashCamVideoAvailable = false;
        }
        this.update.emit({
          tabPos: this.tabPos,
          selected: this.tabPos === 1 ? this.activeGPS : this.selected,
          selectedPidIds: !this.selected.length ? [] : this.selected.length === 1 ? [this.selected[0].pid] : [this.selected[0].pid, this.selected[1].pid],
          device: this.selectedDevice,
          tsTime: this.tsTime,
          teTime: this.teTime,
          dashCam: res[0]
        });
      }, error => {
        this.loading.graph = false;
        this.dashCamVideoAvailable = false;
        this.dashCamArr = [];
        this.frontCamCount = 0;
        this.backCamCount = 0;
        this.videoCount = 0;
      });
  }
  getSupportedPids() {
    this.loading.graph = true;
    const req = this.liveService.getSupportedPids(this.selectedDevice.deviceId, this.tsTime, this.teTime)
      .subscribe(res => {
        const supported = res.supported;
        const collected = res.collected;
        this.pidAllConstraints = [];
        this.filteredData = [];
        this.selected = [];
        this.loading.getSupported = false;
        this.loading.graph = false;
        if (this.prevPidRes.collected.length) {
          this.patchOBDJson(this.prevPidRes.collected, 'collected', false);
        }
        if (this.prevPidRes.supported.length) {
          this.patchOBDJson(this.prevPidRes.supported, 'supported', false);
        }
        if (supported && supported.length) {
          this.patchOBDJson(supported, 'supported', true);
        }
        if (collected && collected.length) {
          const tempArr = [];
          this.patchOBDJson(collected, 'collected', true);
          for (let i = 0; i < this.supportedPidOrder.length; i++) {
            let val = this.supportedPidOrder[i];
            let id = collected.indexOf(val);
            if (id >= 0) {
              tempArr.push(val);
              collected.splice(id, 1);
            }
          }
          for (let k = (tempArr.length - 1); k >= 0; k--) {
            collected.unshift(tempArr[k]);
          }
          collected.map(item => {
            let obj = this.tempAllConstraints["PID_" + item];
            this.pidAllConstraints.push(obj);
          });
          for (let pid in this.tempAllConstraints) {
            if (collected.indexOf(this.tempAllConstraints[pid].pid) === -1) {
              this.pidAllConstraints.push(this.tempAllConstraints[pid]);
            }
          }
          if (collected.length > 1) {
            this.selected.push(this.pidAllConstraints[0]);
            this.selected.push(this.pidAllConstraints[1]);
          } else {
            this.selected.push(this.pidAllConstraints[0]);
          }
          this.filteredData = this.pidAllConstraints;
          // this.getChartData(this.tabPos === 0 ? 'pids' : 'gps');
        } else {
          this.defaultOBDPids();
          // const responseMessage = { success: false, message: `No Supported PID's for this trip!` };
          // this.sharedService.setResponseMessage(responseMessage);
        }
        this.update.emit({
          tabPos: this.tabPos,
          selected: this.selected,
          selectedPidIds: !this.selected.length ? [] : this.selected.length === 1 ? [this.selected[0].pid] : [this.selected[0].pid, this.selected[1].pid],
          device: this.selectedDevice,
          tsTime: this.tsTime,
          teTime: this.teTime
        });
      }, error => {
        this.loading.getSupported = false;
        this.loading.graph = false;
        // const responseMessage = { success: false, message: 'Something went wrong while retrieving Supported Pids!' };
        // this.sharedService.setResponseMessage(responseMessage);
      });
    this.subscribedReqOnLoad.push(req);
  }
  defaultOBDPids() {
    this.allConstraints = JSON.parse(localStorage.getItem('allPids'));
    this.noGraphData = true;
    for (let pid in this.tempAllConstraints) {
      this.tempAllConstraints[pid]['supported'] = false;
      this.tempAllConstraints[pid]['collected'] = false;
      this.filteredData.push(this.tempAllConstraints[pid]);
    }
    this.pidAllConstraints = this.filteredData;
  }
  patchOBDJson(obj, param, reset) {
    const prevLen = this.prevPidRes[param].length;
    obj.map(item => {
      const obj = this.tempAllConstraints["PID_" + item];
      obj[param] = reset;
      if (reset) { this.prevPidRes[param].push(item); }
    });
    if (!reset) { this.prevPidRes[param].splice(0, prevLen); }
  }
  activateTablePid({ selected }) {
    if (this.selected.length === 3) {
      this.selected.splice(0, 1);
    }
    if (this.areParametersValid()) {
      this.update.emit({
        tabPos: this.tabPos,
        selected: this.selected,
        selectedPidIds: !this.selected.length ? [] : this.selected.length === 1 ? [this.selected[0].pid] : [this.selected[0].pid, this.selected[1].pid],
        device: this.selectedDevice,
        tsTime: this.tsTime,
        teTime: this.teTime
      });
    } else {
      this.selected = [];
    }
  }
  compareStrings(a, b) {
    a = a.toLowerCase();
    b = b.toLowerCase();
    return (a < b) ? -1 : (a > b) ? 1 : 0;
  }
  loadFirstVideo(p1) {
    this.loading.graph = false;
    const str = p1 === 0 ? 'FRONT' : p1 === 1 ? 'BACK' : 'INCABIN';
    this.dashCamVideoAvailable = this.checkVideoAvailable(str);
    if (!this.dashCamVideoAvailable) {
      this.update.emit({
        tabPos: this.tabPos,
        selected: this.selected,
        selectedPidIds: !this.selected.length ? [] : this.selected.length === 1 ? [this.selected[0].pid] : [this.selected[0].pid, this.selected[1].pid],
        device: this.selectedDevice,
        tsTime: this.tsTime,
        teTime: this.teTime,
        dashCam: undefined
      });
    }
  }
  checkVideoAvailable(str) {
    for (let d = 0; d < this.dashCamArr.length; d++) {
      if (this.dashCamArr[d].camType === str) {
        this.onVideoClick(this.dashCamArr[d]);
        return true;
      }
    }
    return false;
  }
  onVideoClick(p1) {
    this.selVid.eventName = p1.eventName;
    this.selVid.msgId = p1.messageId;
    // this.selVid.videoURL = p1.videoURL;
    // this.selVid.initSpeed = p1.initSpeed;
    // this.selVid.FinalSpeed = p1.FinalSpeed;
    // this.selVid.maxBraking = p1.maxBraking;
    // this.currentPlaying.nativeElement.src = p1.videoURL;
    // this.currentPlaying.nativeElement.playsInline();
    // this.currentPlaying.nativeElement.play();
    this.selVid.camType = p1.camType === 'FRONT' ? 'Front' : p1.camType === 'BACK' ? 'Back' : 'In Cabin';
    this.update.emit({
      tabPos: this.tabPos,
      selected: this.selected,
      selectedPidIds: !this.selected.length ? [] : this.selected.length === 1 ? [this.selected[0].pid] : [this.selected[0].pid, this.selected[1].pid],
      device: this.selectedDevice,
      tsTime: this.tsTime,
      teTime: this.teTime,
      dashCam: p1
    });
  }
  getDashcamTitle(p1) {
    if (p1) {
      const str = p1.split('_');
      return str[0] + ' ' + str[1];
    }
  }
  resetOBDPids() {
    this.activePids = [];
    this.pid1Data = [];
    this.pid2Data = [];
    this.pidLabel = [];
    this.loading.graph = false;
    this.durationOfTrip = '';
    this.selected = [];
    // if (this.lineChart) { this.lineChart.destroy(); document.getElementById('legend').innerHTML = ''; }
  }
  resetGPS() {
    this.activeGPS = [];
    this.pid1Data = [];
    this.pid2Data = [];
    this.pidLabel = [];
    this.loading.graph = false;
    this.durationOfTrip = '';
    // if (this.lineChart) { this.lineChart.destroy(); document.getElementById('legend').innerHTML = ''; }
  }
  getId(i, item) {
    return item.title + i.toString();
  }
  setHeight() {
    const leftColHeight = $('#leftCol' + (this.activeTab + 1)).height();
    const navHeight = $('.nav.nav-tabs.detailed-tabs').height();
    const footer = $('footer').height();
    if (this.tabPos === 0) {
      const searchBarHeight = $('.input-group.input-group-lg.searchVeh.liveSearch').height();
      $('#pidTable > div > .datatable-body').css('height', leftColHeight - (navHeight + searchBarHeight + footer + 30));
    } else if (this.tabPos === 1) {
      $('#gps').css('height', leftColHeight - (navHeight));
    } else {
      const camRow = $('.cambtn').height();
      $('#cam').css('height', leftColHeight - (camRow + 7));
    }
  }
  onSearch() {
    this.onSearchNoDF = false;
    const inputVal = this.searchModel.value.trim();
    if (inputVal) {
      const val = inputVal.toLowerCase();
      let searchStr = val.replace(/[^a-z]/gi, '');
      let searchNo = parseInt(val, 10);
      const searchModel = this.searchModel;
      if (searchModel.searchBy === 'pidNo') { this.filterOnSearch(searchNo, true); }
      else if (searchModel.searchBy === 'msg') { this.filterOnSearch(val, false); }
    } else {
      this.filteredData = this.pidAllConstraints;
    }
  }
  filterOnSearch(searchParam, searchByPidNo) {
    this.filteredData = [];
    // this.selected = [];
    this.pidAllConstraints.map((supPid) => {
      const pidNoStr = supPid.pid.toString();
      if ((searchByPidNo && pidNoStr.includes(searchParam)) || (!searchByPidNo && (supPid.description.toLowerCase()).includes(searchParam))) {
        this.filteredData.push(supPid);
      }
    });
    if (!this.filteredData.length) { this.onSearchNoDF = true; }
  }
  revertHiddenPids() {
    this.searchModel.hidden.map((id) => {
      $('#pid' + id).show();
    });
    this.searchModel.hidden = [];
  }

  // methods for gps tab
  activeGPSHndlr(p1) {
    if (this.areParametersValid()) {
      this.disablePointerEvents = true;
      if (this.activeGPS.includes(p1)) {
        // if (this.lineChart) { this.lineChart.destroy(); document.getElementById('legend').innerHTML = ''; }
        const index = this.activeGPS.indexOf(p1);
        this.activeGPS.splice(index, 1);
        // if (this.activeGPS.length)
        // this.getChartData('gps');
      } else if (this.activeGPS.length < 2) {
        this.activeGPS.push(p1);
        // if (this.lineChart) { this.lineChart.destroy(); document.getElementById('legend').innerHTML = ''; }
        // this.getChartData('gps');
      } else if (this.activeGPS.length === 2) {
        this.activeGPS.splice(0, 1);
        this.activeGPS.push(p1);
        // if (this.lineChart) { this.lineChart.destroy(); document.getElementById('legend').innerHTML = ''; }
        // this.getChartData('gps');
      }
    } else {
      this.activeGPS = [];
    }
    if (!this.activeGPS.length) { this.disablePointerEvents = false; }
    this.update.emit({
      tabPos: this.tabPos,
      selected: this.activeGPS,
      device: this.selectedDevice,
      tsTime: this.tsTime,
      teTime: this.teTime,
      disablePointerEvents: this.disablePointerEvents
    });
  }
  resetSearch() {
    this.searchModel.value = '';
    this.filteredData = this.pidAllConstraints;
    this.onSearchNoDF = false;
  }
}
