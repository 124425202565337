import { Component, OnInit, OnDestroy, ElementRef, ViewChild, Input, AfterViewInit } from '@angular/core';

import { SharedService } from '@shared/shared.service';
import { Title } from '@angular/platform-browser';
import * as FileSaver from 'file-saver';
import { DeviceService } from '@components/device/device.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '@myenv/environment';
import { enMessage } from '@shared/en-us-message';
import { configMessage } from '@shared/config-message';
import { AdminService } from '@components/admin/admin.service';

@Component({
  selector: 'app-device-view-report',
  templateUrl: './device-view-report.component.html',
  styleUrls: ['./device-view-report.component.scss']
})
export class DeviceViewReportComponent implements OnInit, AfterViewInit {
  @ViewChild('customHeader', { static: false }) customHeader;
  @ViewChild('closeHeaderPopup', { static: false }) closeHeaderPopup: ElementRef;
  headerSettings: any;
  selectedHeaders: any = [];
  headerName: any = [];
  deviceStatus = configMessage.deviceStatus;
  deviceStatusClass = configMessage.deviceStatusClass;
  selectedDevice: any;
  map: google.maps.Map;
  @ViewChild('gmap', { static: false }) gmapElement: any;
  markers: any = [];
  dateAttributes = configMessage.dateAttributes;
  // deviceStatus = configMessage.deviceStatus;
  public dateFormat = environment.isoDateTimeFormat;
  public noSecondsTimeFormat = environment.noSecondsTimeFormat;
  // This to display activity property from config
  activityAttribute = configMessage.activityConfig;
  pageLoading = true;
  listPage = true;
  disable = false;
  responseMessage: any = {};
  public devices: any = [];
  public device: any = {};
  public deviceId = '';
  serialNumber = '';
  deviceDetail: any = {};
  deviceHeader: any = [];
  deviceSearchheader: any = [];
  searchDevice: any = [];
  deviceFullHeader: any = [];
  deviceAllHeader: any = [];
  deviceAllString: any = [];
  // To display device detail in tab
  singleDeviceDetail: any = [];

  deviceStaticHeader = [
    { name: 'serialNumber', displayName: 'Serial Number', type: 'STRING', visible: true },
    { name: 'assetName', displayName: 'Name', type: 'STRING', visible: true },
    { name: 'imei', displayName: 'IMEI', type: 'STRING', visible: true },
    { name: 'simCcid', displayName: 'SIM ICCID', type: 'STRING', visible: true },
    { name: '', displayName: '', type: '', visible: true },
    { name: 'productSerialNumber', displayName: 'Product Serial Number', type: 'STRING', visible: true },
    // {name: 'deviceStatus', displayName: 'Device Status', type: 'STRING', visible: true},
    { name: 'firmwareVersion', displayName: 'Firmware Version', type: 'STRING', visible: true },
    { name: 'configVersion', displayName: 'Config Version', type: 'STRING', visible: true },
  ];
  noPrefColumns = ['assetName', 'serialNumber', 'imei', 'deviceStatus', 'firmwareVersion', 'configVersion'];
  finalHead: any = [];
  allColumns: any = [];
  columns: any = [];
  visibleKeys: any = [];
  slectAllOption = true;
  delBtn = false;
  @ViewChild('btnClose', { static: false }) btnClose: ElementRef;
  @ViewChild('closeeditDevice_model', { static: false }) closeeditDevice_model: ElementRef;
  @Input() sideData: any;
  moduleName = 'DEVICE';
  permissionAuthorities: any = {};
  modules: any = [];
  deviceActivity: any = [];
  currentDevice: string;
  loadmore = false;
  loadingIndicator = true;
  // loadAPI: Promise<any>;
  searchModel: any = {};
  @ViewChild('inputElement', { static: false }) inputOne: ElementRef;
  public total: number;
  public itemsPerPage: any = 10; // we are showing 10 items per page
  public lastPage = 0;
  page = 0;
  isLoading = false;
  index: number;
  currentPageCount: any = {
    start: 0,
    end: 0
  };
  confirmSerialNumber = '';
  pageInfo = {
    offset: 0,
    count: 0,
    limit: 10,
    pageSize: 0,
    lastPage: 0,
  };
  prevResponse = 0;
  message: any;
  deviceTotalCount: any;
  pageLimit: number = environment.pageLimit;
  // report: false;
  // tslint:disable-next-line:max-line-length
  tempSelection: any;
  sortParams: any = {};
  headerPref: any = [];
  checkedItems: any = [];

  // Multi Select
  headSelSettings: any = {};
  finalSelected: any = [];
  allSelected = false;
  columnPopup = false;
  updatingPref = false;
  selectCount = 0;
  timeout = null;
  countTimeOut = null;

  constructor(
    private deviceService: DeviceService,
    private router: Router,

    private sharedService: SharedService,
    private title: Title,
    private adminService: AdminService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.headerSettings = {
      singleSelection: false,
      text: 'Select Key',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 1,
      primaryKey: 'id',
      labelKey: 'displayName',
      classes: 'myclass custom-class-example width100',
      searchBy: ['displayName']
    };
    this.modules = JSON.parse(localStorage.getItem('accessRoleModules'));
    this.headerPref = JSON.parse(localStorage.getItem('preferences')).device.selectedFields;
    this.permissionAuthorities = this.modules.find(module => module.name === this.moduleName);
    this.message = this.adminService.getSideMenu();
    this.title.setTitle('Devices' + environment.title_text);
    this.getAllDevices(this.page);
    this.getDeviceCount();
    this.getDeviceHeader();
  }

  ngAfterViewInit() {
    this.initAutocomplete();
  }

  initAutocomplete() {
    var mapOptionObject = {
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      zoom: 4,
      minZoom: 4,
      maxZoom: 18,
      mapTypeControl: true,
      mapTypeControlOptions: {
        position: google.maps.ControlPosition.TOP_RIGHT
      },
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER
      },
      scaleControl: true,
      streetViewControl: false,
      fullscreenControl: false,
      clickableIcons: false,
      center: {
        lat: environment.lat,
        lng: environment.lng
      }
    }
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapOptionObject);
  }

  refreshPage() {
    this.page = 0;
    this.pageInfo.offset = 0;
    if (this.searchModel.deviceTypeVal && this.searchModel.deviceId) {
      this.searchResults();
    } else {
      this.getAllDevices(this.page);
      this.getDeviceCount();
    }
  }

  downloadSelectedDeviceCSV() {
    let finalAttr = [];
    this.finalHead.filter(item=>{
      finalAttr.push(item.name);
    });
    this.deviceService.downloadDeviceCSV(this.searchModel, undefined, undefined, finalAttr.toString()).subscribe(res => {
      const contentDisposition = res.headers.get('Content-Disposition');
      let filename;
      if (contentDisposition == null) {
        const date = new Date();
        filename = 'Device_' + date + '.csv';
      } else {
        filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
      }
      const blob = new Blob([res.body], {
        type: 'application/zip'
      });
      FileSaver.saveAs(blob, filename);

    }, error => {
      this.sharedService.getErrorMsg(error);
    });
  }

  // To get all devices
  getAllDevices(page) {
    //
    this.deviceService.getAllDevices(page, this.sortParams).subscribe(res => {
      // this.searchModel.deviceId = this.tempSelection;
      //  this.isLoading = false;
      if (page == 0) { this.devices = res; } else {
        this.devices = [...this.devices, ...res];
      }
      this.loadingIndicator = false;
      this.isLoading = true;
      this.pageInfo.count = this.devices.length;
      this.prevResponse = res.length;
      const pagecount = this.pageInfo.count / this.pageInfo.limit;
      this.pageInfo.lastPage = Math.floor(pagecount);
      const decimal = pagecount - this.pageInfo.lastPage;

      if (!Number.isInteger(decimal)) {
        this.pageInfo.lastPage = this.pageInfo.lastPage + 1;
      }
      if (page == 0) {
        if (res.length) {
          this.currentPageCount.start = (this.pageInfo.offset * this.pageInfo.pageSize) + 1;
          if (this.devices.length <= 10) {
            this.currentPageCount.end = this.pageInfo.count;
          } else {
            this.currentPageCount.end = (this.pageInfo.offset * this.pageInfo.pageSize) + this.pageInfo.limit;
          }
        }
      }
      this.pageLoading = false;
    }, error => {
      this.pageLoading = false;
      this.sharedService.getErrorMsg(error);
    });
  }

  // To get the devices for each page in the table
  // pageCallback(value) {
  //   this.pageInfo.offset = value.offset;
  //   this.currentPageCount.start = (value.offset * value.pageSize) + 1;
  //   const lastPage = Math.floor(this.devices.length / 10);
  //   if (lastPage == value.offset) {
  //     this.currentPageCount.end = value.count;
  //   } else {
  //     this.currentPageCount.end = (value.offset * value.pageSize) + value.limit;
  //   }
  //   if (this.pageInfo.lastPage === (this.pageInfo.offset + 1)) {
  //     // tslint:disable-next-line:no-unused-expression
  //     if (this.prevResponse) {
  //       this.page = ++this.page;
  //       this.getAllDevices(this.page);
  //     }
  //   }
  // }

  // To get total device count
  getDeviceCount(deviceId?, deviceTypeValue?) {
    // this.isLoading = true;
    clearTimeout(this.countTimeOut);
    let global = this;
    this.countTimeOut = setTimeout(function () {
      global.deviceService.getVehicleCount(deviceId, deviceTypeValue).subscribe(res => {
        global.deviceTotalCount = res;
      }, error => {
        global.sharedService.getErrorMsg(error);
      });
    }, 1500);
  }

  // To get the header of Devices table
  getDeviceHeader() {
    // this.isLoading = true;
    // const headers = JSON.parse(localStorage.getItem('deviceHeaders'));
    // if (headers) {
    //   this.deviceAllHeader = headers;
    //   this.performHeaderOperations(headers);
    // } else {
    this.sharedService.getDeviceHeader().subscribe(res => {
      localStorage.setItem('deviceHeaders', JSON.stringify(res));
      this.deviceAllHeader = res;
      this.performHeaderOperations(res);
    }, error => {
      this.sharedService.getErrorMsg(error);
    });
    // }
  }

  performHeaderOperations(res) {
    this.setTableHeader(res);
    this.deviceAllString = res.map(function (item) {
      return item.name;
    });
    // tslint:disable-next-line:max-line-length
    const server = res.filter(x => {
      if (!(x.name === 'serialNumber' || x.name === 'assetName' || x.name.slice(-2) === 'Id' || x.name === 'imei' || x.name === 'deviceType' || x.name === 'type' || x.name === 'tested' || x.name === 'createdAt' || x.name === 'activatedOn' || x.name === 'lastCommunication' || x.name === 'firmwareVersion' || x.name === 'configVersion')) {
        return x;
      }
    });
    // tslint:disable-next-line:max-line-length
    this.searchDevice = res.filter(x => {
      if (!(this.dateAttributes.includes(x.name) || x.name === 'systemTag' || x.name === 'assetName' || x.name === 'tags' || x.name === 'productSerialNumber' || x.name === 'simCcid' || x.name === 'deviceStatus' || x.name === 'firmwareVersion' || x.name === 'configVersion' || x.name === 'serialNumber' || x.name === 'imei' || x.name === 'deviceType' || x.name === 'createdAt' || x.name === 'activatedOn' || x.name === 'lastCommunication' || x.name === 'groupId' || x.name === 'companyId' || x.name === 'customerId' || x.name === 'deviceId')) {
        return x;
      }
    });
    this.deviceSearchheader = this.deviceStaticHeader.concat(this.searchDevice);
    let newdeviceSearchheader = this.deviceSearchheader;
    const queryParams = this.route.snapshot.queryParams;
    if (!(queryParams.searchBy && queryParams.value)) {
      this.searchModel['deviceId'] = this.deviceSearchheader[0].name;
    }
    this.tempSelection = this.searchModel['deviceId'];
    this.deviceHeader = server;
    this.deviceHeader = server.filter(item => {
      if (item.visible) {
        return item;
      }
    });
    this.deviceSearchheader = newdeviceSearchheader.filter(item => {
      if (item.visible) {
        return item;
      }
    });
  }

  setTableHeader(res) {
    let pref = [];
    if (this.headerPref && this.headerPref.length) {
      this.noPrefColumns.forEach(item => {
        if (!this.headerPref.includes(item)) {
          this.headerPref.push(item);
        }
      })
      pref = this.headerPref;
    } else {
      pref = this.noPrefColumns;
    }
    res.map(head => {
      if (pref.includes(head.name)) {
        head.checked = true;
        this.selectCount++;
        if (head.visible == true) {
          this.finalHead.push(head);
        }
      }
    });
    if (this.selectCount == res.length) {
      this.allSelected = true;
    }
    this.allColumns = res;
    this.columns = res;
    let keyArray = [];
    let visibleKeys = [];
    this.allColumns.map(header => {
      if (header.checked && header.visible == true) {
        keyArray.push(header.name);
      }
      if (header.visible == true) {
        visibleKeys.push(header);
      }
    });
    this.visibleKeys = visibleKeys;
    this.columns = this.allColumns;
    this.checkedItems = keyArray;
  }

  togglePopup() {
    if (this.columnPopup) {
      this.cancelSelection();
    } else {
      this.columnPopup = true;
      this.checkCustomSelected();
    }
  }

  checkCustomSelected() {
    this.selectCount = 0;
    if (this.searchModel && this.searchModel.columnVal) {
      this.searchModel.columnVal = '';
    }
    // let prefrences = JSON.parse(localStorage.getItem('preferences'));
    // this.allColumns = prefrences.device.selectedFields;
    this.allColumns.map(head => {
      if (this.checkedItems.includes(head.name)) {
        this.selectCount++;
        head.checked = true;
      } else {
        head.checked = false;
      }
      // if (this.noPrefColumns.includes(head.name)) {
      //   head.checked = true;
      //   this.selectCount++;
      // }
    });
    this.columns = this.allColumns;
    if (this.selectCount == this.checkedItems) {
      this.allSelected = true;
    }
  }

  selectItems(event, header, i) {
    if (!this.noPrefColumns.includes(header.name)) {
      if (event.target.checked) {
        this.selectCount++;
      } else {
        this.selectCount--;
      }
      this.allColumns.map(head => {
        if (head.name == header.name) {
          if (event.target.checked) {
            head.checked = true;
          } else {
            head.checked = false;
          }
        }
      });
      // this.allColumns[i].checked = event.target.checked;
    }
  }

  selectAllItems(event) {
    this.allSelected = event.target.checked;
    if (event.target.checked) {
      this.selectCount = this.allColumns.length;
    } else {
      this.selectCount = 0;
    }
    this.allColumns.map(head => {
      if (this.noPrefColumns.includes(head.name)) {
        head.checked = true;
      } else {
        head.checked = event.target.checked;
      }
    });
  }

  cancelSelection() {
    this.selectCount = 0;
    this.columnPopup = false;
  }

  updatePreference() {
    let tempArray = [];
    let keyArray = [];
    this.updatingPref = true;
    this.selectCount = 0;
    this.allColumns.map(header => {
      if (header.checked) {
        const obj = {
          name: header.name,
          displayName: header.displayName,
          checked: header.checked,
          type: header.type,
          visible: header.visible
        };
        tempArray.push(obj);
        keyArray.push(header.name);
      }
    });
    const payload = { device: { selectedFields: keyArray } };
    this.sharedService.updatePreference(payload).subscribe(res => {
      this.finalHead = tempArray;
      this.headerPref = tempArray;
      this.checkedItems = keyArray;
      this.columnPopup = false;
      this.updatingPref = false;
      this.columns = this.allColumns;
      const updateStorage = JSON.parse(localStorage.getItem('preferences'));
      if (updateStorage.device.selectedFields) {
        updateStorage.device.selectedFields = keyArray;
        localStorage.setItem('preferences', JSON.stringify(updateStorage));
      }
    }, error => {
      this.sharedService.getErrorMsg(error);
      this.updatingPref = false;
    });
  }

  downloadDeviceCSV() {
    this.deviceService.downloadDeviceCSV(this.searchModel).subscribe(res => {
      const contentDisposition = res.headers.get('Content-Disposition');
      let filename;
      if (contentDisposition == null) {
        const date = new Date();
        filename = 'Device_' + date + '.csv';
      } else {
        filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
      }
      const blob = new Blob([res.body], {
        type: 'application/zip'
      });
      FileSaver.saveAs(blob, filename);

    }, error => {

    });
  }

  clearSearch(f) {
    this.tempSelection = this.searchModel.deviceId;
    this.searchModel.deviceTypeVal = '';
    if (f.valid) {
      this.devices = [];
      f.resetForm();
      this.loadingIndicator = true;
      this.page = 0;
      this.pageInfo.offset = 0;
      this.getAllDevices(this.page);
      this.getDeviceCount();
    }
  }

  onSort(event) {
    this.page = 0;
    this.loadingIndicator = true;
    this.sortParams = event.sorts[0];
    if (this.searchModel.deviceTypeVal) {
      const deviceTypeValue = this.searchModel.deviceTypeVal.trim();
      this.deviceService.getAllDevices(0, event.sorts[0], this.searchModel.deviceId, deviceTypeValue).subscribe(res => {
        this.devices = res;
        this.loadingIndicator = false;
        this.setPageCount(res);
      }, error => {
        this.sharedService.getErrorMsg(error);
      });
    } else {
      this.deviceService.getAllDevices(0, event.sorts[0]).subscribe(res => {
        this.devices = res;
        this.loadingIndicator = false;
        this.setPageCount(res);
      }, error => {
        this.sharedService.getErrorMsg(error);
      });
    }
  }

  setPageCount(res) {
    this.pageInfo.count = this.devices.length;
    this.prevResponse = res.length;
    const pagecount = this.pageInfo.count / this.pageInfo.limit;
    this.pageInfo.lastPage = Math.floor(pagecount);
    const decimal = pagecount - this.pageInfo.lastPage;

    if (!Number.isInteger(decimal)) {
      this.pageInfo.lastPage = this.pageInfo.lastPage + 1;
    }
    if (this.page == 0) {
      if (res.length) {
        this.currentPageCount.start = (this.pageInfo.offset * this.pageInfo.pageSize) + 1;
        if (this.devices.length <= 10) {
          this.currentPageCount.end = this.pageInfo.count;
        } else {
          this.currentPageCount.end = (this.pageInfo.offset * this.pageInfo.pageSize) + this.pageInfo.limit;
        }
      }
    }
  }

  onColumnSearch() {
    this.columns = this.allColumns;
    const inputVal = this.searchModel.columnVal.trim();
    const val = inputVal.toLowerCase();
    if (val === '') {
      this.slectAllOption = true;
      this.columns = this.allColumns;
      return
    } else {
      if (this.allColumns.length) {
        this.slectAllOption = false;
        const temp = this.allColumns.filter((item, index) => {
          if (item.displayName.toLowerCase().includes(val)) {
            return this.allColumns[index];
          }
        });
        this.columns = temp;
      }
    }

  }

  // searchDataOnChange() {
  //   if(this.searchModel.searchValue && this.searchModel.searchId) {
  //     this.onSearch();
  //   }
  // }

  selectInput() {
    const inputElem = <HTMLInputElement>this.inputOne.nativeElement;
    inputElem.select();
  }

  public inputValidator(event: any) {
    const term = event.target.value.replace(/[^_\-@.,a-zA-Z0-9\s]/g, '');
    this.searchModel.deviceTypeVal = term.split(/[ ]+/).filter(function (v) { return v !== '' }).join(',');
    this.searchResults();
  }

  // To get the devices for each page in the table
  pageCallback(value) {
    this.pageInfo.offset = value.offset;
    this.currentPageCount.start = (value.offset * value.pageSize) + 1;
    const lastPage = Math.floor(this.devices.length / 10);
    if (lastPage == value.offset) {
      this.currentPageCount.end = value.count;
    } else {
      this.currentPageCount.end = (value.offset * value.pageSize) + value.limit;
    }
    if (this.pageInfo.lastPage === (this.pageInfo.offset + 1)) {
      // tslint:disable-next-line:no-unused-expression
      if (this.prevResponse) {
        this.page = ++this.page;
        if (this.searchModel.deviceTypeVal && this.searchModel.deviceId) {
          this.onSearch(this.page);
        } else {
          this.getAllDevices(this.page);
        }
      }
    }
  }
  // debounce(func, delay) {
  //   var d;
  //   var context = this;
  //   return function (...args) {
  //     // const args = arguments;
  //     clearTimeout(d);
  //     d = setTimeout(() => {
  //       func.apply(context, args)
  //     }, delay);
  //   }
  // }


  searchResults() {
    // if (this.searchModel.deviceTypeVal && this.searchModel.deviceId) {
    this.pageInfo.offset = 0;
    this.page = 0;
    this.onSearch(this.page);
    let deviceTypeValue = this.searchModel.deviceTypeVal;
    if (deviceTypeValue) {
      deviceTypeValue = deviceTypeValue.trim();
    }
    this.getDeviceCount(this.searchModel.deviceId, deviceTypeValue);
    // let count = this.debounce(this.getDeviceCount, 1000)(this.searchModel.deviceId, deviceTypeValue);
    // }
  }

  // To get a device based on selected filter
  onSearch(page) {
    clearTimeout(this.timeout);
    let global = this;
    this.timeout = setTimeout(function () {
      if (global.searchModel.deviceTypeVal && global.searchModel.deviceId) {
        let deviceTypeValue = global.searchModel.deviceTypeVal;
        if (deviceTypeValue) {
          deviceTypeValue = deviceTypeValue.trim();
        }
        global.sharedService.onSearchDevice(global.searchModel.deviceId, deviceTypeValue, page,).subscribe(res => {
          global.prevResponse = res.length;
          if (page == 0) {
            global.devices = res;
          } else {
            global.devices = [...global.devices, ...res];
          }
          global.loadingIndicator = false;
          global.isLoading = true;
          global.pageInfo.count = global.devices.length;
          const pagecount = global.pageInfo.count / global.pageInfo.limit;
          global.pageInfo.lastPage = Math.floor(pagecount);
          const decimal = pagecount - global.pageInfo.lastPage;

          if (!Number.isInteger(decimal)) {
            global.pageInfo.lastPage = global.pageInfo.lastPage + 1;
          }
          if (page == 0) {
            if (res.length) {
              global.currentPageCount.start = (global.pageInfo.offset * global.pageInfo.pageSize) + 1;
              if (global.devices.length <= 10) {
                global.currentPageCount.end = global.pageInfo.count;
              } else {
                global.currentPageCount.end = (global.pageInfo.offset * global.pageInfo.pageSize) + global.pageInfo.limit;
              }
            }
          }
        }, error => {
          global.sharedService.getErrorMsg(error);
        });
      } else {
        global.page = 0;
        global.pageInfo.offset = 0;
        global.getAllDevices(0);
        global.getDeviceCount();
      }
    }, 1500);
  }

  addMarker(lat, long, img) {
    const latlng = new google.maps.LatLng(lat, long);
    const marker = new google.maps.Marker({
      position: latlng,
      map: this.map,
      // label: eventDate,
      icon: img
    });
    this.setAnimation(marker);
    this.markers.push(marker);
    var bounds = new google.maps.LatLngBounds();
    bounds.extend(latlng);
    this.map.fitBounds(bounds);
    this.markers.push(marker);
  }

  // Set Animation
  setAnimation(marker) {
    marker.setAnimation(google.maps.Animation.BOUNCE);
    setTimeout(function () { marker.setAnimation(null); }, 750);
  }

  clearMarkers() {
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(null);
    }
  }

  loadMap(row) {
    this.selectedDevice = row;
    let global = this;
    const imgEnd = '../../assets/img/rect836.png';
    if (this.markers.length) {
      this.clearMarkers();
    }
    google.maps.event.addListenerOnce(this.map, 'tilesloaded', function () {
      google.maps.event.trigger(this.map, 'resize');
    });
    this.addMarker(row.latitude, row.longitude, imgEnd);
    setTimeout(function () {
      google.maps.event.trigger(global.map, 'resize');
    }, 1500);
  }

  getCustomDownloadKeysPref() {
    const pref = JSON.parse(localStorage.getItem('preferences'));
    const keys = pref && pref.deviceDownloadKeys && pref.deviceDownloadKeys.selectedFields ? pref.deviceDownloadKeys.selectedFields : [];
    const selected = [];
    if (keys.length) {
      this.visibleKeys.map(item => {
        if (keys.includes(item.name)) selected.push(item);
      });
      this.selectedHeaders = selected;
    }
  }

  onHeaderSelect(item: any) {
    let existing = false;
    this.selectedHeaders.map(header => {
      existing = header.name === item.name ? true : false;
    });
    if (!existing) this.selectedHeaders.push(item);
  }

  onHeaderDeSelect(item: any) {
    var filtered = this.selectedHeaders.filter(function (value) {
      return value != item;
    });
    this.selectedHeaders = filtered;
  }

  onSelectAllHeaders(items: any) {
    this.selectedHeaders = [];
    items.forEach(data => {
      this.selectedHeaders.push(data);
    });
  }

  onDeSelectAllHeaders(items: any) {
    this.selectedHeaders = [];
  }

  // To download table data as a csv file
  downloadCustomDeviceCSV() {
    const selectedKeys = [];
    this.selectedHeaders.filter(item => {
      selectedKeys.push(item.name);
    });
    // Update Custom Keys Preference
    this.updateCustomDownloadKeysPref(selectedKeys);
    // Download CSV
    this.deviceService.downloadDeviceCSV(this.searchModel, undefined, undefined, selectedKeys.toString()).subscribe(res => {
      const contentDisposition = res.headers.get('Content-Disposition');
      let filename;
      if (contentDisposition == null) {
        const date = new Date();
        filename = 'Device_' + date + '.zip';
      } else {
        filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
      }
      const blob = new Blob([res.body], {
        type: 'application/zip'
      });
      FileSaver.saveAs(blob, filename);
      this.closeHeaderPopup.nativeElement.click();
      this.customHeader.selectedItems = [];
      this.selectedHeaders = [];
    }, error => {
      this.sharedService.getErrorMsg(error);
    });
  }

  updateCustomDownloadKeysPref(selectedKeys) {
    this.sharedService.updatePreference({ deviceDownloadKeys: { selectedFields: selectedKeys } }).subscribe({
      next: res => {
        const pref = JSON.parse(localStorage.getItem('preferences'));
        pref.deviceDownloadKeys.selectedFields = selectedKeys;
        localStorage.setItem('preferences', JSON.stringify(pref));
      },
      error: error => {
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  removeHeader(data) {
    const filtered = this.selectedHeaders.filter(function (value) {
      return value != data;
    });
    this.customHeader.selectedItems = filtered;
    this.onHeaderDeSelect(data);
  }

}
