import { OnInit, NgZone } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { UsersService } from '@components/admin/users/users.service';
import { SharedService } from '@shared/shared.service';
import { SupportService } from '@components/support/support.service';
import { AuthenticationService } from '@layouts/login/authentication.service';
import { CookieService } from 'angular2-cookie/core';
import { enMessage } from '@shared/en-us-message';
import { environment } from '@myenv/environment';
var UserInfoComponent = /** @class */ (function () {
    function UserInfoComponent(router, ngzone, _cookieService, title, route, usersService, sharedService, supportService, authenticationService) {
        this.router = router;
        this.ngzone = ngzone;
        this._cookieService = _cookieService;
        this.title = title;
        this.route = route;
        this.usersService = usersService;
        this.sharedService = sharedService;
        this.supportService = supportService;
        this.authenticationService = authenticationService;
        this.accountTab = true;
        this.deviceTab = false;
        this.provisioningTab = false;
        this.developerTab = false;
        this.isLoading = false;
        this.currentUser = {};
        this.impersonate = false;
        this.responseMessage = {};
        this.permissionModules = [];
        this.currentPermissionModules = [];
        this.permissionAuthorities = {};
        this.modules = [];
        /**
         * For long date format
         */
        this.isoDateFormat = environment.isoDateTimeFormat;
    }
    UserInfoComponent.prototype.ngOnInit = function () {
        this.modules = JSON.parse(localStorage.getItem('accessRoleModules'));
        this.permissionAuthorities = this.modules.find(function (module) { return module.name === 'IMPERSONATE'; });
        this.user = this.route.snapshot.queryParams.id;
        this.currentPermissionModules = JSON.parse(localStorage.getItem('permissionModules'));
        this.userName = this.route.snapshot.queryParams.name;
        this.getLoginAttempts(this.user);
        this.getCurrentUser(this.user);
        this.loadAccountInfo();
    };
    UserInfoComponent.prototype.loadAccountInfo = function () {
        this.accountTab = true;
        this.deviceTab = false;
        this.provisioningTab = false;
    };
    UserInfoComponent.prototype.loadDeviceReplacement = function () {
        this.accountTab = false;
        this.deviceTab = true;
        this.provisioningTab = false;
    };
    UserInfoComponent.prototype.loadDeviceProvisioning = function () {
        this.accountTab = false;
        this.deviceTab = false;
        this.provisioningTab = true;
    };
    UserInfoComponent.prototype.getLoginAttempts = function (data) {
        var _this = this;
        this.isLoading = true;
        this.authenticationService.getUserInfo(data).subscribe(function (res) {
            if (res.loginAttempts > 4) {
                _this.unlockStatus = true;
            }
            else {
                _this.unlockStatus = false;
            }
        }, function (error) {
            _this.sharedService.getErrorMsg(error);
        });
    };
    // To unlock the user
    UserInfoComponent.prototype.unlockUser = function (data) {
        var _this = this;
        this.isLoading = true;
        this.supportService.unlockUser(data).subscribe(function (res) {
            _this.unlockStatus = false;
            _this.responseMessage = { success: true, message: enMessage.successUnlockUser };
            _this.sharedService.setResponseMessage(_this.responseMessage);
        }, function (error) {
            _this.sharedService.getErrorMsg(error);
        });
    };
    // To get current user data
    UserInfoComponent.prototype.getCurrentUser = function (data) {
        var _this = this;
        this.supportService.getUser(data).subscribe(function (res) {
            _this.userData = res;
        }, function (error) {
            _this.sharedService.getErrorMsg(error);
        });
    };
    // To Impersonate the user
    UserInfoComponent.prototype.onSearch = function () {
        var _this = this;
        this.authenticationService.impersonate(this.user).subscribe(function (res) {
            // this.vehicles = res;
            // window.location.reload();
            if (res.accessToken) {
                var base64Token = res.accessToken;
                _this.authenticationService.setHeaders(base64Token);
                _this._cookieService.put('user', 'impersonateUser');
                if (_this._cookieService.get('user')) {
                    _this.impersonate = true;
                }
                _this.getUserPreferences();
                _this.tokenScenario(res);
            }
            else if (res.status == 404) {
                _this.authenticationService.setHeaders(_this.authenticationService.getHeaders());
                var errorCode = res.errorCode;
                var errorMessage = enMessage[errorCode];
                _this.responseMessage = { success: false, message: errorMessage };
                _this.sharedService.setResponseMessage(_this.responseMessage);
            }
        }, function (error) {
            var token = _this.authenticationService.getHeaders();
            _this.authenticationService.setHeaders(token);
            if (error.status == 404) {
                _this.sharedService.getErrorMsg(error);
            }
            else {
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    // To get user preferences
    UserInfoComponent.prototype.getUserPreferences = function () {
        var _this = this;
        // Get the User Preferences by making a seperate API call
        this.authenticationService.userPreferences().subscribe(function (responseInfo) {
            if (responseInfo) {
                localStorage.setItem('preferences', JSON.stringify(responseInfo));
            }
            if (responseInfo.liveHistory || responseInfo.live.header) {
                if (responseInfo.liveHistory && responseInfo.liveHistory.header) {
                    _this.authenticationService.liveHistoryPrefrences(responseInfo.liveHistory.header);
                }
                // if (responseInfo.live && responseInfo.live.header) {
                //   this.authenticationService.livePrefrences(responseInfo.live.header);
                // }
            }
        }, function (error) {
            _this.sharedService.getErrorMsg(error);
        });
    };
    // setting the permissions and impersonating the user.
    UserInfoComponent.prototype.tokenScenario = function (res) {
        var _this = this;
        this.authenticationService.getLoggedUser().subscribe(function (reponseInFo) {
            // this.getUserPreferences();
            if (res.status === 404) {
                var errorCode = res.errorCode;
                var errorMessage = enMessage[errorCode];
                _this.responseMessage = { success: false, message: errorMessage };
                _this.sharedService.setResponseMessage(_this.responseMessage);
            }
            else {
                _this.authenticationService.setLoggedInUser(reponseInFo);
                // let responseUserRole = JSON.parse(localStorage.getItem('responseUserRole'));
                // let resPermissionModule = JSON.parse(localStorage.getItem('resPermissionModule'));
                //  this.sharedService.getUserRoleId(res.token).subscribe(responseUserRole => {
                // this.sharedService.getRoleAccessModule(reponseInFo.roleId).subscribe(resPermissionModule => {
                _this.permissionModules = reponseInFo.modules.map(function (data) { return data.name; });
                if (_this.permissionModules.includes('REPORT')) {
                    localStorage.setItem('lastReportUrl', '/reports/system/live-status');
                }
                _this.sharedService.setAccessRole(reponseInFo.modules, _this.permissionModules);
                var permissionModules = JSON.parse(localStorage.getItem('permissionModules'));
                _this.sharedService.setDefaultUrl(permissionModules);
                window.location.reload();
                localStorage.removeItem('reports');
                localStorage.removeItem('dashboards');
                localStorage.removeItem('viewReport');
                localStorage.removeItem('reportData');
                localStorage.removeItem('currentReport');
                localStorage.removeItem('reportsVehicle');
                localStorage.removeItem('unreadNotification');
                localStorage.setItem('impersonate', 'true');
                // })
                // })
            }
        }, function (error) {
            _this.sharedService.getErrorMsg(error);
        });
    };
    return UserInfoComponent;
}());
export { UserInfoComponent };
