import { Router } from '@angular/router';
import { environment } from '@myenv/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var AlReportGuard = /** @class */ (function () {
    function AlReportGuard(router) {
        this.router = router;
        this.AL_SystemReport = environment.AL_SystemReport;
    }
    AlReportGuard.prototype.canActivate = function (route, state) {
        if (this.AL_SystemReport) {
            return true;
        }
        else {
            this.router.navigate(['/']);
            return false;
        }
    };
    AlReportGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AlReportGuard_Factory() { return new AlReportGuard(i0.ɵɵinject(i1.Router)); }, token: AlReportGuard, providedIn: "root" });
    return AlReportGuard;
}());
export { AlReportGuard };
