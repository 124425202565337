import { OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '@myenv/environment';
var DeepViewComponent = /** @class */ (function () {
    function DeepViewComponent(title) {
        this.title = title;
        this.permissionModules = [];
        var url = localStorage.getItem('lastReportUrl');
        this.lastReportUrl = url ? url : 'reports/system/live-status';
    }
    DeepViewComponent.prototype.ngOnInit = function () {
        this.title.setTitle('Deep View' + environment.title_text);
        this.permissionModules = JSON.parse(localStorage.getItem('permissionModules'));
    };
    return DeepViewComponent;
}());
export { DeepViewComponent };
