import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '@myenv/environment';

@Component({
  selector: 'app-deep-view',
  templateUrl: './deep-view.component.html',
  styleUrls: ['./deep-view.component.css']
})
export class DeepViewComponent implements OnInit {

  lastReportUrl: string;
  permissionModules = [];

  constructor(
    private title: Title
  ) {
    const url = localStorage.getItem('lastReportUrl');
    this.lastReportUrl = url ? url : 'reports/system/live-status';
  }

  ngOnInit() {
    this.title.setTitle('Deep View' + environment.title_text);
    this.permissionModules = JSON.parse(localStorage.getItem('permissionModules'));
  }

}
