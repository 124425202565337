import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormatter'
})
export class NumberFormatterPipe implements PipeTransform {

  transform(value: any, string?: boolean): any {
    let unit = '';
    if (value >= 1000000000) {
      value = value / 1000000000;
      value = !Number.isInteger(value) ? parseFloat(value.toFixed(2)) : value;
      unit = 'B';
    } else if (value >= 1000000) {
      value = value / 1000000;
      value = !Number.isInteger(value) ? parseFloat(value.toFixed(2)) : value;
      unit = 'M';
    } else if (value >= 1000) {
      value = value / 1000;
      value = !Number.isInteger(value) ? parseFloat(value.toFixed(2)) : value;
      unit = 'K';
    }
    if (string) {
      return `${value} ${unit}`;
    }
    return {value: value, unit: unit};
  }
}
