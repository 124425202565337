import { enMessage } from '@shared/en-us-message';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SharedService } from '@myproject/shared/shared.service';
import { ToolsService } from '../tools.service';

import { environment } from '@myenv/environment';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-move',
  templateUrl: './move.component.html',
  styleUrls: ['./move.component.scss']
})
export class MoveComponent implements OnInit {
  @ViewChild('inputElement', { static: false }) inputOne: ElementRef;
  @ViewChild('closeshift_device_modal', { static: false }) btnClose: ElementRef;
  public dateFormat = environment.isoDateTimeFormat;
  pageLoading = true;
  public largeDateFormat = environment.isoDateTimeFormat;
  responseMessage: any = {};
  message: any;
  isLoading = false;
  moduleName = 'DEVICE-SHIFT';
  permissionAuthorities: any = {};
  modules: any = [];
  data: any;
  searchModel: any = {};
  page = 0;
  loadingIndicator = true;
  currentPageCount: any = {
    start: 0,
    end: 0
  };
  pageInfo: any = {
    offset: 0,
    count: 0,
    limit: 10,
    pageSize: 0,
    lastPage: 0,
  };
  prevResponse = 0;
  devices: any = [];
  customers: any = [];
  typeValue = '';
  selected = [];
  notSelected = false;
  sameCustId = false;
  moving = false;

  constructor(
    private sharedService: SharedService,
    private toolsService: ToolsService,

    private title: Title
  ) { }

  ngOnInit() {
    this.modules = JSON.parse(localStorage.getItem('accessRoleModules'));
    this.permissionAuthorities = this.modules.find(module => module.name === this.moduleName);
    this.toolsService.currentMessage.subscribe(message => this.message = message);
    this.title.setTitle('Move Devices'+ environment.title_text);
    // this.getAllDevices(this.page);
    this.getAllCustomerIds();
  }

  getAllCustomerIds() {
    this.sharedService.getAllCustomer().subscribe(res => {
      this.customers = res;
      this.searchModel.sourceCustomerId = res[0].customerId;
      this.selectCustomer();
    }, error => {
      this.pageLoading = false;
      this.sharedService.getErrorMsg(error);
    });
  }

  selectCustomer() {
    this.devices = [];
    this.loadingIndicator = true;
    if (this.searchModel.sourceCustomerId !== null) {
      this.getAllDevices(this.page, this.searchModel.sourceCustomerId);
    }
  }

  getAllDevices(page, customerId) {
    this.toolsService.getCustomerDevices(page, customerId, this.typeValue).subscribe(res => {
      if (page === 0) {
        this.devices = [];
      }
      this.devices = [...this.devices, ...res];
      this.loadingIndicator = false;
      this.prevResponse = res.length;
      this.pageInfo.count = this.devices.length;
      const pagecount = this.pageInfo.count / this.pageInfo.limit;
      this.pageInfo.lastPage = Math.floor(pagecount);
      const decimal = pagecount - this.pageInfo.lastPage;

      if (!Number.isInteger(decimal)) {
        this.pageInfo.lastPage = this.pageInfo.lastPage + 1;
      }
      if (page == 0) {
        if (res.length) {
          this.currentPageCount.start = (this.pageInfo.offset * this.pageInfo.pageSize) + 1;
          if (this.devices.length <= 10) {
            this.currentPageCount.end = this.pageInfo.count;
          } else {
            this.currentPageCount.end = (this.pageInfo.offset * this.pageInfo.pageSize) + this.pageInfo.limit;
          }
        }
      }
      this.pageLoading = false;
    }, error => {
      this.pageLoading = false;
      this.sharedService.getErrorMsg(error);
    });
  }

  // To get the devices for each page in the table
  pageCallback(value) {
    this.pageInfo.offset = value.offset;
    this.currentPageCount.start = (value.offset * value.pageSize) + 1;
    const lastPage = Math.floor(this.devices.length / 10);
    if (lastPage == value.offset) {
      this.currentPageCount.end = value.count;
    } else {
      this.currentPageCount.end = (value.offset * value.pageSize) + value.limit;
    }
    if (this.pageInfo.lastPage === (this.pageInfo.offset + 1)) {
      // tslint:disable-next-line:no-unused-expression
      if (this.prevResponse) {
        this.page = ++this.page;
        this.getAllDevices(this.page, this.searchModel.sourceCustomerId);
      }
    }
  }

  onSearch(isValid) {
    if (isValid && this.searchModel.sourceCustomerId !== null) {
      // const inputVal = this.inputOne.nativeElement.value.toLowerCase();
      const val = this.typeValue.trim();
      this.page = 0;
      this.typeValue = val;
      this.devices = [];
      this.loadingIndicator = true;
      this.getAllDevices(this.page, this.searchModel.sourceCustomerId);
    }
  }

  onSelect({selected}) {
    // this.selected.splice(0, this.selected.length);
    // this.selected.push(...selected);
    // console.log('Select Event', this.selected);
  }

  shiftDevices() {
    if (this.searchModel.destinationCustomerId) {
      this.notSelected = false;
      if (this.searchModel.sourceCustomerId === this.searchModel.destinationCustomerId) {
        this.sameCustId = true;
      } else {
        this.moving = true;
        this.sameCustId = false;
        const devices = [];
        this.selected.map(device => {
          devices.push(device.serialNumber);
        });
        const payload = {
          sourceCustomerId: this.searchModel.sourceCustomerId,
          destinationCustomerId: this.searchModel.destinationCustomerId,
          devices: devices
        };
        this.toolsService.shiftDevices(payload).subscribe(res => {
          this.moving = false;
          this.selected = [];
          this.btnClose.nativeElement.click();
          this.responseMessage = {success: true, message: enMessage.successDeviceMove};
          this.sharedService.setResponseMessage(this.responseMessage);
          this.getAllDevices(this.page, this.searchModel.sourceCustomerId);
        }, error => {
          this.moving = false;
          this.btnClose.nativeElement.click();
          this.sharedService.getErrorMsg(error);
        });
      }
    } else {
      this.notSelected = true;
    }
  }
}
