import { GraphicReportsService } from '@components/graphic-reports/graphic-reports.service';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SharedService } from '@myproject/shared/shared.service';
import Chart from 'chart.js';
import { DatePipe } from '@angular/common';
import { configMessage } from '@shared/config-message';
import * as FileSaver from 'file-saver';
import { DeviceService } from '@myproject/components/device/device.service';
import { environment } from '@myenv/environment';
import { GroupsService } from '@myproject/components/device/groups/groups.service';

@Component({
  selector: 'app-discovery-report',
  templateUrl: './discovery-report.component.html',
  styleUrls: ['./discovery-report.component.scss']
})
export class DiscoveryReportComponent implements OnInit {
  status: any = {
    total: 0,
    communicating: 0,
    notCommunicating: 0,
    disconnected: 0,
    notConnected: 0
  };
  statusPercent = {
    communicating: 0,
    notCommunicating: 0,
    disconnected: 0,
    notConnected: 0
  };
  deviceCounts: any = {
    deviceAdded: 0,
    deviceRemoved: 0,
    messageCount: 0,
    deviceReplaced: 0
  };
  pieChart;
  isLoading = false;
  dataPattern = [
    // { key: 'total', label: 'Total' },
    { key: 'communicating', label: 'Communicating', color: '#39cb39' },
    { key: 'notCommunicating', label: 'Not Communicating', color: '#ff8000' },
    { key: 'disconnected', label: 'Disconnected', color: '#ff0000' },
    { key: 'notConnected', label: 'Not Connected', color: '#5c8a8a' },
  ];
  selectedDevice = null;
  highestCount = 0;
  statistics: any;
  fotaStats: any;
  lineChart: any;
  deviceAdded: any;
  deviceRemoved: any;
  deviceReplaced: any;
  multiYChart: any;
  fotaChart: any;
  // Drawer variables
  isDrawerOpen = false;
  selectedStatus: number;
  devices = [];
  pageInfo = {
    offset: 0,
    count: 0,
    limit: 5,
    pageSize: 0,
    lastPage: 0,
    prevResponse: 0
  };
  page = 0;
  currentPageCount = {
    start: 0,
    end: 0
  };
  // config properties
  deviceStatus = configMessage.deviceStatus;
  deviceStatusClass = configMessage.deviceStatusClass;
  dateAttributes = configMessage.dateAttributes;
  public dateFormat = environment.isoDateTimeFormat;
  // table headers
  allHeaders = [];
  displayHeaders = [];
  defaultHeaders = ['serialNumber', 'imei', 'simCcid', 'firmwareVersion', 'configVersion', 'simCarrier', 'model', 'deviceStatus'];
  deviceHeaderPreferences = [];
  querySelectVehicle =null;
  isLoadding = {
    processingTime: true
  };


  discoveryReportData = [];
  discoveryReportStaticData = [];

  columns = [
    { prop: '#', name: '#', width: 100 },
    { prop: 'imei', name: 'IMEI', width: 100 },
    { prop: 'vin', name: 'VIN', width: 100 },
    { prop: 'discovery', name: 'Discovery Info', width: 500 }
  ];

  pageLoading = false;

  groups: any = [];
  vehicleGroupFilterkey: any;
  selectedGroups: any = {};

  constructor(
    private title: Title,
    private reportsService: GraphicReportsService,
    private sharedService: SharedService,
    private deviceService: DeviceService,
    private groupsService: GroupsService
  ) { }

  ngOnInit() {
    this.getGroups();
    this.selectedGroups = {"name": "All", "groupId":"all"};
    this.discoveryReportStaticData = [
      {
        "imei": "354433211132141",
        "vin": "1HGCM72613A027722",
        "discovery": "1HGCM72613A027722;ODO,S,168,km,S;OIL LIFE %,S,28,%    ,S;TIRE PRESSURE LF,S,540,kPa,S;TIRE PRESSURE RF,S,561,kPa,S;TIRE PRESSURE LR,S,608,kPa,S;TIRE PRESSURE RR,S,546,kPa,S;TIRE PRESSURE LRI,S,1034,kPa,S;TIRE PRESSURE RRI,S,1034,kPa,S;TIRE PRESSURE SPR,NSL,,,;SEATBELT D,TO,,,;SEATBELT P,TO,,,;FUEL LEVEL %,S,85,%,S;AIRBAG LAMP,TO,,,;PRNDL POS,S,Forward Drive 1,_,S;PARKING BRAKE STAT,S,Inactive,_,S;PARKING BRAKE LAMP,TO,,,;",
        "createdAt": "2024-08-02 15:37:05",
        "groupName": "Menards"
      },
      {
        "imei": "354433211132142",
        "vin": "KNAFT4A21A5808416",
        "discovery": "KNAFT4A21A5808416;ODO,S,168,km,S;OIL LIFE %,S,28,%    ,S;TIRE PRESSURE LF,S,540,kPa,S;TIRE PRESSURE RF,S,561,kPa,S;TIRE PRESSURE LR,S,608,kPa,S;TIRE PRESSURE RR,S,546,kPa,S;TIRE PRESSURE LRI,S,1034,kPa,S;TIRE PRESSURE RRI,S,1034,kPa,S;TIRE PRESSURE SPR,NSL,,,;SEATBELT D,TO,,,;SEATBELT P,TO,,,;FUEL LEVEL %,S,85,%,S;AIRBAG LAMP,TO,,,;PRNDL POS,S,Forward Drive 1,_,S;PARKING BRAKE STAT,S,Inactive,_,S;PARKING BRAKE LAMP,TO,,,;",
        "createdAt": "2024-08-01 15:37:05",
        "groupName": "Menards"
      },
      {
        "imei": "354433211132143",
        "vin": "2G1WF5E37D1146066",
        "discovery": "2G1WF5E37D1146066;ODO,S,168,km,S;OIL LIFE %,S,28,%    ,S;TIRE PRESSURE LF,S,540,kPa,S;TIRE PRESSURE RF,S,561,kPa,S;TIRE PRESSURE LR,S,608,kPa,S;TIRE PRESSURE RR,S,546,kPa,S;TIRE PRESSURE LRI,S,1034,kPa,S;TIRE PRESSURE RRI,S,1034,kPa,S;TIRE PRESSURE SPR,NSL,,,;SEATBELT D,TO,,,;SEATBELT P,TO,,,;FUEL LEVEL %,S,85,%,S;AIRBAG LAMP,TO,,,;PRNDL POS,S,Forward Drive 1,_,S;PARKING BRAKE STAT,S,Inactive,_,S;PARKING BRAKE LAMP,TO,,,;",
        "createdAt": "2024-07-30 15:37:05",
        "groupName": "Menards"
      },
      {
        "imei": "354433211132144",
        "vin": "1GKCS13W7X2511382",
        "discovery": "1GKCS13W7X2511382;ODO,S,168,km,S;OIL LIFE %,S,28,%    ,S;TIRE PRESSURE LF,S,540,kPa,S;TIRE PRESSURE RF,S,561,kPa,S;TIRE PRESSURE LR,S,608,kPa,S;TIRE PRESSURE RR,S,546,kPa,S;TIRE PRESSURE LRI,S,1034,kPa,S;TIRE PRESSURE RRI,S,1034,kPa,S;TIRE PRESSURE SPR,NSL,,,;SEATBELT D,TO,,,;SEATBELT P,TO,,,;FUEL LEVEL %,S,85,%,S;AIRBAG LAMP,TO,,,;PRNDL POS,S,Forward Drive 1,_,S;PARKING BRAKE STAT,S,Inactive,_,S;PARKING BRAKE LAMP,TO,,,;",
        "createdAt": "2024-07-29 15:37:05",
        "groupName": "Menards"
      },
      {
        "imei": "354433311133141",
        "vin": "KNAGH4A82A5394119",
        "discovery": "KNAGH4A82A5394119;ODO,S,168,km,S;OIL LIFE %,S,28,%    ,S;TIRE PRESSURE LF,S,540,kPa,S;TIRE PRESSURE RF,S,561,kPa,S;TIRE PRESSURE LR,S,608,kPa,S;TIRE PRESSURE RR,S,546,kPa,S;TIRE PRESSURE LRI,S,1034,kPa,S;TIRE PRESSURE RRI,S,1034,kPa,S;TIRE PRESSURE SPR,NSL,,,;SEATBELT D,TO,,,;SEATBELT P,TO,,,;FUEL LEVEL %,S,85,%,S;AIRBAG LAMP,TO,,,;PRNDL POS,S,Forward Drive 1,_,S;PARKING BRAKE STAT,S,Inactive,_,S;PARKING BRAKE LAMP,TO,,,;",
        "createdAt": "2024-07-28 15:37:05",
        "groupName": "Granite"
      }
    ];
    this.discoveryReportData = this.discoveryReportStaticData;
  }

  changeDevice(e) {
    // if (e) {
    //   this.selectedDevice = e.deviceId;
    // }
    this.selectedDevice = e ? e.deviceId : null;
    this.querySelectVehicle = e ? e : null;
    this.getAllProcessingTime(this.selectedDevice);
  }

  // To get the header of Devices table
  getDeviceHeader() {
    const headers = JSON.parse(localStorage.getItem('deviceHeaders'));
    if (headers) {
      this.allHeaders = headers;
      this.setDisplayHeaders();
    } else {
      this.sharedService.getDeviceHeader().subscribe({
        next: res => {
          localStorage.setItem('deviceHeaders', JSON.stringify(res));
          this.allHeaders = res;
          this.setDisplayHeaders();
        },
        error: error => {
          this.sharedService.getErrorMsg(error);
        }
      });
    }
  }

  setDisplayHeaders() {
    const pref = this.deviceHeaderPreferences.length ? this.deviceHeaderPreferences : this.defaultHeaders;
    this.allHeaders.map(item => {
      if (pref.includes(item.name)) {
        this.displayHeaders.push(item);
      }
    });
  }

  refresh() {
    this.getLiveStatus();
    this.getAllProcessingTime(this.selectedDevice);
    this.getStats();
    this.getDeviceCounts();
    this.getFotaStats();
    if (this.isDrawerOpen) { this.getDevices(this.page, this.selectedStatus); }
  }

  getLiveStatus() {
    this.reportsService.getLiveStatus().subscribe({
      next: res => {
        this.status = res;
        this.statusPercent = {
          communicating: Math.round((this.status.communicating / this.status.total) * 100 * 10) / 10,
          notCommunicating: Math.round((this.status.notCommunicating / this.status.total) * 100 * 10) / 10,
          disconnected: Math.round((this.status.disconnected / this.status.total) * 100 * 10) / 10,
          notConnected: Math.round((this.status.notConnected / this.status.total) * 100 * 10) / 10
        };
        // this.drawPieGraph(res);
      },
      error: error => {
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  getAllProcessingTime(deviceId?) {
    this.isLoadding.processingTime = true;
    this.reportsService.getAllProcessingTime(deviceId).subscribe({
      next: res => {
        this.isLoadding.processingTime = false;
        if (res.length) {
          this.sortLineData(res);
        } else {
          this.drawLineGraph([], [], []);
          this.isLoading = false;
        }
      },
      error: error => {
        this.isLoading = false;
        this.isLoadding.processingTime = false;
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  getStats() {
    const end = Date.parse(new Date().toUTCString());
    const start = end - (182 * 24 * 60 * 60 * 1000);
    this.reportsService.getStats(start, end).subscribe({
      next: res => {
        this.statistics = res;
        this.drawMultiYChart(res);
        this.drawDeviceCharts('deviceAdded', res);
        this.drawDeviceCharts('deviceRemoved', res);
        this.drawDeviceCharts('deviceReplaced', res);
      },
      error: error => {
        this.isLoading = false;
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  getDeviceCounts() {
    this.reportsService.getDeviceCounts().subscribe({
      next: res => {
        this.deviceCounts = res;
      },
      error: error => {
        this.isLoading = false;
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  getFotaStats() {
    var datePipe = new DatePipe('en-US');
    const endTime = Date.parse(new Date().toUTCString());
    const startTime = endTime - (182 * 24 * 60 * 60 * 1000);
    const start = datePipe.transform(startTime, 'yyyy-MM-dd');
    const end = datePipe.transform(endTime, 'yyyy-MM-dd');
    this.reportsService.getFotaStats(start, end).subscribe({
      next: res => {
        this.fotaStats = res;
        this.drawFotaChart(res);
      },
      error: error => {
        this.isLoading = false;
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  sortLineData(res) {
    this.highestCount = 0;
    const labels = [];
    const processingTime = [];
    const networkTime = [];
    res.reverse();
    res.map(item => {
      var datePipe = new DatePipe('en-US');
      let eventDate = datePipe.transform(item.eventTime, 'dd MMM hh:mm a');
      labels.push(eventDate);
      networkTime.push(item.networkTime);
      processingTime.push(item.processingTime);
    });
    this.highestCount = Math.max(Math.max(...networkTime), Math.max(...processingTime));
    this.drawLineGraph(labels, networkTime, processingTime);
    this.isLoading = false;
  }

  drawLineGraph(labels, networkTime, processingTime) {
    if (this.lineChart) {
      this.lineChart.destroy();
    }
    var ctx = document.getElementById('lineChart');
    this.lineChart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [{
          label: 'Network Time',
          backgroundColor: 'rgb(54, 162, 235, 0.2)',
          borderColor: 'rgb(54, 162, 235, 1)',
          data: networkTime
        }, {
          label: 'Processing Time',
          backgroundColor: 'rgb(255, 99, 132, 0.2)',
          borderColor: 'rgb(255, 99, 132, 1)',
          data: processingTime
        }]
      },
      options: {
        legend: {
          position: 'bottom',
          display: true
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              suggestedMin: 0,
              suggestedMax: 10,
              stepSize: this.highestCount > 9 ? null : 1
            }
          }],
          xAxes: [{
            ticks: {
              autoSkip: true,
              maxTicksLimit: 12
            }
          }]
        },
      }
    });
  }

  drawDeviceCharts(container, data) {
    const labels = [];
    const counts = [];
    let displayLabel = '';
    data.map(item => {
      const datePipe = new DatePipe('en-US');
      const eventDate = datePipe.transform(item.time, 'MMM');
      labels.push(eventDate);
      if (container === 'deviceAdded') {
        displayLabel = 'Devices Added';
        counts.push(item.deviceAdded);
      } else if (container === 'deviceRemoved') {
        displayLabel = 'Devices Removed';
        counts.push(item.deviceRemoved);
      } else if (container === 'deviceReplaced') {
        displayLabel = 'Devices Replaced';
        counts.push(item.deviceReplaced);
      }
    });
    if (container === 'deviceAdded' && this.deviceAdded) {
      this.deviceAdded.destroy();
    } else if (container === 'deviceRemoved' && this.deviceRemoved) {
      this.deviceRemoved.destroy();
    } else if (container === 'deviceReplaced' && this.deviceReplaced) {
      this.deviceReplaced.destroy();
    }
    var ctx = document.getElementById(container);
    var myChart = new Chart(ctx, {
      type: 'line',
      responsive: true,
      data: {
        labels: labels,
        datasets: [{
          label: displayLabel,
          data: counts,
          backgroundColor: 'rgba(255, 255, 255, 1)',
          borderColor: 'rgba(255, 255, 255, 1)',
          fill: false
          // borderWidth: 1
        }]
      },
      options: {
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            display: true,
            gridLines: {
              borderDash: [8, 4],
              color: '#ffffff3b',
              zeroLineColor: '#ffffff3b',
              drawTicks: false
            },
            ticks: {
              padding: 5,
              beginAtZero: false,
              fontColor: '#ffffffcf',
              autoSkip: true,
              maxTicksLimit: 12
            }
          }],
          yAxes: [{
            display: true,
            gridLines: {
              borderDash: [8, 4],
              color: '#ffffff3b',
              zeroLineColor: '#ffffff3b',
              drawTicks: false,
            },
            ticks: {
              padding: 5,
              beginAtZero: true,
              fontColor: '#ffffffcf',
              suggestedMin: 0,
              suggestedMax: 10,
              // stepSize: this.highestCount > 9 ? null : 1
            }
          }]
        }
      }
    });
    if (container === 'deviceAdded') {
      this.deviceAdded = myChart;
    } else if (container === 'deviceRemoved') {
      this.deviceRemoved = myChart;
    } else {
      this.deviceReplaced = myChart;
    }
  }

  drawMultiYChart(data) {
    const labels = [];
    const messageCount = [];
    const usageCount = [];
    if (this.multiYChart) {
      this.multiYChart.destroy();
    }
    data.map(item => {
      const datePipe = new DatePipe('en-US');
      const eventDate = datePipe.transform(item.time, 'MMM');
      labels.push(eventDate);
      messageCount.push(item.messageReceived);
      usageCount.push(item.dataUsages);
    });
    var ctx = document.getElementById('multiYChart');
    this.multiYChart = new Chart(ctx, {
      type: 'line',
      data: {
        datasets: [{
          data: messageCount,
          label: 'Messages Received',
          backgroundColor: 'rgb(54, 162, 235, 0.2)',
          borderColor: 'rgb(54, 162, 235, 1)',
          // This binds the dataset to the left y axis
          yAxisID: 'left-y-axis'
        },
        {
          data: usageCount,
          label: 'Usage',
          backgroundColor: 'rgb(255, 99, 132, 0.2)',
          borderColor: 'rgb(255, 99, 132, 1)',
          // This binds the dataset to the right y axis
          yAxisID: 'right-y-axis'
        }],
        labels: labels
      },
      options: {
        legend: {
          position: 'bottom',
          display: true
        },
        scales: {
          yAxes: [{
            id: 'left-y-axis',
            type: 'linear',
            position: 'left',
            ticks: {
              suggestedMin: 0,
              suggestedMax: 10,
              // stepSize: this.highestCount > 9 ? null : 1
            }
          }, {
            id: 'right-y-axis',
            type: 'linear',
            position: 'right',
            ticks: {
              suggestedMin: 0,
              suggestedMax: 10,
              // stepSize: this.highestCount > 9 ? null : 1
            }
          }]
        }
      }
    });
  }

  drawFotaChart(data) {
    const labels = [];
    const counts = [];
    if (this.fotaChart) {
      this.fotaChart.destroy();
    }
    data.map(item => {
      const datePipe = new DatePipe('en-US');
      const eventDate = datePipe.transform(item.time, 'MMM');
      labels.push(eventDate);
      counts.push(item.count);
    });
    var ctx = document.getElementById('fotaChart');
    this.fotaChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{
          label: 'Count',
          data: counts,
          backgroundColor: 'rgba(153, 102, 255, 1)',
          borderColor: 'rgba(153, 102, 255, 1)',
          borderWidth: 1
        }]
      },
      options: {
        legend: {
          position: 'bottom',
          display: true
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              suggestedMin: 0,
              suggestedMax: 10,
              // stepSize: this.highestCount > 9 ? null : 1
            }
          }],
          xAxes: [{
            ticks: {
              autoSkip: true,
              maxTicksLimit: 12
            }
          }]
        }
      }
    });
  }

  toggleDrawer(status) {
    if (this.isDrawerOpen && this.selectedStatus === status) {
      this.isDrawerOpen = false;
      this.selectedStatus = 0;
      this.devices = [];
    } else {
      this.isDrawerOpen = true;
      this.selectedStatus = status;
      this.pageInfo.offset = 0;
      this.pageInfo.prevResponse = 0;
      this.page = 0;
      this.devices = [];
      this.isLoading = true;
      this.getDevices(this.page, status);
    }
  }

  getDevices(page, status) {
    this.reportsService.getDevicesWithStatus(page, status).subscribe({
      next: res => {
        if (page === 0) {
          this.devices = res;
        } else {
          this.devices = [...this.devices, ...res];
        }
        this.isLoading = false;
        this.pageInfo.prevResponse = res.length;
      },
      error: error => {
        this.isLoading = false;
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  downloadCSV() {
    this.deviceService.downloadDeviceCSV({}, this.selectedStatus).subscribe(res => {
      const contentDisposition = res.headers.get('Content-Disposition');
      let filename;
      if (contentDisposition == null) {
        const date = new Date();
        filename = 'Device_' + date + '.csv';
      } else {
        filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
      }
      const blob = new Blob([res.body], {
        type: 'text/csv;charset=utf-8'
      });
      FileSaver.saveAs(blob, filename);
    }, error => {
      this.sharedService.getErrorMsg(error);
    });
  }

  pageCallback(value) {
    this.pageInfo.offset = value.offset;
    this.currentPageCount.start = (value.offset * value.pageSize) + 1;
    const lastPage = Math.floor(this.devices.length / 5);
    this.pageInfo.lastPage = lastPage;
    if (lastPage == value.offset) {
      this.currentPageCount.end = value.count;
    } else {
      this.currentPageCount.end = (value.offset * value.pageSize) + value.limit;
    }
    if (this.pageInfo.lastPage === (this.pageInfo.offset + 1)) {
      if (this.pageInfo.prevResponse) {
        this.page = ++this.page;
        this.getDevices(this.page, this.selectedStatus);
      }
    }
  }

  // To get the groups
  getGroups() {
    if (!this.groups.length) {
      let allGroupsOption = {"name": "All", "groupId":"all"};
      this.groupsService.getAssignedGroups().subscribe(res => {
        this.groups = res;
        this.groups.unshift(allGroupsOption);
      }, error => {
        this.sharedService.getErrorMsg(error);
      });
    }
  }

  vehicleGroupFilter(e) {
    this.vehicleGroupFilterkey = e ? e.groupId === "all" ? '' : e.groupId : '';
    let tempData = [];
    if (e.name !== "All") {
      this.discoveryReportStaticData.forEach((data, index) => {
        if (data.groupName === e.name) {
          tempData.push(data);
        }
      });
      this.discoveryReportData = tempData;
    } else {
      this.discoveryReportData = this.discoveryReportStaticData;
    }
  }
}
