<app-notify-message></app-notify-message>
<app-reports-header></app-reports-header>
<div class="row no-gutters reports-page">
  <div class="col-md-2 col-sm-0 p-0">
    <app-reports-side-menu></app-reports-side-menu>
  </div>
  <div class="reports-body col-md-10 col-sm-12 p-0">
    <div class="row no-gutters action-row align-items-center px-2">
      <div class="col-md-9">
        <h4 class="report-heading page-heading animated fadeIn">
          <i class="fa fa-bar-chart"></i> Live Status
        </h4>
      </div>
      <div class="col-md-2 pt-2">
        <ng-select [items]="groups" [(ngModel)]="selectedGroups" bindLabel="name" placeholder="Select Group" placement="top" [ngClass]="{'text-danger': vehicleGroupFilterkey}"
          id="filterGroups" (change)="vehicleGroupFilter($event)"></ng-select>
      </div>
      <div class="col-md-1 text-right">
        <button
          class="btn btn-icon ml-2 refresh"
          (click)="refresh()"
          tooltip="Refresh"
          placement="top"
          [disabled]="isLoading">
          <i class="fa fa-refresh" aria-hidden="true"></i>
          <span class="mob-access-text d-none">&nbsp;Refresh</span>
        </button>
      </div>
    </div>
    <div class="row no-gutters pt-2">
      <div class="col-md-12 px-3 py-2">
        <div class="outer-wrapper" (click)="toggleDrawer(14)">
          <div class="report-item-container total-count-container communicating animated fadeIn"
            [class.active]="selectedStatus===14">
            <span class="count">{{status.communicating}}</span>
            <!-- <span class="unit">K</span> -->
            <div class="progress">
              <div
                class="progress-bar"
                role="progressbar"
                [style.width] = "statusPercent.communicating + '%'"
                aria-valuenow="statusPercent.communicating"
                aria-valuemin="0"
                aria-valuemax="100">
              </div>
            </div>
            <h6 class="text">
              <span>Communicating</span>
              <span class="percentage">{{statusPercent.communicating}} %</span>
            </h6>
          </div>
        </div>
        <div class="outer-wrapper" (click)="toggleDrawer(15)">
          <div class="report-item-container total-count-container notCommunicating animated fadeIn"
            [class.active]="selectedStatus===15">
            <span class="count">{{status.notCommunicating}}</span>
            <!-- <span class="unit">K</span> -->
            <div class="progress">
              <div
                class="progress-bar"
                role="progressbar"
                [style.width] = "statusPercent.notCommunicating + '%'"
                aria-valuenow="statusPercent.notCommunicating"
                aria-valuemin="0"
                aria-valuemax="100">
              </div>
            </div>
            <h6 class="text">
              <span>Not Communicating</span>
              <span class="percentage">{{statusPercent.notCommunicating}} %</span>
            </h6>
          </div>
        </div>
        <div class="outer-wrapper" (click)="toggleDrawer(16)">
          <div class="report-item-container total-count-container disconnected animated fadeIn"
            [class.active]="selectedStatus===16">
            <span class="count">{{status.disconnected}}</span>
            <!-- <span class="unit">K</span> -->
            <div class="progress">
              <div
                class="progress-bar"
                role="progressbar"
                [style.width] = "statusPercent.disconnected + '%'"
                aria-valuenow="statusPercent.disconnected"
                aria-valuemin="0"
                aria-valuemax="100">
              </div>
            </div>
            <h6 class="text">
              <span>Disconnected</span>
              <span class="percentage">{{statusPercent.disconnected}} %</span>
            </h6>
          </div>
        </div>
        <div class="outer-wrapper pr-0" (click)="toggleDrawer(3)">
          <div class="report-item-container total-count-container notConnected animated fadeIn"
            [class.active]="selectedStatus===3">
            <span class="count">{{status.notConnected}}</span>
            <!-- <span class="unit">K</span> -->
            <div class="progress">
              <div
                class="progress-bar"
                role="progressbar"
                [style.width] = "statusPercent.notConnected + '%'"
                aria-valuenow="statusPercent.notConnected"
                aria-valuemin="0"
                aria-valuemax="100">
              </div>
            </div>
            <h6 class="text">
              <span>Never Communicated</span>
              <span class="percentage">{{statusPercent.notConnected}} %</span>
            </h6>
          </div>
        </div>
        <div class="device-drawer" [class.open]="isDrawerOpen">
          <div class="table-container" *ngIf="isDrawerOpen"
            [class.communicating-arrow]="selectedStatus===14"
            [class.notCommunicating-arrow]="selectedStatus===15"
            [class.disconnected-arrow]="selectedStatus===16"
            [class.notConnected-arrow]="selectedStatus===3">
            <div>
              <div class="d-inline-block drawer-heading pull-left mt-1">
                <h6 *ngIf="selectedStatus===14">Communicating Devices</h6>
                <h6 *ngIf="selectedStatus===15">Not Communicating Devices</h6>
                <h6 *ngIf="selectedStatus===16">Disconnected Devices</h6>
                <h6 *ngIf="selectedStatus===3">Not Connected Devices</h6>
              </div>
              <div class="d-inline-block text-right pull-right mb-1">
                <button
                  class="btn btn-icon ml-2 download"
                  (click)="downloadCSV()"
                  tooltip="Download CSV"
                  placement="top"
                  [disabled]="!devices.length">
                  <i class="fa fa-cloud-download" aria-hidden="true"></i>
                  <span class="mob-access-text d-none">&nbsp;Download</span>
                </button>
                <button
                  class="btn btn-icon ml-2"
                  (click)="toggleDrawer(selectedStatus)"
                  tooltip="Close Drawer"
                  placement="top">
                  <i class="fa fa-chevron-up" aria-hidden="true"></i>
                  <span class="mob-access-text d-none">&nbsp;Close</span>
                </button>
              </div>
            </div>
            <div class="drawer-loading text-center" *ngIf="isLoading">
              <img src="../../../assets/img/darby_loader_small.gif"/>
            </div>
            <div *ngIf="!devices.length && !isLoading" class="nodata-contaner text-center">
              <img width="150" src="../../../assets/img/info_nodata_256.png"/>
              <h4 class="mt-3">No data to show</h4>
            </div>
            <ngx-datatable *ngIf="devices.length"
              id="tableau" #dataTable
              class="bootstrap alertTable reportsTable wrapcell"
              [rows]="devices"
              [columnMode]="'force'"
              [headerHeight]="50"
              [footerHeight]="50"
              [rowHeight]="'auto'"
              [summaryRow]="false"
              [scrollbarH]="true"
              [reorderable]="true"
              [limit]="pageInfo.limit"
              [offset]="pageInfo.offset"
              [count]="pageInfo.count"
              (page)="pageCallback($event)"
              >
              <ngx-datatable-column [resizeable]="false" [sortable]="false" name="#" [width]="50">
                <ng-template let-rowIndex="rowIndex" ngx-datatable-cell-template>
                  {{rowIndex+1}}
                </ng-template>
              </ngx-datatable-column>
              <ng-container *ngFor="let dh of displayHeaders">
                <ngx-datatable-column
                  [resizeable]="false"
                  name="{{dh.displayName}}"
                  prop="{{dh.name}}"
                  [sortable]="false"
                  *ngIf="!dh.name.includes('customerId') && !dh.name.includes('companyId')">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <span *ngIf="dateAttributes.includes(dh.name)">{{row[dh.name] | date : dateFormat}}</span>
                    <span *ngIf="!dateAttributes.includes(dh.name) && dh.name !== 'deviceStatus' && dh.name !== 'lastKnownStatus'">
                      {{row[dh.name]}}
                    </span>
                    <span [class]="'class-' + deviceStatusClass[row[dh.name]]" *ngIf="dh.name=='deviceStatus'">
                      {{deviceStatus[row[dh.name]]}}
                    </span>
                    <span [class]="'width-200 class-' + getLastKnownStatus(row[dh.name])"
                              *ngIf="dh.name=='lastKnownStatus'">
                      {{getLastKnownStatus(row[dh.name])}}
                    </span>
                  </ng-template>
                </ngx-datatable-column>
              </ng-container>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row no-gutters">
      <div class="col-md-12 p-3">
        <div class="report-item-container graph-container animated fadeIn">
          <div class="row no-gutters align-items-center">
            <div class="col-sm-4">
              <h6>
                Processing Time (Milliseconds)
                <i class="fa fa-info-circle pl-1" [tooltip]="TooltipContent" content-type="template" placement="right"></i>
              </h6>
              <ng-template #TooltipContent>
                <div class="text-left">
                  <strong>Network Time:</strong> The time is taken by the device from event generation to push to the server in a millisecond.
                  <hr>
                  <strong>Processing Time:</strong> The time is taken by the system to process the request in a millisecond.
                </div>
              </ng-template>
            </div>
            <div class="col-sm-8 text-right">
              <app-search-select-device
                [defaultselectdevice]="true"
                [autoSelect]="true"
                [selectSearchCategory]="true"
                [querySelectDevice]="querySelectVehicle"
                (deviceChangeEvent)="changeDevice($event)"
                >
              </app-search-select-device>
            </div>
          </div>
          <canvas id="lineChart" [style.display]="isLoadding.processingTime ? 'none' : 'block'" height="150"></canvas>
          <div class="loading" *ngIf="isLoadding.processingTime">
            <img src="../../../assets/img/darby_loader_small.gif"/>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="row no-gutters">
      <div class="col-md-4 p-3">
        <div class="small-chart-wrapper">
          <div class="chart deviceAdded">
            <canvas id="deviceAdded" height="185"></canvas>
          </div>
          <div class="meta">
            <h5>Total Devices: {{deviceCounts.deviceAdded}}</h5>
          </div>
        </div>
      </div>
      <div class="col-md-4 p-3">
        <div class="small-chart-wrapper">
          <div class="chart deviceReplaced">
            <canvas id="deviceReplaced" height="185"></canvas>
          </div>
          <div class="meta">
            <h5>Total Devices Replaced: {{deviceCounts.deviceReplaced}}</h5>
          </div>
        </div>
      </div>
      <div class="col-md-4 p-3">
        <div class="small-chart-wrapper">
          <div class="chart deviceRemoved">
            <canvas id="deviceRemoved" height="185"></canvas>
          </div>
          <div class="meta">
            <h5>Total Devices Removed: {{deviceCounts.deviceRemoved}}</h5>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="row no-gutters d-none">
      <div class="col-md-7 px-3 pb-3">
        <div class="report-item-container graph-container animated fadeIn">
          <h6>Message and Usage Stats</h6>
          <canvas id="multiYChart" height="200"></canvas>
        </div>
      </div>
      <div class="col-md-5 pr-3 pb-3 fotaColumn">
        <div class="report-item-container graph-container animated fadeIn">
          <h6>FOTA Stats</h6>
          <canvas id="fotaChart" height="281"></canvas>
        </div>
      </div>
    </div> -->
  </div>
</div>
