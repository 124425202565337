import { OnInit, OnChanges, EventEmitter } from '@angular/core';
var ConfirmDeleteModalComponent = /** @class */ (function () {
    function ConfirmDeleteModalComponent() {
        this.modalInstance = 1;
        this.type = 'danger';
        this.typeConfirmReq = true;
        this.confirmEvent = new EventEmitter();
        this.cancelConfirmEvent = new EventEmitter();
        this.confirmed = false;
        this.confirmText = '';
        this.modalId = '';
        this.modalCloseId = '';
    }
    ConfirmDeleteModalComponent.prototype.ngOnInit = function () {
        this.modalId = "deleteDeviceModel" + this.modalInstance;
        this.modalCloseId = "closeModal" + this.modalInstance;
    };
    ConfirmDeleteModalComponent.prototype.ngOnChanges = function (changes) {
        this.modalId = "deleteDeviceModel" + this.modalInstance;
        this.modalCloseId = "closeModal" + this.modalInstance;
        // Show Modal
        if (changes.showModal && changes.showModal.previousValue !== undefined) {
            if (changes.showModal.previousValue !== changes.showModal.currentValue) {
                if (this.modalInstance === 1) {
                    document.getElementById('deleteModal1').click();
                }
                else {
                    document.getElementById('deleteModal2').click();
                }
                this.confirmText = '';
                this.confirmed = false;
            }
        }
        // Hide Modal
        if (changes.hideModal && changes.hideModal.previousValue !== undefined) {
            if (changes.hideModal.previousValue !== changes.hideModal.currentValue) {
                document.getElementById(this.modalCloseId).click();
            }
        }
    };
    ConfirmDeleteModalComponent.prototype.matchCriteria = function () {
        // if (this.matchText) {
        //   if (this.confirmText === this.matchText) {
        //     this.confirmed = true;
        //   } else {
        //     this.confirmed = false;
        //   }
        // } else {
        if (this.confirmText.toLowerCase() === 'confirm') {
            this.confirmed = true;
        }
        else {
            this.confirmed = false;
        }
        // }
    };
    ConfirmDeleteModalComponent.prototype.confirmDelete = function () {
        this.confirmEvent.emit();
    };
    ConfirmDeleteModalComponent.prototype.cancelConfirm = function () {
        this.cancelConfirmEvent.emit();
    };
    return ConfirmDeleteModalComponent;
}());
export { ConfirmDeleteModalComponent };
