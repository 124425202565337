import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { environment } from "@myenv/environment";
import { configMessage } from "@myproject/shared/config-message";
import Chart from "chart.js";
import { GraphicReportsService } from "../graphic-reports.service";
import * as FileSaver from 'file-saver';

@Component({
  selector: "app-usage",
  templateUrl: "./usage.component.html",
  styleUrls: ["./usage.component.scss"],
  providers: [DatePipe]
})
export class UsageComponent implements OnInit {
  lastReportUrl: string;
  permissionModules = [];
  stackedChart: any;
  hideCalendar = false;
  highestCount = 0;
  chartLabels = [];
  stackedDataset = [];
  totalsDataset = [];
  showTotalChart = false;
  isSideMenuClose = false;
  messageTypeColor = configMessage.billingMessageTypeColor;
  deviceList: any = [];
  currentPageCount: any = {
    start: 0,
    end: 0,
    totalCount: 0
  };
  pageInfo: any = {
    offset: 0,
    count: 0,
    limit: 10,
    pageSize: 0,
    lastPage: 0,
  };
  prevResponse = 0;
  loadingIndicator = false;
  selectedDate = "";
  month = [{ "id": 1, "name": "January" }, { "id": 2, "name": "February" }, { "id": 3, "name": "March" }, { "id": 4, "name": "April" }, { "id": 5, "name": "May" }, { "id": 6, "name": "June" }, { "id": 7, "name": "July" }, { "id": 8, "name": "August" }, { "id": 9, "name": "September" }, { "id": 10, "name": "October" }, { "id": 11, "name": "November" }, { "id": 12, "name": "December" }]
  years = [];
  selectedMonth = 0;
  selectedYear = 0;
  constructor(private title: Title, private reportsService: GraphicReportsService, private datePipe: DatePipe) { }
  page = 0;
  totalAssetCount: any;
  currentCustomer: any = {};
  toDate: any;
  accountStatus: string;
  expiredDate: boolean;
  totalMessagesCount: any;
  assetType: any = 'device'

  ngOnInit() {
    window["deve"] = this;
    this.title.setTitle("Bitbrew Usage" + environment.title_text);
    this.currentCustomer = JSON.parse(localStorage.getItem("currentUser"));
    this.permissionModules = JSON.parse(
      localStorage.getItem("permissionModules")
    );
    var currentYear = new Date().getFullYear();

    for (var i = 0; i < 10; i++) {
      this.years.push(currentYear - i);
      this.years.push(currentYear + i);
    }
    this.years = [...new Set(this.years.sort())];
    this.selectedYear = currentYear;
    this.selectedMonth = new Date().getMonth() + 1;

    // this.selectedDate = this.datePipe.transform(this.selectedDate, 'yyyy-MM-dd');
    this.getUsageStats();
    this.getDeviceUsageByMonthYear(this.selectedYear, this.selectedMonth);
    this.getDeviceUsageByMonthYearCount(this.selectedYear, this.selectedMonth);
    this.getData(this.currentCustomer.customerId)
  }

  getUsageStats() {
    this.reportsService.getUsageStats().subscribe((data) => {
      this.sortData(data)
    }, error => {
      console.log("error", error)
    })
  }
  onChangeName($event) {
    this.deviceList = [];
    this.page = 0;
    this.pageInfo.offset = 0;
    this.getDeviceUsageByMonthYear(this.selectedYear, this.selectedMonth);
    this.getDeviceUsageByMonthYearCount(this.selectedYear, this.selectedMonth);
  }
  pageCallback(value) {
    this.pageInfo.offset = value.offset;
    this.currentPageCount.start = (value.offset * value.pageSize) + 1;
    const lastPage = Math.floor(this.deviceList.length / this.pageInfo.limit);
    if (lastPage === value.offset) {
      this.currentPageCount.end = value.count;
    } else {
      this.currentPageCount.end = (value.offset * value.pageSize) + value.limit;
    }
    if (this.pageInfo.lastPage === (this.pageInfo.offset + 1)) {
      // tslint:disable-next-line:no-unused-expression
      if (this.prevResponse === 100) {
        this.page = ++this.page;
        this.getDeviceUsageByMonthYear(this.selectedYear, this.selectedMonth);
      }
    }
  }

  downloadUsage() {
    this.reportsService.downloadDeviceCSV(this.selectedYear, this.selectedMonth).subscribe(data => {
      console.log("data", data);
      const contentDisposition = data.headers.get('Content-Disposition');
      let filename;
      if (contentDisposition == null) {
        const date = new Date();
        // filename = 'Usage_' + date + '.csv';Bitbrew_Usage_Data_YYYY-MM-DD
        filename = 'Bitbrew_Usage_Data_' + this.selectedYear + '-' + (this.selectedMonth > 9 ? this.selectedMonth : "0" + this.selectedMonth) + "-1" + '.csv';
      } else {
        filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
      }
      const blob = new Blob([data.body], {
        type: 'text/csv;charset=utf-8'
      });
      FileSaver.saveAs(blob, filename);
    })
  }
  getDeviceUsageByMonthYear(yr, mnth) {
    this.loadingIndicator = true
    this.reportsService.getUsageByMonthYear(yr, mnth, this.page).subscribe(data => {
      this.prevResponse = data.length;
      if (this.page === 0) {
        this.deviceList = data;
        //this.deviceList = this.deviceList.filter(device => device.deviceStatus !== 'enrolled');
      } else {
        this.deviceList = [...this.deviceList, ...data];
      }
      this.deviceList.forEach(element => {
        if (element.deviceStatus == 'enrolled') {
          element.deviceStatus = 'neverCommunicated'
        }
      });
      this.loadingIndicator = false;
      this.setPageInfo();
    }, error => {
      this.loadingIndicator = false;
      this.setPageInfo();
    })
  }
  setPageInfo() {
    this.pageInfo.count = this.deviceList.length;
    let pagecount = this.pageInfo.count / this.pageInfo.limit;
    this.pageInfo.lastPage = Math.floor(pagecount);
    let decimal = pagecount - this.pageInfo.lastPage;

    if (!Number.isInteger(decimal)) {
      this.pageInfo.lastPage = this.pageInfo.lastPage + 1;
    }
    if (this.page == 0) {
      if (this.deviceList.length) {
        this.currentPageCount.start = (this.pageInfo.offset * this.pageInfo.pageSize) + 1;
        if (this.deviceList.length <= this.pageInfo.limit) {
          this.currentPageCount.end = this.pageInfo.count;
        } else {
          this.currentPageCount.end = (this.pageInfo.offset * this.pageInfo.pageSize) + this.pageInfo.limit;
        }
      }
    }
  }
  getDeviceUsageByMonthYearCount(year, month) {
    this.reportsService.getUsageByMonthYearCount(year, month).subscribe(data => {
      this.currentPageCount.totalCount = data.count;
    }, error => {});
  }
  sortData(res) {
    const labels = [];
    const keys = [];
    const backgroundColors = [];
    const dataset = [];
    const totalCounts = [];
    // find all the distinct messages
    res.forEach((item) => {
       item.neverCommunicated = item.enrolled;
       item.enrolled  = item.active + item.inactive + item.enrolled
      for (var k in item) {
        if (!keys.includes(k) && k !== "date" && k !== "NO_DATA") {
          if (this.messageTypeColor[this.alterLabel(k)]) {
            backgroundColors.push(this.messageTypeColor[this.alterLabel(k)].colorOpaque);
          } else {
            backgroundColors.push(this.messageTypeColor["UNKNOWN"].colorOpaque);
          }
          keys.push(k);
        }
      }
    });
    // Obtain date labels and total counts for each day
    res.map((item) => {
      var datePipe = new DatePipe("en-US");
      // let eventDate = datePipe.transform(item.date, "EE, dd MMM");
      let eventDate = datePipe.transform(item.date, "MMM yyyy");
      labels.push(eventDate);
      let total = 0;
      for (let k in item) {
        if (k !== "date" && item[k]) {
          total += item[k];
        }
      }
      totalCounts.push(total);
    });
    // get message count and configure dataset
    const highestCountsArray = [];
    for (let i = 0; i < keys.length; i++) {
      const obj: any = {};
      const counts = [];
      res.map((item) => {
        if (item[keys[i]]) {
          counts.push(item[keys[i]]);
        } else {
          counts.push(0);
        }
      });
      obj.label = this.alterLabel(keys[i]);
      // obj.label = configMessage.messageType[keys[i]];
      obj.backgroundColor = backgroundColors[i];
      obj.data = counts;
      obj.borderWidth = 1;
      obj.xAxisID = "bar-x-axis1";
      dataset.push(obj);
      highestCountsArray.push(Math.max(...counts));
    }
    this.chartLabels = labels;
    this.stackedDataset = dataset;
    this.totalsDataset = [
      {
        label: "Total Messages",
        backgroundColor: "rgb(54, 162, 235, 1)",
        data: totalCounts,
      },
    ];
    // this.drawStackedBarGraph(this.chartLabels, this.totalsDataset);
    this.drawStackedBarGraph(this.chartLabels, this.stackedDataset);
  }
  alterLabel(label) {
    if (label === "active") {
      return "Active";
    } else if (label === "inactive") {
      return "Inactive";
    } else if (label === "neverCommunicated") {
      return "Never Communicated";
    } else {
      return "Enrolled";
    }
  }

  drawStackedBarGraph(labels, dataset: any) {
    var canvas = <HTMLCanvasElement>document.getElementById("stackedBarGraph");
    var ctx = canvas.getContext("2d")
    this.stackedChart = new Chart(ctx, {
      type: "bar",
      responsive: true,
      data: {
        labels: labels,
        datasets: dataset,
      },
      options: {
        legend: {
          position: "bottom",
          display: true,
          onHover: function (event, legendItem) {
            document.getElementById("canvas").style.cursor = "pointer";
          },
          onClick: function (e, legendItem) {
            var index = legendItem.datasetIndex;
            var ci = this.chart;
            var alreadyHidden =
              ci.getDatasetMeta(index).hidden === null
                ? false
                : ci.getDatasetMeta(index).hidden;
            var anyOthersAlreadyHidden = false;
            var allOthersHidden = true;
            // figure out the current state of the labels
            ci.data.datasets.forEach(function (e, i) {
              var meta = ci.getDatasetMeta(i);
              if (i !== index) {
                if (meta.hidden) {
                  anyOthersAlreadyHidden = true;
                } else {
                  allOthersHidden = false;
                }
              }
            });
            // if the label we clicked is already hidden
            // then we now want to unhide (with any others already unhidden)
            if (alreadyHidden) {
              ci.getDatasetMeta(index).hidden = null;
            } else {
              // otherwise, lets figure out how to toggle visibility based upon the current state
              ci.data.datasets.forEach(function (e, i) {
                var meta = ci.getDatasetMeta(i);
                if (i !== index) {
                  // handles logic when we click on visible hidden label and there is currently at least
                  // one other label that is visible and at least one other label already hidden
                  // (we want to keep those already hidden still hidden)
                  if (anyOthersAlreadyHidden && !allOthersHidden) {
                    meta.hidden = true;
                  } else {
                    // toggle visibility
                    meta.hidden = meta.hidden === null ? !meta.hidden : null;
                  }
                } else {
                  meta.hidden = null;
                }
              });
            }
            ci.update();
          },
        },
        scales: {
          xAxes: [
            {
              display: true,
              stacked: false,
              id: "bar-x-axis1",
              categoryPercentage: 0.5,
              barPercentage: 1,
              ticks: {
                beginAtZero: true
              },   
            },
          ],
          yAxes: [
            {
              display: true,
              stacked: false,
              ticks: {
                suggestedMin: 0,
                suggestedMax: 1,
                beginAtZero: true
                // stepSize: this.highestCount > 9 ? null : 1,
              },
            },
          ],
        },
      },
    });
  }

  getAccountStatus(customerID?: any) {
    let assetType: any
    let customerId: any
    if (customerID != undefined && customerID != null) {
      customerId =customerID
    } else {
      customerId = this.currentCustomer.customerId;
    }
    
    if (customerId != undefined || customerId != null) {
       
    this.reportsService.getAccountStatus(customerId).subscribe(data => {
      console.log("total vehicle/device count API", data)
      if (data.customerId && data.customerId != null) {
        
        if (data.toDate && data.toDate != null) {
          this.convertLongToDate(data)
        } else {
          this.toDate = null
          this.accountStatus = "Active"
          this.expiredDate = true
        }
      } else if (assetType === "device" && data[0].assetType ==="device") {
        this.totalAssetCount = data[1].count
      }
      }, error => {
        console.log("total vehicle/device count API", error)
      })
    }
  }
  convertLongToDate(row:any) {
    const currentDate = new Date()
    let tempMin = currentDate.getTimezoneOffset()
    let offSet = tempMin * 60 * 1000 * -1;

    if (row.toDate && row.toDate != null) {
      const toDate = new Date(row.toDate)
      var tempToDate = toDate.getTime() + offSet 
     var todate = new Date(tempToDate)
     var todayDate = new Date()
     if (!(todate < todayDate)) {
       this.accountStatus = "Active"
       this.expiredDate = false
     } else if (todate.getDate() === todayDate.getDate()) {
       this.accountStatus = "Active"
       this.expiredDate = false
     } else {
       this.accountStatus = "Inactive"
       this.expiredDate = true
     }
     this.toDate = tempToDate
   }
  }

  getTotalMessagesCount(customerId?:any) {
    this.reportsService.getTotalMessagesCount(customerId).subscribe((data) => {
      this.totalMessagesCount = data.totalMessagesCount
    }, error => {
      console.log("error", error)
    })
  }
  getTotalCountOfAsset(assetType: any, customerId?: any) {
    if (assetType === undefined) {
      this.assetType = "device"
    } else {
      this.assetType = assetType
    }
    this.reportsService.getTotalCountOfAsset(customerId).subscribe(data => {
    data.forEach(element => {
      if (this.assetType === "vehicle" && element.assetType ==="vehicle") {
        this.totalAssetCount = element.count
      } else if (this.assetType === "device" && element.assetType ==="device") {
        this.totalAssetCount = element.count
      }  else if (this.assetType === "device" && element.assetType ==="Others") {
        this.totalAssetCount = element.count
      }
    });
    }, error => {
      console.log("total vehicle/device count API", error)
    })
  }

  getData(customerId?: any) {
    this.getTotalCountOfAsset("device", customerId);
    this.getAccountStatus(customerId);
    this.getTotalMessagesCount(customerId);
    this.getDeviceUsageByMonthYearCount(this.selectedYear, this.selectedMonth);
   }
}
