<div class="page-height">
  <div class="row align-items-center">
    <div class="col-md-12 text-center">
      <form (ngSubmit)="sf.form.valid && onSearch()" #sf="ngForm" novalidate
            class="search-wrapper">
        <div class="select-container text-left">
          <!--<label>Search Device</label>-->
          <ng-select [items]="searchHeader" bindLabel="displayName" bindValue="name" [multiple]="false"
                     [closeOnSelect]="true" name="searchId" [(ngModel)]="searchModel.searchId"
                     [clearable]="false" (change)="selectInput()"
                     placeholder="Select Type" #searchId="ngModel" required="">
          </ng-select>
        </div><div class="container-input ">
          <input #inputElement placeholder="Search" (keyup)="inputValidator($event)" type="text" name="searchValue" required [(ngModel)]="searchModel.searchValue"
                 #searchValue="ngModel">
          <i class="fa fa-search img-responsive search-icon cursor-pointer" aria-hidden="true"></i>
        </div>
      </form>

    </div>
  </div>
  <div class="row mt-2">
    <div class="col-12">
      <div class="col-12 text-center my-5" *ngIf="noResults">
        <br><br><br>
        <img width="170" src="../../../assets/img/info_nodata_256.png"/>
        <h4 class="mt-3">No data to show</h4>
      </div>
      <ngx-datatable
        *ngIf="devices.length"
        class="bootstrap alertTable reportsTable wrapcell"
        [rows]="devices"
        [columnMode]="'force'"
        [headerHeight]="50"
        [footerHeight]="50"
        [rowHeight]="'auto'"
        [scrollbarH]="true"
        [limit]="10">
        <ngx-datatable-column [resizeable]="false" [sortable]="false" name="#" [width]="50">
          <ng-template let-rowIndex="rowIndex" ngx-datatable-cell-template>
            {{rowIndex+1}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [resizeable]="false" [sortable]="false" name="Serial Number" >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.serialNumber}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [resizeable]="false" [sortable]="false" name="tested">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.tested}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [resizeable]="false" [sortable]="false" name="creatorName">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.creatorName}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [resizeable]="false" [sortable]="false" name="Created At">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.createdAt | date : dateFormat}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [resizeable]="false" [sortable]="false" name="simCcid">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.simCcid}}
          </ng-template>
        </ngx-datatable-column>
        <ng-container
          *ngIf="permissionAuthorities.authorities.includes('UPDATE') || permissionAuthorities.authorities.includes('DELETE');">
          <ngx-datatable-column [sortable]="false" [resizeable]="false" name="Action" >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div>
                <button *ngIf="permissionAuthorities.authorities.includes('UPDATE');"
                        (click)="getSelectedRow(row)"
                        data-target="#editAtrModal" data-toggle="modal"
                        class="btn btn-outline-primary btn-sm btn-icon-left mr-1">
                  <i class="fa fa-pencil"></i> Initiate Replacement
                </button>
              </div>
            </ng-template>
          </ngx-datatable-column>
        </ng-container>
      </ngx-datatable>
    </div>
  </div>
</div>
<!--START MODAL edit-->
<div id="editAtrModal" class="modal effect-flip-horizontal fade" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog" role="document">
    <div class="modal-content bd-0 bg-transparent overflow-hidden">
      <div class="modal-body p-0 add-attributes-modal">
        <div class="row no-gutters">
          <div class="col-12">
            <span #btnClose class="pull-right closeBtn" data-dismiss="modal"><i class="fa fa-times"
                                                                                aria-hidden="true"></i></span>
            <div class="modal-box p-3 mt-4">
              <div class="reply-header">
                <h5>
                  Initiate Replacement
                </h5>
              </div>
              <div class="login-content mt-20">
                <form action="javascript:void(0);" #f1="ngForm" (ngSubmit)="update(f1.valid,f1)" novalidate method="POST"
                      class="margin-bottom-0">
                  <div class="row">
                    <div class="col-md-12 sm-form-design">
                      <input type="text" [(ngModel)]="searchModel.oldSerialNumber" name="oldSerialNumber" id="oldSerialNumber"
                             class="form-control h5-email"
                             placeholder="Please enter key"
                             value=""
                             autocomplete="off"
                             tabindex="1"
                             disabled
                             #oldSerialNumber="ngModel" required>
                      <label class="control-label">Selected Device <span class="text-danger">*</span></label>
                    </div>
                    <div class="col-md-12 sm-form-design mt-3">
                      <ng-select
                        [items]="device$ | async"
                        [closeOnSelect]="true"
                        class="selection-type width100 with-label"
                        name="newSerialNumber"
                        bindLabel="serialNumber"
                        bindValue="serialNumber"
                        [(ngModel)]="searchModel.newSerialNumber"
                        placeholder="Select Device"
                        #newSerialNumber="ngModel" required
                        typeToSearchText="Please enter 1 or more characters"
                        (open)="getUnAssociatedDevices()"
                        [minTermLength]="1"
                        (change)="removeLoader()"
                        [loading]="deviceLoading"
                        [typeahead]="deviceInput$">
                      </ng-select>
                      <label class="control-label">New Device <span class="text-danger">*</span></label>
                      <div [hidden]="newSerialNumber.valid || (newSerialNumber.pristine && !f1.submitted)">
                        <small class="text-danger">Select New Device</small>
                      </div>
                    </div>
                    <div class="col-md-12 sm-form-design my-3">
                      <textarea id="comments"
                                class="form-control h5-email"
                                placeholder="Please enter Comments"
                                value=""
                                autocomplete="off"
                                tabindex="1"
                                rows="5"
                                name="comments" [(ngModel)]="searchModel.comments" #comments="ngModel"
                                required></textarea>
                      <label class="control-label">Comments <span class="text-danger">*</span></label>
                      <div [hidden]="comments.valid || (comments.pristine && !f1.submitted)">
                        <small class="text-danger">Please Add Your Comments</small>
                      </div>
                    </div>
                  </div>
                  <!-- Dynamic Row Ends -->
                  <div class="row pb-3 pt-5">
                    <div class="col-lg-6 alertBack darbyColor alertBack d-none d-sm-block emptyDiv">&nbsp;</div>
                    <div class="col-lg-6 login-buttons text-right">
                      <button class="btn btn-outline-secondary small-screen-btn mr-2" data-dismiss="modal">Cancel
                      </button>
                      <input type="submit" class="btn btn-success small-screen-btn"
                             [disabled]="disable" value="Initiate">
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--END MODAL ADD-->
