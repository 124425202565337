<app-notify-message></app-notify-message>
<app-reports-header></app-reports-header>
<div class="row no-gutters">
  <div class="col-md-3 col-lg-2 col-sm-12 p-0">
    <app-reports-side-menu></app-reports-side-menu>
  </div>
  <div class="reports-body col-md-9 col-lg-10 col-sm-12 p-0">
    <div class="row no-gutters align-items-center action-row px-2">
      <div class="col-md-4">
        <h4 class="report-heading page-heading"><i class="fa fa-bar-chart"></i> Not Communicating</h4>
      </div>
      <div class="col-md-8 text-right">
        <div class="dropdown d-inline-block">
          <button
            class="btn btn-custom dropdown-toggle type-dropdown"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            >
            {{displayCategory}}
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a *ngFor="let data of groups" class="dropdown-item" (click)="onSelectCategory(data)">{{data.name}}</a>
            <!-- <a class="dropdown-item" (click)="onCategoryChange('firmwareVersion', 'Firmware')">Firmware</a>
            <a class="dropdown-item" (click)="onCategoryChange('configVersion', 'Configuration')">Configuration</a>
            <a class="dropdown-item" (click)="onCategoryChange('modal', 'Model')">Model</a>
            <a class="dropdown-item" (click)="onCategoryChange('simCarrier1', 'SIM Carrier')">SIM Carrier</a> -->
            <!-- <a class="dropdown-item" (click)="onCategoryChange('simCcid', 'SIM CCID')">SIM CCID</a> -->
            <!-- <a class="dropdown-item" (click)="onCategoryChange('userTag', 'User Tag')">User Tag</a> -->
          </div>
        </div>
        <div class="calendar d-inline-block ml-2" (clickOutside)="hideCalendar=!hideCalendar;">
          <app-date-time-calendar
            [clickOutside]="hideCalendar"
            [selectPreset]="'30days'"
            [timeSelection]="false"
            [hideLinks]="['today', '15mins', '30mins', '1hour', '4hours', '12hours']"
            (timeChangeEvent)="onTimeChange($event)"
            >
          </app-date-time-calendar>
          <button
            class="btn btn-icon ml-2 refresh"
            (click)="refresh()"
            tooltip="Refresh"
            placement="top"
            [disabled]="isLoading.firmwarePie || isLoading.trendData || isLoading.devices">
            <i class="fa fa-refresh" aria-hidden="true"></i>
            <span class="mob-access-text d-none">&nbsp;Refresh</span>
          </button>
        </div>
      </div>
    </div>
    <div class="row no-gutters pt-1">
      <!-- <div class="col-12 col-md-5 p-2">
        <div class="report-item-container animated fadeIn">
          <div class="d-inline-block">
            <h6 style="text-transform: capitalize;">{{displayCategory}}</h6>
          </div>
          <div class="d-inline-block category-search pull-right relative" (clickOutside)="searchPopup=false;">
            <input
              type="text"
              name="categorySearch"
              #columnVal="ngModel"
              placeholder="Search"
              [(ngModel)]="searchValue"
              (focus)="showSearchPopup()"
              (keyup)="onCategorySearch()"
              />
            <span class="cross-icon" *ngIf="searchValue" (click)="searchValue=''">
              <i class="fa fa-times search-icon" aria-hidden="true"></i>
            </span>
            <span *ngIf="!searchValue">
              <i class="fa fa-search img-responsive search-icon" aria-hidden="true"></i>
            </span>
            <div *ngIf="searchPopup" class="search-dropdown">
              <div class="dropdown-item" *ngFor="let item of searchedData" (click)="onSelectCategory(item)">
                {{item.category}}
              </div>
              <div class="dropdown-item text-center" disabled *ngIf="!searchedData.length">
                <span style="font-style: italic; opacity: 0.5;">No Data</span>
              </div>
            </div>
          </div>
          <canvas id="pieChart" height="216" [style.display]="isLoading.firmwarePie ? 'none' : 'block'" role="img"></canvas>
          <div class="loading" *ngIf="isLoading.firmwarePie">
            <img src="../../../assets/img/darby_loader_small.gif"/>
          </div>
        </div>
      </div> -->
      <div class="col-md-7 col-12 p-2" style="margin-left: 17% !important;">
        <div class="report-item-container animated fadeIn">
          <h6><span style="text-transform: capitalize;">Trend line</span> ({{displayCategory}})</h6>
          <canvas id="lineChart" role="img" [style.display]="isLoading.trendData ? 'none' : 'block'"></canvas>
          <div class="loading" *ngIf="isLoading.trendData">
            <img src="../../../assets/img/darby_loader_small.gif"/>
          </div>
        </div>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-12 p-2">
        <div class="report-item-container animated fadeIn" style="min-height: 300px;">
          <app-reports-table
            *ngIf="!isLoading.devices"
            [startTime]="startTime"
            [endTime]="endTime"
            [displayTitle]="'Not Communicating Devices'"
            [fromComponent]="'not-communicating'"
            >
          <!-- [devicesList]="devices"
          [style.display]="isLoading.devices ? 'none' : 'block'"
          [blockCall]="blockCall"
          [changeOffset]="resetOffset"
          (pageChangeEvent)="onPageChange($event)"
          (downloadEvent)="downloadAllNotCommDevices($event)" -->
          </app-reports-table>
          <div class="loading" *ngIf="isLoading.devices">
            <img src="../../../assets/img/darby_loader_small.gif"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
