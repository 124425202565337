import {Component, OnInit} from '@angular/core';
import {FaqService} from "@layouts/faq/faq.service";
import {SharedService} from "@shared/shared.service";

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  data: any = [];

  constructor(
    private faqService: FaqService,
    private sharedService: SharedService
  ) {
  }

  ngOnInit() {
    this.getFaq();
  }

  getFaq() {
    this.faqService.getFaq().subscribe({
      next: (res: any) => {
        this.data = res;
      },
      error: error => {
        this.sharedService.getErrorMsg(error);
      }
    });
  }
}
