import { throwError as observableThrowError, BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { Router } from '@angular/router';
import 'rxjs/Observable';
import { environment } from '@myenv/environment';
var OtaService = /** @class */ (function () {
    function OtaService(http, router, handler) {
        this.http = http;
        this.router = router;
        this.handler = handler;
        this.sideData = [
            {
                name: 'menu.CAMPAIGN',
                img: 'flag-checkered',
                route: '/ota'
            }, {
                name: 'menu.FILES',
                img: 'cube',
                route: '/ota/files'
            }
        ];
        this.d = new Date();
        this.offsetInMinutes = this.d.getTimezoneOffset() * -1;
        this.timeZone = "?dateFormat=" + environment.isoDateTimeFormat + "&offsetInMinutes=" + this.offsetInMinutes;
        this.apiUrl = environment.apiUrl;
        this.apiV2Url = environment.apiV2Url;
        this.messageSource = new BehaviorSubject(this.sideData);
        this.currentMessage = this.messageSource.asObservable();
        this.httpClient = new HttpClient(handler);
    }
    OtaService.prototype.getAllOta = function (page) {
        return this.http.get(this.apiUrl + 'campaigns/files?page=' + page).pipe(catchError(this.handleError));
    };
    OtaService.prototype.getAllOtaFiles = function (data) {
        return this.http.get(this.apiUrl + 'campaigns/ota/files/fileType/' + data).pipe(catchError(this.handleError));
    };
    OtaService.prototype.getEcuCampaignFiles = function (data) {
        return this.http.get(this.apiUrl + 'campaigns/files/fileType/' + data).pipe(catchError(this.handleError));
    };
    OtaService.prototype.loadFiles = function (campaignId) {
        return this.http.get(this.apiUrl + ("campaigns/" + campaignId + "/files")).pipe(catchError(this.handleError));
    };
    OtaService.prototype.getCommands = function (campaignId) {
        return this.http.get(this.apiUrl + ("campaigns/" + campaignId + "/commands")).pipe(catchError(this.handleError));
    };
    OtaService.prototype.getFileDetails = function (fileId) {
        return this.http.get(this.apiUrl + 'ota/files/' + fileId).pipe(catchError(this.handleError));
    };
    OtaService.prototype.getDeviceHistory = function (data) {
        return this.http.get(this.apiUrl + ("campaigns/" + data.campaignId + "/device/" + data.serialNumber + "/history")).pipe(catchError(this.handleError));
    };
    OtaService.prototype.getV2DeviceHistory = function (data, productType) {
        this.customerId = JSON.parse(localStorage.getItem('currentUser')).customerId;
        return this.http.get(this.apiV2Url + (productType + "/" + this.customerId + "/v2/campaigns/" + data.campaignId + "/device/" + data.serialNumber + "/history")).pipe(catchError(this.handleError));
    };
    OtaService.prototype.uploadXL = function (data, fileName, fileType, fileVersion, ecuGroup, ecuName) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
        });
        var tempUrl = this.apiUrl + ("campaigns/files/fileType/" + fileType + "/upload?fileName=" + fileName);
        if (fileVersion)
            tempUrl += "&fileVersion=" + fileVersion;
        if (ecuGroup && ecuName) {
            tempUrl += "&ecuGroup=" + ecuGroup + "&ecuName=" + ecuName;
        }
        return this.httpClient.post(tempUrl, data, { headers: headers }).pipe(catchError(this.handleError));
    };
    OtaService.prototype.uploadV2XL = function (data, fileName, fileType, fileVersion, ecuGroup, ecuName, productType) {
        this.customerId = JSON.parse(localStorage.getItem('currentUser')).customerId;
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
        });
        var tempUrl = this.apiV2Url + (productType + "/" + this.customerId + "/v2/campaigns/files/fileType/" + fileType + "/upload?fileName=" + fileName);
        if (fileVersion)
            tempUrl += "&fileVersion=" + fileVersion;
        if (ecuGroup && ecuName) {
            tempUrl += "&ecuGroup=" + ecuGroup + "&ecuName=" + ecuName;
        }
        return this.httpClient.post(tempUrl, data, { headers: headers }).pipe(catchError(this.handleError));
    };
    OtaService.prototype.deleteOta = function (fileId) {
        return this.http.delete(this.apiUrl + 'campaigns/ota/files/' + fileId).pipe(catchError(this.handleError));
    };
    OtaService.prototype.deleteCampaign = function (campaignId) {
        return this.http.delete(this.apiUrl + 'campaigns/' + campaignId).pipe(catchError(this.handleError));
    };
    OtaService.prototype.deleteDevice = function (campaignId, serialNumber) {
        return this.http.delete(this.apiUrl + 'campaigns/' + campaignId + '/device/' + serialNumber + '/cancel').pipe(catchError(this.handleError));
    };
    OtaService.prototype.associateDevice = function (listnerType, otaType, fileId, data) {
        return this.http.put(this.apiUrl + 'ota/files/' + fileId, data).pipe(catchError(this.handleError));
    };
    OtaService.prototype.editUsersVisibility = function (fileId, userIds) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
        });
        var tempUrl = this.apiUrl + 'ota/files/' + fileId + '/visibility';
        if (userIds.length) {
            tempUrl += '?userIds=' + userIds;
        }
        return this.http.put(tempUrl, headers).pipe(
        // return this.http.put(this.apiUrl +'ota/files/'+fileId+'/visibility?userIds='+ userIds, headers)
        catchError(this.handleError));
    };
    OtaService.prototype.uploadConfigXL = function (data, folderName) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
        });
        return this.httpClient.post(this.apiUrl + 'configurations?folderName=' + folderName, data, { headers: headers }).pipe(catchError(this.handleError));
    };
    OtaService.prototype.deleteConfig = function (folderName) {
        return this.http.delete(this.apiUrl + 'configurations/folder/' + folderName).pipe(catchError(this.handleError));
    };
    OtaService.prototype.getOta = function () {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
        });
        return this.http.get(this.apiUrl + 'ota', { headers: headers }).pipe(catchError(this.handleError));
    };
    OtaService.prototype.getAllCampaign = function (page, groupId) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
        });
        var URL = this.apiUrl + 'campaigns?page=' + page;
        if (groupId) {
            URL = URL + '&groupId=' + groupId;
        }
        return this.http.get(URL, { headers: headers }).pipe(catchError(this.handleError));
    };
    OtaService.prototype.onSearchOta = function (value) {
        return this.http.get(this.apiUrl + "ota?serialNumber=" + value).pipe(catchError(this.handleError));
    };
    OtaService.prototype.addCampaign = function (data) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this.httpClient.post(this.apiUrl + 'campaigns', data, { headers: headers }).pipe(catchError(this.handleError));
    };
    OtaService.prototype.addMoreDevices = function (campaignId, typeId, data, overRide) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        var tempURL = this.apiUrl + ("campaigns/" + campaignId + "/devices");
        if (overRide) {
            tempURL += '?action=1';
        }
        return this.http.put(tempURL, data, { headers: headers }).pipe(catchError(this.handleError));
    };
    OtaService.prototype.uploadMoreDevices = function (campaignId, typeId, data, action) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this.httpClient.put(this.apiUrl + ("campaigns/devices/upload/campaignId/" + campaignId + "?action=" + action), data, { headers: headers }).pipe(catchError(this.handleError));
    };
    OtaService.prototype.getDeviceswithStatus = function (campaignId, status, page, serialNumber, errorCode) {
        if (status === 'progress' && errorCode !== 'all') {
            status = errorCode;
            errorCode = null;
        }
        var tempURL = this.apiUrl + ("campaigns/" + campaignId + "/device/status/" + status + "?page=" + page);
        if (serialNumber)
            tempURL += '&serialNumber=' + serialNumber;
        if (errorCode && !['all', 'PROGRESS', 'DOWNLOAD_SUCCESS'].includes(errorCode))
            tempURL += '&errorCode=' + errorCode;
        return this.http.get(tempURL).pipe(catchError(this.handleError));
    };
    OtaService.prototype.getallDeviceswithStatus = function (campaignId, page, serialNumber) {
        var tempURL = this.apiUrl + 'campaigns/' + campaignId + '/devices?page=' + page;
        if (serialNumber) {
            tempURL += '&serialNumbers=' + serialNumber;
        }
        return this.http.get(tempURL).pipe(catchError(this.handleError));
    };
    OtaService.prototype.getCampaignCount = function (groupId) {
        var URL = this.apiUrl + 'campaigns/count';
        if (groupId) {
            URL = URL + '?groupId=' + groupId;
        }
        return this.http.get(URL).pipe(catchError(this.handleError));
    };
    OtaService.prototype.getFilesCount = function () {
        return this.http.get(this.apiUrl + 'campaigns/ota/files/count').pipe(catchError(this.handleError));
    };
    OtaService.prototype.getCounts = function (campaignId) {
        return this.http.get(this.apiUrl + 'campaigns/' + campaignId).pipe(catchError(this.handleError));
    };
    OtaService.prototype.uploadCampaign = function (data) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this.httpClient.post(this.apiUrl + 'campaigns/upload', data, { headers: headers }).pipe(catchError(this.handleError));
    };
    OtaService.prototype.getAllDevices = function (page) {
        return this.http.get(this.apiUrl + 'devices?limit=3000').pipe(catchError(this.handleError));
    };
    OtaService.prototype.onSearchCampaignDevice = function (campaignId, value, status) {
        var tempURL = this.apiUrl + "campaigns/" + campaignId + "/device?serialNumber=" + value;
        if (status) {
            tempURL += '&status=' + status;
        }
        return this.http.get(tempURL).pipe(catchError(this.handleError));
    };
    OtaService.prototype.onSearchFile = function (key, value) {
        return this.http.get(this.apiUrl + "campaigns/files/search/" + key + "?value=" + value).pipe(catchError(this.handleError));
    };
    OtaService.prototype.handleError = function (error) {
        return observableThrowError(error.error || error || 'Server error');
    };
    OtaService.prototype.downloadFileCSV = function (searchData) {
        var tempUrl = this.apiUrl + 'ota/files/download' + this.timeZone;
        if (searchData.vehicleTypeVal) {
            tempUrl += '&key=' + searchData.campaign + '&value=' + searchData.vehicleTypeVal;
        }
        return this.http.get(tempUrl, { responseType: 'arraybuffer', observe: 'response' }).pipe(catchError(this.handleError));
    };
    OtaService.prototype.downloadCampaignCSV = function (searchData, label) {
        var tempUrl = this.apiUrl + 'campaigns/download' + this.timeZone;
        // if (searchData.vehicleTypeVal || deviceStatusKey) {
        if (label) {
            tempUrl += '&label=' + label;
        }
        if (searchData.vehicleTypeVal) {
            tempUrl += '&key=' + searchData.campaign + '&value=' + searchData.vehicleTypeVal;
        }
        // if (deviceStatusKey && searchData.vehicleTypeVal) {
        //   tempUrl += '?key=' + searchData.campaign + '&value=' + searchData.vehicleTypeVal + '&status=' + deviceStatusKey;
        // }
        // }
        return this.http.get(tempUrl, { responseType: 'arraybuffer', observe: 'response' })
            .pipe(catchError(this.handleError));
    };
    OtaService.prototype.downloadSelectedDeviceCSV = function (serialNumbers, label) {
        var tempUrl = this.apiUrl + 'campaigns/device/latest-status/download' + this.timeZone;
        // if (searchData.vehicleTypeVal || deviceStatusKey) {
        //   if (deviceStatusKey) {
        //     tempUrl += '&status=' + deviceStatusKey;
        //   }
        //   if (searchData.vehicleTypeVal) {
        //     tempUrl += '&key=' + searchData.campaign + '&value=' + searchData.vehicleTypeVal;
        //   }
        if (serialNumbers)
            tempUrl += '&serialNumbers=' + serialNumbers;
        if (label)
            tempUrl += "&label=" + label;
        // }
        return this.http.get(tempUrl, { responseType: 'arraybuffer', observe: 'response' })
            .pipe(catchError(this.handleError));
    };
    OtaService.prototype.downloadOtaFilesCSV = function (searchData, deviceStatusKey) {
        var tempUrl = this.apiUrl + 'ota/files/download' + this.timeZone;
        // if (searchData.vehicleTypeVal || deviceStatusKey) {
        if (deviceStatusKey) {
            tempUrl += '&status=' + deviceStatusKey;
        }
        if (searchData.vehicleTypeVal) {
            tempUrl += '&key=' + searchData.campaign + '&value=' + searchData.vehicleTypeVal;
        }
        // if (deviceStatusKey && searchData.vehicleTypeVal) {
        //   tempUrl += '?key=' + searchData.campaign + '&value=' + searchData.vehicleTypeVal + '&status=' + deviceStatusKey;
        // }
        // }
        return this.http.get(tempUrl, { responseType: 'arraybuffer', observe: 'response' })
            .pipe(catchError(this.handleError));
    };
    OtaService.prototype.downloadCampaignDeviceCSV = function (campaignId, status, searchValue, errorCode) {
        if (status === 'PROGRESS' && errorCode !== 'all') {
            status = errorCode;
            errorCode = null;
        }
        var tempUrl = this.apiUrl + "campaigns/" + campaignId + "/device/download" + this.timeZone;
        if (status)
            tempUrl += '&status=' + status;
        if (searchValue)
            tempUrl += '&serialNumber=' + searchValue;
        if (errorCode && !['all', 'PROGRESS', 'DOWNLOAD_SUCCESS'].includes(errorCode))
            tempUrl += '&errorCode=' + errorCode;
        return this.http.get(tempUrl, { responseType: 'arraybuffer', observe: 'response' }).pipe(catchError(this.handleError));
    };
    OtaService.prototype.onSearchCampaign = function (key, value, label) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        var tempUrl = this.apiUrl + "campaigns/search";
        if (key && value) {
            var char = tempUrl.includes('?') ? '&' : '?';
            tempUrl += char + "key=" + key + "&value=" + value;
        }
        if (label) {
            var char = tempUrl.includes('?') ? '&' : '?';
            tempUrl += char + "key=label&value=" + label;
        }
        return this.httpClient.get(tempUrl, { headers: headers }).pipe(catchError(this.handleError));
    };
    OtaService.prototype.retryDeviceCampaign = function (campaignId, serialNumber) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this.httpClient.put(this.apiUrl + ("campaigns/campaignId/" + campaignId + "/serialNumber/" + serialNumber + "/retry"), '', { headers: headers })
            .pipe(catchError(this.handleError));
    };
    OtaService.prototype.getRetryDeviceCount = function (campaignId) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this.httpClient.get(this.apiUrl + ("campaigns/" + campaignId + "/devices/status/count/retry/all"), { headers: headers })
            .pipe(catchError(this.handleError));
    };
    OtaService.prototype.retryAllDeviceCampaign = function (campaignId, status, timeDiff) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        var tempURL = "campaigns/" + campaignId + "/devices/retry/all?override=true";
        if (['PROGRESS', 'CANCELLED', 'FAILED', 'DOWNLOAD_SUCCESS', 'SUCCESS', 'INSTALL_SUCCESS', 'INSTALL_FAILED', 'SCHEDULED'].includes(status))
            tempURL += "&status=" + status;
        else
            tempURL += "&status=FAILED&errorCode=" + status;
        if (timeDiff)
            tempURL += "&timeDifference=" + timeDiff;
        return this.httpClient.post(this.apiUrl + tempURL, {}, { headers: headers })
            .pipe(catchError(this.handleError));
    };
    OtaService.prototype.cancelAndRetryDevice = function (campaignId, serialNumber) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this.httpClient.put(this.apiUrl + ("campaigns/campaignId/" + campaignId + "/serialNumber/" + serialNumber + "/retry"), '', { headers: headers })
            .pipe(catchError(this.handleError));
    };
    OtaService.prototype.triggerFlashDevice = function (campaignId, serialNumber) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this.httpClient.put(this.apiUrl + ("campaigns/campaignId/" + campaignId + "/serialNumber/" + serialNumber + "/flashEcu"), '', { headers: headers })
            .pipe(catchError(this.handleError));
    };
    OtaService.prototype.getEcuGroups = function () {
        return this.http.get(this.apiUrl + 'campaigns/ecu/groups').pipe(catchError(this.handleError));
    };
    OtaService.prototype.getEcuNames = function (ecuGroup) {
        return this.http.get(this.apiUrl + ("campaigns/ecu/names?ecuGroup=" + ecuGroup)).pipe(catchError(this.handleError));
    };
    OtaService.prototype.getCampaignUploadHistory = function (page, campaignId) {
        return this.http.get(this.apiUrl + ("campaigns/" + campaignId + "/log?page=" + page + "&limit=100")).pipe(catchError(this.handleError));
    };
    OtaService.prototype.downloadLogDetailsCSV = function (logId) {
        return this.http.get(this.apiUrl + ("campaigns/log/" + logId + "/download"), { responseType: 'arraybuffer', observe: 'response' }).pipe(catchError(this.handleError));
    };
    // Campaign dashboard API
    OtaService.prototype.getAllCampaignCount = function (customerId, groupId) {
        var tempUrl = this.apiUrl + "campaigns/type/count";
        if (customerId) {
            tempUrl += "?customerId=" + customerId;
        }
        if (groupId) {
            var char = tempUrl.includes('?') ? '&' : '?';
            tempUrl += char + "groupId=" + groupId;
        }
        return this.http.get(tempUrl)
            .pipe(catchError(this.handleError));
    };
    OtaService.prototype.getHistoricalCampaignCount = function (months, customerId, groupId) {
        var tempUrl = this.apiUrl + "campaigns/type/historical/count";
        if (months) {
            var char = tempUrl.includes('?') ? '&' : '?';
            tempUrl += char + "count=" + months;
        }
        if (customerId) {
            var char = tempUrl.includes('?') ? '&' : '?';
            tempUrl += char + "customerId=" + customerId;
        }
        if (groupId) {
            var char = tempUrl.includes('?') ? '&' : '?';
            tempUrl += char + "groupId=" + groupId;
        }
        return this.http.get(tempUrl).pipe(catchError(this.handleError));
    };
    OtaService.prototype.getAllCampaignDeviceCount = function (label, type, customerId, groupId) {
        var tempUrl = this.apiUrl + "campaigns/device/category/count";
        if (label) {
            var char = tempUrl.includes('?') ? '&' : '?';
            tempUrl += char + "label=" + label;
        }
        if (type) {
            var char = tempUrl.includes('?') ? '&' : '?';
            tempUrl += char + "type=" + type;
        }
        if (customerId) {
            var char = tempUrl.includes('?') ? '&' : '?';
            tempUrl += char + "customerId=" + customerId;
        }
        if (groupId) {
            var char = tempUrl.includes('?') ? '&' : '?';
            tempUrl += char + "groupId=" + groupId;
        }
        return this.http.get(tempUrl).pipe(catchError(this.handleError));
    };
    OtaService.prototype.getAllCampaignFilteredDevices = function (label, type, status, customerId, groupId) {
        var tempUrl = this.apiUrl + ("campaigns/device/category?status=" + status + "&page=0&limit=100");
        if (label) {
            tempUrl += "&label=" + label;
        }
        if (type) {
            tempUrl += "&type=" + type;
        }
        if (customerId) {
            tempUrl += "&customerId=" + customerId;
        }
        if (groupId) {
            tempUrl += "&groupId=" + groupId;
        }
        return this.http.get(tempUrl).pipe(catchError(this.handleError));
    };
    OtaService.prototype.downloadCampaignFilteredDevicesCSV = function (label, type, status, customerId) {
        var tempUrl = this.apiUrl + ("campaigns/device/category/download" + this.timeZone + "&status=" + status);
        if (label) {
            tempUrl += "&label=" + label;
        }
        if (type) {
            tempUrl += "&type=" + type;
        }
        if (customerId) {
            tempUrl += "&customerId=" + customerId;
        }
        return this.http.get(tempUrl, { responseType: 'arraybuffer', observe: 'response' })
            .pipe(catchError(this.handleError));
    };
    OtaService.prototype.getAllLabels = function (customerId) {
        var tempUrl = this.apiUrl + "ota/labels";
        if (customerId) {
            tempUrl += "?customerId=" + customerId;
        }
        return this.http.get(tempUrl).pipe(catchError(this.handleError));
    };
    OtaService.prototype.createLabel = function (label) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this.httpClient.post(this.apiUrl + ("ota/labels/" + label), {}, { headers: headers })
            .pipe(catchError(this.handleError));
    };
    OtaService.prototype.updateCampaign = function (campaignId, description, label) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
        var tempUrl = this.apiUrl + ("campaigns/" + campaignId);
        if (description) {
            var char = tempUrl.includes('?') ? '&' : '?';
            tempUrl += char + "description=" + description;
        }
        if (label) {
            var char = tempUrl.includes('?') ? '&' : '?';
            tempUrl += char + "label=" + label;
        }
        return this.http.put(tempUrl, headers).pipe(catchError(this.handleError));
    };
    OtaService.prototype.getSubFilters = function (campaignId, status) {
        return this.http.get(this.apiUrl + ("campaigns/" + campaignId + "/status/" + status + "/category/count"))
            .pipe(catchError(this.handleError));
    };
    OtaService.prototype.editCampaign = function (campaignID, data) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this.httpClient.put(this.apiUrl + 'campaigns/' + campaignID, data, { headers: headers }).pipe(catchError(this.handleError));
    };
    OtaService.prototype.editfile = function (data, fileId) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
        });
        var tempUrl = this.apiUrl + ("campaigns/ota/files/" + fileId);
        return this.httpClient.put(tempUrl, { description: data }, { headers: headers }).pipe(catchError(this.handleError));
    };
    return OtaService;
}());
export { OtaService };
