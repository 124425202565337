import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '@myenv/environment';
var DashboardService = /** @class */ (function () {
    function DashboardService(http, router) {
        this.http = http;
        this.router = router;
        this.apiUrl = environment.apiUrl;
        this.reportModule = [];
        var permissionmodules = localStorage.getItem('permissionModules');
        this.reportModule = permissionmodules.includes('REPORT');
    }
    DashboardService.prototype.getDashboards = function () {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
        return this.http.get(this.apiUrl + 'dashboard/assigned', { headers: headers }).pipe(catchError(this.handleError));
    };
    DashboardService.prototype.getVindecoder = function (vin) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
        return this.http.get(this.apiUrl + ("devices/vinSearch/" + vin), { headers: headers }).pipe(catchError(this.handleError));
    };
    DashboardService.prototype.getEVStats = function (deviceId) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
        return this.http.get(this.apiUrl + ("messages/ebike/device/" + deviceId + "?messageType=ExtendedVehicleSnapshot&attributes=evbike,customerId,deviceId,eventTime&sort=DSC"), { headers: headers }).pipe(catchError(this.handleError));
    };
    DashboardService.prototype.getTripsDetails = function (deviceId, limit) {
        return this.http.get(this.apiUrl + 'trips/device/' + deviceId + '?limit=' + limit);
    };
    DashboardService.prototype.getallWidgets = function (currentDashboard) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
        return this.http.get(this.apiUrl + 'dashboard/' + currentDashboard, { headers: headers }).pipe(catchError(this.handleError));
    };
    DashboardService.prototype.getVehicleDetail = function (deviceId, attributes) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
        var tempUrl = "messages/device/" + deviceId + "/snapped";
        tempUrl += "?attributes=" + attributes;
        return this.http.get(this.apiUrl + tempUrl, { headers: headers }).pipe(catchError(this.handleError));
    };
    DashboardService.prototype.getSingleWidgetData = function (widgetId, data) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
        if (!data) {
            data = {};
        }
        return this.http.post(this.apiUrl + 'dashboard/widget/' + widgetId + '/data/generate', data, { headers: headers }).pipe(catchError(this.handleError));
    };
    DashboardService.prototype.getEVDetail = function (deviceId) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
        return this.http.get(this.apiUrl + ("messages/latest/ebike/device/" + deviceId), { headers: headers }).pipe(catchError(this.handleError));
    };
    DashboardService.prototype.updateLayout = function (data) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
        return this.http.put(this.apiUrl + 'dashboard/' + data.dashboardId + '/update/layout?layout=' + data.layout, { headers: headers }).pipe(catchError(this.handleError));
    };
    // To get the trips
    DashboardService.prototype.getTrips = function (deviceId, start, end, limit) {
        return this.http.get(this.apiUrl + ("trips/device/" + deviceId + "?startTime=" + start + "&endTime=" + end + "&limit=" + limit + "&sort=desc"));
    };
    // To get the driving behaviour
    DashboardService.prototype.getDrivingBehaviour = function (deviceId, endTime, startTime) {
        return this.http.get(this.apiUrl +
            ("analytics/driver/behaviour/device/" + deviceId + "/count") +
            ("?startTime=" + startTime + "&endTime=" + endTime));
    };
    // To get the driving behaviour by message
    DashboardService.prototype.getIndividualDrivingBehaviour = function (deviceId, endTime, startTime, messageType) {
        return this.http.get(this.apiUrl +
            ("analytics/driver/behaviour/device/" + deviceId + "/message/" + messageType) +
            ("?startTime=" + startTime + "&endTime=" + endTime));
    };
    // To get the Vehicle Status
    DashboardService.prototype.getVehicleStatus = function (deviceId, limit) {
        return this.http.get(this.apiUrl + 'analytics/dtc/device/' + deviceId + '?limit=' + limit);
    };
    // To get the device Events
    DashboardService.prototype.getDeviceEvents = function (deviceId, limit) {
        return this.http.get(this.apiUrl + 'analytics/device/' + deviceId + '/activity?limit=' + limit);
    };
    // To get the vehicle count
    DashboardService.prototype.getVehicleCount = function (deviceId, limit) {
        return this.http.get(this.apiUrl + 'analytics/dtc/device/' + deviceId + '/count?limit=' + limit);
    };
    // Get battery metrics grapg data
    DashboardService.prototype.getBatteryMetrics = function (deviceId, startTime, endTime, limit) {
        return this.http.get(this.apiUrl +
            ("analytics/battery/metrics/device/" + deviceId) +
            ("?startTime=" + startTime + "&endTime=" + endTime + "&limit=" + limit))
            .pipe(catchError(this.handleError));
    };
    DashboardService.prototype.getDeviceCommunicationTrend = function (startTime, endTime, key, value) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
        return this.http.get(this.apiUrl +
            "analytics/communication/trend" +
            ("?startTime=" + startTime + "&endTime=" + endTime + "&type=" + key + "&typeValue=" + value), { headers: headers }).pipe(catchError(this.handleError));
    };
    DashboardService.prototype.getKeyCounts = function (key) {
        return this.http.get(this.apiUrl + ("devices/count/key/" + key)).pipe(catchError(this.handleError));
    };
    DashboardService.prototype.getAllWarningCounts = function (startDate, endDate) {
        return this.http.get(this.apiUrl + ("devices/warning/stats/count?startDate=" + startDate + "&endDate=" + endDate))
            .pipe(catchError(this.handleError));
    };
    DashboardService.prototype.getDeviceData = function (deviceId) {
        return this.http.get(this.apiUrl + ("messages/messageId/" + deviceId));
    };
    DashboardService.prototype.getExtendedVehicleSnapshotData = function (deviceId) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
        var tempUrl = "messages/device/" + deviceId + "/extendedData";
        return this.http.get(this.apiUrl + tempUrl, { headers: headers }).pipe(catchError(this.handleError));
    };
    DashboardService.prototype.getDeviceRecentEvents = function (deviceId, page, pagelimit, messageTypes) {
        var tempUrl = "messages/device/" + deviceId + "?page=" + page + "&limit=" + pagelimit;
        if (messageTypes) {
            tempUrl = tempUrl + ("&messageTypes=" + messageTypes);
        }
        return this.http.get(this.apiUrl + tempUrl).pipe(catchError(this.handleError));
    };
    DashboardService.prototype.getVinDecodedDetails = function (vin) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
        return this.http.get(this.apiUrl + ("devices/decodeVIN/" + vin), { headers: headers }).pipe(catchError(this.handleError));
    };
    DashboardService.prototype.handleError = function (error) {
        console.log(error.error || error.json() || error);
        return observableThrowError(error.error || error.json() || error || 'Server error');
    };
    return DashboardService;
}());
export { DashboardService };
