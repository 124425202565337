import { OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '@shared/shared.service';
import { Router } from '@angular/router';
import { enMessage } from '@shared/en-us-message';
var NotifyMessageComponent = /** @class */ (function () {
    function NotifyMessageComponent(router, translate, sharedService) {
        var _this = this;
        this.router = router;
        this.translate = translate;
        this.sharedService = sharedService;
        this.responseMessage = {};
        this.sharedService.changeEmittedNotify$.subscribe(function (data) {
            if (data.type == 'popup') {
                _this.responseMessage = data;
                var errorArgs_1 = {};
                if (_this.responseMessage.error.args) {
                    _this.responseMessage.error.args.forEach(function (item, i) {
                        errorArgs_1[i] = item;
                    });
                }
                // if(errorArgs) {
                _this.translate.get(_this.responseMessage.error.errorCode, errorArgs_1).subscribe(function (res) {
                    _this.translateMessage = res;
                });
                // } else {
                //   this.translate.get(this.responseMessage.message).subscribe((res: string) => {
                //     this.translateMessage = res;
                //   });
                // }
            }
            else if (data.success == 0 && data.message) {
                _this.responseMessage = data;
                var errorArgs_2 = {};
                if (_this.responseMessage && _this.responseMessage.error && _this.responseMessage.error.args) {
                    _this.responseMessage.error.args.forEach(function (item, i) {
                        errorArgs_2[i] = item;
                    });
                }
                if (errorArgs_2 && Object.keys(errorArgs_2).length !== 0) {
                    _this.translate.get(_this.responseMessage.error.errorCode, errorArgs_2).subscribe(function (res) {
                        _this.translateMessage = res;
                    });
                }
                else {
                    _this.translate.get(_this.responseMessage.message).subscribe(function (res) {
                        _this.translateMessage = res;
                    });
                }
                setTimeout(function () {
                    _this.responseMessage = {};
                }, enMessage.timeOut);
            }
            else if (data.success == 0 && data.custommessage) {
                _this.responseMessage = data;
                _this.translate.get(_this.responseMessage.custommessage).subscribe(function (res) {
                    _this.translateMessage = res;
                });
                setTimeout(function () {
                    _this.responseMessage = {};
                }, enMessage.timeOut);
            }
            else if (data.success == 1 && data.message) {
                _this.responseMessage = data;
                _this.translate.get(_this.responseMessage.message).subscribe(function (res) {
                    _this.translateMessage = res;
                });
                setTimeout(function () {
                    _this.responseMessage = {};
                }, enMessage.timeOut);
            }
            else if (data.success == 'noInternet') {
                _this.responseMessage = data;
                _this.translate.get(_this.responseMessage.message).subscribe(function (res) {
                    _this.translateMessage = res;
                });
            }
            else if (data.success == 'internetIsBack') {
                _this.responseMessage = data;
                _this.translate.get(_this.responseMessage.message).subscribe(function (res) {
                    _this.translateMessage = res;
                });
                setTimeout(function () {
                    _this.responseMessage = {};
                }, 1);
            }
        });
    }
    NotifyMessageComponent.prototype.ngOnInit = function () {
    };
    NotifyMessageComponent.prototype.reloadPage = function () {
        window.location.reload();
    };
    return NotifyMessageComponent;
}());
export { NotifyMessageComponent };
