
import {throwError as observableThrowError,  Observable ,  BehaviorSubject } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { Router } from '@angular/router';

import { environment } from '@myenv/environment';

@Injectable()
export class VehicleService {
  public apiUrl = environment.apiUrl;
  private httpClient: HttpClient;

  // sideData = [];
  // uploadVehicle = {
  //   name: 'menu.AddNewVehicle',
  //   img: 'plus',
  //   route: '/vehicles/upload'
  // };
  // uploadLogs = {
  //   name: 'menu.UploadLogs',
  //   img: 'history',
  //   route: '/vehicles/upload-logs'
  // };
  // addedVehicle = {
  //   name: 'menu.RegisteredVehicles',
  //   img: 'clipboard',
  //   route: '/vehicles'
  // };

  // getSideMenu() {
  //   this.sideData = [];
  //   const moduleName = 'VEHICLE';
  //   const modules = JSON.parse(localStorage.getItem('accessRoleModules'));
  //   const permissionAuthorities = modules.find(module => module.name === moduleName);
  //   const write = permissionAuthorities.authorities.includes('WRITE');
  //   if (write) {
  //     this.sideData.push(this.addedVehicle);
  //     this.sideData.push(this.uploadVehicle);
  //     this.sideData.push(this.uploadLogs);
  //   } else {
  //     this.sideData.push(this.addedVehicle);
  //   }
  //   return this.sideData;
  // }

  constructor(private http: HttpClient, private router: Router, private handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  getAllVehicles(page, data, key?, value?): Observable<any> {
    let tempUrl = `vehicles?page=${page}`;
    if (key && value) {
      tempUrl = tempUrl + `&key=${key}&value=${value}`;
    }
    if (data.prop) {
      tempUrl = tempUrl + `&sortingKey=${data.prop}&direction=${data.dir}`;
    }
    return this.http.get(this.apiUrl + tempUrl).pipe(
      catchError(this.handleError));
  }

  downloadUploadLog(logId): Observable<any> {
    return this.http.get(this.apiUrl + 'vehicles/upload/logs/' + logId + '/download', {
      responseType: 'arraybuffer',
      observe: 'response'
    }).pipe(
      catchError(this.handleError));
  }

  downloadVehicleXML(searchData, vehicleStatusFilterObj?, vehicleGroupFilterkey?): Observable<any> {
    let tempUrl = this.apiUrl + 'vehicles/download?type=xml';
    if (searchData.vehicleTypeVal) {
      tempUrl += '&key=' + searchData.deviceId + '&value=' + searchData.vehicleTypeVal;
    }
    if (vehicleStatusFilterObj) {
      tempUrl += '&status=' + vehicleStatusFilterObj;
    }
    if (vehicleGroupFilterkey) {
      tempUrl += '&groups=' + vehicleGroupFilterkey;
    }
    return this.http.get(tempUrl, { responseType: 'arraybuffer', observe: 'response' }).pipe(
      catchError(this.handleError));
  }

  getDeviceCount(): Observable<any> {
    return this.http.get(this.apiUrl + 'vehicles/all/count').pipe(
      catchError(this.handleError));
  }

  downloadVehicleCSV(searchData, vehicleStatusFilterObj?, vehicleGroupFilterkey?): Observable<any> {
    let tempUrl = this.apiUrl + 'vehicles/download?type=csv';
    if (searchData.vehicleTypeVal) {
      tempUrl += '&key=' + searchData.deviceId + '&value=' + searchData.vehicleTypeVal;
    }
    if (vehicleStatusFilterObj) {
      tempUrl += '&status=' + vehicleStatusFilterObj;
    }
    if (vehicleGroupFilterkey) {
      tempUrl += '&groups=' + vehicleGroupFilterkey;
    }
    return this.http.get(tempUrl, { responseType: 'arraybuffer', observe: 'response' }).pipe(
      catchError(this.handleError));
  }

  updateVehicle(vehicle): Observable<any> {
    return this.http.put(this.apiUrl + 'vehicles/' + vehicle.deviceId, vehicle).pipe(
      catchError(this.handleError));
  }

  downloadActivityCSV(deviceId): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
    });
    return this.http.get(this.apiUrl + 'activity/download/identifier/' + deviceId,
      { responseType: 'arraybuffer', observe: 'response', headers }).pipe(
      catchError(this.handleError));
  }

  handleError(error: any | any) {
    return observableThrowError(error.error || error.json() || error || 'Server error');
  }

}

