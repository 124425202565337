<div *ngIf="(responseMessage | json) == '{}'; else otherTemplate">
</div>
<ng-template #otherTemplate>
    <!--<div *ngIf="!responseMessage.success" class="alert alert-danger alert-dismissible fade show" role="alert">-->
      <!--<span class="fa fa-times pull-right  mr-2 mt-1" data-dismiss="alert"></span>-->
      <!--&lt;!&ndash;<i class="fa fa-times"></i>&ndash;&gt;-->
      <!--{{translateMessage}}-->
  <!--*ngIf="responseMessage.type!=='popup'"-->
  <!--</div>-->
    <div *ngIf="responseMessage.type == 'popup'" class="inline-error" role="alert">
      <span>{{translateMessage}}</span>
      <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close">-->
        <!--<span aria-hidden="true">&times;</span>-->
      <!--</button>-->
    </div>
    <div *ngIf="!responseMessage.success && responseMessage.success!=='noInternet' && responseMessage.type!=='popup'" class="alert alert-danger alert-dismissible fade show" role="alert">
      <span>{{translateMessage}}</span>
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div *ngIf="responseMessage.success && responseMessage.success!=='noInternet'" class="alert alert-success alert-dismissible fade show" role="alert">
      <span>{{translateMessage}}</span>
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  <div *ngIf="responseMessage.success=='noInternet'" class="alert alert-warning alert-dismissible fade show" role="alert">
      <span>
        {{translateMessage}}
        <a style="cursor: pointer; text-decoration: underline;" (click)="reloadPage()">Reload</a><span>.</span>
      </span>
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div *ngIf="responseMessage.success=='internetIsBack'" class="alert alert-warning alert-dismissible fade show" role="alert">
      <span>
        {{translateMessage}}
        <a style="cursor: pointer; text-decoration: underline;" (click)="reloadPage()">Reload</a><span>.</span>
      </span>
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <!--<div *ngIf="responseMessage.success" class="alert alert-success alert-dismissible fade show" role="alert">-->
      <!--<span class="fa fa-times pull-right  mr-2 mt-1" data-dismiss="alert"></span>-->
      <!--&lt;!&ndash;<i class="fa fa-check"></i> &ndash;&gt;-->
      <!--{{translateMessage}}-->
    <!--</div>-->
</ng-template>

