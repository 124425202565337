import { OnInit } from '@angular/core';
var ReportsHeaderComponent = /** @class */ (function () {
    function ReportsHeaderComponent() {
        this.permissionModules = [];
    }
    ReportsHeaderComponent.prototype.ngOnInit = function () {
        this.permissionModules = JSON.parse(localStorage.getItem('permissionModules'));
    };
    return ReportsHeaderComponent;
}());
export { ReportsHeaderComponent };
