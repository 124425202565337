<div>
  <div class="row py-2 align-items-center">
    <div class="col-md-3 col-4 text-right">Select Customer ID:</div>
    <div class="col-md-2 col-8">
      <ng-select
        [items]="customers"
        bindLabel="customerId"
        bindValue="customerId"
        [multiple]="false"
        [closeOnSelect]="true"
        name="customerId"
        [(ngModel)]="searchModel.customerId"
        placeholder="Select Customer ID"
        #sourceCustomer="ngModel"
        (change)="selectCustomer()" required="">
        <ng-template ng-option-tmp let-item="item" let-index="index">
          <b>{{item.firstName }} {{item.lastName}} ({{item.customerId}})</b>
        </ng-template>
      </ng-select>
      <small class="text-danger"
             style="position: absolute;"
             *ngIf="searchModel.customerId===null">
        Please Select a Customer ID.
      </small>
    </div>
    <div class="col-md-7 col-12 pt-3 text-bold" style="height: 60px;">
      Show vehicles not reporting for&nbsp;
      <form #f="ngForm" action="javascript:void(0);" (submit)="changeTime(f)" class="d-inline">
        <input
          class="hours-input text-center"
          required
          name="hours"
          type="number"
          [(ngModel)]="hours"
          maxlength="3"
          min="1"
          max="999">&nbsp;
      </form>
      hours.
      <div class="text-center">
        <small class="text-danger" *ngIf="error">Please enter a number between 1 to 999.</small>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <!--<div class="page-loading" *ngIf="pageLoading">-->
      <!--<img src="../../../assets/img/darby_loader_small.gif"/>-->
      <!--</div>-->
      <div *ngIf="!devices.length" class="text-center mt-5 py-5">
        <img width="170" src="../../../assets/img/info_nodata_256.png"/>
        <h4 class="mt-3">No data to show</h4>
      </div>
      <div  *ngIf="devices.length">
        <ngx-datatable
          id="tableau" #dataTable
          class="bootstrap alertTable reportsTable wrapcell"
          [rows]="devices"
          [columnMode]="'force'"
          [headerHeight]="50"
          [footerHeight]="50"
          [rowHeight]="'auto'"
          [summaryRow]="false"
          [scrollbarH]="true"
          [reorderable]="true"
          [limit]="pageInfo.limit"
          [offset]="pageInfo.offset"
          [count]="pageInfo.count"
          (page)="pageCallback($event)"
          [loadingIndicator]="loadingIndicator"
        >
          <ngx-datatable-column [resizeable]="false" [sortable]="false" name="#" [width]="50">
            <ng-template let-rowIndex="rowIndex" ngx-datatable-cell-template>
              {{rowIndex+1}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Device ID">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{row.deviceId}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Serial Number" >
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{row.serialNumber}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="IMEI">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{row.imei}}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Firmware Version" >
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{row.firmwareVersion}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Config Version" >
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{row.configVersion}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="SIM CCID" >
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{row.simCcid}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="SIM Mobile Number" >
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{row.mobileNumber}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Customer ID" >
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{row.customerId}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Last Communication " >
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{row.lastCommunication | date: dateFormat}}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Received At" >
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{row.receivedAt | date: dateFormat}}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Created At" >
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{row.createdAt | date: dateFormat}}
            </ng-template>
          </ngx-datatable-column>
          <!-- <ngx-datatable-column>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <button type="button" class="btn btn-peak btn-danger btn-icon-left m-0"
                (click)="getCustomerId(row.customerId);">SHOW
              </button>
            </ng-template>
          </ngx-datatable-column> -->
        </ngx-datatable>
        <div class="total-count pl-3" *ngIf="devicesCount && devicesCount.count">
          Showing
          <span contenteditable="false" (input)="currentPageCount.start = $event.target.innerText">
          {{currentPageCount.start}}
        </span>
          -
          <span contenteditable="false" (input)="currentPageCount.end = $event.target.innerText">
          {{currentPageCount.end}}
        </span>
          of {{(devicesCount.count)}} devices.
        </div>
      </div>
    </div>
  </div>
</div>
