
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { SharedService } from '@shared/shared.service';
import { Title } from '@angular/platform-browser';
import { environment } from '@myenv/environment';
import { configMessage } from '@shared/config-message';
import { HttpHeaders } from '@angular/common/http';
import {GraphicReportsService} from '@components/graphic-reports/graphic-reports.service';

@Component({
  selector: 'app-multi-vehicle-download-report',
  templateUrl: './multi-vehicle-download-report.component.html',
  styleUrls: ['./multi-vehicle-download-report.component.scss']
})
export class MultiVehicleDownloadReportComponent implements OnInit {
  public apiUrl = environment.apiUrl;
  public dateFormat = environment.largeDateFormat;
  settings: any;
  dateAttributes = configMessage.dateAttributes;
  maxDate: Date = new Date();
  data: any;
  groups: any;
  temp = [];
  reportsMenu: any = [];
  vehicles: any = [];
  currentReport: any = {};
  querySelectDevice = null;
  querySelectVehicle = null;
  page = 0;
  isLoading = false;
  noReports = false;
  public report: any = {};
  public columMenu: any = [];
  currentPageCount: any = {
    start: 0,
    end: 0
  };
  startTime: any;
  endTime: any;
  searchModel: any = {
    messageCount: '',
    value: null,
    vehicle: null,
    device: null
  };
  noVehiclesSelected = false;
  public reportId: string = null;
  pageInfo: any = {
    offset: 0,
    count: 0,
    limit: 10,
    pageSize: 0,
    lastPage: 0,
  };
  public reports: any = [];
  public total: number;
  public itemsPerPage: any = 10; // we are showing 10 items per page
  public lastPage = 0;
  disable = false;
  nextPage: any;
  selectItemError = false;
  value: string;
  displayName: string;
  permissionModules = [];
  modules = [];
  permissionAuthorities: any = {};
  moduleName = 'REPORT';
  totalMessageCount: any = {};
  selectedItem: any;
  hideCalendar = false;
  isSideMenuClose = false;

  constructor(
    private reportsService: GraphicReportsService,
    private sharedService: SharedService,
    private title: Title
  ) {
  }

  ngOnInit() {
    this.modules = JSON.parse(localStorage.getItem('accessRoleModules'));
    this.permissionAuthorities = this.modules.find(module => module.name === this.moduleName);
    this.permissionModules = JSON.parse(localStorage.getItem('permissionModules'));
    this.title.setTitle('Reports' + environment.title_text);
    this.setReport();
  }

  getReport(report) {
    this.currentReport = report;
    this.setReport();
  }

  onTimeChange(e) {
    this.startTime = e.startTime;
    this.endTime = e.endTime;
    this.page = 0;
  }

  changeDevice(e) {
    if (localStorage.getItem('reportsDevice') && this.querySelectDevice == null) {
      this.setItem();
    } else {
      localStorage.setItem('reportsDevice', JSON.stringify(e));
      this.selectedItem = e;
      this.searchModel.value = e ? e.deviceId : null;
      this.searchModel.device = e ? e.serialNumber : null;
      this.querySelectDevice = e;
    }
  }

  onSideMenuToggle() {
    this.isSideMenuClose = !this.isSideMenuClose;
  }

  refresh() {
    this.page = 0;
    this.setReport();
  }

  setReport() {
    if (this.currentReport && this.currentReport.reportId) {
      localStorage.setItem('currentReport', JSON.stringify(this.currentReport));
      this.report.reportId = this.currentReport.reportId;
    } else {
      if (JSON.parse(localStorage.getItem('currentReport'))) {
        const localReport = JSON.parse(localStorage.getItem('currentReport'));
        if (localReport.downloadable) {
          this.currentReport = localReport;
          this.report.reportId = localReport.reportId;
        } else {
          const reports = JSON.parse(localStorage.getItem('reports'));
          const global = this;
          reports.forEach(item => {
            if (item.downloadable && !global.currentReport.reportId) {
              global.currentReport = item;
            }
          });
          localStorage.setItem('currentReport', JSON.stringify(this.currentReport));
        }
      }
    }
    this.columMenu = this.currentReport.columns;
    this.title.setTitle(this.currentReport.name + environment.title_text);
  }

  setItem() {
    if (this.currentReport.type != 'VIN_CHANGE_2G2K' && localStorage.getItem('reportsVehicle')) {
      let reportsItem = JSON.parse(localStorage.getItem('reportsVehicle'));
      this.querySelectVehicle = reportsItem;
      this.searchModel.value = reportsItem.deviceId;
      this.searchModel.vehicle = reportsItem.deviceId;
      this.selectedItem = reportsItem;
    } else {
      let reportsItem = JSON.parse(localStorage.getItem('reportsDevice'));
      this.querySelectDevice = reportsItem;
      this.searchModel.value = reportsItem.deviceId;
      this.searchModel.device = reportsItem.serialNumber;
      this.selectedItem = reportsItem;
    }
  }

  // to handle disabled buttons
  disableBtn() {
    if (
      !this.searchModel.value ||
      !this.startTime || !this.endTime
    ) {
      this.disable = true;
    } else {
      this.disable = false;
    }
  }

  // To download report as a CSV File
  downloadReportCSV() {
    this.reportsService.multiDownloadVehicle(
      this.currentReport.reportId, this.searchModel.vehicle, this.startTime, this.endTime
    ).subscribe(res => {
      var win = window.open(
        this.apiUrl + 'reports/recent/message/download?access_key=' +
        res.token + '&messageCount=' + this.searchModel.messageCount, '_blank'
      );
      win.focus();
      this.disable = false;
    }, error => {
      this.sharedService.getErrorMsg(error);
      this.disable = false;
    });
  }

  addVehicle(e) {
    this.searchModel.vehicle = [];
    e.map(vehicle => {
      this.searchModel.vehicle.push(vehicle.deviceId);
    });
    this.noVehiclesSelected = this.searchModel.vehicle.length ? false : true;
  }

}
