import { Component, OnInit } from '@angular/core';
import { SharedService } from '@myproject/shared/shared.service';
import { environment } from '@myenv/environment';
import * as FileSaver from 'file-saver';
import { GraphicReportsService } from '../../graphic-reports.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-trip-summary-report',
  templateUrl: './trip-summary-report.component.html',
  styleUrls: ['./trip-summary-report.component.scss']
})
export class TripSummaryReportComponent implements OnInit {
  public distanceMetrics = environment.distanceMetrics;
  public speedMetrics = environment.speedMetrics;
  public dateFormat = environment.isoDateTimeFormat;
  permissionModules = [];
  pageLoading = true;
  vehicles: any[] = [];
  selectedVehicle: string = null;
  querySelectVehicle = null;
  isLoading = false;
  startTime = 0;
  endTime = 0;
  page = 0;
  trips: any[] = [];
  pageInfo: any = {
    offset: 0,
    count: 0,
    limit: 10,
    pageSize: 0,
    lastPage: 0,
  };
  prevResponse = 0;
  currentPageCount: any = {
    start: 0,
    end: 0
  };
  hideCalendar = false;
  isSideMenuClose = false;

  constructor(
    private sharedService: SharedService,
    private title: Title,
    private reportsService: GraphicReportsService
  ) { }

  ngOnInit() {
    this.title.setTitle('Summary' + environment.title_text);
    this.permissionModules = JSON.parse(localStorage.getItem('permissionModules'));
  }

  onTimeChange(e) {
    this.startTime = e.startTime;
    this.endTime = e.endTime;
    if (this.selectedVehicle) {
      this.page = 0;
      this.getTrips(this.page);
    }
  }

  changeVehicle(e) {
    // if (localStorage.getItem('reportsVehicle') && this.selectedVehicle == null) {
    //   this.setItem();
    // } else {
    //   localStorage.setItem('reportsVehicle', JSON.stringify(e));
    //   this.selectedVehicle = e ? e.deviceId : null;
    // }
    this.selectedVehicle = e ? e.deviceId : null;
    this.querySelectVehicle = e ? e : null;
    if (this.startTime && this.endTime) {
      this.page = 0;
      this.getTrips(this.page);
    }
  }

  onSideMenuToggle() {
    this.isSideMenuClose = !this.isSideMenuClose;
    const temp = JSON.parse(JSON.stringify(this.trips));
    this.trips.length = 0;
    this.trips = temp;
  }

  setItem() {
    const reportsItem = JSON.parse(localStorage.getItem('reportsVehicle'));
    this.querySelectVehicle = reportsItem;
    this.selectedVehicle = reportsItem.deviceId;
  }

  refresh() {
    this.page = 0;
    this.getTrips(this.page);
  }

  getTrips(page) {
    this.isLoading = true;
    this.page = page;
    if (this.selectedVehicle !== null) {
      this.reportsService.getTrips(this.selectedVehicle, this.startTime, this.endTime, page).subscribe({
        next: res => {
          this.prevResponse = res.length;
          if (this.page === 0) {
            this.pageInfo.offset = 0;
            this.trips = res;
          } else {
            this.trips = [...this.trips, ...res];
          }
          this.isLoading = false;
          this.pageLoading = false;
        },
        error: error => {
          this.isLoading = false;
          this.pageLoading = false;
          this.sharedService.getErrorMsg(error);
        }
      });
    } else {
      this.isLoading = false;
      this.pageLoading = false;
    }
  }

  downloadCsv() {
    this.reportsService.downloadTripSummary(this.selectedVehicle, this.startTime, this.endTime).subscribe({
      next: res => {
        const contentDisposition = res.headers.get('Content-Disposition');
        let filename;
        if (contentDisposition == null) {
          const date = new Date();
          filename = 'Trip_Summary_' + date + '.csv';
        } else {
          filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
        }
        let arrbuf = new Uint8Array(res.body)
        //Converting the charcodes to string and replacing the string to Distance Travelled (KMS) 
        let text = (String.fromCharCode(...arrbuf)).replace("Distance Travelled,", "Distance Travelled (Kms),")
        // Converting the string to ArrayBuffer
        let uint8array = new TextEncoder().encode(text);
        const blob = new Blob([uint8array], {
          type: 'text/csv;charset=utf-8'
        });
        // const blob = new Blob([res.body], {
        //   type: 'text/csv;charset=utf-8'
        // });
        FileSaver.saveAs(blob, filename);
      },
      error: error => {
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  // To get the vehicles for each page in the table
  pageCallback(value) {
    this.pageInfo.offset = value.offset;
    this.currentPageCount.start = (value.offset * value.pageSize) + 1;
    const lastPage = Math.floor(this.trips.length / 10);
    this.pageInfo.lastPage = lastPage;
    if (lastPage === value.offset) {
      this.currentPageCount.end = value.count;
    } else {
      this.currentPageCount.end = (value.offset * value.pageSize) + value.limit;
    }
    if (this.pageInfo.lastPage === (this.pageInfo.offset + 1)) {
      if (this.prevResponse) {
        this.page = ++this.page;
        this.getTrips(this.page);
      }
    }
  }
}
