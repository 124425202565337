import { TripsService } from './components/trips/trips.service';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler, APP_INITIALIZER, ApplicationRef } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from '@myproject/app-routing.module';
import { APP_BASE_HREF } from '@angular/common';
import { TooltipModule } from 'ng2-tooltip-directive';
import { AppComponent } from '@myproject/app.component';
import { LoginComponent } from '@layouts/login/login.component';
import { AuthenticationService } from '@layouts/login/authentication.service';
import { AuthGuard } from '@myproject/auth.guard';
import { SharedService } from '@shared/shared.service';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LiveComponent } from '@components/live/live.component';
import { LayoutComponent } from '@components/layout/layout.component';
import { HeaderComponent } from '@components/header/header.component';
import { FooterComponent } from '@layouts/footer/footer.component';
import { ForgotPasswordComponent } from '@layouts/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from '@layouts/reset-password/reset-password.component';
import { RouterModule } from '@angular/router';
import { CustomPreloadingStrategy } from '@myproject/lazy-loadding.preloading';
import { NgbDatepickerModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { LiveService } from '@components/live/live.service';
import { DataService } from '@components/data/data.service';
import { SharedModule } from '@shared/shared.module';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgSelectModule } from '@ng-select/ng-select';
import { CustomHttpInterceptor } from '@shared/custom-http-Interceptor';
import { FilterPipe } from '@shared/filter.pipe';
import { PageNotFoundComponent } from '@shared/errors/page-not-found.component';
import { PlaceService } from '@components/places/places.service';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { PermissionGuard } from '@myproject/perimission.guard';
import { ResponseInterceptor } from '@myproject/reponse-interceptor';
import { ForbiddenComponent } from '@shared/errors/forbidden.component';
import { InternalServerErrorComponent } from '@shared/errors/internal-server-error.component';
import { NoInternetComponent } from '@shared/errors/no-internet.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import * as moment from 'moment';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UnauthorizedComponent } from '@shared/errors/unauthorized.component';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
import { CookieService, CookieOptions } from 'angular2-cookie/core';
import { LocationHistoryComponent } from './components/live/location-history/location-history.component';
import { DestinationsComponent } from './components/destinations/destinations.component';
import { DestinationsService } from '@components/destinations/destinations.service';
import { VehicleService } from '@components/vehicle/vehicle.service';
import { DeviceService } from '@components/device/device.service';
import { AdminService } from '@components/admin/admin.service';
// import {BenchTestService} from "@components/bench-test/bench-test.service";
import { VehicleUploadService } from '@components/vehicle/vehicle-upload/vehicle-upload.service';
import { UsersService } from '@components/admin/users/users.service';
import { ToolsComponent } from './components/tools/tools.component';
import { ConfigurationsComponent } from './components/configurations/configurations.component';
import { WorkerService } from './components/live/worker.service';
import { DashboardService } from '@components/dashboard/dashboard.service';
import { PodsStatusComponent } from './components/pods-status/pods-status.component';
import { DataComponent } from './components/data/data.component';
import { GroupsService } from '@myproject/components/device/groups/groups.service';
import { ScoreComponent } from '@components/graphic-reports/score/score.component';
import { GlobalErrorHandler } from '@myproject/globalerrorhandler.service';
import { ContactUsComponent } from './layouts/contact-us/contact-us.component';
import { FaqComponent } from './layouts/faq/faq.component';
import { PopoverModule } from 'ngx-smart-popover';
import { AlDashboardComponent } from './components/al-dashboard/al-dashboard.component';
import { LiveTrackingComponent } from './components/live-tracking/live-tracking.component';
import { TripsComponent } from './components/live/trips/trips.component';
import { TripsNewService } from './components/trips-new/trips-new.service';
import { MarketplaceComponent } from '@components/admin/marketplace/marketplace.component';
import { MarketplaceService } from '@components/admin/marketplace/marketplace.service';
import { SupportModule } from './components/support/support.module';
import { GraphicReportsModule } from './components/graphic-reports/graphic-reports.module';
// import { DataSimulatorComponent } from '@components/data-simulator/data-simulator.component';
// import { QuarantineUsersComponent } from './components/quarantine-users/quarantine-users.component';
// import { QuarantineUsersDetailsComponent } from './components/quarantine-users/quarantine-users-details/quarantine-users-details.component';
import { TripsDirective } from '@components/live/trips/trips.directive';
// import { ObdPidViewDirective } from '@shared/obd-pid-view/obd-pid-view.directive';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LiveComponent,
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    PageNotFoundComponent,
    ForbiddenComponent,
    InternalServerErrorComponent,
    NoInternetComponent,
    UnauthorizedComponent,
    // ProfileComponent,
    LocationHistoryComponent,
    DestinationsComponent,
    ToolsComponent,
    ConfigurationsComponent,
    PodsStatusComponent,
    DataComponent,
    ScoreComponent,
    ContactUsComponent,
    FaqComponent,
    AlDashboardComponent,
    LiveTrackingComponent,
    TripsComponent,
    MarketplaceComponent,
    TripsDirective,
    // ObdPidViewDirective
    // DataSimulatorComponent
  ],
  imports: [
    NgbDatepickerModule,
    NgbTimepickerModule,
    PopoverModule,
    TooltipModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    RouterModule,
    NgxDatatableModule,
    TrimValueAccessorModule,
    AngularMultiSelectModule,
    SharedModule,
    ColorPickerModule,
    NgSelectModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ReactiveFormsModule,
    SupportModule,
    GraphicReportsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    AuthGuard,
    PermissionGuard,
    AuthenticationService,
    DataService,
    SharedService,
    LiveService,
    FilterPipe,
    CookieService,
    VehicleService,
    DeviceService,
    AdminService,
    VehicleUploadService,
    UsersService,
    WorkerService,
    PlaceService,
    DestinationsService,
    TripsService,
    GroupsService,
    DashboardService,
    CustomPreloadingStrategy,
    TripsNewService,
    MarketplaceService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true
    },
    { provide: CookieOptions, useValue: {} },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    // HttpCancelService,
    { provide: APP_BASE_HREF, useValue: '.' }

  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  // constructor(applicationRef: ApplicationRef) {
  //   const originalTick = applicationRef.tick;
  //   applicationRef.tick = function() {
  //     const windowPerfomance = window.performance;
  //     const  before = windowPerfomance.now();
  //     const retValue = originalTick.apply(this, arguments);
  //     const after = windowPerfomance.now();
  //     const runTime = after - before;
  //     window.console.log('CHANGE DETECTION TIME' , runTime);
  //     return retValue;
  //   };
  // }
}
