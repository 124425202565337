import { ElementRef, NgZone, OnDestroy } from '@angular/core';
import { SharedService } from '@myproject/shared/shared.service';
import { TripsComponent } from './trips.component';
import { configMessage } from '@shared/config-message';
var TripsDirective = /** @class */ (function () {
    // @ViewChild(TripsComponent, { static: false }) tripsComponent: TripsComponent;
    function TripsDirective(elRef, sharedService, ngZone, tripsComponent) {
        this.elRef = elRef;
        this.sharedService = sharedService;
        this.ngZone = ngZone;
        this.tripsComponent = tripsComponent;
    }
    TripsDirective.prototype.ngOnInit = function () {
        this.registerDomChangedEvent(this.elRef.nativeElement);
    };
    TripsDirective.prototype.registerDomChangedEvent = function (el) {
        var global = this;
        this.observer = new MutationObserver(function (list) {
            var tripsComponent = global.tripsComponent;
            var firstActiveSection = $('.left-sec')[0].style.display === 'block' ? $($('.left-sec')[0]) : $($('.left-sec')[1]);
            var activeViewHeight = firstActiveSection.height();
            var leftSection = $('#leftsctn').height();
            if (!tripsComponent.accordion.isPidManualCollapse && (activeViewHeight > 100 || tripsComponent.accordion.msg_view || tripsComponent.accordion.trip_view)) {
                var checkCanViewDataView = (leftSection - activeViewHeight);
                tripsComponent.accordion.isHavingEnoughSpace = checkCanViewDataView >= configMessage.minHeightReqDataView;
                tripsComponent.accordion.pid_view = tripsComponent.accordion.isHavingEnoughSpace;
            }
            var params = (tripsComponent.accordion.isHavingEnoughSpace ? (tripsComponent.tripViewToggle ? (tripsComponent.accordion.trip_view ? ['trip_expand', tripsComponent.accordion.pid_view ? 'data_expand' : 'data_collapse'] : ['trip_collapse', tripsComponent.accordion.pid_view ? 'data_expand' : 'data_collapse']) : (tripsComponent.accordion.msg_view ? ['msg_expand', tripsComponent.accordion.pid_view ? 'data_expand' : 'data_collapse'] : ['msg_collapse', tripsComponent.accordion.pid_view ? 'data_expand' : 'data_collapse'])) : (tripsComponent.accordion.trip_view ? 1 : tripsComponent.accordion.msg_view ? 2 : 3));
            global.sharedService.setOBDHeight(window.innerWidth, window.innerHeight, tripsComponent.accordion.isHavingEnoughSpace, params, tripsComponent.graphContainerParams ? tripsComponent.graphContainerParams.tabPos : 0);
        });
        var attributes = true;
        var childList = true;
        var subtree = true;
        this.observer.observe(el, { attributes: attributes, childList: childList, subtree: subtree });
    };
    TripsDirective.prototype.ngOnDestroy = function () {
        this.observer.disconnect();
    };
    return TripsDirective;
}());
export { TripsDirective };
