<app-notify-message></app-notify-message>
<app-reports-header></app-reports-header>
<div class="row no-gutters reports-page">
  <div class="col-md-2 col-sm-0 p-0">
    <app-reports-side-menu></app-reports-side-menu>
  </div>
  <div class="reports-body col-md-10 col-sm-12 p-0">
    <div class="row no-gutters action-row align-items-center px-2">
      <div class="col-md-5">
        <h4 class="report-heading page-heading"><i class="fa fa-bar-chart"></i> C-Triggers</h4>
      </div>
      <div class="col-md-7 text-right">
        <div class="d-inline-block calendar ml-2" (clickOutside)="hideCalendar=!hideCalendar;">
          <app-date-time-calendar
            [clickOutside]="hideCalendar"
            [selectPreset]="'30days'"
            [timeSelection]="false"
            (timeChangeEvent)="onTimeChange($event)"
            >
          </app-date-time-calendar>
        </div>
        <button
          class="btn btn-icon ml-2 refresh"
          (click)="refresh()"
          tooltip="Refresh"
          placement="top"
          [disabled]="isLoading">
          <i class="fa fa-refresh" aria-hidden="true"></i>
          <span class="mob-access-text d-none">&nbsp;Refresh</span>
        </button>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-12 pt-3">
        <div class="total-count-container animated fadeIn" [class.selected]="selectedStatus==='all'">
          <div class="report-item-container" (click)="onStatusChange('all')">
            <span class="count">{{deviceCounts.all || 0}}</span>
            <h6 class="text">All Devices</h6>
          </div>
        </div>
        <div class="total-count-container animated fadeIn" [class.selected]="selectedStatus==='inProgress'">
          <div class="report-item-container" (click)="onStatusChange('inProgress')">
            <span class="count">{{deviceCounts.inProgress || 0}}</span>
            <h6 class="text">In Progress</h6>
          </div>
        </div>
        <div class="total-count-container animated fadeIn" [class.selected]="selectedStatus==='validationFailed'">
          <div class="report-item-container" (click)="onStatusChange('validationFailed')">
            <span class="count">{{deviceCounts.validationFailed || 0}}</span>
            <h6 class="text">Validation Failed</h6>
          </div>
        </div>
        <div class="total-count-container animated fadeIn" [class.selected]="selectedStatus==='success'">
          <div class="report-item-container" (click)="onStatusChange('success')">
            <span class="count">{{deviceCounts.success || 0}}</span>
            <h6 class="text">Success</h6>
          </div>
        </div>
        <div class="total-count-container animated fadeIn" [class.selected]="selectedStatus==='failed'">
          <div class="report-item-container" (click)="onStatusChange('failed')">
            <span class="count">{{deviceCounts.failed || 0}}</span>
            <h6 class="text">Failed</h6>
          </div>
        </div>
      </div>
      <div class="col-4 px-2 pt-3">
        <div class="report-item-container">
          <div>
            <h6>Error Categories</h6>
          </div>
          <p *ngIf="!totalErrorCount" class="noChartData">No data to show</p>
          <canvas id="pieGraph" height="180" role="img"></canvas>
        </div>
      </div>
      <div class="col-4 px-2 pt-3">
        <div class="report-item-container">
          <div>
            <h6>Average Time Taken in Days</h6>
          </div>
          <canvas id="lineGraph" height="180" role="img"></canvas>
        </div>
      </div>
      <div class="col-4 px-2 pt-3">
        <div class="report-item-container">
          <div class="trigger-count-header">
            <h6># of C-Triggers</h6>
            <div class="dropdown pull-right">
              <a class="dropdown-toggle active-view" role="button"
                id="dropdownMenuLink" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                {{selectedCountView}}
              </a>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <h6 class="dropdown-header mb-0">Select Chart View</h6>
                <a class="dropdown-item" (click)="selectViewType('Daily')">Daily</a>
                <a class="dropdown-item" (click)="selectViewType('Monthly')">Monthly</a>
              </div>
            </div>
          </div>
          <canvas id="barGraph" height="180" role="img"></canvas>
        </div>
      </div>
      <div class="col-12 px-2 py-3">
        <div class="report-item-container">
          <div class="row no-gutters">
            <div class="col-8">
              <h6>{{displaySelectedStatus}}</h6>
            </div>
            <div class="col-4 table-button text-right">
              <button
                class="btn btn-icon ml-2 download"
                (click)="downloadCSV()"
                tooltip="Download CSV"
                placement="top"
                [disabled]="isLoading">
                <i class="fa fa-cloud-download" aria-hidden="true"></i>
                <span class="mob-access-text d-none">&nbsp;Download</span>
              </button>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-12">
              <div class="page-loading" *ngIf="isLoading">
                <img src="../../../assets/img/darby_loader_small.gif"/>
              </div>
              <div *ngIf="!devices.length && !isLoading" class="text-center mb-3 mt-2">
                <img width="170" src="../../../assets/img/info_nodata_256.png"/>
                <h4 class="mt-3">No data to show</h4>
              </div>
              <ngx-datatable *ngIf="devices.length"
                id="tableau" #dataTable
                class="bootstrap alertTable reportsTable wrapcell"
                [rows]="devices"
                [columnMode]="'force'"
                [headerHeight]="50"
                [footerHeight]="50"
                [rowHeight]="'auto'"
                [summaryRow]="false"
                [scrollbarH]="true"
                [reorderable]="true"
                [limit]="pageInfo.limit"
                [offset]="pageInfo.offset"
                [count]="pageInfo.count"
                (page)="pageCallback($event)"
                >
                <ngx-datatable-column [resizeable]="false" [sortable]="false" name="#" [width]="50">
                  <ng-template let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{rowIndex+1}}
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="PSN" prop="productSerialNumber">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.productSerialNumber}}
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="VIN" prop="vin">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.vin}}
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [resizeable]="false" [sortable]="false" name="Service Request ID">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.serviceRequestId}}
                  </ng-template>
                </ngx-datatable-column>
                <!--<ngx-datatable-column name="Architecture">-->
                  <!--<ng-template let-row="row" ngx-datatable-cell-template>-->
                    <!--{{row.architectureType}}-->
                  <!--</ng-template>-->
                <!--</ngx-datatable-column>-->
                <!-- <ngx-datatable-column name="Request Type" prop="requestFlag">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.requestFlag}}
                  </ng-template>
                </ngx-datatable-column> -->
                <!--<ngx-datatable-column name="Battery Voltage" >-->
                  <!--<ng-template let-row="row" ngx-datatable-cell-template>-->
                    <!--{{row.batteryVoltage}}-->
                  <!--</ng-template>-->
                <!--</ngx-datatable-column>-->
                <ngx-datatable-column name="Received Date" prop="requestReceivedDate">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.requestReceivedDate | date: dateFormat}}
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Completed Date" prop="requestCompletedDate">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.requestCompletedDate | date: dateFormat}}
                  </ng-template>
                </ngx-datatable-column>
                <!--<ngx-datatable-column name="S Trigger timetaken" >-->
                  <!--<ng-template let-row="row" ngx-datatable-cell-template>-->
                    <!--{{row.sTriggerTimeTaken}}-->
                  <!--</ng-template>-->
                <!--</ngx-datatable-column>-->
                <ngx-datatable-column name="Status" [sortable]="false">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <span *ngIf="row.deviceStatus==4" class="badge badge-primary">In Progress</span>
                    <span *ngIf="row.deviceStatus==5" class="badge badge-danger">Validation Failed</span>
                    <span *ngIf="row.deviceStatus==6" class="badge badge-success">Success</span>
                    <span *ngIf="row.deviceStatus==7" class="badge badge-danger">Failed</span>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Remark" prop="remark">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.remark}}
                  </ng-template>
                </ngx-datatable-column>
                <!--<ngx-datatable-column name="Config OTA Status" >-->
                  <!--<ng-template let-row="row" ngx-datatable-cell-template>-->
                    <!--{{row.configOtaStatus}}-->
                  <!--</ng-template>-->
                <!--</ngx-datatable-column>-->
                <!--<ngx-datatable-column name="Config Version" >-->
                  <!--<ng-template let-row="row" ngx-datatable-cell-template>-->
                    <!--{{row.cfgVersion}}-->
                  <!--</ng-template>-->
                <!--</ngx-datatable-column>-->
                <!--<ngx-datatable-column name="Firmware Version" >-->
                  <!--<ng-template let-row="row" ngx-datatable-cell-template>-->
                    <!--{{row.fwVersion}}-->
                  <!--</ng-template>-->
                <!--</ngx-datatable-column>-->
              </ngx-datatable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
