import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';

import {environment} from '@myenv/environment';

@Injectable()
export class MarketplaceService {

  public apiUrl = environment.apiUrl;

  constructor(private http: HttpClient, private router: Router) {
  }
}
