<app-notify-message></app-notify-message>
<app-reports-header></app-reports-header>
<div class="row no-gutters reports-page">
  <div class="col-md-2 col-sm-0 p-0">
    <app-reports-side-menu></app-reports-side-menu>
  </div>
  <div class="reports-body col-md-10 col-sm-12 p-0">
    <div class="row no-gutters action-row align-items-center px-2">
      <div class="col-md-4">
        <h4 class="report-heading page-heading page-heading animated fadeIn"><i class="fa fa-bar-chart"></i> Processing Time</h4>
      </div>
      <div class="col-md-8">
        <div class="row align-items-center">
          <div class="col-md-5 text-right pr-0">
            <span class="d-inline-block animated fadeIn">Select Device</span>
          </div>
          <div class="col-md-5">
            <app-search-select-vehicle
              (vehicleChangeEvent)="changeVehicle($event)"
              [autoSelect]="false"
              >
            </app-search-select-vehicle>
          </div>
          <div class="col-md-2 text-right">
            <button
              class="btn btn-icon ml-2"
              (click)="refresh()"
              tooltip="Refresh"
              placement="top"
              [disabled]="isLoading">
              <i class="fa fa-refresh" aria-hidden="true"></i>
            </button>
          </div>
          <!-- <div class="col-md-4 d-flex justify-content-end">
            <div class="calendar d-inline-block ml-2">
              <div class="d-inline-block" (clickOutside)="hideCalendar=!hideCalendar;">
                <app-date-time-calendar
                  [clickOutside]="hideCalendar"
                  [selectPreset]="'7days'"
                  [maxRangeDays]="7"
                  [autoselectEndDate]="true"
                  [hideLinks]="['30days', 'month', 'todaysofar', 'monthtodate']"
                  (timeChangeEvent)="onTimeChange($event)"
                  >
                </app-date-time-calendar>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-md-12 p-3">
        <div class="report-item-container graph-container animated fadeIn">
          <h6>Processing Time In Milliseconds</h6>
          <canvas id="lineChart" height="140"></canvas>
        </div>
      </div>
    </div>
  </div>
</div>
