import {throwError as observableThrowError, Observable, BehaviorSubject} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Response} from '@angular/http';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpBackend} from '@angular/common/http';
import {Router} from '@angular/router';

import 'rxjs/Observable';

import {environment} from '@myenv/environment';

@Injectable()
export class OtaService {
  sideData = [
    {
      name: 'menu.CAMPAIGN',
      img: 'flag-checkered',
      route: '/ota'
    }, {
      name: 'menu.FILES',
      img: 'cube',
      route: '/ota/files'
    }
  ];


  d = new Date();
  offsetInMinutes = this.d.getTimezoneOffset() * -1;
  timeZone = `?dateFormat=${environment.isoDateTimeFormat}&offsetInMinutes=${this.offsetInMinutes}`;

  public apiUrl = environment.apiUrl;
  public apiV2Url = environment.apiV2Url;
  private httpClient: HttpClient;
  public customerId: Number;

  constructor(private http: HttpClient, private router: Router, private handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  private messageSource = new BehaviorSubject(this.sideData);
  currentMessage = this.messageSource.asObservable();

  getAllOta(page): Observable<any> {
    return this.http.get(this.apiUrl + 'campaigns/files?page=' + page).pipe(
      catchError(this.handleError));
  }

  getAllOtaFiles(data): Observable<any> {
    return this.http.get(this.apiUrl + 'campaigns/ota/files/fileType/' + data).pipe(
      catchError(this.handleError));
  }

  getEcuCampaignFiles(data): Observable<any> {
    return this.http.get(this.apiUrl + 'campaigns/files/fileType/' + data).pipe(
      catchError(this.handleError));
  }

  loadFiles(campaignId): Observable<any> {
    return this.http.get(this.apiUrl + `campaigns/${campaignId}/files`).pipe(
      catchError(this.handleError));
  }

  getCommands(campaignId): Observable<any> {
    return this.http.get(this.apiUrl + `campaigns/${campaignId}/commands`).pipe(
      catchError(this.handleError));
  }

  getFileDetails(fileId): Observable<any> {
    return this.http.get(this.apiUrl + 'ota/files/' + fileId).pipe(
      catchError(this.handleError));
  }

  getDeviceHistory(data): Observable<any> {
    return this.http.get(this.apiUrl + `campaigns/${data.campaignId}/device/${data.serialNumber}/history`).pipe(
      catchError(this.handleError));
  }

  getV2DeviceHistory(data, productType): Observable<any> {
    this.customerId = JSON.parse(localStorage.getItem('currentUser')).customerId
    return this.http.get(this.apiV2Url + `${productType}/${this.customerId}/v2/campaigns/${data.campaignId}/device/${data.serialNumber}/history`).pipe(
      catchError(this.handleError));
  }

  uploadXL(data, fileName, fileType, fileVersion, ecuGroup, ecuName): Observable<any> {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      // 'Content-Type': 'multipart/form-data'
    });
    let tempUrl = this.apiUrl + `campaigns/files/fileType/${fileType}/upload?fileName=${fileName}`;
    if (fileVersion) tempUrl += `&fileVersion=${fileVersion}`;
    if (ecuGroup && ecuName) {
      tempUrl += `&ecuGroup=${ecuGroup}&ecuName=${ecuName}`;
    }
    return this.httpClient.post(tempUrl, data, {headers}).pipe(
      catchError(this.handleError));
  }

  uploadV2XL(data, fileName, fileType, fileVersion, ecuGroup, ecuName, productType): Observable<any> {
    this.customerId = JSON.parse(localStorage.getItem('currentUser')).customerId
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      // 'Content-Type': 'multipart/form-data'
    });
    let tempUrl = this.apiV2Url + `${productType}/${this.customerId}/v2/campaigns/files/fileType/${fileType}/upload?fileName=${fileName}`;
    if (fileVersion) tempUrl += `&fileVersion=${fileVersion}`;
    if (ecuGroup && ecuName) {
      tempUrl += `&ecuGroup=${ecuGroup}&ecuName=${ecuName}`;
    }
    return this.httpClient.post(tempUrl, data, {headers}).pipe(
      catchError(this.handleError));
  }

  deleteOta(fileId): Observable<any> {
    return this.http.delete(this.apiUrl + 'campaigns/ota/files/' + fileId).pipe(
      catchError(this.handleError));
  }

  deleteCampaign(campaignId): Observable<any> {
    return this.http.delete(this.apiUrl + 'campaigns/' + campaignId).pipe(
      catchError(this.handleError));
  }

  deleteDevice(campaignId, serialNumber): Observable<any> {
    return this.http.delete(this.apiUrl + 'campaigns/' + campaignId + '/device/' + serialNumber + '/cancel').pipe(
      catchError(this.handleError));
  }

  associateDevice(listnerType, otaType, fileId, data): Observable<any> {
    return this.http.put(this.apiUrl + 'ota/files/' + fileId, data).pipe(
      catchError(this.handleError));
  }

  editUsersVisibility(fileId, userIds): Observable<any> {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      // 'Content-Type': 'multipart/form-data'
    });
    let tempUrl = this.apiUrl + 'ota/files/' + fileId + '/visibility';
    if (userIds.length) {
      tempUrl += '?userIds=' + userIds;
    }
    return this.http.put(tempUrl, headers).pipe(
      // return this.http.put(this.apiUrl +'ota/files/'+fileId+'/visibility?userIds='+ userIds, headers)
      catchError(this.handleError));
  }

  uploadConfigXL(data, folderName): Observable<any> {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      // 'Content-Type': 'multipart/form-data'
    });
    return this.httpClient.post(this.apiUrl + 'configurations?folderName=' + folderName, data, {headers}).pipe(
      catchError(this.handleError));
  }

  deleteConfig(folderName): Observable<any> {
    return this.http.delete(this.apiUrl + 'configurations/folder/' + folderName).pipe(
      catchError(this.handleError));
  }

  getOta(): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
    });

    return this.http.get(this.apiUrl + 'ota', {headers}).pipe(
      catchError(this.handleError));
  }

  getAllCampaign(page, groupId? : String): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
    });

    let URL = this.apiUrl + 'campaigns?page=' + page;
    if (groupId) {
      URL = URL + '&groupId=' + groupId;
    }
    return this.http.get(URL, {headers}).pipe(
      catchError(this.handleError));
  }

  onSearchOta(value): Observable<any> {
    return this.http.get(`${this.apiUrl}ota?serialNumber=${value}`).pipe(
      catchError(this.handleError));
  }

  addCampaign(data): Observable<any> {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    return this.httpClient.post(this.apiUrl + 'campaigns', data, {headers}).pipe(
      catchError(this.handleError));
  }

  addMoreDevices(campaignId, typeId, data, overRide?): Observable<any> {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    let tempURL = this.apiUrl + `campaigns/${campaignId}/devices`;
    if (overRide) {
      tempURL += '?action=1';
    }
    return this.http.put(tempURL, data, {headers}).pipe(
      catchError(this.handleError));
  }

  uploadMoreDevices(campaignId, typeId, data, action): Observable<any> {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    return this.httpClient.put(
      this.apiUrl + `campaigns/devices/upload/campaignId/${campaignId}?action=${action}`, data, {headers}
    ).pipe(catchError(this.handleError));
  }

  getDeviceswithStatus(campaignId, status, page, serialNumber?, errorCode?): Observable<any> {
    if (status === 'progress' && errorCode !== 'all') {
      status = errorCode;
      errorCode = null;
    }
    let tempURL = this.apiUrl + `campaigns/${campaignId}/device/status/${status}?page=${page}`;
    if (serialNumber) tempURL += '&serialNumber=' + serialNumber;
    if (errorCode && !['all', 'PROGRESS', 'DOWNLOAD_SUCCESS'].includes(errorCode)) tempURL += '&errorCode=' + errorCode;
    return this.http.get(tempURL).pipe(catchError(this.handleError));
  }

  getallDeviceswithStatus(campaignId, page, serialNumber?): Observable<any> {
    let tempURL = this.apiUrl + 'campaigns/' + campaignId + '/devices?page=' + page;
    if (serialNumber) {
      tempURL += '&serialNumbers=' + serialNumber;
    }
    return this.http.get(tempURL).pipe(
      catchError(this.handleError));
  }

  getCampaignCount(groupId? : String): Observable<any> {
    let URL = this.apiUrl + 'campaigns/count';
    if (groupId) {
      URL = URL + '?groupId=' + groupId;
    }
    return this.http.get(URL).pipe(
      catchError(this.handleError));
  }

  getFilesCount(): Observable<any> {
    return this.http.get(this.apiUrl + 'campaigns/ota/files/count').pipe(
      catchError(this.handleError));
  }

  getCounts(campaignId): Observable<any> {
    return this.http.get(this.apiUrl + 'campaigns/' + campaignId).pipe(
      catchError(this.handleError));
  }

  uploadCampaign(data): Observable<any> {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    return this.httpClient.post(this.apiUrl + 'campaigns/upload', data, {headers}).pipe(
      catchError(this.handleError));
  }

  getAllDevices(page): Observable<any> {
    return this.http.get(this.apiUrl + 'devices?limit=3000').pipe(
      catchError(this.handleError));
  }

  onSearchCampaignDevice(campaignId, value, status): Observable<any> {
    let tempURL = `${this.apiUrl}campaigns/${campaignId}/device?serialNumber=${value}`;
    if (status) {
      tempURL += '&status=' + status;
    }
    return this.http.get(tempURL).pipe(
      catchError(this.handleError));
  }

  onSearchFile(key, value): Observable<any> {
    return this.http.get(`${this.apiUrl}campaigns/files/search/${key}?value=${value}`).pipe(
      catchError(this.handleError));
  }

  handleError(error: any | any) {
    return observableThrowError(error.error || error || 'Server error');
  }

  downloadFileCSV(searchData?): Observable<any> {
    let tempUrl = this.apiUrl + 'ota/files/download' + this.timeZone;
    if (searchData.vehicleTypeVal) {
      tempUrl += '&key=' + searchData.campaign + '&value=' + searchData.vehicleTypeVal;
    }
    return this.http.get(tempUrl, {responseType: 'arraybuffer', observe: 'response'}).pipe(
      catchError(this.handleError));
  }

  downloadCampaignCSV(searchData, label?): Observable<any> {
    let tempUrl = this.apiUrl + 'campaigns/download' + this.timeZone;
    // if (searchData.vehicleTypeVal || deviceStatusKey) {
    if (label) {
      tempUrl += '&label=' + label;
    }
    if (searchData.vehicleTypeVal) {
      tempUrl += '&key=' + searchData.campaign + '&value=' + searchData.vehicleTypeVal;
    }
    // if (deviceStatusKey && searchData.vehicleTypeVal) {
    //   tempUrl += '?key=' + searchData.campaign + '&value=' + searchData.vehicleTypeVal + '&status=' + deviceStatusKey;
    // }
    // }
    return this.http.get(tempUrl, {responseType: 'arraybuffer', observe: 'response'})
      .pipe(catchError(this.handleError));
  }

  downloadSelectedDeviceCSV(serialNumbers, label?): Observable<any> {
    let tempUrl = this.apiUrl + 'campaigns/device/latest-status/download' + this.timeZone;
    // if (searchData.vehicleTypeVal || deviceStatusKey) {
    //   if (deviceStatusKey) {
    //     tempUrl += '&status=' + deviceStatusKey;
    //   }
    //   if (searchData.vehicleTypeVal) {
    //     tempUrl += '&key=' + searchData.campaign + '&value=' + searchData.vehicleTypeVal;
    //   }
    if (serialNumbers) tempUrl += '&serialNumbers=' + serialNumbers;
    if (label) tempUrl += `&label=${label}`;
    // }
    return this.http.get(tempUrl, {responseType: 'arraybuffer', observe: 'response'})
      .pipe(catchError(this.handleError));
  }

  downloadOtaFilesCSV(searchData, deviceStatusKey?): Observable<any> {
    let tempUrl = this.apiUrl + 'ota/files/download' + this.timeZone;
    // if (searchData.vehicleTypeVal || deviceStatusKey) {
    if (deviceStatusKey) {
      tempUrl += '&status=' + deviceStatusKey;
    }
    if (searchData.vehicleTypeVal) {
      tempUrl += '&key=' + searchData.campaign + '&value=' + searchData.vehicleTypeVal;
    }
    // if (deviceStatusKey && searchData.vehicleTypeVal) {
    //   tempUrl += '?key=' + searchData.campaign + '&value=' + searchData.vehicleTypeVal + '&status=' + deviceStatusKey;
    // }
    // }
    return this.http.get(tempUrl, {responseType: 'arraybuffer', observe: 'response'})
      .pipe(catchError(this.handleError));
  }

  downloadCampaignDeviceCSV(campaignId, status?, searchValue?, errorCode?): Observable<any> {
    if (status === 'PROGRESS' && errorCode !== 'all') {
      status = errorCode;
      errorCode = null;
    }
    let tempUrl = `${this.apiUrl}campaigns/${campaignId}/device/download` + this.timeZone;
    if (status) tempUrl += '&status=' + status;
    if (searchValue) tempUrl += '&serialNumber=' + searchValue;
    if (errorCode && !['all', 'PROGRESS', 'DOWNLOAD_SUCCESS'].includes(errorCode)) tempUrl += '&errorCode=' + errorCode;
    return this.http.get(tempUrl, {responseType: 'arraybuffer', observe: 'response'}).pipe(
      catchError(this.handleError));
  }

  onSearchCampaign(key, value, label?): Observable<any> {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    let tempUrl = `${this.apiUrl}campaigns/search`;
    if (key && value) {
      const char = tempUrl.includes('?') ? '&' : '?';
      tempUrl += `${char}key=${key}&value=${value}`;
    }
    if (label) {
      const char = tempUrl.includes('?') ? '&' : '?';
      tempUrl += `${char}key=label&value=${label}`;
    }
    return this.httpClient.get(tempUrl, {headers}).pipe(catchError(this.handleError));
  }

  retryDeviceCampaign(campaignId, serialNumber) {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    return this.httpClient.put(this.apiUrl + `campaigns/campaignId/${campaignId}/serialNumber/${serialNumber}/retry`, '', {headers})
      .pipe(catchError(this.handleError));
  }

  getRetryDeviceCount(campaignId): Observable<any> {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    return this.httpClient.get(this.apiUrl + `campaigns/${campaignId}/devices/status/count/retry/all`, {headers})
    .pipe(catchError(this.handleError));
  }

  retryAllDeviceCampaign(campaignId, status, timeDiff) {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    let tempURL = `campaigns/${campaignId}/devices/retry/all?override=true`;
    if (['PROGRESS', 'CANCELLED', 'FAILED', 'DOWNLOAD_SUCCESS', 'SUCCESS', 'INSTALL_SUCCESS', 'INSTALL_FAILED', 'SCHEDULED'].includes(status)) tempURL += `&status=${status}`;
    else tempURL += `&status=FAILED&errorCode=${status}`;
    if (timeDiff) tempURL += `&timeDifference=${timeDiff}`;
    return this.httpClient.post(this.apiUrl + tempURL, {}, {headers})
    .pipe(catchError(this.handleError));
  }

  cancelAndRetryDevice(campaignId, serialNumber) {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    return this.httpClient.put(this.apiUrl + `campaigns/campaignId/${campaignId}/serialNumber/${serialNumber}/retry`, '', {headers})
      .pipe(catchError(this.handleError));
  }

  triggerFlashDevice(campaignId, serialNumber) {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    return this.httpClient.put(this.apiUrl + `campaigns/campaignId/${campaignId}/serialNumber/${serialNumber}/flashEcu`, '', {headers})
      .pipe(catchError(this.handleError));
  }

  getEcuGroups(): Observable<any> {
    return this.http.get(this.apiUrl + 'campaigns/ecu/groups').pipe(
      catchError(this.handleError));
  }

  getEcuNames(ecuGroup): Observable<any> {
    return this.http.get(this.apiUrl + `campaigns/ecu/names?ecuGroup=${ecuGroup}`).pipe(
      catchError(this.handleError));
  }

  getCampaignUploadHistory(page, campaignId): Observable<any> {
    return this.http.get(this.apiUrl + `campaigns/${campaignId}/log?page=${page}&limit=100`).pipe(
      catchError(this.handleError));
  }

  downloadLogDetailsCSV(logId): Observable<any> {
    return this.http.get(
      this.apiUrl + `campaigns/log/${logId}/download`,
      {responseType: 'arraybuffer', observe: 'response'}
    ).pipe(catchError(this.handleError));
  }

  // Campaign dashboard API
  getAllCampaignCount(customerId?, groupId?): Observable<any> {
    let tempUrl = this.apiUrl + `campaigns/type/count`;
    if (customerId) {
      tempUrl += `?customerId=${customerId}`;
    }
    if (groupId) {
      const char = tempUrl.includes('?') ? '&' : '?';
      tempUrl += `${char}groupId=${groupId}`;
    }
    return this.http.get(tempUrl)
    .pipe(catchError(this.handleError));
  }

  getHistoricalCampaignCount(months?, customerId?, groupId?): Observable<any> {
    let tempUrl = this.apiUrl + `campaigns/type/historical/count`;
    if (months) {
      const char = tempUrl.includes('?') ? '&' : '?';
      tempUrl += `${char}count=${months}`;
    }
    if (customerId) {
      const char = tempUrl.includes('?') ? '&' : '?';
      tempUrl += `${char}customerId=${customerId}`;
    }
    if (groupId) {
      const char = tempUrl.includes('?') ? '&' : '?';
      tempUrl += `${char}groupId=${groupId}`;
    }
    return this.http.get(tempUrl).pipe(catchError(this.handleError));
  }

  getAllCampaignDeviceCount(label, type, customerId?, groupId?): Observable<any> {
    let tempUrl = this.apiUrl + `campaigns/device/category/count`;
    if (label) {
      const char = tempUrl.includes('?') ? '&' : '?';
      tempUrl += `${char}label=${label}`;
    }
    if (type) {
      const char = tempUrl.includes('?') ? '&' : '?';
      tempUrl += `${char}type=${type}`;
    }
    if (customerId) {
      const char = tempUrl.includes('?') ? '&' : '?';
      tempUrl += `${char}customerId=${customerId}`;
    }
    if (groupId) {
      const char = tempUrl.includes('?') ? '&' : '?';
      tempUrl += `${char}groupId=${groupId}`;
    }
    return this.http.get(tempUrl).pipe(catchError(this.handleError));
  }

  getAllCampaignFilteredDevices(label, type, status, customerId?, groupId?): Observable<any> {
    let tempUrl = this.apiUrl + `campaigns/device/category?status=${status}&page=0&limit=100`;
    if (label) {
      tempUrl += `&label=${label}`;
    }
    if (type) {
      tempUrl += `&type=${type}`;
    }
    if (customerId) {
      tempUrl += `&customerId=${customerId}`;
    }
    if (groupId) {
      tempUrl += `&groupId=${groupId}`;
    }
    return this.http.get(tempUrl).pipe(catchError(this.handleError));
  }

  downloadCampaignFilteredDevicesCSV(label, type, status, customerId?): Observable<any> {
    let tempUrl = this.apiUrl + `campaigns/device/category/download${this.timeZone}&status=${status}`;
    if (label) {
      tempUrl += `&label=${label}`;
    }
    if (type) {
      tempUrl += `&type=${type}`;
    }
    if (customerId) {
      tempUrl += `&customerId=${customerId}`;
    }
    return this.http.get(tempUrl, {responseType: 'arraybuffer', observe: 'response'})
    .pipe(catchError(this.handleError));
  }

  getAllLabels(customerId?): Observable<any> {
    let tempUrl = this.apiUrl + `ota/labels`;
    if (customerId) {
      tempUrl += `?customerId=${customerId}`;
    }
    return this.http.get(tempUrl).pipe(catchError(this.handleError));
  }

  createLabel(label): Observable<any> {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    return this.httpClient.post(this.apiUrl + `ota/labels/${label}`, {}, {headers})
    .pipe(catchError(this.handleError));
  }

  updateCampaign(campaignId, description?, label?): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
    let tempUrl = this.apiUrl + `campaigns/${campaignId}`;
    if (description) {
      const char = tempUrl.includes('?') ? '&' : '?';
      tempUrl += `${char}description=${description}`;
    }
    if (label) {
      const char = tempUrl.includes('?') ? '&' : '?';
      tempUrl += `${char}label=${label}`;
    }
    return this.http.put(tempUrl, headers).pipe(catchError(this.handleError));
  }

  getSubFilters(campaignId, status): Observable<any> {
    return this.http.get(this.apiUrl + `campaigns/${campaignId}/status/${status}/category/count`)
    .pipe(catchError(this.handleError));
  }

  editCampaign(campaignID,data): Observable<any> {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    return this.httpClient.put(this.apiUrl + 'campaigns/'+campaignID, data, { headers }).pipe(
      catchError(this.handleError));
  }

  editfile(data, fileId): Observable<any> {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
    });
    let tempUrl = this.apiUrl + `campaigns/ota/files/${fileId}`;
    return this.httpClient.put(tempUrl, {description:data}, { headers }).pipe(
      catchError(this.handleError));
  }
}
