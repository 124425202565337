import { OnInit } from '@angular/core';
var DashboardHeaderComponent = /** @class */ (function () {
    function DashboardHeaderComponent() {
        this.permissionModules = [];
    }
    DashboardHeaderComponent.prototype.ngOnInit = function () {
        this.permissionModules = JSON.parse(localStorage.getItem('permissionModules'));
    };
    return DashboardHeaderComponent;
}());
export { DashboardHeaderComponent };
