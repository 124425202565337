<app-notify-message></app-notify-message>
<app-reports-header></app-reports-header>
<div class="tab-content">
  <div role="tabpanel" class="tab-pane fade in active show relative" id="general">
    <div class="row no-gutters reports-page">
      <div class="col-md-2 col-sm-0 p-0">
        <app-reports-side-menu></app-reports-side-menu>
      </div>
      <div class="reports-body col-md-10 col-sm-12 p-0">
        <!-- Reports content along with the action row -->
        <div class="row no-gutters action-row align-items-center pr-2 pl-2">
          <div class="col-md-12">
            <h4 class="report-heading page-heading"><i class="fa fa-bar-chart"></i> {{currentReport.name}}</h4>
          </div>
        </div>
        <div class="row limit-width mx-0 align-items-center mt-2">
          <div class="col-12">
            <div class="row">
              <div class="col-12 reports-download-btn text-center">
                <button type="button" (click)="downloadReportCSV()" [disabled]="disable" class="btn btn-primary ml-2">
                  <i class="fa fa-cloud-download mr-2"></i> Download
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>