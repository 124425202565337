<app-notify-message></app-notify-message>
<app-reports-header></app-reports-header>
<div class="tab-content">
  <div role="tabpanel" class="tab-pane fade in active show relative" id="general">
    <div class="row no-gutters reports-page">
      <div class="p-0 col-sm-0" [ngClass]="{'col-md-2': !isSideMenuClose, 'no-width': isSideMenuClose}">
        <app-reports-side-menu></app-reports-side-menu>
      </div>
      <div class="reports-body col-sm-12 p-0" [ngClass]="{'col-md-10': !isSideMenuClose, 'col-md-12': isSideMenuClose}">
        <!-- Collapse/Expand reports side menu -->
        <div class="toggle-collapse-wrapper">
          <span
            *ngIf="!isSideMenuClose"
            tooltip="Collapse Side Panel"
            placement="right"
            (click)="onSideMenuToggle()"
            class="toggleBtn badge badge-secondary">
            <i class="fa fa-angle-double-left font-14" aria-hidden="true"></i>
          </span>
          <span
            *ngIf="isSideMenuClose"
            tooltip="Expand Side Panel"
            placement="right"
            (click)="onSideMenuToggle()"
            class="toggleBtn badge badge-secondary">
            <i class="fa fa-angle-double-right font-14" aria-hidden="true"></i>
          </span>
        </div>
        <!-- Main report content with the action row -->
        <div class="row no-gutters action-row align-items-center px-2">
          <div class="col-md-2">
            <h4 class="report-heading page-heading"><i class="fa fa-bar-chart"></i> Packet History</h4>
          </div>
          <div class="col-md-10 text-right">
            <app-search-select-device
              [autoSelect]="true"
              [selectSearchCategory]="true"
              [clearable]="false"
              [querySelectDevice]="querySelectVehicle"
              (deviceChangeEvent)="changeVehicle($event)"
              >
            </app-search-select-device>
            <div class="d-inline-block calendar ml-2" (clickOutside)="hideCalendar=!hideCalendar;">
              <app-date-time-calendar
                [clickOutside]="hideCalendar"
                [selectPreset]="'24hours'"
                [vehicle]="selectedVehicle"
                [timeSelection]="true"
                [maxRangeDays]="1"
                [validateHours]="24"
                [hideLinks]="['7days', '30days', 'week', 'month', 'todaysofar', 'weektodate', 'monthtodate', '90days', '180days', '365days']"
                (timeChangeEvent)="onTimeChange($event)"
              >
              </app-date-time-calendar>
            </div>
            <button
              class="btn btn-icon ml-2 download"
              tooltip="Download CSV"
              placement="top"
              (click)="downloadCSV()"
              [disabled]="isLoading || !selectedVehicle">
              <i class="fa fa-cloud-download" aria-hidden="true"></i>
              <span class="mob-access-text d-none">&nbsp;Download</span>
            </button>
            <button
              class="btn btn-icon ml-2 refresh"
              (click)="refresh()"
              tooltip="Refresh"
              placement="top"
              [disabled]="isLoading">
              <i class="fa fa-refresh" aria-hidden="true"></i>
              <span class="mob-access-text d-none">&nbsp;Refresh</span>
            </button>
          </div>
        </div>
        <div class="row no-gutters">
          <div class="col-md-12 px-2 py-3">
            <div class="report-item-container" style="min-height: 70vh;">
              <div class="d-inline-block pull-left">
                <h6>Packet History</h6>
              </div>
              <!-- <div class="d-inline-block pull-right" style="width: 18rem;">
                <ng-select
                  [items]="messageTypeArray"
                  bindLabel="displayName"
                  bindValue="messageType"
                  [multiple]="false"
                  [closeOnSelect]="true"
                  name="messageType"
                  #messageType="ngModel"
                  [(ngModel)]="searchModel.messageType"
                  (change)="refresh()"
                  class="btn btn-custom p-0 text-left"
                  placeholder="Select Message">
                </ng-select>
              </div> -->
              <ngx-datatable
                #table
                *ngIf="packetHistory.length && !isLoading"
                class="bootstrap alertTable reportsTable wrapcell animated fadeIn"
                [rows]="packetHistory"
                [columnMode]="'force'"
                [headerHeight]="50"
                [footerHeight]="50"
                [limit]="pageInfo.limit"
                [offset]="pageInfo.offset"
                [count]="pageInfo.count"
                [rowHeight]="50"
                [summaryRow]="false"
                [scrollbarH]="true"
                (page)="pageCallback($event)"
                >
                <ngx-datatable-column [resizeable]="false" [sortable]="false" name="#" [width]="50">
                  <ng-template let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{rowIndex+1}}
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [resizeable]="false" name="Message Type" [sortable]="false" [width]="200">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <a href="javascript:void(0)" (click)="getMessageJSON(row.messageId);" data-toggle="modal" data-target="#messageJSON">
                      <span>{{ row.messageType }}</span>
                    </a>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [resizeable]="false" name="Event Time" [sortable]="false">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.eventTime | date: dateFormat }}
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [resizeable]="false" name="Ingestion Timestamp" [sortable]="false">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.ingestionTimestamp | date: dateFormat }}
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [resizeable]="false" name="Sequence Number" [sortable]="false">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.sequenceNumber }}
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [resizeable]="false" name="Latitude" [sortable]="false">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.latitude }}
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [resizeable]="false" name="Logitude" [sortable]="false">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.longitude }}
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [resizeable]="false" name="Altitude" [sortable]="false">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.altitude }}
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [resizeable]="false" name="Heading" [sortable]="false">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.heading }}
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [resizeable]="false" name="Packet Status" [sortable]="false">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.packetStatus }}
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [resizeable]="false" name="GPS Speed" [sortable]="false">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.gpsSpeed }}
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [resizeable]="false" name="GPS Odometer" [sortable]="false">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.gpsOdometer }}
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [resizeable]="false" name="Fix Quality" [sortable]="false">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.fixQuality }}
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [resizeable]="false" name="Number of Satellites" [sortable]="false">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.numberOfSatellites }}
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [resizeable]="false" name="Config Version" [sortable]="false">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.configVersion }}
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [resizeable]="false" name="Firmware Version" [sortable]="false">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.firmwareVersion }}
                  </ng-template>
                </ngx-datatable-column>
              </ngx-datatable>

              <div class="total-count pl-3" *ngIf="totalCount && totalCount.count" style="bottom: 45px;">
                Showing
                <span>
                  {{ currentPageCount.start }}
                </span>
                -
                <span>
                  {{ currentPageCount.end }}
                </span> of {{ totalCount.count }} results.
              </div>
              <div class="page-loading" *ngIf="isLoading">
                <img src="../../../assets/img/darby_loader_small.gif"/>
              </div>
              <div *ngIf="!packetHistory.length && !isLoading" class="text-center" style="margin-top: 20vh;">
                <img width="170" src="../../../assets/img/info_nodata_256.png"/>
                <h4 class="mt-3">No data to show</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- message JSON popup start -->
<div id="messageJSON" data-keyboard="false" data-backdrop="static" class="modal fade">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content bd-0 bg-transparent overflow-hidden">
      <div class="modal-body p-0">
        <div class="row no-gutters">
          <div class="col-12">
            <span class="pull-right closeBtn" data-dismiss="modal">
              <i class="fa fa-times" aria-hidden="true"></i>
            </span>
            <div class="modal-box p-3 mt-4">
              <div class="reply-header">
                <h5>Message Detail</h5>
              </div>
              <div *ngIf="messageJsonLoader" class="text-center mt-5 pt-5">
                <img src="../../../assets/img/darby_loader_small.gif"/>
              </div>
              <div *ngIf="!messageJsonLoader" class="scrollable-modal-body">
                <pre class="bench-device-data mt-4" [innerHTML]="messageJsonFormattedString"> </pre>
              </div>
              <div class="row pt-3">
                <div class="col-lg-6"></div>
                <div class="col-lg-6 sm-form-design">
                  <div class="login-buttons width100 text-right">
                    <button class="btn btn-outline-primary small-screen-btn mr-2" (click)="downloadMessageJSON()">Download JSON</button>
                    <button class="btn btn-outline-secondary small-screen-btn mr-2" data-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- message JSON popup end -->