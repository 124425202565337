import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToHms'
})
export class SecondsToHmsPipe implements PipeTransform {

  transform(d: number, constraint?: string): any {
    if (d === undefined || d === null) {
      return null;
    } else {
      d = Number(d);
      if (constraint && constraint === 'milliseconds') {
        return this.convert(d);
      } else {
        d = d * 1000;
        return this.convert(d);
      }
    }
  }

  convert(millis) {
    const d = Math.floor((millis / (1000 * 60 * 60) / 24));
    const h = Math.floor((millis / (1000 * 60 * 60)) % 24);
    const m = Math.floor((millis / (1000 * 60)) % 60);
    const s = Math.floor((millis / 1000) % 60);

    const dDisplay = (d < 10) ? '0' + d : d;
    const hDisplay = (h < 10) ? '0' + h : h;
    const mDisplay = (m < 10) ? '0' + m : m;
    const sDisplay = (s < 10) ? '0' + s : s;

    let text = '-----';
    if (d) {
      text = `${dDisplay}d ${hDisplay}h ${mDisplay}m ${sDisplay}s`;
    } else if (!d && h) {
      text = `${hDisplay}h ${mDisplay}m ${sDisplay}s`;
    } else if (!d && !h && m) {
      text = `${mDisplay}m ${sDisplay}s`;
    } else if (!d && !h && !m && s) {
      text = `${sDisplay}s`;
    }
    return text;
    // return dDisplay + 'd ' + hDisplay + 'h ' + mDisplay + 'm ' + sDisplay + 's';
  }

}
