import { of as observableOf } from 'rxjs';
var CustomPreloadingStrategy = /** @class */ (function () {
    function CustomPreloadingStrategy() {
        this.preloadedModules = [];
    }
    CustomPreloadingStrategy.prototype.preload = function (route, load) {
        if (route.data && route.data['preload']) {
            this.preloadedModules.push(route.path);
            return load();
        }
        else {
            return observableOf(null);
        }
    };
    return CustomPreloadingStrategy;
}());
export { CustomPreloadingStrategy };
