import { tap } from 'rxjs/operators';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
var ResponseInterceptor = /** @class */ (function () {
    function ResponseInterceptor(router, route) {
        this.router = router;
        this.route = route;
    }
    ResponseInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        return next.handle(req).pipe(tap(function (event) { }, function (err) {
            // if (err instanceof HttpErrorResponse && err.status == 401) {
            //     // handle 401 errors
            // }
            if (err instanceof HttpErrorResponse && err.status == 400) {
            }
            // else if(err instanceof HttpErrorResponse && err.status == 500) {
            //     this.router.navigate(['/500']);
            // }
            // else if(err instanceof HttpErrorResponse && err.status == 403) {
            //     this.router.navigate(['/403']);
            //
            // }
            else if (err instanceof HttpErrorResponse && err.status == 401 && !window.location.href.includes("tracking?key")) {
                // let impersonate = localStorage.getItem('impersonate');
                if (!_this.route.snapshot.queryParams['returnUrl'] && _this.router.url !== '/login') {
                    _this.router.navigate(['login'], { queryParams: {
                            returnUrl: _this.router.url,
                            returnModule: localStorage.getItem("currentModule")
                        } });
                }
                else {
                    return;
                }
                localStorage.removeItem('currentUser');
                localStorage.clear();
                // this.router.navigate(['']);
            }
            // else if(err instanceof HttpErrorResponse && err.status == 404) {
            //     this.router.navigate(['/404']);
            // }
        }));
        //         return next.handle(req).map(resp => {
        //                 if (resp instanceof HttpResponse) {
        //                 }
        //                 return resp;
        //             }).catch(err => {
        //                 if (err instanceof HttpResponse)
        //  {
        //                 }
        //                 return Observable.of(err);
        //             });
    };
    return ResponseInterceptor;
}());
export { ResponseInterceptor };
