import { throwError as observableThrowError, Observable } from 'rxjs';

import { catchError, share } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';

import { environment } from '@myenv/environment';

@Injectable()

export class DeviceService {
  public apiUrl = environment.apiUrl;
  private httpClient: HttpClient;

  d = new Date();
  offsetInMinutes = this.d.getTimezoneOffset() * -1;
  timeZone = `?dateFormat=${environment.isoDateTimeFormat}&offsetInMinutes=${this.offsetInMinutes}`;


  constructor(private http: HttpClient, private handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }


  updateXlFile(data): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    return this.httpClient.put(this.apiUrl + 'devices/upload', data, { headers }).pipe(
      catchError(this.handleError));
  }

  uploadXlFile(data): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    return this.httpClient.post(this.apiUrl + `devices/upload`, data, { headers }).pipe(
      catchError(this.handleError));
  }

  bulkuploadFile(data): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    return this.httpClient.post(this.apiUrl + `devices/bulkUpload`, data, { headers }).pipe(
      catchError(this.handleError));
  }

  getDeletedDevice(page): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
    });

    return this.http.get(this.apiUrl + 'devices/deleted?page=' + page, { headers }).pipe(
      catchError(this.handleError));
  }

  getDeviceUploadLogs(): Observable<any> {
    return this.http.get(this.apiUrl + 'devices/log').pipe(
      catchError(this.handleError));
  }

  getDeviceStatus(deviceId): Observable<any> {
    return this.http.get(this.apiUrl + 'devices/status/deviceId/' + deviceId).pipe(
      catchError(this.handleError));
  }

  getVehicleDetail(serialNumber): Observable<any> {
    return this.http.get(this.apiUrl + 'vehicles/serialNumber/' + serialNumber).pipe(
      catchError(this.handleError));
  }

  getDeviceDetail(deviceId): Observable<any> {
    return this.http.get(this.apiUrl + 'devices/' + deviceId)
      .pipe(share()).pipe(
        catchError(this.handleError));
  }

  getSimInfo(simCcid): Observable<any> {
    return this.http.get(this.apiUrl + `carrier/iccid/${simCcid}/siminfo`).pipe(
      catchError(this.handleError));
  }

  getVehicleCount(key?, value?, data?, group?, isAssociatedDevice?): Observable<any> {
    let tempUrl = `${this.apiUrl}devices/count?page=0`;
    if (isAssociatedDevice === undefined) {
      isAssociatedDevice = false;
    }
    if (value !== '' && value) {
      tempUrl += `&key=${key}&value=${value}&isAssociatedDevice=${isAssociatedDevice}`;
    }
    if (data) {
      tempUrl += `&lastKnownStatus=${data}`;
    }
    if (group) {
      tempUrl += `&groups=${group}`;
    }
    return this.http.get(tempUrl).pipe(catchError(this.handleError));
    // return this.http.get(this.apiUrl + 'devices/count').pipe(
    //   catchError(this.handleError));
  }

  downloadUploadLog(logId): Observable<any> {
    return this.http.get(this.apiUrl + 'devices/log/' + logId + '/csvDownload' + this.timeZone, {
      responseType: 'arraybuffer',
      observe: 'response'
    }).pipe(
      catchError(this.handleError));
  }

  updateDevice(payload, device): Observable<any> {
    return this.http.put(this.apiUrl + 'devices/' + device.deviceId, payload).pipe(
      catchError(this.handleError));
  }

  deleteDevice(deviceId): Observable<any> {
    return this.http.delete(this.apiUrl + 'devices/' + deviceId).pipe(
      catchError(this.handleError));
  }

  downloadDeviceCSV(searchData, deviceStatusKey?, groups?, selectedAttr?, isAssociatedDevice?): Observable<any> {
    let tempUrl = this.apiUrl + 'devices/download' + this.timeZone + '&page=0';
    // if (searchData.deviceTypeVal || deviceStatusKey) {
    if (deviceStatusKey) {
      tempUrl += '&status=' + deviceStatusKey;
    }
    if (searchData.deviceTypeVal) {
      tempUrl += '&key=' + searchData.deviceId + '&value=' + searchData.deviceTypeVal + '&isAssociatedDevice=' + isAssociatedDevice;
    }
    if (groups) {
      tempUrl += '&groups=' + groups;
    }
    if (selectedAttr) {
      tempUrl += '&attributes=' + selectedAttr;
    }
    // }
    return this.http.get(tempUrl, { responseType: 'arraybuffer', observe: 'response' }).pipe(
      catchError(this.handleError));
  }

  getDeviceMetrics(data): Observable<any> {
    return this.http.get(this.apiUrl + `analytics/serialNumber/message/count?serialNumber=${data.serialNumber}&startDate=
      ${data.startTime}&endDate=${data.endTime}`).pipe(catchError(this.handleError));
  }

  getCarrier(carrierName, data): Observable<any> {
    return this.http.post(this.apiUrl + `carrier/${carrierName}/GET_SIM_STATUS`, data).pipe(
      catchError(this.handleError));
  }

  getCarrierFeatures(carrierName): Observable<any> {
    return this.http.get(this.apiUrl + `carrier/${carrierName}/features`).pipe(
      catchError(this.handleError));
  }

  getAllDevices(page, data, key?, value?): Observable<any> {
    let tempUrl = `devices?page=${page}`;

    if (data.prop) {
      tempUrl = tempUrl + `&sortingKey=${data.prop}&sort=${data.dir.toUpperCase()}`;
    }

    if (key && value) {
      tempUrl = tempUrl + `&key=${key}&value=${value}`;
    }
    return this.http.get(this.apiUrl + tempUrl).pipe(
      catchError(this.handleError));
  }

  downloadActivityCSV(deviceId, filter): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
    });
    let tempUrl = `activity/device/download/deviceId/${deviceId}${this.timeZone}`;
    if (filter) tempUrl += `&filter=${filter}`;
    return this.http.get(this.apiUrl + tempUrl, { responseType: 'arraybuffer', observe: 'response', headers })
      .pipe(catchError(this.handleError));
  }

  addDevice(device): Observable<any> {
    // the complete device creation url
    const url = this.apiUrl + `devices`;
    return this.http.post(url, device).pipe(catchError(this.handleError));
  }

  getDeviceCampaigns(page: number, limit: number, serialNumber: string, exact: boolean, filterLabel?: string): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    let tempUrl = this.apiUrl + `campaigns/serialNumber/${serialNumber}?page=${page}&limit=${limit}&partialSearch=${exact}`;
    if (filterLabel) { tempUrl += `&label=${filterLabel}`; }
    return this.httpClient.get(tempUrl, { headers })
      .pipe(catchError(this.handleError));
  }

  downloadKYC(serialNumber) {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    return this.http.get(
      this.apiUrl + `al/vehicles/provisioning/kyc/dsn/${serialNumber}/download`, { headers, responseType: 'arraybuffer' }
    ).pipe(catchError(this.handleError));
  }

  getQueryDevices(term, page): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
    });
    return this.http.get(this.apiUrl + `devices/advance/search?query=${term}&page=${page}&limit=100`, { headers }).pipe(
      catchError(this.handleError));
  }

  getQueryDevicesCount(term): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
    });
    return this.http.get(this.apiUrl + `devices/advance/search/count?query=${term}`, { headers }).pipe(
      catchError(this.handleError));
  }

  getHistoricalUsageData(simCcid, startTime, endTime): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
    });
    return this.http.get(this.apiUrl + `carrier/sim/usages/history/${simCcid}/details?startTime=${startTime}&endTime=${endTime}`, { headers })
      .pipe(catchError(this.handleError));
  }

  getAdvancedFilters(): Observable<any> {
    return this.http.get(this.apiUrl + `devices/filter`).pipe(catchError(this.handleError));
  }

  createAdvancedFilter(payload: { name: string, query: string }): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    return this.httpClient.post(this.apiUrl + `devices/filter`, payload, { headers })
      .pipe(catchError(this.handleError));
  }

  downloadQueryDevices(term, attributes): Observable<any> {
    return this.http.get(
      this.apiUrl + `devices/advance/search/download?query=${term}&attributes=${attributes}`,
      { responseType: 'arraybuffer', observe: 'response' }
    ).pipe(catchError(this.handleError));
  }

  getLoginActivity(sessionId): Observable<any> {
    return this.http.get(this.apiUrl + `activity/loginActivity/session/${sessionId}`)
      .pipe(catchError(this.handleError));
  }

  getConnectivityCarriers(): Observable<any> {
    return this.http.get(this.apiUrl + `carrier/connectivity`)
      .pipe(catchError(this.handleError));
  }

  // for tags
  getAllTags(): Observable<any> {
    return this.http.get(this.apiUrl + `devices/tags`)
      .pipe(catchError(this.handleError));
  }

  createTag(tagName): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    return this.httpClient.post(this.apiUrl + `devices/tag?tagName=${tagName}`, {}, { headers })
      .pipe(catchError(this.handleError));
  }

  updateTag(tagId, tagName, type): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    return this.http.patch(this.apiUrl + `devices/tag/${tagId}?tagName=${tagName}&type=${type}`, {}, { headers }).pipe(
      catchError(this.handleError));
  }

  deleteTag(tagId) {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    return this.http.delete(this.apiUrl + `devices/tag/remove/${tagId}`, { headers }).pipe(
      catchError(this.handleError));
  }

  handleError(error: any | any) {
    return observableThrowError(error.error || error.json() || error || 'Server error');
  }

  getConfig(serialNumber, configType): Observable<any> {
    return this.http.get(this.apiUrl + 'twins/config?serialNumber=' + serialNumber + '&configType=' + configType).pipe(
      catchError(this.handleError));
  }
}
