import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@myenv/environment';
import { throwError as observableThrowError } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ScoreService = /** @class */ (function () {
    function ScoreService(http) {
        this.http = http;
        this.apiUrl = environment.apiUrl;
    }
    ScoreService.prototype.getScores = function (deviceId, startTime, endTime, scoreType) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
        var tempUrl = this.apiUrl + ("scores/device/" + deviceId);
        if (startTime && endTime) {
            tempUrl += "?startTime=" + startTime + "&endTime=" + endTime;
        }
        if (scoreType) {
            tempUrl += "&scoreType=" + scoreType;
        }
        return this.http.get(tempUrl, { headers: headers })
            .pipe(catchError(this.handleError));
    };
    ScoreService.prototype.getVehicleScores = function (deviceId, startTime, endTime, scoreType) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
        var tempUrl = this.apiUrl + ("analyticsProxy/scores/device/" + deviceId);
        if (startTime && endTime) {
            tempUrl += "?startTime=" + startTime + "&endTime=" + endTime;
        }
        if (scoreType) {
            tempUrl += "&scoreType=" + scoreType;
        }
        return this.http.get(tempUrl, { headers: headers })
            .pipe(catchError(this.handleError));
    };
    ScoreService.prototype.handleError = function (error) {
        return observableThrowError(error.error || error.json() || error || 'Server error');
    };
    ScoreService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ScoreService_Factory() { return new ScoreService(i0.ɵɵinject(i1.HttpClient)); }, token: ScoreService, providedIn: "root" });
    return ScoreService;
}());
export { ScoreService };
