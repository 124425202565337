import * as tslib_1 from "tslib";
import { from as observableFrom } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
export var InterceptorSkipHeader = 'X-Skip-Interceptor';
var CustomHttpInterceptor = /** @class */ (function () {
    function CustomHttpInterceptor(router, route) {
        this.router = router;
        this.route = route;
        this.resetToken = '';
    }
    CustomHttpInterceptor.prototype.intercept = function (request, next) {
        return observableFrom(this.handleAccess(request, next));
    };
    CustomHttpInterceptor.prototype.handleAccess = function (request, next) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token, currentUser, reportName, currentPath, changedRequest, headerSettings, _a, _b, key, newHeader;
            var e_1, _c;
            var _this = this;
            return tslib_1.__generator(this, function (_d) {
                switch (_d.label) {
                    case 0: return [4 /*yield*/, localStorage.getItem('token')];
                    case 1:
                        token = _d.sent();
                        currentUser = localStorage.getItem('currentUser');
                        reportName = sessionStorage.getItem('reportName');
                        currentPath = window.location.pathname;
                        changedRequest = request;
                        headerSettings = {};
                        try {
                            for (_a = tslib_1.__values(request.headers.keys()), _b = _a.next(); !_b.done; _b = _a.next()) {
                                key = _b.value;
                                headerSettings[key] = request.headers.getAll(key);
                            }
                        }
                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                        finally {
                            try {
                                if (_b && !_b.done && (_c = _a.return)) _c.call(_a);
                            }
                            finally { if (e_1) throw e_1.error; }
                        }
                        if (!window.location.href.includes("tracking?key")) {
                            if (token) {
                                headerSettings['Authorization'] = 'Bearer ' + token;
                            }
                        }
                        if (currentUser) {
                            headerSettings['customInfo'] = currentPath + '/' + reportName;
                        }
                        // if (reportName) {
                        //   headerSettings['reportName'] = reportName;
                        // }
                        this.route.params.subscribe(function (params) {
                            _this.resetToken = params.id;
                        });
                        if ((this.router.url === '/forgot-password')) {
                            headerSettings['Content-Type'] = 'application/x-www-form-urlencoded';
                        }
                        else {
                            headerSettings['Content-Type'] = 'application/json';
                        }
                        headerSettings['observe'] = 'response';
                        newHeader = new HttpHeaders(headerSettings);
                        changedRequest = request.clone({
                            headers: newHeader
                        });
                        return [2 /*return*/, next.handle(changedRequest).toPromise()];
                }
            });
        });
    };
    return CustomHttpInterceptor;
}());
export { CustomHttpInterceptor };
