import { Router } from '@angular/router';
import { SharedService } from '@shared/shared.service';
var AuthGuard = /** @class */ (function () {
    function AuthGuard(router, sharedService) {
        this.router = router;
        this.sharedService = sharedService;
    }
    AuthGuard.prototype.canActivate = function (route, state) {
        var global = window;
        var intercomm = localStorage.getItem('intercomm');
        if (localStorage.getItem('currentUser') || (localStorage.getItem('token'))) {
            var obj = JSON.parse(localStorage.getItem('userInfo'));
            // if (obj && global.Intercom && intercomm !==) {
            //     this.sharedService.bootUpInterCom();
            // }
            // logged in so return true
            return true;
        }
        // if (global.Intercom) {
        //     this.sharedService.bootDownInterCom();
        // }
        this.sharedService.bootDownInterCom();
        this.router.navigate(['login']);
        return false;
    };
    return AuthGuard;
}());
export { AuthGuard };
