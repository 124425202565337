<div class="container-fluid">
  <div class="row">
    <div class="col-sm-10 mx-auto">
      <div class="widget-container mt-5">
        <h5 class="headingAdd font400 widget-heading">Frequently Asked Questions</h5>
        <div class="mt-4" id="accordion">
          <div class="card mt-3" *ngFor="let item of data; let i=index;">
            <div class="card-header" [id]="'heading'+i">
              <h5 class="mb-0">
                <button class="btn btn-link collapsed color" data-toggle="collapse" [attr.data-target]="'#collapse'+ i" aria-expanded="false" [attr.aria-controls]="'collapse'+ i">
                  {{item.title}}
                  <i class="fa fa-plus ml-2 pull-right mt-1"></i>
                  <i class="fa fa-minus ml-2 pull-right mt-1"></i>
                </button>
              </h5>
            </div>

            <div [id]="'collapse'+ i" class="collapse" [attr.aria-labelledby]="'heading'+i" data-parent="#accordion">
              <div class="card-body" [innerHTML]="item.body"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
