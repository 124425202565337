import { Component, OnInit, OnChanges, Output, EventEmitter, Input } from '@angular/core';
import { Observable, Subject, concat, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { SharedService } from '../shared.service';
import { configMessage } from '@shared/config-message';
import * as moment from 'moment';
import { environment } from '@myenv/environment';

@Component({
  selector: 'app-search-select-vehicle',
  templateUrl: './search-select-vehicle.component.html',
  styleUrls: ['./search-select-vehicle.component.scss']
})

export class SearchSelectVehicleComponent implements OnInit, OnChanges {
  @Output() vehicleChangeEvent = new EventEmitter();
  @Input() autoSelect: boolean;
  @Input() querySelectVehicle: any;
  @Input() clearable = true;
  @Input() isMulti = false;
  @Input() closeOnSelect = true;
  @Input() defaultselectvehicle: boolean;
  @Input() title: boolean;
  @Input() ev: boolean;
  vehicle$: Observable<any[]>;
  vehicles: any = [];
  defaultVehicles: any = [];
  vehicleLoading = false;
  vehicleInput$ = new Subject<string>();
  selectedVehicles = <any>null;
  deviceStatus = configMessage.deviceStatus;
  deviceStatusClass = configMessage.deviceStatusClass;
  public dateFormat = environment.smallDateFormat;
  attributes = 'deviceId,serialNumber,assetName,productSerialNumber,vin,lastCommunication,deviceStatus,architectureType,ecuMake,generation,simStatus,imei,firmwareVersion,configVersion,simCcid,lastKnownEvent,lastKnownStatus';

  constructor(
    private sharedService: SharedService
  ) { }

  ngOnInit() {
    this.getAllVehicles();
  }

  ngOnChanges(changes) {
    if (changes.querySelectVehicle && changes.querySelectVehicle.previousValue !== undefined) {
      if (changes.querySelectVehicle.previousValue !== changes.querySelectVehicle.currentValue) {
        if (changes.querySelectVehicle.previousValue === null) {
          this.selectedVehicles = this.querySelectVehicle;
        }
      }
    }
  }

  trackByFn(item) {
    return item.deviceId;
  }

   getAllVehicles(selectedGroup?) {
    const vehiclesSubsc = this.ev ? this.sharedService.getAllEvVehicles(0) : this.sharedService.getAllDevices(selectedGroup);
    vehiclesSubsc.subscribe({
      next: res => {
        this.defaultVehicles = res;
        localStorage.setItem('devices', JSON.stringify(res));
        // if (this.ev) {
        //   this.defaultVehicles = res.filter((device) => device.generation === "EBIKE");
        // } else { this.defaultVehicles = res; }
        // this.performVehicleOperations();
        // this.selectedVehicles = res[0];
        // this.loadVehices();
        this.selectVehicle();
      },
      error: error => {
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  performVehicleOperations() {
    this.vehicles.map(vehicle => {
      const commDate = Date.parse(new Date(vehicle.lastCommunication).toUTCString());
      const date = moment(commDate).fromNow();
      vehicle.lastCommunicationString = date;
    });
  }

  selectVehicle() {
    if (!this.defaultselectvehicle) {
      if (this.autoSelect) {
        this.selectedVehicles = this.selectedVehicles ? this.selectedVehicles : this.defaultVehicles[0];
      } else {
        this.selectedVehicles = this.selectedVehicles ? this.selectedVehicles : null;
      }
    } else {
      if (this.selectedVehicles) {
        this.sharedService.setdefaultselectvehicle(this.selectedVehicles);
      }
      const defaultSel = this.sharedService.getdefaultselectvehicle();
      if (this.ev) {
        if (defaultSel && defaultSel.generation !== 'EBIKE') {
          if (this.defaultVehicles && this.defaultVehicles.length) {
            this.selectedVehicles = this.defaultVehicles[0];
          }
        } else if (defaultSel === null) {
          this.selectedVehicles = this.defaultVehicles[0];
        } else { this.selectedVehicles = defaultSel; }
      } else { this.selectedVehicles = defaultSel ? defaultSel : this.defaultVehicles[0]; }
    }
    this.vehicleChangeEvent.emit(this.selectedVehicles);
    this.vehicleLoading = false;
  }

  validateInputString(term): string {
    term = term.replace(/[^_\-@.a-zA-Z0-9\s]/g, '').trim();
    this.vehicleInput$.next(term);
    return term;
  }

  loadVehicles() {
    this.vehicle$ = concat(
      of(this.defaultVehicles), // default items
      this.vehicleInput$.pipe(
        distinctUntilChanged(),
        debounceTime(500),
        tap(() => this.vehicleLoading = true),
        switchMap(term => this.sharedService.onSearchVehicle(
          'assetName', this.validateInputString(term)
        ).pipe(
          catchError((error) => of(this.sharedService.getErrorMsg(error), this.defaultVehicles)), // empty list on error
          tap(() => this.vehicleLoading = false)
        ))
      )
    );
    this.vehicle$.subscribe({
      next: res => {
        if (res && res.length && this.ev) {
          this.vehicles = res.filter((device) => device.generation === "EBIKE");
        } else { this.vehicles = res; }
        this.vehicleLoading = false;
        this.performVehicleOperations();
      },
      error: error => {
        this.vehicleLoading = false;
        // this.sharedService.getErrorMsg(error);
      }
    });
  }

  getLastKnownStatus(lastKnownStatus) {
    if (lastKnownStatus) {
      return lastKnownStatus;
    } else {
      return "Registered";
    }
  }
}
