
import {throwError as observableThrowError, Observable, BehaviorSubject} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import 'rxjs/Observable';

import {environment} from '@myenv/environment';

@Injectable()
export class PlaceService {

  public buttonClick = new BehaviorSubject(false);

  public apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

// To get all Place
  getAllPlace(page): Observable<any> {
    return this.http.get(this.apiUrl + 'places?page='+page).pipe(
      catchError(this.handleError))
  }

//To create a Place
  createPlace(data): Observable<any> {
    return this.http.post(this.apiUrl + 'places', data).pipe(
      catchError(this.handleError))
  }

  // to get places count
  getPlacesCount(): Observable<any> {
    return this.http.get(this.apiUrl + "places/count").pipe(
      catchError(this.handleError))
  }

//To get all Place vehicles
  getPlaceVehicles(placeId): Observable<any> {
    return this.http.get(this.apiUrl + 'places/' + placeId + "/devices",).pipe(
      catchError(this.handleError))
  }

//To update a Place
  updatePlace(place): Observable<any> {
    return this.http.put(this.apiUrl + "places/" + place.placeId, place).pipe(
      catchError(this.handleError))
  }

//To update status
  updateStatus(geoplaceId, active): Observable<any> {
    return this.http.patch(this.apiUrl + "places/" + geoplaceId + "/active/" + active, {}).pipe(
      catchError(this.handleError))
  }

//To delete a Place
  deletePlace(placeId): Observable<any> {
    return this.http.delete(this.apiUrl + "places/" + placeId).pipe(
      catchError(this.handleError))
  }

  onSearchPlace(value) : Observable<any>{
    return this.http.get(`${this.apiUrl}places/search?name=${value}`).pipe(
      catchError(this.handleError))
  }

//To handle errors
  handleError(error: any | any) {
    return observableThrowError(error.error || error.json() || error || 'Server error');
  }

}
