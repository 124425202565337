<app-notify-message></app-notify-message>
<app-reports-header></app-reports-header>
<div class="row no-gutters reports-page">
  <div class="col-md-2 col-sm-0 p-0">
    <app-reports-side-menu></app-reports-side-menu>
  </div>
  <div class="reports-body col-md-10 col-sm-12 p-0">
    <div class="row no-gutters action-row align-items-center px-2">
      <div class="col-md-6">
        <h4 class="report-heading page-heading animated fadeIn">
          <i class="fa fa-bar-chart"></i> Diagnostics Report
        </h4>
      </div>
      <div class="col-md-6 text-right">
        <div class="calendar d-inline-block ml-2" (clickOutside)="hideCalendar=!hideCalendar;">
          <app-date-time-calendar
            [clickOutside]="hideCalendar"
            [selectPreset]="'30days'"
            [timeSelection]="false"
            [hideLinks]="['today', '15mins', '30mins', '1hour', '4hours', '12hours', '90days', '180days', '365days']"
            >
          </app-date-time-calendar>
          <button
            class="btn btn-icon ml-2 refresh"
            (click)="refresh()"
            tooltip="Refresh"
            placement="top"
            [disabled]="isLoading">
            <i class="fa fa-refresh" aria-hidden="true"></i>
            <span class="mob-access-text d-none">&nbsp;Refresh</span>
          </button>
        </div>
      </div>
    </div>
    <div class="row no-gutters pt-2">
      <div class="col-md-12 px-3 py-2">
        <div class="outer-wrapper" (click)="toggleDrawer(14)">
          <div class="report-item-container total-count-container communicating animated fadeIn"
            [class.active]="selectedStatus===14">
            <span class="count">{{status.notConnected}}</span>
            <!-- <span class="unit">K</span> -->
            <div class="progress">
              <div
                class="progress-bar"
                role="progressbar"
                [style.width] = "statusPercent.notConnected + '%'"
                aria-valuenow="statusPercent.notConnected"
                aria-valuemin="0"
                aria-valuemax="100">
              </div>
            </div>
            <h6 class="text">
              <span>Odometer Issues</span>
              <span class="percentage">{{statusPercent.notConnected}} %</span>
            </h6>
          </div>
        </div>
        <div class="outer-wrapper" (click)="toggleDrawer(15)">
          <div class="report-item-container total-count-container notCommunicating animated fadeIn"
            [class.active]="selectedStatus===15">
            <span class="count">{{status.notConnected}}</span>
            <!-- <span class="unit">K</span> -->
            <div class="progress">
              <div
                class="progress-bar"
                role="progressbar"
                [style.width] = "statusPercent.notConnected + '%'"
                aria-valuenow="statusPercent.notConnected"
                aria-valuemin="0"
                aria-valuemax="100">
              </div>
            </div>
            <h6 class="text">
              <span>Configuration Mismatch</span>
              <span class="percentage">{{statusPercent.notConnected}} %</span>
            </h6>
          </div>
        </div>
        <div class="outer-wrapper" (click)="toggleDrawer(16)">
          <div class="report-item-container total-count-container disconnected animated fadeIn"
            [class.active]="selectedStatus===16">
            <span class="count">{{status.notConnected}}</span>
            <!-- <span class="unit">K</span> -->
            <div class="progress">
              <div
                class="progress-bar"
                role="progressbar"
                [style.width] = "statusPercent.notConnected + '%'"
                aria-valuenow="statusPercent.notConnected"
                aria-valuemin="0"
                aria-valuemax="100">
              </div>
            </div>
            <h6 class="text">
              <span>Live Disconnect</span>
              <span class="percentage">{{statusPercent.notConnected}} %</span>
            </h6>
          </div>
        </div>
        <div class="outer-wrapper pr-0" (click)="toggleDrawer(3)">
          <div class="report-item-container total-count-container notConnected animated fadeIn"
            [class.active]="selectedStatus===3">
            <span class="count">{{status.notConnected}}</span>
            <!-- <span class="unit">K</span> -->
            <div class="progress">
              <div
                class="progress-bar"
                role="progressbar"
                [style.width] = "statusPercent.notConnected + '%'"
                aria-valuenow="statusPercent.notConnected"
                aria-valuemin="0"
                aria-valuemax="100">
              </div>
            </div>
            <h6 class="text">
              <span>Not Connected</span>
              <span class="percentage">{{statusPercent.notConnected}} %</span>
            </h6>
          </div>
        </div>
        <div class="outer-wrapper pt-3" (click)="toggleDrawer(100)">
          <div class="report-item-container total-count-container ota-failed animated fadeIn"
            [class.active]="selectedStatus===3">
            <span class="count">{{status.notConnected}}</span>
            <!-- <span class="unit">K</span> -->
            <div class="progress">
              <div
                class="progress-bar"
                role="progressbar"
                [style.width] = "statusPercent.notConnected + '%'"
                aria-valuenow="statusPercent.notConnected"
                aria-valuemin="0"
                aria-valuemax="100">
              </div>
            </div>
            <h6 class="text">
              <span>OTA Failed</span>
              <span class="percentage">{{statusPercent.notConnected}} %</span>
            </h6>
          </div>
        </div>
        <div class="outer-wrapper pt-3" (click)="toggleDrawer(101)">
          <div class="report-item-container total-count-container sim-plan-expiry animated fadeIn"
            [class.active]="selectedStatus===3">
            <span class="count">{{status.notConnected}}</span>
            <!-- <span class="unit">K</span> -->
            <div class="progress">
              <div
                class="progress-bar"
                role="progressbar"
                [style.width] = "statusPercent.notConnected + '%'"
                aria-valuenow="statusPercent.notConnected"
                aria-valuemin="0"
                aria-valuemax="100">
              </div>
            </div>
            <h6 class="text">
              <span>SIM Plan Expired</span>
              <span class="percentage">{{statusPercent.notConnected}} %</span>
            </h6>
          </div>
        </div>
        <div class="outer-wrapper pt-3" (click)="toggleDrawer(102)">
          <div class="report-item-container total-count-container not-communicating animated fadeIn"
            [class.active]="selectedStatus===3">
            <span class="count">{{status.notConnected}}</span>
            <!-- <span class="unit">K</span> -->
            <div class="progress">
              <div
                class="progress-bar"
                role="progressbar"
                [style.width] = "statusPercent.notConnected + '%'"
                aria-valuenow="statusPercent.notConnected"
                aria-valuemin="0"
                aria-valuemax="100">
              </div>
            </div>
            <h6 class="text">
              <span>Not Communicating</span>
              <span class="percentage">{{statusPercent.notConnected}} %</span>
            </h6>
          </div>
        </div>
        <div class="device-drawer" [class.open]="isDrawerOpen" 
        [class.first-row]="selectedStatus===14 || selectedStatus===15 || selectedStatus===16 || selectedStatus===3" 
        [class.second-row]="selectedStatus===100 || selectedStatus===101 || selectedStatus===102">
          <div class="table-container" *ngIf="isDrawerOpen"
            [class.communicating-arrow]="selectedStatus===14"
            [class.notCommunicating-arrow]="selectedStatus===15"
            [class.disconnected-arrow]="selectedStatus===16"
            [class.notConnected-arrow]="selectedStatus===3"
            [class.ota-failed-arrow]="selectedStatus===100"
            [class.sim-expiry-arrow]="selectedStatus===101"
            [class.not-communicating-arrow]="selectedStatus===102">
            <div>
              <div class="d-inline-block drawer-heading pull-left mt-1">
                <h6 *ngIf="selectedStatus===14">Devices having Odometer issues</h6>
                <h6 *ngIf="selectedStatus===15">Devices having Configuration Mismatch</h6>
                <h6 *ngIf="selectedStatus===16">Live Disconnect Devices</h6>
                <h6 *ngIf="selectedStatus===3">Not Connected Devices</h6>
                <h6 *ngIf="selectedStatus===100">OTA Failed Devices</h6>
                <h6 *ngIf="selectedStatus===101">SIM Plan Expired Devices</h6>
                <h6 *ngIf="selectedStatus===102">Not Communicating Devices</h6>
              </div>
              <div class="d-inline-block text-right pull-right mb-1">
                <button
                  class="btn btn-icon ml-2 download"
                  (click)="downloadCSV()"
                  tooltip="Download CSV"
                  placement="top"
                  [disabled]="!devices.length">
                  <i class="fa fa-cloud-download" aria-hidden="true"></i>
                  <span class="mob-access-text d-none">&nbsp;Download</span>
                </button>
                <button
                  class="btn btn-icon ml-2"
                  (click)="toggleDrawer(selectedStatus)"
                  tooltip="Close Drawer"
                  placement="top">
                  <i class="fa fa-chevron-up" aria-hidden="true"></i>
                  <span class="mob-access-text d-none">&nbsp;Close</span>
                </button>
              </div>
            </div>
            <div class="drawer-loading text-center" *ngIf="isLoading">
              <img src="../../../assets/img/darby_loader_small.gif"/>
            </div>
            <div *ngIf="!devices.length && !isLoading" class="nodata-contaner text-center">
              <img width="150" src="../../../assets/img/info_nodata_256.png"/>
              <h4 class="mt-3">No data to show</h4>
            </div>
            <ngx-datatable *ngIf="devices.length"
              id="tableau" #dataTable
              class="bootstrap alertTable reportsTable wrapcell"
              [rows]="devices"
              [columnMode]="'force'"
              [headerHeight]="50"
              [footerHeight]="50"
              [rowHeight]="'auto'"
              [summaryRow]="false"
              [scrollbarH]="true"
              [reorderable]="true"
              [limit]="pageInfo.limit"
              [offset]="pageInfo.offset"
              [count]="pageInfo.count"
              (page)="pageCallback($event)"
              >
              <ngx-datatable-column [resizeable]="false" [sortable]="false" name="#" [width]="50">
                <ng-template let-rowIndex="rowIndex" ngx-datatable-cell-template>
                  {{rowIndex+1}}
                </ng-template>
              </ngx-datatable-column>
              <ng-container *ngFor="let dh of displayHeaders">
                <ngx-datatable-column
                  [resizeable]="false"
                  name="{{dh.displayName}}"
                  prop="{{dh.name}}"
                  [sortable]="false"
                  *ngIf="!dh.name.includes('customerId') && !dh.name.includes('companyId')">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <span *ngIf="dateAttributes.includes(dh.name)">{{row[dh.name] | date : dateFormat}}</span>
                    <span *ngIf="!dateAttributes.includes(dh.name) && dh.name !== 'deviceStatus'">
                      {{row[dh.name]}}
                    </span>
                    <span [class]="'class-' + deviceStatusClass[row[dh.name]]" *ngIf="dh.name=='deviceStatus'">
                      {{deviceStatus[row[dh.name]]}}
                    </span>
                  </ng-template>
                </ngx-datatable-column>
              </ng-container>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
