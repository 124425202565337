
import { throwError as observableThrowError, Observable } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { Router } from '@angular/router';

import { environment } from '@myenv/environment';

@Injectable()
export class VehicleUploadService {

  public apiUrl = environment.apiUrl;
  private httpClient: HttpClient;
  constructor(private http: HttpClient, private router: Router, private handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }
  uploadXL(data): Observable<any> {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    return this.httpClient.post(this.apiUrl + 'devices/upload', data, { headers }).pipe(
      catchError(this.handleError));
  }

  updateXL(data): Observable<any> {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    return this.httpClient.put(this.apiUrl + 'devices/upload', data, { headers }).pipe(
      catchError(this.handleError));
  }

  deleteVehicle(deviceId): Observable<any> {

    return this.http.delete(this.apiUrl + 'devices?deviceId=' + deviceId).pipe(
      catchError(this.handleError));
  }
  getVehicles(): Observable<any> {
    return this.http.get(this.apiUrl + 'devices').pipe(
      catchError(this.handleError));
  }

  getVehicleUploadLogs(): Observable<any> {
    return this.http.get(this.apiUrl + 'vehicles/upload/logs').pipe(
      catchError(this.handleError));
  }

  createVehicle(data): Observable<any> {
    return this.http.post(this.apiUrl + 'vehicles', data).pipe(
      catchError(this.handleError));
  }

  handleError(error: any | any) {
    return observableThrowError(error.error || error.json() || error || 'Server error');
  }

}
