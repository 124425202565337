import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { SharedService } from '@myproject/shared/shared.service';
import Chart from 'chart.js';
import { GraphicReportsService } from '../../graphic-reports.service';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { environment } from '@myenv/environment';
var ProcessingTimeReportComponent = /** @class */ (function () {
    function ProcessingTimeReportComponent(sharedService, reportsService, title) {
        this.sharedService = sharedService;
        this.reportsService = reportsService;
        this.title = title;
        this.isLoading = false;
        this.vehicles = [];
        this.vehiclesLoading = false;
        this.highestCount = 0;
    }
    ProcessingTimeReportComponent.prototype.ngOnInit = function () {
        this.title.setTitle('Processing Time Report' + environment.title_text);
    };
    ProcessingTimeReportComponent.prototype.changeVehicle = function (e) {
        this.selectedVehicle = e ? e.deviceId : null;
        this.onSelectVehicle();
    };
    ProcessingTimeReportComponent.prototype.refresh = function () {
        this.onSelectVehicle();
    };
    ProcessingTimeReportComponent.prototype.onSelectVehicle = function () {
        this.isLoading = true;
        if (this.selectedVehicle === null) {
            this.getAllProcessingTime();
        }
        else {
            this.getAllProcessingTime(this.selectedVehicle);
        }
    };
    ProcessingTimeReportComponent.prototype.getAllProcessingTime = function (deviceId) {
        var _this = this;
        this.reportsService.getAllProcessingTime(deviceId).subscribe({
            next: function (res) {
                if (res.length) {
                    _this.sortLineData(res);
                }
                else {
                    _this.drawLineGraph([], [], []);
                    _this.isLoading = false;
                }
            },
            error: function (error) {
                _this.isLoading = false;
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    ProcessingTimeReportComponent.prototype.sortLineData = function (res) {
        this.highestCount = 0;
        var labels = [];
        var processingTime = [];
        var networkTime = [];
        res.reverse();
        res.map(function (item) {
            var datePipe = new DatePipe('en-US');
            var eventDate = datePipe.transform(item.eventTime, 'dd MMM hh:mm a');
            labels.push(eventDate);
            networkTime.push(item.networkTime);
            processingTime.push(item.processingTime);
        });
        this.highestCount = Math.max(Math.max.apply(Math, tslib_1.__spread(networkTime)), Math.max.apply(Math, tslib_1.__spread(processingTime)));
        this.drawLineGraph(labels, networkTime, processingTime);
        this.isLoading = false;
    };
    ProcessingTimeReportComponent.prototype.drawLineGraph = function (labels, networkTime, processingTime) {
        console.log(this.highestCount);
        if (this.lineChart) {
            this.lineChart.destroy();
        }
        var ctx = document.getElementById('lineChart');
        this.lineChart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: labels,
                datasets: [{
                        label: 'Network Time',
                        backgroundColor: 'rgb(54, 162, 235, 0.2)',
                        borderColor: 'rgb(54, 162, 235, 1)',
                        data: networkTime
                    }, {
                        label: 'Processing Time',
                        backgroundColor: 'rgb(255, 99, 132, 0.2)',
                        borderColor: 'rgb(255, 99, 132, 1)',
                        data: processingTime
                    }]
            },
            options: {
                legend: {
                    position: 'bottom',
                    display: true
                },
                scales: {
                    yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                suggestedMin: 0,
                                suggestedMax: 10,
                                stepSize: this.highestCount > 9 ? null : 1
                            }
                        }],
                    xAxes: [{
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 12
                            }
                        }]
                },
            }
        });
    };
    return ProcessingTimeReportComponent;
}());
export { ProcessingTimeReportComponent };
