<app-notify-message></app-notify-message>

<div class="support-content">
  <ul class="nav nav-tabs detailed-tabs relative page-sub-heading" role="tablist">
    <li class="nav-item active">
      <a class="nav-link text-center mt-1 active ml-4" role="tab" data-toggle="tab" href="#attributes"
         (click)="loadAccountInfo()">
        <i class="fa fa-user"></i> Account Information
      </a>
    </li>
    <li *ngIf="currentPermissionModules.includes('DEVICE-REPLACE')" class="nav-item">
      <a class="nav-link text-center mt-1" role="tab" data-toggle="tab" href="#cache"
         (click)="loadDeviceReplacement()">
        <i class="fa fa-list"></i> Device Replacement
      </a>
    </li>
    <li *ngIf="currentPermissionModules.includes('AL-VEHICLE-PROVISION')" class="nav-item">
      <a class="nav-link text-center mt-1" role="tab" data-toggle="tab" href="#provision"
         (click)="loadDeviceProvisioning()">
        <i class="fa fa-car"></i> Vehicle Provisioning
      </a>
    </li>
  </ul>
</div>
<div class="tab-content">
  <div *ngIf="currentPermissionModules.includes('DEVICE-REPLACE')" role="tabpanel" class="tab-pane fade in relative mt-3 mx-3" id="cache">
    <app-device-replacement [userData]="userData" *ngIf="deviceTab"></app-device-replacement>
  </div>
  <div *ngIf="currentPermissionModules.includes('AL-VEHICLE-PROVISION')" role="tabpanel" class="tab-pane fade in relative mx-1" id="provision">
    <app-provisioning *ngIf="provisioningTab" [userData]="userData"></app-provisioning>
  </div>
  <div role="tabpanel" class="tab-pane fade in active show relative mt-3 mx-3" id="attributes">
    <div class="container-fluid wrapper">
      <div class="back-button-container my-2">
        <a class="link-color text-bold" [routerLink]="['/support']" routerLinkActive="active" style="cursor: pointer;">
          <i class="fa fa-chevron-left"></i>
          Back
        </a>
      </div>
      <div class="container my-3 border rounded">
        <div class="row">
          <div class="col-12 border rounded">
            <div class="row py-4" *ngIf="userData">
              <div class="col-3 mt-4 text-center">
                <div class="user-img-container border">
                  <i class="fa fa-user"></i>
                  <!--<img [src]="userData.profilePicture" alt="user-image">-->
                </div>
              </div>
              <div class="col-md-5 mt-4">
                <div class="info-group my-3">
                  <div class="label mx-1">Name : </div>
                  <div class="value mx-1"> {{userData.firstName}} {{userData.lastName}} </div>
                </div>

                <div class="info-group my-3">
                  <span class="label mx-1">Email : </span>
                  <span class="value mx-1"> {{userData.emailId}} </span>
                </div>

                <div class="info-group my-3">
                  <span class="label mx-1">User Name : </span>
                  <span class="value mx-1"> {{userData.userName}} </span>
                </div>

                <div class="info-group my-3">
                  <span class="label mx-1">User Type : </span>
                  <span class="value mx-1"> {{userData.userType}} </span>
                </div>
                <div class="info-group my-3">
                  <span class="label mx-1">Customer ID : </span>
                  <span class="value mx-1"> {{userData.customerId}} </span>
                </div>
                <div class="info-group my-3">
                  <span class="label mx-1">phone :</span>
                  <span class="value mx-1"> {{userData.mobileNumber}} </span>
                </div>
                <div class="info-group my-3">
                  <span class="label mx-1">Address : </span>
                  <span class="value mx-1"> {{userData.address.address1}}, {{userData.address.city}}, {{userData.address.zip}}</span>
                </div>

                <div class="info-group my-3">
                  <span class="label mx-1">Created At :</span>
                  <span class="value mx-1"> {{userData.createdAt | date: isoDateFormat}} </span>
                </div>
              </div>
              <div class="col-md-4 mt-5">
                <div class="row">
                  <div class="col-12 text-right">
                    <button *ngIf="unlockStatus" class="btn btn-secondary unlock-btn ml-0 small-screen-btn"
                            (click)="unlockUser(userName)"><i class="fa fa-unlock text-white mx-2"></i>unlock</button>
                    <button disabled="" *ngIf="!unlockStatus"
                            style="cursor: not-allowed;"
                            class="btn btn-secondary w90 ml-0 unlock-btn small-screen-btn"><i
                      class="fa fa-unlock text-white mx-2"></i>Unlock</button>
                  </div>
                  <div class="col-12 text-right" *ngIf="permissionAuthorities && permissionAuthorities.authorities.includes('READ')">
                    <button *ngIf="userData.userType=='SUB_USER' || userData.userType=='SUPER_USER'"
                            class="btn btn-primary mt-2 small-screen-btn" (click)="onSearch()"><i
                      class="fa fa-sign-out text-white mx-2"></i>Impersonate</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</div>

