import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@myenv/environment';
import { throwError as observableThrowError } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var GraphicReportsService = /** @class */ (function () {
    function GraphicReportsService(http) {
        this.http = http;
        this.apiUrl = environment.apiUrl;
        this.d = new Date();
        this.offsetInMinutes = this.d.getTimezoneOffset() * -1;
        this.timeZone = "&dateFormat=" + environment.isoDateTimeFormat + "&offsetInMinutes=" + this.offsetInMinutes;
    }
    // To get single report
    GraphicReportsService.prototype.getSingleReportByReportId = function (reportId, deviceId, startTime, endTime, page) {
        return this.http.get(this.apiUrl + 'reports/' + reportId + '/generate?startTime=' + startTime + '&endTime=' + endTime +
            '&page=' + page + '&deviceId=' + deviceId).pipe(catchError(this.handleError));
    };
    // To get single report
    GraphicReportsService.prototype.getDeviceView = function (reportId, startTime, endTime, page, serialNumber) {
        var tempURL = this.apiUrl + ("reports/vin-change/" + reportId + "/generate?startTime=" + startTime + "&endTime=" + endTime + "&page=" + page);
        if (serialNumber) {
            tempURL += '&serialNumber=' + serialNumber;
        }
        return this.http.get(tempURL).pipe(catchError(this.handleError));
    };
    // To get Corelation chart data
    GraphicReportsService.prototype.getCorelationChartData = function (reportId, deviceId, startTime, endTime, data) {
        var httpOptions = {
            'responseType': 'arraybuffer'
        };
        return this.http.post(this.apiUrl + ("reports/" + reportId + "/dataScience/download") +
            ("?startTime=" + startTime + "&endTime=" + endTime + "&deviceId=" + deviceId), data, { responseType: 'blob' }).pipe(catchError(this.handleError));
    };
    // To get chart data
    GraphicReportsService.prototype.getChartData = function (reportId, deviceId, startTime, endTime, page, limit, data) {
        return this.http.get(this.apiUrl + ("messages/device/" + deviceId) +
            ("?startTime=" + startTime + "&endTime=" + endTime + "&page=" + page + "&limit=" + limit + "&attributes=" + data + ",eventTime")).pipe(catchError(this.handleError));
    };
    // To download reports as CSV file
    GraphicReportsService.prototype.downloadReportCSV = function (reportId, deviceId, startTime, endTime) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this.http.post(this.apiUrl + 'reports/' + reportId + '/download/token?startTime=' + startTime + '&endTime=' + endTime + '&deviceId=' + deviceId, { headers: headers }).pipe(catchError(this.handleError));
    };
    GraphicReportsService.prototype.downloadVPacketReportCSV = function (reportId) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this.http.post(this.apiUrl + 'reports/' + reportId + '/vpacket/download/token', { headers: headers }).pipe(catchError(this.handleError));
    };
    GraphicReportsService.prototype.downloadalPlantModeReport = function (reportId) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this.http.post(this.apiUrl + ("reports/" + reportId + "/alPlantMode/download/token"), { headers: headers }).pipe(catchError(this.handleError));
    };
    // To download reports as CSV file
    GraphicReportsService.prototype.multiDownloadVehicle = function (reportId, deviceId, startTime, endTime) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this.http.post(this.apiUrl + 'reports/' + reportId + '/download/token?startTime=' + startTime + '&endTime=' + endTime + '&deviceId=' + deviceId, { headers: headers }).pipe(catchError(this.handleError));
    };
    // To download reports as CSV file
    GraphicReportsService.prototype.downloadDeviceReportCSV = function (reportId, startTime, endTime, serialNumber) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        var tempURL = this.apiUrl + 'reports/vin-change/' + reportId + '/download/token?startTime=' + startTime + '&endTime=' + endTime;
        if (serialNumber) {
            tempURL += '&serialNumber=' + serialNumber;
        }
        return this.http.post(tempURL, headers).pipe(catchError(this.handleError));
    };
    // get attribute API for vehicle
    GraphicReportsService.prototype.getAttribute = function (data) {
        return this.http.get(this.apiUrl + 'reports/attribute/' + data).pipe(catchError(this.handleError));
    };
    // to get total message count
    GraphicReportsService.prototype.getMessageCount = function (reportId, deviceId, startTime, endTime) {
        return this.http.get(this.apiUrl + 'reports/' + reportId + '/count?startTime=' + startTime + '&endTime=' + endTime + '&deviceId=' + deviceId).pipe(catchError(this.handleError));
    };
    // to get total message count
    GraphicReportsService.prototype.getDeviceMeessageCount = function (reportId, startTime, endTime, serialNumber) {
        var tempURL = this.apiUrl + 'reports/vin-change/' + reportId + '/count?startTime=' + startTime + '&endTime=' + endTime;
        if (serialNumber) {
            tempURL += '&serialNumber=' + serialNumber;
        }
        return this.http.get(tempURL).pipe(catchError(this.handleError));
    };
    // All api for provisioning report
    GraphicReportsService.prototype.getProvisioningData = function (startTime, endTime) {
        return this.http.get(this.apiUrl + ("analytics/provisioning/trend?startTime=" + startTime + "&endTime=" + endTime))
            .pipe(catchError(this.handleError));
    };
    GraphicReportsService.prototype.getInstalledDevices = function (startTime, endTime, page) {
        return this.http.get(this.apiUrl +
            ("devices?startTime=" + startTime + "&endTime=" + endTime) +
            ("&page=" + page + "&limit=100"))
            .pipe(catchError(this.handleError));
    };
    GraphicReportsService.prototype.getRemovedDevices = function (startTime, endTime, page) {
        return this.http.get(this.apiUrl +
            ("devices/removed?startTime=" + startTime + "&endTime=" + endTime) +
            ("&page=" + page + "&limit=100"))
            .pipe(catchError(this.handleError));
    };
    GraphicReportsService.prototype.getTotalDevices = function (startTime, endTime, page) {
        return this.http.get(this.apiUrl +
            ("devices?startTime=" + startTime + "&endTime=" + endTime) +
            ("&page=" + page + "&limit=100"))
            .pipe(catchError(this.handleError));
    };
    GraphicReportsService.prototype.getTotalDevicesCount = function () {
        return this.http.get(this.apiUrl + "devices/loggedin/count")
            .pipe(catchError(this.handleError));
    };
    GraphicReportsService.prototype.downloadInstalledDevices = function (startTime, endTime, attributes) {
        return this.http.get(this.apiUrl + "devices/installed/download" +
            ("?startTime=" + startTime + "&endTime=" + endTime + "&attributes=" + attributes) +
            ("" + this.timeZone), { responseType: 'arraybuffer', observe: 'response' })
            .pipe(catchError(this.handleError));
    };
    // To download reports as CSV file
    GraphicReportsService.prototype.downloadHeatMapCSV = function (startTime, endTime) {
        return this.http.get(this.apiUrl + 'analytics/heat-map-report/download' +
            ("?startDate=" + startTime + "&endDate=" + endTime), { responseType: 'arraybuffer', observe: 'response' }).pipe(catchError(this.handleError));
    };
    GraphicReportsService.prototype.downloadRemovedDevices = function (startTime, endTime, attributes) {
        return this.http.get(this.apiUrl + "devices/removed/download" +
            ("?startTime=" + startTime + "&endTime=" + endTime + "&attributes=" + attributes) +
            ("" + this.timeZone), { responseType: 'arraybuffer', observe: 'response' })
            .pipe(catchError(this.handleError));
    };
    GraphicReportsService.prototype.downloadTotalDevices = function (startTime, endTime, attributes) {
        return this.http.get(this.apiUrl + "devices/download" +
            ("?startTime=" + startTime + "&endTime=" + endTime + "&attributes=" + attributes) +
            ("" + this.timeZone), { responseType: 'arraybuffer', observe: 'response' })
            .pipe(catchError(this.handleError));
    };
    // All api for version drilldown(Software version page)
    GraphicReportsService.prototype.getFirmwareCount = function (startTime, endTime) {
        return this.http.get(this.apiUrl + ("devices/firmware/count?startTime=" + startTime + "&endTime=" + endTime))
            .pipe(catchError(this.handleError));
    };
    GraphicReportsService.prototype.getConfigCount = function (startTime, endTime, firmware) {
        return this.http.get(this.apiUrl + ("devices/config/count?firmwareVersion=" + firmware + "&startTime=" + startTime + "&endTime=" + endTime))
            .pipe(catchError(this.handleError));
    };
    GraphicReportsService.prototype.getFwConfigTrendData = function (startTime, endTime, firmware, config) {
        return this.http.get(this.apiUrl +
            "analytics/fw/config/count" +
            ("?configVersion=" + config + "&firmwareVersion=" + firmware + "&startTime=" + startTime + "&endTime=" + endTime))
            .pipe(catchError(this.handleError));
    };
    GraphicReportsService.prototype.getFwConfigDevices = function (startTime, endTime, firmware, config, page) {
        return this.http.get(this.apiUrl +
            "devices/firmware/config" +
            ("?configVersion=" + config + "&firmwareVersion=" + firmware + "&startTime=" + startTime + "&endTime=" + endTime) +
            ("&page=" + page + "&limit=100"))
            .pipe(catchError(this.handleError));
    };
    GraphicReportsService.prototype.downloadFwConfigDevices = function (startTime, endTime, firmware, config, attributes) {
        return this.http.get(this.apiUrl +
            "devices/firmware/config/download" +
            ("?configVersion=" + config + "&firmwareVersion=" + firmware + "&startTime=" + startTime + "&endTime=" + endTime) +
            ("&attributes=" + attributes) +
            ("" + this.timeZone), { responseType: 'arraybuffer', observe: 'response' })
            .pipe(catchError(this.handleError));
    };
    // All api for not-communicating/ communicating report
    GraphicReportsService.prototype.getAllNotCommCount = function (startTime, endTime, type) {
        return this.http.get(this.apiUrl +
            "devices/not-communicating/count" +
            ("?startTime=" + startTime + "&endTime=" + endTime + "&type=" + type))
            .pipe(catchError(this.handleError));
    };
    GraphicReportsService.prototype.getAllCommCount = function (startTime, endTime, type) {
        return this.http.get(this.apiUrl +
            "devices/communicating/count" +
            ("?startTime=" + startTime + "&endTime=" + endTime + "&type=" + type))
            .pipe(catchError(this.handleError));
    };
    GraphicReportsService.prototype.getNotCommTrendData = function (startTime, endTime, type, value) {
        return this.http.get(this.apiUrl +
            "analytics/devices/not-communicating/trend" +
            ("?startTime=" + startTime + "&endTime=" + endTime + "&type=" + type + "&value=" + value))
            .pipe(catchError(this.handleError));
    };
    GraphicReportsService.prototype.getCommTrendData = function (startTime, endTime, type, value) {
        return this.http.get(this.apiUrl +
            "analytics/devices/communicating/trend" +
            ("?startTime=" + startTime + "&endTime=" + endTime + "&type=" + type + "&value=" + value))
            .pipe(catchError(this.handleError));
    };
    GraphicReportsService.prototype.getAllNotCommDevices = function (startTime, endTime, page) {
        return this.http.get(this.apiUrl +
            "devices/not-communicating" +
            ("?startTime=" + startTime + "&endTime=" + endTime + "&page=" + page + "&limit=100"))
            .pipe(catchError(this.handleError));
    };
    GraphicReportsService.prototype.getAllCommDevices = function (startTime, endTime, page) {
        return this.http.get(this.apiUrl +
            "devices/communicating" +
            ("?startTime=" + startTime + "&endTime=" + endTime + "&page=" + page + "&limit=100"))
            .pipe(catchError(this.handleError));
    };
    GraphicReportsService.prototype.downloadAllNotCommDevices = function (startTime, endTime, attributes) {
        return this.http.get(this.apiUrl +
            "devices/not-communicating/download" +
            ("?startTime=" + startTime + "&endTime=" + endTime + "&attributes=" + attributes) +
            ("" + this.timeZone), { responseType: 'arraybuffer', observe: 'response' })
            .pipe(catchError(this.handleError));
    };
    GraphicReportsService.prototype.downloadAllCommDevices = function (startTime, endTime, attributes) {
        return this.http.get(this.apiUrl +
            "devices/communicating/download" +
            ("?startTime=" + startTime + "&endTime=" + endTime + "&attributes=" + attributes) +
            ("" + this.timeZone), { responseType: 'arraybuffer', observe: 'response' })
            .pipe(catchError(this.handleError));
    };
    // common function to download file from graphic reports table
    GraphicReportsService.prototype.downloadFileReportsTable = function (startTime, endTime, attributes, url, firmware, config) {
        var modUrl = url === 'devices/firmware/config/download' ? url + ("?configVersion=" + config + "&firmwareVersion=" + firmware + "&startTime=" + startTime + "&endTime=" + endTime + "&attributes=" + attributes) + ("" + this.timeZone)
            : url + ("?startTime=" + startTime + "&endTime=" + endTime + "&attributes=" + attributes) + ("" + this.timeZone);
        return this.http.get(this.apiUrl + modUrl, { responseType: 'arraybuffer', observe: 'response' }).pipe(catchError(this.handleError));
    };
    // All api for usage report
    GraphicReportsService.prototype.getUsageData = function (startTime, endTime) {
        return this.http.get(this.apiUrl + ("analytics/usage/device/active?startTime=" + startTime + "&endTime=" + endTime))
            .pipe(catchError(this.handleError));
    };
    GraphicReportsService.prototype.getUsagePlanName = function () {
        return this.http.get(this.apiUrl + "analytics/usage/device/plan")
            .pipe(catchError(this.handleError));
    };
    // Log in/out activity report APIs
    GraphicReportsService.prototype.getLogInOutActivity = function (userId, startTime, endTime, page) {
        return this.http.get(this.apiUrl +
            ("activity/loginActivity/user/" + userId + "?startTime=" + startTime + "&endTime=" + endTime) +
            ("&page=" + page + "&limit=100"))
            .pipe(catchError(this.handleError));
    };
    GraphicReportsService.prototype.downloadLogInOutActivity = function (userId, startTime, endTime) {
        return this.http.get(this.apiUrl +
            ("activity/loginActivity/download/user/" + userId + "?startTime=" + startTime + "&endTime=" + endTime), { responseType: 'arraybuffer', observe: 'response' })
            .pipe(catchError(this.handleError));
    };
    // Vehicle Message report APIs
    GraphicReportsService.prototype.getVehicleMessageData = function (deviceId, startTime, endTime) {
        return this.http.get(this.apiUrl +
            "analytics/device/message/count" +
            ("?deviceId=" + deviceId + "&startDate=" + startTime + "&endDate=" + endTime))
            .pipe(catchError(this.handleError));
    };
    // To get the total message count per day
    GraphicReportsService.prototype.getDeviceTotalMessageCount = function (deviceId, startTime, endTime) {
        return this.http.get(this.apiUrl +
            "analytics/device/total-message/count" +
            ("?deviceId=" + deviceId + "&startDate=" + startTime + "&endDate=" + endTime))
            .pipe(catchError(this.handleError));
    };
    // Message Report CSV download
    GraphicReportsService.prototype.getMessageDataCSV = function (deviceId, start, end) {
        return this.http.get(this.apiUrl + "analytics/device/message/download" +
            ("?deviceId=" + deviceId + "&startDate=" + start + "&endDate=" + end), {
            responseType: 'arraybuffer', observe: 'response'
        }).pipe(catchError(this.handleError));
    };
    // Trip summary report csv download
    GraphicReportsService.prototype.getTrips = function (deviceId, startTime, endTime, page) {
        return this.http.get(this.apiUrl + ("trips/device/" + deviceId) +
            ("?startTime=" + startTime + "&endTime=" + endTime + "&page=" + page + "&limit=100&sort=asc"))
            .pipe(catchError(this.handleError));
    };
    GraphicReportsService.prototype.downloadTripSummary = function (deviceId, startTime, endTime) {
        return this.http.get(this.apiUrl +
            ("trips/device/" + deviceId + "/download?startTime=" + startTime + "&endTime=" + endTime + "&offset=" + this.offsetInMinutes), { responseType: 'arraybuffer', observe: 'response' })
            .pipe(catchError(this.handleError));
    };
    // Data Destination report APIs
    GraphicReportsService.prototype.getMessagesPublished = function (deviceId) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
        var tempUrl = this.apiUrl + "analytics/messages/pushed";
        if (deviceId) {
            tempUrl += "?deviceId=" + deviceId;
        }
        return this.http.get(tempUrl, { headers: headers })
            .pipe(catchError(this.handleError));
    };
    GraphicReportsService.prototype.getAllProcessingTime = function (deviceId, startTime, endTime) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
        var tempUrl = this.apiUrl + "analytics/message/processing-time";
        if (deviceId && !startTime) {
            tempUrl += "?deviceId=" + deviceId;
        }
        else if (deviceId && startTime && endTime) {
            tempUrl += "?deviceId=" + deviceId + "&startTime=" + startTime + "&endTime=" + endTime;
        }
        return this.http.get(tempUrl, { headers: headers })
            .pipe(catchError(this.handleError));
    };
    GraphicReportsService.prototype.getPublishedTime = function (deviceId) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
        var tempUrl = this.apiUrl + "analytics/device/processing";
        if (deviceId) {
            tempUrl += "?deviceId=" + deviceId;
        }
        return this.http.get(tempUrl, { headers: headers })
            .pipe(catchError(this.handleError));
    };
    // Live ststus report API
    GraphicReportsService.prototype.getLiveStatus = function (groupId) {
        var tempUrl = this.apiUrl + "devices/live/stats";
        if (groupId) {
            tempUrl += "?groupId=" + groupId;
        }
        return this.http.get(tempUrl)
            .pipe(catchError(this.handleError));
    };
    GraphicReportsService.prototype.getStats = function (startTime, endTime) {
        return this.http.get(this.apiUrl +
            ("analytics/status/count?startTime=" + startTime + "&endTime=" + endTime))
            .pipe(catchError(this.handleError));
    };
    GraphicReportsService.prototype.getDeviceCounts = function () {
        return this.http.get(this.apiUrl + "analytics/customer/message/count")
            .pipe(catchError(this.handleError));
    };
    GraphicReportsService.prototype.getFotaStats = function (start, end) {
        return this.http.get(this.apiUrl +
            ("campaigns/active/count?startDate=" + start + "&endDate=" + end))
            .pipe(catchError(this.handleError));
    };
    GraphicReportsService.prototype.getDevicesWithStatus = function (page, status, groupId) {
        var tempUrl = this.apiUrl + ("devices?status=" + status + "&page=" + page + "&limit=100");
        if (groupId) {
            tempUrl += "&groups=" + groupId;
        }
        return this.http.get(tempUrl).pipe(catchError(this.handleError));
    };
    // C & S Trigger Report APIs
    GraphicReportsService.prototype.getAllStatusCount = function (type, customerId, start, end) {
        var tempUrl = "al/vehicles/provisioning/statusCount/requestType/" + type + "/customerId/" + customerId;
        if (start && end) {
            tempUrl += "?startTime=" + start + "&endTime=" + end;
        }
        return this.http.get(this.apiUrl + tempUrl).pipe(catchError(this.handleError));
    };
    GraphicReportsService.prototype.getRequestCounts = function (type, status, customerId, start, end) {
        var tempUrl = "al/vehicles/provisioning/daysCount/requestType/" + type + "/customerId/" + customerId;
        if (status !== 'all') {
            var char = tempUrl.includes('?') ? '&' : '?';
            tempUrl += char + "status=" + status;
        }
        if (start && end) {
            var char = tempUrl.includes('?') ? '&' : '?';
            tempUrl += char + "startTime=" + start + "&endTime=" + end;
        }
        return this.http.get(this.apiUrl + tempUrl).pipe(catchError(this.handleError));
    };
    GraphicReportsService.prototype.getProvisioningErrorCounts = function (type, status, customerId, start, end) {
        var tempUrl = "al/vehicles/provisioning/errorCategory?requestType=" + type;
        if (start && end) {
            tempUrl += "&startTime=" + start + "&endTime=" + end;
        }
        if (customerId) {
            tempUrl += "&customerId=" + customerId;
        }
        // if (status !== 'all') {
        //   tempUrl += `&status=${status}`;
        // }
        return this.http.get(this.apiUrl + tempUrl).pipe(catchError(this.handleError));
    };
    GraphicReportsService.prototype.getAvgTimeTaken = function (type, status, customerId, start, end) {
        var tempUrl = "al/vehicles/provisioning/averageTimeTaken/requestType/" + type + "/customerId/" + customerId;
        if (status !== 'all') {
            var char = tempUrl.includes('?') ? '&' : '?';
            tempUrl += char + "status=" + status;
        }
        if (start && end) {
            var char = tempUrl.includes('?') ? '&' : '?';
            tempUrl += char + "startTime=" + start + "&endTime=" + end;
        }
        return this.http.get(this.apiUrl + tempUrl).pipe(catchError(this.handleError));
    };
    GraphicReportsService.prototype.getDevicesWithFilter = function (page, type, status, customerId, start, end) {
        var tempUrl = "al/vehicles/provisioning/requestType/" + type + "/customerId/" + customerId + "?page=" + page;
        if (status !== 'all') {
            tempUrl += "&status=" + status;
        }
        if (start && end) {
            var char = tempUrl.includes('?') ? '&' : '?';
            tempUrl += char + "startTime=" + start + "&endTime=" + end;
        }
        return this.http.get(this.apiUrl + tempUrl).pipe(catchError(this.handleError));
    };
    GraphicReportsService.prototype.downloadCTriggerDevicesCSV = function (status, customerId, start, end) {
        var tempUrl = "al/vehicles/provisioning/download-c-trigger";
        if (status !== 'all') {
            var char = tempUrl.includes('?') ? '&' : '?';
            tempUrl += char + "status=" + status;
        }
        if (customerId) {
            var char = tempUrl.includes('?') ? '&' : '?';
            tempUrl += char + "customerId=" + customerId;
        }
        if (start && end) {
            var char = tempUrl.includes('?') ? '&' : '?';
            tempUrl += char + "startTime=" + start + "&endTime=" + end;
        }
        return this.http.get(this.apiUrl + tempUrl, { responseType: 'arraybuffer', observe: 'response' })
            .pipe(catchError(this.handleError));
    };
    GraphicReportsService.prototype.downloadSTriggerDevicesCSV = function (status, customerId, start, end) {
        var tempUrl = "al/vehicles/provisioning/download-s-trigger";
        if (status !== 'all') {
            var char = tempUrl.includes('?') ? '&' : '?';
            tempUrl += char + "status=" + status;
        }
        if (customerId) {
            var char = tempUrl.includes('?') ? '&' : '?';
            tempUrl += char + "customerId=" + customerId;
        }
        if (start && end) {
            var char = tempUrl.includes('?') ? '&' : '?';
            tempUrl += char + "startTime=" + start + "&endTime=" + end;
        }
        return this.http.get(this.apiUrl + tempUrl, { responseType: 'arraybuffer', observe: 'response' })
            .pipe(catchError(this.handleError));
    };
    // APIs for billing reports
    GraphicReportsService.prototype.getProvisionBillingData = function (months, customerId) {
        var tempUrl = "billing/provisioning/report?numberOfMonth=" + months;
        if (customerId) {
            tempUrl += "&customerId=" + customerId;
        }
        return this.http.get(this.apiUrl + tempUrl).pipe(catchError(this.handleError));
    };
    GraphicReportsService.prototype.downloadMonthlyBill = function (month, year, customerId) {
        var tempUrl = "billing/provisioning/report/download?year=" + year + "&month=" + month;
        if (customerId) {
            tempUrl += "&customerId=" + customerId;
        }
        return this.http.get(this.apiUrl + tempUrl, { responseType: 'arraybuffer', observe: 'response' }).pipe(catchError(this.handleError));
    };
    // for packet history report
    GraphicReportsService.prototype.downloadPacketHistoryCSV = function (deviceId, startTime, endTime, messageType) {
        var tempUrl = "messages/device/" + deviceId + "/packet-history/download?startTime=" + startTime + "&endTime=" + endTime + this.timeZone;
        if (messageType)
            tempUrl += "&messageType=" + messageType;
        return this.http.get(this.apiUrl + tempUrl, { responseType: 'arraybuffer', observe: 'response' })
            .pipe(catchError(this.handleError));
    };
    GraphicReportsService.prototype.handleError = function (error) {
        return observableThrowError(error.error || error.json() || error || 'Server error');
    };
    // Get BitBrew Usage Stats
    GraphicReportsService.prototype.getUsageStats = function (customerId) {
        var tempUrl = "analytics/device/usage/stats";
        if (customerId) {
            tempUrl += "?customerId=" + customerId;
        }
        return this.http.get(this.apiUrl + tempUrl)
            .pipe(catchError(this.handleError));
    };
    // Get BitBrew Usage Stats
    GraphicReportsService.prototype.getUsageByMonthYear = function (year, month, page) {
        //  </div>https://api.dev.bitbrew.com/v1/analytics/device/usage/data/year/2022/month/3?page=0
        return this.http.get(this.apiUrl +
            ("analytics/device/usage/data/year/" + year + "/month/" + month + "?page=" + page))
            .pipe(catchError(this.handleError));
    };
    // Get BitBrew Usage Stats Count
    GraphicReportsService.prototype.getUsageByMonthYearCount = function (year, month) {
        return this.http.get(this.apiUrl +
            ("analytics/device/usage/data/count/year/" + year + "/month/" + month))
            .pipe(catchError(this.handleError));
    };
    //Download BitBrew Usage CSV file
    GraphicReportsService.prototype.downloadDeviceCSV = function (year, month) {
        // https://api.dev.bitbrew.com/v1/analytics/download/device/usage/year/2022/month/3?offsetInMinutes=330
        var tempUrl = this.apiUrl + ("analytics/download/device/usage/year/" + year + "/month/" + month + "?offsetInMinutes=" + this.offsetInMinutes);
        return this.http.get(tempUrl, { responseType: 'arraybuffer', observe: 'response' }).pipe(catchError(this.handleError));
    };
    // get total count of Vehicle/Device
    GraphicReportsService.prototype.getTotalCountOfAsset = function (customerId) {
        var tempUrl = 'devices/count/key/assetType';
        if (customerId !== undefined) {
            tempUrl += "/" + customerId;
        }
        return this.http.get(this.apiUrl + tempUrl).pipe(catchError(this.handleError));
    };
    // get Account status
    GraphicReportsService.prototype.getAccountStatus = function (customerId) {
        //customerId = 133
        return this.http.get(this.apiUrl + ("customers/" + customerId)).pipe(catchError(this.handleError));
    };
    // get total messages count
    GraphicReportsService.prototype.getTotalMessagesCount = function (customerId) {
        var tempUrl = 'destination/scheme/getMessageCount';
        if (customerId !== undefined) {
            tempUrl += "?customerId=" + customerId;
        }
        return this.http.get(this.apiUrl + tempUrl).pipe(catchError(this.handleError));
    };
    // Live ststus report API
    GraphicReportsService.prototype.getAzugaLiveStatus = function (groupId) {
        var tempUrl = this.apiUrl + "devices/live/stats/counts";
        if (groupId) {
            tempUrl += "?groupId=" + groupId;
        }
        return this.http.get(tempUrl)
            .pipe(catchError(this.handleError));
    };
    GraphicReportsService.prototype.getDevicesWithLiveStatusType = function (page, liveStatusType, groupId) {
        if (liveStatusType === 14) {
            liveStatusType = "Communicating";
        }
        else if (liveStatusType === 15) {
            liveStatusType = "NotCommunicating";
        }
        else if (liveStatusType === 16) {
            liveStatusType = "Disconnected";
        }
        else if (liveStatusType === 3) {
            liveStatusType = "NeverCommunicating";
        }
        var tempUrl = this.apiUrl + ("devices?liveStatusType=" + liveStatusType + "&page=" + page + "&limit=100");
        if (groupId) {
            tempUrl += "&groups=" + groupId;
        }
        return this.http.get(tempUrl).pipe(catchError(this.handleError));
    };
    GraphicReportsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GraphicReportsService_Factory() { return new GraphicReportsService(i0.ɵɵinject(i1.HttpClient)); }, token: GraphicReportsService, providedIn: "root" });
    return GraphicReportsService;
}());
export { GraphicReportsService };
