import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from "@myenv/environment";
declare var require: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  buildInfo:any;
  supportNumber:any;
  footerImageUrl = environment.cmImageUrl;
  public footerText = environment.footerText;
  footerLeft = environment.footerLeft;
  footerRight = environment.footerRight;
  footerText1 = environment.footerText1;
  footerText2 = environment.footerText2;
  footerText3 = environment.footerText3;
  accountKey: string;
  randomNumber = Math.random();
  changeImg = true;
  versionnumber = environment.version;

  constructor(private http :HttpClient) { }

  ngOnInit() {
    // const content = require('./../../../assets/version.json');
    // this.http.get('assets/version.txt', {responseType: 'text'})
    // .subscribe(data => {
    //   this.buildInfo = data;
    // });
    const autogenerateversion = require('./../../../assets/buildversion.json');
    this.buildInfo = autogenerateversion.buildversion;
    // this.buildInfo = content.buildInfo;
    this.supportNumber = environment.supportNumber;
    var data = localStorage.getItem('currentUser');
    var x = JSON.parse(data);
    this.accountKey = x.accountKey;
  }

}
