import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '@myenv/environment';

@Component({
  selector: 'app-communicating-calendar-report',
  templateUrl: './communicating-calendar-report.component.html',
  styleUrls: ['./communicating-calendar-report.component.scss']
})
export class CommunicatingCalendarReportComponent implements OnInit {
  querySelectDevice = null;
  selectedDevice: {} = null;
  isLoading = false;
  refresh = false;

  constructor(
    private title: Title,
  ) { }

  ngOnInit() {
    this.title.setTitle('Device Timeline' + environment.title_text);
  }

  changeDevice(e) {
    // if (localStorage.getItem('reportsVehicle') && this.querySelectDevice == null) {
    //   this.setItem();
    // } else {
    //   localStorage.setItem('reportsVehicle', JSON.stringify(e));
    //   this.selectedDevice = e;
    // }
    this.selectedDevice = e ? e :null;
    this.querySelectDevice = e ? e :null;
  }

  setItem() {
    let reportsItem = JSON.parse(localStorage.getItem('reportsVehicle'));
    this.querySelectDevice = reportsItem;
    this.selectedDevice = reportsItem;
  }

}
