import { Component, OnInit, enableProdMode } from '@angular/core';
import Chart from 'chart.js';
import { GraphicReportsService } from '@components/graphic-reports/graphic-reports.service';
import { SharedService } from '@myproject/shared/shared.service';
import { DatePipe } from '@angular/common';
import * as FileSaver from 'file-saver';
import {Title} from '@angular/platform-browser';
import { environment } from '@myenv/environment';

@Component({
  selector: 'app-provisioning-report',
  templateUrl: './provisioning-report.component.html',
  styleUrls: ['./provisioning-report.component.scss']
})
export class ProvisioningReportComponent implements OnInit {
  page = 0;
  isLoading = false;
  startTime: number;
  endTime: number;
  total = 0;
  installed = 0;
  removed = 0;
  installedDevices: any[] = [];
  removedDevices: any[] = [];
  totalDevices: any[] = [];
  showDevices: any[] = [];
  text = '';
  lineChart: any;
  barChart1: any;
  barChart2: any;
  stackedChart: any;
  resetOffset = false;
  hideCalendar = false;
  blockCall = false;
  highestCount = 0;

  constructor(
    private reportsService: GraphicReportsService,
    private title: Title,
    private sharedService: SharedService
  ) { }

  ngOnInit() {
    this.title.setTitle('Provisioning'+ environment.title_text);
    this.getTotalDevicesCount();
  }

  onTimeChange(e) {
    // this.total = 0;
    this.installed = 0;
    this.removed = 0;
    this.page = 0;
    this.highestCount = 0;
    this.startTime = e.startTime;
    this.endTime = e.endTime;
    this.resetOffset = !this.resetOffset;
    this.getProvisioningData(e.startTime, e.endTime);
  }

  refresh() {
    this.installed = 0;
    this.removed = 0;
    this.page = 0;
    this.highestCount = 0;
    this.resetOffset = !this.resetOffset;
    this.getProvisioningData(this.startTime, this.endTime);
  }

  getTotalDevicesCount() {
    this.reportsService.getTotalDevicesCount().subscribe({
      next: res => {
        if (res.count) {
          this.total = res.count;
        } else {
          this.total = 0;
        }
      },
      error: error => {
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  updateInstalledCount(e) {
    this.installed = e;
  }

  getProvisioningData(startTime, endTime) {
    this.isLoading = true;
    this.reportsService.getProvisioningData(startTime, endTime).subscribe({
      next: res => {
        if (res.length) {
          const labels = [];
          const total = [];
          const installed = [];
          const removed = [];
          // this.total = res[0].total;
          res.reverse();
          res.map(item => {
            const datePipe = new DatePipe('en-US');
            const eventDate = datePipe.transform(item.day, 'dd MMM');
            labels.push(eventDate);
            total.push(item.total);
            installed.push(item.added);
            removed.push(item.removed);
            this.installed += item.added;
            this.removed += item.removed;
            if (item.added > this.highestCount) {
              this.highestCount = item.added;
            }
            if (item.removed > this.highestCount) {
              this.highestCount = item.removed;
            }
          });
          if (this.installed !== 0) {
            this.getInstalledDevices(startTime, endTime, this.page);
          } else {
            this.installedDevices = [];
            this.showDevices = [];
            this.text = 'Installed Devices';
          }
          this.drawLineGraph(labels, total);
          this.drawBarGraph(labels, installed, 'barChartInstalled', 'rgba(54, 162, 235)', 'Installed');
          this.drawBarGraph(labels, removed, 'barChartRemoved', 'rgba(255, 99, 132)', 'Removed');
          this.drawStackedBarGraph(labels, installed, removed);
        } else {
          // this.total = 0;
          this.installed = 0;
          this.removed = 0;
          this.installedDevices = [];
          this.removedDevices = [];
          this.totalDevices = [];
          this.showDevices = [];
          this.text = 'Installed Devices';
          this.drawLineGraph([], []);
          this.drawBarGraph([], [], 'barChartInstalled', 'rgba(54, 162, 235)', 'Installed');
          this.drawBarGraph([], [], 'barChartRemoved', 'rgba(255, 99, 132)', 'Removed');
          this.drawStackedBarGraph([], [], []);
        }
        this.isLoading = false;
      },
      error: error => {
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  getInstalledDevices(startTime, endTime, page) {
    this.reportsService.getInstalledDevices(startTime, endTime, page).subscribe({
      next: res => {
        if (!res.length) { this.blockCall = true; }
        if (page === 0) {
          this.installedDevices = res;
        } else {
          this.installedDevices = [...this.installedDevices, ...res];
        }
        this.text = 'Installed Devices';
        this.showDevices = this.installedDevices;
      },
      error: error => {
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  getRemovedDevices(startTime, endTime, page) {
    this.reportsService.getRemovedDevices(startTime, endTime, page).subscribe({
      next: res => {
        if (!res.length) { this.blockCall = true; }
        if (page === 0) {
          this.removedDevices = res;
        } else {
          this.removedDevices = [...this.removedDevices, ...res];
        }
        this.text = 'Removed Devices';
        this.showDevices = this.removedDevices;
      },
      error: error => {
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  getTotalDevices(startTime, endTime, page) {
    this.reportsService.getTotalDevices(startTime, endTime, page).subscribe({
      next: res => {
        if (!res.length) { this.blockCall = true; }
        if (page === 0) {
          this.totalDevices = res;
        } else {
          this.totalDevices = [...this.totalDevices, ...res];
        }
        this.text = 'Total Devices';
        this.showDevices = this.totalDevices;
      },
      error: error => {
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  downloadInstalledDevices(attributes) {
    this.reportsService.downloadInstalledDevices(this.startTime, this.endTime, attributes).subscribe({
      next: res => {
        this.saveFile(res);
      },
      error: error => {
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  downloadRemovedDevices(attributes) {
    this.reportsService.downloadRemovedDevices(this.startTime, this.endTime, attributes).subscribe({
      next: res => {
        this.saveFile(res);
      },
      error: error => {
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  downloadTotalDevices(attributes) {
    this.reportsService.downloadTotalDevices(this.startTime, this.endTime, attributes).subscribe({
      next: res => {
        this.saveFile(res);
      },
      error: error => {
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  getPreferredDevices(e) {
    this.page = 0;
    this.resetOffset = !this.resetOffset;
    this.blockCall = false;
    if (e === 'installed') {
      if (this.installed !== 0) {
        this.getInstalledDevices(this.startTime, this.endTime, this.page);
      }
    } else if (e === 'removed') {
      if (this.removed !== 0) {
        this.getRemovedDevices(this.startTime, this.endTime, this.page);
      }
    } else if (e === 'total') {
      if (this.total !== 0) {
        this.getTotalDevices(this.startTime, this.endTime, this.page);
      }
    }
  }

  downloadFile(attributes) {
    if (this.text === 'Installed Devices') {
      this.downloadInstalledDevices(attributes);
    } else if (this.text === 'Removed Devices') {
      this.downloadRemovedDevices(attributes);
    } else if (this.text === 'Total Devices') {
      this.downloadTotalDevices(attributes);
    }
  }

  onPageChange(page) {
    this.page = page;
    if (this.text === 'Installed Devices') {
      this.getInstalledDevices(this.startTime, this.endTime, page);
    } else if (this.text === 'Removed Devices') {
      this.getRemovedDevices(this.startTime, this.endTime, page);
    } else if (this.text === 'Total Devices') {
      this.getTotalDevices(this.startTime, this.endTime, page);
    }
  }

  saveFile(res) {
    const contentDisposition = res.headers.get('Content-Disposition');
    let filename;
    if (contentDisposition == null) {
      const date = new Date();
      filename = 'Device_' + date + '.zip';
    } else {
      filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
    }
    const blob = new Blob([res.body], {
      type: 'application/zip'
    });
    FileSaver.saveAs(blob, filename);
  }

  drawLineGraph(labels, data) {
    if (this.lineChart) {
      this.lineChart.destroy();
    }
    var ctx = document.getElementById('lineChartTotal');
    this.lineChart = new Chart(ctx, {
      type: 'line',
      responsive: true,
      data: {
        labels: labels,
        datasets: [{
          label: 'Total',
          data: data,
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgba(54, 162, 235, 1)',
          fill: true,
          borderWidth: 2,
          pointRadius: 1
        }
        ]
      },
      options: {
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            display: false,
            ticks: {
              beginAtZero: true
            }
          }],
          yAxes: [{
            display: false,
          }]
        }
      }
    });
  }

  drawBarGraph(labels, data, container, color, chartLabel) {
    if (container === 'barChartInstalled' && this.barChart1) {
      this.barChart1.destroy();
    } else if (container === 'barChartRemoved' && this.barChart2) {
      this.barChart2.destroy();
    }
    var ctx = document.getElementById(container);
    var myChart = new Chart(ctx, {
      type: 'bar',
      responsive: true,
      data: {
        labels: labels,
        datasets: [{
          label: chartLabel,
          data: data,
          backgroundColor: color
        }]
      },
      options: {
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            display: false,
            ticks: {
              beginAtZero: true
            }
          }],
          yAxes: [{
            display: false,
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    });
    if (container === 'barChartInstalled') {
      this.barChart1 = myChart;
    } else {
      this.barChart2 = myChart;
    }
  }

  drawStackedBarGraph(labels, data1, data2) {
    if (this.stackedChart) {
      this.stackedChart.destroy();
    }
    const data = [{
      label: 'Installed',
      data: data1,
      backgroundColor: 'rgba(54, 162, 235)'
    }, {
      label: 'Removed',
      data: data2,
      backgroundColor: 'rgba(255, 99, 132)'
    }];
    var ctx = document.getElementById('stackedBarGraph');
    this.stackedChart = new Chart(ctx, {
      type: 'bar',
      responsive: true,
      data: {
        labels: labels,
        datasets: data
      },
      options: {
        legend: {
          position: 'bottom'
        },
        scales: {
          xAxes: [{
            display: true,
            stacked: true
          }],
          yAxes: [{
            display: true,
            stacked: true,
            ticks: {
              suggestedMin: 0,
              suggestedMax: 10,
              stepSize: this.highestCount > 9 ? null : 1
            }
          }]
        }
      }
    });
  }
}
