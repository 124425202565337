import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
var DataTabHeaderComponent = /** @class */ (function () {
    function DataTabHeaderComponent(router, route, translate) {
        this.router = router;
        this.route = route;
        this.translate = translate;
        this.menu_title = '';
        this.menu_subtitle = '';
        this.routeParam = [];
        this.tabPos = 0;
    }
    DataTabHeaderComponent.prototype.ngOnInit = function () {
        this.routeParam = (this.router.url).split('/');
        this.modules = JSON.parse(localStorage.getItem('accessRoleModules'));
        this.permissionmodules = JSON.parse(localStorage.getItem('permissionModules'));
    };
    DataTabHeaderComponent.prototype.ngOnChanges = function () {
        // this.permissionmodules = JSON.parse(localStorage.getItem('permissionModules'));
        // const url = this.router.url;
        // if (url.includes('destinations')) {
        //   this.tabPos = 1;
        // } else if (url.includes('data-simulator')) {
        //   this.tabPos = 2;
        // } else if (url.includes('live') || url.includes('trips') || url.includes('location-history') || url.includes('/data/messages')) {
        //   this.tabPos = 0;
        // }
    };
    DataTabHeaderComponent.prototype.goToTrips = function (p1) {
        var _this = this;
        this.router.navigateByUrl('/*', { skipLocationChange: true }).then(function () {
            _this.router.navigate(["/data/" + p1]);
        });
    };
    DataTabHeaderComponent.prototype.checkLinkActive = function () {
        var url = this.router.url;
        if (url.includes('destinations')) {
            this.tabPos = 1;
            return true;
        }
        else if (url.includes('data-simulator')) {
            this.tabPos = 2;
            return true;
        }
        else if (url.includes('live') || url.includes('trips') || url.includes('location-history') || url.includes('/data/messages')) {
            this.tabPos = 0;
            return true;
        }
        return false;
    };
    return DataTabHeaderComponent;
}());
export { DataTabHeaderComponent };
