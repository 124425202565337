import { SupportComponent } from './support.component';
// import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PermissionGuard } from '@myproject/perimission.guard';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { UserInfoComponent } from './user-info/user-info.component';
// import { AdvancedSearchComponent } from './lazy/advanced-search/advanced-search.component';


const routes: Routes = [
  {
    path: '',
    component: SupportComponent,
    data: { moduleName: 'SUPPORT' },
    canActivate: [PermissionGuard]
  },
  {
    path: 'user-info', component: UserInfoComponent,
    data: { moduleName: 'SUPPORT' },
    canActivate: [PermissionGuard]
  }
];

// @NgModule({
//   imports: [RouterModule.forChild(routes)],
//   exports: [RouterModule]
// })
export const SupportRoutingModule: ModuleWithProviders = RouterModule.forChild(routes);
