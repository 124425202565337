import {Component, OnInit, ViewChild, ElementRef, Input} from '@angular/core';
import {SharedService} from "@shared/shared.service";
import {DeviceReplacementService} from "@components/support/user-info/device-replacement/device-replacement.service";
import {environment} from '@myenv/environment';
import {enMessage} from '@shared/en-us-message';
import { Observable, Subject, concat, of } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-device-replacement',
  templateUrl: './device-replacement.component.html',
  styleUrls: ['./device-replacement.component.scss']
})
export class DeviceReplacementComponent implements OnInit {
  @ViewChild('inputElement', { static: false }) inputOne: ElementRef;
  @Input() userData;
  device$: Observable<any[]>;
  deviceInput$ = new Subject<string>();
  deviceLoading = false;
  deviceType: any = {};
  @ViewChild('btnClose', {static: false}) btnClose: ElementRef;
  searchHeader = [
    { name: 'serialNumber', displayName: 'Serial Number', type: 'STRING' }
  ];
  timeout = null;
  devices: any = [];
  unAssociatedDevices: any = [];
  searchModel: any = {};
  selectedRow: any = {};
  noResults = false;
  responseMessage: any = {};
  permissionModules: any = [];
  permissionAuthorities: any = {};
  modules: any = [];
  moduleName = 'DEVICE-REPLACE';
  public dateFormat = environment.largeDateFormat;
  disable = false;

  constructor(
    private sharedService: SharedService,
    private deviceReplacementService: DeviceReplacementService
  ) { }

  ngOnInit() {
    this.searchModel = {
      searchId: 'serialNumber'
    };
    this.modules = JSON.parse(localStorage.getItem('accessRoleModules'));
    this.permissionAuthorities = this.modules.find(module => module.name === this.moduleName);
  }

  searchDataOnChange() {
    if(this.searchModel.searchValue && this.searchModel.searchId) {
      this.onSearch();
    }
  }

  selectInput() {
    const inputElem = <HTMLInputElement>this.inputOne.nativeElement;
    inputElem.select();
  }

  public inputValidator(event: any) {
    //console.log(event.target.value);
    const pattern = /^[_\-@.,a-zA-Z0-9\s]*$/;
    //let inputChar = String.fromCharCode(event.charCode)
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^_\-@.,a-zA-Z0-9\s]/g, "");
      this.searchModel.searchValue = event.target.value;
      this.onSearch();
    } else {
      this.onSearch();
    }
  }

  onSearch() {
    clearTimeout(this.timeout);
    let global = this;
    this.timeout = setTimeout(function () {
      if (global.searchModel.searchValue) {
        const deviceTypeValue = global.searchModel.searchValue.trim();
        global.deviceReplacementService.searchDevice('serialNumber', deviceTypeValue, global.userData.customerId).subscribe({
          next: res => {
            global.devices = res;
            (global.devices.length === 0) ? global.noResults = true : global.noResults = false;
          },
          error: error => {
            global.sharedService.getErrorMsg(error);
          }
        });
      } else {
        global.devices = [];
      }
    }, 1500);
  }

  getSelectedRow(row) {
    this.selectedRow = row;
    this.searchModel.oldSerialNumber = row.serialNumber;
  }

  getUnAssociatedDevices() {
    this.device$ = concat(
      of(this.unAssociatedDevices), // default items
      this.deviceInput$.pipe(
        distinctUntilChanged(),
        tap(() => this.deviceLoading = true),
        switchMap(term => this.deviceReplacementService.getUnAssociatedDevices('serialNumber', term.trim(), this.userData.customerId ).pipe(
          catchError(() => of([this.unAssociatedDevices])), // empty list on error
          tap(() => this.deviceLoading = false)
        ))
      )
    );
  }

  // To update attribute
  update(isValid, form) {
    if (isValid) {
      this.disable = true;
      let payload = {
        "oldSerialNumber" : this.searchModel.oldSerialNumber,
        "newSerialNumber" : this.searchModel.newSerialNumber,
        "comments" : this.searchModel.comments
      }
      this.deviceReplacementService.replaceDevice(payload).subscribe(res => {
        this.btnClose.nativeElement.click();
        this.responseMessage = {success: true, message: enMessage.replacementSuccess};
        this.sharedService.setResponseMessage(this.responseMessage);
        this.disable = false;
      }, error => {
        this.disable = false;
        this.sharedService.getErrorMsg(error);
      });
    }
  }

  removeLoader() {
    this.deviceLoading = false;
  }

}
