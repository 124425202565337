import { Component, OnInit } from '@angular/core';
import { SupportService } from '../support.service';
import { SharedService } from '@myproject/shared/shared.service';
import {environment} from '@myenv/environment';

@Component({
  selector: 'app-not-communicating',
  templateUrl: './not-communicating.component.html',
  styleUrls: ['./not-communicating.component.scss']
})
export class NotCommunicatingComponent implements OnInit {
  devices = [];
  hours = 24;
  page = 0;
  loadingIndicator = true;
  currentPageCount: any = {
    start: 0,
    end: 0
  };
  public dateFormat = environment.smallDateFormat;
  pageInfo: any = {
    offset: 0,
    count: 0,
    limit: 10,
    pageSize: 0,
    lastPage: 0,
  };
  error = false;
  customers = [];
  searchModel: any = {};
  devicesCount: any;

  constructor(
    private supportService: SupportService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.getCustomers();
    // this.getNotCommDevices(this.page);
  }

  getCustomers() {
    this.sharedService.getAllCustomer().subscribe(res => {
      this.customers = res;
      this.searchModel.customerId = res[0].customerId;
      this.selectCustomer();
    }, error => {
      this.sharedService.getErrorMsg(error);
    });
  }

  selectCustomer() {
    if (this.searchModel.customerId !== null) {
      this.devices = [];
      this.page = 0;
      this.devicesCount = {};
      this.getNotCommDevices(this.page);
      this.getCount();
    }
  }

  getNotCommDevices(page) {
    this.supportService.getNotCommDevices(page, this.hours, this.searchModel.customerId).subscribe(res => {
      this.devices = [...this.devices, ...res];
      this.loadingIndicator = false;
      this.pageInfo.count = this.devices.length;
      const pagecount = this.pageInfo.count / this.pageInfo.limit;
      this.pageInfo.lastPage = Math.floor(pagecount);
      const decimal = pagecount - this.pageInfo.lastPage;
      //
      if (!Number.isInteger(decimal)) {
        this.pageInfo.lastPage = this.pageInfo.lastPage + 1;
      }
      if (page == 0) {
        if (res.length) {
          this.currentPageCount.start = (this.pageInfo.offset * this.pageInfo.pageSize) + 1;
          if (this.devices.length <= 10) {
            this.currentPageCount.end = this.pageInfo.count;
          } else {
            this.currentPageCount.end = (this.pageInfo.offset * this.pageInfo.pageSize) + this.pageInfo.limit;
          }
        }
      }
    });
  }

  getCount() {
    this.supportService.getDevicesCount(this.hours, this.searchModel.customerId).subscribe(res => {
      this.devicesCount = res;
    });
  }

  // To get the devices for each page in the table
  pageCallback(value) {
    this.pageInfo.offset = value.offset;
    this.currentPageCount.start = (value.offset * value.pageSize) + 1;
    const lastPage = Math.floor(this.devices.length / 10);
    if (lastPage == value.offset) {
      this.currentPageCount.end = value.count;
    } else {
      this.currentPageCount.end = (value.offset * value.pageSize) + value.limit;
    }
    if (this.pageInfo.lastPage === (this.pageInfo.offset + 1)) {
      // tslint:disable-next-line:no-unused-expression
      this.page === ++this.page;
      this.getNotCommDevices(this.page);
    }
  }

  changeTime(f) {
    if (f.valid && this.hours != 0) {
      this.error = false;
      this.page = 0;
      this.devices = [];
      this.loadingIndicator = true;
      this.getNotCommDevices(this.page);
    } else {
      this.error = true;
    }
  }
}
