import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { GraphicReportsService } from '../../graphic-reports.service';
import { SharedService } from '../../../../shared/shared.service';
import Chart from 'chart.js';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { configMessage } from '../../../../shared/config-message';
import * as FileSaver from 'file-saver';
import { environment } from '@myenv/environment';
var MetricsReportComponent = /** @class */ (function () {
    function MetricsReportComponent(reportsService, sharedService, title) {
        this.reportsService = reportsService;
        this.sharedService = sharedService;
        this.title = title;
        this.messageTypeColor = configMessage.messageTypeColor;
        this.permissionModules = [];
        this.devices = [];
        this.isLoading = false;
        this.selectedVehicle = null;
        this.querySelectVehicle = null;
        this.searchModel = {
            value: null
        };
        this.hideCalendar = false;
        this.highestCount = 0;
        this.chartLabels = [];
        this.stackedDataset = [];
        this.totalsDataset = [];
        this.showTotalChart = false;
        this.isSideMenuClose = false;
    }
    MetricsReportComponent.prototype.ngOnInit = function () {
        this.title.setTitle('Messages' + environment.title_text);
        this.permissionModules = JSON.parse(localStorage.getItem('permissionModules'));
    };
    MetricsReportComponent.prototype.onTimeChange = function (e) {
        this.startTime = e.startTime;
        this.endTime = e.endTime;
        if (this.selectedVehicle) {
            this.getVehicleMessageData();
        }
    };
    MetricsReportComponent.prototype.changeVehicle = function (e) {
        // if (localStorage.getItem('reportsVehicle') && this.selectedVehicle == null) {
        //   this.setItem();
        // } else {
        //   // if (e !== null) {
        //   localStorage.setItem('reportsVehicle', JSON.stringify(e));
        //   this.selectedVehicle = e.deviceId;
        // }
        this.selectedVehicle = e ? e.deviceId : null;
        this.querySelectVehicle = e ? e : null;
        if (this.startTime && this.endTime) {
            this.getVehicleMessageData();
        }
    };
    MetricsReportComponent.prototype.onSideMenuToggle = function () {
        this.isSideMenuClose = !this.isSideMenuClose;
    };
    MetricsReportComponent.prototype.setItem = function () {
        var reportsItem = JSON.parse(localStorage.getItem('reportsVehicle'));
        this.querySelectVehicle = reportsItem;
        this.selectedVehicle = reportsItem.deviceId;
    };
    MetricsReportComponent.prototype.refresh = function () {
        this.getVehicleMessageData();
    };
    MetricsReportComponent.prototype.toggleChart = function () {
        if (this.showTotalChart) {
            this.drawStackedBarGraph(this.chartLabels, this.totalsDataset);
        }
        else {
            this.drawStackedBarGraph(this.chartLabels, this.stackedDataset);
        }
    };
    MetricsReportComponent.prototype.getVehicleMessageData = function () {
        var _this = this;
        this.isLoading = true;
        var startTime = new Date(this.startTime);
        var endTime = new Date(this.endTime);
        var start = startTime.getFullYear() + "-" + (startTime.getMonth() + 1) + "-" + startTime.getDate();
        var end = endTime.getFullYear() + "-" + (endTime.getMonth() + 1) + "-" + endTime.getDate();
        this.reportsService.getVehicleMessageData(this.selectedVehicle, start, end).subscribe({
            next: function (res) {
                _this.sortData(res);
            },
            error: function (error) {
                _this.isLoading = false;
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    MetricsReportComponent.prototype.sortData = function (res) {
        var _this = this;
        this.highestCount = 0;
        var labels = [];
        var keys = [];
        var backgroundColors = [];
        var dataset = [];
        var totalCounts = [];
        // find all the distinct messages
        res.forEach(function (item) {
            for (var k in item) {
                if (!keys.includes(k) && k !== 'date' && k !== 'NO_DATA') {
                    if (_this.messageTypeColor[k]) {
                        backgroundColors.push(_this.messageTypeColor[k].colorOpaque);
                    }
                    else {
                        backgroundColors.push(_this.messageTypeColor['UNKNOWN'].colorOpaque);
                    }
                    keys.push(k);
                }
            }
        });
        // Obtain date labels and total counts for each day
        res.map(function (item) {
            var datePipe = new DatePipe('en-US');
            var eventDate = datePipe.transform(item.date, 'EE, dd MMM');
            labels.push(eventDate);
            var total = 0;
            for (var k in item) {
                if (k !== 'date' && item[k]) {
                    total += item[k];
                }
            }
            totalCounts.push(total);
        });
        // get message count and configure dataset
        var highestCountsArray = [];
        var _loop_1 = function (i) {
            var obj = {};
            var counts = [];
            res.map(function (item) {
                if (item[keys[i]]) {
                    counts.push(item[keys[i]]);
                }
                else {
                    counts.push(0);
                }
            });
            obj.label = keys[i];
            // obj.label = configMessage.messageType[keys[i]];
            obj.backgroundColor = backgroundColors[i];
            obj.data = counts;
            dataset.push(obj);
            highestCountsArray.push(Math.max.apply(Math, tslib_1.__spread(counts)));
        };
        for (var i = 0; i < keys.length; i++) {
            _loop_1(i);
        }
        this.chartLabels = labels;
        this.stackedDataset = dataset;
        this.totalsDataset = [{
                label: 'Total Messages',
                backgroundColor: 'rgb(54, 162, 235, 1)',
                data: totalCounts
            }];
        this.highestCount = Math.max.apply(Math, tslib_1.__spread(highestCountsArray));
        this.toggleChart();
        this.isLoading = false;
    };
    MetricsReportComponent.prototype.drawStackedBarGraph = function (labels, dataset) {
        if (this.stackedChart) {
            this.stackedChart.destroy();
        }
        var ctx = document.getElementById('stackedBarGraph');
        this.stackedChart = new Chart(ctx, {
            type: 'bar',
            responsive: true,
            data: {
                labels: labels,
                datasets: dataset
            },
            options: {
                legend: {
                    position: 'bottom',
                    display: true,
                    onHover: function (event, legendItem) {
                        document.getElementById('canvas').style.cursor = 'pointer';
                    },
                    onClick: function (e, legendItem) {
                        var index = legendItem.datasetIndex;
                        var ci = this.chart;
                        var alreadyHidden = (ci.getDatasetMeta(index).hidden === null) ? false : ci.getDatasetMeta(index).hidden;
                        var anyOthersAlreadyHidden = false;
                        var allOthersHidden = true;
                        // figure out the current state of the labels
                        ci.data.datasets.forEach(function (e, i) {
                            var meta = ci.getDatasetMeta(i);
                            if (i !== index) {
                                if (meta.hidden) {
                                    anyOthersAlreadyHidden = true;
                                }
                                else {
                                    allOthersHidden = false;
                                }
                            }
                        });
                        // if the label we clicked is already hidden 
                        // then we now want to unhide (with any others already unhidden)
                        if (alreadyHidden) {
                            ci.getDatasetMeta(index).hidden = null;
                        }
                        else {
                            // otherwise, lets figure out how to toggle visibility based upon the current state
                            ci.data.datasets.forEach(function (e, i) {
                                var meta = ci.getDatasetMeta(i);
                                if (i !== index) {
                                    // handles logic when we click on visible hidden label and there is currently at least
                                    // one other label that is visible and at least one other label already hidden
                                    // (we want to keep those already hidden still hidden)
                                    if (anyOthersAlreadyHidden && !allOthersHidden) {
                                        meta.hidden = true;
                                    }
                                    else {
                                        // toggle visibility
                                        meta.hidden = meta.hidden === null ? !meta.hidden : null;
                                    }
                                }
                                else {
                                    meta.hidden = null;
                                }
                            });
                        }
                        ci.update();
                    },
                },
                scales: {
                    xAxes: [{
                            display: true,
                            stacked: true
                        }],
                    yAxes: [{
                            display: true,
                            stacked: true,
                            ticks: {
                                suggestedMin: 0,
                                suggestedMax: 10,
                                stepSize: this.highestCount > 9 ? null : 1
                            }
                        }]
                }
            }
        });
        // document.getElementById('js-legend').innerHTML = this.stackedChart.generateLegend();
        // this.legendEvent();
    };
    // legendEvent() {
    //   const global = this;
    //   $('#js-legend > ul > li').on('click', function (e) {
    //     var index = $(this).index();
    //     $(this).toggleClass('strike');
    //     var ci = global.stackedChart;
    //     var alreadyHidden = (ci.getDatasetMeta(index).hidden === null) ? false : ci.getDatasetMeta(index).hidden;
    //     var anyOthersAlreadyHidden = false;
    //     var allOthersHidden = true;
    //     // figure out the current state of the labels
    //     ci.data.datasets.forEach(function(e, i) {
    //       var meta = ci.getDatasetMeta(i);
    //       if (i !== index) {
    //         if (meta.hidden) {
    //           anyOthersAlreadyHidden = true;
    //         } else {
    //           allOthersHidden = false;
    //         }
    //       }
    //     });
    //     // if the label we clicked is already hidden
    //     // then we now want to unhide (with any others already unhidden)
    //     if (alreadyHidden) {
    //       ci.getDatasetMeta(index).hidden = null;
    //     } else {
    //       // otherwise, lets figure out how to toggle visibility based upon the current state
    //       ci.data.datasets.forEach(function(e, i) {
    //         var meta = ci.getDatasetMeta(i);
    //         if (i !== index) {
    //           // handles logic when we click on visible hidden label and there is currently at least
    //           // one other label that is visible and at least one other label already hidden
    //           // (we want to keep those already hidden still hidden)
    //           if (anyOthersAlreadyHidden && !allOthersHidden) {
    //             meta.hidden = true;
    //           } else {
    //             // toggle visibility
    //             meta.hidden = meta.hidden === null ? !meta.hidden : null;
    //           }
    //         } else {
    //           meta.hidden = null;
    //         }
    //       });
    //     }
    //     ci.update();
    //     // var meta = ci.getDatasetMeta(index);
    //     // meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;
    //     // ci.update();
    //   });
    // }
    // To download chart data as a csv file
    MetricsReportComponent.prototype.downloadCSV = function () {
        var _this = this;
        var startTime = new Date(this.startTime);
        var endTime = new Date(this.endTime);
        var start = startTime.getFullYear() + "-" + (startTime.getMonth() + 1) + "-" + startTime.getDate();
        var end = endTime.getFullYear() + "-" + (endTime.getMonth() + 1) + "-" + endTime.getDate();
        this.reportsService.getMessageDataCSV(this.selectedVehicle, start, end).subscribe(function (res) {
            var contentDisposition = res.headers.get('Content-Disposition');
            var filename;
            if (contentDisposition == null) {
                var date = new Date();
                filename = 'Messages_' + date + '.csv';
            }
            else {
                filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
            }
            var blob = new Blob([res.body], {
                type: 'text/csv;charset=utf-8'
            });
            FileSaver.saveAs(blob, filename);
        }, function (error) {
            _this.sharedService.getErrorMsg(error);
        });
    };
    return MetricsReportComponent;
}());
export { MetricsReportComponent };
