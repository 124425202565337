import {
  Component,
  OnInit,
  ViewChild,
  NgZone,
  OnDestroy,
  Inject,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbTimepickerConfig } from '@ng-bootstrap/ng-bootstrap';
// ChangeDetectorRef,
import 'jquery';
import Chart from 'chart.js';
import { LiveService } from '@components/live/live.service';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { Title } from '@angular/platform-browser';
import { environment } from '@myenv/environment';
import { configMessage } from '@shared/config-message';
import * as moment from 'moment';
//
import { SharedService } from '@shared/shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { } from 'googlemaps';
// import * as MarkerClusterer from '@google/markerclusterer';
import * as MarkerClustererPlus from '@google/markerclustererplus';
import { PlaceService } from '@components/places/places.service';
import { WorkerService } from './worker.service';
import { GroupsService } from '@myproject/components/device/groups/groups.service';
import { DashboardService } from '@components/dashboard/dashboard.service';
import { enMessage } from '@shared/en-us-message';
import { DatePipe } from '@angular/common';
import { DataService } from '@components/data/data.service';
import { NgbDatepickerModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { ScoreService } from '../graphic-reports/score/score.service';
import * as FileSaver from 'file-saver';
import * as config from '../../../assets/config.json';
import { element } from 'protractor';

declare var require: any;
var ProgressBar = require('progressbar.js');

declare var jquery: any;
declare var $: any;

declare var require: any;
var ProgressBar = require('progressbar.js');

@Component({
  selector: 'app-live',
  templateUrl: './live.component.html',
  styleUrls: ['./live.component.scss']
})
export class LiveComponent implements OnInit, AfterViewInit, OnDestroy {
  time: NgbTimeStruct = { hour: 1, minute: 59, second: 0 };
  @ViewChild('pacinput', { static: false }) pacinput: ElementRef;
  public distanceMetrics = environment.distanceMetrics;
  public AL_SystemReport = environment.AL_SystemReport;
  lineChart: any;
  vehicleBatteryPotentialGraph: any;
  internalBatteryChart: any;
  fuelLevelChart: any;
  GPSChart: any;
  noDevicesSelected = false;
  showModal = false;
  hideModal = false;
  disable = false;
  searchheader = [
    { displayName: 'Name', name: 'assetName' },
    { displayName: 'Serial Number', name: 'serialNumber' },
    { displayName: 'PSN', name: 'productSerialNumber' },
    { displayName: 'VIN', name: 'vin' },
    { displayName: 'Configuration', name: 'configVersion' },
    { displayName: 'Firmware', name: 'firmwareVersion' },
    { displayName: 'IMEI', name: 'imei' },
    { displayName: 'Message Type', name: 'messageType' }
    // { displayName: 'SIM ICCID', name: 'simCcid' },
    // { displayName: 'Tags', name: 'tags' }
    // { displayName: 'SIM Number', name: 'simNumber' },
  ];
  selectedSearchHeader = {
    displayName: 'Name',
    name: 'assetName'
  };
  staticReports = configMessage.staticReports;
  configMessage = configMessage;
  tripAuthorities: any = [];
  selected = [];
  reportsMenu: any = [];
  reports: any = [];
  reportsData: any = [];
  permissionModules: any = [];
  public cmImageUrl = environment.cmImageUrl;
  public speedMetrics = environment.speedMetrics;
  public dateFormat = environment.smallDateFormat;
  public refreshIcon = environment.refreshIcon;
  public live_obd2_metrics_enabled = environment.live_obd2_metrics_enabled;
  responseMessage: any = {};
  lat = environment.centerLat;
  lng = environment.centerLng;
  zoom = 2;
  mapCenterPoint = {
    minLat: null,
    maxLat: null,
    minLong: null,
    maxLong: null,
  };
  origin: {};
  liveVehicles: any = [];
  vehicleDetail: any;
  loadMore = false;
  vehicle: any = {};
  listPage = true;
  show = true;
  liveKey: any;
  // loadAPI: Promise<any>;
  openRefreshPopup: Boolean = false;
  detailBox: Boolean = false;
  copied: Boolean = false;
  messageLoader: Boolean = false;
  messageFormattedString: any;
  currentIndex: Number;
  copiedAssetName: Boolean = false;
  customLoader: Boolean = false;
  liveSearchHeader: any = [];
  isSnazzyInfoWindowOpened = false;
  vehicleView = false;
  readonly headerHeight = environment.headerHeight;
  readonly rowHeight = environment.rowHeight;
  readonly pageLimit = environment.pageLimit;
  readonly resSize = environment.resSize;
  page = 0;
  isLoading = false;
  loadingIndicator = true;
  rows: any = [];
  temp: any = [];
  livekeyString: String;
  enablePage = true;
  livePermissions: any = [];
  live: any;
  date = new Date();
  calculateStopTime: any;
  liveAttribute = configMessage.liveAttribute;
  timeout = null;
  csvLivePrefHeaderArray = [];
  csvLivePrefKeyArray = [];
  pageCount: any;
  liveSearch = false;

  // NGX Datatable Variables
  infoWindowText = [];
  infoWindowTextCopy = [];
  infoWindow;
  onClick: any;
  @ViewChild('popoverElement', { static: false }) popoverElement: ElementRef;
  @ViewChild('locatePopover', { static: false }) locatePopover: ElementRef;
  // Filter Variables
  searchFilter: any = {};
  prefrences: any;
  filter: Boolean = false;
  speedfilter = '';
  searchKey = '';
  selectedFilter = '';
  searchValue = '';
  vehicleTypeValue: number;
  filterText = 'Status';
  favouriteText: any = {};
  // AutoRefresh Variables
  filterPopUpActive = false;
  searchModel: any = {};
  selectedFav: any = {};
  deviceList: any = {
    devices: []
  };
  querySelectDevice = null;
  toggleEffect = false;
  play = false;
  timer = false;

  // Google Maps Variables
  @ViewChild('inputElement', { static: true }) inputOne: ElementRef;
  @ViewChild('closeuploadDevice_model', { static: true }) closeuploadDevice_model: ElementRef;
  @ViewChild('trackModel', { static: true }) trackModel: ElementRef;
  @ViewChild('showEditModal', { static: true }) showEditModal: ElementRef;
  // MarkerClusterer = MarkerClusterer;
  MarkerClustererPlus = MarkerClustererPlus;
  @ViewChild('gmap', { static: false }) gmapElement: ElementRef;
  @ViewChild('clusterLink', { static: false }) clusterLink: ElementRef;
  map: google.maps.Map;
  markers = [];
  newmarkerCluster: any;
  vehicleTotalCount = { count: 0 };
  allfavouriteItems: any = [];
  favouriteListLoading = false;
  favEditingMode = false;
  tracking = false;
  vehicleGroupFilterkey: any;
  favouriteFilterKey: any;
  selectedFavourite: any;
  trackedVehicle: any = {};
  subtractTime = 15;
  vehicleLocations: any = [];
  trackingTime = 3000;
  latestCoOrds = [];
  flightPath: any;
  trackingId: any;
  event: any = {};
  flightPlan = [];
  markerInfoWindow: any;

  // Web Worker Variables
  workerSubscription: any;

  // Places Filter Variables
  places: any = [];
  placesListLoading = false;
  selectedPlace = 'Places';
  place = false;

  // Location Filter
  locatePlace: any = {};
  locateDeviceFilter: Boolean = false;
  autoRefreshClick: Boolean = false;
  cityCircle: any;
  locateCityCircle: any;
  newPlace: any = {
    circle: {
      newCenter: {
        lat: null,
        lng: null
      },
      radius: 100
    },
    color: '#e01111'
  };
  savedLocations = [];
  selectedSavedLocation: any = {};

  confirmDeleteAction = '';

  // Groups Filter Variables
  groups: any = [];
  groupsListLoading = false;
  openedNav: Boolean = true;
  // showTable: Boolean = true;
  groupsMenu: any = [];
  selectedGroup: any = { name: 'Groups' };
  isGroupFilterOn = false;

  // Selected Vehicle Drawer Variables
  infoWindowSelectedVehicle: any = {};
  selectedVehicle: any = {};
  isDrawerOpen = false;
  dtcCodeCount = 0;
  snappedVehicle: any = {};
  totalTrips: any;
  signalTooltip: any;
  drawerLoading = false;
  historyRows: any = [];
  EVMessageData: any = [];
  BSMessageData: any = [];
  vPacketData: any = [];
  liveHistorykeyString: String;
  liveHistoryAttribute = configMessage.liveHistoryAttribute;
  liveHistoryKey: any;
  batteryEvent: any = {};
  batteryPotential = [];
  internalBattery = [];
  fuelLevel = [];

  //Scores
  healthScoreProgress: any;
  fuelEconomyScoreProgress: any;
  healthFuelProgressBars = [];
  monthNames: any = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  currentMonthName: any;

  // For sharing Track
  ctrl = new FormControl('', (control: FormControl) => {
    const value = control.value;
    if (!value) {
      return { addTime: true };
    }
    if (value.hour > 8) {
      return { maxTime: true };
    }
    if (value.hour == 8 && value.minute > 0) {
      return { maxTime: true };
    }

    if (value.hour == 0 && value.minute == 0) {
      return { minTime: true };
    }
    return null;
  });

  // for device health widget in side drawer
  isHealthTabActive = false;
  deviceEvents: any = [];
  vehicleCount: any;
  drivingBehaviour: any = {};
  progressBars = [];
  dailyScore: any = {};
  exposureProgressBar: any;
  behaviourProgressBar: any;
  riskProgressBar: any;
  showLiveDashboard: any = false
  showButton: any = true;
  obdsList: any;
  vechileData: any;
  direction: any;
  pastVechileData: any;
  initialObdsLoad = false;
  // go to reports modal
  @ViewChild('reportSearch', { static: true }) reportSearch: ElementRef;
  @ViewChild('shareTrackTextArea', { static: true }) shareTrack: ElementRef;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private sharedService: SharedService,
    private liveService: LiveService,
    private dashboardService: DashboardService,
    private ngzone: NgZone,
    private title: Title,
    private workerService: WorkerService,
    private placeService: PlaceService,
    private dataService: DataService,
    private groupsService: GroupsService,
    private scoreService: ScoreService,
    config: NgbTimepickerConfig
  ) {
    this.currentMonthName = this.monthNames[new Date().getMonth()] + " " + new Date().getFullYear();
  }

  ngOnInit() {
    window["live"] = this;
    this.title.setTitle('Live' + environment.title_text);
    this.permissionModules = localStorage.getItem('permissionModules');
    const modules = JSON.parse(localStorage.getItem('accessRoleModules'));
    const tripRoleModule = modules.find(module => module.name === 'TRIPS');
    this.tripAuthorities = tripRoleModule && tripRoleModule.authorities ? tripRoleModule.authorities : [];
    this.workerService.postMessage({ action: 'init', apiUrl: environment.apiUrl });
    this.getPermissionsLive();
    this.searchKey = 'assetName';
    sessionStorage.clear();
    this.selectInput();
    this.searchFilter.value = 50;
    const queryParams = this.route.snapshot.queryParams;
    if (queryParams.searchBy && queryParams.value) {
      this.searchVehicle(queryParams.searchBy, queryParams.value);
    }
    this.checkAutoRefreshPrefrence();
    // if (this.permissionModules.includes('PLACE')) {
    //   this.getAllPlace(this.page);
    // }
    // this.getGroups();
    // this.getAllfavourites();
  }

  changeValue(item) {
    this.selectedSearchHeader = item;
    this.searchKey = item.name;
  }

  checkAutoRefreshPrefrence() {
    this.isLoading = false;
    const autoRefresh = JSON.parse(localStorage.getItem('preferences')).live.autoRefresh;
    if (autoRefresh && !autoRefresh.enabled || autoRefresh == null) {
      this.searchModel.enabled = false;
      this.getAllVehicleMessage(this.page);
    } else {
      this.autoRefresh();
    }
    const livePrefrences = JSON.parse(localStorage.getItem('preferences'));
    this.prefrences = livePrefrences.live;
  }

  ngAfterViewInit() {
    this.mapInit();
  }

  searchVehicle(searchBy, value) {
    this.inputOne.nativeElement.value = value;
    this.searchKey = searchBy;
    this.searchheader.map(item => {
      if (item.name === searchBy) {
        this.selectedSearchHeader = item;
      }
    });
    this.inputValidator({ target: { value: value } });
  }

  // To get preferences and permissions
  getPermissionsLive() {
    this.liveKey = [];
    this.livekeyString = this.liveAttribute;
  }

  // =======================================================================
  // WORKER FUNCTIONS WORKSPACE START
  stopWorker() {
    const workerMessage = { action: 'autorefreshOFF' };
    this.workerService.postMessage(workerMessage);
  }
  // WORKER FUNCTIONS WORKSPACE END
  // ========================================================================

  // To get total campaign count
  getVehicleCount() {
    this.liveService.getVehicleCount().subscribe({
      next: (res: any) => {
        this.vehicleTotalCount = res;
      },
      error: (err: any) => {
        this.sharedService.getErrorMsg(err);
      }
    });
  }


  getGroups() {
    if (!this.groups.length) {
      this.groupsListLoading = true;
      this.groupsService.getAssignedGroups().subscribe({
        next: (res: any) => {
          this.groups = res;
          this.groupsMenu = this.groups;
          this.groupsListLoading = false;
        },
        error: (err: any) => {
          this.sharedService.getErrorMsg(err);
        }
      });
    }
  }
  async loadPage(limit: number) {
    if (this.enablePage === true) {
      this.page = ++this.page;
    }
    this.getPermissionsLive();
    this.page = 0;
    await this.liveService.getAllVehicleMessage(this.page, this.pageLimit, this.livekeyString).subscribe({
      next: (results: any) => {
        if (results.length === 0) {
          this.enablePage = false;
        }
        results = results.map(data => {
          data.isSnazzyInfoWindowOpened = false;
          return data;
        });
        const rows = [...this.rows, ...results];
        this.temp = rows;
        this.rows = rows;
        this.liveVehicles = this.liveVehicles.map(data => {
          if (data.vehicleSpeed && !Number.isInteger(data.vehicleSpeed)) {
            const newvehicleSpeed = data.vehicleSpeed;
            data.vehicleSpeed = newvehicleSpeed.toFixed(1);
          }
        });
        this.liveVehicles = this.rows;
        this.pageCount = results.length;
      },
      error: (err: any) => {
        this.sharedService.getErrorMsg(err);
      }
    });
  }

  selectInput() {
    const inputElem = <HTMLInputElement>this.inputOne.nativeElement;
    inputElem.select();
  }

  public inputValidator(event: any) {
    if (!this.openedNav) {
      this.openNav();
    }
    //const term = event.target.value.replace(/[^_\-.,a-zA-Z0-9\s]/g, '');
    //this.searchValue = term.split(/[ ]+/).filter(function (v) {
    //  return v !== ''
    //).join(',').trim();
    this.updateFilter(true);
  }

  // To get the search input value
  updateFilter(isValid) {
    clearTimeout(this.timeout);
    if (this.tracking) {
      this.resetTracking();
    }
    if (this.isDrawerOpen) {
      this.explicitCloseDrawer();
    }
    if (this.route.snapshot.queryParams['searchBy']) {
      var snapshot = this.route.snapshot;
      const params = { ...snapshot.queryParams };
      delete params.searchBy;
      delete params.value;
      this.router.navigate([], { queryParams: params });
    }
    if (isValid) {
      if (this.searchValue === '') {
        this.liveSearch = false;
        if (this.selectedFilter || this.searchFilter) {
          if (this.timer === false || this.play === true) {
            // tslint:disable-next-line: max-line-length
            this.liveService.searchVehicle(this.selectedFilter, this.searchValue, this.searchKey, this.selectedGroup.id, this.searchFilter, this.newPlace)
              .subscribe(res => {
                this.filter = true;
                this.liveVehicles = res;
                this.loadMore = false;
                this.mapFilter(res);
                this.vehicleTotalCount.count = res.length;
                this.isLoading = true;
                this.page = 0;
                // this.getAllVehicleMessage(this.page);

              }, error => {
                this.isLoading = true;
                this.sharedService.getErrorMsg(error);
              });
          } else {
            this.isLoading = true;
            this.workerSubscription.unsubscribe();
            this.timerFunction(this.vehicleTypeValue);
            // this.checkForCount();
          }
        } else {
          if (this.timer === false || this.play === true) {
            this.clearMarkers();
            this.newmarkerCluster.clearMarkers();
            this.page = 0;
            this.isLoading = true;
            this.getAllVehicleMessage(this.page);
          } else {
            this.isLoading = false;
            this.workerSubscription.unsubscribe();
            this.timerFunction(this.vehicleTypeValue);
          }
        }
      } else {
        this.loadMore = true;
        this.liveSearch = true;
        this.vehicleSearch(this.searchValue);
      }
    }
  }

  clearSearch() {
    this.inputOne.nativeElement.value = '';
    this.liveSearch = false;
    this.updateFilter(true);
  }

  // To get the searched item from API
  vehicleSearch(inputVal) {
    if (this.isDrawerOpen) {
      this.explicitCloseDrawer();
    }
    const global = this;
    global.timeout = setTimeout(function () {
      if (global.timer === false || global.play === true) {
        // tslint:disable-next-line: max-line-length
        global.liveService.searchVehicle(global.selectedFilter, inputVal, global.searchKey, global.selectedGroup.id, global.searchFilter, global.newPlace)
          .subscribe(res => {
            if (res.length) {
              global.liveVehicles = res;
              global.rows = res;
              global.loadingIndicator = false;
              global.loadMore = false;
            }
            else {
              global.liveVehicles = [];
              global.rows = [];
            }
            global.isLoading = true;
            global.mapFilter(res);
            global.searchValue = inputVal;
            global.vehicleTotalCount['count'] = res.length;
            global.loadingIndicator = false;
          }, error => {
            global.sharedService.getErrorMsg(error);
          });
      } else {
        global.workerSubscription.unsubscribe();
        global.timerFunction(global.vehicleTypeValue);
      }
    }, 1500);
  }

  // To display the Filter popup
  openFilterPopUp() {
    this.filterPopUpActive = !this.filterPopUpActive;
  }

  mapFilter(res) {
    if (Array.isArray(res) && res.length) {
      this.trackActivity(res);
      this.ngzone.run(() => {
        if (this.markers.length) {
          this.clearMarkers();
        }
        if (this.infoWindow) {
          this.infoWindow.close();
        }
        if (this.place || this.newPlace.circle.newCenter.lat != null) {
          this.newmarkerCluster.clearMarkers();
          this.clearMarkers();
          this.liveVehicles.forEach(data => {
            this.addMarker(data);
          });
          if (this.place) {
            this.map.fitBounds(this.cityCircle.getBounds());
          } else {
            this.map.fitBounds(this.locateCityCircle.getBounds());
          }
        } else {
          if (this.liveVehicles.length > 1) {
            if (this.newmarkerCluster) {
              this.newmarkerCluster.clearMarkers();
            }
            // this.map.setZoom(4);
            this.addCluster(this.liveVehicles);
          } else if (this.liveVehicles.length < 1) {
            this.newmarkerCluster.clearMarkers();
            this.clearMarkers();
            this.map.setZoom(3);
            // this.setMapCenter();
          } else {
            if (this.newmarkerCluster) {
              this.newmarkerCluster.clearMarkers();
            }
            this.liveVehicles.forEach(data => {
              this.addMarker(data);
            });
          }
        }
      });
    } else {
      this.mapCenterPoint.minLat = null;
      this.mapCenterPoint.minLong = null;
      this.mapCenterPoint.maxLat = null;
      this.mapCenterPoint.maxLong = null;
      this.clearMarkers();
      this.newmarkerCluster.clearMarkers();
      this.setMapCenter();
    }
  }

  // To get the vehicles based on a selected Filter
  getFilter(filterItem) {
    if (this.tracking) {
      this.resetTracking();
    }
    if (this.isDrawerOpen) {
      this.explicitCloseDrawer();
    }
    if (filterItem === 'RUNNING') {
      this.filterText = 'Running';
    }
    if (filterItem === 'STOPPED') {
      this.filterText = 'Stopped';
    }
    if (filterItem === 'DISCONNECTED') {
      this.filterText = 'Disconnected';
    }
    if (filterItem === 'NOT_COMMUNICATING') {
      this.filterText = 'Not-Communicating';
    }
    if (filterItem === 'configVersion') {
      this.filterText = 'Configuration';
    }
    if (filterItem === 'firmwareVersion') {
      this.filterText = 'Firmware';
    }
    this.selectedFilter = filterItem;
    this.openFilterPopUp();
    this.speedfilter = '';
    if (this.place) {
      this.searchFilter.placeKey = 'places';
      this.searchFilter.placeValue = this.selectedPlace;
    }
    this.searchFilter.value = '';
    this.searchFilter.key = '';
    if (this.timer === false || this.play === true) {
      // tslint:disable-next-line: max-line-length
      this.liveService.searchVehicle(this.selectedFilter, this.searchValue, this.searchKey, this.selectedGroup.id, this.searchFilter, this.newPlace)
        .subscribe(res => {
          this.filter = true;
          this.liveVehicles = res;
          this.mapFilter(res);
          this.vehicleTotalCount.count = res.length;

        }, error => {
          this.sharedService.getErrorMsg(error);
          this.filterText = 'Status';
        });
    } else {
      this.workerSubscription.unsubscribe();
      this.timerFunction(this.vehicleTypeValue);
    }
  }

  // To get the vehicles based on speed filter
  getSpeedFilter(filterItem, e) {
    e.stopPropagation();
    if (this.isDrawerOpen) {
      this.explicitCloseDrawer();
    }
    if (this.tracking) {
      this.resetTracking();
    }
    if (filterItem === 'getSpeed') {
      this.filterText = `Speed > ${this.searchFilter.value}`;
      if (!this.searchFilter.value) {
        filterItem = '';
      } else {
        filterItem = 'getSpeed';
      }
    }
    this.speedfilter = filterItem;
    this.openFilterPopUp();
    this.searchFilter.key = 'vehicleSpeed';
    this.selectedFilter = '';
    if (this.timer === false || this.play === true) {
      // tslint:disable-next-line: max-line-length
      this.liveService.searchVehicle(this.selectedFilter, this.searchValue, this.searchKey, this.selectedGroup.id, this.searchFilter, this.newPlace)
        .subscribe(res => {
          this.filter = true;
          this.liveVehicles = res;
          this.mapFilter(res);
          this.vehicleTotalCount.count = res.length;
        }, error => {
          this.sharedService.getErrorMsg(error);
          this.filterText = 'Status';
        });
    } else {
      this.workerSubscription.unsubscribe();
      this.timerFunction(this.vehicleTypeValue);
    }
  }

  // To clear all filters (To get all vehicles)
  clearFilter(e) {
    e.stopPropagation();
    this.filterText = 'Status';
    this.filter = false;
    this.filterPopUpActive = false;
    this.selectedFilter = '';
    this.speedfilter = '';
    if (this.isDrawerOpen) {
      this.explicitCloseDrawer();
    }
    if (this.tracking) {
      this.resetTracking();
    }
    if (this.place) {
      this.searchFilter.placeKey = 'places';
      this.searchFilter.placeValue = this.selectedPlace;
    } else {
      this.searchFilter.key = '';
    }
    this.updateFilter(true);
  }

  clearPlacesFilter(e) {
    e.stopPropagation();
    this.selectedPlace = 'Places';
    this.place = false;
    this.searchFilter = {
      value: 50
    };
    if (this.cityCircle) {
      this.cityCircle.setMap(null);
    }
    if (this.tracking) {
      this.resetTracking();
    }
    if (this.isDrawerOpen) {
      this.explicitCloseDrawer();
    }
    this.updateFilter(true);
    this.vehicleGroupFilterkey = null;
  }

  // To filter vehicles in a perticular place
  placesFilter(data) {
    this.vehicleGroupFilterkey = data.placeName;
    if (this.tracking) {
      this.resetTracking();
    }
    if (this.isDrawerOpen) {
      this.explicitCloseDrawer();
    }
    this.selectedPlace = data.placeName;
    this.searchFilter.placeKey = 'places';
    this.searchFilter.placeValue = data.placeName;
    if (this.timer === false || this.play === true) {
      // tslint:disable-next-line: max-line-length
      this.liveService.searchVehicle(this.selectedFilter, this.searchValue, this.searchKey, this.selectedGroup.id, this.searchFilter, this.newPlace)
        .subscribe(res => {
          this.place = true;
          this.liveVehicles = res;
          this.plotCircle(data);
          this.mapFilter(res);
          this.vehicleTotalCount.count = res.length;
        }, error => {
          this.selectedPlace = 'Places';
          this.sharedService.getErrorMsg(error);
        });
    } else {
      this.place = true;
      this.plotCircle(data);
      this.workerSubscription.unsubscribe();
      this.timerFunction(this.vehicleTypeValue);
    }
  }

  plotCircle(place) {
    if (this.cityCircle) {
      this.cityCircle.setMap(null);
    }
    const location = { lat: place.circle.centre[0], lng: place.circle.centre[1] };
    this.cityCircle = new google.maps.Circle({
      strokeColor: place.color,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: place.color,
      fillOpacity: 0.35,
      map: this.map,
      center: location,
      radius: place.circle.radius,
      editable: false
    });
    var latlng = new google.maps.LatLng(location.lat, location.lng);
    var bounds = new google.maps.LatLngBounds();
    bounds.extend(latlng);
    this.map.fitBounds(this.cityCircle.getBounds());
  }

  groupsFilter(data) {
    if (this.tracking) {
      this.resetTracking();
    }
    if (this.isDrawerOpen) {
      this.explicitCloseDrawer();
    }
    this.selectedGroup.name = data.name;
    this.selectedGroup.id = data.groupId;
    if (this.timer === false || this.play === true) {
      // tslint:disable-next-line: max-line-length
      this.liveService.searchVehicle(this.selectedFilter, this.searchValue, this.searchKey, this.selectedGroup.id, this.searchFilter, this.newPlace)
        .subscribe(res => {
          this.isGroupFilterOn = true;
          this.liveVehicles = res;
          this.mapFilter(res);
          this.vehicleTotalCount.count = res.length;
        }, error => {
          this.selectedGroup.name = 'Groups';
          this.sharedService.getErrorMsg(error);
        });
    } else {
      this.isGroupFilterOn = true;
      this.workerSubscription.unsubscribe();
      this.timerFunction(this.vehicleTypeValue);
    }
  }

  clearGroupsFilter(e) {
    e.stopPropagation();
    this.selectedGroup.name = 'Groups';
    delete this.selectedGroup.id;
    this.isGroupFilterOn = false;
    if (this.tracking) {
      this.resetTracking();
    }
    if (this.isDrawerOpen) {
      this.explicitCloseDrawer();
    }
    this.updateFilter(true);
  }

  // to format the data when the liveVehicles[] change
  formatData(res) {
    if (this.page == 0) {
      this.liveVehicles = [];
    }
    if (Array.isArray(res)) {
      if (res.length < this.pageLimit || res.length === 0) {
        this.loadMore = false;
      } else {
        this.loadMore = true;
      }
      this.ngzone.run(() => {
        if (this.infoWindow) {
          this.infoWindow.close();
        }
      });
      this.liveVehicles = [...this.liveVehicles, ...res];
      this.trackActivity(res);
      this.loadingIndicator = false;
      if (this.place) {
        this.newmarkerCluster.clearMarkers();
        this.clearMarkers();
        this.liveVehicles.forEach(data => {
          this.addMarker(data);
        });
        this.map.fitBounds(this.cityCircle.getBounds());
      } else {
        if (this.newmarkerCluster) {
          this.newmarkerCluster.clearMarkers();
        }
        this.clearMarkers();
        if (this.newPlace.circle.newCenter.lat == null) {
          this.map.setZoom(this.zoom);
        }
        this.addCluster(this.liveVehicles);
      }
      if (
        this.searchValue !== '' ||
        this.selectedFilter !== '' ||
        this.speedfilter !== '' ||
        this.place ||
        this.isGroupFilterOn ||
        this.newPlace.circle.newCenter.lat != null
      ) {
        this.vehicleTotalCount.count = this.liveVehicles.length;
      } else {
        this.getVehicleCount();
      }
    } else {
      this.mapCenterPoint.minLat = null;
      this.mapCenterPoint.minLong = null;
      this.mapCenterPoint.maxLat = null;
      this.mapCenterPoint.maxLong = null;
      this.clearMarkers();
      this.newmarkerCluster.clearMarkers();
      this.setMapCenter();
    }
    this.setMapCenter();
  }


  // To track and set Activity(stopTime, vehicleSpeed, Message) of liveVehicles
  trackActivity(res) {
    if (Array.isArray(res)) {
      if (res.length) {
        this.mapCenterPoint.minLat = res[0].latitude;
        this.mapCenterPoint.maxLat = res[0].latitude;
        this.mapCenterPoint.minLong = res[0].longitude;
        this.mapCenterPoint.maxLong = res[0].longitude;
      }
      res.map(data => {
        if (data.vehicleSpeed && !Number.isInteger(data.vehicleSpeed)) {
          let newvehicleSpeed = data.vehicleSpeed;
          data.vehicleSpeed = parseFloat(newvehicleSpeed.toFixed(1));
        }
        // while loop traversing finding min max lat long values to get boundaries for map
        if (data.latitude && data.latitude < this.mapCenterPoint.minLat) {
          this.mapCenterPoint.minLat = data.latitude;
        } else if (data.latitude && data.latitude > this.mapCenterPoint.maxLat) {
          this.mapCenterPoint.maxLat = data.latitude;
        }
        if (data.longitude && data.longitude < this.mapCenterPoint.minLong) {
          this.mapCenterPoint.minLong = data.longitude;
        } else if (data.longitude && data.longitude > this.mapCenterPoint.maxLong) {
          this.mapCenterPoint.maxLong = data.longitude;
        }
        this.addMarkerIcon(data);
        data.isSnazzyInfoWindowOpened = false;
        if (this.place) {
          if (configMessage.messageType[data.messageType]) {
            data.activity = configMessage.messageType[data.messageType] + '(' + data.placeName + ')';
          } else {
            data.activity = data.messageType;
          }
        } else {
          if (configMessage.messageType[data.messageType]) {
            data.activity = configMessage.messageType[data.messageType];
            if ((data.messageType === 'PLACE_IN_MESSAGE' || data.messageType === 'PLACE_OUT_MESSAGE') && data.placeName) {
              data.activity += ' ' + data.placeName;
            }
          } else {
            data.activity = data.messageType;
          }
        }
      });
      const stopTime = this.liveVehicles.map(data => {
        this.date = new Date(data.eventTime);
        const millisecond = Date.parse(this.date.toUTCString());
        const date = moment(millisecond).fromNow();
        const currentTime = new Date();
        const currentTimeMS = Date.parse(currentTime.toUTCString());
        data.endPacket = currentTimeMS - millisecond > 600000;

        return date;
      });
      var obj = {};
      for (var i = 0; i < stopTime.length; i++) {
        var split = stopTime[i].split(':');
        obj[split[0]] = split[1];
      }
      this.calculateStopTime = stopTime;
      this.temp = this.liveVehicles;
      this.addStopTime(this.liveVehicles, this.calculateStopTime);
      this.rows = this.liveVehicles;
    }
    this.setMapCenter();
  }

  setMapCenter() {
    if (this.mapCenterPoint.maxLat && this.mapCenterPoint.minLat) {
      this.map.setCenter(new google.maps.LatLng(
        ((this.mapCenterPoint.maxLat + this.mapCenterPoint.minLat) / 2.0),
        ((this.mapCenterPoint.maxLong + this.mapCenterPoint.minLong) / 2.0)
      ));
      this.fitMapBound();
    } else {
      this.map.setCenter(new google.maps.LatLng(0, 0));
      this.map.setZoom(2);
    }
  }

  fitMapBound() {
    this.map.fitBounds(new google.maps.LatLngBounds(
      //bottom left
      new google.maps.LatLng(this.mapCenterPoint.minLat, this.mapCenterPoint.minLong),
      //top right
      new google.maps.LatLng(this.mapCenterPoint.maxLat, this.mapCenterPoint.maxLong)
    ));
  }

  // To get all the vehicle data on that page and to invoke cluster methods and to calculate stop time
  getAllVehicleMessage(page, loadMore?) {
    this.loadingIndicator = true;
    if (!loadMore) {
      this.isLoading = false;
    }
    this.listPage = true;
    this.liveService.getAllVehicleMessage(page, this.pageLimit, this.livekeyString).subscribe(res => {
      this.isLoading = true;
      this.formatData(res);
      // this.setMapCenter();
      this.pageCount = res.length;
    }, error => {
      this.isLoading = true;
      this.sharedService.getErrorMsg(error);
    });
  }

  addMarkerIcon(data) {
    let iconName = '';
    if (data.messageType) {
      if (data.messageType === 'COMMUNICATION_LOST') {
        iconName = '../../../assets/img/direction/warning/';
      } else if (data.messageType === 'TRIP_END' || data.messageType === 'IGNITION_OFF') {
        iconName = '../../../assets/img/direction/red/';
      } else if (!data.endPacket && data.messageType !== 'TRIP_END' && data.messageType !== 'IGNITION_OFF') {
        iconName = '../../../assets/img/direction/green/';
      } else {
        iconName = '../../../assets/img/direction/gray/';
      }
    } else {
      iconName = '../../../../assets/img/direction/default/';
    }
    this.setIcon(data, iconName);
  }

  setIcon(data, icon) {
    const heading = data.heading;
    const publishedBy = data.publishedBy;
    const generation = data.generation;
    let iconName = `${icon}stop.png`;
    if (heading || heading == '0') {
      if (heading > 0 && heading <= 30) {
        iconName = `${icon}30.png`;
      } else if (heading > 30 && heading <= 60) {
        iconName = `${icon}60.png`;
      } else if (heading > 60 && heading <= 90) {
        iconName = `${icon}90.png`;
      } else if (heading > 90 && heading <= 120) {
        iconName = `${icon}120.png`;
      } else if (heading > 120 && heading <= 150) {
        iconName = `${icon}150.png`;
      } else if (heading > 150 && heading <= 180) {
        iconName = `${icon}180.png`;
      } else if (heading > 180 && heading <= 210) {
        iconName = `${icon}210.png`;
      } else if (heading > 210 && heading <= 240) {
        iconName = `${icon}240.png`;
      } else if (heading > 240 && heading <= 270) {
        iconName = `${icon}270.png`;
      } else if (heading > 270 && heading <= 300) {
        iconName = `${icon}300.png`;
      } else if (heading > 300 && heading <= 330) {
        iconName = `${icon}330.png`;
      } else if (heading > 330 && heading <= 360 || heading == '0') {
        iconName = `${icon}0.png`;
      }
    }
    if (publishedBy && publishedBy === 'RSU Simulation') {
      iconName = '../../assets/img/direction/v2x.png';
    } else if (publishedBy && publishedBy === 'OBU Simulation') {
      iconName = '../../assets/img/marker_red.png';
    } else if ((generation === 'EBIKE') || (publishedBy && publishedBy === 'EBIKE Simulation')) {
      iconName = '../../assets/img/direction/ebike.png';
    } else if ((generation === 'OBD_DASHCAM') || (publishedBy && publishedBy === 'OBD_DASHCAM Simulation')) {
      iconName = '../../assets/img/direction/obd_dashcam.png';
    } else if ((generation === 'LORAWAN')) {
      iconName = '../../assets/img/direction/lorawan.png';
    }
    const iconUrl = {
      url: iconName,
      labelOrigin: { x: 50, y: -15 }
    };
    data.iconUrl = iconUrl;
    return data;
  }

  // For loading more vehicles on clicking Load more button
  loadMoreVehicles() {
    this.page = ++this.page;
    if (this.timer) {
      this.workerSubscription.unsubscribe();
      if (!this.play) {
        this.autorefreshPlayPause();
      }
      this.getAllVehicleMessage(this.page, true);
    } else {
      this.getAllVehicleMessage(this.page, true);
    }
  }

  addStopTime(rows, stopTime) {
    rows.map(function (item, index) {
      return item['calculateStopTime'] = stopTime[index]
    });
    return rows;
  }

  refresh() {
    this.loadMore = false;
    // if (!this.place) {
    //   this.map.setZoom(this.zoom);
    // }
    this.liveVehicles = [];
    if (this.tracking) {
      this.resetTracking();
    }
    if (this.isDrawerOpen) {
      this.explicitCloseDrawer();
    }
    this.page = 0;
    if (this.timer) {
      if (
        this.searchValue !== '' ||
        this.selectedFilter !== '' ||
        this.speedfilter !== '' ||
        this.place ||
        this.isGroupFilterOn ||
        this.newPlace.circle.newCenter.lat != null
      ) {
        this.workerSubscription.unsubscribe();
        this.timerFunction(this.vehicleTypeValue);
      }
    } else {
      if (
        this.searchValue !== '' ||
        this.selectedFilter !== '' ||
        this.speedfilter !== '' ||
        this.place ||
        this.isGroupFilterOn ||
        this.newPlace.circle.newCenter.lat != null
      ) {
        this.loadingIndicator = true;
        this.vehicleSearch(this.searchValue);
      } else {
        this.getAllVehicleMessage(this.page);
        this.getVehicleCount();
      }
    }
  }

  // Important; DO NOT REMOVE THIS COMMENT
  // getAllVehicle() {
  //   this.infoWindow.close();
  //   this.newmarkerCluster.clearMarkers();
  //   this.clearMarkers();
  //   this.map.setZoom(4);
  //   this.addCluster(this.liveVehicles);
  // }

  getVehicleInfoWindowView(vehicle, tooltip?: boolean) {
    // tslint:disable-next-line:no-unused-expression
    if (vehicle.latitude, vehicle.longitude) {
      this.lat = vehicle.latitude;
      this.lng = vehicle.longitude;
    } else {
      vehicle.latitude = 0;
      vehicle.longitude = 0;
    }
    this.ngzone.run(() => {
      this.clearMarkers();
      if (this.vehicleView == false && vehicle.latitude == 0 && vehicle.longitude == 0) {
        this.clearMarkers();
        this.map.setZoom(14);
        // this.getAllVehicle();
      } else {
        this.addMarker(vehicle, tooltip);
      }
      // this.changeDetectorRef.detectChanges();
    });
  }

  onActivate(event) {
    // if(!this.route.snapshot.queryParams.assetName) {
    if (event.type == 'click' && event.cellIndex != -1) {
      // this.toggleExpandRow(event.row);
      if (this.timer) {
        if (!this.play) {
          this.autorefreshPlayPause();
        }
        // Do something when you click on row cell other than checkbox.
        // this.getVehicleInfoWindowView(event.row);
      }
      if (this.showLiveDashboard) {
        this.selectedVehicle = undefined;
      }
      this.infoWindowSelectedVehicle = event.row;
      this.toggleDrawer(event.row);
      // Do something when you click on row cell other than checkbox.
      this.getVehicleInfoWindowView(event.row, true);
    }
    // }
  }

  // To get preferences and permissions
  getPermissionsLiveHistory() {
    this.liveHistoryKey = [];
    const liveHistoryPrefrence = JSON.parse(localStorage.getItem('liveHistoryPrefrence'));
    if (liveHistoryPrefrence) {
      // this.livehistorySearchheader = liveHistoryPrefrence;
      this.liveHistoryKey = liveHistoryPrefrence.map(function (data) {
        return data.key;
      });
      if (this.liveHistoryKey.length) {
        this.liveHistorykeyString = this.liveHistoryKey.toString().concat(',').concat(this.liveHistoryAttribute);
      } else {
        this.liveHistorykeyString = this.liveHistoryAttribute;
      }
    } else {
      this.liveHistorykeyString = this.liveHistoryAttribute;
    }
  }

  getSingleVehicleView(deviceId, startTime, endTime) {
    try {
      this.getPermissionsLiveHistory();
      if (deviceId) {
        this.liveService.getSingleVehicleMessage(
          deviceId, 0, this.liveHistorykeyString, startTime, endTime, 10
        ).subscribe(res => {
          this.ngzone.run(() => {
            this.historyRows = res.slice(0, 10);
            if (this.historyRows.length > 0) {
              const labels = [];
              const y1Axis = [];
              const y2Axis = [];
              res.map(data => {
                if (data.vehicleSpeed && !Number.isInteger(data.vehicleSpeed)) {
                  let newvehicleSpeed = data.vehicleSpeed;
                  data.vehicleSpeed = parseFloat(newvehicleSpeed).toFixed(1);
                }
                var messageValue = configMessage.messageType[data.messageType];
                if (!messageValue) {
                  messageValue = data.messageType;
                }
                data.activity = messageValue;
              });

            }
          });
        }, error => {
          this.sharedService.getErrorMsg(error);
        });
      }
    } catch (e) {
      console.log(e.message);
    }
  }

  getEVMessage(deviceId, startTime, endTime) {
    try {
      let liveHistorykeyString = 'batteryKwhIn,batteryKwhOut,eventTime';
      if (deviceId) {
        this.liveService.getSingleVehicleMessage(deviceId, 0, liveHistorykeyString, startTime, endTime, 50, 'EV_PERIODIC').subscribe(res => {
          this.EVMessageData = res;
          if (res.length > 0) {
            const labels = [];
            const y1Axis = [];
            const y2Axis = [];
            res.map(data => {
              const datePipe = new DatePipe('en-US');
              const eventDate = datePipe.transform(data.eventTime, 'dd MMM');
              labels.push(eventDate);
              y1Axis.push(data.batteryKwhIn);
              y2Axis.push(data.batteryKwhOut);
            });
            this.drawLineGraph(labels, y1Axis, y2Axis);
          }
        }, error => {
          this.sharedService.getErrorMsg(error);
        });
      }
    } catch (e) {
      console.log(e.message);
    }
  }

  getMessages(deviceId, startTime, endTime, liveHistorykeyString, messageType) {
    if (this.internalBatteryChart) {
      this.internalBatteryChart.destroy();
    }
    if (this.fuelLevelChart) {
      this.fuelLevelChart.destroy();
    }
    if (this.vehicleBatteryPotentialGraph) {
      this.vehicleBatteryPotentialGraph.destroy();
    }
    this.batteryPotential = [];
    this.internalBattery = [];
    this.fuelLevel = [];
    try {
      if (deviceId) {
        this.liveService.getSingleVehicleMessage(deviceId, 0, liveHistorykeyString, startTime, endTime, 50, messageType).subscribe(res => {
          this.BSMessageData = res;
          if (res.length > 0) {
            const labels = [];
            res.reverse();
            res.map(data => {
              const datePipe = new DatePipe('en-US');
              const eventDate = datePipe.transform(data.eventTime, 'dd MMM');
              labels.push(eventDate);
              if (data.vehicleBatteryPotential) {
                this.batteryPotential.push(data.vehicleBatteryPotential);
              }
              if (data.internalBattery) {
                this.internalBattery.push(data.internalBattery);
              }
              if (data.fuelLevel) {
                this.fuelLevel.push(data.fuelLevel.toFixed(1));
              }
            });
            if (this.batteryPotential.length) {
              this.vehicleBatteryGraph(labels, this.batteryPotential);
            }
            if (this.internalBattery.length) {
              this.internalBatteryGraph(labels, this.internalBattery);
            }
            if (this.fuelLevel.length) {
              this.fuelLevelGraph(labels, this.fuelLevel);
            }
          }
        }, error => {
          this.sharedService.getErrorMsg(error);
        });
      }
    } catch (e) {
      console.log(e.message);
    }
  }


  getGPSMessage(deviceId) {
    try {
      if (deviceId) {
        const date = new Date();
        let endTime = date.toISOString().slice(0, 10);
        let lastDay = new Date(date.getTime() - (15 * 24 * 60 * 60 * 1000));
        let startTime = lastDay.toISOString().slice(0, 10);
        this.liveService.getvPacket(deviceId, startTime, endTime).subscribe(res => {
          this.vPacketData = res;
          if (res.length > 0) {
            const labels = [];
            const data1 = [];
            const data2 = [];
            res.map(data => {
              const datePipe = new DatePipe('en-US');
              const eventDate = datePipe.transform(data.bucket, 'dd MMM');
              labels.push(eventDate);
              data1.push(data.validFixCount);
              data2.push(data.invalidFixCount);
            });
            // let chartData={
            //   chartId: 'GPSChart',
            //   chartType: 'bar',
            //   label1: 'Valid Fix Count',
            //   color1: '#007bff',
            //   borderWidth: '1',
            //   label2: 'Invalid Fix Count',
            //   color2: 'dc3545',
            //   display: 'false',
            //   xStacked: 'true',
            //   yStacked: 'true',
            //   suggestedMin: '0',
            //   suggestedMax: '10'
            // };
            // this.drawChart(chartData,labels, data1, data2);
            this.GPSGraph(labels, data1, data2);
          }
        }, error => {
          this.sharedService.getErrorMsg(error);
        });
      }
    } catch (e) {
      console.log(e.message);
    }
  }

  getBatteryEvent(deviceId) {
    try {
      if (deviceId) {
        this.liveService.getBatteryEvent(deviceId).subscribe(res => {
          this.batteryEvent = res;
          const datePipe = new DatePipe('en-US');
          if (this.batteryEvent.mainBatteryConnectAt && this.batteryEvent.mainBatteryDisConnectAt) {
            var date = new Date().toUTCString();
            var currentTime = Date.parse(date);
            const reconnect = Date.parse(this.batteryEvent.mainBatteryConnectAt);
            const disconnect = Date.parse(this.batteryEvent.mainBatteryDisConnectAt);
            let reconnectDiff = currentTime - reconnect;
            let disconnectDiff = currentTime - disconnect;
            if (reconnectDiff > disconnectDiff) {
              const eventDate = datePipe.transform(this.batteryEvent.mainBatteryDisConnectAt, this.dateFormat);
              this.batteryEvent['connect'] = `Disconnect ${eventDate}`;
            } else {
              const eventDate = datePipe.transform(this.batteryEvent.mainBatteryConnectAt, this.dateFormat);
              this.batteryEvent['connect'] = `Reconnect ${eventDate}`;
            }
          } else if (this.batteryEvent.mainBatteryConnectAt) {
            const eventDate = datePipe.transform(this.batteryEvent.mainBatteryConnectAt, this.dateFormat);
            this.batteryEvent['connect'] = `Reconnect ${eventDate}`;
          } else if (this.batteryEvent.mainBatteryDisConnectAt) {
            const eventDate = datePipe.transform(this.batteryEvent.mainBatteryDisConnectAt, this.dateFormat);
            this.batteryEvent['connect'] = `Disconnect ${eventDate}`;
          }
        }, error => {
          this.sharedService.getErrorMsg(error);
        });
      }
    } catch (e) {
      console.log(e.message);
    }
  }

  getVehicleTime(value: any) {
    try {
      this.vehicle = value;
      // localStorage.setItem('liveSelectedVehicle', JSON.stringify(value));
      localStorage.setItem('defaultselectvehicle', JSON.stringify(value));
      // const userPref = JSON.parse(localStorage.getItem('preferences'));
      // if (userPref.liveHistory.toggle && userPref.liveHistory.toggle.tripsView) {
      this.sharedService.goToHistory({
        queryParams: {
          'liveSearch': this.searchValue,
          'liveKey': this.searchKey
        }
      });
      // } else {
      // this.router.navigate(['/location-history'], {
      //   queryParams: {
      //     'liveSearch': this.searchValue,
      //     'liveKey': this.searchKey
      //   }
      // });
      // }
    } catch (e) {
      console.log(e.message);
    }
  }

  redirectPage(page) {
    try {
      if (page == 'trips') {
        this.sharedService.goToHistory({
          queryParams: {
            'id': this.selectedVehicle.deviceId,
            'name': this.selectedVehicle.assetName
          }
        });
      }
    } catch (e) {
      console.log(e.message);
    }
  }

  redirectToReports(report) {
    const vehicle = this.selectedVehicle;
    try {
      localStorage.setItem('currentReport', JSON.stringify(report));
      localStorage.setItem('reportsVehicle', JSON.stringify(vehicle));
      this.sharedService.setdefaultselectvehicle(this.selectedVehicle);
      if (report.type === 'VISUALIZATION') {
        this.router.navigate(['/reports/device/visualization']);
      } else if (report.downloadable && report.type !== 'AL_PLANT_MODE') {
        this.router.navigate(['/reports/device/custom']);
      } else if (report.type === 'AL_PLANT_MODE') {
        this.router.navigate(['/reports/device/alPlantMode']);
      } else if (['vehicle', 'device', 'system', 'billing'].includes(report.reportType) || report.name === 'Trip Summary') {
        this.router.navigate([report.url]);
      } else {
        this.router.navigate(['/reports/device/messages']);
      }
    } catch (e) {
      console.log(e.message);
    }
  }

  redirectToDevice(searchKey) {
    try {
      this.router.navigate(['/devices'], {
        queryParams: {
          'searchBy': searchKey,
          'value': this.vehicleDetail[searchKey],
        }
      });
    } catch (e) {
      console.log(e.message);
    }
  }

  copyToClipboard() {
    var text = document.getElementById('text').innerText;
    var elem = document.createElement('textarea');
    document.body.appendChild(elem);
    elem.value = text;
    elem.select();
    document.execCommand('copy');
    document.body.removeChild(elem);
    this.copied = true;
    let global = this;
    setTimeout(function () {
      global.copied = false;
    }, 200);
  }

  getMessage(messageId) {
    this.messageLoader = true;
    this.dataService.getMessage(messageId).subscribe((res: any) => {
      this.messageLoader = false;
      this.messageFormattedString = this.sharedService.syntaxHighlight([res]);
    }, error => {
      this.messageLoader = false;
      this.sharedService.getErrorMsg(error);
    });
  }

  copyAssetName(name, index) {
    this.currentIndex = index;
    var elem = document.createElement('textarea');
    document.body.appendChild(elem);
    elem.value = name;
    elem.select();
    document.execCommand('copy');
    document.body.removeChild(elem);
    this.copiedAssetName = true;
    let global = this;
    setTimeout(function () {
      global.copiedAssetName = false;
    }, 1500);
  }

  // To download vehicle's table data in csv file
  downloadCsv() {
    this.liveService.downloadLiveSelectedDevices(
      this.selectedFilter, this.searchValue, this.searchKey, this.selectedGroup.id, this.searchFilter, this.newPlace
    ).subscribe({
      next: res => {
        const contentDisposition = res.headers.get('Content-Disposition');
        let filename;
        if (contentDisposition == null) {
          const date = new Date();
          filename = 'Live_Devices' + date + '.csv';
        } else {
          filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
        }
        const blob = new Blob([res.body], {
          type: 'text/csv;charset=utf-8'
        });
        FileSaver.saveAs(blob, filename);
      },
      error: err => {
        this.sharedService.getErrorMsg(err);
      }
    });
  }
  // download() {
  //   // Collecting data for the CSV file based on our csvLivePrefKeyArray Provided
  //   this.csvLivePrefHeaderArray[0] = 'Name';
  //   this.csvLivePrefHeaderArray[1] = 'Time';
  //   this.csvLivePrefKeyArray = Array.from(new Set(['assetName', 'eventTime']));
  //   const data = this.liveVehicles.map(vehicle => {
  //     return this.csvLivePrefKeyArray.map(csvHeader => {
  //       if (csvHeader === 'eventTime') {
  //         vehicle[csvHeader] = moment(vehicle[csvHeader]).format('MMM DD, hh:mm:ss a');
  //       }
  //       if (csvHeader === 'calculateStopTime') {
  //         vehicle[csvHeader] = 'Updated ' + vehicle[csvHeader];
  //       }
  //       return vehicle[csvHeader] || '';
  //     });
  //   });

  //   const options = {
  //     fieldSeparator: ',',
  //     quoteStrings: '"',
  //     decimalseparator: '.',
  //     showLabels: false,
  //     showTitle: false,
  //     useBom: false,
  //     headers: this.csvLivePrefHeaderArray
  //   };
  //   const date = new Date();
  //   const filename = 'Vehicles_' + date + '';
  //   new Angular5Csv(data, filename, options);
  // }

  // =========================================================================
  // AUTOREFRESH LOGIC STARTS HERE
  // To open Refresh tooltip box
  openRefreshPicker() {
    this.openRefreshPopup = this.openRefreshPopup != true;
  }

  // It starts timer if enabled by user in preferences and vice versa.
  // Also invoked while loading component if autorefresh enabled in preferences
  autoRefresh() {
    this.isLoading = false;
    const autoRefresh = JSON.parse(localStorage.getItem('preferences')).live.autoRefresh;
    if (autoRefresh) {
      if (autoRefresh.enabled === true) {
        if (autoRefresh.metrics === 'seconds' || autoRefresh.metrics === 'minutes') {
          this.toggleEffect = true;
          this.searchModel.enabled = true;
          const timeValue = autoRefresh.value;
          this.searchModel.value = timeValue;
          this.isLoading = true;
          this.setTimer(timeValue, autoRefresh.metrics);
        }
      } else {
        this.searchModel.value = 60;
        this.getAllVehicleMessage(0);
      }
    }
  }

  // Sets timer to minutes or seconds based on selection
  setTimer(time: number, metrics: string) {
    this.openRefreshPopup = false;
    if (this.tracking) {
      this.resetTracking();
    }
    if (this.isDrawerOpen) {
      this.explicitCloseDrawer();
    }
    if (this.timer) {
      this.workerSubscription.unsubscribe();
    }
    if (this.newPlace.circle.newCenter.lat != null) {
      this.locateDevice('true');
    }
    if (metrics === 'seconds') {
      this.vehicleTypeValue = time * 1000;
      this.searchModel.enabled = true;
      this.searchModel.metrics = 'seconds';
      this.searchModel.value = time;
      this.timerFunction(this.vehicleTypeValue);
      this.updateAutorefresh();
    } else if (metrics === 'minutes') {
      this.vehicleTypeValue = time * 60000;
      this.searchModel.enabled = true;
      this.searchModel.metrics = 'minutes';
      this.searchModel.value = time;
      this.timerFunction(this.vehicleTypeValue);
      this.updateAutorefresh();
    }
  }

  // Play and pause autorefresh locally without API call
  autorefreshPlayPause() {
    if (this.play === false) {
      this.toggleEffect = false;
      this.play = !this.play;
      this.workerSubscription.unsubscribe();
      this.stopWorker();
    } else {
      this.page = 0;
      if (this.tracking) {
        this.resetTracking();
      }
      if (this.isDrawerOpen) {
        this.explicitCloseDrawer();
      }
      this.play = !this.play;
      this.timerFunction(this.vehicleTypeValue);
    }
  }

  // Stops timer while updating autorefresh through API call
  stopTimer() {
    this.openRefreshPopup = false;
    this.timer = false;
    this.play = true;
    this.searchModel.enabled = false;
    this.stopWorker();
    this.updateAutorefresh();
  }

  // Timer function to refresh
  timerFunction(vehicleTypeValue) {
    // invoking worker to start autorefresh timer
    const token = localStorage.getItem('token');
    this.timer = true;
    this.play = false;

    const workerMessageNoSearch = {
      action: 'autorefreshON',
      subAction: 'none', page: this.page, pageLimit: this.pageLimit,
      liveKeyString: this.livekeyString,
      token: token, time: vehicleTypeValue
    };
    const workerMessageSearch: any = {
      action: 'autorefreshON',
      subAction: 'search',
      page: 0,
      pageLimit: this.pageLimit,
      liveKeyString: this.livekeyString,
      token: token,
      time: vehicleTypeValue,
      mType: this.selectedFilter,
      key: this.searchFilter['key'],
      value: this.searchFilter['value'],
      placeKey: this.searchFilter['placeKey'],
      placeValue: this.searchFilter['placeValue'],
      searchVal: this.searchValue,
      searchKey: this.searchKey,
      groupId: this.selectedGroup.id,
      // newPlace: this.newPlace
    };
    if (this.locateDeviceFilter) workerMessageSearch.newPlace = this.newPlace;
    if (
      this.searchValue !== '' ||
      this.selectedFilter !== '' ||
      this.speedfilter !== '' ||
      this.place ||
      this.isGroupFilterOn ||
      this.locateDeviceFilter
      // this.newPlace.circle.newCenter.lat != null
    ) {
      this.workerService.postMessage(workerMessageSearch);
    } else {
      this.workerService.postMessage(workerMessageNoSearch);
    }

    this.loadingIndicator = true;
    this.workerSubscription = this.workerService.workerSubject.subscribe(res => {
      // if (res && res.error) this.sharedService.getErrorMsg(res);
      this.isLoading = true;
      this.formatData(res);
    });
    this.isLoading = true;
  }

  updateAutorefresh() {
    this.isLoading = false;
    const payload = { live: { autoRefresh: this.searchModel } };
    this.sharedService.updatePreference(payload).subscribe(res => {
    });
    const livePreference: any = JSON.parse(localStorage.getItem('preferences'));
    livePreference.live.autoRefresh = this.searchModel;
    localStorage.setItem('preferences', JSON.stringify(livePreference));
  }

  // AUTOREFRESH LOGIC ENDS HERE
  // ============================================================================

  // To open vehicle infowindow when the name in the cluster is clicked.
  centerVehicle(event) {
    const val = event.toLowerCase();
    const temp = this.temp.filter(function (d) {
      return d.assetName.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.infoWindow.close();
    this.getVehicleInfoWindowView(temp[0]);

  }

  // to get all Reports
  getallReports() {
    this.detailBox = false;
    if (localStorage.getItem('reports')) {
      this.reportsMenu = [];
      let reports = JSON.parse(localStorage.getItem('reports'));
      reports.map(obj => {
        if (!obj.hidden) {
          this.reportsMenu.push(obj);
        }
      });
      this.reports = this.reportsMenu;
      this.reportsData = this.reports;
    } else {
      this.customLoader = true;
      this.sharedService.getReports().subscribe(res => {
        res.map(obj => {
          if (!obj.hidden) {
            this.reportsMenu.push(obj);
          }
        });
        this.staticReports = this.staticReports.filter(item => {
          if (item.reportType === 'system' && item.url.includes('trigger-report') && !this.AL_SystemReport) return false;
          if (item.reportType === 'device' && item.url.includes('trip-summary') && !this.tripAuthorities.includes('READ')) return false;
          if (item.reportType === 'billing' && !this.permissionModules.includes('BILLING')) return false;
          return true;
        });
        this.reportsMenu = [
          ...this.reportsMenu,
          ...this.staticReports
        ];
        localStorage.setItem('reports', JSON.stringify(this.reportsMenu));
        this.customLoader = false;
        this.reports = this.reportsMenu;
        this.reportsData = this.reports;
      }, error => {
        this.customLoader = false;
        this.sharedService.getErrorMsg(error);
      });
    }
  }

  filterReport(event) {
    const inputVal = event.target.value.toLowerCase();
    const val = inputVal.trim();
    const temp = this.reportsMenu.filter(function (d) {
      return d.name.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.reportsData = temp;
    this.reports = temp;
  }

  getRowClass(row): string {
    row['detailRow'] = true;
    return 'row-color';
  }

  // Drawer functions start
  toggleDrawer(vehicle) {
    if (this.selectedVehicle && this.selectedVehicle.deviceId) {
      if (this.selectedVehicle.deviceId === vehicle.deviceId) {
        this.isDrawerOpen = false;
        this.selectedVehicle = {};
        if (this.timer) this.autorefreshPlayPause();
      } else {
        this.openDrawer(vehicle);
      }
    } else {
      this.openDrawer(vehicle);
    }
  }

  openDrawer(vehicle) {
    if (this.tracking) {
      this.resetTracking();
    }
    const endTime = Date.parse(vehicle.eventTime);
    const startTime = endTime - (1000 * 60 * 60 * 4);
    this.drawerLoading = true;
    this.selectedVehicle = vehicle;
    this.vehicleDetail = {};
    this.snappedVehicle = {};
    if (this.isHealthTabActive) this.getDetailsForDeviceEvents();
    this.getSingleVehicleView(vehicle.deviceId, startTime, endTime);
    this.getDtcCodeCount(vehicle);
    this.getSnappedVehicle(vehicle);
    // this.getVehicleDetail();
    this.isDrawerOpen = true;
    // this.getVehicleHealthAndFuelScores(vehicle);
  }

  explicitCloseDrawer() {
    this.isDrawerOpen = false;
    this.selectedVehicle = {};
    this.snappedVehicle = {};
    this.vehicleDetail = {};
    if (this.timer) this.autorefreshPlayPause();
    this.destroyHelathFuelGraphs();
  }

  getDtcCodeCount(vehicle) {
    this.dashboardService.getVehicleStatus(vehicle.deviceId, '20').subscribe((res: any) => {
      if (res.count) {
        this.dtcCodeCount = res.count;
      }
    }, error => {
      this.drawerLoading = false;
      this.sharedService.getErrorMsg(error);
    });
  }

  getSnappedVehicle(vehicle) {
    const attributes = 'assetName,assetId,deviceId,gsmStrength,architectureType,' +
      'firmwareVersion,configVersion,tripNumber,odometer,vin,groupName,ignitionStatus,' +
      'vehicleBatteryPotential,internalBattery,fuelLevel,socGauge,distanceToEmpty,motorSpeedMcu,' +
      'motorTorqueMcu,engineOilPressure,ambientAirTemperature,engineOilTemperature,engineSpeed,' +
      'minimumVoltage,restingVoltage,avgInTripVoltage,timeToStart';
    this.liveService.getSnappedVehicle(vehicle.deviceId, attributes).subscribe(res => {
      this.snappedVehicle = res;
      this.totalTrips = this.snappedVehicle.tripNumber;
      if (this.snappedVehicle.gsmStrength) {
        if (this.snappedVehicle.gsmStrength >= -70) {
          this.signalTooltip = `Excellent Signal (${this.snappedVehicle.gsmStrength} dBm)`;
        } else if (this.snappedVehicle.gsmStrength >= -85 && this.snappedVehicle.gsmStrength < -70) {
          this.signalTooltip = `Good Signal (${this.snappedVehicle.gsmStrength} dBm)`;
        } else if (this.snappedVehicle.gsmStrength <= -86 && this.snappedVehicle.gsmStrength >= -100) {
          this.signalTooltip = `Fair Signal (${this.snappedVehicle.gsmStrength} dBm)`;
        } else if (this.snappedVehicle.gsmStrength < -100 && this.snappedVehicle.gsmStrength > -110) {
          this.signalTooltip = `Poor Signal (${this.snappedVehicle.gsmStrength} dBm)`;
        } else if (this.snappedVehicle.gsmStrength <= -110) {
          this.signalTooltip = `No Signal (${this.snappedVehicle.gsmStrength} dBm)`;
        }
      } else {
        this.signalTooltip = '--';
      }
      const endTime = Date.parse(vehicle.eventTime);
      const startTime = endTime - (1000 * 60 * 60 * 4);
      const liveHistorykeyString = 'vehicleBatteryPotential,internalBattery,fuelLevel,eventTime';
      // this.snappedVehicle.architectureType = 'PDBS6 Non EEA'
      if (this.snappedVehicle.architectureType === 'EV') {
        this.getEVMessage(vehicle.deviceId, startTime, endTime);
      } else if (this.snappedVehicle.architectureType === 'BS6_EDC') {
        const messageType = 'EDC_BS6_PERIODIC';
        this.getMessages(vehicle.deviceId, startTime, endTime, liveHistorykeyString, messageType);
      } else if (this.snappedVehicle.architectureType === 'BS6_EEA') {
        const messageType = 'EEA_BS6_PERIODIC';
        this.getMessages(vehicle.deviceId, startTime, endTime, liveHistorykeyString, messageType);
      } else if (this.snappedVehicle.architectureType === 'BS4_EDC') {
        const messageType = 'EDC_BS4_PERIODIC';
        this.getMessages(vehicle.deviceId, startTime, endTime, liveHistorykeyString, messageType);
      } else if (this.snappedVehicle.architectureType === 'BS4_EEA') {
        const messageType = 'EEA_BS4_PERIODIC';
        this.getMessages(vehicle.deviceId, startTime, endTime, liveHistorykeyString, messageType);
      }
      if (
        this.snappedVehicle.architectureType === 'BS6_EDC' ||
        this.snappedVehicle.architectureType === 'BS6_EEA' ||
        this.snappedVehicle.architectureType === 'BS4_EDC' ||
        this.snappedVehicle.architectureType === 'BS4_EEA'
      ) {
        this.getGPSMessage(vehicle.deviceId);
        this.getBatteryEvent(vehicle.deviceId);
      }
      this.drawerLoading = false;
    }, error => {
      this.drawerLoading = false;
      this.sharedService.getErrorMsg(error);
    });
  }

  toggleDetailPopup() {
    this.detailBox = !this.detailBox;
    this.getVehicleDetail();
    // if (!this.vehicleDetail.deviceId) {
    // }
  }

  getVehicleDetail() {
    this.liveService.getVehicleDetail(this.selectedVehicle.deviceId).subscribe(res => {
      this.vehicleDetail = res[0];
    }, error => {
      this.sharedService.getErrorMsg(error);
    });
  }

  closeDetails() {
    this.detailBox = false;
  }

  // START OF VEHICLE TRACKING
  // To keep track of the selected Vehicle
  trackVehicle(device) {
    this.showLiveDashboard = !this.showLiveDashboard;
    !this.showButton ? this.showButton : this.showButton = !this.showButton;
    const global = this;
    global.tracking = !global.tracking;
    this.selectedVehicle = device;
    const currentVehicle = Object.assign({}, this.selectedVehicle);
    global.trackedVehicle = currentVehicle;
    if (this.timer) {
      if (!this.play) {
        global.autorefreshPlayPause();
      }
    }
    if (global.tracking) {
      // collapse table
      this.openNav();
      if (this.newmarkerCluster) {
        this.newmarkerCluster.clearMarkers();
      }
      const subtractMilliseconds = global.subtractTime * 60000;
      const date = new Date(global.trackedVehicle.eventTime);
      const milliseconds = Date.parse(date.toUTCString());
      const startTime = milliseconds - subtractMilliseconds;
      const endTime = milliseconds;
      global.liveService.getPolylineData(global.trackedVehicle.deviceId, startTime, endTime).subscribe(async res => {
        global.vehicleLocations = [...res.locations];
        global.vehicleLocations = global.vehicleLocations.slice().reverse();
        await this.clearMarkers();
        await global.plotVehicle(res.locations);
        await this.keepTracking();
        this.trackingId = setInterval(() => {
          global.keepTracking();
        }, global.trackingTime);
      });
    } else {
      this.resetTracking();
    }
  }

  keepTracking() {
    const global = this;
    global.liveService.getVehicleLocation(global.trackedVehicle.deviceId).subscribe(async res => {
      if (res.length != 0) {
        global.vehicleLocations = [...global.vehicleLocations, ...res];
        global.latestCoOrds[0] = global.vehicleLocations[global.vehicleLocations.length - 2];
        global.latestCoOrds[1] = global.vehicleLocations[global.vehicleLocations.length - 1];
        global.trackedVehicle.latitude = res.latitude;
        global.trackedVehicle.longitude = res.longitude;
        global.vechileData = res;
        // this.liveDashboardPids(res);
        await global.plotVehicle(global.latestCoOrds);
        await global.clearMarkers();
        await global.addMarkerIcon(res);
        await global.addMarker(res);
      }
    });
    global.liveService.getVehicleEvents(global.trackedVehicle.deviceId).subscribe(async res => {
      this.liveDashboardPids(res);
    });
  }

  async resetTracking() {
    await clearInterval(this.trackingId);
    this.tracking = false;
    this.showButton = true;
    this.getVehicleInfoWindowView(this.trackedVehicle);
    await this.removePolyline();
    await this.clearMarkers();
    this.trackedVehicle = {};
    this.vehicleLocations = [];
    this.latestCoOrds = [];
    this.addCluster(this.liveVehicles);
    this.showLiveDashboard = false;
    if (!this.openedNav) this.openNav();
    if (this.timer) {
      this.autorefreshPlayPause();
    }
  }

  plotVehicle(data) {
    const flightPlanCoordinates = [];
    const bounds = new google.maps.LatLngBounds();

    data.forEach(item => {
      const latLngObj = {};
      if (item !== undefined && item.latitude !== undefined && item.longitude !== undefined) {
        latLngObj['lat'] = item.latitude;
        latLngObj['lng'] = item.longitude;
        flightPlanCoordinates.push(latLngObj);
        // to extends the bounds
        bounds.extend(new google.maps.LatLng(item.latitude, item.longitude));
      }
    });

    this.flightPath = new google.maps.Polyline({
      path: flightPlanCoordinates,
      geodesic: true,
      strokeColor: '#891d56',
      strokeOpacity: 0.8,
      strokeWeight: 3
    });

    this.flightPath.setMap(this.map);
    this.flightPlan.push(this.flightPath);
  }

  removePolyline() {
    this.flightPlan.map(line => {
      line.setMap(null);
    });
  }
  // END OF VEHICLE TRACKING

  // SIDE DRAWER EVENTS
  getDetailsForDeviceEvents() {
    if (this.selectedVehicle.deviceId !== null) {
      const attributes = 'assetName,assetId,deviceId,vin,tripNumber,odometer,vehicleProtocolId,' +
        'firmwareVersion,configVersion,eventTime,latitude,longitude,minimumVoltage,' +
        'restingVoltage,avgInTripVoltage,timeToStart,messageType';
      this.dashboardService.getVehicleDetail(this.selectedVehicle.deviceId, attributes).subscribe(res => {
        this.vehicleDetail = res;
        if (res.eventTime) {
          const endTime = Date.parse(res.eventTime);
          const startTime = endTime - 60 * 60 * 24 * 1000;
          this.getDeviceEvents();
          this.getVehicleCodeCount();
          if (this.permissionModules.includes('SCORE')) {
            // this.getAllScores();
            this.getDrivingBehaviour(startTime, endTime);
          }
        } else {
          this.deviceEvents = [];
          this.vehicleCount = { count: 0 };
          this.drivingBehaviour = {};
          // this.destroyCharts();
          if (this.progressBars.length) {
            this.destroyAllProgressBar();
          }
          this.createProgressBar();
        }
      }, error => {
        this.sharedService.getErrorMsg(error);
      });
    }
  }

  getDeviceEvents() {
    this.dashboardService.getDeviceEvents(this.selectedVehicle.deviceId, '20').subscribe((res: any) => {
      this.deviceEvents = res;
    }, error => {
      this.sharedService.getErrorMsg(error);
    });
  }

  getVehicleCodeCount() {
    this.dashboardService.getVehicleCount(this.selectedVehicle.deviceId, '20').subscribe((res: any) => {
      this.vehicleCount = res;
    }, error => {
      this.sharedService.getErrorMsg(error);
    });
  }

  getDrivingBehaviour(start, end) {
    const endDate = new Date();
    const endTime = endDate.getTime();
    const startTime = endTime - (60 * 60 * 24 * 30 * 1000);
    this.dashboardService.getDrivingBehaviour(this.selectedVehicle.deviceId, endTime, startTime)
      .subscribe((res: any) => {
        this.drivingBehaviour = res;
      }, error => {
        this.sharedService.getErrorMsg(error);
      });
  }

  getAllScores() {
    if (this.selectedVehicle.deviceId === null) return;
    // const endTime = Date.parse(new Date().toUTCString());
    // const startTime = endTime - (24 * 60 * 60 * 1000);
    var exposureProgressContainer = document.getElementById('exposureProgress');
    var behaviourProgressContainer = document.getElementById('behaviourProgress');
    var riskProgressContainer = document.getElementById('riskProgress');
    exposureProgressContainer.innerHTML = '';
    behaviourProgressContainer.innerHTML = '';
    riskProgressContainer.innerHTML = '';
    this.scoreService.getVehicleScores(this.selectedVehicle.deviceId).subscribe({
      next: res => {
        if (res.length) {
          this.dailyScore = res[0];
          this.exposureProgressBar = this.scoreProgressBar(this.dailyScore.exposure, exposureProgressContainer, 'Exposure', false);
          this.behaviourProgressBar = this.scoreProgressBar(this.dailyScore.behaviour, behaviourProgressContainer, 'Behaviour', false);
          this.riskProgressBar = this.scoreProgressBar(this.dailyScore.risk, riskProgressContainer, 'Risk', false);
        } else {
          this.createProgressBar();
        }
      },
      error: error => {
        this.createProgressBar();
        // this.sharedService.getErrorMsg(error);
      }
    });
  }

  createProgressBar() {
    if (this.progressBars.length) { this.destroyAllProgressBar(); }
    var exposureProgressContainer = document.getElementById('exposureProgress');
    var behaviourProgressContainer = document.getElementById('behaviourProgress');
    var riskProgressContainer = document.getElementById('riskProgress');
    this.exposureProgressBar = this.scoreProgressBar(0, exposureProgressContainer, 'Exposure', false);
    this.behaviourProgressBar = this.scoreProgressBar(0, behaviourProgressContainer, 'Behaviour', false);
    this.riskProgressBar = this.scoreProgressBar(0, riskProgressContainer, 'Risk', false);
  }

  scoreProgressBar(value, container, text, isHealthFuelGraph) {
    var bar = new ProgressBar.Circle(container, {
      // This has to be the same size as the maximum width to
      // prevent clipping
      strokeWidth: 7,
      trailWidth: 7,
      easing: 'easeOut',
      duration: 3000,
      text: {
        autoStyleContainer: false
      },
      from: { color: '#F4D03F', width: 1 },
      to: { color: '#229954', width: 4 },
      // Set default step function for all animate calls
      step: function (state, circle) {
        circle.path.setAttribute('stroke', state.color);
        var value = Math.round(circle.value() * 100);
        // circle.setText(value + '\n'+text);
        circle.setText(value
        );
        if (value > 85) {
          circle.path.setAttribute('stroke', '#229954');
        } else if (value > 60 && value <= 85) {
          circle.path.setAttribute('stroke', '#FFA500');
        } if (value > 30 && value <= 60) {
          circle.path.setAttribute('stroke', '#FF0000');
        } if (value <= 30) {
          circle.path.setAttribute('stroke', '#8B0000');
        }
        circle.path.setAttribute('stroke-linecap', 'round');
      }
    })
    if (!isHealthFuelGraph) {
      this.progressBars.push(bar);
    } else {
      this.healthFuelProgressBars.push(bar);
    }
    // bar.text.style.fontFamily = '"Raleway", Helvetica, sans-serif';
    bar.text.style.fontSize = '1rem';
    bar.text.style.textAlign = 'center';
    let progressValue = value / 100;
    bar.animate(progressValue);
  }

  destroyAllProgressBar() {
    this.progressBars.forEach(function (bar) {
      if (bar) { bar.destroy(); }
    });
    this.progressBars = [];
  }

  // GOOGLE MAPS START
  mapInit() {
    let mapOptionObject = {
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      zoom: this.zoom,
      minZoom: this.zoom,
      maxZoom: 17,
      // styles: this.mapStyles.darkMode,
      mapTypeControl: true,
      mapTypeControlOptions: {
        position: google.maps.ControlPosition.TOP_RIGHT
      },
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER
      },
      scaleControl: true,
      streetViewControl: true,
      streetViewControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER
      },
      fullscreenControl: false,
      center: {
        lat: environment.centerLat,
        lng: environment.centerLng
      },
      clickableIcons: false
    };
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapOptionObject);
  }

  addMarker(data, tooltip?: boolean) {
    if (this.tracking) {
      data.assetName = '';
      var image = {
        url: data.iconUrl.url,
        size: new google.maps.Size(71, 71),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(14, 13),
      };
    } else {
      image = data.iconUrl.url;
    }
    var latlng = new google.maps.LatLng(data.latitude, data.longitude);
    var marker = new google.maps.Marker({
      position: latlng,
      map: this.map,
      label: data.assetName,
      icon: image
    });
    // add infowindow
    if (tooltip) {
      this.setMarkerInfoWindow(data, marker);
    }
    marker['eventTime'] = data.eventTime;
    marker['deviceId'] = data.deviceId;
    var route = this.router;
    this.markerClick(marker);
    var bounds = new google.maps.LatLngBounds();
    bounds.extend(latlng);
    this.map.fitBounds(bounds);
    this.markers.push(marker);
  }

  setMarkerInfoWindow(d, marker) {
    const global = this;
    const prop = { color: '', badge: '' };
    if (d.messageType) {
      if (d.messageType === 'COMMUNICATION_LOST') {
        // prop.text = 'car';
        prop.color = '#f6b01d'; // Warning
        prop.badge = 'warning';
      } else if (d.messageType === 'TRIP_END' || d.messageType === 'IGNITION_OFF') {
        // prop.text = 'car';
        d.activity = 'Stopped';
        prop.color = '#dc3545'; // Red
        prop.badge = 'danger';
      } else if (!d.endPacket && d.messageType !== 'TRIP_END' && d.messageType !== 'IGNITION_OFF') {
        // prop.text = 'car';
        prop.color = '#469a25'; // Green
        prop.badge = 'success';
      } else {
        // prop.text = 'car';
        prop.color = '#555555'; // Dark Gray
        prop.badge = 'secondary';
      }
    }
    const datePipe = new DatePipe('en-US');
    const lastComm = datePipe.transform(d.eventTime, this.dateFormat);
    /*<a onclick="document.getElementById('shareTrackBtn').click();" style="margin-left: 5px;">
        <i class="fa fa-share-alt action-icons text-secondary" style="font-size: 14px;"></i>
      </a>*/
    const tooltipContent =
      `<div class="width100 pl-2 m-0 live-marker-infowindow">
      <p class="m-0"><span class="iw-heading" style="color: ${prop.color}">${d.assetName || '----'}</span></p>
      <p class="m-0"><label class="m-0">${d.vin || '----'}</label></p>
      <p class="m-0"><span class="badge badge-${prop.badge} text-light p-1">${d.activity}</span>
      <p class="m-0"><span>${lastComm || '----'}</span></p>
      <p class="m-0">
        <label>Go to <a href="javascript:void(0)" onclick="document.getElementById('historyLink').click();">History</a></label>

        ${!d.generation.includes('V2X') ? `
        <a onclick="document.getElementById('trackVehicleBtn').click();">
          <i class="fa fa-eye action-icons text-secondary" style="font-size: 14px;"></i>
        </a>` : ''}
        ${this.permissionModules.includes('DEVICE') ?
        `<a onclick="document.getElementById('deviceLink').click();">
            <i class="fa fa-microchip action-icons text-secondary" style="font-size: 14px;"></i>
          </a>`
        : null}
        ${this.permissionModules.includes('REPORT') ?
        `<a onclick="document.getElementById('reportsModalBtn').click();">
            <i class="fa fa-bar-chart action-icons text-secondary" style="font-size: 14px;"></i>
          </a>`
        : null}
      </p>
    </div>`;
    //   `<div class="row no-gutters align-items-center live-marker-infowindow">
    //   <div class="col-3 text-center">
    //     <i class="fa fa-${icon.text}" style="color: ${icon.color};"></i>
    //   </div>
    //   <div class="col-9 pl-2">
    //     <p class="m-0"><span class="iw-heading" style="color: ${icon.color}">${d.assetName || '----'}</span></p>
    //     <p class="m-0"><label>${d.vin || '----'}</label></p>
    //     <p class="m-0"><label>${d.serialNumber || '----'} ${d.productSerialNumber ? `(${d.productSerialNumber})` : ''}</label></p>
    //     <p class="m-0"><label>${lastComm || '----'}</label></p>
    //     <p class="m-0">
    //       <label>Go to <a href="javascript:void(0)" onclick="document.getElementById('historyLink').click();">History</a></label>
    //     </p>
    //   </div>
    // </div>`;
    // <label>or <a href="javascript:void(0)" onclick="document.getElementById('trackLink').click();">Track</a> device</label>
    var infoWindow = new google.maps.InfoWindow({
      content: tooltipContent
    });
    infoWindow['eventTime'] = d.eventTime;
    infoWindow['label'] = d.assetName;
    infoWindow['deviceId'] = d.deviceId;
    global.markerInfoWindow = infoWindow;
    infoWindow.open(global.map, marker);
  }

  addCluster(liveVehicles) {
    const markerCluster = [];
    var latlng;
    liveVehicles.forEach(item => {
      const latLngObj = {};
      if (item.latitude && item.longitude) {
        const marker = new google.maps.Marker({
          position: new google.maps.LatLng(item.latitude, item.longitude),
          label: item.assetName,
          title: item.assetName,
          icon: item.iconUrl.url,
        });
        marker['eventTime'] = item.eventTime;
        marker['deviceId'] = item.deviceId;

        this.markerClick(marker);
        markerCluster.push(marker);
      }
      latlng = new google.maps.LatLng(item.latitude, item.longitude);
    });
    if (markerCluster.length <= 1) {
      var bounds = new google.maps.LatLngBounds();
      bounds.extend(latlng);
      this.map.fitBounds(bounds);
    }
    // Addes marker clusterer to manage the markers.
    this.clusterHover(markerCluster);
  }

  clusterHover(markers) {
    const options_markerclusterer = {
      gridSize: 50,
      maxZoom: 16,
      zoomOnClick: true,
      imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
    };
    const obj = this;
    const markerCluster = new this.MarkerClustererPlus(this.map, markers, options_markerclusterer);
    this.newmarkerCluster = markerCluster;
    obj.infoWindow = new google.maps.InfoWindow();
    google.maps.event.addListener(markerCluster, 'mouseover', function (cluster) {
      obj.infoWindowText = [];
      const markers = cluster.getMarkers();
      for (let i = 0; i < markers.length; i++) {
        obj.infoWindowText.push(markers[i].getTitle());
      }
      obj.infoWindowTextCopy = obj.infoWindowText;
      if (obj.clusterLink) {
        if (this.map.getZoom() <= markerCluster.getMaxZoom()) {
          if (obj.markerInfoWindow) { obj.markerInfoWindow.close(); }
          obj.infoWindow.close();
          obj.infoWindow.setContent(obj.clusterLink.nativeElement);
          obj.infoWindow.setPosition(cluster.getCenter());
          obj.infoWindow.open(this.map);
        }
      }

    });
    google.maps.event.addListener(this.map, 'click', function () {
      obj.infoWindow.close();
    });
    google.maps.event.addListener(markerCluster, 'click', function () {
      obj.infoWindow.close();
    });
  }

  markerClick(marker) {
    const global = this;
    google.maps.event.addListener(marker, 'click', function () {
      global.goToLocationHistoryUsingEvent(marker);
    });
  }

  goToLocationHistoryUsingEvent(marker) {
    const route = this.router;
    marker['assetName'] = marker['label'];
    const liveSelectedVehicle = {
      eventTime: marker.eventTime,
      assetName: marker.assetName,
      deviceId: marker.deviceId,
    };
    const device = this.liveVehicles.filter((e) => { return e.deviceId === marker.deviceId });
    this.sharedService.goToHistory({}, device);
  }

  setMapOnAll(map) {
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(map);
    }
  }

  clearMarkers() {
    this.setMapOnAll(null);
  }

  // END OF GOOGLE MAPS

  // To get all place
  getAllPlace(page) {
    if (!this.places.length) {
      this.placesListLoading = true;
      this.placeService.getAllPlace(this.page).subscribe(res => {
        this.places = res;
        this.temp = res;
        this.placesListLoading = false;
      }, error => {
        this.sharedService.getErrorMsg(error);
      });
    }
  }

  addPlace() {
    this.router.navigate(['/admin/place'], {
      queryParams: {
        id: 1
      }
    });
  }

  filterGroups(event) {
    const inputVal = event.target.value.toLowerCase();
    const val = inputVal.trim();
    const temp = this.groupsMenu.filter(function (d) {
      return d.name.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.groups = temp;
  }


  openNav() {
    if (this.openedNav) {
      this.openedNav = false;
      document.getElementById('mySidebar').style.visibility = 'hidden';
      document.getElementById('tableContainer').style.display = 'none';
      document.getElementById('main').style.marginLeft = '0';
    } else {
      document.getElementById('mySidebar').style.visibility = 'visible';
      document.getElementById('tableContainer').style.display = 'block';
      document.getElementById('main').style.marginLeft = '27%';
      this.tableShow();
    }
  }

  tableShow() {
    let global = this;
    setTimeout(function () {
      global.openedNav = true
    }, 300);
  }

  // To get all favourites
  getAllfavourites() {
    if (!this.allfavouriteItems.length) {
      this.favouriteListLoading = true;
      this.sharedService.getAllfavourites(1).subscribe({
        next: (res: any) => {
          this.allfavouriteItems = res;
          this.favouriteListLoading = false;
        },
        error: (err: any) => {
          this.sharedService.getErrorMsg(err);
          this.favouriteListLoading = false;
        }
      });
    }
  }

  // To get all favourites
  deletefavourite() {
    this.sharedService.deletefavourite(this.selectedFavourite.id).subscribe({
      next: (res: any) => {
        this.allfavouriteItems = [];
        this.getAllfavourites();
        this.getAllVehicleMessage(0);
        this.hideModal = !this.hideModal;
        this.searchKey = 'assetName';
        this.searchValue = '';
        this.favouriteFilterKey = '';
        this.responseMessage = {
          success: true,
          message: enMessage.favdeleteSuccess
        };
        this.sharedService.setResponseMessage(this.responseMessage);
      },
      error: (err: any) => {
        this.hideModal = !this.hideModal;
        this.sharedService.getErrorMsg(err);
      }
    });
  }


  togglePopover() {
    this.popoverElement.nativeElement.click();
  }

  addfavourite(valid, form) {
    const payload = {
      name: this.favouriteText.favouriteValue,
      key: this.searchKey,
      value: this.searchValue,
      typeCode: 1
    };
    if (this.locateDeviceFilter) {
      payload.key = 'assetName';
      payload.value = this.liveVehicles.map(device => device.assetName).join();
    }
    if (this.favouriteText.favouriteValue && ((this.searchKey && this.searchValue) || this.locateDeviceFilter)) {
      this.disable = true;
      this.sharedService.addfavourite(payload).subscribe({
        next: (res: any) => {
          // this.getAllfavourites();
          this.allfavouriteItems = [];
          form.resetForm();
          this.togglePopover();
          this.disable = false;
          this.responseMessage = {
            success: true,
            message: enMessage.favAddSuccess
          };
          this.sharedService.setResponseMessage(this.responseMessage);
        },
        error: (err: any) => {
          this.hideModal = !this.hideModal;
          this.disable = false;
          this.sharedService.getErrorMsg(err);
        }
      });
    }
  }

  selectfavouriteFilter(data) {
    this.searchKey = data.key;
    this.searchValue = data.value;
    this.favouriteFilterKey = data.name;
    this.updateFilter('true');
  }

  getSelectedFavourite(e, obj) {
    e.stopPropagation();
    this.confirmDeleteAction = 'favourite';
    this.selectedFavourite = obj;
    this.showModal = !this.showModal;
  }

  getFavDetails(e, data) {
    if (data.key == 'serialNumber') {
      this.favEditingMode = true;
      e.stopPropagation();
      (<any>$('#editFavmodal').modal('show'));
      this.selectedFavourite = data;
      this.sharedService.getFavDetails(data.name).subscribe({
        next: (res: any) => {
          this.selectedFav = res;
          const selectedDevices = [];
          res.serialNumber.map(item => {
            const obj = {};
            obj['serialNumber'] = item;
            selectedDevices.push(obj);
          });
          this.querySelectDevice = selectedDevices;
          this.noDevicesSelected = this.querySelectDevice ? false : true;
        },
        error: (err: any) => {
          this.hideModal = !this.hideModal;
          this.sharedService.getErrorMsg(err);
        }
      });
    }
  }

  addDevices(e) {
    this.querySelectDevice = null;
    this.deviceList.devices = e;
    // e.map(device => {
    //   if (device.serialNumber) {
    //     this.deviceList.devices.push(device.serialNumber);
    //   } else {
    //     this.deviceList.devices.push(device)
    //   }
    // });
    if (this.deviceList.devices.length) {
      this.querySelectDevice = this.deviceList.devices;
    }
    this.noDevicesSelected = this.querySelectDevice ? false : true;
  }

  // To update fav
  save(value: any) {
    if (this.querySelectDevice) {
      this.noDevicesSelected = false;
      this.disable = true;
      const payload = {};
      const serialNumbers = [];
      if (this.querySelectDevice.length) {
        this.querySelectDevice.map(device => {
          serialNumbers.push(device.serialNumber);
        });
      }
      payload['key'] = this.selectedFav.key;
      payload['value'] = serialNumbers.join();
      this.sharedService.updateFav(this.selectedFavourite.name, payload).subscribe(res => {
        this.closeuploadDevice_model.nativeElement.click();
        this.responseMessage = {
          success: true,
          message: enMessage.favUpdateSuccess
        };
        this.sharedService.setResponseMessage(this.responseMessage);
        this.querySelectDevice = null;
        this.disable = false;
        this.allfavouriteItems = [];
        this.getAllfavourites();
        this.favEditingMode = false;
      }, error => {
        console.log(error);
        this.querySelectDevice = null;
        this.disable = false;
        this.favEditingMode = false;
        this.closeuploadDevice_model.nativeElement.click();
        this.sharedService.getErrorMsg(error);
      });
    } else {
      this.noDevicesSelected = true;
      this.disable = false;
    }
  }


  drawLineGraph(labels, data1, data2) {
    if (this.lineChart) {
      this.lineChart.destroy();
    }
    let ctx = document.getElementById('lineChartTotal');
    this.lineChart = new Chart(ctx, {
      type: 'line',
      responsive: true,
      data: {
        labels: labels,
        datasets: [
          {
            label: 'In',
            data: data1,
            borderColor: '#007bff',
            fill: false,
            borderWidth: 2,
            pointRadius: 0
          },
          {
            label: 'Out',
            data: data2,
            borderColor: '#dc3545',
            fill: false,
            borderWidth: 2,
            pointRadius: 0
          }
        ]
      },
      options: {
        title: {
          display: true,
          text: 'Battery kWh'
        },
        legend: {
          display: true
        },
        scales: {
          xAxes: [{
            display: false,
          }],
          yAxes: [{
            display: true
          }]
        }
      }
    });
  }

  vehicleBatteryGraph(labels, data1) {
    if (this.vehicleBatteryPotentialGraph) {
      this.vehicleBatteryPotentialGraph.destroy();
    }
    let ctx = document.getElementById('vehicleBatteryPotentialGraph');
    this.vehicleBatteryPotentialGraph = new Chart(ctx, {
      type: 'line',
      responsive: true,
      data: {
        labels: labels,
        datasets: [{
          label: 'Total',
          data: data1,
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgba(54, 162, 235, 1)',
          fill: true,
          borderWidth: 2,
          pointRadius: 0
        }
        ]
      },
      options: {
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            display: false,
            ticks: {
              beginAtZero: true
            }
          }],
          yAxes: [{
            display: false,
          }]
        }
      }
    });
  }

  internalBatteryGraph(labels, data1) {
    if (this.internalBatteryChart) {
      this.internalBatteryChart.destroy();
    }
    let ctx = document.getElementById('internalBatteryGraph');
    this.internalBatteryChart = new Chart(ctx, {
      type: 'line',
      responsive: true,
      data: {
        labels: labels,
        datasets: [{
          label: 'Total',
          data: data1,
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgba(54, 162, 235, 1)',
          fill: true,
          borderWidth: 2,
          pointRadius: 0
        }
        ]
      },
      options: {
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            display: false,
            ticks: {
              beginAtZero: true
            }
          }],
          yAxes: [{
            display: false,
          }]
        }
      }
    });
  }

  fuelLevelGraph(labels, data1) {
    if (this.fuelLevelChart) {
      this.fuelLevelChart.destroy();
    }
    let ctx = document.getElementById('fuelLevelGraph');
    this.fuelLevelChart = new Chart(ctx, {
      type: 'line',
      responsive: true,
      data: {
        labels: labels,
        datasets: [{
          label: 'Total',
          data: data1,
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgba(54, 162, 235, 1)',
          fill: true,
          borderWidth: 2,
          pointRadius: 0
        }
        ]
      },
      options: {
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            display: false,
            ticks: {
              beginAtZero: true
            }
          }],
          yAxes: [{
            display: false,
          }]
        }
      }
    });
  }

  GPSGraph(labels, data1, data2) {
    if (this.GPSChart) {
      this.GPSChart.destroy();
    }
    let ctx = document.getElementById('GPSChart');
    this.GPSChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{
          label: 'Valid Fix Count',
          data: data1,
          backgroundColor: '#007bff',
          borderColor: '#007bff',
          borderWidth: 1,
        }, {
          label: 'Invalid Fix Count',
          data: data2,
          backgroundColor: '#dc3545',
          borderColor: '#dc3545',
          borderWidth: 1,
        }]
      },
      options: {
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            stacked: true,
            display: false
          }],
          yAxes: [{
            stacked: true,
            display: false,
            suggestedMin: 0,
            suggestedMax: 10
          }]
        }
      }
    });
  }

  validateFav(event) {
    this.favouriteText.favouriteValue = event.target.value.replace(/[^_\-@.a-zA-Z0-9\s]/g, '');
  }

  // locateDevice
  searchMap() {
    var input = document.getElementById('pac-input');
    var searchBox = new google.maps.places.SearchBox(this.pacinput.nativeElement);
    let global = this;
    // Bias the SearchBox results towards current map's viewport.
    this.map.addListener('bounds_changed', function () {
      searchBox.setBounds(global.map.getBounds());
    });

    // Listen for the event fired when the user selects a prediction and retrieve
    // more details for that place.
    searchBox.addListener('places_changed', function () {
      var places = searchBox.getPlaces();

      if (places.length == 0) {
        return;
      }
      // For each place, get the icon, name and location.
      var bounds = new google.maps.LatLngBounds();
      places.forEach(function (place) {
        if (!place.geometry) {
          console.log('Returned place contains no geometry');
          return;
        }
        // global.locatePlace = place;
        global.locatePlace['name'] = place.name;
        // global.locatePlace['circle']['newCenter']['lat'] = place.geometry.location.lat();
        // global.locatePlace['circle']['newCenter']['lng'] = place.geometry.location.lng();
        global.newPlace.circle.newCenter.lat = place.geometry.location.lat();
        global.newPlace.circle.newCenter.lng = place.geometry.location.lng();
        // global.locateDeviceFilter = false;
      });
    });
  }

  locateDevice(isValid, form?) {
    if (isValid) {
      // this.newPlace = this.locatePlace
      // this.locatePlace.circle.newCenter.lat = this.newPlace.circle.newCenter.lat;
      // this.locatePlace.circle.newCenter.lng = this.newPlace.circle.newCenter.lng;
      this.locatePlace['newName'] = this.locatePlace.name;
      this.locateDeviceFilter = false;
      this.addRadius(Number(this.newPlace.circle.radius), this.newPlace.color, this.newPlace.circle.newCenter);
      if (this.timer === false || this.play === true) {
        this.liveService.searchVehicle(
          this.selectedFilter, this.searchValue, this.searchKey, this.selectedGroup.id, this.searchFilter, this.newPlace
        ).subscribe(res => {
          this.filter = true;
          this.liveVehicles = res;
          this.loadMore = false;
          this.mapFilter(res);
          this.vehicleTotalCount.count = res.length;
          this.isLoading = true;
          this.page = 0;
          this.locateDeviceFilter = true;
          if (!this.autoRefreshClick) {
            this.locatePopover.nativeElement.click();
            this.autoRefreshClick = true;
          }
        }, error => {
          this.isLoading = true;
          this.sharedService.getErrorMsg(error);
        });
      } else {
        this.filter = true;
        this.loadMore = false;
        this.isLoading = true;
        this.page = 0;
        this.locateDeviceFilter = true;
        if (!this.autoRefreshClick) {
          this.locatePopover.nativeElement.click();
          this.autoRefreshClick = true;
        }
        this.workerSubscription.unsubscribe();
        this.timerFunction(this.vehicleTypeValue);
      }
    }
  }

  addRadius(radius, color, location) {
    if (this.locateCityCircle) {
      this.locateCityCircle.setMap(null);
    }
    this.locateCityCircle = new google.maps.Circle({
      strokeColor: color,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: color,
      fillOpacity: 0.35,
      map: this.map,
      center: location,
      radius: radius,
      editable: true
    });
    let global = this;
    this.locateCityCircle.addListener('radius_changed', function (e) {
      global.newPlace.circle.radius = global.locateCityCircle.getRadius();
      global.radiusSetBounds(location);
    });
    this.radiusSetBounds(location);
  }

  radiusSetBounds(location) {
    var latlng = new google.maps.LatLng(location.lat, location.lng);
    var bounds = new google.maps.LatLngBounds();
    bounds.extend(latlng);
    this.map.fitBounds(this.locateCityCircle.getBounds());
  }

  clearLocateFilter(e) {
    this.isLoading = true;
    this.newPlace = {
      circle: {
        newCenter: {
          lat: null,
          lng: null
        },
        radius: 100
      },
      color: '#e01111'
    };
    this.locateDeviceFilter = false;
    this.locatePlace = {};
    this.map.setZoom(4);
    this.setMapCenter();
    if (this.locateCityCircle) {
      this.locateCityCircle.setMap(null);
    }
    if (this.timer === false || this.play === true) {
      this.isLoading = false;
      this.updateFilter(true);
    } else {
      this.isLoading = false;
      this.workerSubscription.unsubscribe();
      this.timerFunction(this.vehicleTypeValue);
      // this.checkForCount();

    }
  }

  // checkForCount() {
  //   if (
  //     this.searchValue !== '' ||
  //     this.selectedFilter !== '' ||
  //     this.speedfilter !== '' ||
  //     this.place ||
  //     this.isGroupFilterOn ||
  //     this.newPlace.circle.newCenter.lat != null
  //   ) {
  //     false;
  //   } else {
  //     this.liveService.getVehicleCount().subscribe({
  //       next: (res: any) => {
  //         this.vehicleTotalCount = res;
  //       },
  //       error: (err: any) => {
  //         this.sharedService.getErrorMsg(err);
  //       }
  //     });
  //   }
  // }

  getRadius(radius) {
    this.locateCityCircle.setRadius(Number(radius));
    this.map.fitBounds(this.locateCityCircle.getBounds());
  }

  closeLocatePopover() {
    this.locatePopover.nativeElement.click();
  }

  // Locations Save and other operations start
  getSavedLocations() {
    if (!this.savedLocations.length) {
      this.sharedService.getAllfavourites(2).subscribe({
        next: (res: any) => {
          this.savedLocations = res;
        },
        error: (err: any) => {
          this.sharedService.getErrorMsg(err);
        }
      });
    }
  }

  locateSavedLocation(obj, locate?) {
    this.locatePlace.newplaceName = obj.name;
    this.locatePlace.name = obj.name;
    this.newPlace.circle.radius = obj.radius;
    this.newPlace.circle.newCenter.lat = obj.latitude;
    this.newPlace.circle.newCenter.lng = obj.longitude;
    if (locate) {
      this.locateDevice(true);
    }
  }

  saveLocation() {
    const payload = {
      name: this.locatePlace.name,
      latitude: this.newPlace.circle.newCenter.lat,
      longitude: this.newPlace.circle.newCenter.lng,
      radius: this.newPlace.circle.radius,
      typeCode: 2
    };
    this.sharedService.addfavourite(payload).subscribe({
      next: (res: any) => {
        this.savedLocations = [];
        this.getSavedLocations();
        this.responseMessage = {
          success: true,
          message: enMessage.saveLocationSuccess
        };
        this.sharedService.setResponseMessage(this.responseMessage);
      },
      error: (err: any) => {
        this.sharedService.getErrorMsg(err);
      }
    });
  }

  confirmDeleteLocation(obj) {
    this.confirmDeleteAction = 'location';
    this.selectedSavedLocation = obj;
    this.showModal = !this.showModal;
  }

  deleteLocation() {
    this.sharedService.deletefavourite(this.selectedSavedLocation.id).subscribe({
      next: (res: any) => {
        this.savedLocations = [];
        this.getSavedLocations();
        this.hideModal = !this.hideModal;
        this.responseMessage = {
          success: true,
          message: enMessage.deleteLocationSuccess
        };
        this.sharedService.setResponseMessage(this.responseMessage);
      },
      error: (err: any) => {
        this.hideModal = !this.hideModal;
        this.sharedService.getErrorMsg(err);
      }
    });
  }
  // Locations Save and other operations end

  // All the Operations performed after the operation is confirmed from the modal
  onDeleteConfirm() {
    if (this.confirmDeleteAction === 'location') {
      this.deleteLocation();
    } else if (this.confirmDeleteAction === 'favourite') {
      this.deletefavourite();
    }
  }

  trackShare(isValid, form) {
    if (isValid && this.ctrl.status == 'VALID') {
      let payloadTime = this.time.hour * 60 * 60 + this.time.minute * 60;
      let payload = {
        'emailId': this.searchModel.emailId,
        'expiresIn': payloadTime,
        'deviceId': this.selectedVehicle.deviceId,
        'assetName': this.selectedVehicle.assetName
      };
      this.liveService.trackVehicle(payload).subscribe({
        next: (res: any) => {
          this.time = { hour: 1, minute: 59, second: 0 };
          form.resetForm();
          this.trackModel.nativeElement.click();
          this.responseMessage = {
            success: true,
            message: enMessage.locationShared
          };
          this.sharedService.setResponseMessage(this.responseMessage);
          // this.selectedVehicle = {};
        },
        error: (err: any) => {
          this.sharedService.getErrorMsg(err);
        }
      });
    }
  }

  goToDevice(row) {
    this.router.navigate(['/devices'], {
      queryParams: {
        searchBy: 'serialNumber',
        value: row.serialNumber
      }
    });
  }

  showShareTrackingModal(row) {
    document.getElementById('shareTrackBtn').click();
    this.selectedVehicle = row;
    this.autofocus(this.shareTrack);
  }

  showReportsModal(device?) {
    this.selectedVehicle = device ? device : this.selectedVehicle;
    this.getallReports();
    document.getElementById('goToReportsBtn').click();
    this.autofocus(this.reportSearch);
    // setTimeout(() => {
    //   const inputElem = <HTMLInputElement>this.reportSearch.nativeElement;
    //   inputElem.select();
    // }, 500);
  }

  autofocus(element) {
    setTimeout(() => {
      const inputElem = <HTMLInputElement>element.nativeElement;
      inputElem.select();
    }, 500);
  }

  ngOnDestroy() {
    this.isLoading = true;
    clearInterval(this.trackingId);
    if (this.timer) {
      if (!this.play) {
        this.autorefreshPlayPause();
      }
      this.workerSubscription.unsubscribe();
    }
  }

  public pasteinputValidator(data_value: any) {
    this.searchValue = " "
    this.updateFilter(true);
  }

  getVehicleHealthAndFuelScores(vehicle) {
    this.destroyHelathFuelGraphs();
    if (vehicle && vehicle.vin) {
      this.liveService.getVehicleHealthAndFuelScores(vehicle.vin).subscribe({
        next: res => {
          if (res) {
            var healthScoreProgressContainer = document.getElementById('healthScoreProgress');
            var fuelEconomyScoreProgressContainer = document.getElementById('fuelEconomyScoreProgress');
            this.healthScoreProgress = this.scoreProgressBar(res.healthScore ? res.healthScore : 0, healthScoreProgressContainer, 'Vehicle Health Score', true);
            this.fuelEconomyScoreProgress = this.scoreProgressBar(res.fuelEconomyScore ? res.fuelEconomyScore : 0, fuelEconomyScoreProgressContainer, 'Fuel Economy Score', true);
          } else {
            this.createHealtFuelProgressBar();
          }
        },
        error: error => {
          this.createHealtFuelProgressBar();
          // this.sharedService.getErrorMsg(error);
        }
      });
    } else {
      this.createHealtFuelProgressBar();
    }
  }

  createHealtFuelProgressBar() {
    var healthScoreProgressContainer = document.getElementById('healthScoreProgress');
    var fuelEconomyScoreProgressContainer = document.getElementById('fuelEconomyScoreProgress');
    this.healthScoreProgress = this.scoreProgressBar(0, healthScoreProgressContainer, 'Vehicle Health Score', true);
    this.fuelEconomyScoreProgress = this.scoreProgressBar(0, fuelEconomyScoreProgressContainer, 'Fuel Economy Score', true);
  }

  destroyHelathFuelGraphs() {
    this.healthFuelProgressBars.forEach(function (bar) {
      if (bar.svg) { bar.destroy(); }
    });
    this.healthFuelProgressBars = [];
  }

  dashboardTracking(row) {
    this.showLiveDashboard = !this.showLiveDashboard;
    this.trackVehicle(row);

  }

  closeVechilePopUp() {
    // explicitCloseDrawer
    this.showLiveDashboard = false;
    this.tracking = false;
  }

  // track device data
  liveDashboardPids(data) {
    if (data.hasOwnProperty("cog")) {
      let vechileData = [{ id: 1, name: "Ignition Status", value: data.ignitionStatus == 0 ? "OFF" : "ON", unit: "", icon: `../../../assets/img/pid_icons/1.png` },
      { id: 2, name: "Vechile Speed", value: data.obdSpeed, unit: "km/h", icon: `../../../assets/img/pid_icons/13.png` },
      { id: 3, name: "COG", value: data.cog, unit: "deg", icon: `../../../assets/img/pid_icons/cog.png` },
      { id: 4, name: "SOG", value: data.sog, unit: "km/h", icon: `../../../assets/img/pid_icons/12.png` },
      { id: 5, name: "Odometer", value: data.odometer, unit: "km", icon: `../../../assets/img/pid_icons/166.png` },
      { id: 6, name: "Altitude", value: data.altitude, unit: "m", icon: `../../../assets/img/pid_icons/altitude.png` },
      { id: 7, name: "Latitude", value: data.latitude.toFixed(5), unit: "DD", icon: `../../../assets/img/pid_icons/latLong.png` },
      { id: 8, name: "Longitude", value: data.longitude.toFixed(5), unit: "DD", icon: `../../../assets/img/pid_icons/latLong.png` }]
      this.obdsList = vechileData;
    }
    // let response = data;
    // let tempArr = [];
    // let orderArr = [13, 12, 17, 166, 5, 15, 1];
    // let orderArr1 = [{
    //   id: 1,
    //   value: 13
    // },
    // {
    //   id: 2,
    //   value: 12
    // },
    // {
    //   id: 3,
    //   value: 17
    // },
    // {
    //   id: 4,
    //   value: 166
    // },
    // {
    //   id: 5,
    //   value: 5
    // },
    // {
    //   id: 6,
    //   value: 15
    // },
    // {
    //   id: 7,
    //   value: 1
    // },
    // ];
    // let newObdsData = true;
    // const key = this.configMessage.allPids;
    // if (response.hasOwnProperty("obds")) {
    //   this.pastVechileData = response.obds;
    //   this.initialObdsLoad = true;
    // } else {
    //   newObdsData = false;
    // }

    // Object.keys(this.configMessage.allPids).forEach((ele) => {
    //   let finalObj = key[ele];
    //   let idx = orderArr.findIndex(x => x == finalObj.pid)
    //   if (idx > -1) {
    //     finalObj["idx"] = orderArr1[idx].id;
    //     finalObj["v"] = 0;
    //     finalObj["icon"] = `../../../assets/img/pid_icons/${finalObj.pid}.png`;
    //   }
    //   else {
    //     finalObj["v"] = 1;
    //     finalObj["idx"] = 8;
    //     finalObj["icon"] = `../../../assets/img/pid_icons/generic_pid.png`;
    //   }
    //   let obdVal = [];
    //   if (response.hasOwnProperty("obds")) {
    //     obdVal = response.obds.filter(obdEle => obdEle.pid == finalObj["pid"]);
    //   }

    //   // if (this.initialObdsLoad && !newObdsData) {
    //   //   obdVal = this.pastVechileData.obds.filter(obdEle => obdEle.pid == finalObj["pid"]);
    //   // }
    //   if (obdVal.length > 0) {
    //     // Object.assign(finalObj, { value: obdVal[0].values[0] });
    //     Object.assign(finalObj, { value: Number(obdVal[0].values[0]).toFixed() });

    //   } else {
    //     Object.assign(finalObj, { value: "-" });
    //   }
    //   tempArr.push(finalObj);
    // })
    // this.obdsList = [{
    //   pid: 0,
    //   icon: "../../../assets/img/pid_icons/1.png",
    //   description: 'Ignition status', unit: '', idx: 1, value: response.ignitionStatus === 0 ? "OFF" : "ON"
    // }, ...tempArr.sort((a, b) => a.description.toLowerCase().localeCompare(b.description.toLowerCase())).sort((a, b) => a.v - b.v).sort((a, b) => a.idx - b.idx)];
    if (data.hasOwnProperty("cog")) {
      if (data.cog < 45) {
        this.direction = "N"
      } else if (data.cog < 90) {
        this.direction = "NE"
      }
      else if (data.cog < 135) {
        this.direction = "E"
      }

      else if (data.cog < 180) {
        this.direction = "SE"
      }
      else if (data.cog < 225) {
        this.direction = "S"
      }
      else if (data.cog < 270) {
        this.direction = "SW"
      }
      else if (data.cog < 315) {
        this.direction = "W"
      }
      else if (data.cog < 360) {
        this.direction = "NW"
      }
    } else {
      // this.direction = "-"
    }
  }
}

