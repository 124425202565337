import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SupportService } from '@components/support/support.service';
import { SharedService } from '@shared/shared.service';
import { Router } from '@angular/router';
import { environment } from '@myenv/environment';
import { AuthenticationService } from '@layouts/login/authentication.service';
import { CookieService } from 'angular2-cookie/core';
import { enMessage } from '@shared/en-us-message';
import { configMessage } from '@shared/config-message';


@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss']
})
export class AdvancedSearchComponent implements OnInit, OnDestroy {
  @ViewChild('inputElement', { static: false }) inputOne: ElementRef;
  modules: any = [];
  moduleName = 'SUPPORT';
  permissionAuthorities: any = {};
  impersonatePermission: any = {};
  timeout = null;
  searchModel: any = {
    supportTypeVal: ''
  };
  public users: any = [];
  public devices: any = [];
  public vehicles: any = [];
  isLoading = false;
  currentUser: any = {};
  noResults = false;
  companyName = environment.companyName;
  custId: number;
  typeValue: string;
  impersonate = false;
  responseMessage: any = {};
  permissionModules: any = [];
  currentPermissionModules: any = [];
  /**
   * For date format
   */
  public isoDateTimeFormat = environment.isoDateTimeFormat;
  deviceStatus = configMessage.deviceStatus;
  deviceStatusClass = configMessage.deviceStatusClass;

  constructor(public router: Router,
    private supportService: SupportService,
    private sharedService: SharedService,
    private title: Title,
    private _cookieService: CookieService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this.title.setTitle('Support'+ environment.title_text);
    this.searchModel.searchType = 'emailId';
    this.searchModel.searchGroup = 'User';
    this.modules = JSON.parse(localStorage.getItem('accessRoleModules'));
    this.permissionAuthorities = this.modules.find(module => module.name === this.moduleName);
    this.impersonatePermission = this.modules.find(module => module.name === 'IMPERSONATE');
    this.currentPermissionModules = JSON.parse(localStorage.getItem('permissionModules'));
    const supportSearch = JSON.parse(localStorage.getItem('supportSearch'));
    if (supportSearch && supportSearch.searchGroup && supportSearch.supportTypeVal && supportSearch.searchType) {
      this.searchModel.searchGroup = supportSearch.searchGroup;
      this.searchModel.searchType = supportSearch.searchType;
      this.searchModel.supportTypeVal = supportSearch.supportTypeVal;
      this.onSearch();
    }
  }

  // Records the group name on select change
  onSlotChange(e) {
    const selectedIndex = e.target.selectedIndex;
    this.searchModel.searchGroup = e.target.options[selectedIndex].parentNode.getAttribute('label');
    this.onSearch();
  }

  searchDataOnChange() {
    if(this.searchModel.searchValue && this.searchModel.searchId) {
      this.onSearch();
    }
  }

  selectInput() {
    const inputElem = <HTMLInputElement>this.inputOne.nativeElement;
    inputElem.select();
  }

  public inputValidator(event: any) {
    // console.log(event.target.value);
    const pattern = /^[_\-@.,a-zA-Z0-9\s]*$/;
    // let inputChar = String.fromCharCode(event.charCode)
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^_\-@.,a-zA-Z0-9\s]/g, '');
      this.searchModel.supportTypeVal = event.target.value;
      this.onSearch();
    } else {
      this.onSearch();
    }
  }
  // 'show' button function for User group search
  // To search for users based on filter
  onSearch() {
    clearTimeout(this.timeout);
    let global = this;
    this.timeout = setTimeout(function () {
      if (global.searchModel.supportTypeVal) {
        global.users = [];
        global.devices = [];
        global.vehicles = [];
        global.typeValue = global.searchModel.supportTypeVal.trim();
        const searchGroup = global.searchModel.searchGroup;
        const supportSearch = {
          searchGroup: searchGroup,
          searchType: global.searchModel.searchType,
          supportTypeVal: global.typeValue
        };
        localStorage.setItem('supportSearch', JSON.stringify(supportSearch));
        if (searchGroup === 'User') {
          global.searchUser();
        } else if (searchGroup === 'Device') {
          global.searchDevice();
        } else if (searchGroup === 'Vehicle') {
          global.searchVehicle();
        }
      } else {
        global.users = [];
        global.devices = [];
        global.vehicles = [];
      }
    }, 1500);
  }

  // search all users
  searchUser() {
    this.supportService.onSearchUser(this.searchModel.searchType, this.typeValue).subscribe(res => {
      this.users = res;
      (this.users.length === 0) ? this.noResults = true : this.noResults = false;
    }, error => {
      this.sharedService.getErrorMsg(error);
    });
  }

  // search all devices
  searchDevice() {
    this.sharedService.onSearchDevice(this.searchModel.searchType, this.typeValue).subscribe(res => {
      this.devices = res;
      (this.devices.length === 0) ? this.noResults = true : this.noResults = false;
    }, error => {
      this.sharedService.getErrorMsg(error);
    });
  }

  // search all vehicles
  searchVehicle() {
    this.sharedService.onSearchVehicle(this.searchModel.searchType, this.typeValue).subscribe(res => {
      this.vehicles = res;
      (this.vehicles.length === 0) ? this.noResults = true : this.noResults = false;
    }, error => {
      this.sharedService.getErrorMsg(error);
    });
  }

  // get customer id for device, vehicle and search user based on that
  getCustomerId(value) {
    this.supportService.onSearchUser('customerId', +value).subscribe(res => {
      // this.getCurrentUser(res[0]);
      this.findSuperUser(res);
    });
  }

  findSuperUser(data) {
    data.map(item => {
      if (item.userType === 'SUPER_USER') {
        this.getCurrentUser(item);
        return;
      }
    });
  }

  // get the user info
  /* To Navigate to user-info page on clicking show button in table row
      passing the user name and user id as query params
  */
  getCurrentUser(value: any) {
    try {
      this.currentUser = value;
      this.router.navigate(['/support/user-info'], {
        queryParams: {
          id: value.userId,
          name: value.userName
        }
      });
    } catch (e) {
      console.log(e.message);
    }
  }

  impersonateUser(user) {
    this.authenticationService.impersonate(user.userId).subscribe(res => {
      // this.vehicles = res;
      // window.location.reload();
      if (res.accessToken) {
        let base64Token = res.accessToken;
        this.authenticationService.setHeaders(base64Token);
        this._cookieService.put('user', 'impersonateUser');
        if (this._cookieService.get('user')) {
          this.impersonate = true;
        }
        this.getUserPreferences();
        this.tokenScenario(res);
      } else if (res.status == 404) {
        this.authenticationService.setHeaders(this.authenticationService.getHeaders());
        const errorCode = res.errorCode;
        const errorMessage = enMessage[errorCode];
        this.responseMessage = { success: false, message: errorMessage };
        this.sharedService.setResponseMessage(this.responseMessage);
      }
    }, (error) => {
      var token = this.authenticationService.getHeaders();
      this.authenticationService.setHeaders(token);
      if (error.status == 404) {
        this.sharedService.getErrorMsg(error);
      } else {
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  // To get user preferences
  getUserPreferences() {
    // Get the User Preferences by making a seperate API call
    this.authenticationService.userPreferences().subscribe(responseInfo => {
      if (responseInfo) {
        localStorage.setItem('preferences', JSON.stringify(responseInfo));
      }
      if (responseInfo.liveHistory || responseInfo.live.header) {
        if (responseInfo.liveHistory && responseInfo.liveHistory.header) {
          this.authenticationService.liveHistoryPrefrences(responseInfo.liveHistory.header);
        }
        // if (responseInfo.live && responseInfo.live.header) {
        //   this.authenticationService.livePrefrences(responseInfo.live.header);
        // }
      }
    }, error => {
      this.sharedService.getErrorMsg(error);
    });
  }

  // setting the permissions and impersonating the user.
  tokenScenario(res) {
    this.authenticationService.getLoggedUser().subscribe((reponseInFo) => {
      if (res.status === 404) {
        const errorCode = res.errorCode;
        const errorMessage = enMessage[errorCode];
        this.responseMessage = { success: false, message: errorMessage };
        this.sharedService.setResponseMessage(this.responseMessage);
      } else {
        this.authenticationService.setLoggedInUser(reponseInFo);
        this.permissionModules = reponseInFo.modules.map(data => data.name);
        if (this.permissionModules.includes('REPORT')) {
          localStorage.setItem('lastReportUrl', '/reports/system/live-status');
        }
        this.sharedService.setAccessRole(reponseInFo.modules, this.permissionModules);
        let permissionModules = JSON.parse(localStorage.getItem('permissionModules'));
        this.sharedService.setDefaultUrl(permissionModules);
        window.location.reload();
        localStorage.removeItem('reports');
        localStorage.removeItem('dashboards');
        localStorage.removeItem('viewReport');
        localStorage.removeItem('reportData');
        localStorage.removeItem('currentReport');
        localStorage.removeItem('reportsVehicle');
        localStorage.removeItem('unreadNotification');
        localStorage.setItem('impersonate', 'true');
      }
    }, (error) => {
      this.sharedService.getErrorMsg(error);
    });
  }

  clear() {
    this.searchModel.supportTypeVal = '';
    localStorage.removeItem('supportSearch');
  }

  ngOnDestroy() {
    if (this.searchModel.supportTypeVal === '') {
      localStorage.removeItem('supportSearch');
    }
  }
}
