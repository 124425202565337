import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SharedService } from '@myproject/shared/shared.service';
import { GraphicReportsService } from '../graphic-reports.service';
import { environment } from '@myenv/environment';
import { DataService } from '@myproject/components/data/data.service';
import { LiveService } from '@myproject/components/live/live.service';
import * as FileSaver from 'file-saver';
var PacketHistoryReportComponent = /** @class */ (function () {
    function PacketHistoryReportComponent(title, sharedService, dataService, liveService, reportsService) {
        this.title = title;
        this.sharedService = sharedService;
        this.dataService = dataService;
        this.liveService = liveService;
        this.reportsService = reportsService;
        this.attributes = 'messageType,eventTime,ingestionTimestamp,sequenceNumber,latitude,longitude,altitude,heading,packetStatus,gpsSpeed,gpsOdometer,fixQuality,numberOfSatellites,configVersion,firmwareVersion';
        this.dateFormat = environment.smallDateFormat;
        this.permissionModules = [];
        this.messageTypeArray = [];
        this.pageInfo = {
            offset: 0,
            count: 0,
            limit: 10,
            pageSize: 0,
            lastPage: 0,
        };
        this.prevResponse = 0;
        this.page = 0;
        this.currentPageCount = {
            start: 0,
            end: 0
        };
        this.packetHistory = [];
        this.isLoading = false;
        this.selectedVehicle = null;
        this.selectedDevice = {};
        this.querySelectVehicle = null;
        this.searchModel = {
            value: null,
            messageType: null
        };
        this.hideCalendar = false;
        this.isSideMenuClose = false;
        // message JSON popup
        this.messageJsonLoader = false;
    }
    PacketHistoryReportComponent.prototype.ngOnInit = function () {
        this.title.setTitle('Packet History' + environment.title_text);
        this.permissionModules = JSON.parse(localStorage.getItem('permissionModules'));
        // this.getMessageType();
        this.isLoading = true;
    };
    PacketHistoryReportComponent.prototype.onTimeChange = function (e) {
        this.startTime = e.startTime;
        this.endTime = e.endTime;
        if (this.selectedVehicle)
            this.refresh();
    };
    PacketHistoryReportComponent.prototype.changeVehicle = function (e) {
        if (localStorage.getItem('reportsVehicle') && this.selectedVehicle == null) {
            this.setItem();
        }
        else {
            // if (e !== null) {
            localStorage.setItem('reportsVehicle', JSON.stringify(e));
            this.selectedVehicle = e.deviceId;
            this.selectedDevice = e;
        }
        this.searchModel.messageType = null;
        this.messageTypeArray = [];
        if (this.startTime && this.endTime)
            this.refresh();
    };
    PacketHistoryReportComponent.prototype.onSideMenuToggle = function () {
        this.isSideMenuClose = !this.isSideMenuClose;
        var temp = JSON.parse(JSON.stringify(this.packetHistory));
        this.packetHistory.length = 0;
        this.packetHistory = temp;
    };
    PacketHistoryReportComponent.prototype.getMessageType = function (architectureType) {
        var _this = this;
        this.dataService.getMessageType(architectureType).subscribe({
            next: function (res) {
                _this.messageTypeArray = res;
                // this.searchModel.messageType = this.messageTypeArray[0].messageType;
            },
            error: function (error) {
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    PacketHistoryReportComponent.prototype.refresh = function () {
        this.page = 0;
        this.pageInfo.offset = 0;
        this.isLoading = true;
        if (this.selectedDevice.architectureType)
            this.getMessageType(this.selectedDevice.architectureType);
        this.getPacketHistory(this.selectedVehicle, this.startTime, this.endTime, this.searchModel.messageType);
        this.getCounts(this.selectedVehicle, this.startTime, this.endTime, this.searchModel.messageType);
    };
    PacketHistoryReportComponent.prototype.setItem = function () {
        var reportsItem = JSON.parse(localStorage.getItem('reportsVehicle'));
        this.querySelectVehicle = reportsItem;
        this.selectedVehicle = reportsItem.deviceId;
        this.selectedDevice = reportsItem;
    };
    // refresh() {
    //   this.isLoading = true;
    //   this.getPacketHistory(this.selectedVehicle, this.startTime, this.endTime, this.searchModel.messageType);
    //   this.getCounts(this.selectedVehicle, this.startTime, this.endTime, this.searchModel.messageType);
    // }
    PacketHistoryReportComponent.prototype.getPacketHistory = function (deviceId, startTime, endTime, messageType) {
        var _this = this;
        this.liveService.getSingleVehicleMessage(deviceId, this.page, this.attributes, startTime, endTime, 100, messageType)
            .subscribe(function (res) {
            if (_this.page === 0)
                _this.packetHistory = res;
            else
                _this.packetHistory = tslib_1.__spread(_this.packetHistory, res);
            _this.prevResponse = res.length;
            _this.isLoading = false;
            // for pagination count
            if (_this.page === 0) {
                if (res.length) {
                    _this.currentPageCount.start = (_this.pageInfo.offset * _this.pageInfo.pageSize) + 1;
                    if (_this.packetHistory.length <= 10) {
                        _this.currentPageCount.end = _this.packetHistory.length;
                    }
                    else {
                        _this.currentPageCount.end = (_this.pageInfo.offset * _this.pageInfo.pageSize) + _this.pageInfo.limit;
                    }
                }
            }
        }, function (error) {
            _this.isLoading = false;
            _this.sharedService.getErrorMsg(error);
        });
    };
    PacketHistoryReportComponent.prototype.getCounts = function (deviceId, startTime, endTime, messageType) {
        var _this = this;
        // if(!this.searchModel.messageType) {
        this.isLoading = true;
        this.liveService.getPacketCount(deviceId, startTime, endTime, this.searchModel.messageType)
            .subscribe(function (res) {
            _this.totalCount = res;
        }, function (error) {
            _this.sharedService.getErrorMsg(error);
        });
        // }
    };
    PacketHistoryReportComponent.prototype.getMessageJSON = function (messageId) {
        var _this = this;
        this.messageJsonLoader = true;
        this.dataService.getMessage(messageId).subscribe(function (res) {
            _this.messageJsonLoader = false;
            _this.messageJsonRaw = JSON.stringify(res, null, 4);
            _this.messageJsonFormattedString = _this.sharedService.syntaxHighlight([res]);
        }, function (error) {
            _this.messageJsonLoader = false;
            _this.sharedService.getErrorMsg(error);
        });
    };
    PacketHistoryReportComponent.prototype.downloadMessageJSON = function () {
        var blob = new Blob([this.messageJsonRaw], {
            type: 'text/json;charset=utf-8'
        });
        FileSaver.saveAs(blob, 'message.json');
    };
    PacketHistoryReportComponent.prototype.pageCallback = function (value) {
        this.pageInfo.offset = value.offset;
        this.currentPageCount.start = (value.offset * value.pageSize) + 1;
        var lastPage = Math.floor(this.packetHistory.length / 10);
        if (lastPage == value.offset) {
            this.currentPageCount.end = this.totalCount.count;
        }
        else {
            this.currentPageCount.end = (value.offset * value.pageSize) + value.limit;
        }
        if (lastPage === (this.pageInfo.offset + 1)) {
            if (this.prevResponse) {
                this.page = ++this.page;
                this.getPacketHistory(this.selectedVehicle, this.startTime, this.endTime, this.searchModel.messageType);
            }
        }
    };
    PacketHistoryReportComponent.prototype.downloadCSV = function () {
        var _this = this;
        this.reportsService.downloadPacketHistoryCSV(this.selectedVehicle, this.startTime, this.endTime, this.searchModel.messageType)
            .subscribe({
            next: function (res) {
                var contentDisposition = res.headers.get('Content-Disposition');
                var filename;
                if (contentDisposition == null) {
                    var date = new Date();
                    filename = 'Packet_History_' + date + '.zip';
                }
                else {
                    filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
                }
                var blob = new Blob([res.body], {
                    type: 'application/zip'
                });
                FileSaver.saveAs(blob, filename);
            },
            error: function (err) {
                _this.sharedService.getErrorMsg(err);
            }
        });
    };
    return PacketHistoryReportComponent;
}());
export { PacketHistoryReportComponent };
