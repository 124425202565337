<app-notify-message></app-notify-message>
<div class="row">
  <!--<div class="col-md-12 col-lg-2 sidebar-shadow pr-0">-->
    <!--<app-side-menu [sideData]="message"></app-side-menu>-->
  <!--</div>-->
  <div class="col-lg-12 col-md-12 fullheight">
    <section id="main_content" *ngIf="!isLoading" class="bg">
      <div class="row mx-1 pb-2 align-items-center py-2">
        <div class="col-md-5">
          <h3 class="text-left page-heading mb-0">Move</h3>
        </div>
        <div class="col-md-3">
          <ng-select
            [items]="customers"
            bindLabel="customerId"
            bindValue="customerId"
            [multiple]="false"
            [closeOnSelect]="true"
            name="customerId"
            [(ngModel)]="searchModel.sourceCustomerId"
            placeholder="Select Customer ID"
            #sourceCustomer="ngModel"
            (change)="selectCustomer()" required="">
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <b>{{item.firstName }} {{item.lastName}} ({{item.customerId}})</b>
            </ng-template>
          </ng-select>
          <small class="text-danger"
            style="position: absolute;"
            *ngIf="!searchModel.sourceCustomerId">
            Please Select a Customer ID.
          </small>
        </div>
        <div class="col-md-3 pl-0">
          <form #f="ngForm">
            <div class="">
              <div class="input-group input-group-lg serialSearch">
                <input type="text" name="queryString" id="search" class="form-control ng-trim-ignore"
                  placeholder="Search by Serial Number" #inputElement [(ngModel)]="typeValue" autocomplete="off" (keyup)="onSearch(f.valid)">
                <div class="input-group-append">
                  <i class="fa fa-search img-responsive search-icon" aria-hidden="true"></i>
                  <!-- <i *ngIf="liveSearch" class="fa fa-times img-responsive search-icon" style="cursor: pointer;"
                    (click)="clearSearch()">
                  </i> -->
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-md-1 p-0">
          <button class="btn btn-primary" [disabled]="!selected.length"
            data-toggle="modal" data-target="#shift_device_modal">
            Move
          </button>
        </div>
      </div>
      <div class="row mx-1">
        <div class="col-12">
          <div class="page-loading" *ngIf="pageLoading">
            <img src="../../../assets/img/darby_loader_small.gif"/>
          </div>
          <div *ngIf="!devices.length && !pageLoading" class="text-center mt-5 py-5">
            <img width="170" src="../../../assets/img/info_nodata_256.png"/>
            <h4 class="mt-3">No data to show</h4>
          </div>
          <ngx-datatable *ngIf="devices.length"
            id="tableau" #dataTable
            class="move-customer-table bootstrap ngx-datatable alertTable reportsTable wrapcell active-table"
            [rows]="devices"
            [columnMode]="'force'"
            [headerHeight]="50"
            [footerHeight]="50"
            [rowHeight]="'auto'"
            [summaryRow]="false"
            [scrollbarH]="true"
            [reorderable]="true"
            [limit]="pageInfo.limit"
            [offset]="pageInfo.offset"
            [count]="pageInfo.count"
            (page)="pageCallback($event)"
            [loadingIndicator]="loadingIndicator"
            [selected]="selected"
            [selectionType]="'multiClick'"
            (select)="onSelect($event)"
            >
            <ngx-datatable-column [resizeable]="false" [sortable]="false" name="#" [width]="50">
              <ng-template let-rowIndex="rowIndex" ngx-datatable-cell-template>
                {{rowIndex+1}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Device ID" [sortable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.deviceId}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Serial Number" prop="serialNumber">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.serialNumber}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="IMEI" prop="imei">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.imei}}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Firmware Version" prop="firmwareVersion">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.firmwareVersion}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Config Version" prop="configVersion">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.configVersion}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="SIM CCID" prop="simCcid">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.simCcid}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="SIM Mobile Number" [sortable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.mobileNumber}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Customer ID" prop="customerId">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.customerId}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Last Communication " prop="lastCommunication">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.lastCommunication | date: dateFormat}}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Received At" prop="receivedAt">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.receivedAt | date: dateFormat}}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Created At" prop="createdAt">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.createdAt | date: dateFormat}}
              </ng-template>
            </ngx-datatable-column>
            <!-- <ngx-datatable-column>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <button type="button" class="btn btn-peak btn-danger btn-icon-left m-0"
                (click)="getCustomerId(row.customerId);">SHOW
              </button>
            </ng-template>
          </ngx-datatable-column> -->
          </ngx-datatable>
        </div>
      </div>
    </section>
  </div>
</div>

<!--start Popup upload your Vehicle-->
<div id="shift_device_modal" class="modal effect-flip-horizontal fade" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog" role="document">
      <div class="modal-content bd-0 bg-transparent overflow-hidden">
        <div class="modal-body p-0">
          <div class="row no-gutters">
            <div class="col-12">
              <span #closeshift_device_modal class="pull-right closeBtn" data-dismiss="modal"><i class="fa fa-times" aria-hidden="true"></i>
              </span>
              <div class="modal-box p-30">
                <div class="reply-header">
                  <h5>Move Devices</h5>
                  <!--<span></span>-->
                </div>
                <!--<div class="pt-20">-->
                <!--<a href="javascript:void(0)">Click here to download sample CSV File</a>-->
                <!--</div>-->
                <div class="col-md-12 p-0 mt-20">
                  <label for="destinationCustomer">Select Destination Customer</label>
                  <ng-select
                    [items]="customers"
                    bindLabel="customerId"
                    bindValue="customerId"
                    [multiple]="false"
                    [closeOnSelect]="true"
                    name="customerId"
                    [(ngModel)]="searchModel.destinationCustomerId"
                    placeholder="Select Customer IDs"
                    #destinationCustomer="ngModel"
                    required="">
                  </ng-select>
                </div>
                <small class="text-danger" *ngIf="notSelected">Please Select a Customer ID.</small>
                <small class="text-danger" *ngIf="sameCustId">Source and Destination Customer cannot be same.</small>
                <div class="row mt-20">
                  <div class="col-lg-12 sm-form-design">
                    <div class="login-buttons width100 text-right">
                      <button
                        #closeshift_device_modal
                        class="btn btn-outline-secondary mr-2"
                        data-dismiss="modal"
                        >
                        Cancel
                      </button>
                      <button
                        *ngIf="permissionAuthorities.authorities.includes('WRITE');"
                        type="button"
                        (click)="shiftDevices()"
                        [disabled]="moving"
                        class="btn btn-primary"
                        >Move
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end Popup upload your Vehicle-->
