<app-notify-message *ngIf="billingReportCSR !='true'"></app-notify-message>
<app-reports-header *ngIf="billingReportCSR !='true'"></app-reports-header>
<div class="row no-gutters reports-page" [ngClass]="(billingReportCSR =='true') ? 'billing-report-csr-view' : ''">
  <div class="col-md-2 col-sm-0 p-0" *ngIf="billingReportCSR !='true'">
    <app-reports-side-menu></app-reports-side-menu>
  </div>
  <div class="reports-body col-md-10 col-sm-12 p-0">
    <div class="row no-gutters action-row align-items-center px-2">
      <div class="col-md-12 text-center"  *ngIf="billingReportCSR =='true'">
        <h3 class="headingVehicleAdd font400 mt-2 page-heading">Billing Report</h3>
        </div>
      <div class="col-md-11" [ngClass]="(billingReportCSR !='true') ? 'row-select-asset' : ''">
        <h1 *ngIf="billingReportCSR !='true'" class="report-heading page-heading animated fadeIn" style="width: 50%; margin: 0;">
          <i class="fa fa-bar-chart"></i> Billing Report
        </h1>
        <div class="text-right col-lg-12 sm-form-design" [ngClass]="(billingReportCSR !='true') ? 'select-asset-type' : ''">
            <label style="top: -8px; background: white;" *ngIf="billingReportCSR =='true'">Select Customer</label>
          <select  *ngIf="billingReportCSR =='true'"
                          class="select-control"
                          style="float: left; font-size: 13px !important; font-weight: 200;"
                          name="selectCustomer"
                          placeholder="Select Customer"
                          data-parsley-required="true"
                          (change)="getCustomerData($event)">
                          <!-- <option [value]="All">All</option> -->
                          <option *ngFor="let customer of customersList let i = index;" 
                          [selected]="i == 0" [value]="customer.customerId" selected>
                            {{customer.customerId}} - {{customer.firstName}} {{customer.lastName}}</option>
              </select>

          <select
            class="select-control"
            style=" font-size: 13px !important; font-weight: 200;"
            [ngClass]="(billingReportCSR =='true') ? 'select-asset-type-csr' : ''"
            (change)="getTotalCount($event)">
            <option value="device"> <i class="fa fa-microchip"></i> Device</option>
            <!-- <option value="vehicle"><i class="fa fa-Bus"></i> Vehicle</option>  -->
          </select>
      </div>
    </div>
    </div>
  <div class="row no-gutters pt-2">
    <div class="col-md-12 px-3 py-2">
      <div class="outer-wrapper">
        <div class="report-item-container total-count-container total-device animated fadeIn">
          <span class="count">{{totalAssetCount}}</span>
          <h6 class="text">
            <span *ngIf="assetType === 'device'">Total Devices</span>
            <span *ngIf="assetType !== 'device'">Total Vehciles</span>
          </h6>
        </div>
      </div>
      <div class="outer-wrapper">
        <div class="report-item-container total-count-container total-messages-pushed animated fadeIn">
          <span class="count">{{totalMessagesCount}}</span>
          <h6 class="text">
            <span>Total Messages Pushed</span>
          </h6>
        </div>
      </div>
      <div class="outer-wrapper">
        <div class="report-item-container total-count-container account-status animated fadeIn"
          [class.active]="accountStatus==='Active'">
          <span class="count">{{accountStatus}}</span>
          <h6 class="text">
            <span>Account Status</span>
          </h6>
        </div>
      </div>
      <div class="outer-wrapper pr-0">
        <div class="report-item-container total-count-container account-expiry-date animated fadeIn"
          [class.active]="expiredDate === false">
          <span *ngIf="toDate != null" class="count">{{toDate | date: 'MM-dd-yyyy'}}</span>
          <span *ngIf="toDate === null" class="count">N/A</span>
          <h6 class="text">
            <span>Account Expiry Date</span>
          </h6>
        </div>
      </div>
      <div class="report-item-container" style="margin-top: 15px;">
      <div class="row no-gutters align-items-center action-row px-2" style="margin-top: 12vh;">
        <canvas id="stackedBarGraph" height="150" role="img"></canvas>
      </div>
      </div>
    </div>

  </div>
</div>
</div>
