import { OnInit, OnChanges, EventEmitter } from '@angular/core';
import { SharedService } from '@myproject/shared/shared.service';
import { DataService } from '@components/data/data.service';
var TimeSeekerComponent = /** @class */ (function () {
    function TimeSeekerComponent(sharedService, dataService) {
        this.sharedService = sharedService;
        this.dataService = dataService;
        this.showLabel = true;
        // @Input() selectedSeeker: boolean;
        this.onTimeSel = new EventEmitter();
        this.hourLineData = [];
        this.activeSeeker = [];
        this.html = '';
        this.changedDate = 1;
        this.month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        this.subscribedReq = [];
    }
    TimeSeekerComponent.prototype.ngOnInit = function () {
    };
    TimeSeekerComponent.prototype.ngOnChanges = function () {
        var _this = this;
        if (this.selectedDevice && this.tsTime && this.teTime) {
            if (this.subscribedReq.length) {
                this.subscribedReq[0].unsubscribe();
                this.subscribedReq = [];
            }
            this.changedDate = 1;
            var d = new Date();
            var offset = d.getTimezoneOffset() * -60;
            var date1_1 = new Date(this.tsTime);
            var date2 = new Date(this.teTime);
            if (date1_1.getMinutes() > 0) {
                date1_1.setMinutes(0);
                date1_1.setSeconds(0);
            }
            if (date2.getMinutes() >= 0) {
                date2.setMinutes(0);
                date2.setSeconds(0);
                date2.setHours(date2.getHours() + 1);
            }
            var hours_1 = (((date2.getTime() - date1_1.getTime()) / 1000) / 60) / 60;
            this.tsTime = date1_1.getTime();
            this.teTime = date2.getTime();
            var req = this.sharedService.getSeekerData(this.selectedDevice, this.tsTime, this.teTime, offset)
                .subscribe(function (res) {
                _this.hourLineData = [];
                _this.activeSeeker = [];
                if (res.length) {
                    var dateNow = new Date(_this.tsTime);
                    for (var i = 0; i < (hours_1); i++) {
                        var hour = date1_1.getHours();
                        var epochStart = new Date(date1_1.setMinutes(0)).setSeconds(0);
                        if (_this.changedDate === 1) {
                            if (date1_1.getDate() !== dateNow.getDate()) {
                                _this.hourLineData.push({ label: (hour > 12 ? (hour - 12) : hour), day: (hour >= 12 ? 'pm' : 'am'), dayChange: date1_1.getDate(), dayMonth: date1_1.getMonth(), count: res[i].count, ts: epochStart, te: (epochStart + (1 * 59 * 60 * 1000)), sel: false });
                                _this.changedDate += 1;
                            }
                            else {
                                _this.hourLineData.push({ label: (hour > 12 ? (hour - 12) : hour), day: (hour >= 12 ? 'pm' : 'am'), dayChange: i === 0 ? date1_1.getDate() : null, dayMonth: i === 0 ? date1_1.getMonth() : null, count: res[i].count, ts: epochStart, te: (epochStart + (1 * 59 * 60 * 1000)), sel: false });
                            }
                        }
                        else {
                            _this.hourLineData.push({ label: (hour > 12 ? (hour - 12) : hour), day: (hour >= 12 ? 'pm' : 'am'), dayChange: i === 0 ? date1_1.getDate() : null, dayMonth: i === 0 ? date1_1.getMonth() : null, count: res[i].count, ts: epochStart, te: (epochStart + (1 * 59 * 60 * 1000)), sel: false });
                        }
                        date1_1.setHours(hour + 1);
                    }
                }
            });
            this.subscribedReq.push(req);
        }
    };
    TimeSeekerComponent.prototype.onTimeClick = function (val, p1) {
        if (this.activeSeeker.includes(val.ts)) {
            var index = this.activeSeeker.indexOf(val.ts);
            this.activeSeeker.splice(index, 1);
        }
        else if (this.activeSeeker.length < 1 || this.activeSeeker.length === 1) {
            this.activeSeeker = [];
            this.activeSeeker.push(val.ts);
        }
        val['len'] = this.hourLineData.length;
        this.onTimeSel.emit(val);
    };
    return TimeSeekerComponent;
}());
export { TimeSeekerComponent };
