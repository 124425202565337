<app-notify-message></app-notify-message>
<app-reports-header></app-reports-header>
<div class="row no-gutters reports-page">
  <div class="col-md-2 col-sm-0 p-0">
    <app-reports-side-menu></app-reports-side-menu>
  </div>
  <div class="reports-body col-md-10 col-sm-12 p-0">
    <div class="row no-gutters action-row align-items-center px-2">
      <div class="col-md-10">
        <h4 class="report-heading page-heading animated fadeIn">
          <i class="fa fa-bar-chart"></i> Discovery
        </h4>
      </div>
      <div class="col-md-2 pr-4">
        <ng-select [items]="groups" [(ngModel)]="selectedGroups" bindLabel="name" placeholder="Select Group" placement="top" [ngClass]="{'text-danger': vehicleGroupFilterkey}"
          id="filterGroups" (change)="vehicleGroupFilter($event)"></ng-select>
      </div>
    </div>
    <div role="tabpanel" class="tab-pane fade in active show relative mt-3 mx-3" id="discovery">
      <div class="col-12">
          <div class="page-loading" *ngIf="pageLoading">
              <img src="../../../assets/img/darby_loader_small.gif" />
          </div>
          <div *ngIf="!discoveryReportData.length && !pageLoading" class="text-center mt-5 pt-5">
              <img width="170" src="../../../assets/img/info_nodata_256.png" />
              <h4 class="mt-3">No data to show</h4>
          </div>
          <ngx-datatable *ngIf="discoveryReportData.length" class="bootstrap alertTable reportsTable wrapcell"
              [rows]="discoveryReportData" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
              [reorderable]="true" [rowHeight]="'auto'" [scrollbarH]="true" [scrollbarV]="false"
              [loadingIndicator]="'true'" [limit]="pageInfo.limit" [offset]="pageInfo.offset" [count]="pageInfo.count"
              (page)="pageCallback($event)">
              <ngx-datatable-column [resizeable]="false" [sortable]="false" name="#" prop="#" [width]="5" [minWidth]="5">
                  <ng-template let-rowIndex="rowIndex" ngx-datatable-cell-template>
                      {{rowIndex+1}}
                  </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [resizeable]="false" name="IMEI" prop="imei" [width]="20" [minWidth]="20">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                      {{row.imei}}
                  </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [resizeable]="false" name="Group Name" prop="imei" [width]="20" [minWidth]="20">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.groupName}}
                </ng-template>
            </ngx-datatable-column>
              <ngx-datatable-column [resizeable]="false" name="VIN" prop="vin" [width]="50" [minWidth]="20">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.vin}}
                  </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column class="discovery-width" [resizeable]="false" name="Discovery Info" [sortable]="false" prop="discovery" [width]="350" [minWidth]="100">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.discovery}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column class="discovery-width" [resizeable]="false" name="Created At" [sortable]="false" prop="discovery" [width]="30" [minWidth]="20">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.createdAt}}
                </ng-template>
              </ngx-datatable-column>
          </ngx-datatable>
      </div>
    </div>
  </div>
</div>
