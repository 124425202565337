import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-confirm-delete-modal',
  templateUrl: './confirm-delete-modal.component.html',
  styleUrls: ['./confirm-delete-modal.component.scss']
})
export class ConfirmDeleteModalComponent implements OnInit, OnChanges {
  @Input() modalInstance = 1;
  @Input() showModal: boolean;
  @Input() hideModal: boolean;
  @Input() disableBtn: boolean;
  @Input() title: string;
  @Input() matchText: string;
  @Input() displayText: string;
  @Input() primaryAction: string;
  @Input() displayMessage?: string;
  @Input() hideInputconfirm?: boolean;
  @Input() type = 'danger';
  @Input() typeConfirmReq = true;
  @Output() confirmEvent = new EventEmitter();
  @Output() cancelConfirmEvent = new EventEmitter();
  confirmed = false;
  confirmText = '';
  modalId = '';
  modalCloseId = '';

  constructor() { }

  ngOnInit() {
    this.modalId = `deleteDeviceModel${this.modalInstance}`;
    this.modalCloseId = `closeModal${this.modalInstance}`;
  }

  ngOnChanges(changes) {
    this.modalId = `deleteDeviceModel${this.modalInstance}`;
    this.modalCloseId = `closeModal${this.modalInstance}`;
    // Show Modal
    if (changes.showModal && changes.showModal.previousValue !== undefined) {
      if (changes.showModal.previousValue !== changes.showModal.currentValue) {
        if (this.modalInstance === 1) {
          document.getElementById('deleteModal1').click();
        } else {
          document.getElementById('deleteModal2').click();
        }
        this.confirmText = '';
        this.confirmed = false;
      }
    }
    // Hide Modal
    if (changes.hideModal && changes.hideModal.previousValue !== undefined) {
      if (changes.hideModal.previousValue !== changes.hideModal.currentValue) {
        document.getElementById(this.modalCloseId).click();
      }
    }
  }

  matchCriteria() {
    // if (this.matchText) {
    //   if (this.confirmText === this.matchText) {
    //     this.confirmed = true;
    //   } else {
    //     this.confirmed = false;
    //   }
    // } else {
      if (this.confirmText.toLowerCase() === 'confirm') {
        this.confirmed = true;
      } else {
        this.confirmed = false;
      }
    // }
  }

  confirmDelete() {
    this.confirmEvent.emit();
  }
  cancelConfirm() {
    this.cancelConfirmEvent.emit();
  }
}
