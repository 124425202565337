
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@myenv/environment';

@Injectable()
export class TripsService {
  public apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  // To get the trips
  getTrips(deviceId, page, limit): Observable<any> {
    return this.http.get(
      this.apiUrl +
        'trips/device/' +
        deviceId +
        '?page=' +
        page +
        '&limit=' +
        limit
    );
  }

  getPolylineData(deviceId, startTime, endTime): Observable<any> {
    return this.http
      .get(
        this.apiUrl +
          `messages/device/${deviceId}` +
          `?attributes=eventTime,latitude,longitude,messageType,messageId,` +
          `maxSpeed,avgSpeed,initialSpeed,finalSpeed,maxBraking,initialSpeed,finalSpeed,maxAcceleration` +
          `&startTime=${startTime}&endTime=${endTime}`
      ).pipe(
      catchError(this.handleError));
  }

  getAllPids(): Observable<any> {
    return this.http
      .get(this.apiUrl + 'messages/datalogger/pids/supported').pipe(
      catchError(this.handleError));
  }

  getSupportedPids(tripId, tsTime): Observable<any> {
    return this.http
      .get(
        this.apiUrl +
          `messages/datalogger/pids/supported/device?tripId=${tripId}&startTime=${tsTime}`
      ).pipe(
      catchError(this.handleError));
  }

  getallMessages(deviceId, startTime, endTime, attrubute1, attribute2?): Observable<any> {
    let tempUrl = `&attributes=eventTime,${attrubute1}`;
    if (attribute2) {
      tempUrl += `,${attribute2}`;
    }
    return this.http
      .get(
        this.apiUrl +
          'messages/device/' +
          deviceId +
          '?startTime=' +
          startTime +
          '&endTime=' +
          endTime + tempUrl
      ).pipe(
      catchError(this.handleError));
  }

  handleError(error: any | any) {
    return observableThrowError(
      error.error || error.json() || error || 'Server error'
    );
  }
}
