<app-notify-message></app-notify-message>
<app-reports-header></app-reports-header>
<div class="row no-gutters reports-page">
  <div class="col-md-2 col-sm-0 p-0">
    <app-reports-side-menu></app-reports-side-menu>
  </div>
  <div class="reports-body col-md-10 col-sm-12 p-0">
    <div class="row no-gutters action-row align-items-center px-2">
      <div class="col-md-3 col-sm-12">
        <h4 class="report-heading page-heading mb-0"><i class="fa fa-bar-chart"></i> Vehicle Detail</h4>
      </div>
      <div class="col-md-6 col-sm-12">
        <!--<div class="row">-->
        <form (ngSubmit)="f.form.valid && onSearch()" #f="ngForm" novalidate class="search-wrapper width100">
          <div class="mt-1 d-flex">
            <div class="select-container text-left">
              <ng-select
                class="select-radius"
                [items]="vehicleSearchheader"
                bindLabel="displayName"
                bindValue="name"
                [multiple]="false"
                [closeOnSelect]="true"
                name="deviceId"
                [(ngModel)]="searchModel.deviceId"
                placeholder="Select Type"
                #deviceId="ngModel"
                (change)="searchDataOnChange(); selectInput()"
                required="">
              </ng-select>
              <!--<div class="alert-container" *ngIf="f.submitted && deviceId.invalid">-->
                <!--<small *ngIf="deviceId.errors.required" class="text-danger">Please select type</small>-->
              <!--</div>-->
            </div>
            <div class="container-input p-0">
              <input
                #inputElement
                (keyup)="inputValidator($event)"
                placeholder="Search" type="text"
                name="vehicleTypeVal" required
                [(ngModel)]="searchModel.vehicleTypeVal"
                #vehicleTypeVal="ngModel">
              <i class="fa fa-search img-responsive search-icon cursor-pointer" aria-hidden="true"></i>
            </div>
          </div>
        </form>
      </div>
      <div class="col-md-3 col-sm-12 d-flex mobile-design admin-columns">
        <div class="calendar d-inline-block ml-2 mt-1">
          <button
            class="btn btn-icon small-screen-btn columns"
            tooltip="Configure Columns"
            placement="top"
            (click)="togglePopup()">
            <i class="fa fa-columns"></i>
            <span class="mob-access-text d-none">&nbsp;Columns</span>
          </button>
          <div class="selectColumnsDropdown" *ngIf="columnPopup">
            <!-- <div class="title">
              <h5>Columns</h5>
            </div> -->
            <div class="column-search">
              <input type="text" name="userTypeVal" required [(ngModel)]="searchModel.columnVal"
                     #columnVal="ngModel" (keyup)="onColumnSearch()" autofocus placeholder="Search">
              <span class="cross-icon" *ngIf="searchModel.columnVal" (click)="columns = allColumns; searchModel.columnVal=''">
                <i class="fa fa-times" aria-hidden="true"></i>
              </span>
              <span><i class="fa fa-search img-responsive search-icon" aria-hidden="true"></i></span>
            </div>
            <div class="content">
              <div *ngIf="!this.searchModel.columnVal" class="item">
                <label class="box-container">Select All
                  <input type="checkbox" [checked]="allSelected" (change)="selectAllItems($event)">
                  <span class="checkmark"></span>
                </label>
              </div>
              <div class="item" *ngFor="let header of columns; let i=index;"
                   [class.disabled]="header.name=='assetName'">
                <label class="box-container">{{header.displayName}}
                  <input type="checkbox" [checked]="header.checked" (change)="selectItems($event, header, i)"
                         [disabled]="header.name=='assetName'">
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
            <div *ngIf="!columns.length" class="content">
              <div class="item text-center bold">
                No Data Found
              </div>
            </div>
            <div class="actions text-right">
              <button class="btn btn-outline-secondary small-screen-btn mr-1" (click)="cancelSelection()"
                      [disabled]="updatingPref">
                Cancel
              </button>
              <button class="btn btn-primary small-screen-btn" (click)="updatePreference()"
                      [disabled]="updatingPref">
                Apply
              </button>
            </div>
          </div>
          <button
            class="btn btn-icon ml-2 refresh"
            (click)="refresh()"
            tooltip="Refresh"
            placement="top"
            [disabled]="refreshing">
            <i class="fa fa-refresh" aria-hidden="true"></i>
            <span class="mob-access-text d-none">&nbsp;Refresh</span>
          </button>
          <a
            class="btn btn-icon ml-2 download" href="#"
            role="button" id="dropdownSubMenuLink"
            data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false"
            tooltip="Download"
            placement="top">
            <i class="fa fa-cloud-download"></i>
            <span class="mob-access-text d-none">&nbsp;Download</span>
          </a>
          <div class="dropdown-menu" aria-labelledby="dropdownSubMenuLink">
            <a class="dropdown-item" href="javascript:void(0)" (click)="downloadVehicleCSV()">CSV</a>
            <a class="dropdown-item" href="javascript:void(0)" (click)="downloadVehicleXML()">XML</a>
          </div>
        </div>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-md-12 px-2 py-3">
        <div class="report-item-container">
          <div class="page-loading" *ngIf="pageLoading">
            <img src="../../../assets/img/darby_loader_small.gif"/>
          </div>
          <div *ngIf="!vehicles.length && !pageLoading" class="text-center my-5 py-5">
            <img width="170" src="../../../assets/img/info_nodata_256.png"/>
            <h4 class="mt-3">No data to show</h4>
          </div>
          <ngx-datatable *ngIf="vehicles.length"
                         id="tableau" #dataTable
                         class="bootstrap alertTable reportsTable wrapcell"
                         [rows]="vehicles"
                         [columns]="finalHead"
                         [columnMode]="'force'"
                         [headerHeight]="50"
                         [footerHeight]="50"
                         [limit]="pageInfo.limit"
                         [offset]="pageInfo.offset"
                         [count]="pageInfo.count"
                         [rowHeight]="'auto'"
                         [summaryRow]="false"
                         [scrollbarH]="true"
                         [loadingIndicator]="loadingIndicator"
                         (page)="pageCallback($event)"
                         [reorderable]="true"
                         [externalSorting]="true"
                         (sort)="onSort($event)">

            <ngx-datatable-column [resizeable]="false" [width]="50" name="#">
              <ng-template let-rowIndex="rowIndex" ngx-datatable-cell-template>
                {{rowIndex+1}}
              </ng-template>
            </ngx-datatable-column>
            <ng-container *ngFor="let vh of finalHead">
              <ngx-datatable-column [resizeable]="false" name="{{vh.displayName}}" [width]="215" [sortable]="true">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span *ngIf="dateAttributes.includes(vh.name)">{{row[vh.name] | date : dateFormat}}</span>
                  <span *ngIf="!dateAttributes.includes(vh.name) && vh.name !== 'address'">{{row[vh.name]}}</span>
                  <a class="link-color" *ngIf="vh.name == 'address' && row['latitude'] && row['longitude']"
                     data-toggle="modal"
                     (click)="loadMap(row)"
                     data-target="#addNewUser_modal">
                    <span class="d-inline-block" [tooltip]="row[vh.name]" placement="top" *ngIf="row[vh.name]">{{row[vh.name] | ellipses:50}}</span>
                    <span *ngIf="!row[vh.name]">No address found</span>
                  </a>
                  <span *ngIf="vh.name == 'address' && (!row['latitude'] && !row['longitude'])">
                        No address found
                      </span>
                </ng-template>
              </ngx-datatable-column>
            </ng-container>
          </ngx-datatable>
          <div class="total-count pl-3" *ngIf="vehicleTotalCount && vehicleTotalCount.count">
            Showing
            <span contenteditable="false" (input)="currentPageCount.start = $event.target.innerText">
                {{currentPageCount.start}}
              </span>
            -
            <span contenteditable="false" (input)="currentPageCount.end = $event.target.innerText">
                {{currentPageCount.end}}
              </span>
            of {{(vehicleTotalCount.count)}} vehicles.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Latitude, longitude, map-->
<div data-keyboard="false" data-backdrop="static" id="addNewUser_modal" class="modal effect-flip-horizontal fade">
  <div class="modal-dialog addPlace-modal" role="document">
    <div class="modal-content bd-0 bg-transparent overflow-hidden">
      <div class="modal-body p-0">
        <div class="row no-gutters">
          <div class="col-12">
            <span #addNewUser_modal class="pull-right closeBtn" data-dismiss="modal"><i
              class="fa fa-times" aria-hidden="true"></i>
            </span>
            <div class="modal-box p-3 mt-4">
              <div class="reply-header">
                <h5>
                  Latest Location
                </h5>
                <!--<span></span>-->
              </div>
              <div class="row mt-3" *ngIf="selectedVehicle">
                <div class="col-sm-5" *ngIf="selectedVehicle.assetName">
                  <i class="fa fa-car mr-1"></i> <span class="break-word"> {{selectedVehicle.assetName}}</span>
                </div>
                <div class="col-sm-5" *ngIf="selectedVehicle.serialNumber">
                  <i class="fa fa-microchip mr-1"></i> <span class="break-word"> {{selectedVehicle.serialNumber}}</span>
                </div>
              </div>
              <div class="login-content mt-3">
                <div id="map" #gmap style="width:100%;height:40vh;"></div>
              </div>
              <div class="row mt-3" *ngIf="selectedVehicle">
                <div class="col-sm-10">
                  <span>Address:</span><br>
                  <span class="font-14" *ngIf="selectedVehicle.address"> {{selectedVehicle.address}}</span>
                  <span class="font-14" *ngIf="!selectedVehicle.address"> No address found</span>
                </div>
              </div>
              <div class="row mt-3 mb-5" *ngIf="selectedVehicle">
                <div class="col-sm-12">
                  <a class="font-14 text-blue-grey" [href]="'http://maps.google.com/?q='+selectedVehicle.latitude+','+selectedVehicle.longitude" target="_blank"><u>Latitude, longitude: {{selectedVehicle.latitude}}, {{selectedVehicle.longitude}} (Open in Google Maps)</u></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Latitude, longitude, map-->

