var ScriptService = /** @class */ (function () {
    function ScriptService() {
    }
    ScriptService.prototype.loadScript = function (dynamicScripts) {
        var isFound = false;
        var scripts = document.getElementsByTagName("script");
        for (var i = 0; i < scripts.length; ++i) {
            if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
                isFound = true;
            }
        }
        if (!isFound) {
            for (var i = 0; i < dynamicScripts.length; i++) {
                var node = document.createElement('script');
                node.type = 'text/javascript';
                node.charset = 'utf-8';
                node.async = true;
                node.src = dynamicScripts[i];
                document.getElementsByTagName('head')[0].appendChild(node);
            }
        }
    };
    ScriptService.prototype.loadCSS = function (urls) {
        for (var url = 0; i < urls.length; ++i) {
            // Create link
            var link = document.createElement('link');
            link.href = url[i];
            link.rel = 'stylesheet';
            link.type = 'text/css';
            var head = document.getElementsByTagName('head')[0];
            var links = head.getElementsByTagName('link');
            var style = head.getElementsByTagName('style')[0];
            // Check if the same style sheet has been loaded already.
            var isLoaded = false;
            for (var i = 0; i < links.length; i++) {
                var node = links[i];
                if (node.href.indexOf(link.href) > -1) {
                    isLoaded = true;
                }
            }
            if (isLoaded)
                return;
            head.insertBefore(link, style);
        }
    };
    return ScriptService;
}());
export { ScriptService };
