
import { Injectable } from '@angular/core';
import { throwError as observableThrowError, BehaviorSubject, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '@myenv/environment';
import { HttpHeaders, HttpClient, HttpBackend } from '@angular/common/http';

@Injectable()
export class AdminService {
  sideData = [];

  private messageSource = new BehaviorSubject(this.sideData);
  currentMessage = this.messageSource.asObservable();
  public apiUrl = environment.apiUrl;
  private httpClient: HttpClient;

  // To get the side menu
  getSideMenu() {
    const users = {
      name: 'menu.USER',
      img: 'user-circle-o',
      route: '/admin/users'
    };
    // const groups = {
    //   name: 'menu.GROUP',
    //   img: 'object-ungroup',
    //   route: '/admin/groups'
    // };
    const roles = {
      name: 'menu.ROLE',
      img: 'unlock-alt',
      route: '/admin/roles'
    };
    // const device = {
    //   name: 'menu.DEVICES',
    //   img: 'microchip',
    //   route: '/admin/device'
    // };
    // const settings = {
    //   name: 'menu.SETTINGS',
    //   img: 'cog',
    //   route: '/admin/settings'
    // };
    const developer = {
      name: 'menu.DEVELOPER',
      img: 'code-fork',
      route: '/admin/developer'
    };
    const alerts = {
      name: 'menu.ALERTS',
      img: 'warning',
      route: '/admin/alerts'
    };
    const place = {
      name: 'menu.PLACE',
      img: 'globe',
      route: '/admin/place'
    };
    const whitelabel = {
      name: 'menu.WHITELABEL',
      img: 'tachometer',
      route: '/admin/whitelabel'
    };
    // const headers = {
    //   name: 'menu.HEADERS',
    //   img: 'sun-o',
    //   route: '/admin/headers'
    // };
    const cache = {
      name: 'menu.CACHE-ATTRIBUTES',
      img: 'cubes',
      route: '/admin/cache-attributes'
    };
    const rules = {
      name: 'menu.RULES',
      img: 'gavel',
      route: '/admin/rules'
    };
    // const destination = {
    //   name: 'menu.DESTINATION',
    //   img: 'crosshairs',
    //   route: '/admin/destination'
    // };
    const audit = {
      name: 'menu.AUDIT-TRIAL',
      img: 'exchange',
      route: '/admin/audit-trial'
    };
    let marketplace={
      name: 'menu.MARKETPLACE',
      img: 'university',
      route: '/marketplace'
    };
    
    this.sideData = [];
    const permissionModules = JSON.parse(localStorage.getItem('permissionModules'));
    const sidedata = this.sideData;
    if (permissionModules.includes('USER')) sidedata.push(users);
    if (permissionModules.includes('ROLE')) sidedata.push(roles);
    if (permissionModules.includes('PLACE')) sidedata.push(place);
    if (permissionModules.includes('ALERT')) sidedata.push(alerts);
    if (permissionModules.includes('SETTING')) sidedata.push(whitelabel);
    if (permissionModules.includes('API')) sidedata.push(developer);
    // if (permissionModules.includes('DESTINATION')) sidedata.push(destination);
    if (permissionModules.includes('RULE')) sidedata.push(rules);
    if (permissionModules.includes('AUDIT-TRIAL')) sidedata.push(audit);
    if (permissionModules.includes('CACHE-ATTRIBUTES')) sidedata.push(cache);
    // if (permissionModules.includes('DEVICE')) sidedata.push(device);
    // if (permissionModules.includes('GROUP')) sidedata.push(groups);
    // if (permissionModules.includes('HEADER')) sidedata.push(headers);
    sidedata.push(marketplace);
    return sidedata;
  }

  constructor(
    private http: HttpClient,
    private handler: HttpBackend
  ) {
    this.httpClient = new HttpClient(handler);
  }

  changeMessage(message: any) {
    this.messageSource.next(message);
  }

  uploadLogo(data): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      // 'Content-Type': 'multipart/form-data'
    });
    return this.httpClient.post(this.apiUrl + 'users/logo', data, { headers }).pipe(
      catchError(this.handleError));
  }

  handleError(error: any | any) {
    return observableThrowError(error.error || error || 'Server error');
  }
}
