<!-- tab selection start -->
<div class="support-content">
    <ul class="nav nav-tabs detailed-tabs relative page-sub-heading" role="tablist">
        <li class="nav-item" routerLinkActive="active" *ngIf="permissionModules.includes('LIVE')">
            <a [routerLink]="['/live']" routerLinkActive="active" class="nav-link text-center mt-1" href="#live">
                <i class="fa fa-map-marker"></i> Live
            </a>
        </li>
        <li class="nav-item" routerLinkActive="active" *ngIf="permissionModules.includes('FLEET-DASHBOARD')">
            <a class="nav-link text-center mt-1 ml-4" [routerLink]="['/dashboard/default']" routerLinkActive="active"
                role="tab" href="#dashboard">
                <i class="fa fa-microchip"></i> Devices
            </a>
        </li>
        <!-- <li class="nav-item" routerLinkActive="active" *ngIf="permissionModules.includes('SYSTEM-DASHBOARD')">
            <a class="nav-link text-center mt-1 ml-4" [routerLink]="['/dashboard/system-dashboard']"
                routerLinkActive="active" role="tab" href="#system">
                <i class="fa fa-th"></i> System
            </a>
        </li> -->
        <li class="nav-item" *ngIf="permissionModules.includes('AL-DASHBOARD')" routerLinkActive="active">
            <a class="nav-link text-center mt-1" [routerLink]="['/dashboard/al-dashboard']" routerLinkActive="active"
                role="tab" href="#al">
                <i class="fa fa-line-chart"></i> AL
            </a>
        </li>
        <!-- <li class="nav-item" routerLinkActive="active" *ngIf="permissionModules.includes('EVENTS-DASHBOARD')">
            <a class="nav-link text-center mt-1" [routerLink]="['/dashboard/events-dashboard']" href="#events"
                routerLinkActive="active">
                <i class="fa fa-table"></i> Events Dashboard
            </a>
        </li> -->
        <li class="nav-item" routerLinkActive="active" *ngIf="permissionModules.includes('FLEET-DASHBOARD')">
            <a [routerLink]="['/dashboard/fleet-report']" routerLinkActive="active" class="nav-link text-center mt-1"
                role="tab" href="#fleet">
                <i class="fa fa-ravelry"></i> Fleet
            </a>
        </li>
        <li class="nav-item" routerLinkActive="active" *ngIf="permissionModules.includes('INSURANCE-DASHBOARD')">
            <a [routerLink]="['/dashboard/insurance-report']" routerLinkActive="active"
                class="nav-link text-center mt-1" role="tab" href="#insurance">
                <i class="fa fa-superpowers"></i> Insurance
            </a>
        </li>
        <!-- <li class="nav-item" routerLinkActive="active" *ngIf="permissionModules.includes('EV-DASHBOARD')">
            <a [routerLink]="['/dashboard/ev']" routerLinkActive="active" class="nav-link text-center mt-1" role="tab"
                href="#ev">
                <i class="fa fa-plug"></i> EV
            </a>
        </li> -->
        <li class="nav-item" routerLinkActive="active" *ngIf="permissionModules.includes('AZUGA-DASHBOARD')">
            <a class="nav-link text-center mt-1 ml-4" [routerLink]="['/dashboard/azuga']" routerLinkActive="active"
                role="tab" href="#azuga-dashboard">
                <i class="fa fa-microchip"></i> Status
            </a>
        </li>
    </ul>
</div>