import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { SharedService } from '../shared.service';
import { NgbDate, NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { GraphicReportsService } from '@myproject/components/graphic-reports/graphic-reports.service';
import Chart from 'chart.js';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-calendar-graph',
  templateUrl: './calendar-graph.component.html',
  styleUrls: ['./calendar-graph.component.scss']
})
export class CalendarGraphComponent implements OnInit, OnChanges {

  @Input() device: any = {};
  @Input() refresh: boolean;
  @Input() size = 'lg';
  @Input() showTemplate = false;

  hoveredDate: NgbDate;
  fromDate: NgbDate;
  toDate: NgbDate;
  maxRange: NgbDate;

  fromtime = { hour: 0, minute: 0 };
  totime = { hour: 23, minute: 59 };
  date = new Date();
  today = {
    year: this.date.getFullYear(),
    month: this.date.getMonth() + 1,
    day: this.date.getDate()
  };
  eventClass: any = {};
  autoselectEndDate = true;
  maxRangeDays = 0;
  // Chart Variables
  chartLoading = false;
  highestCount = 0;
  barChart: any;
  // dropdown Variables
  displayText = 'Quick Select';
  selectedPreset: string = null;
  // single day select event
  selectedEvent = '';

  constructor(
    private calendar: NgbCalendar,
    private reportsService: GraphicReportsService,
    private sharedService: SharedService
  ) {
    this.selectedPreset = '3month';
  }

  ngOnInit() {
    // this.drawChart();
  }

  ngOnChanges(changes) {
    // perform operation on device change
    if (changes.device && changes.device.previousValue !== undefined) {
      if (changes.device.previousValue !== changes.device.currentValue) {
        this.getDeviceEventDates();
        this.getDeviceData();
      }
    }
    // perform refresh
    if (changes.refresh && changes.refresh.previousValue !== undefined) {
      if (changes.refresh.previousValue !== changes.refresh.currentValue) {
        this.getDeviceEventDates();
        this.getDeviceData();
      }
    }
  }

  // Get the events on dates
  getDeviceEventDates() {
    const d = new Date();
    const end = Date.parse(d.toUTCString());
    d.setMonth(d.getMonth() - 12);
    const start = Date.parse(new Date(d.getFullYear(), d.getMonth(), 1).toUTCString());
    this.sharedService.getVehicleEventDates(this.device.deviceId, start, end).subscribe({
      next: res => {
        this.eventClass = {};
        if (res.noTrips.length) {
          res.noTrips.map(item => {
            const i = new Date(item);
            const attr = `${i.getFullYear()}-${i.getMonth() + 1}-${i.getDate()}`;
            this.eventClass[attr] = 'messageEvent';
          });
        }
        if (res.trips.length) {
          res.trips.map(item => {
            const i = new Date(item);
            const attr = `${i.getFullYear()}-${i.getMonth() + 1}-${i.getDate()}`;
            this.eventClass[attr] = 'tripEvent';
          });
        }
      },
      error: error => {
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  // get device data based on day selection
  getDeviceData() {
    if (this.selectedPreset === null) {
      this.getSingleDayMessageData(
        new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day),
        new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day)
      );
    } else {
      this.onQuickSelect(this.selectedPreset);
    }
  }

  // Select from Quick Links
  onQuickSelect(preset) {
    let start = null;
    let end = null;
    const t = new Date();
    this.selectedPreset = preset;
    if (preset === 'week') {
      this.displayText = 'This Week';
      const first = t.getDate() - t.getDay() + 1;
      const today = new Date();
      start = new Date(today.setDate(first));
      end = t;
    } else if (preset === 'month') {
      this.displayText = 'This Month';
      start = new Date(t.getFullYear(), t.getMonth(), 1, 0, 0, 0);
      end = new Date(t.getFullYear(), t.getMonth(), t.getDate(), 23, 59, 59);
    } else if (preset === '7days') {
      this.displayText = 'Last 7 Days';
      t.setDate(t.getDate() - 7);
      start = t;
      end = new Date();
    } else if (preset === '30days') {
      this.displayText = 'Last 30 Days';
      t.setDate(t.getDate() - 30);
      start = t;
      end = new Date();
    } else if (preset = '3month') {
      this.displayText = '3 months';
      start = new Date(t.getFullYear(), t.getMonth() - 2, 1);
      end = t;
    }
    this.fromDate = null;
    this.getTotalMessageData(start, end);
  }

  // Getting the total message data
  getTotalMessageData(startTime, endTime) {
    this.chartLoading = true;
    const start = `${startTime.getFullYear()}-${startTime.getMonth() + 1}-${startTime.getDate()}`;
    const end = `${endTime.getFullYear()}-${endTime.getMonth() + 1}-${endTime.getDate()}`;
    this.reportsService.getDeviceTotalMessageCount(this.device.deviceId, start, end).subscribe({
      next: res => {
        this.sortChartData(res);
      },
      error: error => {
        this.chartLoading = false;
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  sortChartData(data) {
    const labels = [];
    const counts = [];
    data.map(item => {
      const datePipe = new DatePipe('en-US');
      const eventDate = datePipe.transform(item.date, 'dd MMM');
      labels.push(eventDate);
      counts.push(item.count);
    });
    this.highestCount = Math.max(...counts);
    this.drawChart(labels, counts, 'rgb(54, 162, 235, 1)');
  }

  getSingleDayMessageData(startTime, endTime) {
    this.chartLoading = true;
    const start = `${startTime.getFullYear()}-${startTime.getMonth() + 1}-${startTime.getDate()}`;
    const end = `${endTime.getFullYear()}-${endTime.getMonth() + 1}-${endTime.getDate()}`;
    this.reportsService.getVehicleMessageData(this.device.deviceId, start, end).subscribe({
      next: res => {
        this.sortMessageData(res);
      },
      error: error => {
        this.chartLoading = false;
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  sortMessageData(data) {
    const labels = [];
    const counts = [];
    for (var k in data[0]) {
      if (!labels.includes(k) && k !== 'date' && k !== 'NO_DATA') {
        labels.push(k);
      }
    }
    labels.map(item => {
      counts.push(data[0][item]);
    });
    this.highestCount = Math.max(...counts);
    if (this.selectedEvent === 'tripEvent') this.drawChart(labels, counts, '#479a24');
    else if (this.selectedEvent === 'messageEvent') this.drawChart(labels, counts, '#9a66fe');
    else this.drawChart(labels, counts, '#969696');
  }

  // Chart draw
  drawChart(labels, counts, backgroundColor) {
    if (this.barChart) {
      this.barChart.destroy();
    }
    var ctx = document.getElementById('barGraph');
    this.barChart = new Chart(ctx, {
      type: 'bar',
      responsive: true,
      data: {
        labels: labels,
        datasets: [{
          label: 'Count',
          backgroundColor: backgroundColor,
          data: counts
        }]
      },
      options: {
        legend: {
          position: 'bottom',
          display: true
        },
        scales: {
          xAxes: [{
            display: true,
            stacked: true
          }],
          yAxes: [{
            display: true,
            stacked: true,
            ticks: {
              suggestedMin: 0,
              suggestedMax: 10,
              stepSize: this.highestCount > 10 ? null : 2
            }
          }]
        }
      }
    });
  }

  // Calendar functions start
  onDateSelection(date: NgbDate) {
    const attr = `${date.year}-${date.month}-${date.day}`;
    if (this.eventClass[attr]) {
      this.fromDate = date;
      this.displayText = 'Quick Selection';
      this.selectedPreset = null;
      this.selectedEvent = this.eventClass[attr];
      this.getDeviceData();
    }
  }

  setMaxRange(date: NgbDate): NgbDate {
    if (this.maxRangeDays >= 0) {
      return this.calendar.getNext(date, 'd', this.maxRangeDays);
    } else {
      return this.calendar.getNext(date, 'd', 30);
    }
  }

  getEventClass(date: NgbDateStruct) {
    const attr = `${date.year}-${date.month}-${date.day}`;
    if (this.eventClass[attr]) {
      return this.eventClass[attr];
    } else {
      return 'noEvent';
    }
  }

}
