import { OnInit } from '@angular/core';
import { SharedService } from '@shared/shared.service';
import { Title } from '@angular/platform-browser';
import { environment } from '@myenv/environment';
import { configMessage } from '@shared/config-message';
import { GraphicReportsService } from '@components/graphic-reports/graphic-reports.service';
var MultiVehicleDownloadReportComponent = /** @class */ (function () {
    function MultiVehicleDownloadReportComponent(reportsService, sharedService, title) {
        this.reportsService = reportsService;
        this.sharedService = sharedService;
        this.title = title;
        this.apiUrl = environment.apiUrl;
        this.dateFormat = environment.largeDateFormat;
        this.dateAttributes = configMessage.dateAttributes;
        this.maxDate = new Date();
        this.temp = [];
        this.reportsMenu = [];
        this.vehicles = [];
        this.currentReport = {};
        this.querySelectDevice = null;
        this.querySelectVehicle = null;
        this.page = 0;
        this.isLoading = false;
        this.noReports = false;
        this.report = {};
        this.columMenu = [];
        this.currentPageCount = {
            start: 0,
            end: 0
        };
        this.searchModel = {
            messageCount: '',
            value: null,
            vehicle: null,
            device: null
        };
        this.noVehiclesSelected = false;
        this.reportId = null;
        this.pageInfo = {
            offset: 0,
            count: 0,
            limit: 10,
            pageSize: 0,
            lastPage: 0,
        };
        this.reports = [];
        this.itemsPerPage = 10; // we are showing 10 items per page
        this.lastPage = 0;
        this.disable = false;
        this.selectItemError = false;
        this.permissionModules = [];
        this.modules = [];
        this.permissionAuthorities = {};
        this.moduleName = 'REPORT';
        this.totalMessageCount = {};
        this.hideCalendar = false;
        this.isSideMenuClose = false;
    }
    MultiVehicleDownloadReportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.modules = JSON.parse(localStorage.getItem('accessRoleModules'));
        this.permissionAuthorities = this.modules.find(function (module) { return module.name === _this.moduleName; });
        this.permissionModules = JSON.parse(localStorage.getItem('permissionModules'));
        this.title.setTitle('Reports' + environment.title_text);
        this.setReport();
    };
    MultiVehicleDownloadReportComponent.prototype.getReport = function (report) {
        this.currentReport = report;
        this.setReport();
    };
    MultiVehicleDownloadReportComponent.prototype.onTimeChange = function (e) {
        this.startTime = e.startTime;
        this.endTime = e.endTime;
        this.page = 0;
    };
    MultiVehicleDownloadReportComponent.prototype.changeDevice = function (e) {
        if (localStorage.getItem('reportsDevice') && this.querySelectDevice == null) {
            this.setItem();
        }
        else {
            localStorage.setItem('reportsDevice', JSON.stringify(e));
            this.selectedItem = e;
            this.searchModel.value = e ? e.deviceId : null;
            this.searchModel.device = e ? e.serialNumber : null;
            this.querySelectDevice = e;
        }
    };
    MultiVehicleDownloadReportComponent.prototype.onSideMenuToggle = function () {
        this.isSideMenuClose = !this.isSideMenuClose;
    };
    MultiVehicleDownloadReportComponent.prototype.refresh = function () {
        this.page = 0;
        this.setReport();
    };
    MultiVehicleDownloadReportComponent.prototype.setReport = function () {
        if (this.currentReport && this.currentReport.reportId) {
            localStorage.setItem('currentReport', JSON.stringify(this.currentReport));
            this.report.reportId = this.currentReport.reportId;
        }
        else {
            if (JSON.parse(localStorage.getItem('currentReport'))) {
                var localReport = JSON.parse(localStorage.getItem('currentReport'));
                if (localReport.downloadable) {
                    this.currentReport = localReport;
                    this.report.reportId = localReport.reportId;
                }
                else {
                    var reports = JSON.parse(localStorage.getItem('reports'));
                    var global_1 = this;
                    reports.forEach(function (item) {
                        if (item.downloadable && !global_1.currentReport.reportId) {
                            global_1.currentReport = item;
                        }
                    });
                    localStorage.setItem('currentReport', JSON.stringify(this.currentReport));
                }
            }
        }
        this.columMenu = this.currentReport.columns;
        this.title.setTitle(this.currentReport.name + environment.title_text);
    };
    MultiVehicleDownloadReportComponent.prototype.setItem = function () {
        if (this.currentReport.type != 'VIN_CHANGE_2G2K' && localStorage.getItem('reportsVehicle')) {
            var reportsItem = JSON.parse(localStorage.getItem('reportsVehicle'));
            this.querySelectVehicle = reportsItem;
            this.searchModel.value = reportsItem.deviceId;
            this.searchModel.vehicle = reportsItem.deviceId;
            this.selectedItem = reportsItem;
        }
        else {
            var reportsItem = JSON.parse(localStorage.getItem('reportsDevice'));
            this.querySelectDevice = reportsItem;
            this.searchModel.value = reportsItem.deviceId;
            this.searchModel.device = reportsItem.serialNumber;
            this.selectedItem = reportsItem;
        }
    };
    // to handle disabled buttons
    MultiVehicleDownloadReportComponent.prototype.disableBtn = function () {
        if (!this.searchModel.value ||
            !this.startTime || !this.endTime) {
            this.disable = true;
        }
        else {
            this.disable = false;
        }
    };
    // To download report as a CSV File
    MultiVehicleDownloadReportComponent.prototype.downloadReportCSV = function () {
        var _this = this;
        this.reportsService.multiDownloadVehicle(this.currentReport.reportId, this.searchModel.vehicle, this.startTime, this.endTime).subscribe(function (res) {
            var win = window.open(_this.apiUrl + 'reports/recent/message/download?access_key=' +
                res.token + '&messageCount=' + _this.searchModel.messageCount, '_blank');
            win.focus();
            _this.disable = false;
        }, function (error) {
            _this.sharedService.getErrorMsg(error);
            _this.disable = false;
        });
    };
    MultiVehicleDownloadReportComponent.prototype.addVehicle = function (e) {
        var _this = this;
        this.searchModel.vehicle = [];
        e.map(function (vehicle) {
            _this.searchModel.vehicle.push(vehicle.deviceId);
        });
        this.noVehiclesSelected = this.searchModel.vehicle.length ? false : true;
    };
    return MultiVehicleDownloadReportComponent;
}());
export { MultiVehicleDownloadReportComponent };
