<button
  id="deleteModal1"
  data-toggle="modal"
  data-target="#deleteDeviceModel1"
  [hidden]="true">
  Modal
</button>
<button
  id="deleteModal2"
  data-toggle="modal"
  data-target="#deleteDeviceModel2"
  [hidden]="true">
  Modal
</button>
<!-- BEGIN MODAL DELETE -->
<div id="{{modalId}}" class="modal effect-flip-horizontal fade" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog" role="document">
    <div class="modal-content bd-0 bg-transparent overflow-hidden">
      <div class="modal-body p-0">
        <div class="row no-gutters">
          <div class="col-12">
            <span id="{{modalCloseId}}" (click)="cancelConfirm()" #btnClose class="pull-right closeBtn" data-dismiss="modal">
              <i class="fa fa-times" aria-hidden="true"></i>
            </span>
            <div class="modal-box p-30">
              <div class="reply-header">
                <h5>{{title}}</h5>
              </div>
              <div class="sm-inner-desc text-center">
                <h1 class="text-{{type}} display-3"><i class="fa fa-exclamation-triangle"></i></h1>
                <h4 class="text-secondary">ARE YOU SURE ?</h4>
                <!-- Type Confirm Required Text  -->
                <!-- <p *ngIf="typeConfirmReq">This action can not be rolled back, to prevent accidental operation, please type <b>confirm</b> in the input box.</p> -->
                <p *ngIf="!displayMessage">This action cannot be undone. Please type “confirm” in the box below to continue.</p>
                <p *ngIf="displayMessage">{{ displayMessage }}</p>  
              </div>
              <div class="col-12 text-center pt-20 p-0" *ngIf="!hideInputconfirm">
                <input
                  type="text"
                  name="confirm"
                  id="confirm"
                  class="form-control h5-email"
                  [(ngModel)]="confirmText"
                  autocomplete="off"
                  tabindex="2"
                  (keyup)="matchCriteria()"
                  required
                />
              </div>
              <div class="row text-center pt-20">
                <div class="col-sm-6 col-6">
                  <button #btnClose type="button"
                    class="width100 btn btn-outline-secondary"
                    data-dismiss="modal" (click)="cancelConfirm()">
                    Cancel
                  </button>
                </div>
                <div class="col-sm-6 col-6" *ngIf="!hideInputconfirm">
                  <button
                    [disabled]="disableBtn || !confirmed"
                    type="button"
                    (click)="confirmDelete()"
                    class="width100 btn btn-{{type}}">
                    <span *ngIf="primaryAction">Confirm</span>
                    <!--<span *ngIf="!primaryAction">Yes, Delete it!</span>-->
                    <span *ngIf="!primaryAction">{{displayText}}</span>
                  </button>
                </div>
                <div class="col-sm-6 col-6"  *ngIf="hideInputconfirm">
                  <button
                    [disabled]="disableBtn"
                    type="button"
                    (click)="confirmDelete()"
                    class="width100 btn btn-{{type}}">
                    <span *ngIf="primaryAction">Confirm</span>
                    <!--<span *ngIf="!primaryAction">Yes, Delete it!</span>-->
                    <span *ngIf="!primaryAction">{{displayText}}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END MODAL DELETE -->
