import { OnInit } from '@angular/core';
var SupportComponent = /** @class */ (function () {
    function SupportComponent() {
        this.searchTab = false;
        this.noReportTab = false;
        this.dashboardTab = false;
        this.otaTab = false;
        this.moveDeviceTab = false;
        this.billingReportTab = true;
    }
    SupportComponent.prototype.ngOnInit = function () {
        this.permissionModules = localStorage.getItem('permissionModules');
        localStorage.setItem("billingReportCSR", "true");
        this.loadSearch();
    };
    SupportComponent.prototype.loadSearch = function () {
        this.searchTab = true;
        this.noReportTab = false;
        this.dashboardTab = false;
        this.otaTab = false;
        this.moveDeviceTab = false;
        this.billingReportTab = false;
    };
    SupportComponent.prototype.loadNoReport = function () {
        this.searchTab = false;
        this.noReportTab = true;
        this.dashboardTab = false;
        this.otaTab = false;
        this.moveDeviceTab = false;
        this.billingReportTab = false;
    };
    SupportComponent.prototype.loadDashboard = function () {
        this.searchTab = false;
        this.noReportTab = false;
        this.dashboardTab = true;
        this.otaTab = false;
        this.moveDeviceTab = false;
        this.billingReportTab = false;
    };
    SupportComponent.prototype.loadMoveDevices = function () {
        this.searchTab = false;
        this.noReportTab = false;
        this.dashboardTab = false;
        this.otaTab = false;
        this.moveDeviceTab = true;
        this.billingReportTab = false;
    };
    SupportComponent.prototype.loadBillingReport = function () {
        this.searchTab = false;
        this.noReportTab = false;
        this.dashboardTab = false;
        this.otaTab = false;
        this.moveDeviceTab = false;
        this.billingReportTab = true;
        localStorage.setItem("billingReportCSR", "true");
    };
    return SupportComponent;
}());
export { SupportComponent };
