import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'distanceMetricspipe'
})
export class DistanceMetricsPipe implements PipeTransform {

  transform(d: any, constraint?: string): any {
    if (d === undefined || d === null) {
      return null;
    } 
    else{
      //if distancemetrics value getting fromm logged in write condition here 
      if(constraint && constraint == "1"){
        return (d*0.6214).toFixed(1);
      }
      else
        return Math.floor(d*0.6214);
    }
  }

}
