import { Component, Input, OnChanges, OnInit, ViewChildren, SimpleChanges, QueryList, ViewChild, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { LiveService } from '@components/live/live.service';
import { configMessage } from '@shared/config-message';
import { Output, EventEmitter } from '@angular/core';
import { SharedService } from '@myproject/shared/shared.service';
declare var $: any;
import Chart from 'chart.js';
import * as _ from 'lodash';
@Component({
  selector: 'app-obdpid',
  templateUrl: './obdpid.component.html',
  styleUrls: ['./obdpid.component.scss']
})
export class ObdpidComponent implements OnInit, OnChanges {
  @Input() selectedDevice: any;
  @Input() tsTime: any;
  @Input() teTime: any;
  @Input() params: any;
  @Output() updatePointerEvents = new EventEmitter<Boolean>();
  @ViewChild('currentPlaying', { static: true }) currentPlaying: ElementRef;
  drawerIsOpen = false;
  allConstraints = [];
  tempAllConstraints = configMessage.allPids;
  supConstraints = [];
  supIconConstraints = [];
  supportedPidOrder = [13, 12, 17, 166, 5, 15, 1];
  activePids = [];
  smallContainer = true;
  // Chart Variables
  visualizing = false;
  chartLoading = false;
  lineChart: any = null;
  pidLabel = [];
  pid1Data = [];
  pid2Data = [];
  durationOfTrip: string = '';
  // initiated = false;

  // obd search
  searchModel: any = {
    value: '',
    search: false,
    searchBy: 'pidNo',
    hidden: []
  };
  isLoading = false;
  tabPos = 0;
  op = null;
  selectedPID = [];
  lastDevice = {
    deviceId: {
      prev: null,
      cur: null
    },
    ts: {
      prev: null,
      cur: null
    },
    te: {
      prev: null,
      cur: null
    }
  };
  onSearchNoDF = false;
  noGraphData = false;
  subscribedReq = [];
  subscribedReqOnLoad = [];

  // gps tab variables
  activeGPS = [];
  tempGPSConstraints = configMessage.gpsPids;

  // dashCam
  dashCamPos = 0;
  selVid = {
    eventName: null,
    videoURL: null,
    initSpeed: null,
    FinalSpeed: null,
    maxBraking: null,
    camType: null,
    msgId: null
  };
  dashCamVideoAvailable = true;
  loading = {
    getSupported: true,
    graph: true
  };
  selected = [];
  noDevices = null;
  filteredData = [];
  pidAllConstraints = [];

  constructor(
    private liveService: LiveService,
    public sharedService: SharedService
  ) { }

  ngOnInit() { }
  ngOnChanges(changes: SimpleChanges) {
    if (this.params && !this.params.hasOwnProperty('noDevices')) {
      this.tabPos = this.params.tabPos;
      this.noDevices = null;
      if (this.params.tabPos === 2) {
        this.loading.graph = false;
        this.dashCamVideoAvailable = false;
        if (this.params.dashCam && this.params.dashCam.hasOwnProperty('videoURL')) {
          this.onVideoClick(this.params.dashCam);
          this.dashCamVideoAvailable = true;
        }
      } else {
        if (this.params.tabPos === 1) {
          if (this.params.selected) { this.activeGPS = this.params.selected; }
        } else {
          if (this.params.selectedPidIds) { this.activePids = this.params.selectedPidIds; }
        }
        this.selected = this.params.selected;
        this.selectedPID = this.params.selected;
        this.selectedDevice = this.params.device;
        this.tsTime = this.params.tsTime;
        this.teTime = this.params.teTime;
        if (this.params.tabPos === 0 || (this.tabPos === 1 && !this.params.hasOwnProperty('selectedPidIds'))) {
          this.getChartData(this.params.tabPos === 0 ? 'pids' : 'gps');
        }
      }
    } else if (this.params && this.params.hasOwnProperty('noDevices')) {
      this.noDevices = true;
    }
  }
  ngOnDestroy(): void {
    this.resetReq();
  }
  onVideoClick(p1) {
    this.selVid.eventName = p1.eventName;
    this.selVid.msgId = p1.messageId;
    this.selVid.videoURL = p1.videoURL;
    this.selVid.initSpeed = p1.initSpeed;
    this.selVid.FinalSpeed = p1.FinalSpeed;
    this.selVid.maxBraking = p1.maxBraking;
    this.currentPlaying.nativeElement.src = p1.videoURL;
    this.selVid.camType = p1.camType === 'FRONT' ? 'Front' : p1.camType === 'BACK' ? 'Back' : 'In Cabin';
  }
  getAllPids() {
    Object.keys(this.tempAllConstraints).map(key => {
      this.allConstraints.push(this.tempAllConstraints[key]);
    });
    localStorage.setItem('allPids', JSON.stringify(this.allConstraints));
  }
  stop() {
    this.currentPlaying.nativeElement.pause();
  }
  compareStrings(a, b) {
    a = a.toLowerCase();
    b = b.toLowerCase();
    return (a < b) ? -1 : (a > b) ? 1 : 0;
  }
  getDashcamTitle(p1) {
    if (p1) {
      const str = p1.split('_');
      return str[0] + ' ' + str[1];
    }
  }
  resetOBDPids() {
    this.selected = [];
    this.pid1Data = [];
    this.pid2Data = [];
    this.pidLabel = [];
    this.durationOfTrip = '';
    if (this.lineChart) { this.lineChart.destroy(); document.getElementById('legend').innerHTML = ''; }
  }
  resetGPS() {
    this.activeGPS = [];
    this.pid1Data = [];
    this.pid2Data = [];
    this.pidLabel = [];
    this.durationOfTrip = '';
    if (this.lineChart) { this.lineChart.destroy(); document.getElementById('legend').innerHTML = ''; }
  }
  resetReq() {
    this.subscribedReq.map((r) => {
      try {
        r.unsubscribe();
      } catch (err) {}
    });
    this.subscribedReq = [];
  }
  getId(i, item) {
    return item.title + i.toString();
  }
  getChartData(p1) {
    this.visualizing = true;
    this.loading.graph = true;
    this.noGraphData = false;
    let request = null;
    // if (this.subscribedReq.length && this.subscribedReq[0]) {
    //   this.subscribedReq[0].unsubscribe();
    //   this.subscribedReq = [];
    // } else {
    //   this.subscribedReq = [];
    // }
    if (p1 === 'pids') {
      if (this.selected.length === 1) {
        const attribute1 = this.selected[0];
        const selectedPids = [attribute1];
        request = this.liveService.getallMessages(this.selectedDevice.deviceId, this.tsTime, this.teTime, 'obds')
          .subscribe(res => {
            if (res.length) {
              this.op = 'pids';
              this.selectedPID = selectedPids;
              this.sortChartData(this.selected, res, 'pids');
            } else {
              this.loading.graph = false;
              this.noGraphData = true;
            }
          }, error => {
            this.loading.graph = false;
            // this.sharedService.getErrorMsg(error);
          });
      } else if (this.selected.length === 2) {
        const selectedPids = this.selected;
        request = this.liveService.getallMessages(this.selectedDevice.deviceId, this.tsTime, this.teTime, 'obds')
          .subscribe(res => {
            if (res.length) {
              this.op = 'pids';
              this.selectedPID = selectedPids;
              this.sortChartData(this.selected, res, 'pids');
            } else {
              this.loading.graph = false;
              this.noGraphData = true;
            }
          }, error => {
            this.loading.graph = false;
            // this.sharedService.getErrorMsg(error);
          });
      } else {
        this.resetOBDPids();
        this.searchModel.value = '';
        this.loading.graph = false;
        document.getElementById('legend').innerHTML = '';
      }
    } else {
      let selectedPids = [];
      if (this.activeGPS.length === 1) {
        selectedPids = [this.tempGPSConstraints["PID_" + this.activeGPS[0]]];
        request = this.liveService.getOBD_GPS_Messages(this.selectedDevice.deviceId, this.tsTime, this.teTime, selectedPids[0].pid)
      } else if (this.activeGPS.length === 2) {
        selectedPids = [this.tempGPSConstraints["PID_" + this.activeGPS[0]], this.tempGPSConstraints["PID_" + this.activeGPS[1]]];
        request = this.liveService.getOBD_GPS_Messages(this.selectedDevice.deviceId, this.tsTime, this.teTime, selectedPids[0].pid + ',' + selectedPids[1].pid)
      }
      if (request) {
        request.subscribe(res => {
          if (res.length && this.activeGPS.length) {
            this.op = 'gps';
            this.selectedPID = selectedPids;
            this.sortChartData(selectedPids, res, 'gps');
          } else if (!this.activeGPS.length) {
            this.loading.graph = false;
          } else {
            this.loading.graph = false;
            this.noGraphData = true;
          }
          this.updatePointerEvents.emit(false);
        }, error => {
          this.loading.graph = false;
          this.updatePointerEvents.emit(false);
        });
      } else {
        this.resetGPS();
        this.loading.graph = false;
        this.noGraphData = false;
        this.updatePointerEvents.emit(false);
        document.getElementById('legend').innerHTML = '';
      }
    }
    if (this.subscribedReq.length) { this.resetReq(); }
    this.subscribedReq.push(request);
  }
  sortChartData(selectedPids, res, op) {
    let pid1 = selectedPids[0], pid2 = null;
    // if (op === 'pids') { pid1 = selectedPids[0]; }
    this.durationOfTrip = '';
    if (this.lineChart) { this.lineChart.destroy(); }
    this.pid1Data = [];
    this.pid2Data = [];
    this.pidLabel = [];
    let ts = this.tsTime;
    let te = this.teTime;
    // const [days, hours, minutes, seconds] = this.sharedService.getDurationBtwTwoEpoch(ts, te);
    // if (hours < 10) { this.durationOfTrip += '0' + (hours).toString() + ':'; } else { this.durationOfTrip += (hours).toString() + ':'; }
    // if (minutes < 10) { this.durationOfTrip += '0' + (minutes).toString() + ':'; } else { this.durationOfTrip += (minutes).toString() + ':'; }
    // if (seconds < 10) { this.durationOfTrip += '0' + (seconds).toString(); } else { this.durationOfTrip += (seconds).toString(); }
    if (selectedPids.length === 1) {
      let that = this;
      for (let i = 0; i < res.length; i++) {
        let pidData = res[i];
        if ((pidData.hasOwnProperty('eventTime') && pidData.hasOwnProperty('obds')) || (pidData.hasOwnProperty('eventTime') || pidData.hasOwnProperty('cog'))) {
          pidData['eventTime'] = new Date(pidData['eventTime']);
          that.pidLabel.push(pidData['eventTime']);
          if (op === 'gps') {
            that.pid1Data.push(parseInt(pidData[pid1.pid], 10));
          } else {
            let pid1Found = false;
            (pidData['obds']).map(obd => {
              if (obd.pid === pid1.pid) {
                pid1Found = true;
                that.pid1Data.push(parseInt(obd.values[0], 10));
              }
            });
            if (!pid1Found) { this.pid1Data.push(0); }
          }
        }
      }
    } else {
      pid2 = selectedPids[1];
      let that = this;
      for (let i = 0; i < res.length; i++) {
        let pidData = res[i];
        if ((pidData.hasOwnProperty('eventTime') && pidData.hasOwnProperty('obds')) || (pidData.hasOwnProperty('eventTime') || pidData.hasOwnProperty('cog'))) {
          pidData['eventTime'] = new Date(pidData['eventTime']);
          that.pidLabel.push(pidData['eventTime']);
          if (op === 'gps') {
            that.pid1Data.push(parseInt(pidData[pid1.pid], 10));
            that.pid2Data.push(parseInt(pidData[pid2.pid], 10));
          } else {
            let pid1Found = false;
            let pid2Found = false;
            (pidData['obds']).map(obd => {
              if (obd.pid === pid1.pid) {
                pid1Found = true;
                that.pid1Data.push(parseInt(obd.values[0], 10));
              } else if (obd.pid === pid2.pid) {
                pid2Found = true;
                that.pid2Data.push(parseInt(obd.values[0], 10));
              }
            });
            if (!pid1Found) { this.pid1Data.push(0); }
            if (!pid2Found) { this.pid2Data.push(0); }
          }
        }
      }
    }
    this.drawChart(selectedPids);
  }

  setHeight() {
    const leftColHeight = $('#leftCol').height();
    const navHeight = $('.nav.nav-tabs.detailed-tabs').height();
    const footer = $('footer').height();
    if (this.tabPos === 0) {
      const searchBarHeight = $('.input-group.input-group-lg.searchVeh.liveSearch').height();
      $('#pidTable > div > .datatable-body').css('height', leftColHeight - (navHeight + searchBarHeight + footer + 20));
    } else if (this.tabPos === 1) {
      $('#gps').css('height', leftColHeight - (navHeight));
    }
  }

  drawChart(selectedPids) {
    let pid1 = selectedPids[0], pid2 = null;
    if (selectedPids.length === 2) {
      pid2 = selectedPids[1];
    }
    this.loading.graph = false;
    if (this.tabPos === 1) {
      this.activeGPS = selectedPids;
    } else if (this.tabPos === 0) {
      this.activePids = selectedPids;
    }
    let ctx = document.getElementById('lineChart');
    if (!ctx) { this.createCanvas(); ctx = document.getElementById('lineChart'); }
    let options = {
      type: 'line',
      responsive: true,
      data: {
        labels: this.pidLabel,
        datasets: [
          {
            label: pid1.unit ? `${pid1.description}  (${pid1.unit})` : pid1.description,
            yAxisID: pid1.description,
            data: this.pid1Data,
            backgroundColor: 'rgb(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235)'
          }
        ]
      },
      options: {
        maintainAspectRatio: false,
        showScale: false,
        legend: {
          position: 'left',
          display: false,
          textDirection: 'rtl',
          labels: {
            fontSize: 8,
            boxWidth: 10
          }
        },
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              unit: 'minute'
            },
            distribution: 'series',
            gridLines: {
              display: false,
            },
            ticks: {
              autoSkip: true,
              maxTicksLimit: 15
            }
          }],
          yAxes: [
            {
              id: pid1.description,
              type: 'linear',
              position: 'left',
              gridLines: {
                display: false,
              }
            }
          ]
        },
        elements: {
          line: {
            tension: 0.5 // disables bezier curves
          }
        }
      }
    };
    if (selectedPids.length > 1) {
      options.options.scales.yAxes.push({
        id: pid2.description,
        type: 'linear',
        position: 'right',
        gridLines: {
          display: false,
        }
      });
      options.data.datasets.push(
        {
          label: pid2.unit ? `${pid2.description} (${pid2.unit})` : pid2.description,
          yAxisID: pid2.description,
          data: this.pid2Data,
          backgroundColor: 'rgb(255, 99, 132, 0.2)',
          borderColor: 'rgb(255, 99, 132)'
        });
    }
    this.lineChart = new Chart(ctx, options);
    // this.lineChart.aspectRatio = 0;
    document.getElementById("legend").innerHTML = this.lineChart.generateLegend();
    $(`#legend > ul > li`).on('click', (e) => {
      const indx = $(e.currentTarget).index();
      $(e.currentTarget).toggleClass('strike');
      var ci = this.lineChart;
      var meta = ci.chart.getDatasetMeta(indx);
      meta.hidden = meta.hidden === null ? !ci.chart.data.datasets[indx].hidden : null;
      ci.chart.update();
    });
  }
  createCanvas() {
    let canvas = document.createElement('canvas');
    let div = document.getElementById('chartcontainer');
    canvas.id = "lineChart";
    if (div) div.appendChild(canvas);
  }

  resetSearch() {
    this.searchModel.value=''; 
    this.filteredData = this.pidAllConstraints;
    this.onSearchNoDF = false;
  }
}
