import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { SharedService } from '@myproject/shared/shared.service';
import { DataService } from '@components/data/data.service';

@Component({
  selector: 'app-time-seeker',
  templateUrl: './time-seeker.component.html',
  styleUrls: ['./time-seeker.component.scss']
})
export class TimeSeekerComponent implements OnInit, OnChanges {
  @Input() selectedDevice: any;
  @Input() selectedTrip: any;
  @Input() tsTime: any;
  @Input() teTime: any;
  @Input() nonTripMode: boolean;
  @Input() hasTrip: boolean;
  @Input() sort: any;
  @Input() endTime: any;
  @Input() trips: any;
  @Input() showLabel: Boolean = true;
  // @Input() selectedSeeker: boolean;
  @Output() onTimeSel = new EventEmitter<Number>();

  hourLineData = [];
  activeSeeker = [];
  html = '';
  changedDate = 1;
  month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  subscribedReq = [];

  constructor(
    public sharedService: SharedService,
    private dataService: DataService
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.selectedDevice && this.tsTime && this.teTime) {
      if (this.subscribedReq.length) { this.subscribedReq[0].unsubscribe(); this.subscribedReq = []; }
      this.changedDate = 1;
      let d = new Date();
      let offset = d.getTimezoneOffset() * -60;
      let date1 = new Date(this.tsTime);
      let date2 = new Date(this.teTime);
      if (date1.getMinutes() > 0) {
        date1.setMinutes(0);
        date1.setSeconds(0);
      }
      if (date2.getMinutes() >= 0) {
        date2.setMinutes(0);
        date2.setSeconds(0);
        date2.setHours(date2.getHours() + 1);
      }
      const hours = (((date2.getTime() - date1.getTime()) / 1000) / 60) / 60;
      this.tsTime = date1.getTime();
      this.teTime = date2.getTime();
      const req = this.sharedService.getSeekerData(this.selectedDevice, this.tsTime, this.teTime, offset)
        .subscribe((res) => {
          this.hourLineData = [];
          this.activeSeeker = [];
          if (res.length) {
            const dateNow = new Date(this.tsTime);
            for (let i = 0; i < (hours); i++) {
              let hour = date1.getHours();
              const epochStart = new Date(date1.setMinutes(0)).setSeconds(0);
              if (this.changedDate === 1) {
                if (date1.getDate() !== dateNow.getDate()) {
                  this.hourLineData.push({ label: (hour > 12 ? (hour - 12) : hour), day: (hour >= 12 ? 'pm' : 'am'), dayChange: date1.getDate(), dayMonth: date1.getMonth(), count: res[i].count, ts: epochStart, te: (epochStart + (1 * 59 * 60 * 1000)), sel: false });
                  this.changedDate += 1;
                } else {
                  this.hourLineData.push({ label: (hour > 12 ? (hour - 12) : hour), day: (hour >= 12 ? 'pm' : 'am'), dayChange: i === 0 ? date1.getDate() : null, dayMonth: i === 0 ? date1.getMonth() : null, count: res[i].count, ts: epochStart, te: (epochStart + (1 * 59 * 60 * 1000)), sel: false });
                }
              } else {
                this.hourLineData.push({ label: (hour > 12 ? (hour - 12) : hour), day: (hour >= 12 ? 'pm' : 'am'), dayChange: i === 0 ? date1.getDate() : null, dayMonth: i === 0 ? date1.getMonth() : null, count: res[i].count, ts: epochStart, te: (epochStart + (1 * 59 * 60 * 1000)), sel: false });
              }
              date1.setHours(hour + 1);
            }
          }
        });
      this.subscribedReq.push(req);
    }
  }

  onTimeClick(val, p1) {
    if (this.activeSeeker.includes(val.ts)) {
      const index = this.activeSeeker.indexOf(val.ts);
      this.activeSeeker.splice(index, 1);
    } else if (this.activeSeeker.length < 1 || this.activeSeeker.length === 1) {
      this.activeSeeker = [];
      this.activeSeeker.push(val.ts);
    }
    val['len'] = this.hourLineData.length;
    this.onTimeSel.emit(val);
  }
}
