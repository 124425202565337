import { Component, OnInit, NgZone } from '@angular/core';
import { AuthenticationService } from '@layouts/login/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';

import { Title } from '@angular/platform-browser';
import { SharedService } from '@shared/shared.service';
import { isArray } from 'util';
import { environment } from '@myenv/environment';
import { CookieService } from 'angular2-cookie/core';
import { enMessage } from '@shared/en-us-message';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  // public tenantId = environment.tenantId;
  public footerText = environment.footerText;
  public cmImageUrl = environment.cmImageUrl;
  login: any = {};
  message: String = '';
  responseMessage: any = {};
  permissionModules: any = [];
  typePassword: Boolean = false;
  disable = false;
  // for MFA
  verificationReq = false;
  resetDisable = true;
  tempToken = null;
  otp = null;
  resendTimer = 0;
  resendInterval = null;
  otpCount = 0;
  resetCount = 0;

  constructor(
    private _cookieService: CookieService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private ngzone: NgZone,
    private route: ActivatedRoute,
    private title: Title,
    private sharedService: SharedService
  ) { }

  ngOnInit() {
    if (this.router.url === '/logout' || this.router.url === '/refresh') {
      this.logout();
      this.ngzone.run(() => {
        this.router.navigate(['/login']);
      });
    }
    this.title.setTitle('Login' + environment.title_text);
    this.route.queryParams.subscribe(queryParams => {
      // do something with the query params
      if (queryParams.msg == 1) {
        this.responseMessage = { success: true, message: enMessage.verifyResetToken }
        this.sharedService.setResponseMessage(this.responseMessage);
      } else if (queryParams.msg == 2) {
        this.responseMessage = { success: true, message: enMessage.successResetPassword };
        this.sharedService.setResponseMessage(this.responseMessage);
      }
    });
    this.sharedService.bootDownInterCom();
    // localStorage.clear();
  }

  togglePassword() {
    this.typePassword = !this.typePassword;
  }

  // To check the preferences
  checkPrefrences(res) {
    this.permissionModules = res.modules.map(data => data.name);
    if (this.permissionModules.includes('REPORT')) {
      localStorage.setItem('lastReportUrl', '/reports/system/live-status');
    }
    this.sharedService.setAccessRole(res.modules, this.permissionModules);
    // this.sharedService.getUserRoleId(res.token).subscribe(responseUserRole => {
    //   // localStorage.setItem('responseUserRole', JSON.stringify(responseUserRole));
    //   this.sharedService.getRoleAccessModule(responseUserRole.roleId).subscribe(resPermissionModule => {
    //     localStorage.setItem('resPermissionModule', JSON.stringify(resPermissionModule));
    //     this.permissionModules = resPermissionModule.modules.map(data => data.name);
    //     let permissionModules = JSON.parse(localStorage.getItem('permissionModules'));
    //     this.sharedService.setDefaultUrl(permissionModules);
    //     // this.sharedService.gotPermissions.next(resPermissionModule);
    //   }, error => {
    //     this.sharedService.getErrorMsg(error);
    //     this.disable = false;
    //   });
    // }, error => {
    //   this.sharedService.getErrorMsg(error);
    //   this.disable = false;
    // });
  }

  // To check the user is logged in
  checkLogin(isValid: boolean) {
    if (isValid) {
      this.disable = true;
      // call login API to get token
      this.authenticationService.login(this.login).subscribe((res) => {
        // if (res.mfaType === 0) this.performLogin(res);
        this.performLogin(res);
        if (res.mfaType === 1) {
          if (res.status === 401) {
            const errorCode = res.errorCode;
            const errorMessage = enMessage[errorCode];
            this.responseMessage = { success: false, message: enMessage };
            this.sharedService.setResponseMessage(this.responseMessage);
            this.disable = false;
            this.verificationReq = false;
            this.otp = false;
          } else {
            this.otpCount = 0;
            this.resetCount = 0;
            this.message = '';
            this.verificationReq = true;
            this.disable = false;
            this.otp = null;
            this.tempToken = res.accessToken;
            if (this.resendInterval) clearInterval(this.resendInterval);
            this.startResendTimer();
          }
        }
      }, (error) => {
        this.disable = false;
        if (error.status == 401) {
          this.sharedService.getErrorMsg(error);
          this.verificationReq = false;
          this.tempToken = null;
          this.otp = null;
          if (this.resendInterval) clearInterval(this.resendInterval);
        } else {
          this.sharedService.getErrorMsg(error);
        }
      });
    }
  }

  performLogin(res) {
    if (res.accessToken) {
      let base64Token = res.accessToken;
      this.authenticationService.setHeaders(base64Token);
      this._cookieService.remove('user');
      this.authenticationService.getLoggedUser().subscribe((responseInfo) => {
        this.authenticationService.getProductType(responseInfo.customerId).subscribe((type:any)=>{
          responseInfo["productType"] = type.productType;
          if (responseInfo.status !== 400) {
            this.checkPrefrences(responseInfo);
            this.getUserPreferences();
            this.authenticationService.setLoggedInUser(responseInfo);
            this.authenticationService.getUserInfo(responseInfo.userId).subscribe((res) => {
              let obj = {
                app_id: environment.production ? 'yxsvtkcv' : 'wm4a76at',
                firstName: res.firstName,
                lastName: res.lastName,
                emailId: res.emailId,
                user_id: res.userId,
                user_hash: responseInfo.userHash,
                company: {
                  company_id: responseInfo.customerId,
                  name: ''
                }
              };
              this.sharedService.getCustomerInfo(responseInfo.customerId).subscribe((res) => {
                obj.company.name = res.companyName;
                localStorage.setItem('userInfo', JSON.stringify(obj));
                this.sharedService.bootUpInterCom();
              }, (err) => {});
            });
          } else {
            const errorCode = res.errorCode;
            const errorMessage = enMessage[errorCode];
            this.responseMessage = { success: false, message: errorMessage };
            this.sharedService.setResponseMessage(this.responseMessage);
            this.disable = false;
          }
        });
      }, (error) => {
        const errorCode = res.errorCode;
        const errorMessage = enMessage[errorCode];
        this.responseMessage = { success: false, message: errorMessage };
        this.sharedService.setResponseMessage(this.responseMessage);
        this.disable = false;
      });
    } else if (res.status == 401) {
      const errorCode = res.errorCode;
      const errorMessage = enMessage[errorCode];
      this.responseMessage = { success: false, message: enMessage };
      this.sharedService.setResponseMessage(this.responseMessage);
      this.disable = false;
    }
  }

  getMfaToken(isValid: boolean) {
    if (isValid) {
      this.disable = true;
      // call MFA token API to get token
      this.authenticationService.getMfaToken(this.tempToken, { verificationCode: this.otp }).subscribe((res) => {
        // this.tempToken = null;
        this.performLogin(res);
      }, (error) => {
        this.disable = false;
        this.otpCount++;
        if (this.otpCount >= 4) {
          this.verificationReq = false;
          // this.message = 'Maximum retry count reached.';
        }
        if (error.status == 401) {
          this.sharedService.getErrorMsg(error);
          this.verificationReq = false;
          this.tempToken = null;
          this.otp = null;
          if (this.resendInterval) clearInterval(this.resendInterval);
        } else {
          this.sharedService.getErrorMsg(error);
        }
      });
    }
  }

  resetOtp() {
    if (this.resendInterval) clearInterval(this.resendInterval);
    this.resetDisable = true;
    this.resetCount++;
    this.otp = null;
    if (this.resetCount >= 4) {
      this.message = 'Maximum tries to resend verification code reached.';
      this.resetDisable = true;
      this.verificationReq = false;
    } else {
      this.authenticationService.resendVerficationCode(this.tempToken).subscribe(res => {
        this.tempToken = res.accessToken;
        this.startResendTimer();
      }, (error) => {
        this.resetDisable = false;
        if (error.status == 401) {
          this.sharedService.getErrorMsg(error);
          this.verificationReq = false;
          this.tempToken = null;
          this.otp = null;
        } else {
          this.sharedService.getErrorMsg(error);
        }
      });
    }
  }

  startResendTimer() {
    const global = this;
    this.resendTimer = 300;
    this.resetDisable = true;
    this.resendInterval = setInterval(function () {
      if (global.resendTimer > 0) global.resendTimer--;
      else global.resetDisable = false;
    }, 1000);
  }

  // To get user preferences
  getUserPreferences() {
    // Get the User Preferences by making a seperate API call
    this.authenticationService.userPreferences().subscribe(responseInfo => {
      this.sharedService.setDefaultUrl(this.permissionModules);
      if (responseInfo) {
        localStorage.setItem('preferences', JSON.stringify(responseInfo));
      }
      if (responseInfo.liveHistory || responseInfo.live.header) {

        if (responseInfo.liveHistory && responseInfo.liveHistory.header) {
          this.authenticationService.liveHistoryPrefrences(responseInfo.liveHistory.header);
        }

        // if (responseInfo.live && responseInfo.live.header) {
        //   this.authenticationService.livePrefrences(responseInfo.live.header);
        // }
      }
    }, error => {
      this.sharedService.getErrorMsg(error);
      this.disable = false;
    });
  }

  logout() {
    window.location.pathname = '/login';
    let token = localStorage.getItem('token');
    // let baseToken = atob(token);
    localStorage.clear();
    sessionStorage.clear();
    this.sharedService.bootDownInterCom();
    this.authenticationService.logout(token).subscribe((res) => {
    });
    let global = this;
    if (this.router.url == 'login') {
      location.reload(true);
    }
  }
}
