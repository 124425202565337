import { OnInit } from '@angular/core';
import { FaqService } from "@layouts/faq/faq.service";
import { SharedService } from "@shared/shared.service";
var FaqComponent = /** @class */ (function () {
    function FaqComponent(faqService, sharedService) {
        this.faqService = faqService;
        this.sharedService = sharedService;
        this.data = [];
    }
    FaqComponent.prototype.ngOnInit = function () {
        this.getFaq();
    };
    FaqComponent.prototype.getFaq = function () {
        var _this = this;
        this.faqService.getFaq().subscribe({
            next: function (res) {
                _this.data = res;
            },
            error: function (error) {
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    return FaqComponent;
}());
export { FaqComponent };
