import {throwError as observableThrowError,  Observable, BehaviorSubject } from 'rxjs';
import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '@myenv/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpBackend } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable()
export class DeviceReplacementService {
  public apiUrl = environment.apiUrl;
  private httpClient: HttpClient;

  constructor(private http: HttpClient, private router: Router, private handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  searchDevice(key, value, customerId): Observable<any> {
    // let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    // let customerId = currentUser.customerId;
    return this.http.get(`${this.apiUrl}devices/search/associated?key=${key}&value=${value}&customerId=${customerId}`).pipe(
      catchError(this.handleError));
  }

  getUnAssociatedDevices(key, value, customerId): Observable<any> {
    return this.http.get(`${this.apiUrl}devices/search/unAssociated?key=${key}&value=${value}&customerId=${customerId}`).pipe(
      catchError(this.handleError));
  }

  replaceDevice(payload): Observable<any> {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    return this.http.post(this.apiUrl + `devices/replace`, payload, { headers }).pipe(
      catchError(this.handleError));
  }

  handleError(error: any | any) {
    return observableThrowError(error.error || error || 'Server error');
  }
}
