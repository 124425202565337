<app-notify-message></app-notify-message>
<app-reports-header></app-reports-header>
<div class="tab-content">
  <div role="tabpanel" class="tab-pane fade in active show relative" id="general">
    <div class="row no-gutters reports-page">
      <div class="p-0 col-sm-0" [ngClass]="{'col-md-2': !isSideMenuClose, 'no-width': isSideMenuClose}">
        <app-reports-side-menu (selectReport)="getReport($event)"></app-reports-side-menu>
      </div>
      <div class="reports-body col-sm-12 p-0" [ngClass]="{'col-md-10': !isSideMenuClose, 'col-md-12': isSideMenuClose}">
        <!-- Collapse/Expand reports side menu -->
        <div class="toggle-collapse-wrapper">
          <span
            *ngIf="!isSideMenuClose"
            tooltip="Collapse Side Panel"
            placement="right"
            (click)="onSideMenuToggle()"
            class="toggleBtn badge badge-secondary">
            <i class="fa fa-angle-double-left font-14" aria-hidden="true"></i>
          </span>
          <span
            *ngIf="isSideMenuClose"
            tooltip="Expand Side Panel"
            placement="right"
            (click)="onSideMenuToggle()"
            class="toggleBtn badge badge-secondary">
            <i class="fa fa-angle-double-right font-14" aria-hidden="true"></i>
          </span>
        </div>
        <!-- Reports content along with the action row -->
        <div class="row no-gutters action-row align-items-center px-2">
          <div class="col-lg-2 col-md-12">
            <h4 class="report-heading page-heading"><i class="fa fa-bar-chart"></i> {{currentReport.name}}</h4>
          </div>
          <div class="col-lg-10 col-md-12 text-right">
            <app-search-select-device
              *ngIf="currentReport.type !== 'VIN_CHANGE_2G2K'"
              [autoSelect]="true"
              [selectSearchCategory]="true"
              [querySelectDevice]="querySelectVehicle"
              (deviceChangeEvent)="changeVehicle($event)"
              >
            </app-search-select-device>
            <app-search-select-device
              *ngIf="currentReport.type == 'VIN_CHANGE_2G2K'"
              [autoSelect]="true"
              [selectSearchCategory]="true"
              [querySelectDevice]="querySelectDevice"
              (deviceChangeEvent)="changeDevice($event)"
              >
            </app-search-select-device>
            <div class="calendar d-inline-block ml-2" (clickOutside)="hideCalendar=!hideCalendar;">
              <app-date-time-calendar
                [clickOutside]="hideCalendar"
                [selectPreset]="'today'"
                [vehicle]="searchModel.vehicle"
                [device]="searchModel.device"
                (timeChangeEvent)="onTimeChange($event)"
                >
              </app-date-time-calendar>
            </div>
            <button
              class="btn btn-icon ml-2 columns"
              tooltip="Configure Columns"
              placement="top"
              (click)="togglePopup()"
              [disabled]="!reports.length"
              >
              <i class="fa fa-columns" aria-hidden="true"></i>
              <span class="mob-access-text d-none">&nbsp;Columns</span>
            </button>
            <!-- Columns dropdown -->
            <div class="selectColumnsDropdown text-left" *ngIf="columnPopup">
              <!-- <div class="title">
                <h5>Columns</h5>
              </div> -->
              <div class="column-search">
                <input
                  type="text" name="userTypeVal"
                  required autofocus
                  [(ngModel)]="searchModel.columnVal"
                  #columnVal="ngModel"
                  (keyup)="onColumnSearch()"
                  placeholder="Search"
                />
                <span
                  class="cross-icon"
                  *ngIf="searchModel.columnVal"
                  (click)="displayColumns = columMenu; searchModel.columnVal=''"
                  >
                  <i class="fa fa-times" aria-hidden="true"></i>
                </span>
                <span><i class="fa fa-search img-responsive search-icon" aria-hidden="true"></i></span>
              </div>
              <div *ngIf="displayColumns.length" class="content">
                <div *ngIf="!this.searchModel.columnVal" class="item">
                  <label class="box-container">Select All
                    <input
                      type="checkbox"
                      [checked]="columnCounts.selectCount===displayColumns.length"
                      (change)="selectDeSelectAllColumns($event)">
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="item"
                  *ngFor="let column of displayColumns; let i=index;"
                  [class.disabled]="false"
                  >
                  <label class="box-container">{{column.displayName}}
                    <input
                      type="checkbox" [checked]="column.checked"
                      (change)="selectColumns($event, column, i)"
                      [disabled]="false">
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
              <div *ngIf="!displayColumns.length" class="content">
                <div class="item text-center bold">
                  No Columns Found
                </div>
              </div>
              <div class="actions text-right">
                <button
                  class="btn btn-outline-secondary small-screen-btn mr-1"
                  (click)="cancelSelection()"
                  >
                  Cancel
                </button>
                <button
                  class="btn btn-primary small-screen-btn"
                  (click)="updateColumns()"
                  [disabled]="!displayColumns.length || !columnCounts.selectCount"
                  >
                  Apply
                </button>
              </div>
            </div>
            <!-- Column Popup ends -->
            <button
              class="btn btn-icon ml-2 refresh"
              (click)="refresh()"
              tooltip="Refresh"
              placement="top"
              [disabled]="disable">
              <i class="fa fa-refresh" aria-hidden="true"></i>
              <span class="mob-access-text d-none">&nbsp;Refresh</span>
            </button>
            <button
              class="btn btn-icon ml-2 download"
              tooltip="Download" placement="top"
              *ngIf="currentReport.type !== 'VIN_CHANGE_2G2K'" type="button"
              (click)="downloadReportCSV()"
              [disabled]="disable || !reports.length">
              <i class="fa fa-cloud-download"></i>
              <span class="mob-access-text d-none">&nbsp;Download</span>
            </button>
            <button
              class="btn btn-icon ml-2 download"
              tooltip="Download" placement="top"
              *ngIf="currentReport.type == 'VIN_CHANGE_2G2K'"
              style="min-width: 120px;" type="button"
              (click)="downloadDeviceReportCSV()"
              [disabled]="disable || !reports.length">
              <i class="fa fa-cloud-download"></i>
              <span class="mob-access-text d-none">&nbsp;Download</span>
            </button>
            <div class="row align-items-center">
              <div class="col-md-4">
                <!-- <app-search-select-vehicle
                  *ngIf="currentReport.type !== 'VIN_CHANGE_2G2K'"
                  (vehicleChangeEvent)="changeVehicle($event)"
                  [autoSelect]="true"
                  [querySelectVehicle]="querySelectVehicle"
                  >
                </app-search-select-vehicle>
                <app-search-select-device
                  *ngIf="currentReport.type == 'VIN_CHANGE_2G2K'"
                  (deviceChangeEvent)="changeDevice($event)"
                  [autoSelect]="true"
                  [querySelectDevice]="querySelectDevice"
                  >
                </app-search-select-device> -->
              </div>
              <div class="col-md-8 d-flex justify-content-end mobile-design pl-0">
                
              </div>
            </div>
          </div>
        </div>
        <div class="row limit-width mx-0 align-items-center mt-2">
          <div class="col-12">
            <div class="row" *ngIf="reports.length">
              <div class="col-md-12">
                <!--<div class="w90 relative">-->
                <!-- <app-ngx-datatable [KeyUp]="pageCallback.bind(this)" [pageInfo]="pageInfo" [tableData]= "reports-old" [columnData]="columMenu"></app-ngx-datatable> -->
                <ngx-datatable
                  id="tableau" #dataTable
                  class="bootstrap alertTable registeredVehicle reportsTable wrapcell"
                  [loadingIndicator]="'true'"
                  [rows]="reports"
                  [columnMode]="'force'"
                  [headerHeight]="50"
                  [footerHeight]="50"
                  [limit]="pageInfo.limit"
                  [offset]="pageInfo.offset"
                  [count]="pageInfo.count"
                  [rowHeight]="'auto'"
                  [summaryRow]="false"
                  [scrollbarH]="true"
                  [columnMode]="'force'"
                  (page)="pageCallback($event)"
                  [reorderable]="true"
                  >
                  <ngx-datatable-column name="#" [sortable]="false" [width]="50">
                    <ng-template let-rowIndex="rowIndex" ngx-datatable-cell-template>
                      {{rowIndex+1}}
                    </ng-template>
                  </ngx-datatable-column>
                  <ng-container *ngFor="let rm of columMenu">
                    <ngx-datatable-column [resizeable]="false" *ngIf="!rm.key.includes('reportId')" name="{{rm.displayName}}">
                      <ng-template ngx-datatable-header-template>
                        <span>{{rm.displayName}}</span>
                      </ng-template>
                      <!--<ng-template let-row="row" ngx-datatable-cell-template>
                        {{rm.key == 'eventTime'?(row[rm.key] | date : dateFormat) : row[rm.key] }}
                      </ng-template>-->
                      <ng-template let-row="row" ngx-datatable-cell-template>
                        <span *ngIf="dateAttributes.includes(rm.key)">{{row[rm.key] | date : dateFormat}}</span>
                        <span *ngIf="!dateAttributes.includes(rm.key)">{{row[rm.key]}}</span>
                        <!--{{rm.key == dateAttributes.includes(row[rm.key] | date : dateFormat) : row[rm.key])}}-->
                        <!--{{rm.key == 'eventTime'?(row[rm.key] | date : dateFormat) : row[rm.key] }}-->
                      </ng-template>
                    </ngx-datatable-column>
                  </ng-container>
                </ngx-datatable>
                <div class="total-count" *ngIf="totalMessageCount || this.currentReport.selectionType == 'GROUP'">
                  Showing
                  <span contenteditable="false" (input)="currentPageCount.start = $event.target.innerText">
                    {{currentPageCount.start}}
                  </span>
                  -
                  <span contenteditable="false" (input)="currentPageCount.end = $event.target.innerText">
                    {{currentPageCount.end}}
                  </span>
                  of <span *ngIf="this.currentReport.selectionType == 'GROUP'"> many</span> <span
                  *ngIf="this.currentReport.selectionType !== 'GROUP'"> {{(totalMessageCount.count)}}</span> records.
                </div>
                <!--</div>-->
                <!-- <div class="total-count row-count-selection">
                  Showing
                  <button class="btn btn-custom btn-sm dropdown-toggle mx-1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {{pageInfo.limit}}
                  </button>
                  <div class="dropdown-menu">
                    <a class="dropdown-item" (click)="changeRowCount(10)">10</a>
                    <a class="dropdown-item" (click)="changeRowCount(25)">25</a>
                    <a class="dropdown-item" (click)="changeRowCount(50)">50</a>
                    <a class="dropdown-item" (click)="changeRowCount(100)">100</a>
                  </div>
                  rows.
                </div> -->
              </div>
            </div>
            <div *ngIf="!reports.length" class="text-center mt-5 pt-5">
              <img width="170" src="../../../assets/img/info_nodata_256.png"/>
              <h4 class="mt-3">No data to show</h4>
              <p>To see data, check your date selection.
                <!--<a class="pointer link-color" data-toggle="modal" (click)="toggleButton()"-->
                <!--data-target="#addNewRole_modal" >create role</a>.-->
              </p>
            </div>
            <!--<div class="row" *ngIf="!reports.length">-->
              <!--<div class="col-12">-->
                <!--<p class="text-center" style="font-size: 1.875rem;line-height: 18.4rem">No Data found.</p>-->
              <!--</div>-->
            <!--</div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
