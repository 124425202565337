import * as tslib_1 from "tslib";
import { OnInit, ElementRef, AfterViewInit } from '@angular/core';
import { SharedService } from '@shared/shared.service';
import { VehicleUploadService } from '@components/vehicle/vehicle-upload/vehicle-upload.service';
import { Title } from '@angular/platform-browser';
import { VehicleService } from '@components/vehicle/vehicle.service';
import * as FileSaver from 'file-saver';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { GroupsService } from '@myproject/components/device/groups/groups.service';
import { environment } from '@myenv/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { configMessage } from '@shared/config-message';
import { AdminService } from '@components/admin/admin.service';
var VehicleViewReportComponent = /** @class */ (function () {
    function VehicleViewReportComponent(groupsService, sharedService, vehicleUploadService, title, vehicleService, route, adminService, router) {
        var _this = this;
        this.groupsService = groupsService;
        this.sharedService = sharedService;
        this.vehicleUploadService = vehicleUploadService;
        this.title = title;
        this.vehicleService = vehicleService;
        this.route = route;
        this.adminService = adminService;
        this.router = router;
        this.markers = [];
        this.cmImageUrl = environment.cmImageUrl;
        this.dateFormat = environment.largeDateFormat;
        this.noSecondsTimeFormat = environment.noSecondsTimeFormat;
        // This to display activity property from config
        this.activityAttribute = configMessage.activityConfig;
        this.slectAllOption = true;
        this.responseMessage = {};
        this.listPage = true;
        this.disable = false;
        this.vehicles = [];
        this.vehicleActivity = [];
        this.loadmore = false;
        this.vehicle = {};
        this.deviceId = '';
        this.currentVehicle = '';
        this.isLoading = false;
        this.refreshing = false;
        this.loadingIndicator = true;
        this.serialNumber = '';
        this.vehicleHeader = [];
        this.vehicleFullString = [];
        /** This is used to display vehicle detail in pop up*/
        this.singleVehicleDetail = [];
        this.currentPageCount = {
            start: 0,
            end: 0
        };
        this.vehiclestaticHeader = [
            { name: 'assetName', displayName: 'Name', type: 'STRING' },
            { name: 'vin', displayName: 'VIN', type: 'STRING' },
            { name: 'serialNumber', displayName: 'Serial Number', type: 'STRING' },
            { name: 'groupName', displayName: 'Group Name', type: 'STRING' }
        ];
        this.noPrefColumns = ['assetName', 'vin', 'serialNumber', 'groupName'];
        this.vehicleTags = environment.vehicleTags;
        this.vehicleNewHeader = [];
        this.vehicleSearchheader = [];
        this.moduleName = 'VEHICLE';
        this.permissionAuthorities = {};
        this.modules = [];
        this.itemsPerPage = 10; // we are showing 10 items per page
        this.lastPage = 0;
        this.searchModel = {};
        this.timeout = null;
        this.page = 0;
        this.pageLimit = environment.pageLimit;
        this.dateAttributes = configMessage.dateAttributes;
        this.vehicleDetail = {};
        this.pageInfo = {
            offset: 0,
            count: 0,
            limit: 10,
            pageSize: 0,
            lastPage: 0,
        };
        this.prevResponse = 0;
        this.groups = [];
        this.vehicleFullHeader = [];
        this.sortParams = {};
        // Multi Select
        this.allColumns = [];
        this.columns = [];
        this.headerPref = [];
        this.finalHead = [];
        this.headSelSettings = {};
        this.finalSelected = [];
        this.allSelected = false;
        this.columnPopup = false;
        this.updatingPref = false;
        this.selectCount = 0;
        this.checkedItems = [];
        this.pageLoading = true;
        this.modules = JSON.parse(localStorage.getItem('accessRoleModules'));
        this.permissionAuthorities = this.modules.find(function (module) { return module.name == _this.moduleName; });
    }
    VehicleViewReportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.modules = JSON.parse(localStorage.getItem('accessRoleModules'));
        this.headerPref = JSON.parse(localStorage.getItem('preferences')).vehicle.selectedFields;
        this.permissionAuthorities = this.modules.find(function (module) { return module.name == _this.moduleName; });
        this.message = this.adminService.getSideMenu();
        this.title.setTitle('Vehicles' + environment.title_text);
        this.getVehicleHeader();
    };
    VehicleViewReportComponent.prototype.ngAfterViewInit = function () {
        this.initAutocomplete();
    };
    VehicleViewReportComponent.prototype.initAutocomplete = function () {
        var mapOptionObject = {
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            zoom: 4,
            minZoom: 4,
            maxZoom: 18,
            mapTypeControl: true,
            mapTypeControlOptions: {
                position: google.maps.ControlPosition.TOP_RIGHT
            },
            zoomControl: true,
            zoomControlOptions: {
                position: google.maps.ControlPosition.RIGHT_CENTER
            },
            scaleControl: true,
            streetViewControl: false,
            fullscreenControl: false,
            clickableIcons: false,
            center: {
                lat: environment.lat,
                lng: environment.lng
            }
        };
        this.map = new google.maps.Map(this.gmapElement.nativeElement, mapOptionObject);
    };
    VehicleViewReportComponent.prototype.refresh = function () {
        this.refreshing = true;
        this.page = 0;
        this.pageInfo.offset = 0;
        if (this.searchModel.deviceId && this.searchModel.vehicleTypeVal) {
            this.onSearch();
        }
        else {
            this.getAllVehicles(this.page);
            this.getVehicleCount();
        }
    };
    // To get the header of Vehicles table
    VehicleViewReportComponent.prototype.getVehicleHeader = function () {
        var _this = this;
        this.sharedService.getVehicleHeader().subscribe(function (res) {
            // this.isLoading = false;
            _this.vehicleFullHeader = res;
            // this.isLoading = true;
            _this.setTableHeader(res);
            _this.vehicleFullString = res.map(function (item) {
                return item.name;
            });
            _this.vehicleHeader = res.filter(function (x) {
                // tslint:disable-next-line: max-line-length
                if (!(x.name == 'vin' || x.name.slice(-2) == 'Id' || x.name == 'assetName' || x.name == 'serialNumber' || x.name == 'lastCommunication' || x.name == 'groupName' || x.name === 'createdAt' || x.name === 'activatedOn' || x.name === 'tags')) {
                    return x;
                }
            });
            _this.vehicleNewHeader = res.filter(function (x) {
                // tslint:disable-next-line: max-line-length
                if (!(_this.dateAttributes.includes(x.name) || x.name === 'updatedAt' || x.name === 'createdAt' || x.name == 'vin' || x.name.slice(-2) == 'Id' || x.name == 'assetName' || x.name == 'serialNumber' || x.name == 'groupName' || x.name == 'deviceId' || x.name == 'customerId' || x.name == 'companyId' || x.name == 'groupId')) {
                    return x;
                }
            });
            // this.dtTrigger.next();
            // this.vehicleHeader = server;
            _this.vehicleSearchheader = _this.vehiclestaticHeader.concat(_this.vehicleNewHeader);
            var queryParams = _this.route.snapshot.queryParams;
            if (!((queryParams.searchBy && queryParams.value) || queryParams.serialNumber)) {
                _this.searchModel['deviceId'] = _this.vehicleSearchheader[0].name;
                _this.page = 0;
                _this.getAllVehicles(_this.page);
                _this.getVehicleCount();
            }
            _this.tempSelection = _this.searchModel['deviceId'];
        }, function (error) {
            _this.pageLoading = false;
            _this.sharedService.getErrorMsg(error);
        });
    };
    VehicleViewReportComponent.prototype.setTableHeader = function (res) {
        var _this = this;
        var pref = [];
        if (this.headerPref && this.headerPref.length) {
            pref = this.headerPref;
        }
        else {
            pref = this.noPrefColumns;
        }
        res.map(function (head) {
            if (pref.includes(head.name)) {
                head.checked = true;
                _this.selectCount++;
                _this.finalHead.push(head);
            }
        });
        if (this.selectCount == res.length) {
            this.allSelected = true;
        }
        this.allColumns = res;
        this.columns = res;
        var keyArray = [];
        this.allColumns.map(function (header) {
            if (header.checked) {
                keyArray.push(header.name);
            }
        });
        this.columns = this.allColumns;
        this.checkedItems = keyArray;
    };
    VehicleViewReportComponent.prototype.togglePopup = function () {
        if (this.columnPopup) {
            this.cancelSelection();
        }
        else {
            this.columnPopup = true;
            this.checkCustomSelected();
        }
    };
    VehicleViewReportComponent.prototype.checkCustomSelected = function () {
        var _this = this;
        this.selectCount = 0;
        if (this.searchModel && this.searchModel.columnVal) {
            this.searchModel.columnVal = '';
        }
        // let prefrences = JSON.parse(localStorage.getItem('preferences'));
        // this.allColumns = prefrences.device.selectedFields;
        this.allColumns.map(function (head) {
            if (_this.checkedItems.includes(head.name)) {
                _this.selectCount++;
                head.checked = true;
            }
            else {
                head.checked = false;
            }
            // if (this.noPrefColumns.includes(head.name)) {
            //   head.checked = true;
            //   this.selectCount++;
            // }
        });
        this.columns = this.allColumns;
        if (this.selectCount == this.checkedItems) {
            this.allSelected = true;
        }
    };
    VehicleViewReportComponent.prototype.selectItems = function (event, header, i) {
        if (header.name !== 'assetName') {
            if (event.target.checked) {
                this.selectCount++;
            }
            else {
                this.selectCount--;
            }
            this.allColumns.map(function (head) {
                if (head.name == header.name) {
                    if (event.target.checked) {
                        head.checked = true;
                    }
                    else {
                        head.checked = false;
                    }
                }
            });
            // this.allColumns[i].checked = event.target.checked;
        }
    };
    VehicleViewReportComponent.prototype.selectAllItems = function (event) {
        this.allSelected = event.target.checked;
        if (event.target.checked) {
            this.selectCount = this.allColumns.length;
        }
        else {
            this.selectCount = 0;
        }
        this.allColumns.map(function (head) {
            if (head.name === 'assetName') {
                head.checked = true;
            }
            else {
                head.checked = event.target.checked;
            }
        });
    };
    VehicleViewReportComponent.prototype.cancelSelection = function () {
        this.selectCount = 0;
        this.columnPopup = false;
    };
    VehicleViewReportComponent.prototype.updatePreference = function () {
        var _this = this;
        var tempArray = [];
        var keyArray = [];
        this.updatingPref = true;
        this.allColumns.map(function (header) {
            if (header.checked) {
                var obj = {
                    name: header.name,
                    displayName: header.displayName,
                    checked: header.checked,
                    type: header.type
                };
                tempArray.push(obj);
                keyArray.push(header.name);
            }
        });
        var payload = { vehicle: { selectedFields: keyArray } };
        this.sharedService.updatePreference(payload).subscribe(function (res) {
            _this.finalHead = tempArray;
            _this.headerPref = tempArray;
            _this.checkedItems = keyArray;
            _this.columnPopup = false;
            _this.updatingPref = false;
            _this.columns = _this.allColumns;
            var updateStorage = JSON.parse(localStorage.getItem('preferences'));
            if (updateStorage.vehicle.selectedFields) {
                updateStorage.vehicle.selectedFields = keyArray;
                localStorage.setItem('preferences', JSON.stringify(updateStorage));
            }
        }, function (error) {
            _this.sharedService.getErrorMsg(error);
            _this.updatingPref = false;
        });
    };
    VehicleViewReportComponent.prototype.onPageChange = function (value) {
        if (this.lastPage === value) {
            var page = ++this.page;
            this.getAllVehicles(page);
        }
    };
    // To get all vehicles
    VehicleViewReportComponent.prototype.getAllVehicles = function (page) {
        var _this = this;
        // this.vehiclessArray$.push(this.sharedService.getAllVehicles(page));
        this.vehicleService.getAllVehicles(page, this.sortParams).subscribe(function (res) {
            // this.searchModel.deviceId = this.tempSelection;
            // this.isLoading = false;
            if (page == 0) {
                _this.vehicles = res;
            }
            else {
                _this.vehicles = tslib_1.__spread(_this.vehicles, res);
            }
            _this.loadingIndicator = false;
            _this.isLoading = true;
            _this.prevResponse = res.length;
            _this.pageInfo.count = _this.vehicles.length;
            var pagecount = _this.pageInfo.count / _this.pageInfo.limit;
            _this.pageInfo.lastPage = Math.floor(pagecount);
            var decimal = pagecount - _this.pageInfo.lastPage;
            if (!Number.isInteger(decimal)) {
                _this.pageInfo.lastPage = _this.pageInfo.lastPage + 1;
            }
            if (page == 0) {
                if (res.length) {
                    _this.currentPageCount.start = (_this.pageInfo.offset * _this.pageInfo.pageSize) + 1;
                    if (_this.vehicles.length <= 10) {
                        _this.currentPageCount.end = _this.pageInfo.count;
                    }
                    else {
                        _this.currentPageCount.end = (_this.pageInfo.offset * _this.pageInfo.pageSize) + _this.pageInfo.limit;
                    }
                }
            }
            _this.pageLoading = false;
            _this.refreshing = false;
        }, function (error) {
            _this.pageLoading = false;
            _this.refreshing = false;
            _this.sharedService.getErrorMsg(error);
        });
    };
    VehicleViewReportComponent.prototype.clearSearch = function (f) {
        this.tempSelection = this.searchModel.deviceId;
        this.searchModel.vehicleTypeVal = '';
        if (f.valid) {
            this.vehicles = [];
            f.resetForm();
            this.loadingIndicator = true;
            this.page = 0;
            this.pageInfo.offset = 0;
            this.getAllVehicles(this.page);
            this.getVehicleCount();
        }
    };
    VehicleViewReportComponent.prototype.onSort = function (event) {
        var _this = this;
        this.loadingIndicator = true;
        this.sortParams = event.sorts[0];
        if (this.searchModel.vehicleTypeVal) {
            var vehicleTypeValue = this.searchModel.vehicleTypeVal.trim();
            this.vehicleService.getAllVehicles(0, event.sorts[0], this.searchModel.deviceId, vehicleTypeValue).subscribe(function (res) {
                _this.vehicles = res;
                _this.loadingIndicator = false;
            }, function (error) {
                _this.sharedService.getErrorMsg(error);
            });
        }
        else {
            this.vehicleService.getAllVehicles(0, event.sorts[0]).subscribe(function (res) {
                _this.vehicles = res;
                _this.loadingIndicator = false;
            }, function (error) {
                _this.sharedService.getErrorMsg(error);
            });
        }
    };
    VehicleViewReportComponent.prototype.onColumnSearch = function () {
        var _this = this;
        this.columns = this.allColumns;
        var inputVal = this.searchModel.columnVal.trim();
        var val = inputVal.toLowerCase();
        if (val === '') {
            this.slectAllOption = true;
            this.columns = this.allColumns;
            return;
        }
        else {
            if (this.allColumns.length) {
                this.slectAllOption = false;
                var temp = this.allColumns.filter(function (item, index) {
                    if (item.displayName.toLowerCase().includes(val)) {
                        return _this.allColumns[index];
                    }
                });
                this.columns = temp;
            }
        }
    };
    // To download table data as a csv file
    VehicleViewReportComponent.prototype.downloadVehicleCSV = function () {
        var _this = this;
        this.vehicleService.downloadVehicleCSV(this.searchModel).subscribe(function (res) {
            var contentDisposition = res.headers.get('Content-Disposition');
            var filename;
            if (contentDisposition == null) {
                var date = new Date();
                filename = 'Vehicles_' + date + '.csv';
            }
            else {
                filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
            }
            var blob = new Blob([res.body], {
                type: 'text/csv;charset=utf-8'
            });
            FileSaver.saveAs(blob, filename);
        }, function (error) {
            _this.sharedService.getErrorMsg(error);
        });
    };
    // To download table data as an XML file
    VehicleViewReportComponent.prototype.downloadVehicleXML = function () {
        this.vehicleService.downloadVehicleXML(this.searchModel).subscribe(function (res) {
            var contentDisposition = res.headers.get('Content-Disposition');
            var filename;
            if (contentDisposition == null) {
                var date = new Date();
                filename = 'Vehicles_' + date + '.xml';
            }
            else {
                filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
            }
            var blob = new Blob([res.body], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-16le'
            });
            FileSaver.saveAs(blob, filename);
        }, function (error) {
        });
    };
    // To get total vehicles count
    VehicleViewReportComponent.prototype.getVehicleCount = function () {
        var _this = this;
        this.vehicleService.getDeviceCount().subscribe(function (res) {
            _this.vehicleTotalCount = res;
            // this.isLoading = true;
        }, function (error) {
        });
    };
    VehicleViewReportComponent.prototype.searchDataOnChange = function () {
        if (this.searchModel.searchValue && this.searchModel.searchId) {
            this.onSearch();
        }
    };
    VehicleViewReportComponent.prototype.selectInput = function () {
        var inputElem = this.inputOne.nativeElement;
        inputElem.select();
    };
    VehicleViewReportComponent.prototype.inputValidator = function (event) {
        var term = event.target.value.replace(/[^_\-@.,a-zA-Z0-9\s]/g, "");
        this.searchModel.vehicleTypeVal = term.split(/[ ]+/).filter(function (v) { return v !== ''; }).join(',');
        this.onSearch();
    };
    VehicleViewReportComponent.prototype.onSearch = function () {
        clearTimeout(this.timeout);
        var global = this;
        this.timeout = setTimeout(function () {
            if (global.searchModel.deviceId && global.searchModel.vehicleTypeVal) {
                var vehicleTypeValue = global.searchModel.vehicleTypeVal.trim();
                global.sharedService.onSearchVehicle(global.searchModel.deviceId, vehicleTypeValue).subscribe({
                    next: function (res) {
                        global.loadingIndicator = false;
                        global.vehicles = res;
                        if (res.length) {
                            global.vehicleTotalCount = {};
                            global.vehicleTotalCount['count'] = res.length;
                            global.pageInfo.offset = 0;
                            global.pageInfo.count = res.length;
                            global.currentPageCount.start = (global.pageInfo.offset * global.pageInfo.pageSize) + 1;
                            if (global.vehicles.length <= 10) {
                                global.currentPageCount.end = global.pageInfo.count;
                            }
                            else {
                                global.currentPageCount.end = (global.pageInfo.offset * global.pageInfo.pageSize) + global.pageInfo.limit;
                            }
                        }
                        else {
                            global.vehicleTotalCount = false;
                        }
                        global.isLoading = true;
                        global.prevResponse = 0;
                        global.refreshing = false;
                    },
                    error: function (error) {
                        global.refreshing = false;
                        global.sharedService.getErrorMsg(error);
                        global.loadingIndicator = false;
                    }
                });
            }
            else {
                global.page = 0;
                global.pageInfo.offset = 0;
                global.getAllVehicles(0);
                global.getVehicleCount();
            }
        }, 1500);
    };
    // To get the vehicles for each page in the table
    VehicleViewReportComponent.prototype.pageCallback = function (value) {
        this.pageInfo.offset = value.offset;
        this.currentPageCount.start = (value.offset * value.pageSize) + 1;
        var lastPage = Math.floor(this.vehicles.length / 10);
        if (lastPage == value.offset) {
            this.currentPageCount.end = value.count;
        }
        else {
            this.currentPageCount.end = (value.offset * value.pageSize) + value.limit;
        }
        if (this.pageInfo.lastPage === (this.pageInfo.offset + 1)) {
            if (this.prevResponse) {
                this.page = ++this.page;
                this.getAllVehicles(this.page);
            }
        }
    };
    VehicleViewReportComponent.prototype.addMarker = function (lat, long, img) {
        var latlng = new google.maps.LatLng(lat, long);
        var marker = new google.maps.Marker({
            position: latlng,
            map: this.map,
            // label: eventDate,
            icon: img
        });
        this.setAnimation(marker);
        this.markers.push(marker);
        var bounds = new google.maps.LatLngBounds();
        bounds.extend(latlng);
        this.map.fitBounds(bounds);
        this.markers.push(marker);
    };
    // Set Animation
    VehicleViewReportComponent.prototype.setAnimation = function (marker) {
        marker.setAnimation(google.maps.Animation.BOUNCE);
        setTimeout(function () { marker.setAnimation(null); }, 750);
    };
    VehicleViewReportComponent.prototype.clearMarkers = function () {
        for (var i = 0; i < this.markers.length; i++) {
            this.markers[i].setMap(null);
        }
    };
    VehicleViewReportComponent.prototype.loadMap = function (row) {
        this.selectedVehicle = row;
        var global = this;
        var imgEnd = '../../assets/img/rect836.png';
        if (this.markers.length) {
            this.clearMarkers();
        }
        this.addMarker(row.latitude, row.longitude, imgEnd);
        setTimeout(function () {
            google.maps.event.trigger(global.map, 'resize');
        }, 3000);
    };
    return VehicleViewReportComponent;
}());
export { VehicleViewReportComponent };
