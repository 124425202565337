
import {tap} from 'rxjs/operators';
import { Injectable } from '@angular/core'
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Router, ActivatedRoute } from '@angular/router';
@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
    constructor(private router: Router,
                private route: ActivatedRoute) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(tap(event => {}, err => {
            // if (err instanceof HttpErrorResponse && err.status == 401) {
            //     // handle 401 errors
            // }
            if(err instanceof HttpErrorResponse && err.status == 400) {
            }
            // else if(err instanceof HttpErrorResponse && err.status == 500) {
            //     this.router.navigate(['/500']);
            // }
            // else if(err instanceof HttpErrorResponse && err.status == 403) {
            //     this.router.navigate(['/403']);
            //
            // }
            else if(err instanceof HttpErrorResponse && err.status == 401 && !window.location.href.includes("tracking?key")) {
                // let impersonate = localStorage.getItem('impersonate');
                  if(!this.route.snapshot.queryParams['returnUrl'] && this.router.url !== '/login') {
                    this.router.navigate(['login'], { queryParams: {
                      returnUrl: this.router.url,
                      returnModule: localStorage.getItem("currentModule")
                    }});
                  } else {
                    return;
                  }
                localStorage.removeItem('currentUser');
                localStorage.clear();
                // this.router.navigate(['']);
            }
            // else if(err instanceof HttpErrorResponse && err.status == 404) {
            //     this.router.navigate(['/404']);
            // }
        }));

//         return next.handle(req).map(resp => {
//                 if (resp instanceof HttpResponse) {
//                 }
//                 return resp;
//             }).catch(err => {
//                 if (err instanceof HttpResponse)
//  {
//                 }

//                 return Observable.of(err);
//             });

    }
}
