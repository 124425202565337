import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '@myenv/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
var DestinationsService = /** @class */ (function () {
    function DestinationsService(http) {
        this.http = http;
        this.apiUrl = environment.apiUrl;
    }
    DestinationsService.prototype.getMessageCount = function (vid, startTime, endTime) {
        return this.http.get(this.apiUrl + 'ups/deviceId/' + vid + '/count?startTime=' + startTime + '&endTime=' + endTime);
    };
    DestinationsService.prototype.getKafkaLatency = function (vid, startTime, endTime, page) {
        return this.http.get(this.apiUrl + 'ups/latency?deviceId=' + vid + '&startTime=' + startTime + '&endTime=' + endTime + '&page=' + page + '&size=100');
    };
    DestinationsService.prototype.getReports = function (vin, startTime, endTime) {
        // tslint:disable-next-line: max-line-length
        return this.http.get(this.apiUrl + 'messages/vin/' + vin + '?attributes=eventTime,latitude,longitude,address&startTime=' + startTime + '&endTime' + endTime).pipe(catchError(this.handleError));
    };
    DestinationsService.prototype.getSingleReportByReportId = function (reportId, deviceId, startTime, endTime, page) {
        // tslint:disable-next-line: max-line-length
        return this.http.get(this.apiUrl + 'reports/' + reportId + '/generate?startTime=' + startTime + '&endTime=' + endTime + '&page=' + page + '&deviceId=' + deviceId).pipe(catchError(this.handleError));
    };
    DestinationsService.prototype.getCorelationChartData = function (reportId, deviceId, startTime, endTime, data) {
        var httpOptions = {
            'responseType': 'arraybuffer'
            // 'responseType'  : 'blob' as 'json'
            // This also worked
        };
        return this.http.post(this.apiUrl + 'reports/' + reportId + '/dataScience/download?startTime=' + startTime + '&endTime=' + endTime + '&deviceId=' + deviceId, data, { responseType: "blob" }).pipe(catchError(this.handleError));
    };
    DestinationsService.prototype.getChartData = function (reportId, deviceId, startTime, endTime, page, limit, data) {
        // tslint:disable-next-line: max-line-length
        return this.http.get(this.apiUrl + 'messages/device/' + deviceId + '?startTime=' + startTime + '&endTime=' + endTime + '&page=' + page + '&limit=' + limit + '&attributes=' + data + ',eventTime').pipe(catchError(this.handleError));
    };
    DestinationsService.prototype.downloadReportCSV = function (reportId, deviceId, startTime, endTime) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        // tslint:disable-next-line: max-line-length
        return this.http.post(this.apiUrl + 'reports/' + reportId + '/download/token?startTime=' + startTime + '&endTime=' + endTime + '&deviceId=' + deviceId, { headers: headers }).pipe(catchError(this.handleError));
    };
    DestinationsService.prototype.getAttribute = function (data) {
        return this.http.get(this.apiUrl + 'reports/attribute/' + data).pipe(catchError(this.handleError));
    };
    DestinationsService.prototype.handleError = function (error) {
        return observableThrowError(error.error || error.json() || error || 'Server error');
    };
    return DestinationsService;
}());
export { DestinationsService };
