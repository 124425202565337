import { OnInit, OnDestroy, ElementRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SupportService } from '@components/support/support.service';
import { SharedService } from '@shared/shared.service';
import { Router } from '@angular/router';
import { environment } from '@myenv/environment';
import { AuthenticationService } from '@layouts/login/authentication.service';
import { CookieService } from 'angular2-cookie/core';
import { enMessage } from '@shared/en-us-message';
import { configMessage } from '@shared/config-message';
var AdvancedSearchComponent = /** @class */ (function () {
    function AdvancedSearchComponent(router, supportService, sharedService, title, _cookieService, authenticationService) {
        this.router = router;
        this.supportService = supportService;
        this.sharedService = sharedService;
        this.title = title;
        this._cookieService = _cookieService;
        this.authenticationService = authenticationService;
        this.modules = [];
        this.moduleName = 'SUPPORT';
        this.permissionAuthorities = {};
        this.impersonatePermission = {};
        this.timeout = null;
        this.searchModel = {
            supportTypeVal: ''
        };
        this.users = [];
        this.devices = [];
        this.vehicles = [];
        this.isLoading = false;
        this.currentUser = {};
        this.noResults = false;
        this.companyName = environment.companyName;
        this.impersonate = false;
        this.responseMessage = {};
        this.permissionModules = [];
        this.currentPermissionModules = [];
        /**
         * For date format
         */
        this.isoDateTimeFormat = environment.isoDateTimeFormat;
        this.deviceStatus = configMessage.deviceStatus;
        this.deviceStatusClass = configMessage.deviceStatusClass;
    }
    AdvancedSearchComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.title.setTitle('Support' + environment.title_text);
        this.searchModel.searchType = 'emailId';
        this.searchModel.searchGroup = 'User';
        this.modules = JSON.parse(localStorage.getItem('accessRoleModules'));
        this.permissionAuthorities = this.modules.find(function (module) { return module.name === _this.moduleName; });
        this.impersonatePermission = this.modules.find(function (module) { return module.name === 'IMPERSONATE'; });
        this.currentPermissionModules = JSON.parse(localStorage.getItem('permissionModules'));
        var supportSearch = JSON.parse(localStorage.getItem('supportSearch'));
        if (supportSearch && supportSearch.searchGroup && supportSearch.supportTypeVal && supportSearch.searchType) {
            this.searchModel.searchGroup = supportSearch.searchGroup;
            this.searchModel.searchType = supportSearch.searchType;
            this.searchModel.supportTypeVal = supportSearch.supportTypeVal;
            this.onSearch();
        }
    };
    // Records the group name on select change
    AdvancedSearchComponent.prototype.onSlotChange = function (e) {
        var selectedIndex = e.target.selectedIndex;
        this.searchModel.searchGroup = e.target.options[selectedIndex].parentNode.getAttribute('label');
        this.onSearch();
    };
    AdvancedSearchComponent.prototype.searchDataOnChange = function () {
        if (this.searchModel.searchValue && this.searchModel.searchId) {
            this.onSearch();
        }
    };
    AdvancedSearchComponent.prototype.selectInput = function () {
        var inputElem = this.inputOne.nativeElement;
        inputElem.select();
    };
    AdvancedSearchComponent.prototype.inputValidator = function (event) {
        // console.log(event.target.value);
        var pattern = /^[_\-@.,a-zA-Z0-9\s]*$/;
        // let inputChar = String.fromCharCode(event.charCode)
        if (!pattern.test(event.target.value)) {
            event.target.value = event.target.value.replace(/[^_\-@.,a-zA-Z0-9\s]/g, '');
            this.searchModel.supportTypeVal = event.target.value;
            this.onSearch();
        }
        else {
            this.onSearch();
        }
    };
    // 'show' button function for User group search
    // To search for users based on filter
    AdvancedSearchComponent.prototype.onSearch = function () {
        clearTimeout(this.timeout);
        var global = this;
        this.timeout = setTimeout(function () {
            if (global.searchModel.supportTypeVal) {
                global.users = [];
                global.devices = [];
                global.vehicles = [];
                global.typeValue = global.searchModel.supportTypeVal.trim();
                var searchGroup = global.searchModel.searchGroup;
                var supportSearch = {
                    searchGroup: searchGroup,
                    searchType: global.searchModel.searchType,
                    supportTypeVal: global.typeValue
                };
                localStorage.setItem('supportSearch', JSON.stringify(supportSearch));
                if (searchGroup === 'User') {
                    global.searchUser();
                }
                else if (searchGroup === 'Device') {
                    global.searchDevice();
                }
                else if (searchGroup === 'Vehicle') {
                    global.searchVehicle();
                }
            }
            else {
                global.users = [];
                global.devices = [];
                global.vehicles = [];
            }
        }, 1500);
    };
    // search all users
    AdvancedSearchComponent.prototype.searchUser = function () {
        var _this = this;
        this.supportService.onSearchUser(this.searchModel.searchType, this.typeValue).subscribe(function (res) {
            _this.users = res;
            (_this.users.length === 0) ? _this.noResults = true : _this.noResults = false;
        }, function (error) {
            _this.sharedService.getErrorMsg(error);
        });
    };
    // search all devices
    AdvancedSearchComponent.prototype.searchDevice = function () {
        var _this = this;
        this.sharedService.onSearchDevice(this.searchModel.searchType, this.typeValue).subscribe(function (res) {
            _this.devices = res;
            (_this.devices.length === 0) ? _this.noResults = true : _this.noResults = false;
        }, function (error) {
            _this.sharedService.getErrorMsg(error);
        });
    };
    // search all vehicles
    AdvancedSearchComponent.prototype.searchVehicle = function () {
        var _this = this;
        this.sharedService.onSearchVehicle(this.searchModel.searchType, this.typeValue).subscribe(function (res) {
            _this.vehicles = res;
            (_this.vehicles.length === 0) ? _this.noResults = true : _this.noResults = false;
        }, function (error) {
            _this.sharedService.getErrorMsg(error);
        });
    };
    // get customer id for device, vehicle and search user based on that
    AdvancedSearchComponent.prototype.getCustomerId = function (value) {
        var _this = this;
        this.supportService.onSearchUser('customerId', +value).subscribe(function (res) {
            // this.getCurrentUser(res[0]);
            _this.findSuperUser(res);
        });
    };
    AdvancedSearchComponent.prototype.findSuperUser = function (data) {
        var _this = this;
        data.map(function (item) {
            if (item.userType === 'SUPER_USER') {
                _this.getCurrentUser(item);
                return;
            }
        });
    };
    // get the user info
    /* To Navigate to user-info page on clicking show button in table row
        passing the user name and user id as query params
    */
    AdvancedSearchComponent.prototype.getCurrentUser = function (value) {
        try {
            this.currentUser = value;
            this.router.navigate(['/support/user-info'], {
                queryParams: {
                    id: value.userId,
                    name: value.userName
                }
            });
        }
        catch (e) {
            console.log(e.message);
        }
    };
    AdvancedSearchComponent.prototype.impersonateUser = function (user) {
        var _this = this;
        this.authenticationService.impersonate(user.userId).subscribe(function (res) {
            // this.vehicles = res;
            // window.location.reload();
            if (res.accessToken) {
                var base64Token = res.accessToken;
                _this.authenticationService.setHeaders(base64Token);
                _this._cookieService.put('user', 'impersonateUser');
                if (_this._cookieService.get('user')) {
                    _this.impersonate = true;
                }
                _this.getUserPreferences();
                _this.tokenScenario(res);
            }
            else if (res.status == 404) {
                _this.authenticationService.setHeaders(_this.authenticationService.getHeaders());
                var errorCode = res.errorCode;
                var errorMessage = enMessage[errorCode];
                _this.responseMessage = { success: false, message: errorMessage };
                _this.sharedService.setResponseMessage(_this.responseMessage);
            }
        }, function (error) {
            var token = _this.authenticationService.getHeaders();
            _this.authenticationService.setHeaders(token);
            if (error.status == 404) {
                _this.sharedService.getErrorMsg(error);
            }
            else {
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    // To get user preferences
    AdvancedSearchComponent.prototype.getUserPreferences = function () {
        var _this = this;
        // Get the User Preferences by making a seperate API call
        this.authenticationService.userPreferences().subscribe(function (responseInfo) {
            if (responseInfo) {
                localStorage.setItem('preferences', JSON.stringify(responseInfo));
            }
            if (responseInfo.liveHistory || responseInfo.live.header) {
                if (responseInfo.liveHistory && responseInfo.liveHistory.header) {
                    _this.authenticationService.liveHistoryPrefrences(responseInfo.liveHistory.header);
                }
                // if (responseInfo.live && responseInfo.live.header) {
                //   this.authenticationService.livePrefrences(responseInfo.live.header);
                // }
            }
        }, function (error) {
            _this.sharedService.getErrorMsg(error);
        });
    };
    // setting the permissions and impersonating the user.
    AdvancedSearchComponent.prototype.tokenScenario = function (res) {
        var _this = this;
        this.authenticationService.getLoggedUser().subscribe(function (reponseInFo) {
            if (res.status === 404) {
                var errorCode = res.errorCode;
                var errorMessage = enMessage[errorCode];
                _this.responseMessage = { success: false, message: errorMessage };
                _this.sharedService.setResponseMessage(_this.responseMessage);
            }
            else {
                _this.authenticationService.setLoggedInUser(reponseInFo);
                _this.permissionModules = reponseInFo.modules.map(function (data) { return data.name; });
                if (_this.permissionModules.includes('REPORT')) {
                    localStorage.setItem('lastReportUrl', '/reports/system/live-status');
                }
                _this.sharedService.setAccessRole(reponseInFo.modules, _this.permissionModules);
                var permissionModules = JSON.parse(localStorage.getItem('permissionModules'));
                _this.sharedService.setDefaultUrl(permissionModules);
                window.location.reload();
                localStorage.removeItem('reports');
                localStorage.removeItem('dashboards');
                localStorage.removeItem('viewReport');
                localStorage.removeItem('reportData');
                localStorage.removeItem('currentReport');
                localStorage.removeItem('reportsVehicle');
                localStorage.removeItem('unreadNotification');
                localStorage.setItem('impersonate', 'true');
            }
        }, function (error) {
            _this.sharedService.getErrorMsg(error);
        });
    };
    AdvancedSearchComponent.prototype.clear = function () {
        this.searchModel.supportTypeVal = '';
        localStorage.removeItem('supportSearch');
    };
    AdvancedSearchComponent.prototype.ngOnDestroy = function () {
        if (this.searchModel.supportTypeVal === '') {
            localStorage.removeItem('supportSearch');
        }
    };
    return AdvancedSearchComponent;
}());
export { AdvancedSearchComponent };
