/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./reports-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./reports-header.component";
var styles_ReportsHeaderComponent = [i0.styles];
var RenderType_ReportsHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReportsHeaderComponent, data: {} });
export { RenderType_ReportsHeaderComponent as RenderType_ReportsHeaderComponent };
function View_ReportsHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "li", [["class", "nav-item active"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(2, 0, null, null, 3, "a", [["class", "nav-link text-center mt-1 active ml-4"], ["data-toggle", "tab"], ["href", "#general"], ["role", "tab"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fa fa-bar-chart"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" General\n        "])), (_l()(), i1.ɵted(-1, null, ["\n    "]))], null, null); }
function View_ReportsHeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(2, 0, null, null, 4, "a", [["class", "nav-link text-center mt-1 ml-4"], ["href", "#deepView"], ["routerLink", "/reports/deep-view"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "fa fa-th"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Deep View\n        "])), (_l()(), i1.ɵted(-1, null, ["\n    "]))], function (_ck, _v) { var currVal_2 = "/reports/deep-view"; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
function View_ReportsHeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(2, 0, null, null, 4, "a", [["class", "nav-link text-center mt-1"], ["href", "#score"], ["routerLink", "/score"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "fa fa-line-chart"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Scores\n        "])), (_l()(), i1.ɵted(-1, null, ["\n    "]))], function (_ck, _v) { var currVal_2 = "/score"; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_ReportsHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "ul", [["class", "nav nav-tabs detailed-tabs relative page-sub-heading"], ["role", "tablist"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportsHeaderComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportsHeaderComponent_2)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportsHeaderComponent_3)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.permissionModules.includes("REPORT"); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.permissionModules.includes("DEEP-VIEW"); _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.permissionModules.includes("SCORE"); _ck(_v, 9, 0, currVal_2); }, null); }
export function View_ReportsHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-reports-header", [], null, null, null, View_ReportsHeaderComponent_0, RenderType_ReportsHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i4.ReportsHeaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReportsHeaderComponentNgFactory = i1.ɵccf("app-reports-header", i4.ReportsHeaderComponent, View_ReportsHeaderComponent_Host_0, {}, {}, []);
export { ReportsHeaderComponentNgFactory as ReportsHeaderComponentNgFactory };
