
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@myenv/environment';

@Injectable()
export class TripsNewService {
  public apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  // searchVehicles(searchValue) {
  //   return this.http.get(this.apiUrl + `messages/latest/search?assetName=${searchValue}`)
  //   .catch(this.handleError);
  // }
  // To get the trips
  getTrips(deviceId, endTime, startTime, sort): Observable<any> {
    return this.http.get(this.apiUrl + 'trips/device/' + deviceId + '?startTime=' + startTime + '&endTime=' + endTime + '&sort=' + sort);
  }

  getPolylineData(deviceId, startTime, endTime): Observable<any> {
    return this.http
      .get(
        this.apiUrl +
        `messages/valid/device/${deviceId}` +
        `?attributes=eventTime,latitude,longitude,messageType,messageId,` +
        `maxSpeed,avgSpeed,initialSpeed,finalSpeed,maxBraking,initialSpeed,finalSpeed,maxAcceleration` +
        `&startTime=${startTime}&endTime=${endTime}&limit=10000`
      ).pipe(
      catchError(this.handleError));
  }

  handleError(error: any | any) {
    return observableThrowError(
      error.error || error.json() || error || 'Server error'
    );
  }
}
