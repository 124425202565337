import * as tslib_1 from "tslib";
import { GraphicReportsService } from '@components/graphic-reports/graphic-reports.service';
import { OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SharedService } from '@myproject/shared/shared.service';
import Chart from 'chart.js';
import { DatePipe } from '@angular/common';
import { configMessage } from '@shared/config-message';
import { DeviceService } from '@myproject/components/device/device.service';
import { environment } from '@myenv/environment';
import { CustomerService } from '../../../customer/customer.service';
var BillingReportComponent = /** @class */ (function () {
    function BillingReportComponent(title, reportsService, sharedService, deviceService, customerService) {
        this.title = title;
        this.reportsService = reportsService;
        this.sharedService = sharedService;
        this.deviceService = deviceService;
        this.customerService = customerService;
        this.status = {
            total: 0,
            communicating: 0,
            notCommunicating: 0,
            disconnected: 0,
            notConnected: 0
        };
        this.statusPercent = {
            communicating: 0,
            notCommunicating: 0,
            disconnected: 0,
            notConnected: 0
        };
        this.deviceCounts = {
            deviceAdded: 0,
            deviceRemoved: 0,
            messageCount: 0,
            deviceReplaced: 0
        };
        this.isLoading = false;
        this.dataPattern = [
            // { key: 'total', label: 'Total' },
            { key: 'communicating', label: 'Communicating', color: '#39cb39' },
            { key: 'notCommunicating', label: 'Not Communicating', color: '#ff8000' },
            { key: 'disconnected', label: 'Disconnected', color: '#ff0000' },
            { key: 'notConnected', label: 'Not Connected', color: '#5c8a8a' },
        ];
        this.selectedDevice = null;
        this.highestCount = 0;
        // Drawer variables
        this.isDrawerOpen = true;
        this.devices = [];
        this.pageInfo = {
            offset: 0,
            count: 0,
            limit: 5,
            pageSize: 0,
            lastPage: 0,
            prevResponse: 0
        };
        this.page = 0;
        this.currentPageCount = {
            start: 0,
            end: 0
        };
        // config properties
        this.deviceStatus = configMessage.deviceStatus;
        this.deviceStatusClass = configMessage.deviceStatusClass;
        this.dateAttributes = configMessage.dateAttributes;
        this.dateFormat = environment.isoDateTimeFormat;
        // table headers
        this.allHeaders = [];
        this.displayHeaders = [];
        this.defaultHeaders = ['serialNumber', 'imei', 'simCcid', 'firmwareVersion', 'configVersion', 'simCarrier', 'model', 'deviceStatus'];
        this.deviceHeaderPreferences = [];
        this.querySelectVehicle = null;
        this.deviceList = [];
        this.selectedDate = "";
        this.month = [{ "id": 1, "name": "January" }, { "id": 2, "name": "February" }, { "id": 3, "name": "March" }, { "id": 4, "name": "April" }, { "id": 5, "name": "May" }, { "id": 6, "name": "June" }, { "id": 7, "name": "July" }, { "id": 8, "name": "August" }, { "id": 9, "name": "September" }, { "id": 10, "name": "October" }, { "id": 11, "name": "November" }, { "id": 12, "name": "December" }];
        this.years = [];
        this.selectedMonth = 0;
        this.selectedYear = 0;
        this.loadingIndicator = false;
        this.prevResponse = 0;
        this.currentCustomer = {};
        this.messageTypeColor = configMessage.billingMessageTypeColor;
        this.totalsDataset = [];
        this.chartLabels = [];
        this.stackedDataset = [];
        this.permissionModules = [];
        this.inActiveColor = "rgb(211, 37, 53)";
        this.activeColor = "rgb(211, 37, 53)";
        this.enrolledColor = "rgb(211, 37, 53)";
        this.assetsList = ['Device', 'Vehicle'];
        this.customersList = [];
        //@Input() billingReportCSR: any;
        this.chart = null;
        this.billingReportCSR = localStorage.getItem("billingReportCSR");
        console.log("billingReportCSR", this.billingReportCSR);
        this.localImpersonate = localStorage.getItem('impersonate');
        if (this.localImpersonate === 'true') {
            this.billingReportCSR = 'false';
        }
        this.getCustomers();
    }
    BillingReportComponent.prototype.ngOnInit = function () {
        //this.billingReportCSR = false
        this.currentCustomer = JSON.parse(localStorage.getItem("currentUser"));
        //await this.getCustomers();
        //this.getTotalCountOfAsset("device");
        //this.getAccountStatus();
        //this.getTotalMessagesCount();
        this.title.setTitle("Billing Report" + environment.title_text);
        this.permissionModules = JSON.parse(localStorage.getItem("permissionModules"));
        var currentYear = new Date().getFullYear();
        for (var i = 0; i < 10; i++) {
            this.years.push(currentYear - i);
            this.years.push(currentYear + i);
        }
        this.years = tslib_1.__spread(new Set(this.years.sort()));
        this.selectedYear = currentYear;
        this.selectedMonth = new Date().getMonth() + 1;
        // this.selectedDate = this.datePipe.transform(this.selectedDate, 'yyyy-MM-dd');
        this.getDeviceUsageByMonthYear(this.selectedYear, this.selectedMonth);
    };
    BillingReportComponent.prototype.getDeviceUsageByMonthYear = function (yr, mnth) {
        var _this = this;
        // this.reportsService.getUsageByMonthYear(2022, 3).subscribe(data => {
        // this.deviceList = [];
        this.loadingIndicator = true;
        this.reportsService.getUsageByMonthYear(yr, mnth, this.page).subscribe(function (data) {
            console.log("data", data);
            _this.prevResponse = data.length;
            if (_this.page === 0) {
                _this.deviceList = data;
            }
            else {
                _this.deviceList = tslib_1.__spread(_this.deviceList, data);
            }
            _this.loadingIndicator = false;
            if (_this.deviceList.length) {
                _this.pageInfo.count = _this.deviceList.length;
                var pagecount = _this.pageInfo.count / _this.pageInfo.limit;
                _this.pageInfo.lastPage = Math.floor(pagecount);
                var decimal = pagecount - _this.pageInfo.lastPage;
                if (!Number.isInteger(decimal)) {
                    _this.pageInfo.lastPage = _this.pageInfo.lastPage + 1;
                }
                _this.currentPageCount.start = (_this.pageInfo.offset * _this.pageInfo.pageSize) + 1;
                if (_this.deviceList.length <= 10) {
                    _this.currentPageCount.end = _this.pageInfo.count;
                }
                else {
                    _this.currentPageCount.end = (_this.pageInfo.offset * _this.pageInfo.pageSize) + _this.pageInfo.limit;
                }
            }
        }, function (error) {
            _this.loadingIndicator = false;
        });
    };
    BillingReportComponent.prototype.getTotalCount = function ($event) {
        this.getTotalCountOfAsset($event.target.value);
    };
    BillingReportComponent.prototype.getTotalCountOfAsset = function (assetType, customerId) {
        var _this = this;
        if (assetType === undefined) {
            this.assetType = "device";
        }
        else {
            this.assetType = assetType;
        }
        this.reportsService.getTotalCountOfAsset(customerId).subscribe(function (data) {
            data.forEach(function (element) {
                if (_this.assetType === "vehicle" && element.assetType === "vehicle") {
                    _this.totalAssetCount = element.count;
                }
                else if (_this.assetType === "device" && element.assetType === "device") {
                    _this.totalAssetCount = element.count;
                }
            });
        }, function (error) {
            console.log("total vehicle/device count API", error);
        });
    };
    BillingReportComponent.prototype.getAccountStatus = function (customerID) {
        var _this = this;
        var assetType;
        var customerId;
        if (customerID != undefined && customerID != null) {
            customerId = customerID;
        }
        else {
            customerId = this.currentCustomer.customerId;
        }
        if (customerId != undefined || customerId != null) {
            this.reportsService.getAccountStatus(customerId).subscribe(function (data) {
                console.log("total vehicle/device count API", data);
                if (data.customerId && data.customerId != null) {
                    if (data.toDate && data.toDate != null) {
                        _this.convertLongToDate(data);
                    }
                    else {
                        _this.toDate = null;
                        _this.accountStatus = "Active";
                        _this.expiredDate = true;
                    }
                }
                else if (assetType === "device" && data[0].assetType === "others") {
                    _this.totalAssetCount = data[1].count;
                }
            }, function (error) {
                console.log("total vehicle/device count API", error);
            });
        }
    };
    BillingReportComponent.prototype.getTotalMessagesCount = function (customerId) {
        var _this = this;
        this.reportsService.getTotalMessagesCount(customerId).subscribe(function (data) {
            _this.totalMessagesCount = data.totalMessagesCount;
        }, function (error) {
            console.log("error", error);
        });
    };
    BillingReportComponent.prototype.getUsageStats = function (customerId) {
        var _this = this;
        this.reportsService.getUsageStats(customerId).subscribe(function (data) {
            _this.sortData(data);
        }, function (error) {
            console.log("error", error);
        });
    };
    BillingReportComponent.prototype.sortData = function (res) {
        var _this = this;
        var labels = [];
        var keys = [];
        var backgroundColors = [];
        var dataset = [];
        var totalCounts = [];
        // find all the distinct messages
        res.forEach(function (item) {
            for (var k in item) {
                if (!keys.includes(k) && k !== "date" && k !== "NO_DATA") {
                    if (_this.messageTypeColor[_this.alterLabel(k)]) {
                        backgroundColors.push(_this.messageTypeColor[_this.alterLabel(k)].colorOpaque);
                    }
                    else {
                        backgroundColors.push(_this.messageTypeColor["UNKNOWN"].colorOpaque);
                    }
                    keys.push(k);
                }
            }
        });
        // Obtain date labels and total counts for each day
        res.map(function (item) {
            var datePipe = new DatePipe("en-US");
            // let eventDate = datePipe.transform(item.date, "EE, dd MMM");
            var eventDate = datePipe.transform(item.date, "MMM yyyy");
            labels.push(eventDate);
            var total = 0;
            for (var k in item) {
                if (k !== "date" && item[k]) {
                    total += item[k];
                }
            }
            totalCounts.push(total);
        });
        // get message count and configure dataset
        var highestCountsArray = [];
        var _loop_1 = function (i) {
            var obj = {};
            var counts = [];
            res.map(function (item) {
                if (item[keys[i]]) {
                    counts.push(item[keys[i]]);
                }
                else {
                    counts.push(0);
                }
            });
            obj.label = this_1.alterLabel(keys[i]);
            // obj.label = configMessage.messageType[keys[i]];
            obj.backgroundColor = backgroundColors[i];
            obj.data = counts;
            dataset.push(obj);
            highestCountsArray.push(Math.max.apply(Math, tslib_1.__spread(counts)));
        };
        var this_1 = this;
        for (var i = 0; i < keys.length; i++) {
            _loop_1(i);
        }
        this.chartLabels = labels;
        this.stackedDataset = dataset;
        this.totalsDataset = [
            {
                label: "Total Messages",
                backgroundColor: "rgb(54, 162, 235, 1)",
                data: totalCounts,
            },
        ];
        // this.drawStackedBarGraph(this.chartLabels, this.totalsDataset);
        this.drawStackedBarGraph(this.chartLabels, this.stackedDataset);
    };
    BillingReportComponent.prototype.alterLabel = function (label) {
        if (label === "inactive") {
            return "In-Active";
        }
        else if (label === "active") {
            return "Active";
        }
        else {
            return "Enrolled";
        }
    };
    BillingReportComponent.prototype.drawStackedBarGraph = function (labels, dataset) {
        if (this.stackedChart) {
            this.stackedChart.destroy();
        }
        var ctx = document.getElementById("stackedBarGraph");
        this.stackedChart = new Chart(ctx, {
            type: "bar",
            responsive: true,
            data: {
                labels: labels,
                datasets: dataset,
            },
            options: {
                legend: {
                    position: "bottom",
                    display: true,
                    onHover: function (event, legendItem) {
                        document.getElementById("canvas").style.cursor = "pointer";
                    },
                    onClick: function (e, legendItem) {
                        var index = legendItem.datasetIndex;
                        var ci = this.chart;
                        var alreadyHidden = ci.getDatasetMeta(index).hidden === null
                            ? false
                            : ci.getDatasetMeta(index).hidden;
                        var anyOthersAlreadyHidden = false;
                        var allOthersHidden = true;
                        // figure out the current state of the labels
                        ci.data.datasets.forEach(function (e, i) {
                            var meta = ci.getDatasetMeta(i);
                            if (i !== index) {
                                if (meta.hidden) {
                                    anyOthersAlreadyHidden = true;
                                }
                                else {
                                    allOthersHidden = false;
                                }
                            }
                        });
                        // if the label we clicked is already hidden
                        // then we now want to unhide (with any others already unhidden)
                        if (alreadyHidden) {
                            ci.getDatasetMeta(index).hidden = null;
                        }
                        else {
                            // otherwise, lets figure out how to toggle visibility based upon the current state
                            ci.data.datasets.forEach(function (e, i) {
                                var meta = ci.getDatasetMeta(i);
                                if (i !== index) {
                                    // handles logic when we click on visible hidden label and there is currently at least
                                    // one other label that is visible and at least one other label already hidden
                                    // (we want to keep those already hidden still hidden)
                                    if (anyOthersAlreadyHidden && !allOthersHidden) {
                                        meta.hidden = true;
                                    }
                                    else {
                                        // toggle visibility
                                        meta.hidden = meta.hidden === null ? !meta.hidden : null;
                                    }
                                }
                                else {
                                    meta.hidden = null;
                                }
                            });
                        }
                        ci.update();
                    },
                },
                scales: {
                    xAxes: [
                        {
                            display: true,
                            stacked: true,
                        },
                    ],
                    yAxes: [
                        {
                            display: true,
                            stacked: true,
                            ticks: {
                                suggestedMin: 0,
                                suggestedMax: 10,
                            },
                        },
                    ],
                },
            },
        });
    };
    BillingReportComponent.prototype.convertLongToDate = function (row) {
        if (row.toDate && row.toDate != null) {
            var tempDate = row.toDate;
            var todate = new Date(row.toDate);
            var todayDate = new Date();
            if (!(todate < todayDate)) {
                this.accountStatus = "Active";
                this.expiredDate = false;
            }
            else if (todate.getDate() === todayDate.getDate()) {
                this.accountStatus = "Active";
                this.expiredDate = false;
            }
            else {
                this.accountStatus = "Inactive";
                this.expiredDate = true;
            }
            this.toDate = tempDate;
        }
    };
    BillingReportComponent.prototype.getCustomers = function () {
        var _this = this;
        setTimeout(function () {
            _this.customerService.getCustomers().subscribe(function (response) {
                _this.customersList = response;
                if (_this.customersList) {
                    //let customerId = this.customersList[0].customerId
                    if (_this.billingReportCSR == 'true') {
                        _this.getData(_this.customersList[0].customerId);
                    }
                }
            }, function (error) {
                console.log("error", error);
                _this.getData();
            });
        }, 500);
    };
    BillingReportComponent.prototype.getCustomerData = function (event) {
        this.getData(event.target.value);
    };
    BillingReportComponent.prototype.getData = function (customerId) {
        this.getTotalCountOfAsset("device", customerId);
        this.getAccountStatus(customerId);
        this.getTotalMessagesCount(customerId);
        this.getUsageStats(customerId);
    };
    return BillingReportComponent;
}());
export { BillingReportComponent };
