import * as tslib_1 from "tslib";
import { OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { LiveService } from "@components/live/live.service";
import { environment } from '@myenv/environment';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from "@shared/shared.service";
var LiveTrackingComponent = /** @class */ (function () {
    function LiveTrackingComponent(liveService, route, sharedService) {
        this.liveService = liveService;
        this.route = route;
        this.sharedService = sharedService;
        this.lat = environment.lat;
        this.lng = environment.lng;
        this.zoom = 4;
        this.isSnazzyInfoWindowOpened = false;
        this.page = 0;
        this.isLoading = false;
        this.loadingIndicator = true;
        this.date = new Date();
        this.timeout = null;
        // NGX Datatable Variables
        this.toggleEffect = false;
        this.play = false;
        this.timer = false;
        this.markers = [];
        this.tracking = false;
        this.trackedVehicle = {};
        this.subtractTime = 15;
        this.vehicleLocations = [];
        this.trackingTime = 10000;
        this.latestCoOrds = [];
        this.event = {};
        this.flightPlan = [];
        this.places = [];
        this.place = false;
        this.sessionExpiredPopup = false;
        // Groups Filter Variables
        this.groups = [];
        this.selectedVehicle = {};
        this.cmImageUrl = environment.cmImageUrl;
        this.accountKey = "";
        this.randomNumber = Math.random();
    }
    LiveTrackingComponent.prototype.ngOnInit = function () {
        this.trackingKey = this.route.snapshot.queryParams.key;
        this.getTrackingToken();
    };
    LiveTrackingComponent.prototype.ngAfterViewInit = function () {
        this.mapInit();
    };
    LiveTrackingComponent.prototype.getTrackingToken = function () {
        var _this = this;
        this.liveService.getTrackingToken(this.trackingKey).subscribe(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var global;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.deviceDetail = res;
                        this.accountKey = res.accountKey;
                        return [4 /*yield*/, this.keepTracking()];
                    case 1:
                        _a.sent();
                        global = this;
                        if (!this.sessionExpiredPopup) {
                            this.trackingId = setInterval(function () {
                                global.keepTracking();
                            }, global.trackingTime);
                        }
                        return [2 /*return*/];
                }
            });
        }); }, function (error) {
            _this.sharedService.getErrorMsg(error);
        });
    };
    LiveTrackingComponent.prototype.clearMarkers = function () {
        this.setMapOnAll(null);
    };
    LiveTrackingComponent.prototype.setMapOnAll = function (map) {
        for (var i = 0; i < this.markers.length; i++) {
            this.markers[i].setMap(map);
        }
    };
    LiveTrackingComponent.prototype.keepTracking = function () {
        var _this = this;
        var global = this;
        global.liveService.keepTrackingVehicle(this.deviceDetail).subscribe(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(res.deviceId && res.length != 0)) return [3 /*break*/, 5];
                        global.vehicleLocations = tslib_1.__spread(global.vehicleLocations, res);
                        global.latestCoOrds[0] = global.vehicleLocations[global.vehicleLocations.length - 2];
                        global.latestCoOrds[1] = global.vehicleLocations[global.vehicleLocations.length - 1];
                        global.trackedVehicle.latitude = res.latitude;
                        global.trackedVehicle.longitude = res.longitude;
                        return [4 /*yield*/, global.plotVehicle(global.latestCoOrds)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, global.clearMarkers()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, global.addMarkerIcon(res)];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, global.addMarker(res)];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        }); }, function (error) {
            global.sessionExpiredPopup = true;
            global.sessionExpired.nativeElement.click();
            _this.sharedService.getErrorMsg(error);
            if (_this.sessionExpiredPopup) {
                clearInterval(_this.trackingId);
            }
        });
    };
    LiveTrackingComponent.prototype.addMarkerIcon = function (data) {
        var iconName = '';
        if (data.messageType) {
            if (data.messageType === 'COMMUNICATION_LOST') {
                iconName = '../../../assets/img/direction/warning/';
            }
            else if (data.messageType === 'TRIP_END' || data.messageType === 'IGNITION_OFF') {
                iconName = '../../../assets/img/direction/red/';
            }
            else if (!data.endPacket && data.messageType !== 'TRIP_END' && data.messageType !== 'IGNITION_OFF') {
                iconName = '../../../assets/img/direction/green/';
            }
            else {
                iconName = '../../../assets/img/direction/gray/';
            }
        }
        else {
            iconName = '../../../../assets/img/direction/default/';
        }
        this.setIcon(data, iconName);
    };
    LiveTrackingComponent.prototype.setIcon = function (data, icon) {
        var heading = data.heading;
        var publishedBy = data.publishedBy;
        var generation = data.generation;
        var iconName = icon + "stop.png";
        if (heading || heading == '0') {
            if (heading > 0 && heading <= 30) {
                iconName = icon + "30.png";
            }
            else if (heading > 30 && heading <= 60) {
                iconName = icon + "60.png";
            }
            else if (heading > 60 && heading <= 90) {
                iconName = icon + "90.png";
            }
            else if (heading > 90 && heading <= 120) {
                iconName = icon + "120.png";
            }
            else if (heading > 120 && heading <= 150) {
                iconName = icon + "150.png";
            }
            else if (heading > 150 && heading <= 180) {
                iconName = icon + "180.png";
            }
            else if (heading > 180 && heading <= 210) {
                iconName = icon + "210.png";
            }
            else if (heading > 210 && heading <= 240) {
                iconName = icon + "240.png";
            }
            else if (heading > 240 && heading <= 270) {
                iconName = icon + "270.png";
            }
            else if (heading > 270 && heading <= 300) {
                iconName = icon + "300.png";
            }
            else if (heading > 300 && heading <= 330) {
                iconName = icon + "330.png";
            }
            else if (heading > 330 && heading <= 360 || heading == '0') {
                iconName = icon + "0.png";
            }
        }
        if (publishedBy && publishedBy === 'RSU Simulation') {
            iconName = '../../assets/img/direction/v2x.png';
        }
        else if (publishedBy && publishedBy === 'OBU Simulation') {
            iconName = '../../assets/img/marker_red.png';
        }
        else if (publishedBy && publishedBy === 'EBIKE Simulation') {
            iconName = '../../assets/img/direction/ebike.png';
        }
        else if (generation === 'LORAWAN') {
            iconName = '../../assets/img/direction/lorawan.png';
        }
        var iconUrl = {
            url: iconName,
            labelOrigin: { x: 50, y: -15 }
        };
        data.iconUrl = iconUrl;
        return data;
    };
    LiveTrackingComponent.prototype.addMarker = function (data) {
        if (this.tracking) {
            data.assetName = '';
            var image = {
                url: data.iconUrl.url,
                size: new google.maps.Size(71, 71),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(14, 13),
            };
        }
        var latlng = new google.maps.LatLng(data.latitude, data.longitude);
        var marker = new google.maps.Marker({
            position: latlng,
            map: this.map,
            label: data.assetName,
            icon: image
        });
        marker['eventTime'] = data.eventTime;
        marker['deviceId'] = data.deviceId;
        var bounds = new google.maps.LatLngBounds();
        bounds.extend(latlng);
        this.map.fitBounds(bounds);
        this.markers.push(marker);
    };
    LiveTrackingComponent.prototype.resetTracking = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, clearInterval(this.trackingId)];
                    case 1:
                        _a.sent();
                        this.tracking = false;
                        // this.getVehicleInfoWindowView(this.trackedVehicle);
                        return [4 /*yield*/, this.removePolyline()];
                    case 2:
                        // this.getVehicleInfoWindowView(this.trackedVehicle);
                        _a.sent();
                        return [4 /*yield*/, this.clearMarkers()];
                    case 3:
                        _a.sent();
                        this.trackedVehicle = {};
                        this.vehicleLocations = [];
                        this.latestCoOrds = [];
                        return [2 /*return*/];
                }
            });
        });
    };
    LiveTrackingComponent.prototype.plotVehicle = function (data) {
        var flightPlanCoordinates = [];
        var bounds = new google.maps.LatLngBounds();
        data.forEach(function (item) {
            var latLngObj = {};
            if (item !== undefined && item.latitude !== undefined && item.longitude !== undefined) {
                latLngObj['lat'] = item.latitude;
                latLngObj['lng'] = item.longitude;
                flightPlanCoordinates.push(latLngObj);
                // to extends the bounds
                bounds.extend(new google.maps.LatLng(item.latitude, item.longitude));
            }
        });
        this.flightPath = new google.maps.Polyline({
            path: flightPlanCoordinates,
            geodesic: true,
            strokeColor: '#891d56',
            strokeOpacity: 0.8,
            strokeWeight: 3
        });
        this.flightPath.setMap(this.map);
        this.flightPlan.push(this.flightPath);
    };
    LiveTrackingComponent.prototype.removePolyline = function () {
        this.flightPlan.map(function (line) {
            line.setMap(null);
        });
    };
    // END OF VEHICLE TRACKING
    // GOOGLE MAPS START
    LiveTrackingComponent.prototype.mapInit = function () {
        var mapOptionObject = {
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            zoom: 4,
            minZoom: 4,
            maxZoom: 17,
            mapTypeControl: true,
            mapTypeControlOptions: {
                position: google.maps.ControlPosition.TOP_RIGHT
            },
            zoomControl: true,
            zoomControlOptions: {
                position: google.maps.ControlPosition.RIGHT_CENTER
            },
            scaleControl: true,
            streetViewControl: true,
            streetViewControlOptions: {
                position: google.maps.ControlPosition.RIGHT_CENTER
            },
            fullscreenControl: false,
            center: {
                lat: environment.lat,
                lng: environment.lng
            },
            clickableIcons: false
        };
        this.map = new google.maps.Map(this.gmapElement.nativeElement, mapOptionObject);
    };
    return LiveTrackingComponent;
}());
export { LiveTrackingComponent };
