import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { environment } from '@myenv/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var FaqService = /** @class */ (function () {
    // private httpClient: HttpClient;
    function FaqService(http, handler) {
        this.http = http;
        this.handler = handler;
        this.apiUrl = environment.apiUrl;
        // this.httpClient = new HttpClient(handler);
    }
    FaqService.prototype.getFaq = function () {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this.http.get(this.apiUrl + "faq", { headers: headers }).pipe(catchError(this.handleError));
    };
    FaqService.prototype.handleError = function (error) {
        console.log(error.error || error.json() || error);
        return observableThrowError(error.error || error.json() || error || 'Server error');
    };
    FaqService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FaqService_Factory() { return new FaqService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i1.HttpBackend)); }, token: FaqService, providedIn: "root" });
    return FaqService;
}());
export { FaqService };
