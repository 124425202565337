import { Component, OnInit } from '@angular/core';
import { environment } from "@myenv/environment";

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  public contactEmail = environment.contactEmail;

  constructor() { }

  ngOnInit() {
  }

}
