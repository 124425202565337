import * as tslib_1 from "tslib";
import { OnInit, ElementRef, AfterViewInit } from '@angular/core';
import { SharedService } from '@shared/shared.service';
import { Title } from '@angular/platform-browser';
import * as FileSaver from 'file-saver';
import { DeviceService } from '@components/device/device.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '@myenv/environment';
import { configMessage } from '@shared/config-message';
import { AdminService } from '@components/admin/admin.service';
var DeviceViewReportComponent = /** @class */ (function () {
    function DeviceViewReportComponent(deviceService, router, sharedService, title, adminService, route) {
        this.deviceService = deviceService;
        this.router = router;
        this.sharedService = sharedService;
        this.title = title;
        this.adminService = adminService;
        this.route = route;
        this.selectedHeaders = [];
        this.headerName = [];
        this.deviceStatus = configMessage.deviceStatus;
        this.deviceStatusClass = configMessage.deviceStatusClass;
        this.markers = [];
        this.dateAttributes = configMessage.dateAttributes;
        // deviceStatus = configMessage.deviceStatus;
        this.dateFormat = environment.isoDateTimeFormat;
        this.noSecondsTimeFormat = environment.noSecondsTimeFormat;
        // This to display activity property from config
        this.activityAttribute = configMessage.activityConfig;
        this.pageLoading = true;
        this.listPage = true;
        this.disable = false;
        this.responseMessage = {};
        this.devices = [];
        this.device = {};
        this.deviceId = '';
        this.serialNumber = '';
        this.deviceDetail = {};
        this.deviceHeader = [];
        this.deviceSearchheader = [];
        this.searchDevice = [];
        this.deviceFullHeader = [];
        this.deviceAllHeader = [];
        this.deviceAllString = [];
        // To display device detail in tab
        this.singleDeviceDetail = [];
        this.deviceStaticHeader = [
            { name: 'serialNumber', displayName: 'Serial Number', type: 'STRING', visible: true },
            { name: 'assetName', displayName: 'Name', type: 'STRING', visible: true },
            { name: 'imei', displayName: 'IMEI', type: 'STRING', visible: true },
            { name: 'simCcid', displayName: 'SIM ICCID', type: 'STRING', visible: true },
            { name: '', displayName: '', type: '', visible: true },
            { name: 'productSerialNumber', displayName: 'Product Serial Number', type: 'STRING', visible: true },
            // {name: 'deviceStatus', displayName: 'Device Status', type: 'STRING', visible: true},
            { name: 'firmwareVersion', displayName: 'Firmware Version', type: 'STRING', visible: true },
            { name: 'configVersion', displayName: 'Config Version', type: 'STRING', visible: true },
        ];
        this.noPrefColumns = ['assetName', 'serialNumber', 'imei', 'deviceStatus', 'firmwareVersion', 'configVersion'];
        this.finalHead = [];
        this.allColumns = [];
        this.columns = [];
        this.visibleKeys = [];
        this.slectAllOption = true;
        this.delBtn = false;
        this.moduleName = 'DEVICE';
        this.permissionAuthorities = {};
        this.modules = [];
        this.deviceActivity = [];
        this.loadmore = false;
        this.loadingIndicator = true;
        // loadAPI: Promise<any>;
        this.searchModel = {};
        this.itemsPerPage = 10; // we are showing 10 items per page
        this.lastPage = 0;
        this.page = 0;
        this.isLoading = false;
        this.currentPageCount = {
            start: 0,
            end: 0
        };
        this.confirmSerialNumber = '';
        this.pageInfo = {
            offset: 0,
            count: 0,
            limit: 10,
            pageSize: 0,
            lastPage: 0,
        };
        this.prevResponse = 0;
        this.pageLimit = environment.pageLimit;
        this.sortParams = {};
        this.headerPref = [];
        this.checkedItems = [];
        // Multi Select
        this.headSelSettings = {};
        this.finalSelected = [];
        this.allSelected = false;
        this.columnPopup = false;
        this.updatingPref = false;
        this.selectCount = 0;
        this.timeout = null;
        this.countTimeOut = null;
    }
    DeviceViewReportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.headerSettings = {
            singleSelection: false,
            text: 'Select Key',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            enableSearchFilter: true,
            badgeShowLimit: 1,
            primaryKey: 'id',
            labelKey: 'displayName',
            classes: 'myclass custom-class-example width100',
            searchBy: ['displayName']
        };
        this.modules = JSON.parse(localStorage.getItem('accessRoleModules'));
        this.headerPref = JSON.parse(localStorage.getItem('preferences')).device.selectedFields;
        this.permissionAuthorities = this.modules.find(function (module) { return module.name === _this.moduleName; });
        this.message = this.adminService.getSideMenu();
        this.title.setTitle('Devices' + environment.title_text);
        this.getAllDevices(this.page);
        this.getDeviceCount();
        this.getDeviceHeader();
    };
    DeviceViewReportComponent.prototype.ngAfterViewInit = function () {
        this.initAutocomplete();
    };
    DeviceViewReportComponent.prototype.initAutocomplete = function () {
        var mapOptionObject = {
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            zoom: 4,
            minZoom: 4,
            maxZoom: 18,
            mapTypeControl: true,
            mapTypeControlOptions: {
                position: google.maps.ControlPosition.TOP_RIGHT
            },
            zoomControl: true,
            zoomControlOptions: {
                position: google.maps.ControlPosition.RIGHT_CENTER
            },
            scaleControl: true,
            streetViewControl: false,
            fullscreenControl: false,
            clickableIcons: false,
            center: {
                lat: environment.lat,
                lng: environment.lng
            }
        };
        this.map = new google.maps.Map(this.gmapElement.nativeElement, mapOptionObject);
    };
    DeviceViewReportComponent.prototype.refreshPage = function () {
        this.page = 0;
        this.pageInfo.offset = 0;
        if (this.searchModel.deviceTypeVal && this.searchModel.deviceId) {
            this.searchResults();
        }
        else {
            this.getAllDevices(this.page);
            this.getDeviceCount();
        }
    };
    DeviceViewReportComponent.prototype.downloadSelectedDeviceCSV = function () {
        var _this = this;
        var finalAttr = [];
        this.finalHead.filter(function (item) {
            finalAttr.push(item.name);
        });
        this.deviceService.downloadDeviceCSV(this.searchModel, undefined, undefined, finalAttr.toString()).subscribe(function (res) {
            var contentDisposition = res.headers.get('Content-Disposition');
            var filename;
            if (contentDisposition == null) {
                var date = new Date();
                filename = 'Device_' + date + '.csv';
            }
            else {
                filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
            }
            var blob = new Blob([res.body], {
                type: 'application/zip'
            });
            FileSaver.saveAs(blob, filename);
        }, function (error) {
            _this.sharedService.getErrorMsg(error);
        });
    };
    // To get all devices
    DeviceViewReportComponent.prototype.getAllDevices = function (page) {
        var _this = this;
        //
        this.deviceService.getAllDevices(page, this.sortParams).subscribe(function (res) {
            // this.searchModel.deviceId = this.tempSelection;
            //  this.isLoading = false;
            if (page == 0) {
                _this.devices = res;
            }
            else {
                _this.devices = tslib_1.__spread(_this.devices, res);
            }
            _this.loadingIndicator = false;
            _this.isLoading = true;
            _this.pageInfo.count = _this.devices.length;
            _this.prevResponse = res.length;
            var pagecount = _this.pageInfo.count / _this.pageInfo.limit;
            _this.pageInfo.lastPage = Math.floor(pagecount);
            var decimal = pagecount - _this.pageInfo.lastPage;
            if (!Number.isInteger(decimal)) {
                _this.pageInfo.lastPage = _this.pageInfo.lastPage + 1;
            }
            if (page == 0) {
                if (res.length) {
                    _this.currentPageCount.start = (_this.pageInfo.offset * _this.pageInfo.pageSize) + 1;
                    if (_this.devices.length <= 10) {
                        _this.currentPageCount.end = _this.pageInfo.count;
                    }
                    else {
                        _this.currentPageCount.end = (_this.pageInfo.offset * _this.pageInfo.pageSize) + _this.pageInfo.limit;
                    }
                }
            }
            _this.pageLoading = false;
        }, function (error) {
            _this.pageLoading = false;
            _this.sharedService.getErrorMsg(error);
        });
    };
    // To get the devices for each page in the table
    // pageCallback(value) {
    //   this.pageInfo.offset = value.offset;
    //   this.currentPageCount.start = (value.offset * value.pageSize) + 1;
    //   const lastPage = Math.floor(this.devices.length / 10);
    //   if (lastPage == value.offset) {
    //     this.currentPageCount.end = value.count;
    //   } else {
    //     this.currentPageCount.end = (value.offset * value.pageSize) + value.limit;
    //   }
    //   if (this.pageInfo.lastPage === (this.pageInfo.offset + 1)) {
    //     // tslint:disable-next-line:no-unused-expression
    //     if (this.prevResponse) {
    //       this.page = ++this.page;
    //       this.getAllDevices(this.page);
    //     }
    //   }
    // }
    // To get total device count
    DeviceViewReportComponent.prototype.getDeviceCount = function (deviceId, deviceTypeValue) {
        // this.isLoading = true;
        clearTimeout(this.countTimeOut);
        var global = this;
        this.countTimeOut = setTimeout(function () {
            global.deviceService.getVehicleCount(deviceId, deviceTypeValue).subscribe(function (res) {
                global.deviceTotalCount = res;
            }, function (error) {
                global.sharedService.getErrorMsg(error);
            });
        }, 1500);
    };
    // To get the header of Devices table
    DeviceViewReportComponent.prototype.getDeviceHeader = function () {
        var _this = this;
        // this.isLoading = true;
        // const headers = JSON.parse(localStorage.getItem('deviceHeaders'));
        // if (headers) {
        //   this.deviceAllHeader = headers;
        //   this.performHeaderOperations(headers);
        // } else {
        this.sharedService.getDeviceHeader().subscribe(function (res) {
            localStorage.setItem('deviceHeaders', JSON.stringify(res));
            _this.deviceAllHeader = res;
            _this.performHeaderOperations(res);
        }, function (error) {
            _this.sharedService.getErrorMsg(error);
        });
        // }
    };
    DeviceViewReportComponent.prototype.performHeaderOperations = function (res) {
        var _this = this;
        this.setTableHeader(res);
        this.deviceAllString = res.map(function (item) {
            return item.name;
        });
        // tslint:disable-next-line:max-line-length
        var server = res.filter(function (x) {
            if (!(x.name === 'serialNumber' || x.name === 'assetName' || x.name.slice(-2) === 'Id' || x.name === 'imei' || x.name === 'deviceType' || x.name === 'type' || x.name === 'tested' || x.name === 'createdAt' || x.name === 'activatedOn' || x.name === 'lastCommunication' || x.name === 'firmwareVersion' || x.name === 'configVersion')) {
                return x;
            }
        });
        // tslint:disable-next-line:max-line-length
        this.searchDevice = res.filter(function (x) {
            if (!(_this.dateAttributes.includes(x.name) || x.name === 'systemTag' || x.name === 'assetName' || x.name === 'tags' || x.name === 'productSerialNumber' || x.name === 'simCcid' || x.name === 'deviceStatus' || x.name === 'firmwareVersion' || x.name === 'configVersion' || x.name === 'serialNumber' || x.name === 'imei' || x.name === 'deviceType' || x.name === 'createdAt' || x.name === 'activatedOn' || x.name === 'lastCommunication' || x.name === 'groupId' || x.name === 'companyId' || x.name === 'customerId' || x.name === 'deviceId')) {
                return x;
            }
        });
        this.deviceSearchheader = this.deviceStaticHeader.concat(this.searchDevice);
        var newdeviceSearchheader = this.deviceSearchheader;
        var queryParams = this.route.snapshot.queryParams;
        if (!(queryParams.searchBy && queryParams.value)) {
            this.searchModel['deviceId'] = this.deviceSearchheader[0].name;
        }
        this.tempSelection = this.searchModel['deviceId'];
        this.deviceHeader = server;
        this.deviceHeader = server.filter(function (item) {
            if (item.visible) {
                return item;
            }
        });
        this.deviceSearchheader = newdeviceSearchheader.filter(function (item) {
            if (item.visible) {
                return item;
            }
        });
    };
    DeviceViewReportComponent.prototype.setTableHeader = function (res) {
        var _this = this;
        var pref = [];
        if (this.headerPref && this.headerPref.length) {
            this.noPrefColumns.forEach(function (item) {
                if (!_this.headerPref.includes(item)) {
                    _this.headerPref.push(item);
                }
            });
            pref = this.headerPref;
        }
        else {
            pref = this.noPrefColumns;
        }
        res.map(function (head) {
            if (pref.includes(head.name)) {
                head.checked = true;
                _this.selectCount++;
                if (head.visible == true) {
                    _this.finalHead.push(head);
                }
            }
        });
        if (this.selectCount == res.length) {
            this.allSelected = true;
        }
        this.allColumns = res;
        this.columns = res;
        var keyArray = [];
        var visibleKeys = [];
        this.allColumns.map(function (header) {
            if (header.checked && header.visible == true) {
                keyArray.push(header.name);
            }
            if (header.visible == true) {
                visibleKeys.push(header);
            }
        });
        this.visibleKeys = visibleKeys;
        this.columns = this.allColumns;
        this.checkedItems = keyArray;
    };
    DeviceViewReportComponent.prototype.togglePopup = function () {
        if (this.columnPopup) {
            this.cancelSelection();
        }
        else {
            this.columnPopup = true;
            this.checkCustomSelected();
        }
    };
    DeviceViewReportComponent.prototype.checkCustomSelected = function () {
        var _this = this;
        this.selectCount = 0;
        if (this.searchModel && this.searchModel.columnVal) {
            this.searchModel.columnVal = '';
        }
        // let prefrences = JSON.parse(localStorage.getItem('preferences'));
        // this.allColumns = prefrences.device.selectedFields;
        this.allColumns.map(function (head) {
            if (_this.checkedItems.includes(head.name)) {
                _this.selectCount++;
                head.checked = true;
            }
            else {
                head.checked = false;
            }
            // if (this.noPrefColumns.includes(head.name)) {
            //   head.checked = true;
            //   this.selectCount++;
            // }
        });
        this.columns = this.allColumns;
        if (this.selectCount == this.checkedItems) {
            this.allSelected = true;
        }
    };
    DeviceViewReportComponent.prototype.selectItems = function (event, header, i) {
        if (!this.noPrefColumns.includes(header.name)) {
            if (event.target.checked) {
                this.selectCount++;
            }
            else {
                this.selectCount--;
            }
            this.allColumns.map(function (head) {
                if (head.name == header.name) {
                    if (event.target.checked) {
                        head.checked = true;
                    }
                    else {
                        head.checked = false;
                    }
                }
            });
            // this.allColumns[i].checked = event.target.checked;
        }
    };
    DeviceViewReportComponent.prototype.selectAllItems = function (event) {
        var _this = this;
        this.allSelected = event.target.checked;
        if (event.target.checked) {
            this.selectCount = this.allColumns.length;
        }
        else {
            this.selectCount = 0;
        }
        this.allColumns.map(function (head) {
            if (_this.noPrefColumns.includes(head.name)) {
                head.checked = true;
            }
            else {
                head.checked = event.target.checked;
            }
        });
    };
    DeviceViewReportComponent.prototype.cancelSelection = function () {
        this.selectCount = 0;
        this.columnPopup = false;
    };
    DeviceViewReportComponent.prototype.updatePreference = function () {
        var _this = this;
        var tempArray = [];
        var keyArray = [];
        this.updatingPref = true;
        this.selectCount = 0;
        this.allColumns.map(function (header) {
            if (header.checked) {
                var obj = {
                    name: header.name,
                    displayName: header.displayName,
                    checked: header.checked,
                    type: header.type,
                    visible: header.visible
                };
                tempArray.push(obj);
                keyArray.push(header.name);
            }
        });
        var payload = { device: { selectedFields: keyArray } };
        this.sharedService.updatePreference(payload).subscribe(function (res) {
            _this.finalHead = tempArray;
            _this.headerPref = tempArray;
            _this.checkedItems = keyArray;
            _this.columnPopup = false;
            _this.updatingPref = false;
            _this.columns = _this.allColumns;
            var updateStorage = JSON.parse(localStorage.getItem('preferences'));
            if (updateStorage.device.selectedFields) {
                updateStorage.device.selectedFields = keyArray;
                localStorage.setItem('preferences', JSON.stringify(updateStorage));
            }
        }, function (error) {
            _this.sharedService.getErrorMsg(error);
            _this.updatingPref = false;
        });
    };
    DeviceViewReportComponent.prototype.downloadDeviceCSV = function () {
        this.deviceService.downloadDeviceCSV(this.searchModel).subscribe(function (res) {
            var contentDisposition = res.headers.get('Content-Disposition');
            var filename;
            if (contentDisposition == null) {
                var date = new Date();
                filename = 'Device_' + date + '.csv';
            }
            else {
                filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
            }
            var blob = new Blob([res.body], {
                type: 'application/zip'
            });
            FileSaver.saveAs(blob, filename);
        }, function (error) {
        });
    };
    DeviceViewReportComponent.prototype.clearSearch = function (f) {
        this.tempSelection = this.searchModel.deviceId;
        this.searchModel.deviceTypeVal = '';
        if (f.valid) {
            this.devices = [];
            f.resetForm();
            this.loadingIndicator = true;
            this.page = 0;
            this.pageInfo.offset = 0;
            this.getAllDevices(this.page);
            this.getDeviceCount();
        }
    };
    DeviceViewReportComponent.prototype.onSort = function (event) {
        var _this = this;
        this.page = 0;
        this.loadingIndicator = true;
        this.sortParams = event.sorts[0];
        if (this.searchModel.deviceTypeVal) {
            var deviceTypeValue = this.searchModel.deviceTypeVal.trim();
            this.deviceService.getAllDevices(0, event.sorts[0], this.searchModel.deviceId, deviceTypeValue).subscribe(function (res) {
                _this.devices = res;
                _this.loadingIndicator = false;
                _this.setPageCount(res);
            }, function (error) {
                _this.sharedService.getErrorMsg(error);
            });
        }
        else {
            this.deviceService.getAllDevices(0, event.sorts[0]).subscribe(function (res) {
                _this.devices = res;
                _this.loadingIndicator = false;
                _this.setPageCount(res);
            }, function (error) {
                _this.sharedService.getErrorMsg(error);
            });
        }
    };
    DeviceViewReportComponent.prototype.setPageCount = function (res) {
        this.pageInfo.count = this.devices.length;
        this.prevResponse = res.length;
        var pagecount = this.pageInfo.count / this.pageInfo.limit;
        this.pageInfo.lastPage = Math.floor(pagecount);
        var decimal = pagecount - this.pageInfo.lastPage;
        if (!Number.isInteger(decimal)) {
            this.pageInfo.lastPage = this.pageInfo.lastPage + 1;
        }
        if (this.page == 0) {
            if (res.length) {
                this.currentPageCount.start = (this.pageInfo.offset * this.pageInfo.pageSize) + 1;
                if (this.devices.length <= 10) {
                    this.currentPageCount.end = this.pageInfo.count;
                }
                else {
                    this.currentPageCount.end = (this.pageInfo.offset * this.pageInfo.pageSize) + this.pageInfo.limit;
                }
            }
        }
    };
    DeviceViewReportComponent.prototype.onColumnSearch = function () {
        var _this = this;
        this.columns = this.allColumns;
        var inputVal = this.searchModel.columnVal.trim();
        var val = inputVal.toLowerCase();
        if (val === '') {
            this.slectAllOption = true;
            this.columns = this.allColumns;
            return;
        }
        else {
            if (this.allColumns.length) {
                this.slectAllOption = false;
                var temp = this.allColumns.filter(function (item, index) {
                    if (item.displayName.toLowerCase().includes(val)) {
                        return _this.allColumns[index];
                    }
                });
                this.columns = temp;
            }
        }
    };
    // searchDataOnChange() {
    //   if(this.searchModel.searchValue && this.searchModel.searchId) {
    //     this.onSearch();
    //   }
    // }
    DeviceViewReportComponent.prototype.selectInput = function () {
        var inputElem = this.inputOne.nativeElement;
        inputElem.select();
    };
    DeviceViewReportComponent.prototype.inputValidator = function (event) {
        var term = event.target.value.replace(/[^_\-@.,a-zA-Z0-9\s]/g, '');
        this.searchModel.deviceTypeVal = term.split(/[ ]+/).filter(function (v) { return v !== ''; }).join(',');
        this.searchResults();
    };
    // To get the devices for each page in the table
    DeviceViewReportComponent.prototype.pageCallback = function (value) {
        this.pageInfo.offset = value.offset;
        this.currentPageCount.start = (value.offset * value.pageSize) + 1;
        var lastPage = Math.floor(this.devices.length / 10);
        if (lastPage == value.offset) {
            this.currentPageCount.end = value.count;
        }
        else {
            this.currentPageCount.end = (value.offset * value.pageSize) + value.limit;
        }
        if (this.pageInfo.lastPage === (this.pageInfo.offset + 1)) {
            // tslint:disable-next-line:no-unused-expression
            if (this.prevResponse) {
                this.page = ++this.page;
                if (this.searchModel.deviceTypeVal && this.searchModel.deviceId) {
                    this.onSearch(this.page);
                }
                else {
                    this.getAllDevices(this.page);
                }
            }
        }
    };
    // debounce(func, delay) {
    //   var d;
    //   var context = this;
    //   return function (...args) {
    //     // const args = arguments;
    //     clearTimeout(d);
    //     d = setTimeout(() => {
    //       func.apply(context, args)
    //     }, delay);
    //   }
    // }
    DeviceViewReportComponent.prototype.searchResults = function () {
        // if (this.searchModel.deviceTypeVal && this.searchModel.deviceId) {
        this.pageInfo.offset = 0;
        this.page = 0;
        this.onSearch(this.page);
        var deviceTypeValue = this.searchModel.deviceTypeVal;
        if (deviceTypeValue) {
            deviceTypeValue = deviceTypeValue.trim();
        }
        this.getDeviceCount(this.searchModel.deviceId, deviceTypeValue);
        // let count = this.debounce(this.getDeviceCount, 1000)(this.searchModel.deviceId, deviceTypeValue);
        // }
    };
    // To get a device based on selected filter
    DeviceViewReportComponent.prototype.onSearch = function (page) {
        clearTimeout(this.timeout);
        var global = this;
        this.timeout = setTimeout(function () {
            if (global.searchModel.deviceTypeVal && global.searchModel.deviceId) {
                var deviceTypeValue = global.searchModel.deviceTypeVal;
                if (deviceTypeValue) {
                    deviceTypeValue = deviceTypeValue.trim();
                }
                global.sharedService.onSearchDevice(global.searchModel.deviceId, deviceTypeValue, page).subscribe(function (res) {
                    global.prevResponse = res.length;
                    if (page == 0) {
                        global.devices = res;
                    }
                    else {
                        global.devices = tslib_1.__spread(global.devices, res);
                    }
                    global.loadingIndicator = false;
                    global.isLoading = true;
                    global.pageInfo.count = global.devices.length;
                    var pagecount = global.pageInfo.count / global.pageInfo.limit;
                    global.pageInfo.lastPage = Math.floor(pagecount);
                    var decimal = pagecount - global.pageInfo.lastPage;
                    if (!Number.isInteger(decimal)) {
                        global.pageInfo.lastPage = global.pageInfo.lastPage + 1;
                    }
                    if (page == 0) {
                        if (res.length) {
                            global.currentPageCount.start = (global.pageInfo.offset * global.pageInfo.pageSize) + 1;
                            if (global.devices.length <= 10) {
                                global.currentPageCount.end = global.pageInfo.count;
                            }
                            else {
                                global.currentPageCount.end = (global.pageInfo.offset * global.pageInfo.pageSize) + global.pageInfo.limit;
                            }
                        }
                    }
                }, function (error) {
                    global.sharedService.getErrorMsg(error);
                });
            }
            else {
                global.page = 0;
                global.pageInfo.offset = 0;
                global.getAllDevices(0);
                global.getDeviceCount();
            }
        }, 1500);
    };
    DeviceViewReportComponent.prototype.addMarker = function (lat, long, img) {
        var latlng = new google.maps.LatLng(lat, long);
        var marker = new google.maps.Marker({
            position: latlng,
            map: this.map,
            // label: eventDate,
            icon: img
        });
        this.setAnimation(marker);
        this.markers.push(marker);
        var bounds = new google.maps.LatLngBounds();
        bounds.extend(latlng);
        this.map.fitBounds(bounds);
        this.markers.push(marker);
    };
    // Set Animation
    DeviceViewReportComponent.prototype.setAnimation = function (marker) {
        marker.setAnimation(google.maps.Animation.BOUNCE);
        setTimeout(function () { marker.setAnimation(null); }, 750);
    };
    DeviceViewReportComponent.prototype.clearMarkers = function () {
        for (var i = 0; i < this.markers.length; i++) {
            this.markers[i].setMap(null);
        }
    };
    DeviceViewReportComponent.prototype.loadMap = function (row) {
        this.selectedDevice = row;
        var global = this;
        var imgEnd = '../../assets/img/rect836.png';
        if (this.markers.length) {
            this.clearMarkers();
        }
        google.maps.event.addListenerOnce(this.map, 'tilesloaded', function () {
            google.maps.event.trigger(this.map, 'resize');
        });
        this.addMarker(row.latitude, row.longitude, imgEnd);
        setTimeout(function () {
            google.maps.event.trigger(global.map, 'resize');
        }, 1500);
    };
    DeviceViewReportComponent.prototype.getCustomDownloadKeysPref = function () {
        var pref = JSON.parse(localStorage.getItem('preferences'));
        var keys = pref && pref.deviceDownloadKeys && pref.deviceDownloadKeys.selectedFields ? pref.deviceDownloadKeys.selectedFields : [];
        var selected = [];
        if (keys.length) {
            this.visibleKeys.map(function (item) {
                if (keys.includes(item.name))
                    selected.push(item);
            });
            this.selectedHeaders = selected;
        }
    };
    DeviceViewReportComponent.prototype.onHeaderSelect = function (item) {
        var existing = false;
        this.selectedHeaders.map(function (header) {
            existing = header.name === item.name ? true : false;
        });
        if (!existing)
            this.selectedHeaders.push(item);
    };
    DeviceViewReportComponent.prototype.onHeaderDeSelect = function (item) {
        var filtered = this.selectedHeaders.filter(function (value) {
            return value != item;
        });
        this.selectedHeaders = filtered;
    };
    DeviceViewReportComponent.prototype.onSelectAllHeaders = function (items) {
        var _this = this;
        this.selectedHeaders = [];
        items.forEach(function (data) {
            _this.selectedHeaders.push(data);
        });
    };
    DeviceViewReportComponent.prototype.onDeSelectAllHeaders = function (items) {
        this.selectedHeaders = [];
    };
    // To download table data as a csv file
    DeviceViewReportComponent.prototype.downloadCustomDeviceCSV = function () {
        var _this = this;
        var selectedKeys = [];
        this.selectedHeaders.filter(function (item) {
            selectedKeys.push(item.name);
        });
        // Update Custom Keys Preference
        this.updateCustomDownloadKeysPref(selectedKeys);
        // Download CSV
        this.deviceService.downloadDeviceCSV(this.searchModel, undefined, undefined, selectedKeys.toString()).subscribe(function (res) {
            var contentDisposition = res.headers.get('Content-Disposition');
            var filename;
            if (contentDisposition == null) {
                var date = new Date();
                filename = 'Device_' + date + '.zip';
            }
            else {
                filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
            }
            var blob = new Blob([res.body], {
                type: 'application/zip'
            });
            FileSaver.saveAs(blob, filename);
            _this.closeHeaderPopup.nativeElement.click();
            _this.customHeader.selectedItems = [];
            _this.selectedHeaders = [];
        }, function (error) {
            _this.sharedService.getErrorMsg(error);
        });
    };
    DeviceViewReportComponent.prototype.updateCustomDownloadKeysPref = function (selectedKeys) {
        var _this = this;
        this.sharedService.updatePreference({ deviceDownloadKeys: { selectedFields: selectedKeys } }).subscribe({
            next: function (res) {
                var pref = JSON.parse(localStorage.getItem('preferences'));
                pref.deviceDownloadKeys.selectedFields = selectedKeys;
                localStorage.setItem('preferences', JSON.stringify(pref));
            },
            error: function (error) {
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    DeviceViewReportComponent.prototype.removeHeader = function (data) {
        var filtered = this.selectedHeaders.filter(function (value) {
            return value != data;
        });
        this.customHeader.selectedItems = filtered;
        this.onHeaderDeSelect(data);
    };
    return DeviceViewReportComponent;
}());
export { DeviceViewReportComponent };
