import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { SharedService } from '@shared/shared.service';
import { DataService } from '@components/data/data.service';
import { environment } from '@myenv/environment';
import { configMessage } from '@shared/config-message';
import * as _ from 'lodash';
import * as FileSaver from 'file-saver';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
var DataComponent = /** @class */ (function () {
    function DataComponent(title, sharedService, dataService, router, route) {
        this.title = title;
        this.sharedService = sharedService;
        this.dataService = dataService;
        this.router = router;
        this.route = route;
        this.moduleName = 'DATA';
        this.subTitle = 'Messages';
        this.messageTypeArray = [];
        this.toggleEffect = 'all';
        this.messageSort = 'asc';
        this.tripSort = 'desc';
        this.tripMsgSort = 'asc';
        this.currentSort = 'asc';
        this.deviceAtr = environment.deviceAtr;
        this.platformAtr = environment.platformAtr;
        this.selected = [];
        this.selectedMessage = [];
        this._ = _;
        this.vehicles = [];
        this.trips = [];
        this.selecteddeviceId = null;
        this.selectedVehicle = {};
        this.allMessages = [];
        this.messagesValue = [];
        this.searchModel = {};
        this.isLoading = false;
        this.deviceDetails = [];
        this.vehicleDetails = [];
        this.platformDetails = [];
        this.dateFormat = environment.smallDateFormat;
        this.configMessage = configMessage;
        this.objectKeys = Object.keys;
        this.table = true;
        this.disableNav = true;
        this.tripsLoading = false;
        this.messageTable = false;
        this.hideCalendar = false;
        this.pageInfo = {
            offset: 0,
            count: 0,
            limit: 15,
            pageSize: 0,
            lastPage: 0,
        };
        this.page = 0;
        this.prevResponse = 0;
        this.queryMessage = [];
        this.pageLoading = true;
        this.modules = [];
        this.permissionAuthorities = [];
        this.timeSeeker = {
            selected: false,
            ts: null,
            te: null,
            selectedVal: []
        };
        this.apiMessageData = [];
        this.selectedFlag = false;
        this.currentPageCount = {
            start: 0,
            end: 0,
            totalCount: 0
        };
        // pageVehicleHistory = 0;
        this.resSizeenv = environment.resSize;
        this.resSize = this.resSizeenv;
    }
    DataComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.title.setTitle('Data' + environment.title_text);
        this.modules = JSON.parse(localStorage.getItem('accessRoleModules'));
        this.permissionAuthorities = this.modules.find(function (module) { return module.name === _this.moduleName; });
        var param = this.route.snapshot.paramMap.get('tab');
        if (param === 'messages') {
            $('#messagesTab').click();
        }
        else if (param === 'trips') {
            this.toggleEffect = 'trips';
            this.getTrips('asc');
            $('#tripsTab').click();
        }
        // this.getVehicles();
        // this.anchortag();
        window["data"] = this;
    };
    DataComponent.prototype.onTimeChange = function (e) {
        this.resetTimeSeeker();
        this.startTime = e.startTime;
        this.endTime = e.endTime;
        this.queryMessage = [];
        this.queryFormattedString = {};
        this.searchModel.value = "";
        if (this.trips.length) {
            this.messageTable = true;
        }
        else {
            this.messageTable = false;
        }
        if (this.selecteddeviceId) {
            this.getMessagesTotalCount();
            this.getTripsMessage();
            // this.getTrips('asc');
        }
    };
    DataComponent.prototype.changeVehicle = function (e) {
        var _this = this;
        this.resetTimeSeeker();
        this.queryMessage = [];
        this.queryFormattedString = {};
        if (this.trips.length) {
            this.messageTable = true;
        }
        else {
            this.messageTable = false;
        }
        this.selecteddeviceId = e ? e.deviceId : null;
        this.selectedVehicle = e;
        this.messageTypeArray = [];
        this.searchModel.messageType = null;
        this.timeSeeker.selected = false;
        this.timeSeeker.selectedVal = [];
        this.startTime = null;
        this.endTime = null;
        this.sharedService.getRecentdeviceData(this.selecteddeviceId).subscribe(function (res) {
            if (res.teTime || res.tsTime) {
                if (res.teTime) {
                    _this.devicestartTime = res.teTime - (60 * 24 * 60 * 1000);
                    _this.deviceendTime = res.teTime;
                    _this.startTime = _this.devicestartTime;
                    _this.endTime = _this.deviceendTime;
                }
                else if (!res.teTime && res.tsTime) {
                    _this.devicestartTime = (new Date()).getTime() - (60 * 24 * 60 * 1000);
                    _this.deviceendTime = (new Date()).getTime();
                    _this.startTime = res.tsTime - (60 * 24 * 60 * 1000);
                    _this.endTime = _this.deviceendTime;
                }
                else {
                    var start = null;
                    var end = null;
                    var t = new Date();
                    start = Date.parse(new Date(t.getFullYear(), t.getMonth(), t.getDate(), 0, 0, 0).toUTCString());
                    end = Date.parse(new Date(t.getFullYear(), t.getMonth(), t.getDate(), 23, 59, 59).toUTCString());
                    _this.devicestartTime = start;
                    _this.deviceendTime = end;
                    _this.startTime = _this.devicestartTime;
                    _this.endTime = _this.deviceendTime;
                }
                if (_this.startTime && _this.endTime) {
                    _this.getTripsMessage();
                    // this.getTrips('asc');
                }
            }
            else {
                _this.sharedService.getRecentdeviceDataAlternateAPI(_this.selecteddeviceId).subscribe(function (res) {
                    if (res.eventTimeEpoch) {
                        _this.devicestartTime = res.eventTimeEpoch - (60 * 24 * 60 * 1000);
                        _this.deviceendTime = res.eventTimeEpoch;
                        _this.startTime = _this.devicestartTime;
                        _this.endTime = _this.deviceendTime;
                    }
                    else {
                        var start = null;
                        var end = null;
                        var t = new Date();
                        start = Date.parse(new Date(t.getFullYear(), t.getMonth(), t.getDate(), 0, 0, 0).toUTCString());
                        end = Date.parse(new Date(t.getFullYear(), t.getMonth(), t.getDate(), 23, 59, 59).toUTCString());
                        _this.devicestartTime = start;
                        _this.deviceendTime = end;
                        _this.startTime = _this.devicestartTime;
                        _this.endTime = _this.deviceendTime;
                    }
                    if (_this.startTime && _this.endTime) {
                        _this.getTripsMessage();
                        // this.getTrips('asc');
                    }
                });
            }
        });
        this.searchModel.value = "";
    };
    DataComponent.prototype.onSideNavClick = function (p1) {
        if (p1 === 'trips') {
            $('#tripsTab').click();
            this.toggleEffect = 'trips';
            this.getTrips('asc');
        }
        else if (p1 === 'messages') {
            $('#messagesTab').click();
            this.toggleEffect = 'all';
            this.getallMessages('asc', '0');
        }
    };
    DataComponent.prototype.getTrips = function (sort) {
        var _this = this;
        this.pageLoading = true;
        if (this.selecteddeviceId !== null) {
            // this.checkDateValidity();
            this.dataService.getTrips(this.selecteddeviceId, this.endTime, this.startTime, sort).subscribe(function (res) {
                _this.trips = res;
                _this.trips.map(function (item, index) {
                    item['index'] = index;
                });
                if (_this.trips.length) {
                    var lastActiveTrip = _this.trips[0];
                    _this.tsTime = lastActiveTrip.tsTime;
                    // if (!this.trips[0].teTime) {
                    //   if (sort === 'asc' && this.trips[1]) {
                    //     // this.teTime = this.trips[1].tsTime;
                    //     this.teTime = this.trips[1].tsTime;
                    //   } else {
                    //     // let currentTime = new Date().toUTCString();
                    //     // this.teTime = Date.parse(currentTime);
                    //     this.teTime = this.endTime;
                    //   }
                    // }
                    _this.selected = [lastActiveTrip];
                    _this.selectedTrip = lastActiveTrip;
                    if (!lastActiveTrip.hasOwnProperty('teTime')) {
                        _this.teTime = new Date().getTime();
                    }
                    else {
                        _this.teTime = lastActiveTrip.teTime;
                    }
                    _this.getMessagesTotalCount();
                    _this.messageTable = true;
                }
                else {
                    _this.messageTable = false;
                    _this.allMessages = [];
                    _this.singleMessages = false;
                    _this.deviceDetails = [];
                    _this.platformDetails = [];
                    _this.vehicleDetails = [];
                    _this.messagesValue = [];
                    _this.tripsLoading = false;
                }
                _this.page = 0;
                _this.getallMessages(_this.tripMsgSort, '0');
                _this.pageLoading = false;
            }, function (error) {
                _this.pageLoading = false;
                _this.sharedService.getErrorMsg(error);
                _this.tripsLoading = false;
            });
        }
        else {
            this.pageLoading = false;
        }
    };
    // navigateDates(dir) {
    //   this.tripsLoading = true;
    //   if (dir === 'left') {
    //     this.startDt = new Date(this.startDt.setDate(this.startDt.getDate() - 1));
    //     this.disableNav = false;
    //   } else if (dir === 'right') {
    //     this.startDt = new Date(this.startDt.setDate(this.startDt.getDate() + 1));
    //   }
    //   this.getTrips('asc');
    // }
    // checkDateValidity() {
    //   if (
    //     this.startDt.getDate() === this.maxDate.getDate() &&
    //     this.startDt.getMonth() === this.maxDate.getMonth() &&
    //     this.startDt.getFullYear() === this.maxDate.getFullYear()
    //   ) {
    //     this.disableNav = true;
    //   } else {
    //     this.disableNav = false;
    //   }
    // }
    DataComponent.prototype.getTripsMessage = function () {
        // if(this.trips.length) {
        //   this.messageTable = true;
        // } else {
        //   this.messageTable = false;
        // }
        if (this.toggleEffect === 'all') {
            this.page = 0;
            this.getallMessages(this.messageSort, '0');
            this.getMessagesTotalCount();
        }
        if (this.toggleEffect === 'trips') {
            this.getTrips(this.tripSort);
        }
        if (this.toggleEffect === 'query' && this.selectedVehicle.architectureType) {
            this.getMessageType();
        }
        else {
            // this.pageLoading = false;
            this.trips = [];
            this.allMessages = [];
            this.messagesValue = [];
        }
    };
    DataComponent.prototype.getallMessages = function (sort, page) {
        if (this.searchModel.value != "" && this.selectedFlag) {
            this.apiSearch(true);
        }
        else {
            this.pageLoading = true;
            this.currentSort = sort;
            var startTime = void 0;
            var endTime = void 0;
            if (this.toggleEffect === 'all') {
                startTime = this.startTime;
                endTime = this.endTime;
            }
            if (this.toggleEffect === 'trips') {
                if (this.trips.length) {
                    startTime = this.tsTime;
                    endTime = this.teTime;
                }
            }
            if (startTime && endTime) {
                if (this.timeSeeker.selected) {
                    this.getMessagesData(this.selecteddeviceId, this.timeSeeker.ts, this.timeSeeker.te, this.currentSort, page);
                }
                else {
                    this.getMessagesData(this.selecteddeviceId, startTime, endTime, this.currentSort, page);
                }
            }
            else {
                this.pageLoading = false;
                this.allMessages = [];
                this.singleMessages = false;
                this.tripsLoading = false;
                this.deviceDetails = [];
                this.platformDetails = [];
                this.vehicleDetails = [];
            }
        }
    };
    // pageCallback(value) {
    //   this.pageInfo.offset = value.offset;
    //   const lastPage = Math.floor(this.allMessages.length / 15);
    //   // this.pageInfo.lastPage = lastPage;
    //   if (this.pageInfo.lastPage === (this.pageInfo.offset + 1)) {
    //     if (this.prevResponse === 100) {
    //       this.page = ++this.page;
    //       this.getallMessages(this.currentSort, this.page);
    //     }
    //   }
    // }
    DataComponent.prototype.getMessages = function (event) {
        if (event.type === 'click' && event.cellIndex !== -1) {
            this.tsTime = event.row.tsTime;
            if (!event.row.teTime) {
                this.teTime = new Date().getTime();
            }
            else {
                this.teTime = event.row.teTime;
            }
            this.searchModel.value ? this.searchModel.value = "" : "";
            // if (!event.row.teTime) {
            // const rowIndex = event.row.index;
            // if (this.currentSort === 'asc') {
            // if (rowIndex !== this.trips.length - 1) {
            //   this.teTime = this.trips[rowIndex + 1].tsTime;
            // } else {
            //   // let currentTime = new Date().toUTCString();
            //   this.teTime = this.endTime;
            // }
            // } else {
            // if (rowIndex !== 0) {
            //   this.teTime = this.trips[rowIndex - 1].tsTime;
            // } else {
            //   // let currentTime = new Date().toUTCString();
            //   this.teTime = this.endTime;
            // }
            //   }
            // }
            this.page = 0;
            this.getallMessages(this.currentSort, this.page);
            this.getMessagesTotalCount();
            this.timeSeeker.selected = false;
        }
    };
    // getallMessages(row, sort) {
    //   this.dataService.getallMessages(this.selecteddeviceId, this.tsTime, this.teTime, sort).subscribe((res: any) => {
    //     res.map(item => {
    //       item['messageTypeText'] = this.configMessage.messageType[item.messageType]
    //     })
    //     this.allMessages = res;
    //     this.messagesValue = res;
    //     if (res.length) {
    //       this.getMessage(res[0].messageId)
    //       this.selectedMessage = [res[0]];
    //     } else {
    //       this.tripsLoading = false;
    //     }
    //   }, error => {
    //     this.allMessages = [];
    //     this.singleMessages = false;
    //     this.tripsLoading = false;
    //     this.deviceDetails = [];
    //     this.platformDetails = [];
    //     this.vehicleDetails = [];
    //     this.sharedService.getErrorMsg(error);
    //   });
    // }
    // getCache() {
    //   this.dataService.getCache().subscribe((res) => {
    //     this.cache = res;
    //     console.log(this.cache)
    //   }, error => {
    //     this.sharedService.getErrorMsg(error);
    //   });
    // }
    DataComponent.prototype.getMessage = function (messageId) {
        var _this = this;
        this.dataService.getMessage(messageId).subscribe(function (res) {
            _this.tripsLoading = false;
            _this.singleMessages = res;
            _this.messageFormattedString = _this.sharedService.syntaxHighlight([res]);
            _this.deviceDetails = [];
            _this.platformDetails = [];
            _this.vehicleDetails = [];
            Object.keys(_this.singleMessages).forEach(function (key) {
                var value = _this.singleMessages[key];
                // stringify it if the value for that key is arrar or array of object
                if (Array.isArray(value))
                    value = JSON.stringify(value);
                if (_this.deviceAtr.includes(key)) {
                    _this.deviceDetails.push({ 'key': key, 'value': value });
                }
                else if (_this.platformAtr.includes(key)) {
                    _this.platformDetails.push({ 'key': key, 'value': value });
                }
                else {
                    _this.vehicleDetails.push({ 'key': key, 'value': value });
                }
            });
        }, function (error) {
            _this.tripsLoading = false;
            _this.sharedService.getErrorMsg(error);
        });
    };
    DataComponent.prototype.getData = function (event) {
        if (event.type === 'click' && event.cellIndex !== -1) {
            this.getMessage(event.row.messageId);
        }
    };
    DataComponent.prototype.onSearch = function () {
        var inputVal = this.searchModel.value.trim();
        var val = inputVal.toLowerCase();
        // const temp = this.search(val, this.messagesValue);
        if (val === '') {
            this.allMessages = this.messagesValue;
            // this.messagesValue = this.messagesValue.splice(0,100);
            this.selectedFlag = false;
            // this.page = 0;
            // this.pageInfo.count = 100;
            // this.pageInfo.offset = 0;
            // this.pageInfo.lastPage = 7;
            // this.pageInfo.pageSize = 0;
            this.getTripsMessage();
        }
        else {
            // if (this.messagesValue.length) {
            //   const temp = this.messagesValue.filter((item, index) => {
            //     if (item.messageType && item.messageType.toLowerCase().includes(val)) {
            //       return this.messagesValue[index];
            //     } else if (item.messageTypeId && item.messageTypeId.toString().includes(val)) {
            //       return this.messagesValue[index];
            //     }
            //   });
            //   this.allMessages = temp;
            // }
        }
        // this.getMessagesTotalCount();
    };
    DataComponent.prototype.apiSearch = function (flag) {
        var _this = this;
        if (!flag) {
            this.page = 0;
            this.pageInfo.offset = 0;
        }
        if (this.searchModel.value) {
            var url = "messages/device/" + this.selecteddeviceId + "/search?startTime=" + this.startTime + "&endTime=" + this.endTime + "&attributes=eventTime,messageTypeId,messageId,messageType,deviceCommProtocol&sort=ASC&page=" + this.page;
            if (this.timeSeeker.selected) {
                url = "messages/device/" + this.selecteddeviceId + "/search?startTime=" + this.timeSeeker.ts + "&endTime=" + this.timeSeeker.te + "&attributes=eventTime,messageTypeId,messageId,messageType,deviceCommProtocol&sort=ASC&page=" + this.page;
            }
            if (this.toggleEffect === "trips") {
                url = "messages/device/" + this.selecteddeviceId + "/search?startTime=" + this.tsTime + "&endTime=" + this.teTime + "&attributes=eventTime,messageTypeId,messageId,messageType,deviceCommProtocol&sort=ASC&page=" + this.page;
            }
            this.getMessagesTotalCountOnSearch();
            this.dataService.getMessageData(url, { "searchKey": this.searchModel.value }).subscribe(function (data) {
                _this.prevResponse = data.length;
                if (_this.page === 0) {
                    _this.allMessages = data;
                }
                else {
                    _this.allMessages = tslib_1.__spread(_this.allMessages, data);
                }
                if (_this.allMessages.length) {
                    _this.messageTable = true;
                    _this.getMessage(_this.allMessages[0].messageId);
                    _this.selectedMessage = [_this.allMessages[0]];
                }
                else {
                    // this.messageTable = false;
                    _this.singleMessages = false;
                }
                // this.pageInfo.count = this.allMessages.length;
                // const pagecount = this.pageInfo.count / this.pageInfo.limit;
                // this.pageInfo.lastPage = Math.floor(pagecount);
                // const decimal = pagecount - this.pageInfo.lastPage;
                // if (!Number.isInteger(decimal)) {
                //   this.pageInfo.lastPage = this.pageInfo.lastPage + 1;
                // }
                _this.pageLoading = false;
                _this.setPageInfo();
            });
        }
    };
    /**
     *
     * @param serailNumber : String
     * @param type : String
     */
    //  Download JSON
    DataComponent.prototype.downloadJson = function () {
        var filename;
        var date = new Date();
        filename = 'data.json';
        var blob = new Blob([JSON.stringify(this.singleMessages)], {
            type: 'text/csv;charset=utf-8'
        });
        FileSaver.saveAs(blob, filename);
    };
    //  Download JSON
    DataComponent.prototype.downloadQueryJson = function () {
        var filename;
        var date = new Date();
        filename = 'data.json';
        var blob = new Blob([JSON.stringify(this.queryJson)], {
            type: 'text/csv;charset=utf-8'
        });
        FileSaver.saveAs(blob, filename);
    };
    // To download trips data as a csv file
    DataComponent.prototype.downloadTripCSV = function () {
        var _this = this;
        this.dataService.downloadCSV(this.selecteddeviceId, this.endTime, this.startTime).subscribe(function (res) {
            _this.downloadCSV(res, 'data');
        }, function (error) {
            _this.sharedService.getErrorMsg(error);
        });
    };
    // To download message data as a csv file
    DataComponent.prototype.downloadMsgCSV = function () {
        var _this = this;
        var startTime;
        var endTime;
        if (this.toggleEffect === 'all') {
            startTime = this.startTime;
            endTime = this.endTime;
        }
        if (this.toggleEffect === 'trips') {
            startTime = this.tsTime;
            endTime = this.teTime;
        }
        if (this.timeSeeker.selected) {
            startTime = this.timeSeeker.ts;
            endTime = this.timeSeeker.te;
        }
        this.dataService.downloadCSV(this.selecteddeviceId, endTime, startTime).subscribe(function (res) {
            _this.downloadCSV(res, 'trip');
        }, function (error) {
            _this.sharedService.getErrorMsg(error);
        });
    };
    // download api res to downloaded file
    DataComponent.prototype.downloadCSV = function (res, name) {
        var contentDisposition = res.headers.get('Content-Disposition');
        var filename;
        if (contentDisposition === null) {
            var date = new Date();
            filename = name + date + '.json.gz';
        }
        else {
            filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
        }
        var blob = new Blob([res.body], {
            type: 'application/tar+gzip;charset=utf-8'
        });
        FileSaver.saveAs(blob, filename);
    };
    DataComponent.prototype.sortTrips = function (e) {
        if (this.trips.length) {
            this.tripSort = e.newValue;
            this.currentSort = e.newValue;
            this.getTrips(e.newValue);
        }
    };
    DataComponent.prototype.sortMessages = function (e) {
        if (this.allMessages.length) {
            this.page = 0;
            this.messageSort = e.newValue;
            this.currentSort = e.newValue;
            this.getallMessages(e.newValue, '0');
        }
    };
    DataComponent.prototype.sortMessagesWithTrips = function (e) {
        if (this.trips.length && this.allMessages.length) {
            this.page = 0;
            this.tripMsgSort = e.newValue;
            this.currentSort = e.newValue;
            this.getallMessages(e.newValue, '0');
        }
    };
    DataComponent.prototype.getMessageType = function () {
        var _this = this;
        this.pageLoading = true;
        this.dataService.getMessageType(this.selectedVehicle.architectureType).subscribe({
            next: function (res) {
                if (res.length) {
                    _this.messageTypeArray = res;
                    _this.searchModel.messageType = _this.messageTypeArray[0].messageType;
                }
                else {
                    _this.messageTypeArray = [];
                    _this.searchModel.messageType = null;
                }
                _this.pageLoading = false;
            },
            error: function (error) {
                _this.pageLoading = false;
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    DataComponent.prototype.refresh = function () {
        this.generateQueryMessage(true);
    };
    DataComponent.prototype.generateQueryMessage = function (isValid) {
        var _this = this;
        if (isValid) {
            var payload = {};
            payload['deviceId'] = this.selecteddeviceId;
            payload['endTime'] = this.endTime;
            payload['startTime'] = this.startTime;
            payload['messageType'] = this.searchModel.messageType;
            // payload['filter']  = JSON.stringify(this.searchModel.filter);
            payload['filter'] = this.searchModel.filter;
            if (this.searchModel.attributes) {
                payload['attributes'] = this.searchModel.attributes;
            }
            this.dataService.generateQueryMessage(payload).subscribe({
                next: function (res) {
                    _this.queryMessage = res;
                    _this.queryJson = res;
                    Object.keys(_this.queryJson).forEach(function (key) {
                        if (_this.deviceAtr.includes(key)) {
                            _this.deviceDetails.push({ 'key': key, 'value': _this.queryJson[key] });
                        }
                        if (_this.platformAtr.includes(key)) {
                            _this.platformDetails.push({ 'key': key, 'value': _this.queryJson[key] });
                        }
                        else {
                            _this.vehicleDetails.push({ 'key': key, 'value': _this.queryJson[key] });
                        }
                    });
                    _this.queryFormattedString = _this.sharedService.syntaxHighlight(res);
                },
                error: function (error) {
                    _this.sharedService.getErrorMsg(error);
                }
            });
        }
    };
    DataComponent.prototype.onTimeSelection = function (val) {
        this.page = 0;
        this.pageInfo.offset = 0;
        this.searchModel.dataPresent = false;
        this.searchModel.value = "";
        this.searchModel.messageType = null;
        if (this.timeSeeker.selectedVal.includes(val.ts)) {
            var index = this.timeSeeker.selectedVal.indexOf(val.ts);
            this.timeSeeker.selectedVal.splice(index, 1);
            this.timeSeeker.selected = false;
            this.getallMessages(this.currentSort, this.page);
            this.getMessagesTotalCount();
        }
        else if (this.timeSeeker.selectedVal.length < 1 || this.timeSeeker.selectedVal.length === 1) {
            this.timeSeeker.selectedVal = [];
            this.timeSeeker.selected = true;
            this.timeSeeker.ts = val.ts;
            this.timeSeeker.te = val.te;
            this.getMessagesData(this.selecteddeviceId, val.ts, val.te, this.currentSort, this.page);
            this.getMessagesTotalCount();
            this.timeSeeker.selectedVal.push(val.ts);
        }
    };
    DataComponent.prototype.getMessagesData = function (deviceId, ts, te, sort, page) {
        var _this = this;
        this.dataService.getallMessages(deviceId, ts, te, sort, page).subscribe(function (res) {
            if (res.length) {
                res.map(function (item) {
                    item['messageTypeText'] = _this.configMessage.messageType[item.messageType];
                });
                _this.prevResponse = res.length;
                if (page == 0) {
                    _this.pageInfo.offset = 0;
                    _this.allMessages = res;
                    _this.messagesValue = res;
                    if (res.length) {
                        _this.getMessage(res[0].messageId);
                        _this.selectedMessage = [res[0]];
                    }
                    else {
                        _this.tripsLoading = false;
                    }
                }
                else {
                    _this.allMessages = tslib_1.__spread(_this.allMessages, res);
                    _this.messagesValue = tslib_1.__spread(_this.messagesValue, res);
                    _this.getMessage(_this.allMessages[0].messageId);
                    _this.selectedMessage = [_this.allMessages[0]];
                }
                if (_this.allMessages.length) {
                    _this.messageTable = true;
                }
                else {
                    _this.messageTable = false;
                }
                _this.setPageInfo();
                // this.pageInfo.count = this.allMessages.length;
                // const pagecount = this.pageInfo.count / this.pageInfo.limit;
                // this.pageInfo.lastPage = Math.floor(pagecount);
                // const decimal = pagecount - this.pageInfo.lastPage;
                // if (!Number.isInteger(decimal)) {
                //   this.pageInfo.lastPage = this.pageInfo.lastPage + 1;
                // }
                _this.pageLoading = false;
            }
            else {
                _this.pageLoading = false;
                _this.messageTable = false;
                _this.allMessages = [];
                _this.singleMessages = false;
                _this.deviceDetails = [];
                _this.platformDetails = [];
                _this.vehicleDetails = [];
                _this.messagesValue = [];
            }
        }, function (error) {
            _this.pageLoading = false;
            _this.allMessages = [];
            _this.singleMessages = false;
            _this.tripsLoading = false;
            _this.deviceDetails = [];
            _this.platformDetails = [];
            _this.vehicleDetails = [];
            _this.sharedService.getErrorMsg(error);
        });
    };
    // Setting data limit for table in each page
    DataComponent.prototype.setPageInfo = function () {
        this.pageInfo.count = this.allMessages.length;
        var pagecount = this.pageInfo.count / this.pageInfo.limit;
        this.pageInfo.lastPage = Math.floor(pagecount);
        var decimal = pagecount - this.pageInfo.lastPage;
        if (!Number.isInteger(decimal)) {
            this.pageInfo.lastPage = this.pageInfo.lastPage + 1;
        }
        if (this.page == 0) {
            if (this.allMessages.length) {
                this.currentPageCount.start = (this.pageInfo.offset * this.pageInfo.pageSize) + 1;
                if (this.allMessages.length <= this.pageInfo.limit) {
                    this.currentPageCount.end = this.pageInfo.count;
                }
                else {
                    this.currentPageCount.end = (this.pageInfo.offset * this.pageInfo.pageSize) + this.pageInfo.limit;
                }
            }
        }
        else if (this.prevResponse >= 15) {
            this.currentPageCount.end = (this.pageInfo.offset * this.pageInfo.limit) + this.pageInfo.limit;
        }
    };
    DataComponent.prototype.pageCallback = function (value) {
        this.pageInfo.offset = value.offset;
        this.currentPageCount.start = (value.offset * value.pageSize) + 1;
        var lastPage = Math.floor(this.allMessages.length / this.pageInfo.limit);
        if (lastPage == value.offset) {
            this.currentPageCount.end = value.count;
        }
        else {
            this.currentPageCount.end = (value.offset * value.pageSize) + value.limit;
        }
        // if (this.pageCount == 100) {
        if (this.pageInfo.lastPage == (this.pageInfo.offset + 1)) {
            if (this.prevResponse == 100) {
                this.page = ++this.page;
                // if (this.usingEventTime) {
                //   this.getSingleVehicleView(this.vehicle.deviceId, this.vehicle.startTime, this.vehicle.endTime);
                // } else {
                if (this.timeSeeker.selected) {
                    this.getallMessages(this.currentSort, this.page);
                }
                else {
                    this.getallMessages(this.currentSort, this.page);
                }
                // }
            }
        }
        // }
    };
    DataComponent.prototype.getMessagesTotalCount = function () {
        var _this = this;
        var req = null;
        if (this.toggleEffect === "trips") {
            req = this.dataService.getMessagesCount(this.selecteddeviceId, this.tsTime, this.teTime, this.page, this.resSize, this.searchModel.messageType);
        }
        else {
            req = this.dataService.getMessagesCount(this.selecteddeviceId, this.startTime, this.endTime, this.page, this.resSize, this.searchModel.messageType);
        }
        if (this.timeSeeker.selected) {
            req = this.dataService.getMessagesCount(this.selecteddeviceId, this.timeSeeker.ts, this.timeSeeker.te, this.page, this.resSize, this.searchModel.messageType);
        }
        req.subscribe(function (res) {
            _this.currentPageCount.totalCount = res.count;
        }, function (error) {
            _this.sharedService.getErrorMsg(error);
        });
    };
    DataComponent.prototype.getMessagesTotalCountOnSearch = function () {
        var _this = this;
        var req = null;
        if (this.toggleEffect === "trips") {
            req = this.dataService.getMessagesCountOnSearch(this.selecteddeviceId, this.tsTime, this.teTime, this.page, this.resSize, this.searchModel.value);
        }
        else {
            req = this.dataService.getMessagesCountOnSearch(this.selecteddeviceId, this.startTime, this.endTime, this.page, this.resSize, this.searchModel.value);
        }
        if (this.timeSeeker.selected) {
            req = this.dataService.getMessagesCountOnSearch(this.selecteddeviceId, this.timeSeeker.ts, this.timeSeeker.te, this.page, this.resSize, this.searchModel.value);
        }
        req.subscribe(function (res) {
            _this.currentPageCount.totalCount = res.count;
        }, function (error) {
            _this.sharedService.getErrorMsg(error);
        });
    };
    DataComponent.prototype.resetTimeSeeker = function () {
        this.timeSeeker = {
            selected: false,
            ts: null,
            te: null,
            selectedVal: []
        };
    };
    return DataComponent;
}());
export { DataComponent };
