import { OnInit, NgZone } from '@angular/core';
import { AuthenticationService } from '@layouts/login/authentication.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { SharedService } from '@shared/shared.service';
import { environment } from '@myenv/environment';
var ForgotPasswordComponent = /** @class */ (function () {
    function ForgotPasswordComponent(sharedService, authenticationService, ngzone, router, title) {
        this.sharedService = sharedService;
        this.authenticationService = authenticationService;
        this.ngzone = ngzone;
        this.router = router;
        this.title = title;
        // public tenantId = environment.tenantId;
        this.footerText = environment.footerText;
        this.cmImageUrl = environment.cmImageUrl;
        this.responseMessage = {};
        this.forgot = {};
        this.disable = false;
    }
    ForgotPasswordComponent.prototype.ngOnInit = function () {
        this.title.setTitle('Forgot password' + environment.title_text);
        this.sharedService.bootDownInterCom();
    };
    /* Resetting the token in local storage
      Redirecting to login page */
    ForgotPasswordComponent.prototype.onClickForgotPassword = function (isValid) {
        var _this = this;
        if (isValid) {
            this.disable = true;
            this.authenticationService.ForgotPassword(this.forgot).subscribe(function (res) {
                localStorage.setItem("resetToken", res.resetToken);
                _this.ngzone.run(function () {
                    _this.disable = false;
                    _this.router.navigate(['/login'], { queryParams: { msg: 1 } });
                });
            }, function (error) {
                _this.sharedService.getErrorMsg(error);
                _this.disable = false;
            });
        }
    };
    return ForgotPasswordComponent;
}());
export { ForgotPasswordComponent };
