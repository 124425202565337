import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '@myenv/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
var ReportsService = /** @class */ (function () {
    function ReportsService(http) {
        this.http = http;
        this.apiUrl = environment.apiUrl;
    }
    // get attribute API for vehicle
    ReportsService.prototype.getAttribute = function (data) {
        return this.http.get(this.apiUrl + 'reports/attribute/' + data).pipe(catchError(this.handleError));
    };
    //To get single report
    ReportsService.prototype.getDeviceView = function (reportId, startTime, endTime, page, serialNumber) {
        var tempURL = this.apiUrl + 'reports/vin-change/' + reportId + '/generate?startTime=' + startTime + '&endTime=' + endTime + '&page=' + page;
        if (serialNumber) {
            tempURL += '&serialNumber=' + serialNumber;
        }
        return this.http.get(tempURL).pipe(catchError(this.handleError));
    };
    //To get single report
    ReportsService.prototype.getSingleReportByReportId = function (reportId, data, startTime, endTime, page) {
        return this.http.get(this.apiUrl + 'reports/' + reportId + '/generate?startTime=' + startTime + '&endTime=' + endTime + '&page=' + page + '&deviceId=' + data).pipe(catchError(this.handleError));
    };
    //to get total message count
    ReportsService.prototype.getMessageCount = function (reportId, deviceId, startTime, endTime) {
        return this.http.get(this.apiUrl + 'reports/' + reportId + '/count?startTime=' + startTime + '&endTime=' + endTime + '&deviceId=' + deviceId).pipe(catchError(this.handleError));
    };
    //to get total message count
    ReportsService.prototype.getDeviceMeessageCount = function (reportId, startTime, endTime, serialNumber) {
        var tempURL = this.apiUrl + 'reports/vin-change/' + reportId + '/count?startTime=' + startTime + '&endTime=' + endTime;
        if (serialNumber) {
            tempURL += '&serialNumber=' + serialNumber;
        }
        return this.http.get(tempURL).pipe(catchError(this.handleError));
    };
    //to get total message count
    // getMessageCount(reportId, deviceId, startTime, endTime): Observable<any>{
    //   return this.http.get(this.apiUrl + 'reports/' + reportId + '/count?startTime=' + startTime + '&endTime=' + endTime + '&selectionTypeId=' + deviceId).pipe(
    //     catchError(this.handleError))
    // }
    //to get total message count
    ReportsService.prototype.multiVehicleView = function (reportId, deviceId, startTime, endTime, nextPage) {
        var tempURL = this.apiUrl + 'reports/' + reportId + '/generate/multi?startTime=' + startTime + '&endTime=' + endTime + '&selectionTypeId=' + deviceId + '&limit=100';
        if (nextPage) {
            tempURL += '&nextPage=' + nextPage;
        }
        return this.http.get(tempURL).pipe(catchError(this.handleError));
    };
    //To download reports as CSV file
    ReportsService.prototype.downloadReportCSV = function (reportId, deviceId, startTime, endTime) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this.http.post(this.apiUrl + 'reports/' + reportId + '/download/token?startTime=' + startTime + '&endTime=' + endTime + '&selectionTypeId=' + deviceId, { headers: headers }).pipe(catchError(this.handleError));
    };
    ReportsService.prototype.handleError = function (error) {
        return observableThrowError(error.error || error.json() || error || 'Server error');
    };
    return ReportsService;
}());
export { ReportsService };
