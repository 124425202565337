import * as tslib_1 from "tslib";
import { OnInit, ElementRef } from '@angular/core';
import { SupportService } from '../../support.service';
import { SharedService } from '@myproject/shared/shared.service';
import { environment } from '@myenv/environment';
import { enMessage } from '@shared/en-us-message';
var ProvisioningComponent = /** @class */ (function () {
    function ProvisioningComponent(supportService, sharedService) {
        this.supportService = supportService;
        this.sharedService = sharedService;
        this.dateFormat = environment.isoDateTimeFormat;
        this.responseMessage = {};
        this.searchBySchema = [
            { displayName: 'Serial Number', key: 'serialNumber' },
            { displayName: 'SR ID', key: 'serviceRequestId' },
            { displayName: 'PSN', key: 'productSerialNumber' },
            { displayName: 'VIN', key: 'vin' },
            { displayName: 'Device Status', key: 'deviceStatus' },
            { displayName: 'Request ID', key: 'requestId' },
            { displayName: 'Request Flag', key: 'requestFlag' }
        ];
        this.deviceStatus = [
            { displayName: 'In Progress', key: 4 },
            { displayName: 'Request Failed', key: 5 },
            { displayName: 'Success', key: 6 },
            { displayName: 'Commercially Failed', key: 7 },
            { displayName: 'Retrigger', key: 8 }
        ];
        this.requestFlags = [
            { displayName: 'S', key: 'S' },
            { displayName: 'C', key: 'C' }
        ];
        this.searchModel = {
            searchBy: 'serialNumber',
            searchValue: '',
            remarks: ''
        };
        this.page = 0;
        this.devices = [];
        this.prevResponse = 0;
        this.pageLoading = false;
        this.pageInfo = {
            offset: 0,
            count: 0,
            limit: 10,
            pageSize: 0,
            lastPage: 0,
        };
        this.currentPageCount = {
            start: 0,
            end: 0
        };
        this.selectedDevice = {};
        this.vahanFileSelect = false;
        this.bsnlFileSelect = false;
        this.disable = false;
        this.activating = false;
        this.activationStatus = '';
        // currentUser: any = {};
        this.showConfirmModal = false;
        this.hideConfirmModal = false;
        this.confirmSelectDevice = {};
    }
    ProvisioningComponent.prototype.ngOnInit = function () {
        this.pageLoading = true;
        // this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.getProvisioningDevices(0);
    };
    ProvisioningComponent.prototype.searchDataOnChange = function () {
        if (['deviceStatus', 'requestFlag'].includes(this.searchModel.searchBy)) {
            if (this.searchModel.searchValue) {
                this.searchModel.searchValue = null;
                this.onSearch();
            }
            else {
                this.searchModel.searchValue = null;
            }
        }
        else if ([4, 5, 6, 7, 'S', 'C'].includes(this.searchModel.searchValue)) {
            this.searchModel.searchValue = '';
            this.onSearch();
        }
        else if (this.searchModel.searchValue === null) {
            this.searchModel.searchValue = '';
        }
        if (this.searchModel.searchValue && this.searchModel.searchBy) {
            this.onSearch();
        }
    };
    ProvisioningComponent.prototype.onSearch = function () {
        this.pageLoading = true;
        this.page = 0;
        this.pageInfo.offset = 0;
        if (!this.searchModel.searchValue) {
            this.getProvisioningDevices(0);
        }
        else {
            this.provisionDeviceSearch(0, this.searchModel.searchBy, this.searchModel.searchValue);
        }
    };
    ProvisioningComponent.prototype.provisionDeviceSearch = function (page, key, value) {
        var _this = this;
        this.supportService.provisionDeviceSearch(page, key, value, this.userData.customerId).subscribe({
            next: function (res) {
                if (page === 0) {
                    _this.devices = res;
                }
                else {
                    _this.devices = tslib_1.__spread(_this.devices, res);
                }
                _this.pageLoading = false;
                _this.prevResponse = res.length;
            },
            error: function (error) {
                _this.pageLoading = false;
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    ProvisioningComponent.prototype.selectInput = function () {
        var inputElem = this.inputOne.nativeElement;
        inputElem.select();
    };
    ProvisioningComponent.prototype.getProvisioningDevices = function (page) {
        var _this = this;
        this.supportService.getProvisioningDevices(page, this.userData.customerId).subscribe({
            next: function (res) {
                if (page === 0) {
                    _this.devices = res;
                }
                else {
                    _this.devices = tslib_1.__spread(_this.devices, res);
                }
                _this.pageLoading = false;
                _this.prevResponse = res.length;
            },
            error: function (error) {
                _this.pageLoading = false;
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    // To get the vehicles for each page in the table
    ProvisioningComponent.prototype.pageCallback = function (value) {
        this.pageInfo.offset = value.offset;
        this.currentPageCount.start = (value.offset * value.pageSize) + 1;
        var lastPage = Math.floor(this.devices.length / 10);
        this.pageInfo.lastPage = lastPage;
        if (lastPage == value.offset) {
            this.currentPageCount.end = value.count;
        }
        else {
            this.currentPageCount.end = (value.offset * value.pageSize) + value.limit;
        }
        if (this.pageInfo.lastPage === (this.pageInfo.offset + 1)) {
            if (this.prevResponse) {
                this.page = ++this.page;
                if (this.searchModel.searchValue) {
                    this.provisionDeviceSearch(this.page, this.searchModel.searchBy, this.searchModel.searchValue);
                }
                else {
                    this.getProvisioningDevices(this.page);
                }
            }
        }
    };
    ProvisioningComponent.prototype.downloadKYC = function (device) {
        this.supportService.downloadKYC(device.productSerialNumber).subscribe(function (data) {
            var blob = new Blob([data], {
                type: 'application/zip'
            });
            var url = window.URL.createObjectURL(blob);
            window.open(url);
        });
    };
    ProvisioningComponent.prototype.onFileSelect = function (event, type) {
        if (event.target.files.length != 0) {
            if (type === 'vahan') {
                this.vahanFileSelect = false;
            }
            if (type === 'bsnl') {
                this.bsnlFileSelect = false;
            }
        }
        else {
            if (type === 'vahan') {
                this.vahanFileSelect = true;
            }
            if (type === 'bsnl') {
                this.bsnlFileSelect = true;
            }
        }
    };
    ProvisioningComponent.prototype.uploadCertificates = function (value) {
        var _this = this;
        var vahanFile = this.inputVaahanFile.nativeElement.files[0];
        var bsnlFile = this.inputBSNLFile.nativeElement.files[0];
        if (vahanFile && bsnlFile) {
            this.disable = true;
            var formData = new FormData();
            formData.append('vahanFile', vahanFile);
            formData.append('bsnlFile', bsnlFile);
            this.supportService.uploadCertificates(formData, this.selectedDevice.productSerialNumber, 'success').subscribe({
                next: function (res) {
                    _this.closeuploadModal.nativeElement.click();
                    _this.responseMessage = { success: true, message: enMessage.provisionUploadSuccess };
                    _this.sharedService.setResponseMessage(_this.responseMessage);
                    _this.disable = false;
                    _this.page = 0;
                    _this.pageInfo.offset = 0;
                }, error: function (error) {
                    _this.sharedService.getErrorMsg(error);
                    _this.disable = false;
                    // this.closeuploadModal.nativeElement.click();
                }
            });
        }
        else {
            this.disable = false;
            if (!vahanFile) {
                this.vahanFileSelect = true;
            }
            if (!bsnlFile) {
                this.bsnlFileSelect = true;
            }
        }
    };
    ProvisioningComponent.prototype.activateDevice = function (isValid) {
        var _this = this;
        if (isValid) {
            var device = this.selectedDevice;
            this.activating = true;
            this.supportService.activateDevice(device.requestId, device.productSerialNumber, this.activationStatus, 'yes', this.searchModel.remarks).subscribe({
                next: function (res) {
                    if (_this.activationStatus === 'SUCCESS') {
                        _this.responseMessage = { success: true, message: enMessage.provisionActivateSuccess };
                    }
                    else if (_this.activationStatus === 'FAILED') {
                        _this.responseMessage = { success: true, message: enMessage.provisionActivateFail };
                    }
                    _this.sharedService.setResponseMessage(_this.responseMessage);
                    _this.page = 0;
                    _this.pageInfo.offset = 0;
                    _this.searchModel.searchValue = null;
                    _this.getProvisioningDevices(0);
                    _this.closeRemarkModal.nativeElement.click();
                    _this.searchModel.remarks = '';
                    _this.activationStatus = '';
                    _this.activating = false;
                },
                error: function (error) {
                    // this.activating = false;
                    // this.activationStatus = '';
                    // this.closeRemarkModal.nativeElement.click();
                    _this.sharedService.getErrorMsg(error);
                }
            });
        }
    };
    ProvisioningComponent.prototype.retriggerDevice = function () {
        var _this = this;
        this.disable = true;
        var d = this.confirmSelectDevice;
        this.supportService.retriggerDevice(d.requestId, d.productSerialNumber).subscribe({
            next: function (res) {
                _this.disable = false;
                _this.hideConfirmModal = !_this.hideConfirmModal;
                _this.responseMessage = { success: true, message: enMessage.successTriggerFlashDevice };
                _this.sharedService.setResponseMessage(_this.responseMessage);
                _this.page = 0;
                _this.pageInfo.offset = 0;
                _this.searchModel.searchValue = null;
                _this.getProvisioningDevices(0);
            },
            error: function (error) {
                _this.disable = false;
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    return ProvisioningComponent;
}());
export { ProvisioningComponent };
