import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { MarketplaceService } from '@components/admin/marketplace/marketplace.service'
import { SharedService } from '@shared/shared.service';
// import { NgxSpinnerService } from 'ngx-spinner';
import { Title } from '@angular/platform-browser';
import { environment } from '@myenv/environment';
import { AdminService } from '@components/admin/admin.service';
import { enMessage } from '@shared/en-us-message';

@Component({
  selector: 'app-marketplace',
  templateUrl: './marketplace.component.html',
  styleUrls: ['./marketplace.component.scss'],
})
export class MarketplaceComponent implements OnInit {
  // public tenantId = environment.tenantId;
  public cmImageUrl = environment.cmImageUrl;
  public dateFormat = environment.isoDateTimeFormat;
  responseMessage: any = {};

  roles: any = [];
  allModules: any = [];
  role: any = {};
  currentUser: any = {};
  modules: any = [];
  // tslint:disable-next-line:no-inferrable-types
  getRName: string = '';
  roleId: string = '';
  @ViewChild('btnClose', { static: false }) btnClose: ElementRef;
  @ViewChild('btnCloseDelete', { static: false }) btnCloseDelete: ElementRef;
  moduleName: string = 'ROLE';
  permissionAuthorities: any = {};
  permissionModules: any = [];
  isLoading: boolean = false;
  disable = false;
  loadingIndicator = true;
  message: any;
  searchModel: any = {};
  @ViewChild('inputElement', { static: false }) inputOne: ElementRef;
  timeout = null;
  searchHeader = [
    { name: 'name', displayName: 'Role Name', type: 'STRING' }
  ];
  showModal = false;
  hideModal = false;
  currentPageCount: any = {
    start: 0,
    end: 0
  };
  pageInfo: any = {
    offset: 0,
    count: 0,
    limit: 10,
    pageSize: 0,
    lastPage: 0,
  };
  prevResponse = 0;
  selectedRole: any = null;
  popoverPlacement = 'right';

  // tslint:disable-next-line:max-line-length
  constructor(
    private adminService: AdminService,
    private sharedService: SharedService,
    private title: Title
  ) { }

  ngOnInit() {
    this.searchModel = {
      searchId: 'name'
    };
    this.permissionModules = JSON.parse(localStorage.getItem('accessRoleModules'));
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.permissionAuthorities = this.permissionModules.find(module => module.name === this.moduleName);
    this.title.setTitle('Marketplace'+ environment.title_text);
    this.message = this.adminService.getSideMenu();
    this.sharedService.bootDownInterCom();
  }
}
