import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '@myenv/environment';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { Router } from '@angular/router';
var DeviceReplacementService = /** @class */ (function () {
    function DeviceReplacementService(http, router, handler) {
        this.http = http;
        this.router = router;
        this.handler = handler;
        this.apiUrl = environment.apiUrl;
        this.httpClient = new HttpClient(handler);
    }
    DeviceReplacementService.prototype.searchDevice = function (key, value, customerId) {
        // let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        // let customerId = currentUser.customerId;
        return this.http.get(this.apiUrl + "devices/search/associated?key=" + key + "&value=" + value + "&customerId=" + customerId).pipe(catchError(this.handleError));
    };
    DeviceReplacementService.prototype.getUnAssociatedDevices = function (key, value, customerId) {
        return this.http.get(this.apiUrl + "devices/search/unAssociated?key=" + key + "&value=" + value + "&customerId=" + customerId).pipe(catchError(this.handleError));
    };
    DeviceReplacementService.prototype.replaceDevice = function (payload) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
        return this.http.post(this.apiUrl + "devices/replace", payload, { headers: headers }).pipe(catchError(this.handleError));
    };
    DeviceReplacementService.prototype.handleError = function (error) {
        return observableThrowError(error.error || error || 'Server error');
    };
    return DeviceReplacementService;
}());
export { DeviceReplacementService };
