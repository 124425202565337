<!-- <app-notify-message></app-notify-message>
<app-reports-header></app-reports-header>
<div class="tab-content">
  <div role="tabpanel" class="tab-pane fade in active show relative" id="deepView">
    <div class="row no-gutters">
      <div class="col-sm-12 fullheight">
        <div class="text-center" style="margin-top: 30vh;">
          <canvas id="stackedBarGraph" height="150" role="img"></canvas>
        </div>
      </div>
    </div>
  </div>
</div> -->


<!--  
<app-reports-header></app-reports-header>
<div class="row no-gutters">
  <div class="col-md-2 col-sm-0 p-0">
    <app-reports-side-menu></app-reports-side-menu>
  </div>
  
    
        <div class="col-sm-12 fullheight">
          <div class="text-center" style="margin-top: 30vh;">
            <canvas id="stackedBarGraph" height="150" role="img"></canvas>
          </div>
       
   </div>
  </div>

</div> -->


<app-reports-header></app-reports-header>
<div class="row no-gutters">
  <div class="col-md-2 col-sm-0 p-0">
    <app-reports-side-menu></app-reports-side-menu>
  </div>


    <div class="reports-body col-md-10 col-sm-12" style="padding: 10px;">
      <div class="outer-wrapper">
        <div class="report-item-container total-count-container total-device animated fadeIn">
          <span class="count">{{totalAssetCount}}</span>
          <h6 class="text">
            <span *ngIf="assetType === 'device'">Total Devices</span>
            <span *ngIf="assetType !== 'device'">Total Vehciles</span>
          </h6>
        </div>
      </div>
      <div class="outer-wrapper">
        <div class="report-item-container total-count-container total-messages-pushed animated fadeIn">
          <span class="count">{{totalMessagesCount}}</span>
          <h6 class="text">
            <span>Total Messages Pushed</span>
          </h6>
        </div>
      </div>
      <div class="outer-wrapper">
        <div class="report-item-container total-count-container account-status animated fadeIn"
          [class.active]="accountStatus==='Active'">
          <span class="count">{{accountStatus}}</span>
          <h6 class="text">
            <span>Account Status</span>
          </h6>
        </div>
      </div>
      <div class="outer-wrapper pr-0">
        <div class="report-item-container total-count-container account-expiry-date animated fadeIn"
          [class.active]="expiredDate === false">
          <span *ngIf="toDate != null" class="count">{{toDate | date: 'MM-dd-yyyy'}}</span>
          <span *ngIf="toDate === null" class="count">N/A</span>
          <h6 class="text">
            <span>Account Expiry Date</span>
          </h6>
        </div>
      </div>
      <!-- <div class="report-item-container" style="margin-top: 15px;">
      <div class="row no-gutters align-items-center action-row px-2" style="margin-top: 12vh;">
        <canvas id="stackedBarGraph" height="150" role="img"></canvas>
      </div>
      </div> -->

      <div class="report-item-container">
      <h5 class="text-center">
        <strong>
          Month wise Usage Data
        </strong>
      </h5>
      <div class="align-items-center col-12 row mt-3 action-row px-2">
        <canvas id="stackedBarGraph" height="150" role="img"></canvas>
      </div>
      <div class="row">
        <div class=" offset-lg-7 offset-xl-7 col-lg-5 col-xl-5 col-md-12">
          <div class="row m-2">
            <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 mb-2 ">
              <select name="month" (change)="onChangeName($event)" [(ngModel)]="selectedMonth"
                class="form-control select-select">
                <option *ngFor="let mnth of month" [value]="mnth.id">{{mnth.name}}</option>
              </select>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2 ">
              <select name="month" (change)="onChangeName($event)" [(ngModel)]="selectedYear"
                class="form-control select-select">
                <option *ngFor="let yr of years" [value]="yr">{{yr}}</option>
              </select>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 mb-2 ">
              <button class="btn btn-icon" tooltip="Download in excel" (click)="downloadUsage()" placement="top">
                <i class="fa fa-download" aria-hidden="true"></i>
                <span class="mob-access-text">&nbsp;Download</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>

        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">


            <ngx-datatable [loadingIndicator]="loadingIndicator" class="bootstrap wrapcell" [rows]="deviceList"
              [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [scrollbarH]="false"
              [limit]="pageInfo.limit" [offset]="pageInfo.offset" 
              (page)="pageCallback($event)" [count]="pageInfo.count" [scrollbarV]="false">
              <ngx-datatable-column [resizeable]="false" [sortable]="false" name="#" [width]="20">
                <ng-template let-rowIndex="rowIndex" ngx-datatable-cell-template>
                  {{rowIndex+1}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [resizeable]="false" prop="name" name="Customer Id"  [width]="80">
                <ng-template let-row="row" let-i="rowIndex" ngx-datatable-cell-template>
                  <a>
                    {{row.customerId}}
                  </a>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [resizeable]="false" prop="name" name="Serial Number">
                <ng-template let-row="row" let-i="rowIndex" ngx-datatable-cell-template>
                  <a>
                    {{row.serialNumber}}
                  </a>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [resizeable]="false" prop="name" name="Name">
                <ng-template let-row="row" let-i="rowIndex" ngx-datatable-cell-template>
                  <a>
                    {{row.assetName}}
                  </a>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [resizeable]="false" prop="name" name="Message Count" [width]="100">
                <ng-template let-row="row" let-i="rowIndex" ngx-datatable-cell-template>
                  <a>
                    {{row.messageCount}}
                  </a>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [resizeable]="false" prop="name" name="Device Status">
                <ng-template let-row="row" let-i="rowIndex" ngx-datatable-cell-template>
                  <a>
                    {{row.deviceStatus}}
                  </a>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [resizeable]="false" prop="name" name="Device Added on">
                <ng-template let-row="row" let-i="rowIndex" ngx-datatable-cell-template>
                  <a>
                    {{row.createdAt}}
                  </a>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
            <div class="total-count" *ngIf="currentPageCount.totalCount && deviceList.length">
              Showing
              <span>
                {{ currentPageCount.start }}
              </span>
              -
              <span>
                {{ currentPageCount.end }}
              </span> of {{ currentPageCount.totalCount }} results.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>