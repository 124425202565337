<div class="body-wrap">
    <div id="sm-container" class="sm-container">
        <div class="sm-menu sm-effect-1" id="menu-1">
            <div class="sm-profile">
                <div class="sm-profile-user-wrapper">
                    <div class="profile-user-image">
                        <img src="https://via.placeholder.com/128x128" class="img-circle rounded-circle" alt="darby"/>
                    </div>
                    <div class="profile-user-info">
                        <span class="profile-user-name">Administrator</span>
                        <span class="profile-user-email">darby</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="sm-pusher">

            <div class="sm-content">
                <div class="sm-content-inner">
                    <app-header></app-header>
                  <div class="body-scroll">
                    <router-outlet></router-outlet>
                  </div>
                    <app-footer *ngIf="showFooter"></app-footer>

                </div>
            </div>
        </div>
    </div>
</div>
