import { OnInit, NgZone } from '@angular/core';
import { AuthenticationService } from '@layouts/login/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { SharedService } from '@shared/shared.service';
import { environment } from '@myenv/environment';
import { CookieService } from 'angular2-cookie/core';
import { enMessage } from '@shared/en-us-message';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(_cookieService, authenticationService, router, ngzone, route, title, sharedService) {
        this._cookieService = _cookieService;
        this.authenticationService = authenticationService;
        this.router = router;
        this.ngzone = ngzone;
        this.route = route;
        this.title = title;
        this.sharedService = sharedService;
        // public tenantId = environment.tenantId;
        this.footerText = environment.footerText;
        this.cmImageUrl = environment.cmImageUrl;
        this.login = {};
        this.message = '';
        this.responseMessage = {};
        this.permissionModules = [];
        this.typePassword = false;
        this.disable = false;
        // for MFA
        this.verificationReq = false;
        this.resetDisable = true;
        this.tempToken = null;
        this.otp = null;
        this.resendTimer = 0;
        this.resendInterval = null;
        this.otpCount = 0;
        this.resetCount = 0;
    }
    LoginComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.router.url === '/logout' || this.router.url === '/refresh') {
            this.logout();
            this.ngzone.run(function () {
                _this.router.navigate(['/login']);
            });
        }
        this.title.setTitle('Login' + environment.title_text);
        this.route.queryParams.subscribe(function (queryParams) {
            // do something with the query params
            if (queryParams.msg == 1) {
                _this.responseMessage = { success: true, message: enMessage.verifyResetToken };
                _this.sharedService.setResponseMessage(_this.responseMessage);
            }
            else if (queryParams.msg == 2) {
                _this.responseMessage = { success: true, message: enMessage.successResetPassword };
                _this.sharedService.setResponseMessage(_this.responseMessage);
            }
        });
        this.sharedService.bootDownInterCom();
        // localStorage.clear();
    };
    LoginComponent.prototype.togglePassword = function () {
        this.typePassword = !this.typePassword;
    };
    // To check the preferences
    LoginComponent.prototype.checkPrefrences = function (res) {
        this.permissionModules = res.modules.map(function (data) { return data.name; });
        if (this.permissionModules.includes('REPORT')) {
            localStorage.setItem('lastReportUrl', '/reports/system/live-status');
        }
        this.sharedService.setAccessRole(res.modules, this.permissionModules);
        // this.sharedService.getUserRoleId(res.token).subscribe(responseUserRole => {
        //   // localStorage.setItem('responseUserRole', JSON.stringify(responseUserRole));
        //   this.sharedService.getRoleAccessModule(responseUserRole.roleId).subscribe(resPermissionModule => {
        //     localStorage.setItem('resPermissionModule', JSON.stringify(resPermissionModule));
        //     this.permissionModules = resPermissionModule.modules.map(data => data.name);
        //     let permissionModules = JSON.parse(localStorage.getItem('permissionModules'));
        //     this.sharedService.setDefaultUrl(permissionModules);
        //     // this.sharedService.gotPermissions.next(resPermissionModule);
        //   }, error => {
        //     this.sharedService.getErrorMsg(error);
        //     this.disable = false;
        //   });
        // }, error => {
        //   this.sharedService.getErrorMsg(error);
        //   this.disable = false;
        // });
    };
    // To check the user is logged in
    LoginComponent.prototype.checkLogin = function (isValid) {
        var _this = this;
        if (isValid) {
            this.disable = true;
            // call login API to get token
            this.authenticationService.login(this.login).subscribe(function (res) {
                // if (res.mfaType === 0) this.performLogin(res);
                _this.performLogin(res);
                if (res.mfaType === 1) {
                    if (res.status === 401) {
                        var errorCode = res.errorCode;
                        var errorMessage = enMessage[errorCode];
                        _this.responseMessage = { success: false, message: enMessage };
                        _this.sharedService.setResponseMessage(_this.responseMessage);
                        _this.disable = false;
                        _this.verificationReq = false;
                        _this.otp = false;
                    }
                    else {
                        _this.otpCount = 0;
                        _this.resetCount = 0;
                        _this.message = '';
                        _this.verificationReq = true;
                        _this.disable = false;
                        _this.otp = null;
                        _this.tempToken = res.accessToken;
                        if (_this.resendInterval)
                            clearInterval(_this.resendInterval);
                        _this.startResendTimer();
                    }
                }
            }, function (error) {
                _this.disable = false;
                if (error.status == 401) {
                    _this.sharedService.getErrorMsg(error);
                    _this.verificationReq = false;
                    _this.tempToken = null;
                    _this.otp = null;
                    if (_this.resendInterval)
                        clearInterval(_this.resendInterval);
                }
                else {
                    _this.sharedService.getErrorMsg(error);
                }
            });
        }
    };
    LoginComponent.prototype.performLogin = function (res) {
        var _this = this;
        if (res.accessToken) {
            var base64Token = res.accessToken;
            this.authenticationService.setHeaders(base64Token);
            this._cookieService.remove('user');
            this.authenticationService.getLoggedUser().subscribe(function (responseInfo) {
                _this.authenticationService.getProductType(responseInfo.customerId).subscribe(function (type) {
                    responseInfo["productType"] = type.productType;
                    if (responseInfo.status !== 400) {
                        _this.checkPrefrences(responseInfo);
                        _this.getUserPreferences();
                        _this.authenticationService.setLoggedInUser(responseInfo);
                        _this.authenticationService.getUserInfo(responseInfo.userId).subscribe(function (res) {
                            var obj = {
                                app_id: environment.production ? 'yxsvtkcv' : 'wm4a76at',
                                firstName: res.firstName,
                                lastName: res.lastName,
                                emailId: res.emailId,
                                user_id: res.userId,
                                user_hash: responseInfo.userHash,
                                company: {
                                    company_id: responseInfo.customerId,
                                    name: ''
                                }
                            };
                            _this.sharedService.getCustomerInfo(responseInfo.customerId).subscribe(function (res) {
                                obj.company.name = res.companyName;
                                localStorage.setItem('userInfo', JSON.stringify(obj));
                                _this.sharedService.bootUpInterCom();
                            }, function (err) { });
                        });
                    }
                    else {
                        var errorCode = res.errorCode;
                        var errorMessage = enMessage[errorCode];
                        _this.responseMessage = { success: false, message: errorMessage };
                        _this.sharedService.setResponseMessage(_this.responseMessage);
                        _this.disable = false;
                    }
                });
            }, function (error) {
                var errorCode = res.errorCode;
                var errorMessage = enMessage[errorCode];
                _this.responseMessage = { success: false, message: errorMessage };
                _this.sharedService.setResponseMessage(_this.responseMessage);
                _this.disable = false;
            });
        }
        else if (res.status == 401) {
            var errorCode = res.errorCode;
            var errorMessage = enMessage[errorCode];
            this.responseMessage = { success: false, message: enMessage };
            this.sharedService.setResponseMessage(this.responseMessage);
            this.disable = false;
        }
    };
    LoginComponent.prototype.getMfaToken = function (isValid) {
        var _this = this;
        if (isValid) {
            this.disable = true;
            // call MFA token API to get token
            this.authenticationService.getMfaToken(this.tempToken, { verificationCode: this.otp }).subscribe(function (res) {
                // this.tempToken = null;
                _this.performLogin(res);
            }, function (error) {
                _this.disable = false;
                _this.otpCount++;
                if (_this.otpCount >= 4) {
                    _this.verificationReq = false;
                    // this.message = 'Maximum retry count reached.';
                }
                if (error.status == 401) {
                    _this.sharedService.getErrorMsg(error);
                    _this.verificationReq = false;
                    _this.tempToken = null;
                    _this.otp = null;
                    if (_this.resendInterval)
                        clearInterval(_this.resendInterval);
                }
                else {
                    _this.sharedService.getErrorMsg(error);
                }
            });
        }
    };
    LoginComponent.prototype.resetOtp = function () {
        var _this = this;
        if (this.resendInterval)
            clearInterval(this.resendInterval);
        this.resetDisable = true;
        this.resetCount++;
        this.otp = null;
        if (this.resetCount >= 4) {
            this.message = 'Maximum tries to resend verification code reached.';
            this.resetDisable = true;
            this.verificationReq = false;
        }
        else {
            this.authenticationService.resendVerficationCode(this.tempToken).subscribe(function (res) {
                _this.tempToken = res.accessToken;
                _this.startResendTimer();
            }, function (error) {
                _this.resetDisable = false;
                if (error.status == 401) {
                    _this.sharedService.getErrorMsg(error);
                    _this.verificationReq = false;
                    _this.tempToken = null;
                    _this.otp = null;
                }
                else {
                    _this.sharedService.getErrorMsg(error);
                }
            });
        }
    };
    LoginComponent.prototype.startResendTimer = function () {
        var global = this;
        this.resendTimer = 300;
        this.resetDisable = true;
        this.resendInterval = setInterval(function () {
            if (global.resendTimer > 0)
                global.resendTimer--;
            else
                global.resetDisable = false;
        }, 1000);
    };
    // To get user preferences
    LoginComponent.prototype.getUserPreferences = function () {
        var _this = this;
        // Get the User Preferences by making a seperate API call
        this.authenticationService.userPreferences().subscribe(function (responseInfo) {
            _this.sharedService.setDefaultUrl(_this.permissionModules);
            if (responseInfo) {
                localStorage.setItem('preferences', JSON.stringify(responseInfo));
            }
            if (responseInfo.liveHistory || responseInfo.live.header) {
                if (responseInfo.liveHistory && responseInfo.liveHistory.header) {
                    _this.authenticationService.liveHistoryPrefrences(responseInfo.liveHistory.header);
                }
                // if (responseInfo.live && responseInfo.live.header) {
                //   this.authenticationService.livePrefrences(responseInfo.live.header);
                // }
            }
        }, function (error) {
            _this.sharedService.getErrorMsg(error);
            _this.disable = false;
        });
    };
    LoginComponent.prototype.logout = function () {
        window.location.pathname = '/login';
        var token = localStorage.getItem('token');
        // let baseToken = atob(token);
        localStorage.clear();
        sessionStorage.clear();
        this.sharedService.bootDownInterCom();
        this.authenticationService.logout(token).subscribe(function (res) {
        });
        var global = this;
        if (this.router.url == 'login') {
            location.reload(true);
        }
    };
    return LoginComponent;
}());
export { LoginComponent };
