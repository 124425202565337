<!-- BEGIN HEADER -->
<div class="header">
  <app-notify-message></app-notify-message>
  <!-- BEGIN NAVBAR -->
  <nav id="header" class="navbar navbar-expand-lg navbar--bold navbar-light bg-default navbar--bb-1px">
    <!--navbar-inverse bg-dark-->
    <div class="row width100 menuHeightFix">
      <!-- BEGIN LOGO -->
      <div class="menuLogo">
        <a class="navbar-brand" (click)="redirectTo()">
          <img *ngIf="accountKey" style="height: 30px;"
            src="{{cmImageUrl}}{{accountKey}}/assets/img/customer-logo.png?random={{randomNumber}}"
            alt="logo" class="darbySVGLogo"
          />
          <img *ngIf="!accountKey" style="height: 30px;"
            src="{{cmImageUrl}}assets/img/customer-logo.png?random={{randomNumber}}"
            alt="logo" class="darbySVGLogo"
          />
        </a>
      </div>
      <!--END LOGO-->
      <div class="d-inline-block noDis mt-2">
        <!-- BEGIN NAVBAR TOGGLER  -->
        <button class="navbar-toggler hamburger-js hamburger--spring border-0"
          type="button" data-toggle="collapse" data-target="#navbar_main">
          <span #menuClose class="hamburger-box">
            <!-- <span class="hamburger-inner"></span> -->
            <img src="../../../assets/img/bitbrew_menu.png" width="24" height="24">
          </span>
        </button>
        <!-- END NAVBAR TOGGLER  -->
      </div>
      <div class="collapse navbar-collapse align-items-center justify-content-end" id="navbar_main">
        <ul class="navbar-nav">
          <!-- <li *ngIf="localImpersonate=='true'" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <form (ngSubmit)="f.form.valid && stopImpersonate()" #f="ngForm" novalidate class="search-box mt-2 mr-2">
              <div class="container-input">
                <button class="btn btn-peak m-0">Stop Impersonate</button>
              </div>
            </form>
          </li> -->
          <!-- Outer header Items -->
          <li class="nav-item" *ngFor="let header of outerHeader" routerLinkActive="active"
            [ngClass]="{'active-report':window.location.pathname==defaultReportUrl.getItem('lastReportUrl')&&header.permission=='REPORT'}">
            <a class="nav-link ripple"
              [routerLink]="header.route"
              (click)="[subNavHeader('')]"
              *ngIf="
                header.permission!=='REPORT' &&
                header.permission!=='ADMIN' &&
                header.permission!=='SUPPORT-ADMIN' &&
                header.permission!=='ORDER' &&
                header.permission!=='TOOLS' &&
                header.permission!=='DASHBOARD' &&
                header.permission!=='DATA' &&
                header.permission!=='HISTORY' &&
                header.permission!=='DEVICE' &&
                header.permission!=='DATAHUB' &&
                header.permission!=='CONNECTIONS'
              ">
              <i class="fa fa-{{header.icon}} p-0 m-0"></i>
              {{ header.name | translate }}
            </a>
            <!-- Special case header for dynamic routing -->
            <a *ngIf="header.permission==='REPORT'"
              [routerLink]="defaultReportUrl.getItem('lastReportUrl')"
              [class.active]="
                router.isActive('/score', true) ||
                router.isActive('/reports/device/alPlantMode', true) ||
                router.isActive('/reports/device/device-report', true) ||
                router.isActive('/reports/system/not-communicating', true) ||
                router.isActive('/reports/system/version-drilldown', true) ||
                router.isActive('/reports/system/provisioning', true) ||
                router.isActive('/reports/device/usage', true) ||
                router.isActive('/reports/deep-view', true) ||
                router.isActive('/reports/score', true)
              "
              class="nav-link ripple"
              (click)="menuClick(); subNavHeader('')">
              <i class="fa fa-{{header.icon}} p-0 m-0"></i>
              {{header.name | translate}}
            </a>
            <!-- Header with sub routing -->
            <!-- For Admin Header -->
            <a *ngIf="header.permission==='ADMIN'"
              class="nav-link ripple"
              (click)="menuClick(); subNavHeader('')"
              [class.active]="
                router.isActive('/admin/users', true) ||
                router.isActive('/admin/roles', true) ||
                router.isActive('/admin/place', true) ||
                router.isActive('/admin/alerts', true) ||
                router.isActive('/admin/alerts/create-alert', true) ||
                router.isActive('/admin/whitelabel', false) ||
                router.isActive('/admin/developer', false) ||
                router.isActive('/admin/destination', false) ||
                router.isActive('/admin/rules', true) ||
                router.isActive('/admin/audit-trial', true) ||
                router.isActive('/marketplace', true)
              "
              [routerLink]="header.route">
              <i class="fa fa-{{header.icon}} p-0 m-0"></i>
              {{header.name | translate}}
            </a>
            <a *ngIf="header.permission === 'DATA'"
              class="nav-link ripple"
              (click)="subNavHeader('DATA')"
              [class.active]="router.isActive('/data', true) || router.isActive('/data-simulator', true) || router.isActive('/destinations', true) || router.isActive('/destinations/data-schemes', true) || router.isActive('/destinations/replay-data', true)"
              [routerLink]="header.route">
              <i class="fa fa-{{header.icon}} p-0 m-0"></i>
              {{header.name | translate}}
            </a>
            <!-- history tab header -->
            <a *ngIf="header.permission==='HISTORY'"
              class="nav-link ripple"
              (click)="subNavHeader('HISTORY')"
              [class.active]="router.isActive('/location-history', false) || router.isActive('/trips', false)"
              [routerLink]="header.route">
              <i class="fa fa-{{header.icon}} p-0 m-0"></i>
              {{ header.name | translate }}
            </a>
             <!-- device -->
             <a *ngIf="header.permission === 'CONNECTIONS' && (productType === 'EVSE' || productType === 'VIM')"
                class="nav-link ripple"
                (click)="subNavHeader('');"
                [class.active]="router.isActive('/v2/devices', true) || router.isActive('/devices/groups', true) || router.isActive('/devices/tags', true) || router.isActive('/v2/ota', false) || router.isActive('/devices', false)"
                [routerLink]="header.route">
                <i class="fa fa-{{header.icon}} p-0 m-0"></i>
                {{ header.name | translate }}
              </a>
             <a *ngIf="header.permission === 'CONNECTIONS' && productType === 'BITBREW'"
                class="nav-link ripple"
                (click)="subNavHeader('');"
                [class.active]="router.isActive('/devices', true) || router.isActive('/devices/groups', true) || router.isActive('/devices/tags', true) || router.isActive('/ota', false) || router.isActive('/devices', false)"
                [routerLink]="header.route">
                <i class="fa fa-{{header.icon}} p-0 m-0"></i>
                {{ header.name | translate }}
              </a>
              <!-- data hub header -->
              <a *ngIf="header.permission === 'DATAHUB'"
                class="nav-link ripple"
                (click)="subNavHeader('DATAHUB');"
                [class.active]="
                  router.isActive('/location-history', false) ||
                  router.isActive('/location-history?searchBy=&value=', false) ||
                  router.isActive('/trips', false) ||
                  router.isActive('/data/messages', true) ||
                  router.isActive('/data/trips', true) ||
                  router.isActive('/data/location-history', false) ||
                  router.isActive('/data-simulator', true) ||
                  router.isActive('/destinations', false)
                "
                [routerLink]="header.route">
                <i class="fa fa-{{header.icon}} p-0 m-0"></i>
                {{header.name | translate}}
              </a>
            <!-- For Support Admin Header -->
            <a *ngIf="header.permission==='SUPPORT-ADMIN'"
              class="nav-link ripple"
              (click)="subNavHeader('')"
              [class.active]="
                router.isActive('/support-admin/users', true) ||
                router.isActive('/support-admin/roles', true) ||
                router.isActive('/support-admin/customers', true)
              "
              [routerLink]="header.route">
              <i class="fa fa-{{header.icon}} p-0 m-0"></i>
              {{header.name | translate}}
            </a>
            <!-- For Orders Header -->
            <a *ngIf="header.permission==='ORDER'"
              class="nav-link ripple"
              (click)="subNavHeader('')"
              [routerLink]="header.route"
              [class.active]="
                router.isActive('/orders/pending', true) ||
                router.isActive('/orders/processed', true) ||
                router.isActive('/orders/processed/processed-order-details', true) ||
                router.isActive('/orders/replace-pending', true) ||
                router.isActive('/orders/replace-approved', true) ||
                router.isActive('/orders/replace-confirmed', true) ||
                router.isActive('/orders/replace-rejected', true) ||
                router.isActive('/orders/return-pending', true) ||
                router.isActive('/orders/return-confirmed', true) ||
                router.isActive('/orders/return-rejected', true)
              ">
              <i class="fa fa-{{header.icon}} p-0 m-0"></i>
              {{header.name | translate}}
            </a>
            <!-- For Tools Header -->
            <a *ngIf="header.permission==='TOOLS'"
              class="nav-link ripple"
              (click)="subNavHeader('')"
              [routerLink]="header.route"
              [class.active]="
                router.isActive('/tools/cache', true) ||
                router.isActive('/tools/republish', true)
              ">
              <i class="fa fa-{{header.icon}} p-0 m-0"></i>
              {{header.name | translate}}
            </a>
            <!-- For Dashboard Header -->
            <a *ngIf="header.permission==='DASHBOARD'"
              class="nav-link ripple"
              (click)="subNavHeader('DASHBOARD')"
              [routerLink]="header.route"
              [class.active]="
              router.isActive('/dashboard/ev', true) ||
              router.isActive('/dashboard/insurance-report', true) ||
              router.isActive('/dashboard/fleet-report', true) ||
              router.isActive('/dashboard/default', true) ||
              router.isActive('/dashboard/azuga', true) ||
              router.isActive('/dashboard/system-dashboard', true) ||
              router.isActive('/dashboard/al-dashboard', true)
              ">
              <i class="fa fa-{{header.icon}} p-0 m-0"></i>
              {{header.name | translate}}
            </a>
          </li>
          <!-- Dropdown header Items -->
          <li class="nav-item dropdown" *ngIf="dropdownHeader.length">
            <a class="nav-link dropdown-toggle"
              id="dropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              href="javascript:void(0)"
              data-hover="dropdown">
              More
              <i class="fa fa-angle-down mr-0"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right dropdown-scale" aria-labelledby="dropdownMenuButton">
              <span *ngFor="let header of dropdownHeader">
                <a class="dropdown-item"
                  (click)="menuClick();"
                  routerLinkActive="active"
                  [routerLink]="header.route"
                  *ngIf="
                    header.permission!=='ADMIN' &&
                    header.permission!=='SUPPORT-ADMIN' &&
                    header.permission!=='ORDER' &&
                    header.permission!=='TOOLS'
                  "
                  >
                  <i class="fa fa-{{header.icon}}"></i>
                  {{header.name | translate}}
                </a>
                <!-- Header with sub routing -->
                <!-- For Admin Header -->
                <a *ngIf="header.permission==='ADMIN'"
                  class="dropdown-item"
                  (click)="menuClick();"
                  [routerLink]="header.route"
                  [class.active]="
                    router.isActive('/admin/users', true) ||
                    router.isActive('/admin/roles', true) ||
                    router.isActive('/admin/place', true) ||
                    router.isActive('/admin/alerts', true) ||
                    router.isActive('/admin/alerts/create-alert', true) ||
                    router.isActive('/admin/whitelabel', false) ||
                    router.isActive('/admin/developer', false) ||
                    router.isActive('/admin/destination', false) ||
                    router.isActive('/admin/rules', true) ||
                    router.isActive('/admin/audit-trial', true)
                  ">
                  <i class="fa fa-{{header.icon}}"></i>
                  {{header.name | translate}}
                </a>
                <!-- For Support Admin Header -->
                <a *ngIf="header.permission==='SUPPORT-ADMIN'"
                  class="dropdown-item"
                  (click)="menuClick();"
                  [routerLink]="header.route"
                  [class.active]="
                    router.isActive('/support-admin/users', false) ||
                    router.isActive('/support-admin/roles', false) ||
                    router.isActive('/support-admin/customers', false)
                  ">
                  <i class="fa fa-{{header.icon}} p-0 m-0"></i>
                  {{header.name | translate}}
                </a>
                <!-- For Orders Header -->
                <a *ngIf="header.permission==='ORDER'"
                  class="dropdown-item"
                  (click)="menuClick();"
                  [routerLink]="header.route"
                  [class.active]="
                    router.isActive('/orders/pending', true) ||
                    router.isActive('/orders/processed', true) ||
                    router.isActive('/orders/processed/processed-order-details', true) ||
                    router.isActive('/orders/replace-pending', true) ||
                    router.isActive('/orders/replace-approved', true) ||
                    router.isActive('/orders/replace-confirmed', true) ||
                    router.isActive('/orders/replace-rejected', true) ||
                    router.isActive('/orders/return-pending', true) ||
                    router.isActive('/orders/return-confirmed', true) ||
                    router.isActive('/orders/return-rejected', true)
                  ">
                  <i class="fa fa-{{header.icon}}"></i>
                  {{header.name | translate}}
                </a>
                <!-- For Tools Header -->
                <a *ngIf="header.permission==='TOOLS'"
                  class="dropdown-item"
                  (click)="menuClick();"
                  [routerLink]="header.route"
                  [class.active]="
                    router.isActive('/tools/cache', true) ||
                    router.isActive('/tools/republish', true)
                  ">
                  <i class="fa fa-{{header.icon}}"></i>
                  {{header.name | translate}}
                </a>
              </span>
            </div>
          </li>

          <!-- <li class="nav-item" *ngIf="permissionModules?.includes('LIVE') && !unoHeader" routerLinkActive="active">
            <a (click)="menuClick();" routerLink="/live" class="nav-link ripple">
              <i class="fa fa-map-marker p-0 m-0"></i>
              {{'menu.LIVE' | translate}}
            </a>
          </li> -->
          <!-- <li (click)="getDashboards();" [class.active]="router.isActive('/dashboard', false)"
              class="nav-item" *ngIf="permissionModules?.includes('DASHBOARD') && !router.url.includes('/dashboard/default')"
              routerLinkActive="active">
            <a routerLink="/dashboard/default" class="nav-link ripple">
              <i class="fa fa-th-large p-0 m-0"></i>
              {{'menu.DASHBOARD' | translate}}
            </a>
          </li> -->
          <!-- <li [class.active]="router.isActive('/dashboard', false)" class="dropdown nav-item dashboard-dropdown right-align"
              *ngIf="permissionModules?.includes('DASHBOARD') && router.url.includes('/dashboard/default')" routerLinkActive="active">
            <a (click)="getDashboards();" class="nav-link dropdown-toggle" id="dashboardMenuButton"
               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" href="javascript:void(0)"
               data-hover="dropdown">
              <i class="fa fa-th-large p-0 m-0"></i>
              {{'menu.DASHBOARD' | translate}}
              <i *ngIf="localStorage.getItem('dashboards') && dashboards.length" class="fa fa-angle-down mr-0"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right dropdown-scale" aria-labelledby="dropdownMenuButton">
              <a *ngFor="let data of dashboards" class="dropdown-item ripple" (click)="dashboardClick(data);">
                <i class="fa fa-th-large p-0 "></i>
                {{data.dashboardName}}
              </a>
            </div>
          </li> -->
          <!-- <li class="nav-item" *ngIf="permissionModules?.includes('TRIPS');" routerLinkActive="active">
            <a (click)="menuClick();" routerLink="/trips" class="nav-link ripple">
              <i class="fa fa-map-o p-0 m-0"></i>
              {{'menu.TRIPS' | translate}}
            </a>
          </li> -->
          <!-- <li class="nav-item" *ngIf="permissionModules?.includes('DATA');" routerLinkActive="active">
            <a (click)="menuClick();" routerLink="/data" class="nav-link ripple">
              <i class="fa fa-flask p-0 m-0"></i>
              {{'menu.DATA' | translate}}
            </a>
          </li> -->
          <!-- <li class="nav-item" *ngIf="permissionModules?.includes('LIVE') && unoHeader" routerLinkActive="active">
            <a (click)="menuClick();" routerLink="/live" class="nav-link ripple">
              <i class="fa fa-map-marker p-0 m-0"></i>
              {{'menu.TRACK' | translate}}
            </a>
          </li> -->
          <!-- <li [ngClass]="{'active-report':window.location.pathname ==defaultReportUrl.getItem('lastReportUrl') }"
            class="nav-item" *ngIf="permissionModules?.includes('REPORT');" routerLinkActive="active"
            [class.active]="router.isActive('/reports/device/device-report', true) || router.isActive('/reports/system/not-communicating', true) || router.isActive('/reports/system/version-drilldown', true) || router.isActive('/reports/system/provisioning', true) || router.isActive('/reports/device/usage', true) || router.isActive('/reports/messages', true)">
            <a (click)="menuClick();" [routerLink]="defaultReportUrl.getItem('lastReportUrl')" class="nav-link ripple">
              <i class="fa fa-bar-chart p-0 m-0"></i>
              {{'menu.REPORTS' | translate}}
            </a>
          </li> -->
          <!-- <li class="nav-item" *ngIf="permissionModules?.includes('SCORE');" routerLinkActive="active">
            <a (click)="menuClick();" routerLink="/score" class="nav-link ripple">
              <i class="fa fa-line-chart"></i>
              {{'menu.SCORE' | translate}}
            </a>
          </li> -->
          <!-- <li class="nav-item" *ngIf="permissionModules?.includes('OTA');" routerLinkActive="active">
            <a (click)="menuClick();" routerLink="/ota" class="nav-link ripple">
              <i class="fa fa-flash p-0 m-0"></i>
              {{'menu.OTA' | translate}}
            </a>
          </li> -->
          <!-- <li class="nav-item" *ngIf="permissionModules?.includes('PODS-STATUS');" routerLinkActive="active">
            <a (click)="menuClick();" routerLink="/pods" class="nav-link ripple">
              <i class="fa fa-flash p-0 m-0"></i>
              {{'menu.PODS' | translate}}
            </a>
          </li> -->
          <!-- <li class="nav-item" *ngIf="permissionModules?.includes('BENCH-TEST');" routerLinkActive="active">
            <a (click)="menuClick();" routerLink="/bench-test" class="nav-link ripple">
              <i class="fa fa-check-circle p-0 m-0"></i>
              {{'menu.BENCHTEST' | translate}}
            </a>
          </li> -->
          <!-- <li class="nav-item" *ngIf="permissionModules?.includes('TOOLS');" [class.active]="router.isActive('/tools/cache', true) || router.isActive('/tools/republish', true)">
            <a (click)="menuClick();" routerLink="/tools/cache" class="nav-link ripple">
              <i class="fa fa-wrench p-0 m-0"></i>
              {{'menu.TOOLS' | translate}}
            </a>
          </li> -->
          <!-- <li [class.active]="router.isActive('/orders/pending', true) || router.isActive('/orders/processed', true)" class="nav-item" *ngIf="permissionModules?.includes('ORDER');">
            <a (click)="menuClick();" [routerLink]="defaultOrdersUrl" class="nav-link ripple">
              <i class="fa fa-truck" aria-hidden="true"></i>
              {{'menu.ORDERS' | translate}}
            </a>
          </li> -->
          <!-- <li class="nav-item" *ngIf="permissionModules?.includes('ADMIN');"
              [class.active]="router.isActive('/admin/vehicles', false) || router.isActive('/admin/developer', false) || router.isActive('/admin/users', true) || router.isActive('/admin/roles', true) || router.isActive('/admin/settings', true) || router.isActive('/admin/alerts', true) || router.isActive('/admin/alerts/create-alert', true) || router.isActive('/admin/place', true)">
            <a (click)="menuClick();" [routerLink]="defaultAdminUrl" class="nav-link ripple">
              <i class="fa fa-user-circle-o p-0 m-0"></i>
              {{'menu.ADMIN' | translate}}
            </a>
          </li> -->
          <!-- <li class="nav-item" *ngIf="permissionModules?.includes('SUPPORT-OTA');" routerLinkActive="active">
            <a (click)="menuClick();" routerLink="/support-ota" class="nav-link ripple">
              <i class="fa fa-flash p-0 m-0"></i>
              {{'menu.OTA' | translate}}
            </a>
          </li> -->
          <!-- <li class="nav-item" *ngIf="permissionModules?.includes('SUPPORT');" routerLinkActive="active">
            <a (click)="menuClick();" routerLink="/support" class="nav-link ripple">
              <i class="fa fa-headphones p-0 m-0"></i>
              {{'menu.SUPPORT' | translate}}
            </a>
          </li> -->
          <!-- <li class="nav-item" *ngIf="permissionModules?.includes('CUSTOMER');" routerLinkActive="active">
            <a (click)="menuClick();" routerLink="/customer" class="nav-link ripple">
              <i class="fa fa-headphones p-0 m-0"></i>
              {{'menu.CUSTOMER' | translate}}
            </a>
          </li> -->
          <!-- <li class="nav-item" *ngIf="permissionModules?.includes('CONFIGURATION');" routerLinkActive="active">
            <a (click)="menuClick();" routerLink="/configurations" class="nav-link ripple">
              <i class="fa fa- p-0 m-0"></i>
              {{'menu.CONFIGURATIONS' | translate}}
            </a>
          </li> -->


          <!--notification icon-->
          <!-- <li class="nav-item relative" id="intercom"> -->
            <!-- menuClick(), readNotifications(), subNavHeader('') routerLink="/profile/notifications" -->
            <!-- <a (click)="[initiateIntercom()]" class="nav-link ripple" [class.active]="interCom"> -->
              <!-- <i class="fa fa-bell p-0 m-0"></i> -->
              <!-- <i class="fa fa-intercom"></i> -->
              <!-- <img src="../../../assets/img/intercom.svg" style="width: 16px;"> -->
              <!-- <svg aria-hidden="true" viewBox="0 0 28 32" width="28" height="32" [class.active]="interCom">
                <path
                  d="M28,32 C28,32 23.2863266,30.1450667 19.4727818,28.6592 L3.43749107,28.6592 C1.53921989,28.6592 0,27.0272 0,25.0144 L0,3.6448 C0,1.632 1.53921989,0 3.43749107,0 L24.5615088,0 C26.45978,0 27.9989999,1.632 27.9989999,3.6448 L27.9989999,22.0490667 L28,22.0490667 L28,32 Z M23.8614088,20.0181333 C23.5309223,19.6105242 22.9540812,19.5633836 22.5692242,19.9125333 C22.5392199,19.9392 19.5537934,22.5941333 13.9989999,22.5941333 C8.51321617,22.5941333 5.48178311,19.9584 5.4277754,19.9104 C5.04295119,19.5629428 4.46760991,19.6105095 4.13759108,20.0170667 C3.97913051,20.2124916 3.9004494,20.4673395 3.91904357,20.7249415 C3.93763774,20.9825435 4.05196575,21.2215447 4.23660523,21.3888 C4.37862552,21.5168 7.77411059,24.5386667 13.9989999,24.5386667 C20.2248893,24.5386667 23.6203743,21.5168 23.7623946,21.3888 C23.9467342,21.2215726 24.0608642,20.9827905 24.0794539,20.7254507 C24.0980436,20.4681109 24.0195551,20.2135019 23.8614088,20.0181333 Z"
                  fill="#3E4B5B">
                </path>
              </svg> -->
              <!-- <small class="notification-icon" *ngIf="unreadCount>0">{{unreadCount}}</small> -->
              <!-- &nbsp; -->
            <!-- </a> -->
          <!-- </li> -->
          <!--help icon-->
          <!-- <li class="nav-item relative" routerLinkActive="active">
            <a class="nav-link ripple" href="https://developer.drivewithdarby.com" target="_blank">
              <i class="fa fa-question-circle-o p-0 m-0" style="font-size: 16px;"></i>
            </a>
          </li> -->
          <li class="dashboard-dropdown dropdown nav-item">
            <a class="dropdown-toggle nav-link has-badge ripple pdlr-2" id="dropdownMenuButton" href="javascript:void(0)"
              data-toggle="dropdown" aria-haspopup="true" data-hover="dropdown" aria-expanded="false">
              <span class="dropdown-text ">
                <i class="fa fa-lg fa-question-circle-o p-0 m-0"></i>
              </span>
            </a>
            <div class="dropdown-menu dropdown-menu-right dropdown-scale" aria-labelledby="dropdownMenuButton">
              <a href="https://docs.bitbrew.com" target="_blanks" class="dropdown-item ripple">
                Documentation
              </a>
              <!--<div class="dropdown-divider" role="presentation"></div>-->
              <a role="button" target="_blanks" class="dropdown-item ripple" (click)="initiateIntercom();" id="intercom">
                Help
                <!-- <small [tooltip]="'Intercom Unread Messages'" class="notification-icon" *ngIf="unreadCount>0">{{ unreadCount }}</small> -->
              </a>
              <a href="https://status.bitbrew.com" target="_blanks" class="dropdown-item ripple">
                Health
              </a>
            </div>
          </li>
          <!-- profile dropdown div starts -->
          <li class="dashboard-dropdown dropdown nav-item ml-md-2">
            <a class="dropdown-toggle nav-link has-badge ripple text-center"
              [routerLink]="'/profile'"
              [class.active]="
                router.isActive('/profile', true)
              "
              (click)="subNavHeader('DROPDOWN');"
              id="dropdownMenuButton" href="javascript:void(0)"
              data-toggle="dropdown" aria-haspopup="true"
              data-hover="dropdown" aria-expanded="false">
              <span class="dropdown-text strong-600">
                <img id="headerProfilePic" class="rounded-circle profile-pic" alt="" 
                src="{{cmImageUrl}}{{currentUser.userId}}/assets/img/profile-pic.png?random={{profilePicRandomNumber}}" 
                onerror="this.src='../../../../assets/img/user.png'">
                {{currentUser?.firstName}}
              </span>
            </a>
            <div class="dropdown-menu dropdown-menu-right dropdown-scale" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item ripple" (click)="menuClick(); subNavHeader('');" routerLink="/profile" href="javascript:void(0)">
                <i class="fa fa-user darbyColor"></i>Profile
              </a>
              <!--<div class="dropdown-divider" role="presentation"></div>-->
              <a class="dropdown-item" style="cursor : pointer" (click)="logout()">
                <i class="fa fa-power-off darbyColor"></i>Logoff
              </a>
            </div>
          </li>
          <!-- profile dropdown div ends -->
          <!-- Stop Impersonate Button -->
          <li *ngIf="localImpersonate=='true'" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <form (ngSubmit)="f.form.valid && stopImpersonate()" #f="ngForm" novalidate class="search-box impersonate-btn">
              <div class="container-input">
                <button tooltip="Stop Impersonating." class="btn btn-peak btn-sm m-0">Stop</button>
              </div>
            </form>
          </li>
          <!-- Stop Impersonate end -->
        </ul>
      </div>
    </div>
  </nav>





  <div class="notification-container" *ngIf="notifications.length && alertMessage">
    <div [id]="notifications[0].type"
         class="alert alert-success alert-dismissible fade show notification-banner row justify-content-md-center"
         role="alert">
      <p class="mb-0 col-12 col-lg-3 heading">{{notifications[0].title}}</p>
      <p class="mb-0 col-12 col-lg-7 content">{{notifications[0].body}}</p>
      <button type="button" class="close" data-dismiss="alert" aria-label="Close"
              (click)="closeUserNotification(notifications[0].userNotificationId)">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
</div>
<!-- <app-data-tab-header [moduleName]="'moduleName'" [subTitleName]="'subTitle'" *ngIf="subTabHeader"></app-data-tab-header> -->
