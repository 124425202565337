import { Component, OnInit } from '@angular/core';

import { SharedService } from '@shared/shared.service';
import { Title } from '@angular/platform-browser';
import { environment } from '@myenv/environment';
import { configMessage } from '@shared/config-message';
import { GraphicReportsService } from '@components/graphic-reports/graphic-reports.service';


@Component({
  selector: 'app-al-plant-mode-report',
  templateUrl: './al-plant-mode-report.component.html',
  styleUrls: ['./al-plant-mode-report.component.scss']
})
export class AlPlantModeReportComponent implements OnInit {
  public apiUrl = environment.apiUrl;
  reportsMenu: any = [];
  currentReport: any = {};
  isLoading = false;
  public columMenu: any = [];
  public reportId: string = null;
  public report: any = [];
  disable = false;
  displayName: string;
  permissionModules = [];
  modules = [];
  permissionAuthorities: any = {};
  moduleName = 'REPORT';
  constructor(
    private reportsService: GraphicReportsService,
    private sharedService: SharedService,
    private title: Title
  ) {
  }

  ngOnInit() {
    this.modules = JSON.parse(localStorage.getItem('accessRoleModules'));
    this.permissionAuthorities = this.modules.find(module => module.name === this.moduleName);
    this.permissionModules = JSON.parse(localStorage.getItem('permissionModules'));
    this.title.setTitle('Reports' + environment.title_text);
    this.setReport();
  }

  getReport(report) {
    this.currentReport = report;
    this.setReport();
  }

  setReport() {
    if (this.currentReport && this.currentReport.reportId) {
      localStorage.setItem('currentReport', JSON.stringify(this.currentReport));
      this.report.reportId = this.currentReport.reportId;
    } else {
      if (JSON.parse(localStorage.getItem('currentReport'))) {
        const localReport = JSON.parse(localStorage.getItem('currentReport'));
        if (localReport.downloadable) {
          this.currentReport = localReport;
          this.report.reportId = localReport.reportId;
        } else {
          const reports = JSON.parse(localStorage.getItem('reports'));
          const global = this;
          reports.forEach(item => {
            if (item.downloadable && !global.currentReport.reportId) {
              global.currentReport = item;
            }
          });
          localStorage.setItem('currentReport', JSON.stringify(this.currentReport));
        }
      }
    }
    this.columMenu = this.currentReport.columns;
    this.title.setTitle(this.currentReport.name + environment.title_text);
  }

  // To download report as a CSV File
  downloadReportCSV() {
    this.disable = true;
    this.reportsService.downloadalPlantModeReport(this.currentReport.reportId).subscribe(res => {
      const win = window.open(this.apiUrl + 'reports/alPlantMode/download?access_key=' + res.token, '_blank');
      win.focus();
      this.disable = false;
    }, error => {
      this.sharedService.getErrorMsg(error);
      this.disable = false;
    });
  }

}
