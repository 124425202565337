import { ElementRef } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
var MyRenderer = /** @class */ (function () {
    function MyRenderer(platformId) {
        this.platformId = platformId;
    }
    MyRenderer.prototype.invokeElementMethod = function (eleRef, method) {
        if (isPlatformBrowser(this.platformId)) {
            eleRef.nativeElement[method]();
        }
    };
    return MyRenderer;
}());
export { MyRenderer };
