<div class="col-12 relative br-right nopadding" id="drawerContainer" style="height: inherit;">
    <hr style="margin: 0;">
    <ul class="nav nav-tabs detailed-tabs" *ngIf="!noDevices">
        <li class="nav-item">
            <a class="nav-link py-2" [class.active]="tabPos === 0" (click)="tabPos = 0; renderGraphOnTab();" id="all"
                role="tab" data-toggle="tab" href="#allConstraintss">
                <i class="fa fa-line-chart" aria-hidden="true"></i>
                PID's
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link py-2" id="gpst" [class.active]="tabPos === 1" (click)="tabPos = 1; renderGraphOnTab();"
                role="tab" href="#gps" data-toggle="tab">
                <i class="fa fa-map-marker" aria-hidden="true"></i>
                GPS
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link py-2" role="tab" [class.active]="tabPos === 2" (click)="tabPos = 2; renderGraphOnTab();" role="tab"
                href="#dashcam" data-toggle="tab">
                <i class="fa fa-video-camera" aria-hidden="true"></i>
                Videos <span *ngIf="videoCount >= 0">({{ videoCount }})</span>
            </a>
        </li>
    </ul>
    <span class="di infoIcon" *ngIf="!noDevices">
        <i class="fa fa-info-circle"
            [tooltip]="tabPos === 0 ? 'Select either one or two PIDs to Visualize' : tabPos === 1 ? 'Select either one or two Parameters to Visualize' : 'Select any one video to play'"
            aria-hidden="true"></i>
    </span>
    <div class="input-group input-group-lg searchVeh liveSearch" *ngIf="tabPos === 0">
        <div class="select-container filter-search relative live-page" style="width: 40%">
            <ng-select [clearable]="false" [items]="searchheader" bindLabel="displayName" bindValue="name"
                [multiple]="false" [closeOnSelect]="true" [(ngModel)]="searchModel.searchBy"
                (change)="searchModel.value=''; revertHiddenPids();" name="searchType" #searchType="ngModel" required
                [searchable]="true" [clearable]="false" placeholder="Select Type">
            </ng-select>
        </div>
        <div class="filter-search input-box">
            <input type="text" name="queryString" [(ngModel)]="searchModel.value" id="search" (keyup)="onSearch();"
                class="form-control search-border" placeholder="Search" #inputElement autocomplete="off"
                style="display: inline-flex; border-left: 1px solid rgba(0, 0, 0, 0.3); height: 36px;" />
            <i class="fa fa-search obd-search" style="top: unset !important;" aria-hidden="true"></i>
        </div>
    </div>
    <div class="" *ngIf="tabPos === 0 && !onSearchNoDF" style="overflow: auto; height: auto !important;" id="pids">
        <ngx-datatable #pidTable *ngIf="filteredData.length"
            class="bootstrap alertTable default-background data-table active-table reportsTable wrapcell trips-obd"
            [rows]="filteredData" [id]="'pidTable'" [loadingIndicator]="'true'" [columnMode]="'force'"
            [headerHeight]="30" [scrollbarH]="true" [scrollbarV]="false" [rowHeight]="'auto'" [selected]="selected"
            [selectionType]="'multiClick'" (select)="activateTablePid($event)">
            <ngx-datatable-column [resizeable]="false" [sortable]="false" name="Icon" prop="icon" [width]="50">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <img class="iconImg" *ngIf="row.icon" [src]="'../../../assets/img/OBD_Icons/' + row.icon">
                    <img class="iconImg" *ngIf="!row.icon" [src]="'../../../assets/img/OBD_Icons/generic_pid.png'">
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" [sortable]="true" name="Description" [width]="110">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.description }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" [sortable]="true" [prop]="'pid'" name="PID #" [width]="67">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.pid }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" [sortable]="true" name="Supported" [width]="105">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <i *ngIf="row.supported" class="fa fa-check fa-lg icon-itag-g" aria-hidden="true"></i>
                    <i *ngIf="!row.supported" class="fa fa-times fa-lg icon-itag-r" aria-hidden="true"></i>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" [sortable]="true" name="Collected" [width]="110">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <i *ngIf="row.collected" class="fa fa-check fa-lg icon-itag-g" aria-hidden="true"></i>
                    <i *ngIf="!row.collected" class="fa fa-times fa-lg icon-itag-r" aria-hidden="true"></i>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
        <div class="text-center loader" style="margin-top: 2rem;"
            [ngStyle]="loading.getSupported ? { 'display': 'block'} : {'display': 'none'}">
            <img src="../../../assets/img/darby_loader_small.gif" />
        </div>
    </div>
    <div class="" *ngIf="tabPos === 0 && onSearchNoDF" style="overflow: auto; height: inherit;" id="pids">
        <!-- <img width="170" src="../../../assets/img/info_nodata_256.png"/> -->
        <h6 class="" style="text-align: center; margin-top: 1rem;">No data to show<br></h6>
    </div>
    <div class="info"
        *ngIf="noDevices">
        <img width="170" src="../../../assets/img/info_nodata_256.png" />
        <h5 class="" style="text-align: center;">No data to show<br>
        </h5>
    </div>
    <div role="tabpanel" *ngIf="tabPos === 1" class="tab-pane fade" [class.show]="tabPos === 1"
        [class.active]="tabPos === 1" id="gps" style="overflow: auto; height: inherit;">
        <ul #gpsTab class="reports-container pl-0" [ngStyle]="!disablePointerEvents ? { 'display': 'block'} : {'display': 'none'}">
            <div class="di pid-box">
                <li class="pid-list green-border" (click)="activeGPSHndlr('ALTITUDE')">
                    <div class="pid-box" [class.activePid]="activeGPS.includes('ALTITUDE')">
                        <div class="iconDiv">
                            <img class="iconImg" src="{{ iconPath + 'gps.png' }}">
                        </div>
                        <span class="pid-description">Altitude</span>
                    </div>
                </li>
            </div>
            <div class="di pid-box">
                <li class="pid-list green-border" (click)="activeGPSHndlr('COG')">
                    <div class="pid-box" [class.activePid]="activeGPS.includes('COG')">
                        <div class="iconDiv">
                            <img class="iconImg" src="{{ iconPath + 'gps.png' }}">
                        </div>
                        <span class="pid-description">COG (Course Over Ground)</span>
                    </div>
                </li>
            </div>
            <div class="di pid-box">
                <li class="pid-list green-border" (click)="activeGPSHndlr('GPS')">
                    <div class="pid-box" [class.activePid]="activeGPS.includes('GPS')">
                        <div class="iconDiv">
                            <img class="iconImg" src="{{ iconPath + 'gps.png' }}">
                        </div>
                        <span class="pid-description">GPS Speed</span>
                    </div>
                </li>
            </div>
            <div class="di pid-box">
                <li class="pid-list green-border" (click)="activeGPSHndlr('HDOP')">
                    <div class="pid-box" [class.activePid]="activeGPS.includes('HDOP')">
                        <div class="iconDiv">
                            <img class="iconImg" src="{{ iconPath + 'gps.png' }}">
                        </div>
                        <span class="pid-description">HDOP (Horizontal Dilution of Precision)</span>
                    </div>
                </li>
            </div>
            <div class="di pid-box">
                <li class="pid-list green-border" (click)="activeGPSHndlr('NOS')">
                    <div class="pid-box" [class.activePid]="activeGPS.includes('NOS')">
                        <div class="iconDiv">
                            <img class="iconImg" src="{{ iconPath + 'gps.png' }}">
                        </div>
                        <span class="pid-description">NOS (Number of Satellites)</span>
                    </div>
                </li>
            </div>
            <div class="di pid-box">
                <li class="pid-list green-border" (click)="activeGPSHndlr('SOG')">
                    <div class="pid-box" [class.activePid]="activeGPS.includes('SOG')">
                        <div class="iconDiv">
                            <img class="iconImg" src="{{ iconPath + 'gps.png' }}">
                        </div>
                        <span class="pid-description">SOG (Speed Over Ground)</span>
                    </div>
                </li>
            </div>
        </ul>
        <div class="text-center loader" style="margin-top: 2rem;"
            [ngStyle]="disablePointerEvents ? { 'display': 'block'} : {'display': 'none'}">
            <img src="../../../assets/img/darby_loader_small.gif" />
        </div>
    </div>
    <div role="tabpanel" class="tab-pane fade" id="cam" *ngIf="tabPos === 2" [class.show]="tabPos === 2" style="overflow: hidden;"
        [class.active]="tabPos === 2">
        <div class="cambtn">
            <button class="btn alertBtn small-screen-btn mr-2 ft-075"
                (click)="dashCamPos = 0; loadFirstVideo(dashCamPos);" [ngClass]="{'btnsel': dashCamPos === 0}">Front
                <span>({{ frontCamCount }})</span>
            </button>
            <button class="btn alertBtn small-screen-btn mr-2 ft-075"
                (click)="dashCamPos = 1; loadFirstVideo(dashCamPos);" [ngClass]="{'btnsel': dashCamPos === 1}">Rear
                <span>({{ backCamCount }})</span>
            </button>
            <button class="btn alertBtn small-screen-btn mr-2 ft-075"
                (click)="dashCamPos = 2; loadFirstVideo(dashCamPos);" [ngClass]="{'btnsel': dashCamPos === 2}">In Cabin
                <span>({{ inCabinCount }})</span>
            </button>
        </div>
        <div class="vidblk">
            <div class="vidscrl">
                <ng-container *ngFor="let cam of dashCamArr; trackBy:getId">
                    <div class="vid" *ngIf="dashCamPos === 0 && cam.camType === 'FRONT'"
                        [class.activeVid]="cam.messageId === selVid.msgId" (click)="onVideoClick(cam);">
                        <div class="row no-gutters">
                            <div class="col-md-2 nopadding border-right">
                                <div class="vidDiv">
                                    <video width="60" height="25" playsinline>
                                        <source [src]="cam.videoURL">
                                    </video>
                                </div>
                            </div>
                            <div class="col-md-10">
                                <div class="title" style="display: flex;justify-content: space-between;">
                                    <div class="">
                                        <span class="text-blue-grey font-14">{{ getDashcamTitle(cam.eventName) |
                                            titlecase }}</span>
                                    </div>
                                    <!-- <span class="camType">Front</span> -->
                                    <!-- <div >
                                                    <small> {{ sharedService.dateToTimeAgo((cam.eventTime)) }} </small>
                                                </div> -->
                                    <div>
                                        <a [href]="cam.videoURL+'.mp4'" style="color: #459824;">
                                            <i tooltip="Download" placement="top" class="fa fa-cloud-download">
                                                <span class="mob-access-text d-none">&nbsp;Download</span>
                                            </i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="title"> -->
                        <!-- <div class=""> -->
                        <!-- <span class="">{{ getDashcamTitle(cam.eventName) | titlecase }}</span> -->
                        <!-- </div> -->
                        <!-- <span class="camType">{{ cam.camType === 'FRONT' ? 'Front' : 'Back' }}</span> -->
                        <!-- <br> -->
                        <!-- <div class="" style="margin: 6px 0;">
                                            <p>Initial Speed: {{ cam.initSpeed ? cam.initSpeed : '--' }}</p>
                                            <p>Final Speed: {{ cam.FinalSpeed ? cam.FinalSpeed : '--'}}</p>
                                            <p>Max Braking: {{ cam.maxBraking ? cam.maxBraking : '--'}}</p>
                                        </div> -->
                        <!-- </div> -->
                    </div>
                    <div class="vid" *ngIf="dashCamPos === 1 && cam.camType === 'BACK'"
                        [class.activeVid]="cam.messageId === selVid.msgId" (click)="onVideoClick(cam);">
                        <div class="row no-gutters">
                            <div class="col-md-2 nopadding border-right">
                                <div class="vidDiv">
                                    <video width="60" height="25" playsinline>
                                        <source [src]="cam.videoURL">
                                    </video>
                                </div>
                            </div>
                            <div class="col-md-10">
                                <div class="title" style="display: flex;justify-content: space-between;">
                                    <div class="">
                                        <span class="text-blue-grey font-14">{{ getDashcamTitle(cam.eventName) |
                                            titlecase }}</span>
                                    </div>
                                    <!-- <span class="camType">Rear</span> -->
                                    <!-- <div >
                                                    <small>{{ 'Event Ago:' + cam.eventTime }}</small>
                                                </div> -->
                                    <div>
                                        <a [href]="cam.videoURL+'.mp4'" style="color: #459824;">
                                            <i tooltip="Download" placement="top" class="fa fa-cloud-download">
                                                <span class="mob-access-text d-none">&nbsp;Download</span>
                                            </i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="vid" *ngIf="dashCamPos === 2 && cam.camType === 'INCABIN'"
                        [class.activeVid]="cam.messageId === selVid.msgId" (click)="onVideoClick(cam);">
                        <div class="row no-gutters">
                            <div class="col-md-2 nopadding border-right">
                                <div class="vidDiv">
                                    <video width="60" height="25" playsinline>
                                        <source [src]="cam.videoURL">
                                    </video>
                                </div>
                            </div>
                            <div class="col-md-10">
                                <div class="title" style="display: flex;justify-content: space-between;">
                                    <div class="">
                                        <span class="text-blue-grey font-14">{{ getDashcamTitle(cam.eventName) |
                                            titlecase }}</span>
                                    </div>
                                    <!-- <span class="camType">In Cabin</span> -->
                                    <!-- <div >
                                                    <small>{{ 'Event Ago:' + cam.eventTime }}</small>
                                                </div> -->
                                    <div>
                                        <a [href]="cam.videoURL+'.mp4'" style="color: #459824;">
                                            <i tooltip="Download" placement="top" class="fa fa-cloud-download">
                                                <span class="mob-access-text d-none">&nbsp;Download</span>
                                            </i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class="text-md-center" *ngIf="(!this.loading.graph && !this.dashCamVideoAvailable)">
                    <img width="170" src="../../../assets/img/info_nodata_256.png" />
                    <h5 class="text-center nodata">
                        No Videos to Show
                    </h5>
                </div>
                <div class="text-center loader" style="margin-top: 2rem;"
                    [ngStyle]="{'display': ((loading.graph) ? 'block' : 'none')}">
                    <img src="../../../assets/img/darby_loader_small.gif" />
                </div>
            </div>
        </div>
    </div>
</div>