<!-- wrapper starts -->
<app-notify-message></app-notify-message>
<div class="container-fluid wrapper">
  <div class="form-container col-lg-8 p-4">
    <!-- begin login-header -->
    <div class="loginHeader my-4">
      <div class="logo-container">
        <img src="{{cmImageUrl}}assets/img/login-logo.png" alt="logo">
      </div>
    </div>
    <!-- end login-header -->

    <!-- login form starts -->
    <form *ngIf="!verificationReq"
      action="javascript:void(0);"
      method="POST" #f="ngForm"
      (ngSubmit)="checkLogin(f.valid)"
      novalidate>
      <h3 class="loginC text-center">Login</h3>
      <div class="loginAlert container">
        <small class="text-danger text-center">{{message}}</small>
      </div>
      <!-- username div starts -->
      <div class="sm-form-design form-field">
        <input
          type="text"
          autocapitalize="none"
          autofocus
          name="userName" id="cf_email"
          class="form-control h5-email"
          placeholder="User Name"
          value=""
          tabindex="1"
          maxlength="35"
          #userName="ngModel"
          [(ngModel)]="login.userName"
          required="required"
        />
        <label class="control-label left-10">User Name</label>
        <div class="error-container" [hidden]="userName.valid || (userName.pristine && !f.submitted)">
          <small class="text-danger">Please enter User Name</small>
        </div>
      </div>
      <!-- username div ends -->

      <!-- password div starts -->
      <div class="sm-form-design form-field">
        <i *ngIf="typePassword" (click)="togglePassword()" class="fa fa-eye show-password"></i>
        <i *ngIf="!typePassword" (click)="togglePassword()" class="fa fa-eye-slash show-password"></i>
        <input
          type="{{ typePassword ? 'text' : 'password' }}"
          class="form-control h5-email password-box"
          autocapitalize="none"
          placeholder="Password"
          id="cf_password" name="password"
          value=""
          tabindex="2"
          maxlength="46"
          [(ngModel)]="login.password"
          #password="ngModel" required
        />
        <label class="control-label left-10">Password</label>

        <div class="error-container" [hidden]="password.valid || (password.pristine && !f.submitted)">
          <small class="text-danger">Please enter Password</small>
        </div>
      </div>
      <!-- password div ends -->
      <a routerLink="/forgot-password" class="forgot_password mt-1">Forgot Password?</a>
      <button class="btn btn-peak btn-block btn-lg border-0 submit-btn mb-4" type="submit" [disabled]="disable">Login</button>
    </form>
    <!-- login form ends -->

    <!-- Verification code form starts -->
    <form *ngIf="verificationReq"
      action="javascript:void(0);"
      method="POST" #f="ngForm"
      (ngSubmit)="getMfaToken(f.valid)"
      novalidate>
      <h3 class="loginC text-center">Verify Your Identity</h3>
      <div class="loginAlert container mb-2 text-center">
        <small class="text-danger text-center">{{message}}</small>
      </div>

      <!-- Info start -->
      <p>You're trying to <b>Log In</b> to BitBrew. To make sure your BitBrew account is secure, we have to verify your identity.</p>
      <p>A verification Code has been sent to your email address. Use the code to login.</p>
      <p><b>Username: {{login.userName}}</b></p>
      <!-- Info end -->

      <!-- code div starts -->
      <div class="sm-form-design form-field">
        <input
          type="text"
          autocapitalize="none"
          autofocus
          name="verificationCode" id="cf_email"
          class="form-control h5-email"
          placeholder="Verification Code"
          value=""
          tabindex="1"
          maxlength="6"
          #verificationCode="ngModel"
          [(ngModel)]="otp"
          required="required"
        />
        <label class="control-label left-10">Verification Code</label>
        <div class="error-container" [hidden]="verificationCode.valid || (verificationCode.pristine && !f.submitted)">
          <small class="text-danger">Please enter Verification Code</small>
        </div>
      </div>
      <!-- code div ends -->

      <button class="btn btn-lg border-0 mt-1 resendBtn" type="button" [disabled]="resetDisable" (click)="resetOtp()">
        Resend Code <span *ngIf="resetDisable">({{resendTimer}})</span>
      </button>
      <button class="btn btn-peak btn-block btn-lg border-0 submit-btn mb-4" type="submit" [disabled]="disable">
        <span *ngIf="disable" class="mr-1"><i class="fa fa-circle-o-notch fa-spin fa-fw"></i></span>
        Verify
      </button>
      <!-- <button class="btn btn-peak btn-block btn-lg border-0 submit-btn mb-2 width100" type="submit" [disabled]="disable">
        <span *ngIf="disable" class="mr-1"><i class="fa fa-circle-o-notch fa-spin fa-fw"></i></span>
        Verify
      </button>
      <button class="btn btn-peak btn-block btn-lg border-0 submit-btn mb-4" type="button" [disabled]="resetDisable" (click)="resetOtp()">
        Resend Code <span *ngIf="resetDisable">({{resendTimer}})</span>
      </button> -->
    </form>
    <!-- Verification code form ends -->
  </div>
  <!-- footer starts -->

  <p class="copyrights-footer col text-center">
    &copy; {{footerText}}
  </p>
  <!-- footer ends -->
</div>
<!-- wrapper ends   -->


<!-- BEGIN MAIN WRAPPER -->
<!-- <div class="body-wrap">
    <app-notify-message></app-notify-messag'e>
    <div class="login login-v3"> -->
<!-- begin news-feed -->
<!-- <div class="news-feed">
    <div class="news-image">
    </div>
</div> -->
<!-- end news-feed -->
<!-- begin right-content -->
<!-- <div class="right-content fullvh"> -->
<!-- begin login-header -->
<!-- <div class="login-header">
    <div class="brand text-center  darby-logo-extra">
        <img src="{{cmImageUrl}}assets/img/login-logo.png" alt="logo">
    </div>
</div> -->
<!-- end login-header -->
<!-- begin login-content -->
<!-- <div class="login-content">
    <form action="javascript:void(0);" method="POST" class="margin-bottom-0" #f="ngForm" (ngSubmit)="checkLogin(f.valid)" novalidate>
    <h3 class="loginC">LOGIN</h3>
    <div class="loginAlert">
        <small class="text-danger text-center">{{message}}</small>
    </div>
    <div class="row mb-3">
        <div class="col-lg-12 sm-form-design">
            <input type="text" name="userName" id="cf_email"
            class="form-control h5-email"
            placeholder="Please enter your userName address"
            value=""
            autocomplete="off"
            tabindex="1"
            maxlength="35"
            #userName="ngModel" [(ngModel)]="login.userName" placeholder="User Name" required="required"
            >
            <label class="control-label">USER NAME</label>
            <div [hidden]="userName.valid || (userName.pristine && !f.submitted)">
                <small class="text-danger">Please enter User Name</small>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-lg-12 sm-form-design">
            <input [attr.type]=" typePassword ? 'text' : 'password' " name="password" id="cf_password"
            class="form-control h5-email password-box"
            placeholder="Please enter your password"
            value=""
            autocomplete="off"
            tabindex="2"
            maxlength="46" [(ngModel)]="login.password" #password="ngModel" required>
            <i (click)="togglePassword()" class="fa fa-eye show-password"></i>
            <span class="showPassword" >{{ typePassword ? 'Hide' : 'Show' }}</span><br>
            <input type="{{ typePassword ? 'text' : 'password' }}" name="password" placeholder="Password">
            <label class="control-label">PASSWORD</label>
            <div [hidden]="password.valid || (password.pristine && !f.submitted)">
                <small class="text-danger">Please enter password</small>
            </div>
        </div>
    </div>
    <div class="row m-b-10 m-t-15">
        <div class="col-6">
            <div class="checkbox">
                <input type="checkbox" checked id="chkRemember">
                <label for="chkRemember">Remember me</label>
            </div>
        </div>
        <div class="col-6 text-right">
            <label for="chkRemember"><a routerLink="/forgot-password">Forgot Password?</a></label>
        </div>
    </div>
    <div class="login-buttons loginSubmit">
        <button type="submit" class="btn btn-primary btn-peak btn-block btn-lg border-0">LOGIN
        </button>
    </div>
    <div class="loginFooter">
        <hr class="margTop20" />
        <p class="text-center f-s-12">
            &copy; {{footerText}}
        </p>
    </div>
    </form>
</div> -->
<!-- end login-content -->
<!-- </div> -->
<!-- end right-container -->
<!-- </div>
</div> -->
