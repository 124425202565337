import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@myenv/environment';
import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CustomerService = /** @class */ (function () {
    function CustomerService(http, httpClient, handler) {
        this.http = http;
        this.httpClient = httpClient;
        this.handler = handler;
        this.apiUrl = environment.apiUrl;
        this.httpClient = new HttpClient(handler);
    }
    CustomerService.prototype.getCustomers = function () {
        return this.http.get(this.apiUrl + "customers")
            .pipe(catchError(this.handleError));
    };
    CustomerService.prototype.addCustomer = function (data) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this.httpClient.post(this.apiUrl + "customers", data, { headers: headers })
            .pipe(catchError(this.handleError));
    };
    CustomerService.prototype.handleError = function (error) {
        return observableThrowError(error.error || error.json() || error || 'Server error');
    };
    CustomerService.prototype.getPreDefinedRoleId = function () {
        return this.http.get(this.apiUrl + "roles/defaultRoles/predefined")
            .pipe(catchError(this.handleError));
    };
    CustomerService.prototype.getPermissionsOnRoleId = function (roleId) {
        return this.http.get(this.apiUrl + 'roles/' + roleId + "/modules")
            .pipe(catchError(this.handleError));
    };
    CustomerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CustomerService_Factory() { return new CustomerService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i1.HttpBackend)); }, token: CustomerService, providedIn: "root" });
    return CustomerService;
}());
export { CustomerService };
