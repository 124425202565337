import { PipeTransform } from '@angular/core';
var FilterPipe = /** @class */ (function () {
    function FilterPipe() {
    }
    FilterPipe.prototype.transform = function (value, term) {
        if (!term)
            return value;
        return value.filter(function (item) {
            for (var prop in item) {
                if (typeof item[prop] === "string" && item[prop].indexOf(term) > -1) {
                    return true;
                }
            }
            return false;
        });
    };
    return FilterPipe;
}());
export { FilterPipe };
