<div class="calendar-graph-container" *ngIf="showTemplate">
  <div class="calendar-container">
    <div class="row no-gutters header mb-2 align-items-center">
      <div class="col-sm-6">
        <!-- <h6>Device Activity</h6> -->
      </div>
      <div class="col-sm-6 text-right">
        <div class="btn-group">
          <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{displayText}}
          </button>
          <div class="dropdown-menu">
            <a class="dropdown-item" (click)="onQuickSelect('7days')">Last 7 days</a>
            <a class="dropdown-item" (click)="onQuickSelect('30days')">Last 30 days</a>
            <a class="dropdown-item" (click)="onQuickSelect('week')">This week</a>
            <a class="dropdown-item" (click)="onQuickSelect('month')">This month</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" (click)="onQuickSelect('3month')">3 months</a>
          </div>
        </div>
      </div>
    </div>
    <div class="row no-gutters calendar text-center {{size}}">
      <div class="col-sm-12">
        <ngb-datepicker
          [startDate]="{year: today?.year, month: today?.month-2}"
          [maxDate]="today" #dp
          [displayMonths]="3"
          [dayTemplate]="t"
          outsideDays="hidden"
          (dateSelect)="onDateSelection($event)"
          >
        </ngb-datepicker>
        <ng-template #t let-date let-focused="focused">
          <span
            class="custom-day {{getEventClass(date)}}"
            >
            <!-- [class.focused]="focused"
            [class.range]="isRange(date)"
            [class.faded]="isHovered(date) || isInside(date)"
            (mouseenter)="hoveredDate = date"
            (mouseleave)="hoveredDate = null" -->
            {{ date.day }}
          </span>
        </ng-template>
      </div>
      <div class="col-sm-12 calendar-info">
        <div class="d-inline-block mr-4">
          <div class="infobox reporting d-inline-block"></div>
          <span class="text">Reporting</span>
        </div>
        <div class="d-inline-block mr-4">
          <div class="infobox notReporting d-inline-block"></div>
          <span class="text">Not Reporting</span>
        </div>
        <!-- <div class="d-inline-block">
          <div class="infobox heartbeat d-inline-block"></div>
          <span class="text">Heartbeat</span>
        </div> -->
      </div>
    </div>
  </div>
  <!-- <div class="graph-container mt-2">
    <div class="header">
      <h6 class="d-inline mr-2">Messages</h6>
      <small class="text-muted" *ngIf="selectedPreset==null">
        (Showing data for {{fromDate?.year}}/{{fromDate?.month}}/{{fromDate?.day}})
      </small>
      <small class="text-muted" *ngIf="selectedPreset!==null">(Showing for {{displayText}})</small>
    </div>
    <div class="graph">
      <canvas id="barGraph" height="80" role="img"></canvas>
    </div>
  </div> -->
</div>