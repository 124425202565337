import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { SharedService } from '@myproject/shared/shared.service';
import { GraphicReportsService } from '../graphic-reports.service';
import { environment } from '@myenv/environment';
import { DatePipe } from '@angular/common';
import * as FileSaver from 'file-saver';
import Chart from 'chart.js';
import { Title } from '@angular/platform-browser';
var STriggerReportComponent = /** @class */ (function () {
    function STriggerReportComponent(title, sharedService, reportService) {
        this.title = title;
        this.sharedService = sharedService;
        this.reportService = reportService;
        this.dateFormat = environment.isoDateTimeFormat;
        this.currentuser = JSON.parse(localStorage.getItem('currentUser'));
        this.requestType = 'S';
        this.hideCalendar = false;
        this.isLoading = false;
        this.startTime = null;
        this.endTime = null;
        this.deviceCounts = {
            all: 0,
            inProgress: 0,
            validationFailed: 0,
            success: 0,
            failed: 0
        };
        this.selectedStatus = 'all';
        this.selectedCountView = 'Monthly';
        this.displaySelectedStatus = 'All Devices';
        this.errorCountChart = null;
        this.avgTimeChart = null;
        this.requestCountChart = null;
        this.requestCountChartData = {
            labels: {
                daily: [],
                weekly: [],
                monthly: []
            },
            counts: {
                daily: [],
                weekly: [],
                monthly: []
            }
        };
        this.devices = [];
        this.page = 0;
        this.pageInfo = {
            offset: 0,
            count: 0,
            limit: 10,
            pageSize: 0,
            lastPage: 0,
            prevResponse: 0
        };
        this.currentPageCount = {
            start: 0,
            end: 0
        };
        this.totalErrorCount = 0;
    }
    STriggerReportComponent.prototype.ngOnInit = function () {
        this.title.setTitle('S-Trigger Report' + environment.title_text);
        // this.getStatusCounts(this.startTime, this.endTime);
        // this.getProvisioningErrorCounts(this.startTime, this.endTime, this.selectedStatus);
        // this.onStatusChange(this.selectedStatus);
    };
    STriggerReportComponent.prototype.onTimeChange = function (e) {
        this.startTime = e.startTime;
        this.endTime = e.endTime;
        this.getStatusCounts(this.startTime, this.endTime);
        this.getProvisioningErrorCounts(this.startTime, this.endTime, this.selectedStatus);
        this.onStatusChange(this.selectedStatus);
    };
    STriggerReportComponent.prototype.refresh = function () {
        this.getStatusCounts(this.startTime, this.endTime);
        this.getProvisioningErrorCounts(this.startTime, this.endTime, this.selectedStatus);
        this.onStatusChange(this.selectedStatus);
    };
    STriggerReportComponent.prototype.onStatusChange = function (status) {
        this.page = 0;
        this.pageInfo.offset = 0;
        this.selectedStatus = status;
        if (status === 'inProgress') {
            this.displaySelectedStatus = 'Devices In Progress';
        }
        else if (status === 'validationfailed') {
            this.displaySelectedStatus = 'Validation Failed Devices';
        }
        else if (status === 'success') {
            this.displaySelectedStatus = 'Devices Succeeded';
        }
        else if (status === 'failed') {
            this.displaySelectedStatus = 'Devices Failed';
        }
        else {
            this.displaySelectedStatus = 'All Devices';
        }
        // this.getStatusCounts(this.startTime, this.endTime);
        this.getAvgTimeData(this.startTime, this.endTime, this.selectedStatus);
        this.getRequestCount(this.startTime, this.endTime, this.selectedStatus);
        this.getDevicesWithFilter(this.startTime, this.endTime, this.selectedStatus, this.page);
    };
    STriggerReportComponent.prototype.getStatusCounts = function (startTime, endTime) {
        var _this = this;
        this.reportService.getAllStatusCount(this.requestType, this.currentuser.customerId, startTime, endTime).subscribe({
            next: function (res) {
                _this.deviceCounts = res;
            },
            error: function (error) {
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    STriggerReportComponent.prototype.getProvisioningErrorCounts = function (startTime, endTime, status) {
        var _this = this;
        this.reportService.getProvisioningErrorCounts(this.requestType, status, this.currentuser.customerId, startTime, endTime).subscribe({
            next: function (res) {
                _this.drawErrorCountChart(res);
            },
            error: function (error) {
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    STriggerReportComponent.prototype.getAvgTimeData = function (startTime, endTime, status) {
        var _this = this;
        this.reportService.getAvgTimeTaken(this.requestType, status, this.currentuser.customerId, startTime, endTime).subscribe({
            next: function (res) {
                _this.drawAvgTimeChart(res);
            },
            error: function (error) {
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    STriggerReportComponent.prototype.getRequestCount = function (startTime, endTime, status) {
        var _this = this;
        this.reportService.getRequestCounts(this.requestType, status, this.currentuser.customerId, startTime, endTime).subscribe({
            next: function (res) {
                _this.sortRequestCountChartData(res);
                // this.drawRequestCountChart(res);
            },
            error: function (error) {
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    STriggerReportComponent.prototype.getDevicesWithFilter = function (startTime, endTime, status, page) {
        var _this = this;
        this.reportService.getDevicesWithFilter(page, this.requestType, status, this.currentuser.customerId, startTime, endTime).subscribe({
            next: function (res) {
                if (page === 0) {
                    _this.devices = res;
                }
                else {
                    _this.devices = tslib_1.__spread(_this.devices, res);
                }
                _this.pageInfo.prevResponse = res.length;
                _this.isLoading = false;
            },
            error: function (error) {
                _this.isLoading = false;
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    STriggerReportComponent.prototype.pageCallback = function (value) {
        this.pageInfo.offset = value.offset;
        this.currentPageCount.start = (value.offset * value.pageSize) + 1;
        var lastPage = Math.floor(this.devices.length / 10);
        this.pageInfo.lastPage = lastPage;
        if (lastPage == value.offset) {
            this.currentPageCount.end = value.count;
        }
        else {
            this.currentPageCount.end = (value.offset * value.pageSize) + value.limit;
        }
        if (this.pageInfo.lastPage === (this.pageInfo.offset + 1)) {
            if (this.pageInfo.prevResponse) {
                this.page = ++this.page;
                this.getDevicesWithFilter(this.startTime, this.endTime, this.selectedStatus, this.page);
            }
        }
    };
    STriggerReportComponent.prototype.downloadCSV = function () {
        var _this = this;
        this.reportService.downloadSTriggerDevicesCSV(this.selectedStatus, this.currentuser.customerId).subscribe(function (res) {
            var contentDisposition = res.headers.get('Content-Disposition');
            var filename;
            if (contentDisposition == null) {
                var date = new Date();
                filename = 'Device_' + date + '.csv';
            }
            else {
                filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
            }
            var blob = new Blob([res.body], {
                type: 'text/csv;charset=utf-8'
            });
            FileSaver.saveAs(blob, filename);
        }, function (error) {
            _this.sharedService.getErrorMsg(error);
        });
    };
    STriggerReportComponent.prototype.drawErrorCountChart = function (data) {
        var labels = ['Device Not Found', 'Validation Failed', 'Sensorise Error', 'Unknown'];
        var counts = [];
        counts.push(data.DeviceNotFound);
        counts.push(data.ValidationFailed);
        counts.push(data.SensoriseError);
        counts.push(data.Unknown);
        this.totalErrorCount = data.DeviceNotFound + data.ValidationFailed + data.SensoriseError + data.Unknown;
        if (this.errorCountChart) {
            this.errorCountChart.destroy();
        }
        var ctx = document.getElementById('pieGraph');
        this.errorCountChart = new Chart(ctx, {
            type: 'pie',
            responsive: true,
            data: {
                labels: labels,
                datasets: [{
                        data: counts,
                        backgroundColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                        ]
                    }]
            },
            options: {
                legend: {
                    position: 'bottom',
                    display: true
                },
                scales: {
                    xAxes: [{
                            display: false,
                            ticks: {
                                beginAtZero: true
                            }
                        }],
                    yAxes: [{
                            display: false,
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                }
            }
        });
    };
    STriggerReportComponent.prototype.drawAvgTimeChart = function (data) {
        var labels = [];
        var days = [];
        var highestCount = 0;
        if (this.avgTimeChart) {
            this.avgTimeChart.destroy();
        }
        data.map(function (item) {
            var datePipe = new DatePipe('en-US');
            var eventDate = datePipe.transform(item.date, 'dd MMM');
            labels.push(eventDate);
            days.push(item.days);
        });
        highestCount = Math.max.apply(Math, tslib_1.__spread(days));
        var ctx = document.getElementById('lineGraph');
        this.avgTimeChart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: labels,
                datasets: [{
                        label: 'Days',
                        data: days,
                        backgroundColor: 'rgba(255, 159, 64, 0.2)',
                        borderColor: 'rgba(255, 159, 64, 1)',
                        borderWidth: 1,
                        fill: false
                    }]
            },
            options: {
                legend: {
                    position: 'bottom',
                    display: true
                },
                scales: {
                    yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                suggestedMin: 0,
                                suggestedMax: 10,
                                stepSize: highestCount > 9 ? null : 1
                            }
                        }],
                    xAxes: [{
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 12
                            }
                        }]
                }
            }
        });
    };
    STriggerReportComponent.prototype.sortRequestCountChartData = function (data) {
        var _this = this;
        this.requestCountChartData.labels = { daily: [], weekly: [], monthly: [] };
        this.requestCountChartData.counts = { daily: [], weekly: [], monthly: [] };
        // Daily counts and dstinct months and week
        data.map(function (item) {
            var datePipe = new DatePipe('en-US');
            var eventDate = datePipe.transform(item.date, 'dd MMM');
            // const week = `Week ${new Date(item.date)}`;
            var month = datePipe.transform(item.date, 'MMM');
            // get the week nummber
            // const date1 = new Date(item.date);
            // const oneJan = new Date(date1.getFullYear(), 0, 1);
            // const numberOfDays = Math.floor((date1 - oneJan) / (24 * 60 * 60 * 1000));
            // const week = Math.ceil((date1.getDay() + 1 + numberOfDays) / 7);
            _this.requestCountChartData.labels.daily.push(eventDate);
            // if (!this.requestCountChartData.labels.weekly.includes(week)) {
            //   this.requestCountChartData.labels.weekly.push(week);
            // }
            if (!_this.requestCountChartData.labels.monthly.includes(month)) {
                _this.requestCountChartData.labels.monthly.push(month);
            }
            _this.requestCountChartData.counts.daily.push(item.count);
        });
        // monthly counts
        this.requestCountChartData.labels.monthly.map(function (item) {
            var count = 0;
            data.map(function (obj) {
                var datePipe = new DatePipe('en-US');
                var month = datePipe.transform(obj.date, 'MMM');
                if (item === month) {
                    count += obj.count;
                }
            });
            _this.requestCountChartData.counts.monthly.push(count);
        });
        this.selectViewType(this.selectedCountView);
    };
    STriggerReportComponent.prototype.selectViewType = function (view) {
        this.selectedCountView = view;
        if (view === 'Monthly') {
            this.drawRequestCountChart(this.requestCountChartData.labels.monthly, this.requestCountChartData.counts.monthly);
        }
        else {
            this.drawRequestCountChart(this.requestCountChartData.labels.daily, this.requestCountChartData.counts.daily);
        }
    };
    STriggerReportComponent.prototype.drawRequestCountChart = function (labels, counts) {
        // const labels = [];
        // const counts = [];
        // let highestCount = 0;
        if (this.requestCountChart) {
            this.requestCountChart.destroy();
        }
        // data.map(item => {
        //   const datePipe = new DatePipe('en-US');
        //   const eventDate = datePipe.transform(item.date, 'dd MMM');
        //   labels.push(eventDate);
        //   counts.push(item.count);
        // });
        // highestCount = Math.max(...counts);
        var ctx = document.getElementById('barGraph');
        this.requestCountChart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: labels,
                datasets: [{
                        label: 'Count',
                        data: counts,
                        backgroundColor: 'rgb(54, 162, 235, 1)',
                        borderColor: 'rgb(54, 162, 235, 1)',
                        borderWidth: 1,
                    }]
            },
            options: {
                legend: {
                    position: 'bottom',
                    display: true
                },
                scales: {
                    yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                suggestedMin: 0,
                                suggestedMax: 10,
                            }
                        }],
                    xAxes: [{
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 12
                            }
                        }]
                }
            }
        });
    };
    return STriggerReportComponent;
}());
export { STriggerReportComponent };
