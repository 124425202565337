<!--BEGIN CONTENT-->
<!-- Top Action Row Start -->
<!-- Top Action Row End -->
<!-- Table and Map Row Start -->
<div #sessionExpired data-toggle="modal"
     data-target="#sessionExpired"
     class="d-none"></div>
<div class="container-fluid">
  <div class="row no-gutters live-page-height py-2">
    <div class="col-sm-6">
      <img style="height: 30px;"
           src="{{cmImageUrl}}{{accountKey}}/assets/img/customer-logo.png?random={{randomNumber}}"
           alt="logo" class="darbySVGLogo"
      />
    </div>
    <div class="col-sm-6 text-right">
      <div class="btn btn-outline-primary">Tracking Vehicle: {{deviceDetail.assetName}}</div>
    </div>
  </div>
</div>
<div class="row no-gutters live-page-height">
  <!-- Table Column Start -->
  <!-- Table Column End -->
  <!-- Map Column Start -->
  <div id="main" class="p-0 main-padding width100">
    <section id="live_content">
      <!-- Vehicle Tracking Indicator End -->
      <div class="p-0 dirMap snazzy-popup-wrapper" id="map_canvas">
        <div class="outer-wrapper">
          <div class="map-wrapper">
            <!--<ng-container>-->
              <div #gmap class="map-size"></div>
            <!--</ng-container>-->
          </div>
        </div>
      </div>
    </section>
  </div>
  <!-- Map Column End -->
</div>
<!-- Table and Map Row End -->
<!-- END OF CONTENT -->

<div id="sessionExpired" data-keyboard="false" data-backdrop="static" class="modal effect-flip-horizontal fade">
  <div class="modal-dialog" role="document">
    <div class="modal-content bd-0 bg-transparent overflow-hidden">
      <div class="modal-body p-0">
        <div class="row no-gutters">
          <div class="col-12">
            <div class="modal-box p-3 mt-4 text-center">
              <img class="mt-3" width="250" src="../../../assets/img/oops.jpg"/>
              <h2 class="my-5">Your Session has Expired</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
