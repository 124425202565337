import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '@myenv/environment';
var VehicleUploadService = /** @class */ (function () {
    function VehicleUploadService(http, router, handler) {
        this.http = http;
        this.router = router;
        this.handler = handler;
        this.apiUrl = environment.apiUrl;
        this.httpClient = new HttpClient(handler);
    }
    VehicleUploadService.prototype.uploadXL = function (data) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this.httpClient.post(this.apiUrl + 'devices/upload', data, { headers: headers }).pipe(catchError(this.handleError));
    };
    VehicleUploadService.prototype.updateXL = function (data) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this.httpClient.put(this.apiUrl + 'devices/upload', data, { headers: headers }).pipe(catchError(this.handleError));
    };
    VehicleUploadService.prototype.deleteVehicle = function (deviceId) {
        return this.http.delete(this.apiUrl + 'devices?deviceId=' + deviceId).pipe(catchError(this.handleError));
    };
    VehicleUploadService.prototype.getVehicles = function () {
        return this.http.get(this.apiUrl + 'devices').pipe(catchError(this.handleError));
    };
    VehicleUploadService.prototype.getVehicleUploadLogs = function () {
        return this.http.get(this.apiUrl + 'vehicles/upload/logs').pipe(catchError(this.handleError));
    };
    VehicleUploadService.prototype.createVehicle = function (data) {
        return this.http.post(this.apiUrl + 'vehicles', data).pipe(catchError(this.handleError));
    };
    VehicleUploadService.prototype.handleError = function (error) {
        return observableThrowError(error.error || error.json() || error || 'Server error');
    };
    return VehicleUploadService;
}());
export { VehicleUploadService };
