import { Injectable, NgZone } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SharedService } from '@shared/shared.service';

@Injectable()
export class PermissionGuard implements CanActivate {
  moduleName: string;
  constructor(
    private sharedService: SharedService,
    private router: Router,
    private ngzone: NgZone
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let permissionModules = [];
    const global = (<any>window);
    permissionModules = JSON.parse(localStorage.getItem('permissionModules'));
    this.moduleName = route.data.moduleName;
    localStorage.setItem('currentModule', this.moduleName);
    return this.checkPermission(this.moduleName, permissionModules).then(res => {
      if (res) {
        let obj = JSON.parse(localStorage.getItem('userInfo'));
        const intercomm = localStorage.getItem('intercomm');
        const intercomManualOn = localStorage.getItem('intercomManualOn');
        if (global.Intercom) {
          if (obj && (intercomm === null || intercomm === 'off') && this.moduleName === 'LIVE') {
            this.sharedService.bootUpInterCom();
          } else {
            global.Intercom("update");
          }
          global.Intercom('onUnreadCountChange', function (unreadCount) {
            if (unreadCount) {
              global.Intercom("show");
            }
          });
          if (this.moduleName !== 'LIVE') {
            this.sharedService.bootDownInterCom();
          }
        }
        return true;
      } else {
        this.sharedService.setDefaultUrl(permissionModules);
        this.sharedService.bootDownInterCom();
        return false;
      }
    });
  }
  checkPermission(modulename, permissionModules): Promise<boolean> {
    if (permissionModules) {
      const checkmodule = permissionModules.includes(modulename);
      return Promise.resolve(checkmodule);
    } else {
      this.sharedService.bootDownInterCom();
      this.router.navigate(['login']);
    }
  }

}
