<!--BEGIN CONTENT-->
<app-notify-message></app-notify-message>

<div class="row">
  <!-- <div class="col-md-3 col-lg-2 components-side-menu pr-0">
    <app-side-menu [sideData]="message"></app-side-menu>
  </div> -->
  <!-- <div class="col-md-9 col-sm-12 col-lg-10 pl-1 fullheight"> -->
  <div class="col fullheight">
    <section id="main_content" *ngIf="!isLoading" class="bg">
      <div class="row">
        <div class="col-md-12 text-center">
          <h3 class="headingVehicleAdd font400 mt-2 page-heading">{{companyName}}</h3>
          <form (ngSubmit)="f.form.valid && onSearch()" #f="ngForm" novalidate class="col-lg-12 search-wrapper mt-2">
            <div class="select-container text-left">
              <select required class="form-control" [(ngModel)]="searchModel.searchType" name="searchType"
                #searchType="ngModel" (change)="onSlotChange($event); selectInput()">
                <optgroup label="User">
                  <option value="emailId">Email</option>
                  <option value="userName">Username</option>
                  <option value="firstName">First Name</option>
                  <option value="lastName">Last Name</option>
                  <option value="mobileNumber">Mobile Number</option>
                  <option value="customerId">Customer Id</option>
                </optgroup>
                <optgroup label="Device">
                  <option value="assetName">Name</option>
                  <option value="serialNumber">Serial Number</option>
                  <option value="productSerialNumber">Product Serial Number</option>
                  <option value="imei">IMEI</option>
                  <option value="simCcid">SIM CCID</option>
                  <option value="vin">VIN</option>
                  <option value="firmwareVersion">Firmware Version</option>
                  <option value="configVersion">Config Version</option>
                  <option value="deviceId">Device ID</option>
                  <!-- <option value="groupName">Group Name</option> -->
                  <!-- <option value="mobileNumber">SIM Mobile Number</option> -->
                  <!-- <option value="groupId">Group ID</option> -->
                </optgroup>
              </select>
              <i class="fa fa-caret-down" aria-hidden="true"></i>
              <div class="alert-container" *ngIf="f.submitted && searchType.invalid">
                <small *ngIf="searchType.errors.required" class="text-danger">Please select type</small>
              </div>
            </div>
            <div class="container-input">
              <input #inputElement (keyup)="inputValidator($event)" placeholder="Search" type="text" name="supportTypeVal" required [(ngModel)]="searchModel.supportTypeVal"
                #supportTypeVal="ngModel" [autofocus]="true">
              <i class="fa fa-search img-responsive search-icon cursor-pointer" aria-hidden="true"></i>
                <!--<i class="fa fa-times clear" aria-hidden="true" *ngIf="searchModel.supportTypeVal!=''" (click)="clear()"></i>-->
              <!--<div class="alert-container" *ngIf="f.submitted && supportTypeVal.invalid">-->
                <!--<small *ngIf="supportTypeVal.errors.required" class="text-danger">Please write some text</small>-->
              <!--</div>-->
            </div>
            <!--<div class="button-container small-screen-btn">-->
              <!--<button class="btn btn-primary"><i class="fa fa-search mr-1"></i>Search</button>-->
            <!--</div>-->
          </form>
        </div>
      </div>

      <div class="row" *ngIf="users.length>0">
        <div class="col-12">
          <div class="w90 col mt-4">
            <ngx-datatable
              id="tableau" #dataTable
              class="bootstrap alertTable registeredVehicle reportsTable wrapcell"
              [rows]="users"
              [columnMode]="'force'"
              [headerHeight]="50"
              [footerHeight]="50"
              [rowHeight]="'auto'"
              [summaryRow]="false"
              [scrollbarH]="true"
              [reorderable]="true">

              <ngx-datatable-column [resizeable]="false" [sortable]="false" name="#" [width]="50">
                <ng-template let-rowIndex="rowIndex" ngx-datatable-cell-template>
                  {{rowIndex+1}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Name" prop="firstName">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.firstName}} {{row.lastName}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Email" prop="emailId">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.emailId}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="User Name" prop="userName">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.userName}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Customer ID" prop="customerId" [width]="130">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.customerId}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Phone Number" [sortable]="false">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.mobileNumber}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Type" prop="userType">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.userType}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Created At" prop="createdAt">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.createdAt | date: isoDateTimeFormat}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [sortable]="false" [width]="200">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <button type="button" class="btn btn-outline-primary btn-sm mr-2"
                    (click)="getCurrentUser(row);">SHOW
                  </button>
                  <button
                    *ngIf="impersonatePermission && impersonatePermission.authorities.includes('READ') && (row.userType=='SUB_USER' || row.userType=='SUPER_USER')"
                    class="btn btn-primary btn-sm"
                    (click)="impersonateUser(row)">
                    <i class="fa fa-sign-out text-white"></i>Impersonate
                  </button>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="devices.length>0">
        <div class="col-12">
          <div class="w90 col mt-4">
            <ngx-datatable
            id="tableau" #dataTable
            class="bootstrap alertTable registeredVehicle reportsTable wrapcell"
            [rows]="devices"
            [columnMode]="'force'"
            [headerHeight]="50"
            [footerHeight]="50"
            [rowHeight]="'auto'"
            [summaryRow]="false"
            [scrollbarH]="true"
            [reorderable]="true">
              <ngx-datatable-column [resizeable]="false" [sortable]="false" name="#" [width]="50">
                <ng-template let-rowIndex="rowIndex" ngx-datatable-cell-template>
                  {{rowIndex+1}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Device ID" prop="deviceId">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.deviceId}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Name" prop="assetName">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.assetName}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Serial Number" prop="serialNumber">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.serialNumber}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="PSN" prop="productSerialNumber">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.productSerialNumber}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="IMEI" prop="imei">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.imei}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="VIN" prop="vin">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.vin}}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Firmware Version" prop="firmwareVersion">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.firmwareVersion}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Config Version" prop="configVersion">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.configVersion}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="SIM CCID" prop="simCcid">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.simCcid}}
                </ng-template>
              </ngx-datatable-column>
              <!-- <ngx-datatable-column name="SIM Mobile Number" >
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.mobileNumber}}
                </ng-template>
              </ngx-datatable-column> -->
              <ngx-datatable-column name="Customer ID" prop="customerId" [width]="130">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.customerId}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Status" [sortable]="false" [width]="130">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span [class]="'class-' + deviceStatusClass[row.deviceStatus]">
                    {{deviceStatus[row.deviceStatus]}}
                  </span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Last Communication" prop="lastCommunication">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.lastCommunication | date: isoDateTimeFormat}}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Received At" prop="receivedAt">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.receivedAt | date: isoDateTimeFormat}}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Created At" prop="createdAt">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.createdAt | date: isoDateTimeFormat}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [sortable]="false" [width]="200">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <button type="button" class="btn btn-outline-primary btn-icon-left mr-2"
                    (click)="getCustomerId(row.customerId);">SHOW
                  </button>
                  <button
                    *ngIf="impersonatePermission && impersonatePermission.authorities.includes('READ') && (row.userType=='SUB_USER' || row.userType=='SUPER_USER')"
                    class="btn btn-primary btn-sm"
                    (click)="impersonateUser(row)">
                    <i class="fa fa-sign-out text-white"></i>Impersonate
                  </button>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="vehicles.length>0">
        <div class="col-12">
          <div class="w90 col mt-4">
            <ngx-datatable
              id="tableau" #dataTable
              class="bootstrap alertTable registeredVehicle reportsTable wrapcell"
              [rows]="vehicles"
              [columnMode]="'force'"
              [headerHeight]="50"
              [footerHeight]="50"
              [rowHeight]="'auto'"
              [summaryRow]="false"
              [scrollbarH]="true"
              [reorderable]="true">

            <ngx-datatable-column [resizeable]="false" [sortable]="false" name="#" [width]="50">
                <ng-template let-rowIndex="rowIndex" ngx-datatable-cell-template>
                  {{rowIndex+1}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Vehicle ID" prop="deviceId">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.deviceId}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Name" prop="assetName">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.assetName}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="VIN" prop="vin">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.vin}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Serial Number" prop="serialNumber">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.serialNumber}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Group Name" prop="groupName">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.groupName}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Customer ID" prop="customerId" [width]="130">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.customerId}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Tags" prop="tags">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.tags}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Last Communication" prop="lastCommunication">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.lastCommunication | date: isoDateTimeFormat}}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Received At" prop="receivedAt">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.receivedAt | date: isoDateTimeFormat}}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Created At" prop="createdAt">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.createdAt | date: isoDateTimeFormat}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [sortable]="false" [width]="200">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <button type="button" class="btn btn-peak btn-danger btn-icon-left mr-2"
                    (click)="getCustomerId(row.customerId);">SHOW
                  </button>
                  <button
                    *ngIf="impersonatePermission && impersonatePermission.authorities.includes('READ') && (row.userType=='SUB_USER' || row.userType=='SUPER_USER')"
                    class="btn btn-primary btn-sm"
                    (click)="impersonateUser(row)">
                    <i class="fa fa-sign-out text-white"></i>Impersonate
                  </button>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>
      <div class="col-12 text-center my-5" *ngIf="noResults">
        <br><br><br>
        <img width="170" src="../../../assets/img/info_nodata_256.png"/>
        <h4 class="mt-3">No data to show</h4>
      </div>
    </section>
  </div>
</div>
