import {Component, OnInit, NgZone} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '@layouts/login/authentication.service';
import {configMessage} from '@shared/config-message';

import {Title} from '@angular/platform-browser';
import {SharedService} from '@shared/shared.service';
import {environment} from '@myenv/environment';
import {enMessage} from '@shared/en-us-message';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  // public tenantId = environment.tenantId;
  public cmImageUrl = environment.cmImageUrl;
  public footerText = environment.footerText;
  public userDetails: any = {};
  responseMessage: any = {};
  resetToken: string = '';
  confirmNewPassword: Boolean = false;
  NewPassword: Boolean = false;
  ConfirmPassword: Boolean = false;

  constructor(private sharedService: SharedService, private route: ActivatedRoute, private authenticationService: AuthenticationService, private router: Router, private ngzone: NgZone,  private title: Title) {
  }

  ngOnInit() {
    this.title.setTitle('Reset password'+ environment.title_text)
    // this.resetToken = localStorage.getItem('resetToken');
    this.route.params.subscribe(params => {
      this.resetToken = params.id;
    });
    this.sharedService.bootDownInterCom();
  }

  onClickResetPassword(isValid: boolean) {
    if(isValid) {
      if (this.userDetails.newPassword !== this.userDetails.confirmPassword) {
        this.confirmNewPassword = true;

      } else {
        this.authenticationService.ResetPassword(this.userDetails, this.resetToken).subscribe((res) => {

          this.ngzone.run(() => {
            this.router.navigate(['/login'], {queryParams: {msg: 2}})
          })

        }, error => {
          this.sharedService.getErrorMsg(error);
        })
      }
    }
    // else {
    //   this.confirmNewPassword = false;
    // }


  }

  confirmPass() {
    if(this.userDetails.confirmPassword) {
      this.confirmNewPassword = false;
    }
  }

  viewNewPassword() {
    this.NewPassword = !this.NewPassword;
  }

  viewConfirmPassword() {
    this.ConfirmPassword = !this.ConfirmPassword;
  }

}
