import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { SharedService } from '@myproject/shared/shared.service';
import { environment } from '@myenv/environment';
import { GraphicReportsService } from '../../graphic-reports.service';
import * as FileSaver from 'file-saver';
import { Title } from '@angular/platform-browser';
var LogInOutReportComponent = /** @class */ (function () {
    function LogInOutReportComponent(sharedService, reportsService, title) {
        this.sharedService = sharedService;
        this.reportsService = reportsService;
        this.title = title;
        this.dateFormat = environment.isoDateTimeFormat;
        this.pageLoading = true;
        this.users = [];
        this.activity = [];
        this.searchModel = {};
        this.isLoading = false;
        this.page = 0;
        this.currentPageCount = {
            start: 0,
            end: 0
        };
        this.pageInfo = {
            offset: 0,
            count: 0,
            limit: 10,
            pageSize: 0,
            lastPage: 0,
        };
        this.prevResponse = 0;
        this.hideCalendar = false;
    }
    LogInOutReportComponent.prototype.ngOnInit = function () {
        this.title.setTitle('Log-in Activity' + environment.title_text);
    };
    LogInOutReportComponent.prototype.onTimeChange = function (e) {
        this.startTime = e.startTime;
        this.endTime = e.endTime;
        if (this.users.length) {
            this.page = 0;
            this.getData(this.page);
        }
        else {
            this.getUsers();
        }
    };
    LogInOutReportComponent.prototype.refresh = function () {
        this.page = 0;
        this.pageLoading = true;
        this.getData(this.page);
    };
    LogInOutReportComponent.prototype.getUsers = function () {
        var _this = this;
        this.sharedService.getUsers().subscribe({
            next: function (res) {
                _this.users = res;
                _this.searchModel.userId = res[0].userId;
                _this.getData(_this.page);
            },
            error: function (error) {
                _this.pageLoading = false;
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    LogInOutReportComponent.prototype.getData = function (page) {
        var _this = this;
        this.page = page;
        this.reportsService.getLogInOutActivity(this.searchModel.userId, this.startTime, this.endTime, page).subscribe({
            next: function (res) {
                _this.prevResponse = res.length;
                if (page === 0) {
                    _this.activity = res;
                }
                else {
                    _this.activity = tslib_1.__spread(_this.activity, res);
                }
                _this.pageLoading = false;
            },
            error: function (error) {
                _this.pageLoading = false;
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    LogInOutReportComponent.prototype.downloadCsv = function () {
        var _this = this;
        this.reportsService.downloadLogInOutActivity(this.searchModel.userId, this.startTime, this.endTime).subscribe({
            next: function (res) {
                var contentDisposition = res.headers.get('Content-Disposition');
                var filename;
                if (contentDisposition == null) {
                    var date = new Date();
                    filename = 'Login_Activity_' + date + '.csv';
                }
                else {
                    filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
                }
                var blob = new Blob([res.body], {
                    type: 'text/csv;charset=utf-8'
                });
                FileSaver.saveAs(blob, filename);
            },
            error: function (error) {
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    // searchUsers(e) {}
    // clearSearch() {}
    // To get the vehicles for each page in the table
    LogInOutReportComponent.prototype.pageCallback = function (value) {
        this.pageInfo.offset = value.offset;
        this.currentPageCount.start = (value.offset * value.pageSize) + 1;
        var lastPage = Math.floor(this.activity.length / 10);
        if (lastPage == value.offset) {
            this.currentPageCount.end = value.count;
        }
        else {
            this.currentPageCount.end = (value.offset * value.pageSize) + value.limit;
        }
        if (this.pageInfo.lastPage === (this.pageInfo.offset + 1)) {
            if (this.prevResponse) {
                this.page = ++this.page;
                this.getData(this.page);
            }
        }
    };
    return LogInOutReportComponent;
}());
export { LogInOutReportComponent };
