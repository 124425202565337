import * as tslib_1 from "tslib";
import { OnInit, OnChanges } from '@angular/core';
import { SharedService } from '../shared.service';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { GraphicReportsService } from '@myproject/components/graphic-reports/graphic-reports.service';
import Chart from 'chart.js';
import { DatePipe } from '@angular/common';
var CalendarGraphComponent = /** @class */ (function () {
    function CalendarGraphComponent(calendar, reportsService, sharedService) {
        this.calendar = calendar;
        this.reportsService = reportsService;
        this.sharedService = sharedService;
        this.device = {};
        this.size = 'lg';
        this.showTemplate = false;
        this.fromtime = { hour: 0, minute: 0 };
        this.totime = { hour: 23, minute: 59 };
        this.date = new Date();
        this.today = {
            year: this.date.getFullYear(),
            month: this.date.getMonth() + 1,
            day: this.date.getDate()
        };
        this.eventClass = {};
        this.autoselectEndDate = true;
        this.maxRangeDays = 0;
        // Chart Variables
        this.chartLoading = false;
        this.highestCount = 0;
        // dropdown Variables
        this.displayText = 'Quick Select';
        this.selectedPreset = null;
        // single day select event
        this.selectedEvent = '';
        this.selectedPreset = '3month';
    }
    CalendarGraphComponent.prototype.ngOnInit = function () {
        // this.drawChart();
    };
    CalendarGraphComponent.prototype.ngOnChanges = function (changes) {
        // perform operation on device change
        if (changes.device && changes.device.previousValue !== undefined) {
            if (changes.device.previousValue !== changes.device.currentValue) {
                this.getDeviceEventDates();
                this.getDeviceData();
            }
        }
        // perform refresh
        if (changes.refresh && changes.refresh.previousValue !== undefined) {
            if (changes.refresh.previousValue !== changes.refresh.currentValue) {
                this.getDeviceEventDates();
                this.getDeviceData();
            }
        }
    };
    // Get the events on dates
    CalendarGraphComponent.prototype.getDeviceEventDates = function () {
        var _this = this;
        var d = new Date();
        var end = Date.parse(d.toUTCString());
        d.setMonth(d.getMonth() - 12);
        var start = Date.parse(new Date(d.getFullYear(), d.getMonth(), 1).toUTCString());
        this.sharedService.getVehicleEventDates(this.device.deviceId, start, end).subscribe({
            next: function (res) {
                _this.eventClass = {};
                if (res.noTrips.length) {
                    res.noTrips.map(function (item) {
                        var i = new Date(item);
                        var attr = i.getFullYear() + "-" + (i.getMonth() + 1) + "-" + i.getDate();
                        _this.eventClass[attr] = 'messageEvent';
                    });
                }
                if (res.trips.length) {
                    res.trips.map(function (item) {
                        var i = new Date(item);
                        var attr = i.getFullYear() + "-" + (i.getMonth() + 1) + "-" + i.getDate();
                        _this.eventClass[attr] = 'tripEvent';
                    });
                }
            },
            error: function (error) {
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    // get device data based on day selection
    CalendarGraphComponent.prototype.getDeviceData = function () {
        if (this.selectedPreset === null) {
            this.getSingleDayMessageData(new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day), new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day));
        }
        else {
            this.onQuickSelect(this.selectedPreset);
        }
    };
    // Select from Quick Links
    CalendarGraphComponent.prototype.onQuickSelect = function (preset) {
        var start = null;
        var end = null;
        var t = new Date();
        this.selectedPreset = preset;
        if (preset === 'week') {
            this.displayText = 'This Week';
            var first = t.getDate() - t.getDay() + 1;
            var today = new Date();
            start = new Date(today.setDate(first));
            end = t;
        }
        else if (preset === 'month') {
            this.displayText = 'This Month';
            start = new Date(t.getFullYear(), t.getMonth(), 1, 0, 0, 0);
            end = new Date(t.getFullYear(), t.getMonth(), t.getDate(), 23, 59, 59);
        }
        else if (preset === '7days') {
            this.displayText = 'Last 7 Days';
            t.setDate(t.getDate() - 7);
            start = t;
            end = new Date();
        }
        else if (preset === '30days') {
            this.displayText = 'Last 30 Days';
            t.setDate(t.getDate() - 30);
            start = t;
            end = new Date();
        }
        else if (preset = '3month') {
            this.displayText = '3 months';
            start = new Date(t.getFullYear(), t.getMonth() - 2, 1);
            end = t;
        }
        this.fromDate = null;
        this.getTotalMessageData(start, end);
    };
    // Getting the total message data
    CalendarGraphComponent.prototype.getTotalMessageData = function (startTime, endTime) {
        var _this = this;
        this.chartLoading = true;
        var start = startTime.getFullYear() + "-" + (startTime.getMonth() + 1) + "-" + startTime.getDate();
        var end = endTime.getFullYear() + "-" + (endTime.getMonth() + 1) + "-" + endTime.getDate();
        this.reportsService.getDeviceTotalMessageCount(this.device.deviceId, start, end).subscribe({
            next: function (res) {
                _this.sortChartData(res);
            },
            error: function (error) {
                _this.chartLoading = false;
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    CalendarGraphComponent.prototype.sortChartData = function (data) {
        var labels = [];
        var counts = [];
        data.map(function (item) {
            var datePipe = new DatePipe('en-US');
            var eventDate = datePipe.transform(item.date, 'dd MMM');
            labels.push(eventDate);
            counts.push(item.count);
        });
        this.highestCount = Math.max.apply(Math, tslib_1.__spread(counts));
        this.drawChart(labels, counts, 'rgb(54, 162, 235, 1)');
    };
    CalendarGraphComponent.prototype.getSingleDayMessageData = function (startTime, endTime) {
        var _this = this;
        this.chartLoading = true;
        var start = startTime.getFullYear() + "-" + (startTime.getMonth() + 1) + "-" + startTime.getDate();
        var end = endTime.getFullYear() + "-" + (endTime.getMonth() + 1) + "-" + endTime.getDate();
        this.reportsService.getVehicleMessageData(this.device.deviceId, start, end).subscribe({
            next: function (res) {
                _this.sortMessageData(res);
            },
            error: function (error) {
                _this.chartLoading = false;
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    CalendarGraphComponent.prototype.sortMessageData = function (data) {
        var labels = [];
        var counts = [];
        for (var k in data[0]) {
            if (!labels.includes(k) && k !== 'date' && k !== 'NO_DATA') {
                labels.push(k);
            }
        }
        labels.map(function (item) {
            counts.push(data[0][item]);
        });
        this.highestCount = Math.max.apply(Math, tslib_1.__spread(counts));
        if (this.selectedEvent === 'tripEvent')
            this.drawChart(labels, counts, '#479a24');
        else if (this.selectedEvent === 'messageEvent')
            this.drawChart(labels, counts, '#9a66fe');
        else
            this.drawChart(labels, counts, '#969696');
    };
    // Chart draw
    CalendarGraphComponent.prototype.drawChart = function (labels, counts, backgroundColor) {
        if (this.barChart) {
            this.barChart.destroy();
        }
        var ctx = document.getElementById('barGraph');
        this.barChart = new Chart(ctx, {
            type: 'bar',
            responsive: true,
            data: {
                labels: labels,
                datasets: [{
                        label: 'Count',
                        backgroundColor: backgroundColor,
                        data: counts
                    }]
            },
            options: {
                legend: {
                    position: 'bottom',
                    display: true
                },
                scales: {
                    xAxes: [{
                            display: true,
                            stacked: true
                        }],
                    yAxes: [{
                            display: true,
                            stacked: true,
                            ticks: {
                                suggestedMin: 0,
                                suggestedMax: 10,
                                stepSize: this.highestCount > 10 ? null : 2
                            }
                        }]
                }
            }
        });
    };
    // Calendar functions start
    CalendarGraphComponent.prototype.onDateSelection = function (date) {
        var attr = date.year + "-" + date.month + "-" + date.day;
        if (this.eventClass[attr]) {
            this.fromDate = date;
            this.displayText = 'Quick Selection';
            this.selectedPreset = null;
            this.selectedEvent = this.eventClass[attr];
            this.getDeviceData();
        }
    };
    CalendarGraphComponent.prototype.setMaxRange = function (date) {
        if (this.maxRangeDays >= 0) {
            return this.calendar.getNext(date, 'd', this.maxRangeDays);
        }
        else {
            return this.calendar.getNext(date, 'd', 30);
        }
    };
    CalendarGraphComponent.prototype.getEventClass = function (date) {
        var attr = date.year + "-" + date.month + "-" + date.day;
        if (this.eventClass[attr]) {
            return this.eventClass[attr];
        }
        else {
            return 'noEvent';
        }
    };
    return CalendarGraphComponent;
}());
export { CalendarGraphComponent };
