import { PipeTransform } from '@angular/core';
var SecondsToHmsPipe = /** @class */ (function () {
    function SecondsToHmsPipe() {
    }
    SecondsToHmsPipe.prototype.transform = function (d, constraint) {
        if (d === undefined || d === null) {
            return null;
        }
        else {
            d = Number(d);
            if (constraint && constraint === 'milliseconds') {
                return this.convert(d);
            }
            else {
                d = d * 1000;
                return this.convert(d);
            }
        }
    };
    SecondsToHmsPipe.prototype.convert = function (millis) {
        var d = Math.floor((millis / (1000 * 60 * 60) / 24));
        var h = Math.floor((millis / (1000 * 60 * 60)) % 24);
        var m = Math.floor((millis / (1000 * 60)) % 60);
        var s = Math.floor((millis / 1000) % 60);
        var dDisplay = (d < 10) ? '0' + d : d;
        var hDisplay = (h < 10) ? '0' + h : h;
        var mDisplay = (m < 10) ? '0' + m : m;
        var sDisplay = (s < 10) ? '0' + s : s;
        var text = '-----';
        if (d) {
            text = dDisplay + "d " + hDisplay + "h " + mDisplay + "m " + sDisplay + "s";
        }
        else if (!d && h) {
            text = hDisplay + "h " + mDisplay + "m " + sDisplay + "s";
        }
        else if (!d && !h && m) {
            text = mDisplay + "m " + sDisplay + "s";
        }
        else if (!d && !h && !m && s) {
            text = sDisplay + "s";
        }
        return text;
        // return dDisplay + 'd ' + hDisplay + 'h ' + mDisplay + 'm ' + sDisplay + 's';
    };
    return SecondsToHmsPipe;
}());
export { SecondsToHmsPipe };
