import { OnInit, ElementRef } from '@angular/core';
import { SharedService } from "@shared/shared.service";
import { DeviceReplacementService } from "@components/support/user-info/device-replacement/device-replacement.service";
import { environment } from '@myenv/environment';
import { enMessage } from '@shared/en-us-message';
import { Subject, concat, of } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
var DeviceReplacementComponent = /** @class */ (function () {
    function DeviceReplacementComponent(sharedService, deviceReplacementService) {
        this.sharedService = sharedService;
        this.deviceReplacementService = deviceReplacementService;
        this.deviceInput$ = new Subject();
        this.deviceLoading = false;
        this.deviceType = {};
        this.searchHeader = [
            { name: 'serialNumber', displayName: 'Serial Number', type: 'STRING' }
        ];
        this.timeout = null;
        this.devices = [];
        this.unAssociatedDevices = [];
        this.searchModel = {};
        this.selectedRow = {};
        this.noResults = false;
        this.responseMessage = {};
        this.permissionModules = [];
        this.permissionAuthorities = {};
        this.modules = [];
        this.moduleName = 'DEVICE-REPLACE';
        this.dateFormat = environment.largeDateFormat;
        this.disable = false;
    }
    DeviceReplacementComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.searchModel = {
            searchId: 'serialNumber'
        };
        this.modules = JSON.parse(localStorage.getItem('accessRoleModules'));
        this.permissionAuthorities = this.modules.find(function (module) { return module.name === _this.moduleName; });
    };
    DeviceReplacementComponent.prototype.searchDataOnChange = function () {
        if (this.searchModel.searchValue && this.searchModel.searchId) {
            this.onSearch();
        }
    };
    DeviceReplacementComponent.prototype.selectInput = function () {
        var inputElem = this.inputOne.nativeElement;
        inputElem.select();
    };
    DeviceReplacementComponent.prototype.inputValidator = function (event) {
        //console.log(event.target.value);
        var pattern = /^[_\-@.,a-zA-Z0-9\s]*$/;
        //let inputChar = String.fromCharCode(event.charCode)
        if (!pattern.test(event.target.value)) {
            event.target.value = event.target.value.replace(/[^_\-@.,a-zA-Z0-9\s]/g, "");
            this.searchModel.searchValue = event.target.value;
            this.onSearch();
        }
        else {
            this.onSearch();
        }
    };
    DeviceReplacementComponent.prototype.onSearch = function () {
        clearTimeout(this.timeout);
        var global = this;
        this.timeout = setTimeout(function () {
            if (global.searchModel.searchValue) {
                var deviceTypeValue = global.searchModel.searchValue.trim();
                global.deviceReplacementService.searchDevice('serialNumber', deviceTypeValue, global.userData.customerId).subscribe({
                    next: function (res) {
                        global.devices = res;
                        (global.devices.length === 0) ? global.noResults = true : global.noResults = false;
                    },
                    error: function (error) {
                        global.sharedService.getErrorMsg(error);
                    }
                });
            }
            else {
                global.devices = [];
            }
        }, 1500);
    };
    DeviceReplacementComponent.prototype.getSelectedRow = function (row) {
        this.selectedRow = row;
        this.searchModel.oldSerialNumber = row.serialNumber;
    };
    DeviceReplacementComponent.prototype.getUnAssociatedDevices = function () {
        var _this = this;
        this.device$ = concat(of(this.unAssociatedDevices), // default items
        this.deviceInput$.pipe(distinctUntilChanged(), tap(function () { return _this.deviceLoading = true; }), switchMap(function (term) { return _this.deviceReplacementService.getUnAssociatedDevices('serialNumber', term.trim(), _this.userData.customerId).pipe(catchError(function () { return of([_this.unAssociatedDevices]); }), // empty list on error
        tap(function () { return _this.deviceLoading = false; })); })));
    };
    // To update attribute
    DeviceReplacementComponent.prototype.update = function (isValid, form) {
        var _this = this;
        if (isValid) {
            this.disable = true;
            var payload = {
                "oldSerialNumber": this.searchModel.oldSerialNumber,
                "newSerialNumber": this.searchModel.newSerialNumber,
                "comments": this.searchModel.comments
            };
            this.deviceReplacementService.replaceDevice(payload).subscribe(function (res) {
                _this.btnClose.nativeElement.click();
                _this.responseMessage = { success: true, message: enMessage.replacementSuccess };
                _this.sharedService.setResponseMessage(_this.responseMessage);
                _this.disable = false;
            }, function (error) {
                _this.disable = false;
                _this.sharedService.getErrorMsg(error);
            });
        }
    };
    DeviceReplacementComponent.prototype.removeLoader = function () {
        this.deviceLoading = false;
    };
    return DeviceReplacementComponent;
}());
export { DeviceReplacementComponent };
