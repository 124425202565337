import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GraphicReportsComponent } from './graphic-reports.component';
import { GraphicReportsRoutingModule } from './graphic-reports-routing.module';
import { ReportsSideMenuComponent } from './reports-side-menu/reports-side-menu.component';
import { NotCommunicatingReportComponent } from './device-reports/not-communicating-report/not-communicating-report.component';
import { ProvisioningReportComponent } from './device-reports/provisioning-report/provisioning-report.component';
import { VersionDrilldownReportComponent } from './device-reports/version-drilldown-report/version-drilldown-report.component';
import { UsageReportComponent } from './system-reports/usage-report/usage-report.component';
import { ReportsTableComponent } from './device-reports/reports-table/reports-table.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedService } from '@myproject/shared/shared.service';
import { SharedModule } from '@myproject/shared/shared.module';
import { GraphicReportsService } from './graphic-reports.service';
import { MessageReportComponent } from './message-report/message-report.component';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { ReportsService } from '@components/reports/reports.service';
import { VisualizationReportComponent } from './visualization-report/visualization-report.component';
import { CustomReportComponent } from './custom-report/custom-report.component';
import { VehicleUploadService } from '@components/vehicle/vehicle-upload/vehicle-upload.service';
// import { ReportsModule } from '@components/reports-old/reports-old.module';
import { MetricsReportComponent } from './vehicle-reports/metrics-report/metrics-report.component';
import { LogInOutReportComponent } from './user-reports/log-in-out-report/log-in-out-report.component';
import { TripSummaryReportComponent } from './vehicle-reports/trip-summary-report/trip-summary-report.component';
import { TripsNewService } from '../trips-new/trips-new.service';
// import { SecondsToHmsPipe } from '@myproject/shared/seconds-to-hms.pipe';
import { DestinationReportComponent } from './system-reports/destination-report/destination-report.component';
import { ProcessingTimeReportComponent } from './system-reports/processing-time-report/processing-time-report.component';
import { NumberFormatterPipe } from '@myproject/shared/number-formatter.pipe';
import { DeviceViewReportComponent } from './device-reports/device-view-report/device-view-report.component';
import { VehicleViewReportComponent } from './vehicle-reports/vehicle-view-report/vehicle-view-report.component';
import { CommunicatingReportComponent } from './device-reports/communicating-report/communicating-report.component';
import { TooltipModule } from 'ng2-tooltip-directive';
import { LiveStatusReportComponent } from './system-reports/live-status-report/live-status-report.component';
import { MultiVehicleDownloadReportComponent } from './multi-vehicle-download-report/multi-vehicle-download-report.component';
import { AlPlantModeReportComponent } from './al-plant-mode-report/al-plant-mode-report.component';
// tslint:disable-next-line: max-line-length
import { CommunicatingCalendarReportComponent } from './system-reports/communicating-calendar-report/communicating-calendar-report.component';
import { CTriggerReportComponent } from './c-trigger-report/c-trigger-report.component';
import { STriggerReportComponent } from './s-trigger-report/s-trigger-report.component';
import { ProvisionBillingComponent } from './billing-reports/provision-billing/provision-billing.component';
import { GeneralComponent } from './general/general.component';
import { DeepViewComponent } from './deep-view/deep-view.component';
// import { ScoreComponent } from './score/score.component';
import { PacketHistoryReportComponent } from './packet-history-report/packet-history-report.component';
import { ReportsHeaderComponent } from './reports-header/reports-header.component';
import { UsageComponent } from './usage/usage.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BillingReportComponent } from './system-reports/billing-report/billing-report.component';
import { DiscoveryReportComponent } from './system-reports/discovery-report/discovery-report.component';
import { DiagnosticsReportComponent } from './system-reports/diagnostics-report/diagnostics-report.component';



@NgModule({
  declarations: [
    GraphicReportsComponent,
    //ReportsSideMenuComponent,
    NotCommunicatingReportComponent,
    ProvisioningReportComponent,
    VersionDrilldownReportComponent,
    UsageReportComponent,
    ReportsTableComponent,
    MessageReportComponent,
    VisualizationReportComponent,
    CustomReportComponent,
    MetricsReportComponent,
    LogInOutReportComponent,
    TripSummaryReportComponent,
    // SecondsToHmsPipe,
    NumberFormatterPipe,
    DestinationReportComponent,
    ProcessingTimeReportComponent,
    DeviceViewReportComponent,
    VehicleViewReportComponent,
    CommunicatingReportComponent,
    LiveStatusReportComponent,
    MultiVehicleDownloadReportComponent,
    AlPlantModeReportComponent,
    CommunicatingCalendarReportComponent,
    CTriggerReportComponent,
    STriggerReportComponent,
    ProvisionBillingComponent,
    GeneralComponent,
    DeepViewComponent,
    // ScoreComponent,
    PacketHistoryReportComponent,
    //ReportsHeaderComponent,
    UsageComponent,
    //BillingReportComponent
    DiscoveryReportComponent,
    DiagnosticsReportComponent
  ],
  imports: [
    FormsModule,
    NgSelectModule,
    CommonModule,
    GraphicReportsRoutingModule,
    NgxDatatableModule,
    SharedModule,
    TrimValueAccessorModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    AngularMultiSelectModule,
    // ReportsModule,
    TooltipModule,
    NgbModule
  ],
  providers: [
    SharedService,
    GraphicReportsService,
    ReportsService,
    VehicleUploadService,
    TripsNewService,
    NumberFormatterPipe,
  ],exports: [
    GraphicReportsComponent
  ]
})
export class GraphicReportsModule { }
