
import {throwError as observableThrowError, Observable, BehaviorSubject} from 'rxjs';

import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';

import {environment} from '@myenv/environment';
import {HttpClient, HttpHeaders, HttpBackend} from '@angular/common/http';

@Injectable()
export class ToolsService {
  public apiUrl = environment.apiUrl;
  // private httpClient: HttpClient;

  sideData = [
    {
      name : 'menu.CACHE',
      img: 'cubes',
      route: '/tools/cache'
    },
    {
      name : 'menu.REPUBLISH',
      img: 'paper-plane-o',
      route: '/tools/republish'
    }
    // {
    //   name : 'menu.MOVE',
    //   img: 'arrows',
    //   route: '/tools/move'
    // }
  ];

  constructor(private http: HttpClient) {}

  private messageSource = new BehaviorSubject(this.sideData);
  currentMessage = this.messageSource.asObservable();

  // To rebuild cache
  getRebuildCache(serialNumber): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    return this.http.get(this.apiUrl + 'devices/cache?serialNumber=' + serialNumber, {headers}).pipe(
      catchError(this.handleError));
  }

  // To invalidate cache
  invalidateCache(serialNumber): Observable<any>{
    return this.http.delete(this.apiUrl + 'devices/cache?serialNumber=' + serialNumber).pipe(
      catchError(this.handleError));
  }

  // To Republish cache
  republishCache(cache,startTime, endTime): Observable<any>{
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization':'Bearer '+token
    });
    if(cache.messageType){
      return this.http.post(this.apiUrl+"messages/publish?deviceId="+ cache.deviceId +"&messageType="+ cache.messageType +"&startTime="+ startTime +"&endTime=" + endTime,{}, {headers}).pipe(
        catchError(this.handleError))
    } if(!cache.messageType){
      return this.http.post(this.apiUrl+"messages/publish?deviceId="+ cache.deviceId +"&startTime="+ startTime +"&endTime=" + endTime,{}, {headers}).pipe(
        catchError(this.handleError))
    }

  }

  getCustomerDevices(page, customerId, serialNumber): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json',
      'CUSTOM_X_LCM': 'true'
    });
    let tempUrl = this.apiUrl + `devices/customer/${customerId}?page=${page}`;
    if (serialNumber) {
      tempUrl += `&serialNumber=${serialNumber}`;
    }
    return this.http.get(tempUrl, {headers}).pipe(
      catchError(this.handleError));
  }

  shiftDevices(payload): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    return this.http.post(this.apiUrl + `devices/shift`, payload, {headers}).pipe(
      catchError(this.handleError));
  }

  // To handle errors
  handleError(error: any | any ) {

    console.log(error.error || error.json() || error);
    return observableThrowError(error.error || error.json() || error || 'Server error');
  }


}
