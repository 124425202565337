import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import Chart from 'chart.js';
import { GraphicReportsService } from '../../graphic-reports.service';
import { SharedService } from '@myproject/shared/shared.service';
import { DatePipe } from '@angular/common';
import * as FileSaver from 'file-saver';
import { Title } from '@angular/platform-browser';
import { environment } from '@myenv/environment';
import { GroupsService } from '@myproject/components/device/groups/groups.service';
import { configMessage } from '@shared/config-message';
var CommunicatingReportComponent = /** @class */ (function () {
    function CommunicatingReportComponent(reportsService, sharedService, title, groupsService) {
        this.reportsService = reportsService;
        this.sharedService = sharedService;
        this.title = title;
        this.groupsService = groupsService;
        this.page = 0;
        this.devices = [];
        this.selectedCategory = '';
        this.displayCategory = '';
        this.selectedType = '';
        this.resetOffset = false;
        this.hideCalendar = false;
        this.blockCall = false;
        this.highestCount = 0;
        this.colors = [];
        this.searchableData = [];
        this.searchedData = [];
        this.searchValue = '';
        this.searchPopup = false;
        this.isLoading = {
            pieChart: true,
            lineGraph: true,
            devices: true
        };
        this.groups = [];
        this.configMessage = configMessage;
        this.selectedDate = {};
        this.displayTitle = {};
    }
    CommunicatingReportComponent.prototype.ngOnInit = function () {
        this.title.setTitle('Communicating' + environment.title_text);
        this.getGroups();
    };
    CommunicatingReportComponent.prototype.onTimeChange = function (e) {
        if (e) {
            this.startTime = e.startTime;
            this.endTime = e.endTime;
        }
        this.page = 0;
        this.resetOffset = !this.resetOffset;
        if (this.groups.length) {
            this.resetSearch();
            //this.getAllCommCount(this.startTime, this.endTime, this.selectedCategory);
            this.getCommTrendData(this.startTime, this.endTime, 'groupName', this.displayCategory, 'green');
            this.getAllCommDevices(this.startTime, this.endTime, 0);
        }
    };
    CommunicatingReportComponent.prototype.onPageChange = function (page) {
        this.page = page;
        this.getAllCommDevices(this.startTime, this.endTime, page);
    };
    CommunicatingReportComponent.prototype.onCategoryChange = function (key, display) {
        this.selectedCategory = key;
        this.displayCategory = display;
        this.blockCall = false;
        this.resetSearch();
        this.getAllCommCount(this.startTime, this.endTime, key);
    };
    CommunicatingReportComponent.prototype.onCategorySearch = function () {
        var global = this;
        this.searchedData = this.searchableData.filter(function (item) {
            return item.category.toLowerCase().includes(global.searchValue.toLowerCase());
        });
    };
    CommunicatingReportComponent.prototype.showSearchPopup = function () {
        this.searchValue = '';
        this.searchedData = this.searchableData;
        this.searchPopup = true;
    };
    CommunicatingReportComponent.prototype.onSelectCategory = function (item) {
        this.displayCategory = item.name;
        this.selectedType = item.category;
        this.searchPopup = false;
        this.searchValue = '';
        this.getCommTrendData(this.startTime, this.endTime, this.selectedCategory, item.name, 'green');
        this.getAllCommDevices(this.startTime, this.endTime, 0);
    };
    CommunicatingReportComponent.prototype.resetSearch = function () {
        this.searchValue = '';
        this.searchableData = [];
        this.searchedData = [];
        this.searchPopup = false;
    };
    CommunicatingReportComponent.prototype.refresh = function () {
        this.page = 0;
        this.resetOffset = !this.resetOffset;
        this.resetSearch();
        this.getAllCommCount(this.startTime, this.endTime, this.selectedCategory);
        this.getAllCommDevices(this.startTime, this.endTime, 0);
    };
    CommunicatingReportComponent.prototype.getAllCommCount = function (startTime, endTime, type) {
        var _this = this;
        this.isLoading.pieChart = true;
        this.isLoading.lineGraph = true;
        this.reportsService.getAllCommCount(startTime, endTime, type).subscribe({
            next: function (res) {
                _this.isLoading.pieChart = false;
                if (res.length) {
                    res.map(function (item) {
                        var r = Math.floor(Math.random() * 256);
                        var g = Math.floor(Math.random() * 256);
                        var b = Math.floor(Math.random() * 256);
                        var color = 'rgb(' + r + ',' + g + ',' + b + ')';
                        var obj = { category: item.type, color: color };
                        _this.searchableData.push(obj);
                        _this.colors.push(color);
                    });
                    _this.selectedType = res[0].type;
                    _this.getCommTrendData(_this.startTime, _this.endTime, type, res[0].type, _this.colors[0]);
                    _this.drawPieGraph(res);
                }
                else {
                    _this.drawPieGraph([]);
                    _this.drawLineGraph([], '');
                    _this.isLoading.lineGraph = false;
                }
            },
            error: function (error) {
                _this.isLoading.pieChart = false;
                _this.isLoading.lineGraph = false;
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    CommunicatingReportComponent.prototype.getCommTrendData = function (startTime, endTime, type, value, color) {
        var _this = this;
        this.isLoading.lineGraph = true;
        this.reportsService.getCommTrendData(startTime, endTime, type, value).subscribe({
            next: function (res) {
                _this.isLoading.lineGraph = false;
                if (res.length) {
                    if (value === "All") {
                        var sumCounts = _this.sumCountsByDay(res);
                        var combinedRes = [];
                        var tempRes = {};
                        tempRes["type"] = "All";
                        tempRes["data"] = sumCounts;
                        combinedRes.push(tempRes);
                        _this.drawLineGraph(combinedRes, color);
                    }
                    else {
                        _this.drawLineGraph(res, color);
                    }
                }
                else {
                    _this.drawLineGraph([], '');
                }
            },
            error: function (error) {
                _this.isLoading.lineGraph = false;
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    CommunicatingReportComponent.prototype.getAllCommDevices = function (startTime, endTime, page) {
        var _this = this;
        this.isLoading.devices = true;
        //this.isLoading.devices = false;
        this.selectedDate = new Date(endTime);
        this.selectedDate.setDate(this.selectedDate.getDate() - 1);
        this.selectedDate.setHours(0);
        this.selectedDate.setMinutes(0);
        this.selectedDate.setSeconds(0);
        this.selectedDate.setMilliseconds(0);
        var datePipe = new DatePipe('en-US');
        var eventDate = datePipe.transform(this.selectedDate, 'dd MMM yyyy');
        this.displayTitle = "Communicating Devices as on " + eventDate;
        // let tempDevices = [];
        // configMessage.reportsDeviceList.forEach((tempDevice, index) => {
        //   const d = new Date(tempDevice.lastCommunication);
        //   d.setHours(0);
        //   d.setMinutes(0);
        //   d.setSeconds(0);
        //   d.setMilliseconds(0);
        //   if (d.getTime() > new Date("14-Aug-2024").getTime() && (this.displayCategory === "All" || this.displayCategory === tempDevice.groupName)) {
        //     tempDevices.push(tempDevice);
        //   }
        // });
        // this.devices = tempDevices;
        this.reportsService.getAllCommDevices(startTime, endTime, page).subscribe({
            next: function (res) {
                _this.isLoading.devices = false;
                if (!res.length) {
                    _this.blockCall = true;
                }
                if (page === 0) {
                    _this.devices = res;
                }
                else {
                    _this.devices = tslib_1.__spread(_this.devices, res);
                }
            },
            error: function (error) {
                _this.isLoading.devices = false;
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    CommunicatingReportComponent.prototype.downloadAllCommDevices = function (attributes) {
        var _this = this;
        this.reportsService.downloadAllCommDevices(this.startTime, this.endTime, attributes).subscribe({
            next: function (res) {
                var contentDisposition = res.headers.get('Content-Disposition');
                var filename;
                if (contentDisposition == null) {
                    var date = new Date();
                    filename = 'Device_' + date + '.zip';
                }
                else {
                    filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
                }
                var blob = new Blob([res.body], {
                    type: 'application/zip'
                });
                FileSaver.saveAs(blob, filename);
            },
            error: function (error) {
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    CommunicatingReportComponent.prototype.drawPieGraph = function (data) {
        if (this.pieChart) {
            this.pieChart.destroy();
        }
        var global = this;
        var labels = [];
        var counts = [];
        var backgroundColor = [];
        data.map(function (item) {
            labels.push(item.type);
            counts.push(item.count);
        });
        var ctx = document.getElementById('pieChart');
        this.pieChart = new Chart(ctx, {
            type: 'pie',
            responsive: true,
            data: {
                labels: labels,
                datasets: [{
                        data: counts,
                        backgroundColor: global.colors,
                        borderColor: global.colors
                    }]
            },
            options: {
                legend: {
                    position: 'bottom',
                    display: false
                },
                scales: {
                    xAxes: [{
                            display: false,
                            ticks: {
                                beginAtZero: true
                            }
                        }],
                    yAxes: [{
                            display: false,
                            stacked: true,
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                },
                onClick: function (event, elem) {
                    var elem = elem[0];
                    if (!elem)
                        return; // check and return if not clicked on bar/data
                    console.log(elem);
                    global.selectedType = elem._view.label;
                    global.getCommTrendData(global.startTime, global.endTime, global.selectedCategory, elem._view.label, elem._view.backgroundColor);
                }
            }
        });
    };
    CommunicatingReportComponent.prototype.drawLineGraph = function (res, color) {
        var _this = this;
        if (this.lineChart) {
            this.lineChart.destroy();
        }
        var labels = [];
        var dataset = [];
        var highestCountsArray = [];
        if (res.length) {
            res[0].data.map(function (item) {
                var datePipe = new DatePipe('en-US');
                var eventDate = datePipe.transform(item.day, 'dd MMM yyyy');
                labels.push(eventDate);
            });
            res.map(function (item, index) {
                var obj = {};
                var counts = [];
                item.data.map(function (innerItem) {
                    counts.push(innerItem.count);
                });
                highestCountsArray.push(Math.max.apply(Math, tslib_1.__spread(counts)));
                obj.label = item.type;
                obj.data = counts;
                obj.backgroundColor = color;
                obj.borderColor = color;
                obj.fill = false;
                dataset.push(obj);
            });
            this.highestCount = Math.max.apply(Math, tslib_1.__spread(highestCountsArray));
        }
        var ctx = document.getElementById('lineChart');
        this.lineChart = new Chart(ctx, {
            type: 'line',
            responsive: true,
            data: {
                labels: labels,
                datasets: dataset
            },
            options: {
                legend: {
                    position: 'right',
                    display: false
                },
                scales: {
                    xAxes: [{
                            display: true,
                            ticks: {
                                beginAtZero: true
                            }
                        }],
                    yAxes: [{
                            display: true,
                            stacked: false,
                            ticks: {
                                beginAtZero: true,
                                suggestedMin: 0,
                                suggestedMax: 10,
                                stepSize: this.highestCount > 9 ? null : 1
                            }
                        }]
                },
                onClick: function (event, elements) {
                    var points = _this.lineChart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, false);
                    console.log('Points:', points); // Debug log
                    if (points.length > 0) {
                        var firstPoint = points[0];
                        console.log('First Point:', firstPoint); // Debug log
                        var label = _this.lineChart.data.labels[firstPoint._index];
                        var value = _this.lineChart.data.datasets[firstPoint._datasetIndex].data[firstPoint._index];
                        // Convert the label to a Date object if necessary
                        var date = new Date(label);
                        //this.devices = configMessage.reportsDeviceList;
                        // this.isLoading.devices = true;
                        // this.isLoading.devices = false;
                        // let tempDevices = [];
                        // configMessage.reportsDeviceList.forEach((tempDevice, index) => {
                        //   const d = new Date(tempDevice.lastCommunication);
                        //   d.setHours(0);
                        //   d.setMinutes(0);
                        //   d.setSeconds(0);
                        //   d.setMilliseconds(0);
                        //   if (d.getTime() >= date.getTime() && (this.displayCategory === "All" || this.displayCategory === tempDevice.groupName)) {
                        //     tempDevices.push(tempDevice);
                        //   }
                        // });
                        // this.displayTitle = "Communicating Devices as on " + label;
                        // this.devices = tempDevices;
                        if (date.getTime() < new Date("01-Aug-2024").getTime()) {
                            _this.devices = [];
                        }
                        else {
                            _this.getAllCommDevices(new Date("01-Aug-2024").getTime(), new Date("30-Aug-2024").getTime(), 0);
                        }
                    }
                },
                onHover: function (event, elements) {
                    var target = event.native ? event.native.target : event.currentTarget;
                    if (elements.length > 0) {
                        target.style.cursor = 'pointer'; // Only when hovering over a data point
                    }
                    else {
                        target.style.cursor = 'default'; // Default cursor when not hovering over a data point
                    }
                }
            }
        });
    };
    // To get the groups
    CommunicatingReportComponent.prototype.getGroups = function () {
        var _this = this;
        if (!this.groups.length) {
            var allGroupsOption_1 = { "name": "All", "groupId": "all" };
            this.groupsService.getAssignedGroups().subscribe(function (res) {
                _this.groups = res;
                _this.groups.unshift(allGroupsOption_1);
                _this.selectedCategory = "groupName";
                _this.displayCategory = res[0].name;
                _this.onTimeChange(null);
            }, function (error) {
                _this.sharedService.getErrorMsg(error);
            });
        }
    };
    CommunicatingReportComponent.prototype.sumCountsByDay = function (dataSets) {
        var sumByDayMap = new Map();
        dataSets.forEach(function (dataSet) {
            dataSet.data.forEach(function (item) {
                var currentCount = sumByDayMap.get(item.day) || 0;
                sumByDayMap.set(item.day, currentCount + item.count);
            });
        });
        // Convert the map back to an array if needed
        return Array.from(sumByDayMap, function (_a) {
            var _b = tslib_1.__read(_a, 2), day = _b[0], count = _b[1];
            return ({ day: day, count: count });
        });
    };
    return CommunicatingReportComponent;
}());
export { CommunicatingReportComponent };
