<app-notify-message></app-notify-message>
<app-reports-header></app-reports-header>
<div class="tab-content">
  <div role="tabpanel" class="tab-pane fade in active show relative" id="general">
    <div class="row no-gutters reports-page">
      <div class="p-0 col-sm-0" [ngClass]="{'col-md-2': !isSideMenuClose, 'no-width': isSideMenuClose}">
        <app-reports-side-menu></app-reports-side-menu>
      </div>
      <div class="reports-body col-sm-12 p-0" [ngClass]="{'col-md-10': !isSideMenuClose, 'col-md-12': isSideMenuClose}">
        <!-- Collapse/Expand reports side menu -->
        <div class="toggle-collapse-wrapper">
          <span
            *ngIf="!isSideMenuClose"
            tooltip="Collapse Side Panel"
            placement="right"
            (click)="onSideMenuToggle()"
            class="toggleBtn badge badge-secondary">
            <i class="fa fa-angle-double-left font-14" aria-hidden="true"></i>
          </span>
          <span
            *ngIf="isSideMenuClose"
            tooltip="Expand Side Panel"
            placement="right"
            (click)="onSideMenuToggle()"
            class="toggleBtn badge badge-secondary">
            <i class="fa fa-angle-double-right font-14" aria-hidden="true"></i>
          </span>
        </div>
        <!-- Reports content along with the action row -->
        <div class="row no-gutters action-row align-items-center px-2">
          <div class="col-lg-2 col-md-12">
            <h4 class="report-heading page-heading"><i class="fa fa-bar-chart"></i> Trip Summary</h4>
          </div>
          <div class="col-lg-10 col-md-12 text-right">
            <app-search-select-device
              [defaultselectdevice]="true"
              [autoSelect]="true"
              [selectSearchCategory]="true"
              [querySelectDevice]="querySelectVehicle"
              (deviceChangeEvent)="changeVehicle($event)"
              >
            </app-search-select-device>
            <div class="calendar ml-2 d-inline-block" (clickOutside)="hideCalendar=!hideCalendar;">
              <app-date-time-calendar
                [clickOutside]="hideCalendar"
                [selectPreset]="'30days'"
                [vehicle]="selectedVehicle"
                (timeChangeEvent)="onTimeChange($event)"
              >
              </app-date-time-calendar>
            </div>
            <button
              class="btn btn-icon ml-2 refresh"
              (click)="refresh()"
              tooltip="Refresh"
              placement="top"
              [disabled]="isLoading">
              <i class="fa fa-refresh" aria-hidden="true"></i>
              <span class="mob-access-text d-none">&nbsp;Refresh</span>
            </button>
            <button
              class="btn btn-icon ml-2 download"
              tooltip="Download" placement="top"
              (click)="downloadCsv()"
              [disabled]="!trips.length">
              <i class="fa fa-cloud-download"></i>
              <span class="mob-access-text d-none">&nbsp;Download</span>
            </button>
          </div>
        </div>
        <div class="row no-gutters">
          <div class="col-md-12 px-2 py-3">
            <div class="report-item-container">
              <div class="page-loading" *ngIf="pageLoading">
                <img src="../../../assets/img/darby_loader_small.gif"/>
              </div>
              <div *ngIf="!trips.length && !pageLoading" class="text-center mt-5 py-5">
                <img width="170" src="../../../assets/img/info_nodata_256.png"/>
                <h4 class="mt-3">No data to show</h4>
              </div>
              <ngx-datatable
                *ngIf="trips.length" id="tableau" #dataTable
                class="bootstrap alertTable reportsTable wrapcell"
                [rows]="trips"
                [columnMode]="'force'"
                [headerHeight]="50"
                [footerHeight]="50"
                [rowHeight]="50"
                [summaryRow]="false"
                [scrollbarH]="true"
                [reorderable]="true"
                [limit]="pageInfo.limit"
                [offset]="pageInfo.offset"
                [count]="pageInfo.count"
                (page)="pageCallback($event)"
                >
                <!--<ng-template *ngIf="trips.length && selectedTrip" let-row="row"">-->
                <ngx-datatable-column [resizeable]="false" name="Trip #" prop="tripNumber" [width]="70">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.tripNumber}}
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [resizeable]="false" [sortable]="true" prop="tsTime" name="Trip Start">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.tsTime | date: dateFormat}}
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [resizeable]="false" [sortable]="false" name="Trip End">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.teTime | date: dateFormat}}
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [resizeable]="false" [sortable]="true" prop="haltTime" name="Stop Time">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.haltTime | secondsToHms: 'milliseconds'}}
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [resizeable]="false" [sortable]="true" prop="distanceTravelled" name="Distance">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.distanceTravelled | distanceMetricspipe:'1'}}
                    <span *ngIf="row.distanceTravelled">{{distanceMetrics}}</span>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [resizeable]="false" [sortable]="true" prop="tripTime" name="Trip Time">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.tripTime | secondsToHms}}
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [resizeable]="false" [sortable]="false" name="Calculated Fuel Consumed">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.calculatedFuelConsumed}}
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [resizeable]="false" [sortable]="false" name="Idle Time" >
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.tripIdleTime | secondsToHms}}
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [resizeable]="false" [sortable]="false" name="Max Speed" >
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.maxSpeed}}
                    <span *ngIf="row.maxSpeed"> {{speedMetrics}}</span>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [resizeable]="false" [sortable]="false" name="Avg Speed" >
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.avgSpeed}}
                    <span *ngIf="row.avgSpeed"> {{speedMetrics}}</span>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [resizeable]="false" [sortable]="false" name="Speed Events" >
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.highSpeedEventCounts}}
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [resizeable]="false" [sortable]="false" name="Speeding Duration">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.highSpeedDuration | secondsToHms}}
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [resizeable]="false" [sortable]="false" name="Hard Acceleration Events">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.hardAcclCounts}}
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [resizeable]="false" [sortable]="false" name="Hard Breaking Events">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.hardBreakingCounts}}
                  </ng-template>
                </ngx-datatable-column>
                <!--</ng-template>-->
              </ngx-datatable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
