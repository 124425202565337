import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '@shared/shared.service';
import { Router } from '@angular/router';
import { enMessage } from '@shared/en-us-message';

@Component({
  selector: 'app-notify-message',
  templateUrl: './notify-message.component.html',
  styleUrls: ['./notify-message.component.scss']
})

export class NotifyMessageComponent implements OnInit {
  responseMessage: any = {};
  translateMessage: string;

  constructor(public router: Router, public translate: TranslateService, public sharedService: SharedService) {
    this.sharedService.changeEmittedNotify$.subscribe(data => {
      if (data.type == 'popup') {
        this.responseMessage = data;
        const errorArgs = {};
        if (this.responseMessage.error.args) {
          this.responseMessage.error.args.forEach((item, i) => {
            errorArgs[i] = item;
          });
        }
        // if(errorArgs) {
        this.translate.get(this.responseMessage.error.errorCode, errorArgs).subscribe((res: string) => {
          this.translateMessage = res;
        });
        // } else {
        //   this.translate.get(this.responseMessage.message).subscribe((res: string) => {
        //     this.translateMessage = res;
        //   });
        // }
      } else if (data.success == 0 && data.message) {
        this.responseMessage = data;
        const errorArgs = {};
        if (this.responseMessage && this.responseMessage.error && this.responseMessage.error.args) {
          this.responseMessage.error.args.forEach((item, i) => {
            errorArgs[i] = item;
          });
        }
        if (errorArgs && Object.keys(errorArgs).length !== 0) {
          this.translate.get(this.responseMessage.error.errorCode, errorArgs).subscribe((res: string) => {
            this.translateMessage = res;
          });
        } else {
          this.translate.get(this.responseMessage.message).subscribe((res: string) => {
            this.translateMessage = res;
          });
        }
        setTimeout(() => {
          this.responseMessage = {};
        }, enMessage.timeOut);
      } else if (data.success == 0 && data.custommessage) {
        this.responseMessage = data;
        this.translate.get(this.responseMessage.custommessage).subscribe((res: string) => {
          this.translateMessage = res;
        });
        setTimeout(() => {
          this.responseMessage = {};
        }, enMessage.timeOut);
      } else if (data.success == 1 && data.message) {
        this.responseMessage = data;
        this.translate.get(this.responseMessage.message).subscribe((res: string) => {
          this.translateMessage = res;
        });
        setTimeout(() => {
          this.responseMessage = {};
        }, enMessage.timeOut);
      }
      else if (data.success == 'noInternet') {
        this.responseMessage = data;
        this.translate.get(this.responseMessage.message).subscribe((res: string) => {
          this.translateMessage = res;
        });
      } else if (data.success == 'internetIsBack') {
        this.responseMessage = data;
        this.translate.get(this.responseMessage.message).subscribe((res: string) => {
          this.translateMessage = res;
        });
        setTimeout(() => {
          this.responseMessage = {};
        }, 1);
      }
    });
  }

  ngOnInit() {

  }

  reloadPage() {
    window.location.reload();
  }

}
