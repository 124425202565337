import { PipeTransform } from '@angular/core';
var DistanceMetricsPipe = /** @class */ (function () {
    function DistanceMetricsPipe() {
    }
    DistanceMetricsPipe.prototype.transform = function (d, constraint) {
        if (d === undefined || d === null) {
            return null;
        }
        else {
            //if distancemetrics value getting fromm logged in write condition here 
            if (constraint && constraint == "1") {
                return (d * 0.6214).toFixed(1);
            }
            else
                return Math.floor(d * 0.6214);
        }
    };
    return DistanceMetricsPipe;
}());
export { DistanceMetricsPipe };
