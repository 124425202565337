<app-notify-message></app-notify-message>
<app-reports-header></app-reports-header>
<div class="tab-content">
  <div role="tabpanel" class="tab-pane fade in active show relative" id="general">
    <div class="row no-gutters reports-page">
      <div class="p-0 col-sm-0" [ngClass]="{'col-md-2': !isSideMenuClose, 'no-width': isSideMenuClose}">
        <app-reports-side-menu (selectReport)="getReport($event)"></app-reports-side-menu>
      </div>
      <div class="reports-body col-sm-12 p-0" [ngClass]="{'col-md-10': !isSideMenuClose, 'col-md-12': isSideMenuClose}">
        <!-- Collapse/Expand reports side menu -->
        <div class="toggle-collapse-wrapper">
          <span
            *ngIf="!isSideMenuClose"
            tooltip="Collapse Side Panel"
            placement="right"
            (click)="onSideMenuToggle()"
            class="toggleBtn badge badge-secondary">
            <i class="fa fa-angle-double-left font-14" aria-hidden="true"></i>
          </span>
          <span
            *ngIf="isSideMenuClose"
            tooltip="Expand Side Panel"
            placement="right"
            (click)="onSideMenuToggle()"
            class="toggleBtn badge badge-secondary">
            <i class="fa fa-angle-double-right font-14" aria-hidden="true"></i>
          </span>
        </div>
        <!-- Reports content along with the action row -->
        <div class="row no-gutters action-row align-items-center pr-2 pl-2">
          <div class="col-md-5">
            <h4 class="report-heading page-heading"><i class="fa fa-bar-chart"></i> {{currentReport.name}}</h4>
          </div>
          <div class="col-md-7 text-right mobile-design">
            <div class="calendar d-inline-block pr-1 pl-3" (clickOutside)="hideCalendar=!hideCalendar;">
              <app-date-time-calendar
                [clickOutside]="hideCalendar"
                [selectPreset]="'today'"
                (timeChangeEvent)="onTimeChange($event)">
              </app-date-time-calendar>
            </div>
          </div>
        </div>
        <div class="row no-gutters text-center">
          <form (ngSubmit)="f.form.valid" #f="ngForm" novalidate
          class="multi-vehicle-form mb-0 ng-pristine ng-invalid ng-touched">
            <div class="col-12 px-0 mb-3 multi-vehicle-select text-center">
              <app-search-select-vehicle
                [closeOnSelect]="false"
                isMulti="true"
                (vehicleChangeEvent)="addVehicle($event)"
                [autoSelect]="true"
                [querySelectVehicle]="querySelectVehicle"
              >
              </app-search-select-vehicle>
              <small class="text-danger" *ngIf="noVehiclesSelected">
                Please select atleast one vehicle.
              </small>
            </div>
            <div class="col-12 px-0 mb-4 message-select text-center">
              <input
                type="number"
                name="messageCount"
                id="messageCount"
                min="0" max="100"
                class="form-control h5-email"
                placeholder="Number of Messages"
                value=""
                autocomplete="off"
                tabindex="1"
                [(ngModel)]="searchModel.messageCount"
                #messageCount="ngModel"
                required>
              <div class="text-left" [hidden]="messageCount.valid || (messageCount.pristine && !f.submitted)">
                <small class="text-danger">Please enter message count</small>
              </div>
              <div class="text-left" *ngIf="(searchModel.messageCount < 1 || searchModel.messageCount > 100) && searchModel.messageCount !== 'undefined' && searchModel.messageCount !== ''">
                <small class="text-danger">
                  Number must be in range of 1 to 100
                </small>
              </div>
            </div>
            <div class="col-12 px-0 text-center">
              <button type="button" (click)="downloadReportCSV()"
                [disabled]="searchModel.messageCount == '' || !this.searchModel.vehicle.length || (searchModel.messageCount < 1 || searchModel.messageCount > 100)"
                class="btn btn-primary">
                <i class="fa fa-cloud-download mr-2"></i>Download
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>