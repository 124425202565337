import { Component, OnInit } from '@angular/core';
import { SharedService } from '@myproject/shared/shared.service';
import { environment } from '@myenv/environment';
import { GraphicReportsService } from '../../graphic-reports.service';
import * as FileSaver from 'file-saver';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-log-in-out-report',
  templateUrl: './log-in-out-report.component.html',
  styleUrls: ['./log-in-out-report.component.scss']
})
export class LogInOutReportComponent implements OnInit {
  public dateFormat = environment.isoDateTimeFormat;
  pageLoading = true;
  users: any[] = [];
  activity: any[] = [];
  searchModel: any = {};
  isLoading = false;
  startTime: number;
  endTime: number;
  page = 0;
  currentPageCount: any = {
    start: 0,
    end: 0
  };
  pageInfo: any = {
    offset: 0,
    count: 0,
    limit: 10,
    pageSize: 0,
    lastPage: 0,
  };
  prevResponse = 0;
  hideCalendar = false;

  constructor(
    private sharedService: SharedService,
    private reportsService: GraphicReportsService,
    private title: Title
  ) { }

  ngOnInit() {
    this.title.setTitle('Log-in Activity'+ environment.title_text);
  }

  onTimeChange(e) {
    this.startTime = e.startTime;
    this.endTime = e.endTime;
    if (this.users.length) {
      this.page = 0;
      this.getData(this.page);
    } else {
      this.getUsers();
    }
  }

  refresh() {
    this.page = 0;
    this.pageLoading = true;
    this.getData(this.page);
  }

  getUsers() {
    this.sharedService.getUsers().subscribe({
      next: res => {
        this.users = res;
        this.searchModel.userId = res[0].userId;
        this.getData(this.page);
      },
      error: error => {
        this.pageLoading = false;
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  getData(page) {
    this.page = page;
    this.reportsService.getLogInOutActivity(this.searchModel.userId, this.startTime, this.endTime, page).subscribe({
      next: res => {
        this.prevResponse = res.length;
        if (page === 0) {
          this.activity = res;
        } else {
          this.activity = [...this.activity, ...res];
        }
        this.pageLoading = false;
      },
      error: error => {
        this.pageLoading = false;
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  downloadCsv() {
    this.reportsService.downloadLogInOutActivity(this.searchModel.userId, this.startTime, this.endTime).subscribe({
      next: res => {
        const contentDisposition = res.headers.get('Content-Disposition');
        let filename;
        if (contentDisposition == null) {
          const date = new Date();
          filename = 'Login_Activity_' + date + '.csv';
        } else {
          filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
        }
        const blob = new Blob([res.body], {
          type: 'text/csv;charset=utf-8'
        });
        FileSaver.saveAs(blob, filename);
      },
      error: error => {
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  // searchUsers(e) {}

  // clearSearch() {}

  // To get the vehicles for each page in the table
  pageCallback(value) {
    this.pageInfo.offset = value.offset;
    this.currentPageCount.start = (value.offset * value.pageSize) + 1;
    const lastPage = Math.floor(this.activity.length / 10);
    if (lastPage == value.offset) {
      this.currentPageCount.end = value.count;
    } else {
      this.currentPageCount.end = (value.offset * value.pageSize) + value.limit;
    }
    if (this.pageInfo.lastPage === (this.pageInfo.offset + 1)) {
      if (this.prevResponse) {
        this.page = ++this.page;
        this.getData(this.page);
      }
    }
  }

}
