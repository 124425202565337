import { Component, OnInit } from '@angular/core';
import { GraphicReportsService } from '../../graphic-reports.service';
import { SharedService } from '../../../../shared/shared.service';
import Chart from 'chart.js';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { configMessage } from '../../../../shared/config-message';
import * as FileSaver from 'file-saver';
import { environment } from '@myenv/environment';
declare var $: any;

@Component({
  selector: 'app-metrics-report',
  templateUrl: './metrics-report.component.html',
  styleUrls: ['./metrics-report.component.scss']
})
export class MetricsReportComponent implements OnInit {
  messageTypeColor = configMessage.messageTypeColor;
  permissionModules = [];
  devices: any[] = [];
  isLoading = false;
  stackedChart: any;
  startTime: number;
  endTime: number;
  selectedVehicle: string = null;
  querySelectVehicle = null;
  searchModel: any = {
    value: null
  };
  hideCalendar = false;
  highestCount = 0;
  chartLabels = [];
  stackedDataset = [];
  totalsDataset = [];
  showTotalChart = false;
  isSideMenuClose = false;

  constructor(
    private reportsService: GraphicReportsService,
    private sharedService: SharedService,
    private title: Title,
  ) { }

  ngOnInit() {
    this.title.setTitle('Messages' + environment.title_text);
    this.permissionModules = JSON.parse(localStorage.getItem('permissionModules'));
  }

  onTimeChange(e) {
    this.startTime = e.startTime;
    this.endTime = e.endTime;
    if (this.selectedVehicle) {
      this.getVehicleMessageData();
    }
  }

  changeVehicle(e) {
    // if (localStorage.getItem('reportsVehicle') && this.selectedVehicle == null) {
    //   this.setItem();
    // } else {
    //   // if (e !== null) {
    //   localStorage.setItem('reportsVehicle', JSON.stringify(e));
    //   this.selectedVehicle = e.deviceId;
    // }
    this.selectedVehicle = e ? e.deviceId : null;
    this.querySelectVehicle = e ? e : null;
    if (this.startTime && this.endTime) {
      this.getVehicleMessageData();
    }
  }

  onSideMenuToggle() {
    this.isSideMenuClose = !this.isSideMenuClose;
  }

  setItem() {
    let reportsItem = JSON.parse(localStorage.getItem('reportsVehicle'));
    this.querySelectVehicle = reportsItem;
    this.selectedVehicle = reportsItem.deviceId;
  }

  refresh() {
    this.getVehicleMessageData();
  }

  toggleChart() {
    if (this.showTotalChart) {
      this.drawStackedBarGraph(this.chartLabels, this.totalsDataset);
    } else {
      this.drawStackedBarGraph(this.chartLabels, this.stackedDataset);
    }
  }

  getVehicleMessageData() {
    this.isLoading = true;
    const startTime = new Date(this.startTime);
    const endTime = new Date(this.endTime);
    const start = `${startTime.getFullYear()}-${startTime.getMonth() + 1}-${startTime.getDate()}`;
    const end = `${endTime.getFullYear()}-${endTime.getMonth() + 1}-${endTime.getDate()}`;
    this.reportsService.getVehicleMessageData(this.selectedVehicle, start, end).subscribe({
      next: res => {
        this.sortData(res);
      },
      error: error => {
        this.isLoading = false;
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  sortData(res) {
    this.highestCount = 0;
    const labels = [];
    const keys = [];
    const backgroundColors = [];
    const dataset = [];
    const totalCounts = [];
    // find all the distinct messages
    res.forEach(item => {
      for (var k in item) {
        if (!keys.includes(k) && k !== 'date' && k !== 'NO_DATA') {
          if (this.messageTypeColor[k]) {
            backgroundColors.push(this.messageTypeColor[k].colorOpaque);
          } else {
            backgroundColors.push(this.messageTypeColor['UNKNOWN'].colorOpaque);
          }
          keys.push(k);
        }
      }
    });
    // Obtain date labels and total counts for each day
    res.map(item => {
      var datePipe = new DatePipe('en-US');
      let eventDate = datePipe.transform(item.date, 'EE, dd MMM');
      labels.push(eventDate);
      let total = 0;
      for (let k in item) {
        if (k !== 'date' && item[k]) {
          total += item[k];
        }
      }
      totalCounts.push(total);
    });
    // get message count and configure dataset
    const highestCountsArray = [];
    for (let i = 0; i < keys.length; i++) {
      const obj: any = {};
      const counts = [];
      res.map(item => {
        if (item[keys[i]]) {
          counts.push(item[keys[i]]);
        } else {
          counts.push(0);
        }
      });
      obj.label = keys[i];
      // obj.label = configMessage.messageType[keys[i]];
      obj.backgroundColor = backgroundColors[i];
      obj.data = counts;
      dataset.push(obj);
      highestCountsArray.push(Math.max(...counts));
    }
    this.chartLabels = labels;
    this.stackedDataset = dataset;
    this.totalsDataset = [{
      label: 'Total Messages',
      backgroundColor: 'rgb(54, 162, 235, 1)',
      data: totalCounts
    }];
    this.highestCount = Math.max(...highestCountsArray);
    this.toggleChart();
    this.isLoading = false;
  }

  drawStackedBarGraph(labels, dataset) {
    if (this.stackedChart) {
      this.stackedChart.destroy();
    }
    var ctx = document.getElementById('stackedBarGraph');
    this.stackedChart = new Chart(ctx, {
      type: 'bar',
      responsive: true,
      data: {
        labels: labels,
        datasets: dataset
      },
      options: {
        legend: {
          position: 'bottom',
          display: true,
          onHover: function(event, legendItem) {
            document.getElementById('canvas').style.cursor = 'pointer';
          },
          onClick: function(e, legendItem) {
            var index = legendItem.datasetIndex;
            var ci = this.chart;
            var alreadyHidden = (ci.getDatasetMeta(index).hidden === null) ? false : ci.getDatasetMeta(index).hidden;
            var anyOthersAlreadyHidden = false;
            var allOthersHidden = true;
            // figure out the current state of the labels
            ci.data.datasets.forEach(function(e, i) {
              var meta = ci.getDatasetMeta(i);
              if (i !== index) {
                if (meta.hidden) {
                  anyOthersAlreadyHidden = true;
                } else {
                  allOthersHidden = false;
                }
              }
            });
            // if the label we clicked is already hidden 
            // then we now want to unhide (with any others already unhidden)
            if (alreadyHidden) {
              ci.getDatasetMeta(index).hidden = null;
            } else { 
              // otherwise, lets figure out how to toggle visibility based upon the current state
              ci.data.datasets.forEach(function(e, i) {
                var meta = ci.getDatasetMeta(i);
                if (i !== index) {
                  // handles logic when we click on visible hidden label and there is currently at least
                  // one other label that is visible and at least one other label already hidden
                  // (we want to keep those already hidden still hidden)
                  if (anyOthersAlreadyHidden && !allOthersHidden) {
                    meta.hidden = true;
                  } else {
                    // toggle visibility
                    meta.hidden = meta.hidden === null ? !meta.hidden : null;
                  }
                } else {
                  meta.hidden = null;
                }
              });
            }
            ci.update();
          },
        },
        scales: {
          xAxes: [{
            display: true,
            stacked: true
          }],
          yAxes: [{
            display: true,
            stacked: true,
            ticks: {
              suggestedMin: 0,
              suggestedMax: 10,
              stepSize: this.highestCount > 9 ? null : 1
            }
          }]
        }
      }
    });
    // document.getElementById('js-legend').innerHTML = this.stackedChart.generateLegend();
    // this.legendEvent();
  }

  // legendEvent() {
  //   const global = this;
  //   $('#js-legend > ul > li').on('click', function (e) {
  //     var index = $(this).index();
  //     $(this).toggleClass('strike');
  //     var ci = global.stackedChart;
  //     var alreadyHidden = (ci.getDatasetMeta(index).hidden === null) ? false : ci.getDatasetMeta(index).hidden;
  //     var anyOthersAlreadyHidden = false;
  //     var allOthersHidden = true;
  //     // figure out the current state of the labels
  //     ci.data.datasets.forEach(function(e, i) {
  //       var meta = ci.getDatasetMeta(i);
  //       if (i !== index) {
  //         if (meta.hidden) {
  //           anyOthersAlreadyHidden = true;
  //         } else {
  //           allOthersHidden = false;
  //         }
  //       }
  //     });
  //     // if the label we clicked is already hidden
  //     // then we now want to unhide (with any others already unhidden)
  //     if (alreadyHidden) {
  //       ci.getDatasetMeta(index).hidden = null;
  //     } else {
  //       // otherwise, lets figure out how to toggle visibility based upon the current state
  //       ci.data.datasets.forEach(function(e, i) {
  //         var meta = ci.getDatasetMeta(i);
  //         if (i !== index) {
  //           // handles logic when we click on visible hidden label and there is currently at least
  //           // one other label that is visible and at least one other label already hidden
  //           // (we want to keep those already hidden still hidden)
  //           if (anyOthersAlreadyHidden && !allOthersHidden) {
  //             meta.hidden = true;
  //           } else {
  //             // toggle visibility
  //             meta.hidden = meta.hidden === null ? !meta.hidden : null;
  //           }
  //         } else {
  //           meta.hidden = null;
  //         }
  //       });
  //     }
  //     ci.update();
  //     // var meta = ci.getDatasetMeta(index);
  //     // meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;
  //     // ci.update();
  //   });
  // }

  // To download chart data as a csv file
  downloadCSV() {
    const startTime = new Date(this.startTime);
    const endTime = new Date(this.endTime);
    const start = `${startTime.getFullYear()}-${startTime.getMonth() + 1}-${startTime.getDate()}`;
    const end = `${endTime.getFullYear()}-${endTime.getMonth() + 1}-${endTime.getDate()}`;
    this.reportsService.getMessageDataCSV(this.selectedVehicle, start, end).subscribe(res => {
      const contentDisposition = res.headers.get('Content-Disposition');
      let filename;
      if (contentDisposition == null) {
        const date = new Date();
        filename = 'Messages_' + date + '.csv';
      } else {
        filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
      }
      const blob = new Blob([res.body], {
        type: 'text/csv;charset=utf-8'
      });
      FileSaver.saveAs(blob, filename);
    }, error => {
      this.sharedService.getErrorMsg(error);
    });
  }
}
