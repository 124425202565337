<div class="">
    <label class="mt-1 bold page-sub-heading" *ngIf="hourLineData.length && showLabel" style="float: left; padding: 2px 0px;">Time Seeker:  &nbsp;</label>
    <ul class="hourline">
        <ng-container *ngFor="let hourRow of hourLineData; let ii = index">
            <!-- <li [class.left]="i === 0" *ngIf="hourRow.dayChange && i == 0">
                <span>
                    May
                </span>
                <div class="">
                    {{ hourRow.dayChange }}
                </div>
            </li> -->
            <!-- [tooltip]="((hourRow.dayChange && i == 0) || (hourRow.dayChange && i !== 0)) ? month[hourRow.dayMonth] : ''"  -->
            <li *ngIf="hourRow.count > 0 && !((hourRow.dayChange && ii === 0) || (hourRow.dayChange && ii !== 0))" (click)="onTimeClick(hourRow, ii)" [class.selected]="activeSeeker.includes(hourRow.ts)" [class.avail]="hourRow.count > 0" [class.right]="ii === (hourLineData.length - 1)" [class.left]="ii === 0">
                <div class="hour">{{ hourRow.label === 0 ? '12' : hourRow.label }}</div>
                <u>{{ hourRow.day }}</u>
            </li>
            <li *ngIf="hourRow.count === 0 && !((hourRow.dayChange && ii === 0) || (hourRow.dayChange && ii !== 0))" (click)="onTimeClick(hourRow, ii)" [class.selected]="activeSeeker.includes(hourRow.ts)" [class.left]="ii === 0" [class.right]="ii === (hourLineData.length - 1)">
                <div class="hour">{{ hourRow.label === 0 ? '12' : hourRow.label }}</div>
                <u>{{ hourRow.day }}</u>
            </li>
            <li *ngIf="hourRow.count > 0 && ((hourRow.dayChange && ii === 0) || (hourRow.dayChange && ii !== 0))" [tooltip]="hourRow.dayChange + ' ' + month[hourRow.dayMonth]" (click)="onTimeClick(hourRow, ii)" [class.selected]="activeSeeker.includes(hourRow.ts)" [class.avail]="hourRow.count > 0" [class.right]="ii === (hourLineData.length - 1)" [class.left]="ii === 0">
                <div class="hour">{{ hourRow.label === 0 ? '12' : hourRow.label }}</div>
                <u>{{ hourRow.day }}</u>
            </li>
            <li *ngIf="hourRow.count === 0 && ((hourRow.dayChange && ii === 0) || (hourRow.dayChange && ii !== 0))" [tooltip]="hourRow.dayChange + ' ' + month[hourRow.dayMonth]" (click)="onTimeClick(hourRow, ii)" [class.selected]="activeSeeker.includes(hourRow.ts)" [class.left]="ii === 0" [class.right]="ii === (hourLineData.length - 1)">
                <div class="hour">{{ hourRow.label === 0 ? '12' : hourRow.label }}</div>
                <u>{{ hourRow.day }}</u>
            </li>
            <!-- <li *ngIf="hourRow.dayChange && i !== 0">
                <div class="">
                    {{ hourRow.dayChange }}
                </div>
            </li> -->
        </ng-container>
    </ul>
</div>