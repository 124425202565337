import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '@myenv/environment';
var MarketplaceService = /** @class */ (function () {
    function MarketplaceService(http, router) {
        this.http = http;
        this.router = router;
        this.apiUrl = environment.apiUrl;
    }
    return MarketplaceService;
}());
export { MarketplaceService };
