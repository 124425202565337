import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss']
})
export class DashboardHeaderComponent implements OnInit {
  permissionModules = [];

  constructor() { }

  ngOnInit() {
    this.permissionModules = JSON.parse(localStorage.getItem('permissionModules'));
  }

}
