import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '@myenv/environment';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { Router } from '@angular/router';
var SupportService = /** @class */ (function () {
    // currentuser = JSON.parse(localStorage.getItem('currentUser'));
    function SupportService(http, router, handler) {
        this.http = http;
        this.router = router;
        this.handler = handler;
        this.apiUrl = environment.apiUrl;
        this.httpClient = new HttpClient(handler);
    }
    SupportService.prototype.onSearchUser = function (key, value) {
        return this.http.get(this.apiUrl + "users/search?key=" + key + "&value=" + value).pipe(catchError(this.handleError));
    };
    SupportService.prototype.getLoginAttempts = function (userId) {
        return this.http.get(this.apiUrl + "password/" + userId).pipe(catchError(this.handleError));
    };
    SupportService.prototype.getUser = function (userId) {
        return this.http.get(this.apiUrl + "users/" + userId + "?address=true").pipe(catchError(this.handleError));
    };
    SupportService.prototype.unlockUser = function (userName) {
        return this.http.patch(this.apiUrl + "users/login-attempt?userName=" + userName, {}).pipe(catchError(this.handleError));
    };
    SupportService.prototype.getNotCommDevices = function (page, hours, customerId) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
        return this.http.get(this.apiUrl + ("devices/not-communicating/support?page=" + page + "&inHours=" + hours + "&customerId=" + customerId), { headers: headers }).pipe(catchError(this.handleError));
    };
    SupportService.prototype.getDevicesCount = function (hours, customerId) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
        return this.http.get(this.apiUrl + ("devices/not-communicating/support/count?inHours=" + hours + "&customerId=" + customerId), { headers: headers }).pipe(catchError(this.handleError));
    };
    // onSearchCampaign(type, data) {
    //   if (type && data) {
    //     const token = localStorage.getItem('token');
    //     const headers = new HttpHeaders({
    //       'Authorization': 'Bearer ' + token,
    //     });
    //     let tempUrl = this.apiUrl + 'support/campaign/search';
    //     tempUrl += '?key=' + type + '&value=' + data
    //     return this.httpClient.get(tempUrl, { headers })
    //       .catch(this.handleError);
    //   }
    // }
    // getCounts(campaignId) {
    //   return this.http.get(this.apiUrl + 'support/campaign/' + campaignId + '/device/count')
    //     .catch(this.handleError)
    // }
    // addCampaign(data) {
    //   let token = localStorage.getItem('token');
    //   const headers = new HttpHeaders({
    //     'Authorization': 'Bearer ' + token
    //   });
    //   return this.http.post(this.apiUrl + 'support/campaign', data, { headers })
    //     .catch(this.handleError);
    // }
    // getAllCampaign(page) {
    //   const token = localStorage.getItem('token');
    //   const headers = new HttpHeaders({
    //     'Authorization': 'Bearer ' + token,
    //   });
    //   return this.http.get(this.apiUrl + 'support/campaign?page=' + page, { headers })
    //     .catch(this.handleError);
    // }
    // getCampaignCount() {
    //   return this.http.get(this.apiUrl + 'support/campaign/count')
    //     .catch(this.handleError);
    // }
    // getSupportOtaFiletype(data) {
    //   return this.http.get(this.apiUrl + 'support/ota/files/fileType/' + data)
    //     .catch(this.handleError);
    // }
    // getAllSupportOtaFiles(page) {
    //   return this.http.get(this.apiUrl + 'support/ota/files?page=' + page)
    //     .catch(this.handleError);
    // }
    // uploadXL(data, fileName, otaName) {
    //   let token = localStorage.getItem('token');
    //   const headers = new HttpHeaders({
    //     'Authorization': 'Bearer ' + token,
    //   });
    //   let tempUrl = this.apiUrl + 'support/ota/files/' + otaName + '/upload?fileName=' + fileName;
    //   return this.httpClient.post(tempUrl, data, { headers })
    //     .catch(this.handleError);
    // }
    SupportService.prototype.getGroupCounts = function (customerId) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this.http.get(this.apiUrl + ("devices/group/count?customerId=" + customerId), { headers: headers }).pipe(catchError(this.handleError));
    };
    SupportService.prototype.getTagCounts = function (customerId, groupBy) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this.http.get(this.apiUrl + ("devices/category/count?groupBy=" + groupBy + "&customerId=" + customerId), { headers: headers }).pipe(catchError(this.handleError));
    };
    SupportService.prototype.getProvisioningDevices = function (page, customerId) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this.http.get(this.apiUrl + ("al/vehicles/provisioning/devices/customerId/" + customerId + "?page=" + page + "&limit=100"), { headers: headers }).pipe(catchError(this.handleError));
    };
    SupportService.prototype.provisionDeviceSearch = function (page, key, value, customerId) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this.http.get(this.apiUrl +
            ("al/vehicles/provisioning/search/customerId/" + customerId + "?key=" + key + "&value=" + value + "&page=" + page + "&limit=" + 100), { headers: headers }).pipe(catchError(this.handleError));
    };
    SupportService.prototype.uploadCertificates = function (data, psn, status) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this.httpClient.post(this.apiUrl + ("al/vehicles/provisioning/doc/status/ais140/psn/" + psn + "/status/" + status), data, { headers: headers }).pipe(catchError(this.handleError));
    };
    SupportService.prototype.downloadKYC = function (psn) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this.http.get(this.apiUrl + ("al/vehicles/provisioning/kyc/psn/" + psn + "/download"), { headers: headers, responseType: 'arraybuffer' }).pipe(catchError(this.handleError));
    };
    SupportService.prototype.activateDevice = function (requestId, psn, status, notify, remarks) {
        return this.http.put(this.apiUrl +
            ("al/vehicles/provisioning/requestId/" + requestId + "/psn/" + psn + "/status/" + status + "/notify/" + notify + "?remark=" + remarks), {})
            .pipe(catchError(this.handleError));
    };
    SupportService.prototype.retriggerDevice = function (requestId, psn) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this.httpClient.post(this.apiUrl + ("al/vehicles/provisioning/cco/requestId/" + requestId + "/psn/" + psn), {}, { headers: headers }).pipe(catchError(this.handleError));
    };
    SupportService.prototype.handleError = function (error) {
        return observableThrowError(error.error || error || 'Server error');
    };
    return SupportService;
}());
export { SupportService };
