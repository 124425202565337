<!-- tab selection start -->
<div class="support-content" *ngIf="checkLinkActive()">
    <ul class="nav nav-tabs detailed-tabs relative page-sub-heading" role="tablist">
        <li class="nav-item" routerLinkActive="active"
            *ngIf="permissionmodules.includes('TRIPS') || permissionmodules.includes('DATA')">
            <a id="visualizationTab" class="nav-link text-center mt-1" [routerLink]="[permissionmodules.includes('TRIPS') ? '/trips' : '/data/messages']" [class.active]="
            router.isActive('/location-history', false) ||
            router.isActive('/location-history?searchBy=&value=', false) ||
            router.isActive('/trips', false) ||
            router.isActive('/data/messages', true) ||
            tabPos === 0
            " (click)="tabPos = 0;" href="#visualization">
                <i class="fa fa-line-chart"></i> Visualization
            </a>
        </li>
        <li class="nav-item" routerLinkActive="active" *ngIf="permissionmodules.includes('DESTINATION')">
            <a [routerLink]="['/destinations']" (click)="tabPos = 1;" routerLinkActive="active"
                class="nav-link text-center mt-1" href="#destinations">
                <i class="fa fa-random"></i> Destinations
            </a>
        </li>
        <li class="nav-item" routerLinkActive="active" *ngIf="permissionmodules.includes('DATASIMULATOR')">
            <a [routerLink]="['/data-simulator']" (click)="tabPos = 2;" routerLinkActive="active"
                class="nav-link text-center mt-1" href="#simulator">
                <i class="fa fa-tripadvisor"></i> Data Simulator
            </a>
        </li>
    </ul>
    <ul class="nav nav-tabs detailed-tabs relative page-sub-heading" *ngIf="tabPos === 0" role="tablist">
        <!-- <li class="nav-item" routerLinkActive="active" *ngIf="permissionmodules.includes('LIVE')">
            <a [routerLink]="['/live']" routerLinkActive="active" class="nav-link text-center mt-1" href="#live">
                <i class="fa fa-map-marker"></i> Live
            </a>
        </li> -->
        <li class="nav-item" routerLinkActive="active" *ngIf="permissionmodules.includes('TRIPS')">
            <a [routerLink]="['/trips']"
                [class.active]="router.isActive('/location-history', false) || router.isActive('/location-history?searchBy=&value=', false) || router.isActive('/trips', false)"
                (click)="tabPos = 0;" class="nav-link text-center mt-1" href="#history">
                <i class="fa fa-history"></i> History
            </a>
        </li>
        <li class="nav-item" routerLinkActive="active" *ngIf="permissionmodules.includes('DATA')">
            <a [routerLink]="['/data/messages']" (click)="goToTrips('messages')" [class.active]="
            router.isActive('/data/messages', true) ||
            router.isActive('/data/location-history', false)
          " class="nav-link text-center mt-1" href="#messages">
                <i class="fa fa-envelope"></i> Message Data
            </a>
        </li>
        <li class="nav-item" routerLinkActive="active" *ngIf="permissionmodules.includes('TRIPS')">
            <a [routerLink]="['/data/trips']" (click)="goToTrips('trips')" [class.active]="
            router.isActive('/data/trips', true)
          "
                class="nav-link text-center mt-1" href="#trips">
                <i class="fa fa-map-o"></i> Trip Data
            </a>
        </li>
    </ul>
</div>