import { Component, OnInit, NgZone } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { UsersService } from '@components/admin/users/users.service';
import { SharedService } from '@shared/shared.service';
import { SupportService } from '@components/support/support.service';
import { AuthenticationService } from '@layouts/login/authentication.service';
import { CookieService } from 'angular2-cookie/core';
import { enMessage } from '@shared/en-us-message';
import { isArray } from 'util';
import { environment } from '@myenv/environment';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit {
  accountTab = true;
  deviceTab = false;
  provisioningTab = false;
  developerTab = false;
  isLoading = false;
  currentUser = {}
  user: string;
  userName: string;
  unlockStatus: Boolean;
  userData: any;
  impersonate: Boolean = false;
  responseMessage: any = {};
  permissionModules: any = [];
  currentPermissionModules: any = [];
  permissionAuthorities: any = {};
  modules: any = [];


  /**
   * For long date format
   */
  public isoDateFormat = environment.isoDateTimeFormat;

  constructor(
    private router: Router,
    private ngzone: NgZone,
    private _cookieService: CookieService,
    private title: Title,

    private route: ActivatedRoute,
    private usersService: UsersService,
    private sharedService: SharedService,
    private supportService: SupportService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this.modules = JSON.parse(localStorage.getItem('accessRoleModules'));
    this.permissionAuthorities = this.modules.find(module => module.name === 'IMPERSONATE');
    this.user = this.route.snapshot.queryParams.id;
    this.currentPermissionModules = JSON.parse(localStorage.getItem('permissionModules'));
    this.userName = this.route.snapshot.queryParams.name;
    this.getLoginAttempts(this.user);
    this.getCurrentUser(this.user);
    this.loadAccountInfo();

  }

  loadAccountInfo() {
    this.accountTab = true;
    this.deviceTab = false;
    this.provisioningTab = false;
  }

  loadDeviceReplacement() {
    this.accountTab = false;
    this.deviceTab = true;
    this.provisioningTab = false;
  }

  loadDeviceProvisioning() {
    this.accountTab = false;
    this.deviceTab = false;
    this.provisioningTab = true;
  }

  getLoginAttempts(data) {
    this.isLoading = true;
    this.authenticationService.getUserInfo(data).subscribe(res => {
      if (res.loginAttempts > 4) {
        this.unlockStatus = true;
      } else {
        this.unlockStatus = false;
      }

    }, error => {

      this.sharedService.getErrorMsg(error);
    });
  }

  // To unlock the user
  unlockUser(data) {
    this.isLoading = true;
    this.supportService.unlockUser(data).subscribe(res => {
      this.unlockStatus = false;
      this.responseMessage = { success: true, message: enMessage.successUnlockUser };
      this.sharedService.setResponseMessage(this.responseMessage);
    }, error => {
      this.sharedService.getErrorMsg(error);
    });
  }

  // To get current user data
  getCurrentUser(data) {
    this.supportService.getUser(data).subscribe(res => {
      this.userData = res;
    }, error => {
      this.sharedService.getErrorMsg(error);
    });
  }

  // To Impersonate the user
  onSearch() {
    this.authenticationService.impersonate(this.user).subscribe(res => {
      // this.vehicles = res;
      // window.location.reload();
      if (res.accessToken) {
        let base64Token = res.accessToken;
        this.authenticationService.setHeaders(base64Token);
        this._cookieService.put('user', 'impersonateUser');
        if (this._cookieService.get('user')) {
          this.impersonate = true;
        }
        this.getUserPreferences();
        this.tokenScenario(res);
      } else if (res.status == 404) {
        this.authenticationService.setHeaders(this.authenticationService.getHeaders())
        const errorCode = res.errorCode;
        const errorMessage = enMessage[errorCode];
        this.responseMessage = { success: false, message: errorMessage };
        this.sharedService.setResponseMessage(this.responseMessage);
      }
    },
      (error) => {
        var token = this.authenticationService.getHeaders();
        this.authenticationService.setHeaders(token);
        if (error.status == 404) {
          this.sharedService.getErrorMsg(error);
        } else {
          this.sharedService.getErrorMsg(error);
        }
      }
    );
  }

  // To get user preferences
  getUserPreferences() {
    // Get the User Preferences by making a seperate API call
    this.authenticationService.userPreferences().subscribe(responseInfo => {
      if (responseInfo) {
        localStorage.setItem('preferences', JSON.stringify(responseInfo));
      }
      if (responseInfo.liveHistory || responseInfo.live.header) {

        if (responseInfo.liveHistory && responseInfo.liveHistory.header) {
          this.authenticationService.liveHistoryPrefrences(responseInfo.liveHistory.header);
        }

        // if (responseInfo.live && responseInfo.live.header) {
        //   this.authenticationService.livePrefrences(responseInfo.live.header);
        // }
      }
    }, error => {
      this.sharedService.getErrorMsg(error);
    });
  }

  // setting the permissions and impersonating the user.
  tokenScenario(res) {
    this.authenticationService.getLoggedUser().subscribe((reponseInFo) => {
      // this.getUserPreferences();
      if (res.status === 404) {
        const errorCode = res.errorCode;
        const errorMessage = enMessage[errorCode];
        this.responseMessage = { success: false, message: errorMessage };
        this.sharedService.setResponseMessage(this.responseMessage);
      } else {
        this.authenticationService.setLoggedInUser(reponseInFo);
        // let responseUserRole = JSON.parse(localStorage.getItem('responseUserRole'));
        // let resPermissionModule = JSON.parse(localStorage.getItem('resPermissionModule'));
        //  this.sharedService.getUserRoleId(res.token).subscribe(responseUserRole => {
        // this.sharedService.getRoleAccessModule(reponseInFo.roleId).subscribe(resPermissionModule => {
        this.permissionModules = reponseInFo.modules.map(data => data.name);
        if (this.permissionModules.includes('REPORT')) {
          localStorage.setItem('lastReportUrl', '/reports/system/live-status');
        }

        this.sharedService.setAccessRole(reponseInFo.modules, this.permissionModules);
        let permissionModules = JSON.parse(localStorage.getItem('permissionModules'));
        this.sharedService.setDefaultUrl(permissionModules);
        window.location.reload();
        localStorage.removeItem('reports');
        localStorage.removeItem('dashboards');
        localStorage.removeItem('viewReport');
        localStorage.removeItem('reportData');
        localStorage.removeItem('currentReport');
        localStorage.removeItem('reportsVehicle');
        localStorage.removeItem('unreadNotification');
        localStorage.setItem('impersonate', 'true');
        // })
        // })
      }

    }, (error) => {
      this.sharedService.getErrorMsg(error);
    })
  }


}
