<div class="row no-gutters">
  <div class="col-md-12 px-2 py-3">
    <div class="report-item-container">
      <div class="row no-gutters align-items-center">
        <!-- <div class="col-md-4 px-0"> -->
          <!-- <h4>Devices</h4> -->
        <!-- </div> -->
        <div class="col-md-12">
          <form (ngSubmit)="f.form.valid && onSearch()" #f="ngForm" novalidate class="search-wrapper">
            <div class="mt-1 d-flex">
              <div class="select-container text-left">
                <ng-select
                  class="select-radius"
                  [items]="searchBySchema"
                  bindLabel="displayName"
                  bindValue="key"
                  [multiple]="false"
                  [closeOnSelect]="true"
                  [clearable]="false"
                  name="searchBy"
                  [(ngModel)]="searchModel.searchBy"
                  placeholder="Select Type"
                  #searchBy="ngModel"
                  (change)="searchDataOnChange(); selectInput()"
                  required="">
                </ng-select>
              </div>
              <!-- Normal Search Input -->
              <div class="container-input p-0"
                *ngIf="searchModel.searchBy!=='deviceStatus' && searchModel.searchBy!=='requestFlag'">
                <input
                  #inputElement
                  (keyup)="onSearch()"
                  placeholder="Search" type="text"
                  name="searchValue" required
                  [(ngModel)]="searchModel.searchValue"
                  #searchValue="ngModel">
                <i class="fa fa-search img-responsive search-icon cursor-pointer" aria-hidden="true"></i>
              </div>
              <!-- select for device status -->
              <div class="searchBy-select-container" *ngIf="searchModel.searchBy==='deviceStatus'">
                <ng-select
                  name="selectStatus"
                  bindValue="key"
                  bindLabel="displayName"
                  placeholder="Select Device Status"
                  [items]="deviceStatus"
                  [clearable]="true"
                  [multiple]="false"
                  [closeOnSelect]="true"
                  #selectStatus="ngModel"
                  [(ngModel)]="searchModel.searchValue"
                  (change)="onSearch()">
                </ng-select>
              </div>
              <!-- select for request flag -->
              <div class="searchBy-select-container" *ngIf="searchModel.searchBy==='requestFlag'">
                <ng-select
                  name="selectFlags"
                  bindValue="key"
                  bindLabel="displayName"
                  placeholder="Select Request Flag"
                  [items]="requestFlags"
                  [clearable]="true"
                  [multiple]="false"
                  [closeOnSelect]="true"
                  #selectFlags="ngModel"
                  [(ngModel)]="searchModel.searchValue"
                  (change)="onSearch()">
                </ng-select>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="page-loading" *ngIf="pageLoading">
          <img src="../../../assets/img/darby_loader_small.gif"/>
        </div>
        <div *ngIf="!devices.length && !pageLoading" class="noDataContainer mt-5 py-5">
          <img width="170" src="../../../assets/img/info_nodata_256.png"/>
          <h4 class="mt-3">No data to show</h4>
        </div>
        <div class="col-md-12 px-0" *ngIf="devices.length">
          <ngx-datatable
            id="tableau" #dataTable
            class="bootstrap alertTable reportsTable wrapcell"
            [rows]="devices"
            [columnMode]="'force'"
            [headerHeight]="50"
            [footerHeight]="50"
            [rowHeight]="'auto'"
            [summaryRow]="false"
            [scrollbarH]="true"
            [reorderable]="true"
            [limit]="pageInfo.limit"
            [offset]="pageInfo.offset"
            [count]="pageInfo.count"
            (page)="pageCallback($event)"
            >
            <ngx-datatable-column [resizeable]="false" [sortable]="false" name="#" [width]="70">
              <ng-template let-rowIndex="rowIndex" ngx-datatable-cell-template>
                {{rowIndex+1}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" [sortable]="false" [width]="300" name="Request ID">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.requestId}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" [sortable]="false" name="SR ID">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.serviceRequestId}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="true" prop="productSerialNumber" name="PSN">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.productSerialNumber}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="true" prop="serialNumber" name="DSN">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.serialNumber}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="true" prop="vin" name="VIN">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.vin}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="true" prop="requestFlag" name="Request Flag">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.requestFlag}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="true" [sortable]="false" name="Status">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span class="badge badge-primary" *ngIf="row.deviceStatus==8">{{row.deviceStatus}} - Retriggered</span>
                <span class="badge badge-danger" *ngIf="row.deviceStatus==5">{{row.deviceStatus}} - Failed</span>
                <span class="badge badge-primary" *ngIf="row.deviceStatus==4 && (row.requestFlag=='S' || row.requestFlag=='C')">{{row.deviceStatus}} - In Progress</span>
                <span class="badge badge-success" *ngIf="row.deviceStatus==6 && row.requestFlag=='S'">{{row.deviceStatus}} - Activated</span>
                <span class="badge" [class.badge-primary]="row.ais140Status===1" [class.badge-success]="row.ais140Status===0"
                  *ngIf="row.deviceStatus==6 && row.requestFlag=='C'">
                  <span *ngIf="row.ais140Status===1">{{row.deviceStatus}} - In Progress</span>
                  <span *ngIf="row.ais140Status===0">{{row.deviceStatus}} - Activated</span>
                </span>
                <span class="badge badge-danger" *ngIf="row.deviceStatus==7">{{row.deviceStatus}} - Activation Failed</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="true" [sortable]="false" name="Remark" [width]="300">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.remark}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="true" prop="architectureType" name="Architecture Type">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.architectureType}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="true" prop="batteryVoltage" name="Battery Voltage">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.batteryVoltage}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="true" prop="createdAt" name="Created At" >
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.createdAt | date: dateFormat}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="true" prop="updatedAt" name="Updated At" >
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.updatedAt | date: dateFormat}}
              </ng-template>
            </ngx-datatable-column>
            <!-- <ngx-datatable-column [resizeable]="true" [sortable]="false" [width]="360" name="Action">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <button
                  *ngIf="(row.deviceStatus==4 || row.deviceStatus==6) && row.requestFlag==='C' && (row.ais140Status===1 || row.ais140Status===0)"
                  (click)="downloadKYC(row)"
                  class="btn btn-sm btn-primary ml-1">
                  Download KYC
                </button>
                <span *ngIf="row.deviceStatus==4 && row.requestFlag==='C'">
                  <button
                    *ngIf="row.ais140Status===1 || row.ais140Status===0"
                    data-toggle="modal"
                    data-target="#addRemarkModal"
                    (click)="selectedDevice=row; activationStatus='SUCCESS'"
                    class="btn btn-sm btn-success ml-1">
                    Activate
                  </button>
                  <button
                    *ngIf="row.ais140Status===1 || row.ais140Status===0"
                    data-toggle="modal"
                    data-target="#addRemarkModal"
                    (click)="selectedDevice=row; activationStatus='FAILED'"
                    class="btn btn-sm btn-danger ml-1">
                    Fail
                  </button>
                  <button
                    *ngIf="row.ais140Status===1"
                    data-toggle="modal"
                    data-target="#uploadCertificatesModal"
                    (click)="selectedDevice=row"
                    class="btn btn-sm btn-primary ml-1">
                    Upload Certificates
                  </button>
                </span>
                <button
                  *ngIf="row.deviceStatus==7 && row.requestFlag==='C'"
                  (click)="confirmSelectDevice=row;showConfirmModal=!showConfirmModal;"
                  class="btn btn-sm btn-primary ml-1">
                  Re-Trigger
                </button>
                <button
                  *ngIf="row.deviceStatus==6 && row.requestFlag==='C' && row.ais140Status===1"
                  data-toggle="modal"
                  data-target="#uploadCertificatesModal"
                  (click)="selectedDevice=row"
                  class="btn btn-sm btn-primary">
                  Upload Certificates
                </button>
              </ng-template>
            </ngx-datatable-column> -->
            <!--</ng-template>-->
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- ADD DEVICE MODAL -->
<div data-keyboard="false" data-backdrop="static" id="uploadCertificatesModal" class="modal fade">
  <div class="modal-dialog" role="document">
    <div class="modal-content bd-0 overflow-hidden">
      <div class="modal-body p-0">
        <div class="row no-gutters">
          <div class="col-12">
            <span #closeuploadModal class="pull-right closeBtn" data-dismiss="modal">
              <i class="fa fa-times" aria-hidden="true"></i>
            </span>
            <div class="modal-box p-30">
              <div class="reply-header">
                <h5>Upload Certificates</h5>
              </div>
              <div class="login-content user-modal mt-20">
                <form action="javascript:void(0);" (ngSubmit)="uploadCertificates(f)" #f="ngForm" method="POST" class="margin-bottom-0">
                  <div class="row">
                    <!-- Vaahan File Select -->
                    <label for="vaahanCertificate">Vaahan Certificate</label>
                    <div class="col-md-12 p-0 mb-4 pl-0">
                      <span class="text-danger upload-mandatory">*</span>
                      <input name="file" type="file"
                        class="form-control-file" id="vaahanCertificate" multiple
                        #inputVaahanFile autocomplete="off" (change)="onFileSelect($event, 'vahan')"
                      />
                      <div *ngIf="vahanFileSelect">
                        <small class="text-danger">Please select a file</small>
                      </div>
                    </div>
                    <!-- BSNL File Select -->
                    <label for="bsnlCertificate">BSNL Certificate</label>
                    <div class="col-md-12 p-0 pl-0">
                      <span class="text-danger upload-mandatory">*</span>
                      <input name="file" type="file"
                        class="form-control-file" id="bsnlCertificate" multiple
                        #inputBSNLFile autocomplete="off" (change)="onFileSelect($event, 'bsnl')"
                      />
                      <div *ngIf="bsnlFileSelect">
                        <small class="text-danger">Please select a file</small>
                      </div>
                    </div>
                  </div>
                  <div class="row pt-3">
                    <div class="col-lg-6"></div>
                    <div class="col-lg-6 pl-0 text-right sm-form-design">
                      <div class="login-buttons width100 text-right">
                        <button class="btn btn-outline-secondary small-screen-btn mr-2" data-dismiss="modal">
                          Cancel
                        </button>
                        <button type="submit"
                          class="btn btn-success small-screen-btn pull-right" [disabled]="disable">
                          Upload
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- ADD DEVICE MODAL END -->
<!-- Add Remark Modal Start -->
<div data-keyboard="false" data-backdrop="static" id="addRemarkModal" class="modal fade">
  <div class="modal-dialog" role="document">
    <div class="modal-content bd-0 overflow-hidden">
      <div class="modal-body p-0">
        <div class="row no-gutters">
          <div class="col-12">
            <span #closeRemarkModal class="pull-right closeBtn" data-dismiss="modal">
              <i class="fa fa-times" aria-hidden="true"></i>
            </span>
            <div class="modal-box p-30">
              <div class="reply-header">
                <h5 *ngIf="activationStatus==='SUCCESS'">Activate</h5>
                <h5 *ngIf="activationStatus==='FAILED'">Reject</h5>
              </div>
              <div class="login-content user-modal mt-20">
                <form action="javascript:void(0);"
                  (ngSubmit)="activateDevice(fr.valid)"
                  #fr="ngForm" method="POST" class="margin-bottom-0">
                  <!-- Add Remark -->
                  <div class="row mb-1 mt-10">
                    <div class="col-lg-12 sm-form-design">
                      <input
                        required
                        type="text" id="remarks"
                        class="form-control h5-email"
                        placeholder="Please enter Remarks."
                        autocomplete="off"
                        tabindex="1"
                        name="remarks"
                        [(ngModel)]="searchModel.remarks"
                        #remarks="ngModel"
                      />
                      <label class="control-label">Add Remarks<span class="text-danger">*</span></label>
                      <div [hidden]="remarks.valid || (remarks.pristine && !fr.submitted)">
                        <small class="text-danger">Please Add Remark.</small>
                      </div>
                    </div>
                  </div>
                  <div class="row pt-3">
                    <div class="col-lg-6"></div>
                    <div class="col-lg-6 pl-0 text-right sm-form-design">
                      <div class="login-buttons width100 text-right">
                        <button class="btn btn-outline-secondary small-screen-btn mr-2"
                          data-dismiss="modal" (click)="activationStatus=''">
                          Cancel
                        </button>
                        <button type="submit"
                          class="btn small-screen-btn pull-right"
                          [class.btn-danger]="activationStatus==='FAILED'"
                          [class.btn-success]="activationStatus==='SUCCESS'"
                          [disabled]="activating">
                          <span *ngIf="activationStatus==='SUCCESS'">Activate</span>
                          <span *ngIf="activationStatus==='FAILED'">Reject</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Add Remark Modal End -->
<!-- Confirm Modal Start -->
<app-confirm-delete-modal
  [title]="'Re-Trigger Device'"
  [showModal]="showConfirmModal"
  [hideModal]="hideConfirmModal"
  [disableBtn]="disable"
  [primaryAction]="'confirm'"
  [displayText]="'Yes, Retry it!'"
  (confirmEvent)="retriggerDevice()">
</app-confirm-delete-modal>
  <!--[matchText]="confirmSelectDevice.productSerialNumber"-->
<!-- Confirm Modal End -->
