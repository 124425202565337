import { OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '@myenv/environment';
var CommunicatingCalendarReportComponent = /** @class */ (function () {
    function CommunicatingCalendarReportComponent(title) {
        this.title = title;
        this.querySelectDevice = null;
        this.selectedDevice = null;
        this.isLoading = false;
        this.refresh = false;
    }
    CommunicatingCalendarReportComponent.prototype.ngOnInit = function () {
        this.title.setTitle('Device Timeline' + environment.title_text);
    };
    CommunicatingCalendarReportComponent.prototype.changeDevice = function (e) {
        // if (localStorage.getItem('reportsVehicle') && this.querySelectDevice == null) {
        //   this.setItem();
        // } else {
        //   localStorage.setItem('reportsVehicle', JSON.stringify(e));
        //   this.selectedDevice = e;
        // }
        this.selectedDevice = e ? e : null;
        this.querySelectDevice = e ? e : null;
    };
    CommunicatingCalendarReportComponent.prototype.setItem = function () {
        var reportsItem = JSON.parse(localStorage.getItem('reportsVehicle'));
        this.querySelectDevice = reportsItem;
        this.selectedDevice = reportsItem;
    };
    return CommunicatingCalendarReportComponent;
}());
export { CommunicatingCalendarReportComponent };
