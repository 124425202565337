<app-reports-header></app-reports-header>
<div class="row no-gutters">
  <div class="col-md-2 col-sm-0 p-0">
    <app-reports-side-menu></app-reports-side-menu>
  </div>
  <div class="reports-body col-md-10 col-sm-12 p-0">
    <div class="row no-gutters align-items-center action-row px-2">
      <div class="col-md-4">
        <h4 class="report-heading page-heading">
          <i class="fa fa-bar-chart"></i> Device Timeline
        </h4>
      </div>
      <div class="col-md-8 text-right">
        <app-search-select-device
          [defaultselectdevice]="true"
          [autoSelect]="true"
          [clearable]="false"
          [querySelectDevice]="querySelectDevice"
          [selectSearchCategory]="true"
          (deviceChangeEvent)="changeDevice($event)"
          >
        </app-search-select-device>
        <button
          class="btn btn-icon ml-2 refresh"
          (click)="refresh=!refresh"
          tooltip="Refresh"
          placement="top"
          [disabled]="isLoading">
          <i class="fa fa-refresh" aria-hidden="true"></i>
          <span class="mob-access-text d-none">&nbsp;Refresh</span>
        </button>
      </div>
    </div>
    <div class="row no-gutters pt-1">
      <div class="col-12 p-2">
        <app-calendar-graph
          [device]="selectedDevice"
          [showTemplate]="true"
          [refresh]="refresh"
        ></app-calendar-graph>
      </div>
    </div>
  </div>
</div>
