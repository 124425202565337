
import { throwError as observableThrowError, Observable } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { environment } from '@myenv/environment';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';

@Injectable()
export class ReportsService {
  public apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  // get attribute API for vehicle
  getAttribute(data): Observable<any> {
    return this.http.get(this.apiUrl + 'reports/attribute/' + data).pipe(
      catchError(this.handleError))
  }

  //To get single report
  getDeviceView(reportId, startTime, endTime, page, serialNumber?): Observable<any> {
    let tempURL = this.apiUrl + 'reports/vin-change/' + reportId + '/generate?startTime=' + startTime + '&endTime=' + endTime + '&page=' + page;
    if (serialNumber) {
      tempURL += '&serialNumber=' + serialNumber;
    }
    return this.http.get(tempURL).pipe(
      catchError(this.handleError))
  }

  //To get single report
  getSingleReportByReportId(reportId, data, startTime, endTime, page): Observable<any> {
    return this.http.get(this.apiUrl + 'reports/' + reportId + '/generate?startTime=' + startTime + '&endTime=' + endTime + '&page=' + page + '&deviceId=' + data).pipe(
      catchError(this.handleError))
  }

  //to get total message count
  getMessageCount(reportId, deviceId, startTime, endTime): Observable<any> {
    return this.http.get(this.apiUrl + 'reports/' + reportId + '/count?startTime=' + startTime + '&endTime=' + endTime + '&deviceId=' + deviceId).pipe(
      catchError(this.handleError))
  }

  //to get total message count
  getDeviceMeessageCount(reportId, startTime, endTime, serialNumber?): Observable<any> {
    let tempURL = this.apiUrl + 'reports/vin-change/' + reportId + '/count?startTime=' + startTime + '&endTime=' + endTime;
    if (serialNumber) {
      tempURL += '&serialNumber=' + serialNumber;
    }
    return this.http.get(tempURL).pipe(
      catchError(this.handleError))
  }

  //to get total message count
  // getMessageCount(reportId, deviceId, startTime, endTime): Observable<any>{
  //   return this.http.get(this.apiUrl + 'reports/' + reportId + '/count?startTime=' + startTime + '&endTime=' + endTime + '&selectionTypeId=' + deviceId).pipe(
  //     catchError(this.handleError))
  // }

  //to get total message count
  multiVehicleView(reportId, deviceId, startTime, endTime, nextPage?): Observable<any> {
    let tempURL = this.apiUrl + 'reports/' + reportId + '/generate/multi?startTime=' + startTime + '&endTime=' + endTime + '&selectionTypeId=' + deviceId + '&limit=100';
    if (nextPage) {
      tempURL += '&nextPage=' + nextPage;
    }
    return this.http.get(tempURL).pipe(
      catchError(this.handleError))
  }

  //To download reports as CSV file
  downloadReportCSV(reportId, deviceId, startTime, endTime): Observable<any> {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    return this.http.post(this.apiUrl + 'reports/' + reportId + '/download/token?startTime=' + startTime + '&endTime=' + endTime + '&selectionTypeId=' + deviceId, { headers }).pipe(
      catchError(this.handleError))
  }

  handleError(error: any | any) {

    return observableThrowError(error.error || error.json() || error || 'Server error');
  }

}
