import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})

export class SupportComponent implements OnInit {
  searchTab = false;
  noReportTab = false;
  dashboardTab = false;
  otaTab = false;
  moveDeviceTab = false;
  billingReportTab = true;
  permissionModules: any;
  billingReportCSR: any
  
  
  constructor() { }
  ngOnInit() {
    this.permissionModules = localStorage.getItem('permissionModules');
    localStorage.setItem("billingReportCSR","true");
    this.loadSearch();

  }

  loadSearch() {
    this.searchTab = true;
    this.noReportTab = false;
    this.dashboardTab = false;
    this.otaTab = false;
    this.moveDeviceTab = false;
    this.billingReportTab = false;
  }

  loadNoReport() {
    this.searchTab = false;
    this.noReportTab = true;
    this.dashboardTab = false;
    this.otaTab = false;
    this.moveDeviceTab = false;
    this.billingReportTab = false;
  }

  loadDashboard() {
    this.searchTab = false;
    this.noReportTab = false;
    this.dashboardTab = true;
    this.otaTab = false;
    this.moveDeviceTab = false;
    this.billingReportTab = false;
  }

  loadMoveDevices() {
    this.searchTab = false;
    this.noReportTab = false;
    this.dashboardTab = false;
    this.otaTab = false;
    this.moveDeviceTab = true;
    this.billingReportTab = false;
  }
  loadBillingReport() {
    this.searchTab = false;
    this.noReportTab = false;
    this.dashboardTab = false;
    this.otaTab = false;
    this.moveDeviceTab = false;
    this.billingReportTab = true;
    localStorage.setItem("billingReportCSR","true");
  }
}
