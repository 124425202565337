import { Injectable, EventEmitter } from '@angular/core';
import { Subject ,  Observable ,  Subscription } from 'rxjs';


@Injectable()
export class WorkerService {
  public readonly workerPath = 'assets/workers/live-worker.js';

  workerResponse: any;
  private worker: Worker;
  public workerSubject: Subject<any>;

  constructor() {
    this.workerInit();
    this.onmessage();
  }


// Post message to worker function.
  postMessage(workerMessage: any): void {
    if (this.worker) {
      this.worker.postMessage(workerMessage);
    }
  }

// Function to trigger onmessage listener
  onmessage(): void {
      this.worker.onmessage = function(e: any) {
        // console.log('onmessage service');
        // console.log(e.data);
        this.workerResponse = e.data;
        this.workerSubject.next(this.workerResponse);
    }.bind(this);
  }

  /**
   * Terminate the worker
   * It is not used since the worker is kept alive
   * If terminated it will kill thread for the session
   * Use cautiously and only if highly important
   */
  terminate(): void {
      this.worker.terminate();
  }

// Initialize worker
  workerInit(): void {
    try {
      if (!!this.worker === false) {
        this.worker = new Worker(this.workerPath);
        this.workerSubject = new Subject<any>();
      }
    } catch (exception) {
      console.error(exception);
    }
  }

}
