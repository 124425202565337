import * as config from '../assets/config.json';
export const environment = {
  footerText: 'Copyright 2023, BitBrew, by Danlaw Inc',
  showFooter: true,
  footerLeft: true,
  footerRight: true,
  footerText1: 'Contact Us',
  footerText2: 'FAQs',
  footerText3: false,
  contactEmail: 'help@bitbrew.com',
  EOTA_Enabled: false,
  title_text: ' - BitBrew',
  quarantineRadius: 50,
  unoHeader: false,
  live_obd2_metrics_enabled: true,
  version: '2.0.0',
  production: false,
  development: true,
  staging: false,
  AL_SystemReport: false,
  speedMetrics: 'mile/h',
  distanceMetrics: 'mi',
  companyName: 'Danlaw Inc',
  apiUrl: 'https://api.qa.bitbrew.com/v1/',
  apiV2Url: 'https://ota.qa.bitbrew.com/',
  cmImageUrl: 'https://bitbrew-qa-public-contents.s3.amazonaws.com/webapp/',
  largeDateFormat: 'dd-MM-yyyy HH:mm:ss',
  onlyDate: 'MM/dd/yyyy',
  yearMonthDate: 'yyyy-MM-dd',
  smallDateFormat: 'MMM d, h:mm:ss a',
  readableDteaFormat: 'd MMM, h:mm a',
  noSecondsTimeFormat: 'MMM d, h:mm a',
  mediumTime: 'h:mm:ss a',
  isoDateTimeFormat: 'yyyy-MM-dd HH:mm:ss',
  supportNumber: '08068155407',
  lat: 42.47866,
  lng: -83.45234,
  centerLat: 21.325,
  centerLng: 0.946,
  headerHeight: 40,
  rowHeight: 50,
  pageLimit: 200,
  headerHeightVH: 50,
  rowHeightVH: 25,
  pageLimitVH: 15,
  page: 0,
  pageVehicleHistory: 0,
  resSize : 100,
  limit : 3000,
  tags: [
    {
      "key": "heartbeat-check",
      "displayName": "Heartbeat Check"
    },{
      "key": "enable-raw-data",
      "displayName": "Enable Raw Data"
    },
  ],
  vehicleTags: [
    {
      'id': 'enableReverseGeocode',
      'name': 'Enable Reverse Geocode'
    }
  ],
  kibaraImeiSearch: "http://10.30.36.205:5601/app/kibana#/discover?_g=()&_a=(columns:!(log),index:'7b23c420-c97c-11e9-b3fe-fb079d89490a',interval:auto,query:(language:lucene,query:%IMEI_NUMBER%22),sort:!('@timestamp',desc))",
  kibaraSerialNumberSearch: "http://10.30.36.205:5601/app/kibana#/discover?_g=()&_a=(columns:!(log),index:'7b23c420-c97c-11e9-b3fe-fb079d89490a',interval:auto,query:(language:lucene,query:%SERIAL_NUMBER%22),sort:!('@timestamp',desc))",
  refreshIcon: true,
  deviceAtr: ["serialNumber","imei","eventTime","offset","tripNumber","messageType","messageTypeId","sequenceNumber","protocolVersion","latitude","longitude","altitude","heading","hdop","sog","numberOfSatellites","fixQuality","tripType","deviceCommProtocol","configVersion","firmwareVersion","triggerReason","vehicleOdo","trackingFrequency"],
  platformAtr: ["ingestionTimestamp","pushTimestamp","consumerIngestionTime","messageId","assetName","deviceId ","groupName","groupId","deviceId","companyId","customerId","darbyVehicleStatus","vIdMsgTypeSrchIdx","createdAt","tags","upsJsonVersion","architectureType","vehicleType"]
  // tags: ["latitude,longitude,eventTime,heading,vehicleSpeed,messageType"],
};
