import { OnInit } from '@angular/core';
import { SharedService } from '@shared/shared.service';
import * as FileSaver from 'file-saver';
import { Title } from '@angular/platform-browser';
import { environment } from '@myenv/environment';
import { configMessage } from '@shared/config-message';
import { GraphicReportsService } from '@components/graphic-reports/graphic-reports.service';
var CustomReportComponent = /** @class */ (function () {
    function CustomReportComponent(
    // private reportsService: ReportsService,
    reportsService, sharedService, title) {
        this.reportsService = reportsService;
        this.sharedService = sharedService;
        this.title = title;
        this.apiUrl = environment.apiUrl;
        this.dateFormat = environment.largeDateFormat;
        this.dateAttributes = configMessage.dateAttributes;
        this.maxDate = new Date();
        this.temp = [];
        this.reportsMenu = [];
        this.vehicles = [];
        this.currentReport = {};
        this.querySelectDevice = null;
        this.querySelectVehicle = null;
        this.page = 0;
        this.isLoading = false;
        this.noReports = false;
        this.report = {};
        this.columMenu = [];
        this.currentPageCount = {
            start: 0,
            end: 0
        };
        this.searchModel = {
            value: null,
            vehicle: null,
            device: null
        };
        this.reportId = null;
        this.pageInfo = {
            offset: 0,
            count: 0,
            limit: 10,
            pageSize: 0,
            lastPage: 0,
        };
        this.reports = [];
        this.itemsPerPage = 10; // we are showing 10 items per page
        this.lastPage = 0;
        this.disable = false;
        this.selectItemError = false;
        this.permissionModules = [];
        this.modules = [];
        this.permissionAuthorities = {};
        this.moduleName = 'REPORT';
        this.totalMessageCount = {};
        this.hideCalendar = false;
        this.isSideMenuClose = false;
    }
    CustomReportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.modules = JSON.parse(localStorage.getItem('accessRoleModules'));
        this.permissionAuthorities = this.modules.find(function (module) { return module.name === _this.moduleName; });
        this.permissionModules = JSON.parse(localStorage.getItem('permissionModules'));
        this.title.setTitle('Reports' + environment.title_text);
        this.setReport();
    };
    CustomReportComponent.prototype.getReport = function (report) {
        this.currentReport = report;
        this.setReport();
    };
    CustomReportComponent.prototype.onTimeChange = function (e) {
        this.startTime = e.startTime;
        this.endTime = e.endTime;
        this.page = 0;
    };
    CustomReportComponent.prototype.changeVehicle = function (e) {
        if (localStorage.getItem('reportsVehicle') && this.querySelectVehicle == null) {
            this.setItem();
        }
        else {
            localStorage.setItem('reportsVehicle', JSON.stringify(e));
            this.searchModel.value = e ? e.deviceId : null;
            this.searchModel.vehicle = e ? e.deviceId : null;
            this.selectedItem = e;
            this.querySelectVehicle = e;
        }
    };
    CustomReportComponent.prototype.changeDevice = function (e) {
        if (localStorage.getItem('reportsDevice') && this.querySelectDevice == null) {
            this.setItem();
        }
        else {
            localStorage.setItem('reportsDevice', JSON.stringify(e));
            this.selectedItem = e;
            this.searchModel.value = e ? e.serialNumber : null;
            this.searchModel.device = e ? e.serialNumber : null;
            this.querySelectDevice = e;
        }
    };
    CustomReportComponent.prototype.onSideMenuToggle = function () {
        this.isSideMenuClose = !this.isSideMenuClose;
    };
    CustomReportComponent.prototype.refresh = function () {
        this.page = 0;
        this.setReport();
    };
    CustomReportComponent.prototype.setReport = function () {
        if (this.currentReport && this.currentReport.reportId) {
            localStorage.setItem('currentReport', JSON.stringify(this.currentReport));
            this.report.reportId = this.currentReport.reportId;
        }
        else {
            if (JSON.parse(localStorage.getItem('currentReport'))) {
                var localReport = JSON.parse(localStorage.getItem('currentReport'));
                if (localReport.downloadable) {
                    this.currentReport = localReport;
                    this.report.reportId = localReport.reportId;
                }
                else {
                    var reports = JSON.parse(localStorage.getItem('reports'));
                    var global_1 = this;
                    reports.forEach(function (item) {
                        if (item.downloadable && !global_1.currentReport.reportId) {
                            global_1.currentReport = item;
                        }
                    });
                    localStorage.setItem('currentReport', JSON.stringify(this.currentReport));
                }
            }
        }
        this.columMenu = this.currentReport.columns;
        this.title.setTitle(this.currentReport.name + environment.title_text);
    };
    CustomReportComponent.prototype.setItem = function () {
        if (this.currentReport.type != 'VIN_CHANGE_2G2K' && localStorage.getItem('reportsVehicle')) {
            var reportsItem = JSON.parse(localStorage.getItem('reportsVehicle'));
            this.querySelectVehicle = reportsItem;
            this.searchModel.value = reportsItem.deviceId;
            this.searchModel.vehicle = reportsItem.deviceId;
            this.selectedItem = reportsItem;
        }
        else {
            var reportsItem = JSON.parse(localStorage.getItem('reportsDevice'));
            this.querySelectDevice = reportsItem;
            this.searchModel.value = reportsItem.deviceId;
            this.searchModel.device = reportsItem.serialNumber;
            this.selectedItem = reportsItem;
        }
    };
    // to handle disabled buttons
    CustomReportComponent.prototype.disableBtn = function () {
        if (!this.searchModel.value ||
            !this.startTime || !this.endTime) {
            this.disable = true;
        }
        else {
            this.disable = false;
        }
    };
    // To download report as a CSV File
    CustomReportComponent.prototype.downloadReportCSV = function () {
        var _this = this;
        this.disable = true;
        if (this.currentReport.type === 'VPACKET_SYSTEM_REPORT') {
            this.reportsService.downloadVPacketReportCSV(this.currentReport.reportId).subscribe(function (res) {
                var win = window.open(_this.apiUrl + 'reports/vpacket/download?access_key=' + res.token, '_blank');
                win.focus();
                _this.disable = false;
            }, function (error) {
                _this.sharedService.getErrorMsg(error);
                _this.disable = false;
            });
        }
        else {
            this.reportsService.downloadReportCSV(this.currentReport.reportId, this.searchModel.value, this.startTime, this.endTime).subscribe(function (res) {
                var win = window.open(_this.apiUrl + 'reports/download?access_key=' + res.token, '_blank');
                win.focus();
                _this.disable = false;
            }, function (error) {
                _this.sharedService.getErrorMsg(error);
                _this.disable = false;
            });
        }
    };
    // To download report as a CSV File
    CustomReportComponent.prototype.downloadHeatMapCSV = function () {
        var _this = this;
        this.disable = true;
        var startTime = new Date(this.startTime);
        var endTime = new Date(this.endTime);
        var start = startTime.getFullYear() + "-" + (startTime.getMonth() + 1) + "-" + startTime.getDate();
        var end = endTime.getFullYear() + "-" + (endTime.getMonth() + 1) + "-" + endTime.getDate();
        this.reportsService.downloadHeatMapCSV(start, end).subscribe(function (res) {
            var contentDisposition = res.headers.get('Content-Disposition');
            var filename;
            if (contentDisposition == null) {
                var date = new Date();
                filename = 'Report_' + date + '.csv';
            }
            else {
                filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
            }
            var blob = new Blob([res.body], {
                type: 'text/csv;charset=utf-8'
            });
            FileSaver.saveAs(blob, filename);
            _this.disable = false;
        }, function (error) {
            _this.sharedService.getErrorMsg(error);
            _this.disable = false;
        });
    };
    CustomReportComponent.prototype.downloadDeviceReportCSV = function () {
        var _this = this;
        this.disable = true;
        this.reportsService.downloadDeviceReportCSV(this.currentReport.reportId, this.startTime, this.endTime, this.searchModel.value).subscribe(function (res) {
            var win = window.open(_this.apiUrl + 'reports/vin-change/download?access_key=' + res.token + '&compress=true', '_blank');
            win.focus();
            _this.disable = false;
        }, function (error) {
            _this.sharedService.getErrorMsg(error);
            _this.disable = false;
        });
    };
    return CustomReportComponent;
}());
export { CustomReportComponent };
