import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { SharedService } from '@myproject/shared/shared.service';
import { environment } from '@myenv/environment';
import { configMessage } from '@shared/config-message';
import { GraphicReportsService } from '../../graphic-reports.service';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-reports-table',
  templateUrl: './reports-table.component.html',
  styleUrls: ['./reports-table.component.scss']
})
export class ReportsTableComponent implements OnInit, OnChanges {
  // @Input() devicesList;
  @Input() displayTitle;
  @Input() startTime;
  @Input() endTime;
  // input parameter to determine from which component this component is being rendered
  @Input() fromComponent;
  // input parameter only for app-version-drilldown-report(Software Version Page) component
  @Input() firmware;
  @Input() config;
  @Input() firmwareDevicesCount = 0;
  // @Input() isLoadingFW = true;
  @Input() isLoadingCFG = false;
  // @Input() changeOffset;
  // @Input() blockCall: boolean;
  // @Output() pageChangeEvent = new EventEmitter();
  @Output() updateProvnIntslCount = new EventEmitter();
  public dateFormat = environment.isoDateTimeFormat;
  dateAttributes = configMessage.dateAttributes;
  deviceStatus = configMessage.deviceStatus;
  deviceStatusClass = configMessage.deviceStatusClass;
  pageInfo: any = {
    offset: 0,
    count: 0,
    limit: 5,
    pageSize: 0,
    lastPage: 0,
  };
  page = 0;
  isLoading = false;
  pageLoading = true;
  currentPageCount: any = {
    start: 0,
    end: 0
  };
  headerItems: any[] = [];
  columns: any[] = [];
  searchModel: any = {};
  customHeaders: any[] = [];
  deviceStaticHeader = [
    'serialNumber',
    'imei',
    // 'simCcid',
    'firmwareVersion',
    'configVersion',
    // 'simCarrier1',
    // 'model',
    // 'deviceStatus',
    'lastCommunication'
  ];
  customSelectedHeader: any[] = [];
  columnPopup = false;
  selectCount = 0;
  allSelected = false;
  devicesList = [];
  prevResponse = 0;
  isLoadingData = false;

  constructor(
    private sharedService: SharedService,
    private reportsService: GraphicReportsService,
  ) { }

  ngOnInit() {
    this.getDeviceHeader();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.startTime || changes.endTime) {
      this.devicesList = [];
      this.pageInfo.offset = 0;
      if (this.fromComponent === 'provisioning') {
        this.isLoadingData = true;
        this.getInstalledDevicesCount();
        this.getInstalledDevices(this.startTime, this.endTime, this.page);
      } else if(this.fromComponent === 'communicating') {
        this.isLoadingData = true;
        this.getCommunicatingDevicesCount();
        this.getCommunicatingdDevices(this.startTime, this.endTime, this.page);
      } else if(this.fromComponent === 'not-communicating') {
        this.isLoadingData = true;
        this.getNotCommunicatingDevicesCount();
        this.getAllNotCommDevices(this.startTime, this.endTime, this.page);
      }
    }
    // get software version page data
    if (changes.isLoadingCFG) {
      this.pageInfo.offset = 0;
      if (!changes.isLoadingCFG.currentValue) {
        this.getSoftwareVersionDevicesCount();
        this.getSoftwareVersiondDevices(this.startTime, this.endTime, this.firmware, this.config, 0);
      } else if (changes.isLoadingCFG.currentValue) {
        this.devicesList = [];
      } else if (changes.config.currentValue) {
        this.devicesList = [];
        this.getSoftwareVersionDevicesCount();
        this.getSoftwareVersiondDevices(this.startTime, this.endTime, this.firmware, this.config, 0);
      }
    }
    if (changes.firmwareDevicesCount && changes.firmwareDevicesCount.currentValue) {
      this.currentPageCount.totalCount = this.firmwareDevicesCount;
    }
    if (changes.fromComponent && changes.fromComponent.currentValue) {
      this.currentPageCount.totalCount = 0;
    }
  }

  getDeviceHeader() {
    let headers = JSON.parse(localStorage.getItem('deviceHeaders'));
    if (headers) {
      this.headerItems = headers;
      this.columns = headers;
      this.isLoading = true;
      this.pageLoading = false;
    } else {
      this.sharedService.getDeviceHeader().subscribe({
        next: res => {
          localStorage.setItem('deviceHeaders', JSON.stringify(res));
          this.headerItems = res;
          this.columns = res;
          this.isLoading = true;
          this.pageLoading = false;
        },
        error: error => {
          this.pageLoading = false;
          this.sharedService.getErrorMsg(error);
        }
      });
    }
  }

  getInstalledDevicesCount() {
    this.sharedService.getInstalledDevicesCount(this.startTime, this.endTime).subscribe(data => {
      this.currentPageCount.totalCount = data.Count;
      // this.updateProvnIntslCount.emit(this.currentPageCount.totalCount);
      this.setPageInfo();
    }, error => { });
  }

  getCommunicatingDevicesCount() {
    this.sharedService.getCommunicatingDevicesCount(this.startTime, this.endTime).subscribe(data => {
      this.currentPageCount.totalCount = data.Count;
      this.setPageInfo();
    }, error => { });
  }

  getNotCommunicatingDevicesCount() {
    this.sharedService.getNotCommunicatingDevicesCount(this.startTime, this.endTime).subscribe(data => {
      this.currentPageCount.totalCount = data.Count;
      this.setPageInfo();
    }, error => {});
  }

  getSoftwareVersionDevicesCount() {
    this.sharedService.getSVDevicesCount(this.startTime, this.endTime, this.firmware, this.config).subscribe(data => {
      if (Array.isArray(data) && data.length) {
        this.currentPageCount.totalCount = data[0].count;
        this.setPageInfo();
      } else {
        this.currentPageCount.totalCount = 0;
      }
    }, error => { });
  }

  setPageInfo() {
    this.pageInfo.count = this.devicesList.length;
    let pagecount = this.pageInfo.count / this.pageInfo.limit;
    this.pageInfo.lastPage = Math.floor(pagecount);
    let decimal = pagecount - this.pageInfo.lastPage;

    if (!Number.isInteger(decimal)) {
      this.pageInfo.lastPage = this.pageInfo.lastPage + 1;
    }
    if (this.page == 0) {
      if (this.devicesList.length) {
        this.currentPageCount.start = (this.pageInfo.offset * this.pageInfo.pageSize) + 1;
        if (this.devicesList.length <= this.pageInfo.limit) {
          this.currentPageCount.end = this.pageInfo.count;
        } else {
          this.currentPageCount.end = (this.pageInfo.offset * this.pageInfo.pageSize) + this.pageInfo.limit;
        }
      }
    }
  }

  // for provisiong-report component
  getInstalledDevices(startTime, endTime, page) {
    this.reportsService.getInstalledDevices(startTime, endTime, page).subscribe({
      next: res => {
        this.isLoadingData = false;
        this.prevResponse = res.length;
        this.devicesList = [...this.devicesList, ...res];
        this.setPageInfo();
      },
      error: error => {
        this.isLoadingData = false;
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  getCommunicatingdDevices(startTime, endTime, page) {
    this.reportsService.getAllCommDevices(startTime, endTime, page).subscribe({
      next: res => {
        this.isLoadingData = false;
        this.prevResponse = res.length;
        this.devicesList = [...this.devicesList, ...res];
        this.setPageInfo();
      },
      error: error => {
        this.isLoadingData = false;
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  getAllNotCommDevices(startTime, endTime, page) {
    this.reportsService.getAllNotCommDevices(startTime, endTime, page).subscribe({
      next: res => {
        this.isLoadingData = false;
        this.prevResponse = res.length;
        this.devicesList = [...this.devicesList, ...res];
        this.setPageInfo();
      },
      error: error => {
        this.isLoadingData = false;
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  getSoftwareVersiondDevices(startTime, endTime, firmware, config, page) {
    if (firmware && config) {
      this.isLoadingData = true;
      this.reportsService.getFwConfigDevices(startTime, endTime, firmware, config, page).subscribe({
        next: res => {
          this.isLoadingData = false;
          this.prevResponse = res.length;
          this.devicesList = [...this.devicesList, ...res];
          this.setPageInfo();
        },
        error: error => {
          this.isLoadingData = false;
          this.sharedService.getErrorMsg(error);
        }
      });
    }
  }

  togglePopup() {
    if (this.searchModel && this.searchModel.columnVal) {
      this.searchModel.columnVal = '';
    }
    if (this.columnPopup) {
      this.cancelSelection();
    } else {
      this.checkCustomSelected();
      this.columnPopup = true;
    }
  }

  checkCustomSelected() {
    this.headerItems.map(item => {
      this.selectCount += this.deviceStaticHeader.includes(item.name) ? 1 : 0;
      if (this.customSelectedHeader.includes(item.name)) {
        item.checked = true;
        this.selectCount++;
      } else {
        item.checked = false;
      }
    });
    this.columns = this.headerItems;
  }

  selectItems(event, header, i) {
    if (!this.deviceStaticHeader.includes(header.name)) {
      if (event.target.checked) {
        this.selectCount++;
      } else {
        this.selectCount--;
      }
      this.headerItems.map(head => {
        if (head.name == header.name) {
          if (event.target.checked) {
            head.checked = true;
          } else {
            head.checked = false;
          }
        }
      });
      // this.headerItems[i].checked = event.target.checked;
    }
  }

  selectAllItems(event) {
    this.allSelected = event.target.checked;
    if (event.target.checked) {
      this.selectCount = this.headerItems.length;
    } else {
      this.selectCount = 0;
      this.headerItems.map(item => {
        this.selectCount += this.deviceStaticHeader.includes(item.name) ? 1 : 0;
      });
    }
    this.headerItems.map(head => {
      if (this.deviceStaticHeader.includes(head.name)) {
        head.checked = true;
      } else {
        head.checked = event.target.checked;
      }
    });
  }

  cancelSelection() {
    this.selectCount = 0;
    this.columnPopup = false;
  }

  updatePreference() {
    this.customHeaders = [];
    this.customSelectedHeader = [];
    this.selectCount = 0;
    this.headerItems.map(header => {
      if (
        header.checked &&
        !this.deviceStaticHeader.includes(header.name) &&
        !this.customSelectedHeader.includes(header.name)
      ) {
        const obj = {
          name: header.name,
          displayName: header.displayName,
          checked: header.checked,
          type: header.type
        };
        this.customHeaders.push(obj);
        this.customSelectedHeader.push(header.name);
      }
    });
    this.columnPopup = false;
  }

  // downloadFile() {
  //   const attributes = [...this.deviceStaticHeader, ...this.customSelectedHeader];
  //   this.downloadEvent.emit(attributes);
  // }

  pageCallback(value) {
    this.pageInfo.offset = value.offset;
    this.currentPageCount.start = (value.offset * value.pageSize) + 1;
    const lastPage = Math.floor(this.devicesList.length / this.pageInfo.limit);
    if (lastPage == value.offset) {
      this.currentPageCount.end = value.count;
    } else {
      this.currentPageCount.end = (value.offset * value.pageSize) + value.limit;
    }
    if (this.pageInfo.lastPage === (this.pageInfo.offset + 1)) {
      if (this.prevResponse === 100) {
        this.page = ++this.page;
        if (this.fromComponent === 'provisioning') {
          this.getInstalledDevices(this.startTime, this.endTime, this.page);
        } else if(this.fromComponent === 'communicating') {
  
        } else if(this.fromComponent === 'not-communicating') {
          this.getAllNotCommDevices(this.startTime, this.endTime, this.page);
        } else if(this.fromComponent === 'versioning') {
  
        }
      }
    }
  }

  downloadFile() {
    const attributes = [...this.deviceStaticHeader, ...this.customSelectedHeader];
    const url = this.fromComponent === 'communicating' ? 'devices/communicating/download' : this.fromComponent === 'not-communicating' ? 'devices/not-communicating/download' : this.fromComponent === 'version' ? `devices/firmware/config/download` : 'devices/installed/download';
    this.reportsService.downloadFileReportsTable(this.startTime, this.endTime, attributes, url, this.firmware, this.config).subscribe({
      next: res => {
        const contentDisposition = res.headers.get('Content-Disposition');
        let filename;
        if (contentDisposition == null) {
          const date = new Date();
          filename = 'Device_' + date + '.zip';
        } else {
          filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
        }
        const blob = new Blob([res.body], {
          type: 'application/zip'
        });
        FileSaver.saveAs(blob, filename);
      },
      error: error => {
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  onColumnSearch() {
    this.columns = this.headerItems;
    const inputVal = this.searchModel.columnVal.trim();
    const val = inputVal.toLowerCase();
    if (val === '') {
      this.columns = this.headerItems;
      return;
    } else {
      if (this.headerItems.length) {
        const temp = this.headerItems.filter((item, index) => {
          if (item.displayName.toLowerCase().includes(val)) {
            return this.headerItems[index];
          }
        });
        this.columns = temp;
      }
    }

  }

  getLastKnownStatus(lastKnownStatus) {
    if (lastKnownStatus) {
      return lastKnownStatus;
    } else {
      return "Registered";
    }
  }
}
