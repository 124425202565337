import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '@myenv/environment';
var UsersService = /** @class */ (function () {
    function UsersService(http, router, handler) {
        this.http = http;
        this.router = router;
        this.handler = handler;
        this.d = new Date();
        this.offsetInMinutes = this.d.getTimezoneOffset() * -1;
        this.timeZone = "?dateFormat=" + environment.isoDateTimeFormat + "&offsetInMinutes=" + this.offsetInMinutes;
        this.apiUrl = environment.apiUrl;
        this.reportModule = [];
        this.httpClient = new HttpClient(handler);
        var permissionmodules = localStorage.getItem('permissionModules');
        this.reportModule = permissionmodules.includes('REPORT');
    }
    UsersService.prototype.getUsers = function (roleId) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
        var tempUrl = this.apiUrl + 'users';
        if (roleId)
            tempUrl += "?roleId=" + roleId;
        return this.http.get(tempUrl, { headers: headers }).pipe(catchError(this.handleError));
    };
    UsersService.prototype.userSearch = function (key, value, roleId) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
        var tempUrl = this.apiUrl + 'users/user/search';
        if (key && value)
            tempUrl += "?key=" + key + "&value=" + value;
        if (roleId)
            tempUrl += "&roleId=" + roleId;
        return this.http.get(tempUrl, { headers: headers }).pipe(catchError(this.handleError));
    };
    UsersService.prototype.createUserReports = function (user) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
        return this.http.get(this.apiUrl + 'reports/user/' + user, { headers: headers }).pipe(catchError(this.handleError));
    };
    UsersService.prototype.getReports = function (page) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
        return this.http.get(this.apiUrl + 'reports/all?page=' + page, { headers: headers }).pipe(catchError(this.handleError));
    };
    UsersService.prototype.updateUserStatus = function (userId, enable) {
        return this.http.patch(this.apiUrl + "users/" + userId + "/active/" + enable, {}).pipe(catchError(this.handleError));
    };
    UsersService.prototype.getUserGroups = function (user) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
        return this.http.get(this.apiUrl + 'groups/assigned/user/' + user, { headers: headers }).pipe(catchError(this.handleError));
    };
    UsersService.prototype.createUser = function (data) {
        return this.http.post(this.apiUrl + 'users', data).pipe(catchError(this.handleError));
    };
    UsersService.prototype.updateUser = function (data) {
        return this.http.put(this.apiUrl + 'users/' + data.userId, data).pipe(catchError(this.handleError));
    };
    UsersService.prototype.deleteUser = function (userId) {
        return this.http.delete(this.apiUrl + 'users/' + userId).pipe(catchError(this.handleError));
    };
    UsersService.prototype.assignReports = function (userId, payload) {
        return this.http.put(this.apiUrl + ("reports/user/" + userId), payload)
            .pipe(catchError(this.handleError));
    };
    UsersService.prototype.unassignReports = function (userId, reportId) {
        return this.http.delete(this.apiUrl + ("reports/user/" + userId + "/report/" + reportId))
            .pipe(catchError(this.handleError));
    };
    UsersService.prototype.assignGroups = function (userId, payload) {
        return this.http.post(this.apiUrl + ("groups/assign/user/" + userId), payload)
            .pipe(catchError(this.handleError));
    };
    UsersService.prototype.unassignGroups = function (userId, groupId) {
        return this.http.delete(this.apiUrl + ("groups/user/" + userId + "/group/" + groupId))
            .pipe(catchError(this.handleError));
    };
    UsersService.prototype.downloadUserCSV = function (searchData, roleId) {
        var tempUrl = this.apiUrl + 'users/download' + this.timeZone;
        if (searchData.userTypeVal) {
            tempUrl += '&key=' + searchData.searchType + '&value=' + searchData.userTypeVal;
        }
        if (roleId)
            tempUrl += "&roleId=" + roleId;
        return this.http.get(tempUrl, { responseType: 'arraybuffer', observe: 'response' }).pipe(catchError(this.handleError));
    };
    UsersService.prototype.handleError = function (error) {
        console.log(error.error || error.json() || error);
        return observableThrowError(error.error || error.json() || error || 'Server error');
    };
    return UsersService;
}());
export { UsersService };
