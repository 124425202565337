import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';

import { RouterModule } from '@angular/router'
import { SupportRoutingModule } from './support-routing.module';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SupportService } from '@components/support/support.service';
import { SharedService } from '@shared/shared.service';
import { FormsModule }   from '@angular/forms';

import {SupportComponent} from "@components/support/support.component";
import { UserInfoComponent } from './user-info/user-info.component';
import { AdvancedSearchComponent } from './advanced-search/advanced-search.component';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
import { NotCommunicatingComponent } from './not-communicating/not-communicating.component';
import { SupportDashboardComponent } from './support-dashboard/support-dashboard.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import {OtaService} from "@components/ota/ota.service";
import {MoveComponent} from "@components/tools/move/move.component";
import {ToolsService} from "@components/tools/tools.service";
import { DeviceReplacementComponent } from './user-info/device-replacement/device-replacement.component';
import {DeviceReplacementService} from "@components/support/user-info/device-replacement/device-replacement.service";
import { ProvisioningComponent } from './user-info/provisioning/provisioning.component';
import { BillingReportComponent } from '../../components/graphic-reports/system-reports/billing-report/billing-report.component';
import { ReportsHeaderComponent } from '../graphic-reports/reports-header/reports-header.component';
import { ReportsSideMenuComponent } from '../graphic-reports/reports-side-menu/reports-side-menu.component';
@NgModule({
  imports: [
    CommonModule,
    SupportRoutingModule,
    SharedModule,
    RouterModule,
    NgxDatatableModule,
    FormsModule,
    TrimValueAccessorModule,
    NgSelectModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule
  ],
  declarations: [
    UserInfoComponent,
    AdvancedSearchComponent,
    SupportComponent,
    NotCommunicatingComponent,
    SupportDashboardComponent,
    MoveComponent,
    DeviceReplacementComponent,
    ProvisioningComponent,
    // BillingReportComponent,
    // ReportsHeaderComponent,
    // ReportsSideMenuComponent
  ],
  providers: [
    SupportService,
    SharedService,
    OtaService,
    ToolsService,
    DeviceReplacementService
  ],
  exports: [
    SupportComponent
  ]
})
export class SupportModule { }
