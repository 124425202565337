import { Subject } from 'rxjs';
var WorkerService = /** @class */ (function () {
    function WorkerService() {
        this.workerPath = 'assets/workers/live-worker.js';
        this.workerInit();
        this.onmessage();
    }
    // Post message to worker function.
    WorkerService.prototype.postMessage = function (workerMessage) {
        if (this.worker) {
            this.worker.postMessage(workerMessage);
        }
    };
    // Function to trigger onmessage listener
    WorkerService.prototype.onmessage = function () {
        this.worker.onmessage = function (e) {
            // console.log('onmessage service');
            // console.log(e.data);
            this.workerResponse = e.data;
            this.workerSubject.next(this.workerResponse);
        }.bind(this);
    };
    /**
     * Terminate the worker
     * It is not used since the worker is kept alive
     * If terminated it will kill thread for the session
     * Use cautiously and only if highly important
     */
    WorkerService.prototype.terminate = function () {
        this.worker.terminate();
    };
    // Initialize worker
    WorkerService.prototype.workerInit = function () {
        try {
            if (!!this.worker === false) {
                this.worker = new Worker(this.workerPath);
                this.workerSubject = new Subject();
            }
        }
        catch (exception) {
            console.error(exception);
        }
    };
    return WorkerService;
}());
export { WorkerService };
