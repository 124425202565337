import * as config from '../../assets/config.json';
import * as azugaEvents from '../../assets/azuga-events.json';

const message: any = {}
for (const key in config) {
    // if (config.hasOwnProperty(key)) {
        message[key] = config[key];
    // }
}
export const configMessage: any = message.default;
