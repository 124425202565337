
import {throwError as observableThrowError,  Observable, BehaviorSubject } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '@myenv/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpBackend } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable()
export class SupportService {
  public apiUrl = environment.apiUrl;
  private httpClient: HttpClient;
  // currentuser = JSON.parse(localStorage.getItem('currentUser'));

  constructor(private http: HttpClient, private router: Router, private handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  onSearchUser(key, value): Observable<any> {
    return this.http.get(`${this.apiUrl}users/search?key=${key}&value=${value}`).pipe(
      catchError(this.handleError));
  }

  getLoginAttempts(userId): Observable<any> {
    return this.http.get(`${this.apiUrl}password/${userId}`).pipe(
      catchError(this.handleError));
  }

  getUser(userId): Observable<any> {
    return this.http.get(`${this.apiUrl}users/${userId}?address=true`).pipe(
      catchError(this.handleError));
  }

  unlockUser(userName): Observable<any> {
    return this.http.patch(`${this.apiUrl}users/login-attempt?userName=${userName}`, {}).pipe(
      catchError(this.handleError));
  }

  getNotCommDevices(page, hours, customerId): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    return this.http.get(
      this.apiUrl + `devices/not-communicating/support?page=${page}&inHours=${hours}&customerId=${customerId}`,
      { headers }
      ).pipe(
      catchError(this.handleError));
  }

  getDevicesCount(hours, customerId): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    return this.http.get(
      this.apiUrl + `devices/not-communicating/support/count?inHours=${hours}&customerId=${customerId}`,
      { headers }
      ).pipe(
      catchError(this.handleError));
  }

  // onSearchCampaign(type, data) {
  //   if (type && data) {
  //     const token = localStorage.getItem('token');
  //     const headers = new HttpHeaders({
  //       'Authorization': 'Bearer ' + token,
  //     });
  //     let tempUrl = this.apiUrl + 'support/campaign/search';
  //     tempUrl += '?key=' + type + '&value=' + data
  //     return this.httpClient.get(tempUrl, { headers })
  //       .catch(this.handleError);
  //   }
  // }

  // getCounts(campaignId) {
  //   return this.http.get(this.apiUrl + 'support/campaign/' + campaignId + '/device/count')
  //     .catch(this.handleError)
  // }

  // addCampaign(data) {
  //   let token = localStorage.getItem('token');
  //   const headers = new HttpHeaders({
  //     'Authorization': 'Bearer ' + token
  //   });
  //   return this.http.post(this.apiUrl + 'support/campaign', data, { headers })
  //     .catch(this.handleError);
  // }

  // getAllCampaign(page) {
  //   const token = localStorage.getItem('token');
  //   const headers = new HttpHeaders({
  //     'Authorization': 'Bearer ' + token,
  //   });

  //   return this.http.get(this.apiUrl + 'support/campaign?page=' + page, { headers })
  //     .catch(this.handleError);
  // }

  // getCampaignCount() {
  //   return this.http.get(this.apiUrl + 'support/campaign/count')
  //     .catch(this.handleError);
  // }

  // getSupportOtaFiletype(data) {
  //   return this.http.get(this.apiUrl + 'support/ota/files/fileType/' + data)
  //     .catch(this.handleError);
  // }

  // getAllSupportOtaFiles(page) {
  //   return this.http.get(this.apiUrl + 'support/ota/files?page=' + page)
  //     .catch(this.handleError);
  // }

  // uploadXL(data, fileName, otaName) {
  //   let token = localStorage.getItem('token');
  //   const headers = new HttpHeaders({
  //     'Authorization': 'Bearer ' + token,
  //   });
  //   let tempUrl = this.apiUrl + 'support/ota/files/' + otaName + '/upload?fileName=' + fileName;
  //   return this.httpClient.post(tempUrl, data, { headers })
  //     .catch(this.handleError);
  // }

  getGroupCounts(customerId): Observable<any> {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    return this.http.get(this.apiUrl + `devices/group/count?customerId=${customerId}`, {headers}).pipe(
      catchError(this.handleError));
  }

  getTagCounts(customerId, groupBy): Observable<any> {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    return this.http.get(this.apiUrl + `devices/category/count?groupBy=${groupBy}&customerId=${customerId}`, {headers}).pipe(
      catchError(this.handleError));
  }

  getProvisioningDevices(page, customerId) {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    return this.http.get(this.apiUrl + `al/vehicles/provisioning/devices/customerId/${customerId}?page=${page}&limit=100`, {headers}).pipe(
      catchError(this.handleError));
  }

  provisionDeviceSearch(page, key, value,customerId) {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    return this.http.get(
      this.apiUrl +
      `al/vehicles/provisioning/search/customerId/${customerId}?key=${key}&value=${value}&page=${page}&limit=${100}`, {headers}
    ).pipe(catchError(this.handleError));
  }

  uploadCertificates(data, psn, status) {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    return this.httpClient.post(
      this.apiUrl + `al/vehicles/provisioning/doc/status/ais140/psn/${psn}/status/${status}`, data, { headers }
      ).pipe(catchError(this.handleError));
  }

  downloadKYC(psn) {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    return this.http.get(
      this.apiUrl + `al/vehicles/provisioning/kyc/psn/${psn}/download`, { headers, responseType: 'arraybuffer' }
      ).pipe(catchError(this.handleError));
  }

  activateDevice(requestId, psn, status, notify, remarks) {
    return this.http.put(
      this.apiUrl +
      `al/vehicles/provisioning/requestId/${requestId}/psn/${psn}/status/${status}/notify/${notify}?remark=${remarks}`, {})
    .pipe(catchError(this.handleError));
  }

  retriggerDevice(requestId, psn) {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    return this.httpClient.post(
      this.apiUrl + `al/vehicles/provisioning/cco/requestId/${requestId}/psn/${psn}`, {}, { headers }
      ).pipe(catchError(this.handleError));
  }

  handleError(error: any | any) {
    return observableThrowError(error.error || error || 'Server error');
  }
}
