import { OnInit, OnChanges, EventEmitter } from '@angular/core';
import { Subject, concat, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { SharedService } from '../shared.service';
import { configMessage } from '@shared/config-message';
import * as moment from 'moment';
import { environment } from '@myenv/environment';
var SearchSelectVehicleComponent = /** @class */ (function () {
    function SearchSelectVehicleComponent(sharedService) {
        this.sharedService = sharedService;
        this.vehicleChangeEvent = new EventEmitter();
        this.clearable = true;
        this.isMulti = false;
        this.closeOnSelect = true;
        this.vehicles = [];
        this.defaultVehicles = [];
        this.vehicleLoading = false;
        this.vehicleInput$ = new Subject();
        this.selectedVehicles = null;
        this.deviceStatus = configMessage.deviceStatus;
        this.deviceStatusClass = configMessage.deviceStatusClass;
        this.dateFormat = environment.smallDateFormat;
        this.attributes = 'deviceId,serialNumber,assetName,productSerialNumber,vin,lastCommunication,deviceStatus,architectureType,ecuMake,generation,simStatus,imei,firmwareVersion,configVersion,simCcid,lastKnownEvent,lastKnownStatus';
    }
    SearchSelectVehicleComponent.prototype.ngOnInit = function () {
        this.getAllVehicles();
    };
    SearchSelectVehicleComponent.prototype.ngOnChanges = function (changes) {
        if (changes.querySelectVehicle && changes.querySelectVehicle.previousValue !== undefined) {
            if (changes.querySelectVehicle.previousValue !== changes.querySelectVehicle.currentValue) {
                if (changes.querySelectVehicle.previousValue === null) {
                    this.selectedVehicles = this.querySelectVehicle;
                }
            }
        }
    };
    SearchSelectVehicleComponent.prototype.trackByFn = function (item) {
        return item.deviceId;
    };
    SearchSelectVehicleComponent.prototype.getAllVehicles = function (selectedGroup) {
        var _this = this;
        var vehiclesSubsc = this.ev ? this.sharedService.getAllEvVehicles(0) : this.sharedService.getAllDevices(selectedGroup);
        vehiclesSubsc.subscribe({
            next: function (res) {
                _this.defaultVehicles = res;
                localStorage.setItem('devices', JSON.stringify(res));
                // if (this.ev) {
                //   this.defaultVehicles = res.filter((device) => device.generation === "EBIKE");
                // } else { this.defaultVehicles = res; }
                // this.performVehicleOperations();
                // this.selectedVehicles = res[0];
                // this.loadVehices();
                _this.selectVehicle();
            },
            error: function (error) {
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    SearchSelectVehicleComponent.prototype.performVehicleOperations = function () {
        this.vehicles.map(function (vehicle) {
            var commDate = Date.parse(new Date(vehicle.lastCommunication).toUTCString());
            var date = moment(commDate).fromNow();
            vehicle.lastCommunicationString = date;
        });
    };
    SearchSelectVehicleComponent.prototype.selectVehicle = function () {
        if (!this.defaultselectvehicle) {
            if (this.autoSelect) {
                this.selectedVehicles = this.selectedVehicles ? this.selectedVehicles : this.defaultVehicles[0];
            }
            else {
                this.selectedVehicles = this.selectedVehicles ? this.selectedVehicles : null;
            }
        }
        else {
            if (this.selectedVehicles) {
                this.sharedService.setdefaultselectvehicle(this.selectedVehicles);
            }
            var defaultSel = this.sharedService.getdefaultselectvehicle();
            if (this.ev) {
                if (defaultSel && defaultSel.generation !== 'EBIKE') {
                    if (this.defaultVehicles && this.defaultVehicles.length) {
                        this.selectedVehicles = this.defaultVehicles[0];
                    }
                }
                else if (defaultSel === null) {
                    this.selectedVehicles = this.defaultVehicles[0];
                }
                else {
                    this.selectedVehicles = defaultSel;
                }
            }
            else {
                this.selectedVehicles = defaultSel ? defaultSel : this.defaultVehicles[0];
            }
        }
        this.vehicleChangeEvent.emit(this.selectedVehicles);
        this.vehicleLoading = false;
    };
    SearchSelectVehicleComponent.prototype.validateInputString = function (term) {
        term = term.replace(/[^_\-@.a-zA-Z0-9\s]/g, '').trim();
        this.vehicleInput$.next(term);
        return term;
    };
    SearchSelectVehicleComponent.prototype.loadVehicles = function () {
        var _this = this;
        this.vehicle$ = concat(of(this.defaultVehicles), // default items
        this.vehicleInput$.pipe(distinctUntilChanged(), debounceTime(500), tap(function () { return _this.vehicleLoading = true; }), switchMap(function (term) { return _this.sharedService.onSearchVehicle('assetName', _this.validateInputString(term)).pipe(catchError(function (error) { return of(_this.sharedService.getErrorMsg(error), _this.defaultVehicles); }), // empty list on error
        tap(function () { return _this.vehicleLoading = false; })); })));
        this.vehicle$.subscribe({
            next: function (res) {
                if (res && res.length && _this.ev) {
                    _this.vehicles = res.filter(function (device) { return device.generation === "EBIKE"; });
                }
                else {
                    _this.vehicles = res;
                }
                _this.vehicleLoading = false;
                _this.performVehicleOperations();
            },
            error: function (error) {
                _this.vehicleLoading = false;
                // this.sharedService.getErrorMsg(error);
            }
        });
    };
    SearchSelectVehicleComponent.prototype.getLastKnownStatus = function (lastKnownStatus) {
        if (lastKnownStatus) {
            return lastKnownStatus;
        }
        else {
            return "Registered";
        }
    };
    return SearchSelectVehicleComponent;
}());
export { SearchSelectVehicleComponent };
