<app-notify-message></app-notify-message>
<app-reports-header></app-reports-header>
<div class="tab-content">
  <div role="tabpanel" class="tab-pane fade in active show relative" id="deepView">
    <div class="row no-gutters">
      <div class="col-sm-12 fullheight">
        <!-- Main content -->
        <div class="text-center" style="margin-top: 30vh;">
          <img width="170" src="../../../assets/img/deepView.png"/>
          <h4 class="mt-3">
            <a href="http://deepview.drivewithdarby.com:3838/demo2/" target="_blank">Click here</a> to view DeepView Reports.
          </h4>
          <p>DeepView Reports are currently in another location.</p>
        </div>
      </div>
    </div>
  </div>
</div>