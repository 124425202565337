import { Component, OnInit } from '@angular/core';
import Chart from 'chart.js';
import { GraphicReportsService } from '../../graphic-reports.service';
import { SharedService } from '@myproject/shared/shared.service';
import { DatePipe } from '@angular/common';
import * as FileSaver from 'file-saver';
import { Title } from '@angular/platform-browser';
import { environment } from '@myenv/environment';

@Component({
  selector: 'app-version-drilldown-report',
  templateUrl: './version-drilldown-report.component.html',
  styleUrls: ['./version-drilldown-report.component.scss']
})
export class VersionDrilldownReportComponent implements OnInit {
  page = 0;
  isLoading = false;
  startTime: number;
  endTime: number;
  firmwares: any[] = [];
  configs: any[] = [];
  fwChart: any;
  configChart: any;
  areaChart: any;
  devices: any[] = [];
  fwSelected: any = {};
  cfgSelected: any = {};
  backgroundColors: any[] = [];
  resetOffset = false;
  hideCalendar = false;
  blockCall = false;
  highestCount = 0;
  searchModel = {
    fwSearchVal: '',
    fwSearchedData: [],
    cfgSearchVal: '',
    cfgSearchedData: [],
  };
  // var for reports-table-component to send firmware, config loading
  isLoadingCFG = true;
  firmwareDevicesCount = 0;

  constructor(
    private reportsService: GraphicReportsService,
    private title: Title,
    private sharedService: SharedService
  ) { }

  ngOnInit() {
    this.title.setTitle('Software Version' + environment.title_text);
  }

  onTimeChange(e) {
    this.startTime = e.startTime;
    this.endTime = e.endTime;
    this.page = 0;
    this.resetOffset = !this.resetOffset;
      this.searchModel = {
        fwSearchVal: '',
        fwSearchedData: [],
        cfgSearchVal: '',
        cfgSearchedData: [],
      };
    this.getFirmwareCount(e.startTime, e.endTime);
  }

  onPageChange(page) {
    this.page = 0;
    this.getFwConfigDevices(this.startTime, this.endTime, this.fwSelected.firmwareVersion, this.cfgSelected.configVersion, page);
  }

  refresh() {
    this.page = 0;
    this.resetOffset = !this.resetOffset;
    this.searchModel = {
      fwSearchVal: '',
      fwSearchedData: [],
      cfgSearchVal: '',
      cfgSearchedData: [],
    };
    this.getFirmwareCount(this.startTime, this.endTime);
  }

  onFWSearch() {
    const global = this;
    this.searchModel.fwSearchedData = this.firmwares.filter(function (item) {
      return item.firmwareVersion.toLowerCase().includes(global.searchModel.fwSearchVal.toLowerCase());
    });
  }

  onCFGSearch() {
    const global = this;
    this.searchModel.cfgSearchedData = this.configs.filter(function (item) {
      return item.configVersion.toLowerCase().includes(global.searchModel.cfgSearchVal.toLowerCase());
    });
  }

  setBackgroundColors(res) {
    res.map(item => {
      const r = Math.floor(Math.random() * 254);
      const g = Math.floor(Math.random() * 254);
      const b = Math.floor(Math.random() * 254);
      this.backgroundColors.push('rgb(' + r + ',' + g + ',' + b + ')');
    });
  }

  getFirmwareCount(startTime, endTime) {
    this.isLoading = true;
    this.isLoadingCFG = true;
    this.reportsService.getFirmwareCount(startTime, endTime).subscribe({
      next: res => {
        this.isLoadingCFG = false;
        this.isLoading = false;
        if (res.length) {
          // console.log(res);
          this.firmwares = res;
          this.searchModel.fwSearchedData = res;
          this.fwSelected = res[0];
          this.setBackgroundColors(res);
          this.drawPieChart('pieChartFW', res);
          this.getConfigCount(startTime, endTime, res[0].firmwareVersion);
          this.firmwareDevicesCount = res[0].count;
        } else {
          this.firmwares = [];
          this.searchModel.fwSearchedData = [];
          this.configs = [];
          this.devices = [];
          this.fwSelected = {};
          this.cfgSelected = {};
          this.drawPieChart('pieChartFW', []);
          this.drawPieChart('pieChartCFG', []);
          this.drawAreaGraph([]);
          this.firmwareDevicesCount = 0;
        }
      },
      error: error => {
        this.isLoading = false;
        this.isLoadingCFG = false;
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  getConfigCount(startTime, endTime, firmware) {
    this.isLoadingCFG = true;
    this.firmwareDevicesCount = 0;
    this.reportsService.getConfigCount(startTime, endTime, firmware).subscribe({
      next: res => {
        if (res.length) {
          // console.log(res);
          this.configs = res;
          this.searchModel.cfgSearchedData = res;
          this.cfgSelected = res[0];
          this.drawPieChart('pieChartCFG', res);
          this.getFwConfigTrendData(startTime, endTime, firmware, res[0].configVersion);
          this.getFwConfigDevices(startTime, endTime, firmware, res[0].configVersion, this.page);
          this.fwSelected.configVersion = res[0].configVersion;
          this.firmwareDevicesCount = res[0].count;
        } else {
          this.configs = [];
          this.searchModel.cfgSearchedData = [];
          this.devices = [];
          this.cfgSelected = {};
          this.drawPieChart('pieChartCFG', []);
          this.drawAreaGraph([]);
          this.isLoading = false;
          this.firmwareDevicesCount = 0;
        }
        this.isLoadingCFG = false;
      },
      error: error => {
        this.isLoading = false;
        this.isLoadingCFG = false;
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  getFwConfigTrendData(startTime, endTime, firmware, config) {
    this.reportsService.getFwConfigTrendData(startTime, endTime, firmware, config).subscribe({
      next: res => {
        if (res.length) {
          if (this.areaChart) {
            this.areaChart.destroy();
          }
          this.drawAreaGraph(res);
        } else {
          this.drawAreaGraph([]);
        }
        this.isLoading = false;
      },
      error: error => {
        this.isLoading = false;
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  getFwConfigDevices(startTime, endTime, firmware, config, page) {
    this.reportsService.getFwConfigDevices(startTime, endTime, firmware, config, page).subscribe({
      next: res => {
        if (!res.length) { this.blockCall = true; }
        if (page === 0) {
          this.devices = res;
        } else {
          this.devices = [...this.devices, ...res];
        }
      },
      error: error => {
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  downloadFwConfigDevices(attributes) {
    this.reportsService.downloadFwConfigDevices(
      this.startTime, this.endTime, this.fwSelected.firmwareVersion, this.cfgSelected.configVersion, attributes
    ).subscribe({
      next: res => {
        const contentDisposition = res.headers.get('Content-Disposition');
        let filename;
        if (contentDisposition == null) {
          const date = new Date();
          filename = 'Device_' + date + '.zip';
        } else {
          filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
        }
        const blob = new Blob([res.body], {
          type: 'application/zip'
        });
        FileSaver.saveAs(blob, filename);
      },
      error: error => {
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  selectFirmware(e) {
    this.page = 0;
    this.blockCall = false;
    this.resetOffset = !this.resetOffset;
    this.fwSelected = e;
    this.searchModel.cfgSearchVal = '';
    this.getConfigCount(this.startTime, this.endTime, this.fwSelected.firmwareVersion);
  }

  selectConfig(e) {
    this.page = 0;
    this.blockCall = false;
    this.resetOffset = !this.resetOffset;
    this.cfgSelected = e;
    this.fwSelected.configVersion = e.configVersion;
    this.firmwareDevicesCount = e.count;
    this.getFwConfigTrendData(this.startTime, this.endTime, this.fwSelected.firmwareVersion, this.cfgSelected.configVersion);
    this.getFwConfigDevices(this.startTime, this.endTime, this.fwSelected.firmwareVersion, this.cfgSelected.configVersion, 0);
  }

  drawAreaGraph(data) {
    if (this.areaChart) {
      this.areaChart.destroy();
    }
    this.highestCount = 0;
    const labels = [];
    const counts = [];
    let label = '';
    if (data.length) {
      label = `${data[0].firmwareVersion} > ${data[0].configVersion}`;
    }
    data.map(item => {
      const datePipe = new DatePipe('en-US');
      const eventDate = datePipe.transform(item.day, 'dd MMM');
      labels.push(eventDate);
      counts.push(item.count);
      if (item.count > this.highestCount) {
        this.highestCount = item.count;
      }
    });
    var ctx = document.getElementById('areaGraph');
    this.areaChart = new Chart(ctx, {
      type: 'line',
      responsive: true,
      data: {
        labels: labels,
        datasets: [{
          label: label,
          data: counts,
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgba(54, 162, 235, 1)',
        }]
      },
      options: {
        plugins: {
          filler: {
            propagate: true
          }
        },
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            display: true,
            ticks: {
              beginAtZero: true
            }
          }],
          yAxes: [{
            display: true,
            ticks: {
              suggestedMin: 0,
              suggestedMax: 10,
              stepSize: this.highestCount > 9 ? null : 1
            }
          }]
        }
      }
    });
  }

  drawPieChart(container, data) {
    if (container === 'pieChartFW' && this.fwChart) {
      this.fwChart.destroy();
    } else if (container === 'pieChartCFG' && this.configChart) {
      this.configChart.destroy();
    }
    const labels = [];
    const counts = [];
    let label = '';
    data.map(item => {
      if (item.firmwareVersion) {
        label = 'firmware';
        labels.push(item.firmwareVersion);
      } else if (item.configVersion) {
        label = 'config';
        labels.push(item.configVersion);
      }
      counts.push(item.count);
    });
    var ctx = document.getElementById(container);
    var myChart = new Chart(ctx, {
      type: 'pie',
      responsive: true,
      data: {
        labels: labels,
        datasets: [{
          data: counts,
          backgroundColor: this.backgroundColors,
          borderColor: this.backgroundColors
        }]
      },
      options: {
        legend: {
          position: 'bottom',
          display: false
        },
        scales: {
          xAxes: [{
            display: false,
            ticks: {
              beginAtZero: true
            }
          }],
          yAxes: [{
            display: false,
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    });
    if (label === 'firmware') {
      this.fwChart = myChart;
    } else {
      this.configChart = myChart;
    }
  }
}
