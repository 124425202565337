import { throwError as observableThrowError, Observable } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { Router } from '@angular/router';

import { environment } from '@myenv/environment';

@Injectable()
export class GroupsService {

  public apiUrl = environment.apiUrl;
  private httpClient: HttpClient;

  constructor(private http: HttpClient, private router: Router, private handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  // To get groups
  getGroups() {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });

    return this.http.get(this.apiUrl + 'groups?includeDevice=true', { headers }).pipe(
      catchError(this.handleError));
  }

  // To get assigned groups
  getAssignedGroups() {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });

    return this.http.get(this.apiUrl + 'groups?includeDevice=true', { headers }).pipe(
      catchError(this.handleError));
  }

  // To create a group
  createGroup(data) {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });

    return this.http.post(this.apiUrl + 'groups', data, { headers }).pipe(
      catchError(this.handleError));
  }

  // To update a group
  updateGroup(data) {
    return this.http.put(this.apiUrl + 'groups/' + data.groupId + '?groupName=' + data.name, data).pipe(
      catchError(this.handleError));
  }

  // To delete a group
  deleteGroup(groupId) {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });

    return this.http.delete(this.apiUrl + 'groups/' + groupId, { headers }).pipe(
      catchError(this.handleError));
  }

  onSearchGroup(value, includeDevice): Observable<any> {
    return this.http.get(`${this.apiUrl}groups?name=${value}&includeDevice=${includeDevice}`).pipe(
      catchError(this.handleError));
  }

  handleError(error: any | any) {
    return observableThrowError(error.error || error.json() || error || 'Server error');
  }

  // getAssociatedVehicles(groupId, page, pageLimit) {
  //   return this.http.get(this.apiUrl + 'devices/groupId/' + groupId + '?page=' + page + '&pagelimit=' + pageLimit + '&attributes=assetName');
  // }

  getAssociatedVehicles(groupId, page, pageLimit) {
    return this.http.get(
      this.apiUrl +
      `devices?sortingKey=lastCommunication&direction=desc&key=groupName&value=${groupId}&attributes=assetName&page=${page}&pagelimit=${pageLimit}&isAssociatedDevice=true`)
      .pipe(catchError(this.handleError));
  }

  addDevicesToGroup(groupId, devices, selectAll, filterCriteria) {
    let payload = { actionName: 'CG', selectAll: selectAll, deviceIds: devices, groupId: groupId };
    if (selectAll) {
      payload['filterCriteria'] = {};
      if (filterCriteria[1]) {
        payload['filterCriteria'][filterCriteria[0]] = filterCriteria[1];
      }
      payload['filterCriteria']['isAssociatedDevice'] = false;
      if (filterCriteria[3]) {
        payload['filterCriteria']['groupId'] = filterCriteria[3];
      } if (filterCriteria[4]) {
        payload['filterCriteria']['status'] = filterCriteria[4];
      }
    }
    return this.http.put(this.apiUrl + 'devices/bulkAction', payload).pipe(
      catchError(this.handleError));
  }

  addDevicesToTag(id, devices, selectAll, action, filterCriteria) {
    let payload = { actionName: action, selectAll: selectAll, deviceIds: devices, tags: [id] };
    if (selectAll) {
      payload['filterCriteria'] = {};
      if (filterCriteria[1]) {
        payload['filterCriteria'][filterCriteria[0]] = filterCriteria[1];
      }
      payload['filterCriteria']['isAssociatedDevice'] = false;
      if (filterCriteria[3]) {
        payload['filterCriteria']['groupId'] = filterCriteria[3];
      } if (filterCriteria[4]) {
        payload['filterCriteria']['status'] = filterCriteria[4];
      }
    }
    return this.http.put(this.apiUrl + 'devices/bulkAction', payload).pipe(
      catchError(this.handleError));
  }

  uploadFile(data): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    return this.httpClient.post(this.apiUrl + `groups/upload`, data, { headers }).pipe(
      catchError(this.handleError));
  }
}
