<!-- <div id="drawerSlideButton" (click)="toggleDrawer()">
    <div class="text">
        <span><i class="fa fa-area-chart p-0 m-0"></i> Visualization</span>
    </div>
</div> -->
<!-- BOTTOM DRAWER START -->
<div id="drawerContainer">
    <div id="drawerContent" class="drawer-open">
        <!-- *ngIf="!visualizing" -->
        <div class="row" style="height: inherit;">
            <!-- [ngStyle]="{'overflow-x': tabPos === 2 ? 'hidden' : 'auto' }" -->
            <div class="tab-content col-12 tab-body" [ngStyle]="(noDevices === null) ? { 'display': 'block'} : {'display': 'none'}">
                <div role="tabpanel" class="tab-pane fade" style="height: 100%;"
                    [class.show]="tabPos === 0 || tabPos === 1" [class.active]="tabPos === 0 || tabPos === 1"
                    id="allConstraintss">
                    <div class="info" *ngIf="(!loading.graph && this.tabPos === 0 && !selected.length && !noGraphData)">
                        <img width="170" src="../../../assets/img/info_nodata_256.png" />
                        <h6 class="" style="text-align: center;">Select either one or two PIDs to Visualize<br></h6>
                    </div>
                    <div class="info"
                        *ngIf="(!loading.graph && this.tabPos === 1 && !activeGPS.length && !noGraphData)">
                        <img width="170" src="../../../assets/img/info_nodata_256.png" />
                        <h6 class="" style="text-align: center;">Select either one or two Parameters to Visualize<br>
                        </h6>
                    </div>
                    <div class="row chart-container no-gutters"
                        [ngStyle]="(loading.graph || noGraphData || (tabPos === 0 && !selected.length) || (tabPos === 1 && !activeGPS.length)) ? { 'display': 'none'} : {'display': 'block'}"
                        style="height: inherit;">
                        <div class="col-12 graph-container nopadding" style="height: inherit;">
                            <div class="trip-chart-legend di" id="legend"></div>
                            <div id="chartcontainer">
                                <canvas id="lineChart" aria-label="linechart" role="img"></canvas>
                            </div>
                        </div>
                    </div>
                    <div class="text-center loader" [ngStyle]="loading.graph ? { 'display': 'block'} : {'display': 'none'}">
                        <img src="../../../assets/img/darby_loader_small.gif" />
                    </div>
                    <div class="info" *ngIf="(!loading.graph && noGraphData)">
                        <img width="170" src="../../../assets/img/info_nodata_256.png" />
                        <h5 class="" style="text-align: center;">No data to show<br></h5>
                    </div>
                </div>
                <div role="tabpanel" class="tab-pane fade" [class.show]="tabPos === 2" style="overflow: hidden; height: 100%;"
                    [class.active]="tabPos === 2">
                    <div class="row val" [ngStyle]="dashCamVideoAvailable ? { 'display': 'flex'} : {'display': 'none'}">
                        <div class="col-md-6">
                            <div class="">
                                <!-- src="../../../assets/VID20220322170911.mp4" -->
                                <video height="200" id="camvid" #currentPlaying [muted]="true" controls
                                    [autoplay]="false" loop playsinline src="{{ selVid.videoURL }}">
                                    Your browser does not support HTML5 video.
                                </video>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="title">
                                <span class="">{{ getDashcamTitle(selVid.eventName) | titlecase }}</span>
                            </div>
                            <p class="params">
                                <span class="text-blue-grey font-14">Initial Speed: </span>
                                <span class="text-blue-grey font-14">{{ selVid.initSpeed ? selVid.initSpeed : '--'
                                    }}</span>
                            </p>
                            <p class="params">
                                <span class="text-blue-grey font-14">Final Speed: </span>
                                <span class="text-blue-grey font-14">{{ selVid.FinalSpeed ? selVid.FinalSpeed : '--'
                                    }}</span>
                            </p>
                            <p class="params">
                                <span class="text-blue-grey font-14">Max Speed: </span>
                                <span class="text-blue-grey font-14">{{ selVid.maxBraking ? selVid.maxBraking : '--'
                                    }}</span>
                            </p>
                            <div class="">
                                <!-- <i class="fa fa-map-marker" aria-hidden="true"></i> -->
                            </div>
                        </div>
                    </div>
                    <div class="info" *ngIf="!dashCamVideoAvailable && tabPos === 2">
                        <img width="170" src="../../../assets/img/info_nodata_256.png" />
                        <h5 class="" style="text-align: center;">No Videos to Show<br></h5>
                    </div>
                </div>
            </div>
            <div class="tab-content col-12 tab-body" [ngStyle]="noDevices ? { 'display': 'block'} : {'display': 'none'}">
                <div class="info">
                    <img width="170" src="../../../assets/img/info_nodata_256.png" />
                    <h5 class="" style="text-align: center;">No data to show<br></h5>
                </div>
            </div>
            <!-- <div class="row content-row" *ngIf="!visualizing"> -->
        </div>
        <!-- </div> -->

        <!-- <div class="row chart-container" *ngIf="visualizing">
            <div class="col-2">
                <div *ngIf="pid1Data.length>=1 && !chartLoading">
                    <button class="btn btn-peak btn-lg border-0 py-2 px-4"
                        (click)="tabPos === 0 ? resetOBDPids():resetGPS(); searchModel.value = '';">
                        <i class="fa fa-chevron-left" aria-hidden="true"></i>
                        Go Back
                    </button>
                    <div class="tripduration" *ngIf="!nonTripMode && hasTrip">
                        <span>Trip Duration<i class="fa fa-info-circle pl-1 fa-xs"
                                tooltip="Trip Duration in Hours, Minutes, Seconds" placement="top"></i></span>
                        <div> {{ durationOfTrip }} </div>
                    </div>
                </div>
                <div class="trip-chart-legend" id="legend"></div>
            </div>
            <div class="col-10 graph-container">
                <div class="text-center loader" *ngIf="chartLoading">
                    <img src="../../../assets/img/darby_loader_small.gif" />
                </div>
                <div class="text-center nodata" *ngIf="pid1Data.length==0 && !chartLoading">
                    <h5 class="d-inline">No Data to Visualize. <br>
                        <a (click)="tabPos === 0 ? resetOBDPids():resetGPS()" class="text-primary">
                            <u>Go Back</u>
                        </a>
                    </h5>
                </div>
                <div style="width: 100%; height: 62vh;">
                    <canvas id="lineChart" aria-label="linechart" role="img"></canvas>
                </div>
            </div>
        </div> -->
    </div>
</div>