import { OnChanges, OnInit, SimpleChanges, ElementRef } from '@angular/core';
import { LiveService } from '@components/live/live.service';
import { configMessage } from '@shared/config-message';
import { EventEmitter } from '@angular/core';
import { SharedService } from '@myproject/shared/shared.service';
import Chart from 'chart.js';
var ObdpidComponent = /** @class */ (function () {
    function ObdpidComponent(liveService, sharedService) {
        this.liveService = liveService;
        this.sharedService = sharedService;
        this.updatePointerEvents = new EventEmitter();
        this.drawerIsOpen = false;
        this.allConstraints = [];
        this.tempAllConstraints = configMessage.allPids;
        this.supConstraints = [];
        this.supIconConstraints = [];
        this.supportedPidOrder = [13, 12, 17, 166, 5, 15, 1];
        this.activePids = [];
        this.smallContainer = true;
        // Chart Variables
        this.visualizing = false;
        this.chartLoading = false;
        this.lineChart = null;
        this.pidLabel = [];
        this.pid1Data = [];
        this.pid2Data = [];
        this.durationOfTrip = '';
        // initiated = false;
        // obd search
        this.searchModel = {
            value: '',
            search: false,
            searchBy: 'pidNo',
            hidden: []
        };
        this.isLoading = false;
        this.tabPos = 0;
        this.op = null;
        this.selectedPID = [];
        this.lastDevice = {
            deviceId: {
                prev: null,
                cur: null
            },
            ts: {
                prev: null,
                cur: null
            },
            te: {
                prev: null,
                cur: null
            }
        };
        this.onSearchNoDF = false;
        this.noGraphData = false;
        this.subscribedReq = [];
        this.subscribedReqOnLoad = [];
        // gps tab variables
        this.activeGPS = [];
        this.tempGPSConstraints = configMessage.gpsPids;
        // dashCam
        this.dashCamPos = 0;
        this.selVid = {
            eventName: null,
            videoURL: null,
            initSpeed: null,
            FinalSpeed: null,
            maxBraking: null,
            camType: null,
            msgId: null
        };
        this.dashCamVideoAvailable = true;
        this.loading = {
            getSupported: true,
            graph: true
        };
        this.selected = [];
        this.noDevices = null;
        this.filteredData = [];
        this.pidAllConstraints = [];
    }
    ObdpidComponent.prototype.ngOnInit = function () { };
    ObdpidComponent.prototype.ngOnChanges = function (changes) {
        if (this.params && !this.params.hasOwnProperty('noDevices')) {
            this.tabPos = this.params.tabPos;
            this.noDevices = null;
            if (this.params.tabPos === 2) {
                this.loading.graph = false;
                this.dashCamVideoAvailable = false;
                if (this.params.dashCam && this.params.dashCam.hasOwnProperty('videoURL')) {
                    this.onVideoClick(this.params.dashCam);
                    this.dashCamVideoAvailable = true;
                }
            }
            else {
                if (this.params.tabPos === 1) {
                    if (this.params.selected) {
                        this.activeGPS = this.params.selected;
                    }
                }
                else {
                    if (this.params.selectedPidIds) {
                        this.activePids = this.params.selectedPidIds;
                    }
                }
                this.selected = this.params.selected;
                this.selectedPID = this.params.selected;
                this.selectedDevice = this.params.device;
                this.tsTime = this.params.tsTime;
                this.teTime = this.params.teTime;
                if (this.params.tabPos === 0 || (this.tabPos === 1 && !this.params.hasOwnProperty('selectedPidIds'))) {
                    this.getChartData(this.params.tabPos === 0 ? 'pids' : 'gps');
                }
            }
        }
        else if (this.params && this.params.hasOwnProperty('noDevices')) {
            this.noDevices = true;
        }
    };
    ObdpidComponent.prototype.ngOnDestroy = function () {
        this.resetReq();
    };
    ObdpidComponent.prototype.onVideoClick = function (p1) {
        this.selVid.eventName = p1.eventName;
        this.selVid.msgId = p1.messageId;
        this.selVid.videoURL = p1.videoURL;
        this.selVid.initSpeed = p1.initSpeed;
        this.selVid.FinalSpeed = p1.FinalSpeed;
        this.selVid.maxBraking = p1.maxBraking;
        this.currentPlaying.nativeElement.src = p1.videoURL;
        this.selVid.camType = p1.camType === 'FRONT' ? 'Front' : p1.camType === 'BACK' ? 'Back' : 'In Cabin';
    };
    ObdpidComponent.prototype.getAllPids = function () {
        var _this = this;
        Object.keys(this.tempAllConstraints).map(function (key) {
            _this.allConstraints.push(_this.tempAllConstraints[key]);
        });
        localStorage.setItem('allPids', JSON.stringify(this.allConstraints));
    };
    ObdpidComponent.prototype.stop = function () {
        this.currentPlaying.nativeElement.pause();
    };
    ObdpidComponent.prototype.compareStrings = function (a, b) {
        a = a.toLowerCase();
        b = b.toLowerCase();
        return (a < b) ? -1 : (a > b) ? 1 : 0;
    };
    ObdpidComponent.prototype.getDashcamTitle = function (p1) {
        if (p1) {
            var str = p1.split('_');
            return str[0] + ' ' + str[1];
        }
    };
    ObdpidComponent.prototype.resetOBDPids = function () {
        this.selected = [];
        this.pid1Data = [];
        this.pid2Data = [];
        this.pidLabel = [];
        this.durationOfTrip = '';
        if (this.lineChart) {
            this.lineChart.destroy();
            document.getElementById('legend').innerHTML = '';
        }
    };
    ObdpidComponent.prototype.resetGPS = function () {
        this.activeGPS = [];
        this.pid1Data = [];
        this.pid2Data = [];
        this.pidLabel = [];
        this.durationOfTrip = '';
        if (this.lineChart) {
            this.lineChart.destroy();
            document.getElementById('legend').innerHTML = '';
        }
    };
    ObdpidComponent.prototype.resetReq = function () {
        this.subscribedReq.map(function (r) {
            try {
                r.unsubscribe();
            }
            catch (err) { }
        });
        this.subscribedReq = [];
    };
    ObdpidComponent.prototype.getId = function (i, item) {
        return item.title + i.toString();
    };
    ObdpidComponent.prototype.getChartData = function (p1) {
        var _this = this;
        this.visualizing = true;
        this.loading.graph = true;
        this.noGraphData = false;
        var request = null;
        // if (this.subscribedReq.length && this.subscribedReq[0]) {
        //   this.subscribedReq[0].unsubscribe();
        //   this.subscribedReq = [];
        // } else {
        //   this.subscribedReq = [];
        // }
        if (p1 === 'pids') {
            if (this.selected.length === 1) {
                var attribute1 = this.selected[0];
                var selectedPids_1 = [attribute1];
                request = this.liveService.getallMessages(this.selectedDevice.deviceId, this.tsTime, this.teTime, 'obds')
                    .subscribe(function (res) {
                    if (res.length) {
                        _this.op = 'pids';
                        _this.selectedPID = selectedPids_1;
                        _this.sortChartData(_this.selected, res, 'pids');
                    }
                    else {
                        _this.loading.graph = false;
                        _this.noGraphData = true;
                    }
                }, function (error) {
                    _this.loading.graph = false;
                    // this.sharedService.getErrorMsg(error);
                });
            }
            else if (this.selected.length === 2) {
                var selectedPids_2 = this.selected;
                request = this.liveService.getallMessages(this.selectedDevice.deviceId, this.tsTime, this.teTime, 'obds')
                    .subscribe(function (res) {
                    if (res.length) {
                        _this.op = 'pids';
                        _this.selectedPID = selectedPids_2;
                        _this.sortChartData(_this.selected, res, 'pids');
                    }
                    else {
                        _this.loading.graph = false;
                        _this.noGraphData = true;
                    }
                }, function (error) {
                    _this.loading.graph = false;
                    // this.sharedService.getErrorMsg(error);
                });
            }
            else {
                this.resetOBDPids();
                this.searchModel.value = '';
                this.loading.graph = false;
                document.getElementById('legend').innerHTML = '';
            }
        }
        else {
            var selectedPids_3 = [];
            if (this.activeGPS.length === 1) {
                selectedPids_3 = [this.tempGPSConstraints["PID_" + this.activeGPS[0]]];
                request = this.liveService.getOBD_GPS_Messages(this.selectedDevice.deviceId, this.tsTime, this.teTime, selectedPids_3[0].pid);
            }
            else if (this.activeGPS.length === 2) {
                selectedPids_3 = [this.tempGPSConstraints["PID_" + this.activeGPS[0]], this.tempGPSConstraints["PID_" + this.activeGPS[1]]];
                request = this.liveService.getOBD_GPS_Messages(this.selectedDevice.deviceId, this.tsTime, this.teTime, selectedPids_3[0].pid + ',' + selectedPids_3[1].pid);
            }
            if (request) {
                request.subscribe(function (res) {
                    if (res.length && _this.activeGPS.length) {
                        _this.op = 'gps';
                        _this.selectedPID = selectedPids_3;
                        _this.sortChartData(selectedPids_3, res, 'gps');
                    }
                    else if (!_this.activeGPS.length) {
                        _this.loading.graph = false;
                    }
                    else {
                        _this.loading.graph = false;
                        _this.noGraphData = true;
                    }
                    _this.updatePointerEvents.emit(false);
                }, function (error) {
                    _this.loading.graph = false;
                    _this.updatePointerEvents.emit(false);
                });
            }
            else {
                this.resetGPS();
                this.loading.graph = false;
                this.noGraphData = false;
                this.updatePointerEvents.emit(false);
                document.getElementById('legend').innerHTML = '';
            }
        }
        if (this.subscribedReq.length) {
            this.resetReq();
        }
        this.subscribedReq.push(request);
    };
    ObdpidComponent.prototype.sortChartData = function (selectedPids, res, op) {
        var pid1 = selectedPids[0], pid2 = null;
        // if (op === 'pids') { pid1 = selectedPids[0]; }
        this.durationOfTrip = '';
        if (this.lineChart) {
            this.lineChart.destroy();
        }
        this.pid1Data = [];
        this.pid2Data = [];
        this.pidLabel = [];
        var ts = this.tsTime;
        var te = this.teTime;
        // const [days, hours, minutes, seconds] = this.sharedService.getDurationBtwTwoEpoch(ts, te);
        // if (hours < 10) { this.durationOfTrip += '0' + (hours).toString() + ':'; } else { this.durationOfTrip += (hours).toString() + ':'; }
        // if (minutes < 10) { this.durationOfTrip += '0' + (minutes).toString() + ':'; } else { this.durationOfTrip += (minutes).toString() + ':'; }
        // if (seconds < 10) { this.durationOfTrip += '0' + (seconds).toString(); } else { this.durationOfTrip += (seconds).toString(); }
        if (selectedPids.length === 1) {
            var that_1 = this;
            var _loop_1 = function (i) {
                var pidData = res[i];
                if ((pidData.hasOwnProperty('eventTime') && pidData.hasOwnProperty('obds')) || (pidData.hasOwnProperty('eventTime') || pidData.hasOwnProperty('cog'))) {
                    pidData['eventTime'] = new Date(pidData['eventTime']);
                    that_1.pidLabel.push(pidData['eventTime']);
                    if (op === 'gps') {
                        that_1.pid1Data.push(parseInt(pidData[pid1.pid], 10));
                    }
                    else {
                        var pid1Found_1 = false;
                        (pidData['obds']).map(function (obd) {
                            if (obd.pid === pid1.pid) {
                                pid1Found_1 = true;
                                that_1.pid1Data.push(parseInt(obd.values[0], 10));
                            }
                        });
                        if (!pid1Found_1) {
                            this_1.pid1Data.push(0);
                        }
                    }
                }
            };
            var this_1 = this;
            for (var i = 0; i < res.length; i++) {
                _loop_1(i);
            }
        }
        else {
            pid2 = selectedPids[1];
            var that_2 = this;
            var _loop_2 = function (i) {
                var pidData = res[i];
                if ((pidData.hasOwnProperty('eventTime') && pidData.hasOwnProperty('obds')) || (pidData.hasOwnProperty('eventTime') || pidData.hasOwnProperty('cog'))) {
                    pidData['eventTime'] = new Date(pidData['eventTime']);
                    that_2.pidLabel.push(pidData['eventTime']);
                    if (op === 'gps') {
                        that_2.pid1Data.push(parseInt(pidData[pid1.pid], 10));
                        that_2.pid2Data.push(parseInt(pidData[pid2.pid], 10));
                    }
                    else {
                        var pid1Found_2 = false;
                        var pid2Found_1 = false;
                        (pidData['obds']).map(function (obd) {
                            if (obd.pid === pid1.pid) {
                                pid1Found_2 = true;
                                that_2.pid1Data.push(parseInt(obd.values[0], 10));
                            }
                            else if (obd.pid === pid2.pid) {
                                pid2Found_1 = true;
                                that_2.pid2Data.push(parseInt(obd.values[0], 10));
                            }
                        });
                        if (!pid1Found_2) {
                            this_2.pid1Data.push(0);
                        }
                        if (!pid2Found_1) {
                            this_2.pid2Data.push(0);
                        }
                    }
                }
            };
            var this_2 = this;
            for (var i = 0; i < res.length; i++) {
                _loop_2(i);
            }
        }
        this.drawChart(selectedPids);
    };
    ObdpidComponent.prototype.setHeight = function () {
        var leftColHeight = $('#leftCol').height();
        var navHeight = $('.nav.nav-tabs.detailed-tabs').height();
        var footer = $('footer').height();
        if (this.tabPos === 0) {
            var searchBarHeight = $('.input-group.input-group-lg.searchVeh.liveSearch').height();
            $('#pidTable > div > .datatable-body').css('height', leftColHeight - (navHeight + searchBarHeight + footer + 20));
        }
        else if (this.tabPos === 1) {
            $('#gps').css('height', leftColHeight - (navHeight));
        }
    };
    ObdpidComponent.prototype.drawChart = function (selectedPids) {
        var _this = this;
        var pid1 = selectedPids[0], pid2 = null;
        if (selectedPids.length === 2) {
            pid2 = selectedPids[1];
        }
        this.loading.graph = false;
        if (this.tabPos === 1) {
            this.activeGPS = selectedPids;
        }
        else if (this.tabPos === 0) {
            this.activePids = selectedPids;
        }
        var ctx = document.getElementById('lineChart');
        if (!ctx) {
            this.createCanvas();
            ctx = document.getElementById('lineChart');
        }
        var options = {
            type: 'line',
            responsive: true,
            data: {
                labels: this.pidLabel,
                datasets: [
                    {
                        label: pid1.unit ? pid1.description + "  (" + pid1.unit + ")" : pid1.description,
                        yAxisID: pid1.description,
                        data: this.pid1Data,
                        backgroundColor: 'rgb(54, 162, 235, 0.2)',
                        borderColor: 'rgba(54, 162, 235)'
                    }
                ]
            },
            options: {
                maintainAspectRatio: false,
                showScale: false,
                legend: {
                    position: 'left',
                    display: false,
                    textDirection: 'rtl',
                    labels: {
                        fontSize: 8,
                        boxWidth: 10
                    }
                },
                scales: {
                    xAxes: [{
                            type: 'time',
                            time: {
                                unit: 'minute'
                            },
                            distribution: 'series',
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 15
                            }
                        }],
                    yAxes: [
                        {
                            id: pid1.description,
                            type: 'linear',
                            position: 'left',
                            gridLines: {
                                display: false,
                            }
                        }
                    ]
                },
                elements: {
                    line: {
                        tension: 0.5 // disables bezier curves
                    }
                }
            }
        };
        if (selectedPids.length > 1) {
            options.options.scales.yAxes.push({
                id: pid2.description,
                type: 'linear',
                position: 'right',
                gridLines: {
                    display: false,
                }
            });
            options.data.datasets.push({
                label: pid2.unit ? pid2.description + " (" + pid2.unit + ")" : pid2.description,
                yAxisID: pid2.description,
                data: this.pid2Data,
                backgroundColor: 'rgb(255, 99, 132, 0.2)',
                borderColor: 'rgb(255, 99, 132)'
            });
        }
        this.lineChart = new Chart(ctx, options);
        // this.lineChart.aspectRatio = 0;
        document.getElementById("legend").innerHTML = this.lineChart.generateLegend();
        $("#legend > ul > li").on('click', function (e) {
            var indx = $(e.currentTarget).index();
            $(e.currentTarget).toggleClass('strike');
            var ci = _this.lineChart;
            var meta = ci.chart.getDatasetMeta(indx);
            meta.hidden = meta.hidden === null ? !ci.chart.data.datasets[indx].hidden : null;
            ci.chart.update();
        });
    };
    ObdpidComponent.prototype.createCanvas = function () {
        var canvas = document.createElement('canvas');
        var div = document.getElementById('chartcontainer');
        canvas.id = "lineChart";
        if (div)
            div.appendChild(canvas);
    };
    ObdpidComponent.prototype.resetSearch = function () {
        this.searchModel.value = '';
        this.filteredData = this.pidAllConstraints;
        this.onSearchNoDF = false;
    };
    return ObdpidComponent;
}());
export { ObdpidComponent };
