import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { SharedService } from '@myproject/shared/shared.service';
import { environment } from '@myenv/environment';
import * as FileSaver from 'file-saver';
import { GraphicReportsService } from '../../graphic-reports.service';
import { Title } from '@angular/platform-browser';
var TripSummaryReportComponent = /** @class */ (function () {
    function TripSummaryReportComponent(sharedService, title, reportsService) {
        this.sharedService = sharedService;
        this.title = title;
        this.reportsService = reportsService;
        this.distanceMetrics = environment.distanceMetrics;
        this.speedMetrics = environment.speedMetrics;
        this.dateFormat = environment.isoDateTimeFormat;
        this.permissionModules = [];
        this.pageLoading = true;
        this.vehicles = [];
        this.selectedVehicle = null;
        this.querySelectVehicle = null;
        this.isLoading = false;
        this.startTime = 0;
        this.endTime = 0;
        this.page = 0;
        this.trips = [];
        this.pageInfo = {
            offset: 0,
            count: 0,
            limit: 10,
            pageSize: 0,
            lastPage: 0,
        };
        this.prevResponse = 0;
        this.currentPageCount = {
            start: 0,
            end: 0
        };
        this.hideCalendar = false;
        this.isSideMenuClose = false;
    }
    TripSummaryReportComponent.prototype.ngOnInit = function () {
        this.title.setTitle('Summary' + environment.title_text);
        this.permissionModules = JSON.parse(localStorage.getItem('permissionModules'));
    };
    TripSummaryReportComponent.prototype.onTimeChange = function (e) {
        this.startTime = e.startTime;
        this.endTime = e.endTime;
        if (this.selectedVehicle) {
            this.page = 0;
            this.getTrips(this.page);
        }
    };
    TripSummaryReportComponent.prototype.changeVehicle = function (e) {
        // if (localStorage.getItem('reportsVehicle') && this.selectedVehicle == null) {
        //   this.setItem();
        // } else {
        //   localStorage.setItem('reportsVehicle', JSON.stringify(e));
        //   this.selectedVehicle = e ? e.deviceId : null;
        // }
        this.selectedVehicle = e ? e.deviceId : null;
        this.querySelectVehicle = e ? e : null;
        if (this.startTime && this.endTime) {
            this.page = 0;
            this.getTrips(this.page);
        }
    };
    TripSummaryReportComponent.prototype.onSideMenuToggle = function () {
        this.isSideMenuClose = !this.isSideMenuClose;
        var temp = JSON.parse(JSON.stringify(this.trips));
        this.trips.length = 0;
        this.trips = temp;
    };
    TripSummaryReportComponent.prototype.setItem = function () {
        var reportsItem = JSON.parse(localStorage.getItem('reportsVehicle'));
        this.querySelectVehicle = reportsItem;
        this.selectedVehicle = reportsItem.deviceId;
    };
    TripSummaryReportComponent.prototype.refresh = function () {
        this.page = 0;
        this.getTrips(this.page);
    };
    TripSummaryReportComponent.prototype.getTrips = function (page) {
        var _this = this;
        this.isLoading = true;
        this.page = page;
        if (this.selectedVehicle !== null) {
            this.reportsService.getTrips(this.selectedVehicle, this.startTime, this.endTime, page).subscribe({
                next: function (res) {
                    _this.prevResponse = res.length;
                    if (_this.page === 0) {
                        _this.pageInfo.offset = 0;
                        _this.trips = res;
                    }
                    else {
                        _this.trips = tslib_1.__spread(_this.trips, res);
                    }
                    _this.isLoading = false;
                    _this.pageLoading = false;
                },
                error: function (error) {
                    _this.isLoading = false;
                    _this.pageLoading = false;
                    _this.sharedService.getErrorMsg(error);
                }
            });
        }
        else {
            this.isLoading = false;
            this.pageLoading = false;
        }
    };
    TripSummaryReportComponent.prototype.downloadCsv = function () {
        var _this = this;
        this.reportsService.downloadTripSummary(this.selectedVehicle, this.startTime, this.endTime).subscribe({
            next: function (res) {
                var contentDisposition = res.headers.get('Content-Disposition');
                var filename;
                if (contentDisposition == null) {
                    var date = new Date();
                    filename = 'Trip_Summary_' + date + '.csv';
                }
                else {
                    filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
                }
                var arrbuf = new Uint8Array(res.body);
                //Converting the charcodes to string and replacing the string to Distance Travelled (KMS) 
                var text = (String.fromCharCode.apply(String, tslib_1.__spread(arrbuf))).replace("Distance Travelled,", "Distance Travelled (Kms),");
                // Converting the string to ArrayBuffer
                var uint8array = new TextEncoder().encode(text);
                var blob = new Blob([uint8array], {
                    type: 'text/csv;charset=utf-8'
                });
                // const blob = new Blob([res.body], {
                //   type: 'text/csv;charset=utf-8'
                // });
                FileSaver.saveAs(blob, filename);
            },
            error: function (error) {
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    // To get the vehicles for each page in the table
    TripSummaryReportComponent.prototype.pageCallback = function (value) {
        this.pageInfo.offset = value.offset;
        this.currentPageCount.start = (value.offset * value.pageSize) + 1;
        var lastPage = Math.floor(this.trips.length / 10);
        this.pageInfo.lastPage = lastPage;
        if (lastPage === value.offset) {
            this.currentPageCount.end = value.count;
        }
        else {
            this.currentPageCount.end = (value.offset * value.pageSize) + value.limit;
        }
        if (this.pageInfo.lastPage === (this.pageInfo.offset + 1)) {
            if (this.prevResponse) {
                this.page = ++this.page;
                this.getTrips(this.page);
            }
        }
    };
    return TripSummaryReportComponent;
}());
export { TripSummaryReportComponent };
