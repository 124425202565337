<ul class="nav nav-tabs detailed-tabs relative page-sub-heading" role="tablist">
    <li class="nav-item active" *ngIf="permissionModules.includes('REPORT')">
        <a class="nav-link text-center mt-1 active ml-4" role="tab" data-toggle="tab" href="#general">
            <i class="fa fa-bar-chart"></i> General
        </a>
    </li>
    <li class="nav-item" *ngIf="permissionModules.includes('DEEP-VIEW')">
        <a class="nav-link text-center mt-1 ml-4" href="#deepView" routerLink="/reports/deep-view">
            <i class="fa fa-th"></i> Deep View
        </a>
    </li>
    <li class="nav-item" *ngIf="permissionModules.includes('SCORE')">
        <a class="nav-link text-center mt-1" href="#score" routerLink="/score">
            <i class="fa fa-line-chart"></i> Scores
        </a>
    </li>
</ul>
