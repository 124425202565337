import { CalendarComponent } from '@shared/calendar/calendar.component';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SharedService } from '@shared/shared.service';
import * as FileSaver from 'file-saver';
import { Title } from '@angular/platform-browser';
import { environment } from '@myenv/environment';
import { configMessage } from '@shared/config-message';
import { GraphicReportsService } from '@components/graphic-reports/graphic-reports.service';

@Component({
  selector: 'app-custom-report',
  templateUrl: './custom-report.component.html',
  styleUrls: ['./custom-report.component.scss']
})
export class CustomReportComponent implements OnInit {

  public apiUrl = environment.apiUrl;
  public dateFormat = environment.largeDateFormat;
  settings: any;
  dateAttributes = configMessage.dateAttributes;
  maxDate: Date = new Date();
  data: any;
  groups: any;
  temp = [];
  reportsMenu: any = [];
  vehicles: any = [];
  currentReport: any = {};
  querySelectDevice = null;
  querySelectVehicle = null;
  page = 0;
  isLoading = false;
  noReports = false;
  public report: any = {};
  public columMenu: any = [];
  currentPageCount: any = {
    start: 0,
    end: 0
  };
  startTime: any;
  endTime: any;
  searchModel: any = {
    value: null,
    vehicle: null,
    device: null
  };
  public reportId: string = null;
  pageInfo: any = {
    offset: 0,
    count: 0,
    limit: 10,
    pageSize: 0,
    lastPage: 0,
  };
  public reports: any = [];
  public total: number;
  public itemsPerPage: any = 10; // we are showing 10 items per page
  public lastPage = 0;
  disable = false;
  nextPage: any;
  selectItemError = false;
  value: string;
  displayName: string;
  permissionModules = [];
  modules = [];
  permissionAuthorities: any = {};
  moduleName = 'REPORT';
  totalMessageCount: any = {};
  selectedItem: any;
  hideCalendar = false;
  isSideMenuClose = false;

  constructor(
    // private reportsService: ReportsService,
    private reportsService: GraphicReportsService,
    private sharedService: SharedService,
    private title: Title
  ) {
  }

  ngOnInit() {
    this.modules = JSON.parse(localStorage.getItem('accessRoleModules'));
    this.permissionAuthorities = this.modules.find(module => module.name === this.moduleName);
    this.permissionModules = JSON.parse(localStorage.getItem('permissionModules'));
    this.title.setTitle('Reports' + environment.title_text);
    this.setReport();
  }

  getReport(report) {
    this.currentReport = report;
    this.setReport();
  }

  onTimeChange(e) {
    this.startTime = e.startTime;
    this.endTime = e.endTime;
    this.page = 0;
  }

  changeVehicle(e) {
    if (localStorage.getItem('reportsVehicle') && this.querySelectVehicle == null) {
      this.setItem();
    } else {
      localStorage.setItem('reportsVehicle', JSON.stringify(e));
      this.searchModel.value = e ? e.deviceId : null;
      this.searchModel.vehicle = e ? e.deviceId : null;
      this.selectedItem = e;
      this.querySelectVehicle = e;
    }
  }

  changeDevice(e) {
    if (localStorage.getItem('reportsDevice') && this.querySelectDevice == null) {
      this.setItem();
    } else {
      localStorage.setItem('reportsDevice', JSON.stringify(e));
      this.selectedItem = e;
      this.searchModel.value = e ? e.serialNumber : null;
      this.searchModel.device = e ? e.serialNumber : null;
      this.querySelectDevice = e;
    }
  }

  onSideMenuToggle() {
    this.isSideMenuClose = !this.isSideMenuClose;
  }

  refresh() {
    this.page = 0;
    this.setReport();
  }

  setReport() {
    if (this.currentReport && this.currentReport.reportId) {
      localStorage.setItem('currentReport', JSON.stringify(this.currentReport));
      this.report.reportId = this.currentReport.reportId;
    } else {
      if (JSON.parse(localStorage.getItem('currentReport'))) {
        const localReport = JSON.parse(localStorage.getItem('currentReport'));
        if (localReport.downloadable) {
          this.currentReport = localReport;
          this.report.reportId = localReport.reportId;
        } else {
          const reports = JSON.parse(localStorage.getItem('reports'));
          const global = this;
          reports.forEach(item => {
            if (item.downloadable && !global.currentReport.reportId) {
              global.currentReport = item;
            }
          });
          localStorage.setItem('currentReport', JSON.stringify(this.currentReport));
        }
      }
    }
    this.columMenu = this.currentReport.columns;
    this.title.setTitle(this.currentReport.name + environment.title_text);
  }

  setItem() {
    if (this.currentReport.type != 'VIN_CHANGE_2G2K' && localStorage.getItem('reportsVehicle')) {
      let reportsItem = JSON.parse(localStorage.getItem('reportsVehicle'));
      this.querySelectVehicle = reportsItem;
      this.searchModel.value = reportsItem.deviceId;
      this.searchModel.vehicle = reportsItem.deviceId;
      this.selectedItem = reportsItem;
    } else {
      let reportsItem = JSON.parse(localStorage.getItem('reportsDevice'));
      this.querySelectDevice = reportsItem;
      this.searchModel.value = reportsItem.deviceId;
      this.searchModel.device = reportsItem.serialNumber;
      this.selectedItem = reportsItem;
    }
  }

  // to handle disabled buttons
  disableBtn() {
    if (
      !this.searchModel.value ||
      !this.startTime || !this.endTime
    ) {
      this.disable = true;
    } else {
      this.disable = false;
    }
  }

  // To download report as a CSV File
  downloadReportCSV() {
    this.disable = true;
    if (this.currentReport.type === 'VPACKET_SYSTEM_REPORT') {
      this.reportsService.downloadVPacketReportCSV(this.currentReport.reportId).subscribe(res => {
        var win = window.open(this.apiUrl + 'reports/vpacket/download?access_key=' + res.token, '_blank');
        win.focus();
        this.disable = false;
      }, error => {
        this.sharedService.getErrorMsg(error);
        this.disable = false;
      });
    } else {
      this.reportsService.downloadReportCSV(
        this.currentReport.reportId, this.searchModel.value, this.startTime, this.endTime
      ).subscribe(res => {
        var win = window.open(this.apiUrl + 'reports/download?access_key=' + res.token, '_blank');
        win.focus();
        this.disable = false;
      }, error => {
        this.sharedService.getErrorMsg(error);
        this.disable = false;
      });
    }
  }

  // To download report as a CSV File
  downloadHeatMapCSV() {
    this.disable = true;
    const startTime = new Date(this.startTime);
    const endTime = new Date(this.endTime);
    const start = `${startTime.getFullYear()}-${startTime.getMonth() + 1}-${startTime.getDate()}`;
    const end = `${endTime.getFullYear()}-${endTime.getMonth() + 1}-${endTime.getDate()}`;
    this.reportsService.downloadHeatMapCSV(start, end).subscribe(res => {
      let contentDisposition = res.headers.get('Content-Disposition');
      let filename;
      if (contentDisposition == null) {
        const date = new Date();
        filename = 'Report_' + date + '.csv';
      } else {
        filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
      }
      const blob = new Blob([res.body], {
        type: 'text/csv;charset=utf-8'
      });
      FileSaver.saveAs(blob, filename);
      this.disable = false;
    }, error => {
      this.sharedService.getErrorMsg(error);
      this.disable = false;
    });
  }

  downloadDeviceReportCSV() {
    this.disable = true;
    this.reportsService.downloadDeviceReportCSV(
      this.currentReport.reportId, this.startTime, this.endTime, this.searchModel.value
    ).subscribe(res => {
      var win = window.open(this.apiUrl + 'reports/vin-change/download?access_key=' + res.token + '&compress=true', '_blank');
      win.focus();
      this.disable = false;
    }, error => {
      this.sharedService.getErrorMsg(error);
      this.disable = false;
    });
  }

}
