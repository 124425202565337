
import {merge as observableMerge, Observable, of, fromEvent} from 'rxjs';
// import {Observable} from 'rxjs/Rx';
import {map} from 'rxjs/operators';
import {Component, OnInit, Inject, NgZone} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '@myenv/environment';
import {DOCUMENT} from '@angular/common';
import { SharedService } from '@shared/shared.service';
import { enMessage } from '@shared/en-us-message';
import {AuthenticationService} from "@layouts/login/authentication.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  public cmImageUrl = environment.cmImageUrl;
  title = 'BitBrew';
  currentUser: any = {};
  returnUrl: string;
  public originalUrl: string;
  public imgSrc: string;
  today: number;
  pi: number;
  value: number;
  responseMessage = {};
//   public onlineFlag =navigator.onLine;
  online$: Observable<boolean>;
  isConnected: Observable<boolean>;

  // tslint:disable-next-line:max-line-length
  constructor(@Inject(DOCUMENT) private _document: HTMLDocument,
    private sharedService: SharedService,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
              private authenticationService: AuthenticationService,
              private ngzone: NgZone) {
    this.imgSrc = this.cmImageUrl + 'assets/img/load.svg';
    this._document.getElementById('appFavicon').setAttribute('href', this.cmImageUrl + 'assets/img/load.svg');
    translate.addLangs(['en', 'fr', 'it', 'zh-hans']);
    translate.setDefaultLang('en');
    const language = navigator.language || (<any>navigator).userLanguage || window.navigator.language;
    translate.use(language ? language : 'en');
    this.isConnected = observableMerge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(map(() => true)),
      fromEvent(window, 'offline').pipe(map(() => false)));
      this.isConnected.subscribe(conn => {
      if (conn === false) {
        const errorCode = 'noInternet';
        const errorMessage = enMessage[errorCode];
        this.responseMessage = {success : 'noInternet', message : errorMessage};
        this.sharedService.setResponseMessage(this.responseMessage);
      }
      if (conn === true) {
        const errorCode = 'internetIsBack';
        const errorMessage = enMessage[errorCode];
        this.responseMessage = {success : 'internetIsBack', message : errorMessage};
        this.sharedService.setResponseMessage(this.responseMessage);
      }
    });
  }

  ngOnInit() {
    this.loginComponent();
    this.adminComponent();
    this.ordersComponent();
    this.reportsComponent();
    this.inactivityTime();
  }

  change(): void {
    this.value = Math.round(Math.random() * 1000000) / 100;
  }

  loginComponent(){
    if (window.location.pathname == '/login') {
      const defaultUrl = localStorage.getItem('defaultUrl');
      if (localStorage.getItem('token')) {
        this.router.navigate([defaultUrl]);
      } else {
        this.router.navigate(['login']);
      }
    }
  }

  adminComponent() {
    if (window.location.pathname == '/admin' || window.location.pathname == '/admin/') {
      const defaultAdminUrl = localStorage.getItem('defaultAdminUrl');
      if (localStorage.getItem('token')) {
        this.router.navigate([defaultAdminUrl]);
      } else {
        this.router.navigate(['login']);
      }
    }
  }

  reportsComponent() {
    if (window.location.pathname == '/reports' || window.location.pathname == '/reports/') {
      if (localStorage.getItem('token')) {
        this.router.navigate(['/reports/system/live-status']);
      } else {
        this.router.navigate(['login']);
      }
    }
  }

  ordersComponent() {
    if (window.location.pathname == '/orders' || window.location.pathname == '/orders/') {
      const defaultOrdersUrl = localStorage.getItem('defaultOrdersUrl');
      if (localStorage.getItem('token')) {
        this.router.navigate([defaultOrdersUrl]);
      } else {
        this.router.navigate(['login']);
      }
    }
  }

  inactivityTime() {
    var time;
    window.onload = resetTimer;
    // DOM Events
    window.onkeypress = resetTimer;
    window.onload = resetTimer;
    window.onmousemove = resetTimer;
    window.onmousedown = resetTimer; // touchscreen presses
    window.ontouchstart = resetTimer;
    window.onclick = resetTimer;     // touchpad clicks
    window.onkeypress = resetTimer;
    window.addEventListener('scroll', resetTimer, true);
    let global = this;
    function logout() {
      let token = localStorage.getItem('token');
      localStorage.clear();
      sessionStorage.clear();
      if(token) {
        global.sharedService.bootDownInterCom();
        global.authenticationService.logout(token).subscribe((res) => {
        });
      }
      global.ngzone.run(() => {
        global.sharedService.bootDownInterCom();
        global.router.navigate(['/login']);
      });
    }

    function resetTimer() {
      clearTimeout(time);
      time = setTimeout(logout, 60 * 60 * 8 * 1000);
    }
  };

}
