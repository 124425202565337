import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { SharedService } from '@shared/shared.service';
import { ActivatedRoute } from '@angular/router';
import { configMessage } from '@shared/config-message';
import { environment } from '@myenv/environment';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { GraphicReportsService } from "@components/graphic-reports/graphic-reports.service";
var MessageReportComponent = /** @class */ (function () {
    function MessageReportComponent(route, sharedService, reportsService, title, router) {
        this.route = route;
        this.sharedService = sharedService;
        this.reportsService = reportsService;
        this.title = title;
        this.router = router;
        this.apiUrl = environment.apiUrl;
        this.dateFormat = environment.largeDateFormat;
        this.dateAttributes = configMessage.dateAttributes;
        this.maxDate = new Date();
        this.data = [];
        this.temp = [];
        this.reportsMenu = [];
        this.vehicles = [];
        this.devices = [];
        this.currentReport = {};
        this.querySelectDevice = null;
        this.querySelectVehicle = null;
        this.page = 0;
        this.isLoading = false;
        this.noReports = false;
        this.report = {};
        this.columMenu = [];
        this.displayColumns = [];
        this.currentPageCount = {
            start: 0,
            end: 0
        };
        this.searchModel = {
            value: null,
            vehicle: null,
            device: null
        };
        this.reportId = null;
        this.pageInfo = {
            offset: 0,
            count: 0,
            limit: Number(localStorage.getItem('messagesReportRows')) || 10,
            pageSize: Number(localStorage.getItem('messagesReportRows')) || 10,
            lastPage: 0,
        };
        this.prevResponse = 0;
        this.newPage = true;
        this.reports = [];
        this.itemsPerPage = 10; // we are showing 10 items per page
        this.lastPage = 0;
        this.disable = false;
        this.selectItemError = false;
        this.permissionModules = [];
        this.permissionAuthorities = {};
        this.moduleName = 'REPORT';
        this.modules = [];
        this.hideCalendar = false;
        this.columnPopup = false;
        this.columnCounts = {
            selectCount: 0,
            selectedKeys: []
        };
        this.isSideMenuClose = false;
    }
    MessageReportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.title.setTitle('Reports' + environment.title_text);
        this.permissionModules = JSON.parse(localStorage.getItem('permissionModules'));
        this.modules = JSON.parse(localStorage.getItem('accessRoleModules'));
        this.permissionAuthorities = this.modules.find(function (module) { return module.name == _this.moduleName; });
        this.setReport();
    };
    MessageReportComponent.prototype.onTimeChange = function (e) {
        this.startTime = e.startTime;
        this.endTime = e.endTime;
        this.page = 0;
        if (this.selectedItem) {
            this.getViewReports('0');
        }
    };
    MessageReportComponent.prototype.changeVehicle = function (e) {
        if (localStorage.getItem('reportsVehicle') && this.querySelectVehicle == null) {
            this.setItem();
        }
        else {
            localStorage.setItem('reportsVehicle', JSON.stringify(e));
            this.searchModel.value = e ? e.deviceId : null;
            this.searchModel.vehicle = e ? e.deviceId : null;
            this.selectedItem = e;
            this.querySelectVehicle = e;
        }
        if (this.startTime && this.endTime) {
            this.getViewReports('0');
        }
    };
    MessageReportComponent.prototype.changeDevice = function (e) {
        if (localStorage.getItem('reportsDevice') && this.querySelectDevice == null) {
            this.setItem();
        }
        else {
            this.selectedItem = e;
            localStorage.setItem('reportsDevice', JSON.stringify(e));
            this.searchModel.value = e ? e.deviceId : null;
            this.searchModel.device = e ? e.serialNumber : null;
            this.querySelectDevice = e;
        }
        if (this.startTime && this.endTime) {
            this.getViewReports('0');
        }
    };
    MessageReportComponent.prototype.onSideMenuToggle = function () {
        this.isSideMenuClose = !this.isSideMenuClose;
        var temp = JSON.parse(JSON.stringify(this.reports));
        this.reports.length = 0;
        this.reports = temp;
    };
    MessageReportComponent.prototype.getReport = function (report) {
        this.currentReport = report;
        this.setReport();
        if (this.selectedItem && this.startTime && this.endTime) {
            this.getViewReports('0');
        }
        this.pageInfo = {
            offset: 0,
            count: 0,
            limit: Number(localStorage.getItem('messagesReportRows')) || 10,
            pageSize: Number(localStorage.getItem('messagesReportRows')) || 10,
            lastPage: 0,
        };
        this.page = 0;
    };
    MessageReportComponent.prototype.refresh = function () {
        this.getViewReports('0');
        this.page = 0;
        this.pageInfo = {
            offset: 0,
            count: 0,
            limit: Number(localStorage.getItem('messagesReportRows')) || 10,
            pageSize: Number(localStorage.getItem('messagesReportRows')) || 10,
            lastPage: 0,
        };
    };
    MessageReportComponent.prototype.setReport = function () {
        if (this.currentReport && this.currentReport.reportId) {
            localStorage.setItem('currentReport', JSON.stringify(this.currentReport));
            this.report.reportId = this.currentReport.reportId;
        }
        else {
            if (JSON.parse(localStorage.getItem('currentReport'))) {
                var localReport = JSON.parse(localStorage.getItem('currentReport'));
                if (localReport.type != 'VISUALIZATION' && !localReport.downloadable) {
                    this.currentReport = localReport;
                    this.report.reportId = localReport.reportId;
                }
                else {
                    var reports = JSON.parse(localStorage.getItem('reports'));
                    var global_1 = this;
                    reports.forEach(function (item) {
                        if (item.type != 'VISUALIZATION' && !item.downloadable && !global_1.currentReport.reportId) {
                            global_1.currentReport = item;
                        }
                    });
                    localStorage.setItem('currentReport', JSON.stringify(this.currentReport));
                }
            }
            else {
                var reports = JSON.parse(localStorage.getItem('reports'));
                var global_2 = this;
                reports.forEach(function (item) {
                    if (item.type != 'VISUALIZATION' && !item.downloadable && !global_2.currentReport.reportId) {
                        global_2.currentReport = item;
                    }
                });
                localStorage.setItem('currentReport', JSON.stringify(this.currentReport));
            }
        }
        this.columMenu = this.currentReport.columns;
        this.setAllColumns(this.columMenu);
        this.title.setTitle(this.currentReport.name + environment.title_text);
        // this.getSelectionType();
    };
    MessageReportComponent.prototype.setItem = function () {
        if (this.currentReport.type != 'VIN_CHANGE_2G2K' && localStorage.getItem('reportsVehicle')) {
            var reportsItem = JSON.parse(localStorage.getItem('reportsVehicle'));
            this.querySelectVehicle = reportsItem;
            this.searchModel.value = reportsItem.deviceId;
            this.searchModel.vehicle = reportsItem.deviceId;
            this.selectedItem = reportsItem;
        }
        else {
            var reportsItem = JSON.parse(localStorage.getItem('reportsDevice'));
            this.querySelectDevice = reportsItem;
            this.searchModel.value = reportsItem.deviceId;
            this.searchModel.device = reportsItem.serialNumber;
            this.selectedItem = reportsItem;
        }
    };
    // to handle disabled buttons
    MessageReportComponent.prototype.disableBtn = function () {
        if (!this.searchModel.value ||
            !this.startTime || !this.endTime) {
            this.disable = true;
        }
        else {
            this.disable = false;
        }
    };
    MessageReportComponent.prototype.getViewReports = function (page) {
        this.getMessageCount();
        if (this.currentReport.type == 'VIN_CHANGE_2G2K') {
            this.getDeviceView(true, 0);
        }
        else {
            this.getNewReports(true, 0);
        }
    };
    // To get a report
    MessageReportComponent.prototype.getNewReports = function (isValid, page) {
        var _this = this;
        if (this.router.url.includes('/reports/device/messages')) {
            if (this.pageInfo.pageSize === 0) {
                this.pageInfo.pageSize = 10;
            }
            if (isValid) {
                if (this.currentReport.multiVehicle) {
                    this.report.deviceId = '';
                    this.disable = false;
                }
                this.newPage = false;
                this.reportsService.getSingleReportByReportId(this.currentReport.reportId, this.searchModel.value, this.startTime, this.endTime, page).subscribe(function (res) {
                    if (res == null) {
                        _this.reports = [];
                        _this.disable = false;
                    }
                    else {
                        _this.prevResponse = res.length;
                        if (page == 0) {
                            _this.reports = res;
                            _this.pageInfo = {
                                offset: 0,
                                count: 0,
                                limit: Number(localStorage.getItem('messagesReportRows')) || 10,
                                pageSize: Number(localStorage.getItem('messagesReportRows')) || 10,
                                lastPage: 0,
                            };
                        }
                        else {
                            _this.reports = tslib_1.__spread(_this.reports, res);
                        }
                        _this.pageInfo.count = _this.reports.length;
                        var pagecount = _this.pageInfo.count / _this.pageInfo.limit;
                        _this.pageInfo.lastPage = Math.floor(pagecount);
                        var decimal = pagecount - _this.pageInfo.lastPage;
                        _this.disable = false;
                        if (!Number.isInteger(decimal)) {
                            _this.pageInfo.lastPage = _this.pageInfo.lastPage + 1;
                        }
                        if (page == 0) {
                            if (res.length) {
                                _this.currentPageCount.start = (_this.pageInfo.offset * _this.pageInfo.pageSize) + 1;
                                if (_this.reports.length <= 10) {
                                    _this.currentPageCount.end = _this.pageInfo.count;
                                }
                                else {
                                    _this.currentPageCount.end = (_this.pageInfo.offset * _this.pageInfo.pageSize) + _this.pageInfo.limit;
                                }
                            }
                        }
                        _this.disable = false;
                    }
                }, function (error) {
                    _this.sharedService.getErrorMsg(error);
                    _this.disable = false;
                });
            }
        }
    };
    // To get a report
    MessageReportComponent.prototype.getDeviceView = function (isValid, page) {
        var _this = this;
        if (this.router.url.includes('/reports/device/messages')) {
            if (this.pageInfo.pageSize === 0) {
                this.pageInfo.pageSize = 10;
            }
            if (isValid) {
                this.newPage = false;
                if (this.report.startDate == null || this.report.endDate == null)
                    return;
                this.reportsService.getDeviceView(this.currentReport.reportId, this.startTime, this.endTime, page, this.searchModel.value)
                    .subscribe(function (res) {
                    if (res == null) {
                        _this.reports = [];
                        _this.disable = false;
                    }
                    else {
                        _this.prevResponse = res.length;
                        if (page == 0) {
                            _this.reports = res;
                            _this.pageInfo = {
                                offset: 0,
                                count: 0,
                                limit: Number(localStorage.getItem('messagesReportRows')) || 10,
                                pageSize: Number(localStorage.getItem('messagesReportRows')) || 10,
                                lastPage: 0,
                            };
                        }
                        else {
                            _this.reports = tslib_1.__spread(_this.reports, res);
                        }
                        _this.pageInfo.count = _this.reports.length;
                        var pagecount = _this.pageInfo.count / _this.pageInfo.limit;
                        _this.pageInfo.lastPage = Math.floor(pagecount);
                        var decimal = pagecount - _this.pageInfo.lastPage;
                        _this.disable = false;
                        if (!Number.isInteger(decimal)) {
                            _this.pageInfo.lastPage = _this.pageInfo.lastPage + 1;
                        }
                        if (page == 0) {
                            if (res.length) {
                                _this.currentPageCount.start = (_this.pageInfo.offset * _this.pageInfo.pageSize) + 1;
                                if (_this.reports.length <= 10) {
                                    _this.currentPageCount.end = _this.pageInfo.count;
                                }
                                else {
                                    _this.currentPageCount.end = (_this.pageInfo.offset * _this.pageInfo.pageSize) + _this.pageInfo.limit;
                                }
                            }
                        }
                        _this.disable = false;
                    }
                }, function (error) {
                    _this.sharedService.getErrorMsg(error);
                    _this.disable = false;
                });
            }
        }
    };
    // To get total reports count
    MessageReportComponent.prototype.getMessageCount = function () {
        var _this = this;
        if (this.router.url.includes('/reports/device/messages')) {
            if (this.currentReport.type == 'VIN_CHANGE_2G2K') {
                this.reportsService.getDeviceMeessageCount(this.currentReport.reportId, this.startTime, this.endTime, this.searchModel.value).subscribe(function (res) {
                    _this.totalMessageCount = res;
                }, function (error) {
                    _this.sharedService.getErrorMsg(error);
                });
            }
            else {
                this.reportsService.getMessageCount(this.currentReport.reportId, this.searchModel.value, this.startTime, this.endTime).subscribe(function (res) {
                    _this.totalMessageCount = res;
                }, function (error) {
                    _this.sharedService.getErrorMsg(error);
                });
            }
        }
    };
    MessageReportComponent.prototype.pageCallback = function (value) {
        this.pageInfo.offset = value.offset;
        this.currentPageCount.start = (value.offset * value.pageSize) + 1;
        var lastPage = Math.floor(this.reports.length / this.pageInfo.limit);
        this.pageInfo.lastPage = lastPage;
        if (lastPage == value.offset) {
            this.currentPageCount.end = value.count;
        }
        else {
            this.currentPageCount.end = (value.offset * value.pageSize) + value.limit;
        }
        if (this.pageInfo.count % 100 === 0) {
            if (this.pageInfo.lastPage === (this.pageInfo.offset + 1)) {
                if (this.prevResponse) {
                    this.page = ++this.page;
                    if (this.currentReport.type == 'VIN_CHANGE_2G2K') {
                        this.getDeviceView(true, this.page);
                    }
                    else {
                        this.getNewReports(true, this.page);
                    }
                }
            }
        }
    };
    MessageReportComponent.prototype.changeRowCount = function (rows) {
        this.pageInfo.limit = rows;
        this.pageInfo.pageSize = rows;
        if (rows !== 100) {
            localStorage.setItem('messagesReportRows', rows);
        }
        this.pageCallback(this.pageInfo);
    };
    // To download report as a CSV File
    MessageReportComponent.prototype.downloadReportCSV = function () {
        var _this = this;
        this.reportsService.downloadReportCSV(this.currentReport.reportId, this.searchModel.value, this.startTime, this.endTime).subscribe(function (res) {
            var win = window.open(_this.apiUrl + 'reports/download?access_key=' + res.token, '_blank');
            win.focus();
            _this.disable = false;
        }, function (error) {
            _this.sharedService.getErrorMsg(error);
            _this.disable = false;
        });
    };
    MessageReportComponent.prototype.downloadDeviceReportCSV = function () {
        var _this = this;
        this.reportsService.downloadDeviceReportCSV(this.currentReport.reportId, this.startTime, this.endTime, this.searchModel.value).subscribe(function (res) {
            var win = window.open(_this.apiUrl + 'reports/vin-change/download?access_key=' + res.token + '&compress=true', '_blank');
            win.focus();
            _this.disable = false;
        }, function (error) {
            _this.sharedService.getErrorMsg(error);
            _this.disable = false;
        });
    };
    MessageReportComponent.prototype.togglePopup = function () {
        this.columnPopup = !this.columnPopup;
    };
    MessageReportComponent.prototype.setAllColumns = function (columns) {
        var _this = this;
        this.displayColumns = [];
        this.columnCounts.selectedKeys = [];
        this.columnCounts.selectCount = 0;
        columns.map(function (item) {
            item.checked = true;
            _this.columnCounts.selectCount++;
            _this.displayColumns.push(item);
            _this.columnCounts.selectedKeys.push(item.key);
        });
    };
    MessageReportComponent.prototype.selectColumns = function (e, column, i) {
        if (e.target.checked) {
            this.columnCounts.selectCount++;
        }
        else {
            this.columnCounts.selectCount--;
        }
        this.displayColumns.map(function (item) {
            if (column.key == item.key) {
                item.checked = e.target.checked;
            }
        });
    };
    MessageReportComponent.prototype.selectDeSelectAllColumns = function (e) {
        if (e.target.checked) {
            this.columnCounts.selectCount = this.displayColumns.length;
        }
        else {
            this.columnCounts.selectCount = 0;
        }
        this.displayColumns.map(function (item) {
            item.checked = e.target.checked;
        });
    };
    MessageReportComponent.prototype.updateColumns = function () {
        var _this = this;
        var temp = [];
        this.columnCounts.selectedKeys = [];
        this.searchModel.columnVal = '';
        this.displayColumns = this.currentReport.columns;
        this.displayColumns.map(function (item) {
            if (item.checked) {
                temp.push(item);
                _this.columnCounts.selectedKeys.push(item.key);
            }
        });
        this.columMenu = temp;
        this.columnPopup = false;
    };
    MessageReportComponent.prototype.cancelSelection = function () {
        var _this = this;
        this.columnPopup = false;
        this.columnCounts.selectCount = 0;
        this.searchModel.columnVal = '';
        this.displayColumns = this.currentReport.columns;
        this.displayColumns.map(function (item) {
            if (_this.columnCounts.selectedKeys.includes(item.key)) {
                _this.columnCounts.selectCount++;
                item.checked = true;
            }
            else {
                item.checked = false;
            }
        });
    };
    MessageReportComponent.prototype.onColumnSearch = function () {
        var inputVal = this.searchModel.columnVal.trim();
        var val = inputVal.toLowerCase();
        if (val === '') {
            this.displayColumns = this.columMenu;
            return;
        }
        else {
            if (this.columMenu.length) {
                this.displayColumns = this.currentReport.columns.filter(function (item) {
                    return item.displayName.toLowerCase().includes(val);
                });
            }
        }
    };
    return MessageReportComponent;
}());
export { MessageReportComponent };
