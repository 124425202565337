import { throwError as observableThrowError, BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import 'rxjs/Observable';
import { environment } from '@myenv/environment';
var PlaceService = /** @class */ (function () {
    function PlaceService(http) {
        this.http = http;
        this.buttonClick = new BehaviorSubject(false);
        this.apiUrl = environment.apiUrl;
    }
    // To get all Place
    PlaceService.prototype.getAllPlace = function (page) {
        return this.http.get(this.apiUrl + 'places?page=' + page).pipe(catchError(this.handleError));
    };
    //To create a Place
    PlaceService.prototype.createPlace = function (data) {
        return this.http.post(this.apiUrl + 'places', data).pipe(catchError(this.handleError));
    };
    // to get places count
    PlaceService.prototype.getPlacesCount = function () {
        return this.http.get(this.apiUrl + "places/count").pipe(catchError(this.handleError));
    };
    //To get all Place vehicles
    PlaceService.prototype.getPlaceVehicles = function (placeId) {
        return this.http.get(this.apiUrl + 'places/' + placeId + "/devices").pipe(catchError(this.handleError));
    };
    //To update a Place
    PlaceService.prototype.updatePlace = function (place) {
        return this.http.put(this.apiUrl + "places/" + place.placeId, place).pipe(catchError(this.handleError));
    };
    //To update status
    PlaceService.prototype.updateStatus = function (geoplaceId, active) {
        return this.http.patch(this.apiUrl + "places/" + geoplaceId + "/active/" + active, {}).pipe(catchError(this.handleError));
    };
    //To delete a Place
    PlaceService.prototype.deletePlace = function (placeId) {
        return this.http.delete(this.apiUrl + "places/" + placeId).pipe(catchError(this.handleError));
    };
    PlaceService.prototype.onSearchPlace = function (value) {
        return this.http.get(this.apiUrl + "places/search?name=" + value).pipe(catchError(this.handleError));
    };
    //To handle errors
    PlaceService.prototype.handleError = function (error) {
        return observableThrowError(error.error || error.json() || error || 'Server error');
    };
    return PlaceService;
}());
export { PlaceService };
