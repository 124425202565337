import * as tslib_1 from "tslib";
import { enMessage } from '@shared/en-us-message';
import { OnInit, ElementRef } from '@angular/core';
import { SharedService } from '@myproject/shared/shared.service';
import { ToolsService } from '../tools.service';
import { environment } from '@myenv/environment';
import { Title } from '@angular/platform-browser';
var MoveComponent = /** @class */ (function () {
    function MoveComponent(sharedService, toolsService, title) {
        this.sharedService = sharedService;
        this.toolsService = toolsService;
        this.title = title;
        this.dateFormat = environment.isoDateTimeFormat;
        this.pageLoading = true;
        this.largeDateFormat = environment.isoDateTimeFormat;
        this.responseMessage = {};
        this.isLoading = false;
        this.moduleName = 'DEVICE-SHIFT';
        this.permissionAuthorities = {};
        this.modules = [];
        this.searchModel = {};
        this.page = 0;
        this.loadingIndicator = true;
        this.currentPageCount = {
            start: 0,
            end: 0
        };
        this.pageInfo = {
            offset: 0,
            count: 0,
            limit: 10,
            pageSize: 0,
            lastPage: 0,
        };
        this.prevResponse = 0;
        this.devices = [];
        this.customers = [];
        this.typeValue = '';
        this.selected = [];
        this.notSelected = false;
        this.sameCustId = false;
        this.moving = false;
    }
    MoveComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.modules = JSON.parse(localStorage.getItem('accessRoleModules'));
        this.permissionAuthorities = this.modules.find(function (module) { return module.name === _this.moduleName; });
        this.toolsService.currentMessage.subscribe(function (message) { return _this.message = message; });
        this.title.setTitle('Move Devices' + environment.title_text);
        // this.getAllDevices(this.page);
        this.getAllCustomerIds();
    };
    MoveComponent.prototype.getAllCustomerIds = function () {
        var _this = this;
        this.sharedService.getAllCustomer().subscribe(function (res) {
            _this.customers = res;
            _this.searchModel.sourceCustomerId = res[0].customerId;
            _this.selectCustomer();
        }, function (error) {
            _this.pageLoading = false;
            _this.sharedService.getErrorMsg(error);
        });
    };
    MoveComponent.prototype.selectCustomer = function () {
        this.devices = [];
        this.loadingIndicator = true;
        if (this.searchModel.sourceCustomerId !== null) {
            this.getAllDevices(this.page, this.searchModel.sourceCustomerId);
        }
    };
    MoveComponent.prototype.getAllDevices = function (page, customerId) {
        var _this = this;
        this.toolsService.getCustomerDevices(page, customerId, this.typeValue).subscribe(function (res) {
            if (page === 0) {
                _this.devices = [];
            }
            _this.devices = tslib_1.__spread(_this.devices, res);
            _this.loadingIndicator = false;
            _this.prevResponse = res.length;
            _this.pageInfo.count = _this.devices.length;
            var pagecount = _this.pageInfo.count / _this.pageInfo.limit;
            _this.pageInfo.lastPage = Math.floor(pagecount);
            var decimal = pagecount - _this.pageInfo.lastPage;
            if (!Number.isInteger(decimal)) {
                _this.pageInfo.lastPage = _this.pageInfo.lastPage + 1;
            }
            if (page == 0) {
                if (res.length) {
                    _this.currentPageCount.start = (_this.pageInfo.offset * _this.pageInfo.pageSize) + 1;
                    if (_this.devices.length <= 10) {
                        _this.currentPageCount.end = _this.pageInfo.count;
                    }
                    else {
                        _this.currentPageCount.end = (_this.pageInfo.offset * _this.pageInfo.pageSize) + _this.pageInfo.limit;
                    }
                }
            }
            _this.pageLoading = false;
        }, function (error) {
            _this.pageLoading = false;
            _this.sharedService.getErrorMsg(error);
        });
    };
    // To get the devices for each page in the table
    MoveComponent.prototype.pageCallback = function (value) {
        this.pageInfo.offset = value.offset;
        this.currentPageCount.start = (value.offset * value.pageSize) + 1;
        var lastPage = Math.floor(this.devices.length / 10);
        if (lastPage == value.offset) {
            this.currentPageCount.end = value.count;
        }
        else {
            this.currentPageCount.end = (value.offset * value.pageSize) + value.limit;
        }
        if (this.pageInfo.lastPage === (this.pageInfo.offset + 1)) {
            // tslint:disable-next-line:no-unused-expression
            if (this.prevResponse) {
                this.page = ++this.page;
                this.getAllDevices(this.page, this.searchModel.sourceCustomerId);
            }
        }
    };
    MoveComponent.prototype.onSearch = function (isValid) {
        if (isValid && this.searchModel.sourceCustomerId !== null) {
            // const inputVal = this.inputOne.nativeElement.value.toLowerCase();
            var val = this.typeValue.trim();
            this.page = 0;
            this.typeValue = val;
            this.devices = [];
            this.loadingIndicator = true;
            this.getAllDevices(this.page, this.searchModel.sourceCustomerId);
        }
    };
    MoveComponent.prototype.onSelect = function (_a) {
        var selected = _a.selected;
        // this.selected.splice(0, this.selected.length);
        // this.selected.push(...selected);
        // console.log('Select Event', this.selected);
    };
    MoveComponent.prototype.shiftDevices = function () {
        var _this = this;
        if (this.searchModel.destinationCustomerId) {
            this.notSelected = false;
            if (this.searchModel.sourceCustomerId === this.searchModel.destinationCustomerId) {
                this.sameCustId = true;
            }
            else {
                this.moving = true;
                this.sameCustId = false;
                var devices_1 = [];
                this.selected.map(function (device) {
                    devices_1.push(device.serialNumber);
                });
                var payload = {
                    sourceCustomerId: this.searchModel.sourceCustomerId,
                    destinationCustomerId: this.searchModel.destinationCustomerId,
                    devices: devices_1
                };
                this.toolsService.shiftDevices(payload).subscribe(function (res) {
                    _this.moving = false;
                    _this.selected = [];
                    _this.btnClose.nativeElement.click();
                    _this.responseMessage = { success: true, message: enMessage.successDeviceMove };
                    _this.sharedService.setResponseMessage(_this.responseMessage);
                    _this.getAllDevices(_this.page, _this.searchModel.sourceCustomerId);
                }, function (error) {
                    _this.moving = false;
                    _this.btnClose.nativeElement.click();
                    _this.sharedService.getErrorMsg(error);
                });
            }
        }
        else {
            this.notSelected = true;
        }
    };
    return MoveComponent;
}());
export { MoveComponent };
