import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '@myenv/environment';
import {throwError as observableThrowError, Observable} from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  public apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private httpClient: HttpClient,
    private handler: HttpBackend
    ) {
    this.httpClient = new HttpClient(handler);
  }

  getCustomers(): Observable<any> {
    return this.http.get(
      this.apiUrl + `customers`
    )
      .pipe(catchError(this.handleError));
  }

  addCustomer(data): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    return this.httpClient.post(this.apiUrl + `customers`, data, {headers})
      .pipe(catchError(this.handleError));
  }

  handleError(error: any | any) {
    return observableThrowError(
      error.error || error.json() || error || 'Server error'
    );
  }

  getPreDefinedRoleId(): Observable<any> {
    return this.http.get(
      this.apiUrl + `roles/defaultRoles/predefined`
    )
      .pipe(catchError(this.handleError));
  }

  getPermissionsOnRoleId(roleId): Observable<any> {
    return this.http.get(
      this.apiUrl + 'roles/' + roleId + `/modules`
    )
      .pipe(catchError(this.handleError));
  }
}
