import { SupportComponent } from './support.component';
// import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PermissionGuard } from '@myproject/perimission.guard';
import { UserInfoComponent } from './user-info/user-info.component';
var ɵ0 = { moduleName: 'SUPPORT' }, ɵ1 = { moduleName: 'SUPPORT' };
// import { AdvancedSearchComponent } from './lazy/advanced-search/advanced-search.component';
var routes = [
    {
        path: '',
        component: SupportComponent,
        data: ɵ0,
        canActivate: [PermissionGuard]
    },
    {
        path: 'user-info', component: UserInfoComponent,
        data: ɵ1,
        canActivate: [PermissionGuard]
    }
];
// @NgModule({
//   imports: [RouterModule.forChild(routes)],
//   exports: [RouterModule]
// })
export var SupportRoutingModule = RouterModule.forChild(routes);
export { ɵ0, ɵ1 };
