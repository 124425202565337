<app-notify-message></app-notify-message>
<ul class="nav nav-tabs detailed-tabs relative page-sub-heading" role="tablist">
  <li class="nav-item active">
    <a class="nav-link text-center mt-1 ml-4" href="#general" [routerLink]="lastReportUrl">
      <i class="fa fa-bar-chart"></i> General
    </a>
  </li>
  <!-- <li class="nav-item">
    <a class="nav-link text-center mt-1 ml-4" href="#visualizations" routerLink="/reports/device/visualization">
      <i class="fa fa-area-chart"></i> Visualizations
    </a>
  </li> -->
  <li class="nav-item" *ngIf="permissionModules.includes('DEEP-VIEW')">
    <a class="nav-link text-center mt-1 ml-4" href="#deepView" routerLink="/reports/deep-view">
      <i class="fa fa-th"></i> Deep View
    </a>
  </li>
  <li class="nav-item" *ngIf="permissionModules.includes('SCORE')">
    <a class="nav-link text-center active mt-1" role="tab" data-toggle="tab" href="#score">
      <i class="fa fa-line-chart"></i> Scores
    </a>
  </li>
</ul>

<div class="tab-content">
  <div role="tabpanel" class="tab-pane fade in active show relative" id="score">
    <div class="row action-row align-items-center px-2">
      <div class="col-md-6">
        <h5 class="mb-0 page-heading ml-1">Have a look at your Vehicle Behavior</h5>
      </div>
      <div class="col-md-6 text-right">
        <div class="row no-gutters align-items-center">
          <div class="col-md-6"></div>
          <div class="col-md-6 text-left">
            <app-search-select-vehicle
            (vehicleChangeEvent)="changeVehicle($event)"
            [autoSelect]="true"
            >
          </app-search-select-vehicle>
          </div>
        </div>
      </div>
    </div>
    <div class="row no-gutters score-body">
      <div class="col-md-5 col-sm-12 py-4 pl-3">
        <div class="outer-score">
          <div class="score-container" (click)="changeScore('harshBraking')">
            <div class="score-name-container" [class.active]="selectedScore==='harshBraking'">
              <span class="score-name">Harsh Braking</span>
              <i class="fa fa-info-circle tooltip-event relative">
                <span class="tooltip-event-name">Daily score factoring in the intensity of hard braking events (configured on device) as well as time of the event and normalized by distance driven that day</span>
              </i>
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>
            </div>
            <div class="progress">
            <div
              class="progress-bar" role="progressbar"
              [class.green]="dailyScore.harshBraking > 85"
              [class.orange]="dailyScore.harshBraking > 60 && dailyScore.harshBraking <= 85"
              [class.red]="dailyScore.harshBraking > 30 && dailyScore.harshBraking <= 60"
              [class.dark-red]="dailyScore.harshBraking <= 30"
              [style.width]="dailyScore.harshBraking + '%'">
              {{dailyScore.harshBraking | number}}
            </div>
          </div>
          </div>
        </div>
        <div class="outer-score">
          <div class="score-container" (click)="changeScore('harshAcceleration')">
            <div class="score-name-container" [class.active]="selectedScore==='harshAcceleration'">
              <span class="score-name">Harsh Acceleration</span>
              <i class="fa fa-info-circle tooltip-event relative">
                <span class="tooltip-event-name">Daily score factoring in the intensity of harsh acceleration events (configured on device) as well as time of the event and normalized by distance driven that day.</span>
              </i>
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>
            </div>
            <div class="progress">
            <div
              class="progress-bar" role="progressbar"
              [class.green]="dailyScore.harshAcceleration > 85"
              [class.orange]="dailyScore.harshAcceleration > 60 && dailyScore.harshAcceleration <= 85"
              [class.red]="dailyScore.harshAcceleration > 30 && dailyScore.harshAcceleration <= 60"
              [class.dark-red]="dailyScore.harshAcceleration <= 30"
              [style.width]="dailyScore.harshAcceleration + '%'">
              {{dailyScore.harshAcceleration | number}}
            </div>
          </div>
          </div>
        </div>
        <div class="outer-score">
          <div class="score-container" (click)="changeScore('overSpeed')">
            <div class="score-name-container" [class.active]="selectedScore==='overSpeed'">
              <span class="score-name">Over Speeding</span>
              <i class="fa fa-info-circle tooltip-event relative">
                <span class="tooltip-event-name">Daily score factoring in speeding event magnitude (above 65 kmph), speeding duration and time of the speeding event normalized by distance driven that day.</span>
              </i>
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>
            </div>
            <div class="progress">
            <div
              class="progress-bar" role="progressbar"
              [class.green]="dailyScore.overSpeed > 85"
              [class.orange]="dailyScore.overSpeed > 60 && dailyScore.overSpeed <= 85"
              [class.red]="dailyScore.overSpeed > 30 && dailyScore.overSpeed <= 60"
              [class.dark-red]="dailyScore.overSpeed <= 30"
              [style.width]="dailyScore.overSpeed + '%'">
              {{dailyScore.overSpeed | number}}
            </div>
          </div>
          </div>
        </div>
        <div class="outer-score">
          <div class="score-container" (click)="changeScore('idling')">
            <div class="score-name-container" [class.active]="selectedScore==='idling'">
              <span class="score-name">Idling</span>
              <i class="fa fa-info-circle tooltip-event relative">
                <span class="tooltip-event-name">Daily score factoring in the percentage of trip idling time that day.</span>
              </i>
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>
            </div>
            <div class="progress">
            <div
              class="progress-bar" role="progressbar"
              [class.green]="dailyScore.idling > 85"
              [class.orange]="dailyScore.idling > 60 && dailyScore.idling <= 85"
              [class.red]="dailyScore.idling > 30 && dailyScore.idling <= 60"
              [class.dark-red]="dailyScore.idling <= 30"
              [style.width]="dailyScore.idling + '%'">
              {{dailyScore.idling | number}}
            </div>
          </div>
          </div>
        </div>
        <div class="outer-score">
          <div class="score-container" (click)="changeScore('exposure')">
            <div class="score-name-container" [class.active]="selectedScore==='exposure'">
              <span class="score-name">Exposure</span>
              <i class="fa fa-info-circle tooltip-event relative">
                <span class="tooltip-event-name">Daily score based on the time-of-day distance driven in the day.</span>
              </i>
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>
            </div>
            <div class="progress">
            <div
              class="progress-bar" role="progressbar"
              [class.green]="dailyScore.exposure > 85"
              [class.orange]="dailyScore.exposure > 60 && dailyScore.exposure <= 85"
              [class.red]="dailyScore.exposure > 30 && dailyScore.exposure <= 60"
              [class.dark-red]="dailyScore.exposure <= 30"
              [style.width]="dailyScore.exposure + '%'">
              {{dailyScore.exposure | number}}
            </div>
          </div>
          </div>
        </div>
        <div class="outer-score">
          <div class="score-container" (click)="changeScore('behaviour')">
            <div class="score-name-container" [class.active]="selectedScore==='behaviour'">
              <span class="score-name">Behaviour</span>
              <i class="fa fa-info-circle tooltip-event relative">
                <span class="tooltip-event-name">Weighted average of the Braking score (0.4), Speeding score (0.3), Acceleration score (0.2) and Idling score (0.1).</span>
              </i>
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>
            </div>
            <div class="progress">
            <div
              class="progress-bar" role="progressbar"
              [class.green]="dailyScore.behaviour > 85"
              [class.orange]="dailyScore.behaviour > 60 && dailyScore.behaviour <= 85"
              [class.red]="dailyScore.behaviour > 30 && dailyScore.behaviour <= 60"
              [class.dark-red]="dailyScore.behaviour <= 30"
              [style.width]="dailyScore.behaviour + '%'">
              {{dailyScore.behaviour | number}}
            </div>
          </div>
          </div>
        </div>
        <div class="outer-score">
          <div class="score-container" (click)="changeScore('risk')">
            <div class="score-name-container" [class.active]="selectedScore==='risk'">
              <span class="score-name">Risk</span>
              <i class="fa fa-info-circle tooltip-event relative">
                <span class="tooltip-event-name">Weighted average of the Exposure Risk score (0.4), Braking score (0.3), Acceleration Score (0.2) and Speeding score (0.1) .</span>
              </i>
              <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>
            </div>
            <div class="progress">
            <div
              class="progress-bar" role="progressbar"
              [class.green]="dailyScore.risk > 85"
              [class.orange]="dailyScore.risk > 60 && dailyScore.risk <= 85"
              [class.red]="dailyScore.risk > 30 && dailyScore.risk <= 60"
              [class.dark-red]="dailyScore.risk <= 30"
              [style.width]="dailyScore.risk + '%'">
              {{dailyScore.risk | number}}
            </div>
          </div>
          </div>
        </div>
      </div>
      <div class="col-md-7 col-sm-12 p-4">
        <div class="score-container trend">
          <h6 class="pl-2">{{titleText}} History</h6>
          <canvas id="lineChart" height="180" role="img"></canvas>
        </div>
      </div>
    </div>
  </div>
</div>

