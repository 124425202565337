import { GraphicReportsService } from '@components/graphic-reports/graphic-reports.service';
import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SharedService } from '@myproject/shared/shared.service';
import Chart from 'chart.js';
import { DatePipe } from '@angular/common';
import { configMessage } from '@shared/config-message';
import * as FileSaver from 'file-saver';
import { DeviceService } from '@myproject/components/device/device.service';
import { environment } from '@myenv/environment';
import { CustomerService } from '../../../customer/customer.service';

@Component({
  selector: 'app-billing-report',
  templateUrl: './billing-report.component.html',
  styleUrls: ['./billing-report.component.scss']
})
export class BillingReportComponent implements OnInit {
  status: any = {
    total: 0,
    communicating: 0,
    notCommunicating: 0,
    disconnected: 0,
    notConnected: 0
  };
  statusPercent = {
    communicating: 0,
    notCommunicating: 0,
    disconnected: 0,
    notConnected: 0
  };
  deviceCounts: any = {
    deviceAdded: 0,
    deviceRemoved: 0,
    messageCount: 0,
    deviceReplaced: 0
  };
  pieChart;
  isLoading = false;
  dataPattern = [
    // { key: 'total', label: 'Total' },
    { key: 'communicating', label: 'Communicating', color: '#39cb39' },
    { key: 'notCommunicating', label: 'Not Communicating', color: '#ff8000' },
    { key: 'disconnected', label: 'Disconnected', color: '#ff0000' },
    { key: 'notConnected', label: 'Not Connected', color: '#5c8a8a' },
  ];
  selectedDevice = null;
  highestCount = 0;
  statistics: any;
  fotaStats: any;
  lineChart: any;
  deviceAdded: any;
  deviceRemoved: any;
  deviceReplaced: any;
  multiYChart: any;
  fotaChart: any;
  // Drawer variables
  isDrawerOpen = true;
  selectedStatus: number;
  devices = [];
  pageInfo = {
    offset: 0,
    count: 0,
    limit: 5,
    pageSize: 0,
    lastPage: 0,
    prevResponse: 0
  };
  page = 0;
  currentPageCount = {
    start: 0,
    end: 0
  };
  // config properties
  deviceStatus = configMessage.deviceStatus;
  deviceStatusClass = configMessage.deviceStatusClass;
  dateAttributes = configMessage.dateAttributes;
  public dateFormat = environment.isoDateTimeFormat;
  // table headers
  allHeaders = [];
  displayHeaders = [];
  defaultHeaders = ['serialNumber', 'imei', 'simCcid', 'firmwareVersion', 'configVersion', 'simCarrier', 'model', 'deviceStatus'];
  deviceHeaderPreferences = [];
  querySelectVehicle =null;


  deviceList: any[] = [];
  selectedDate = "";
  month = [{ "id": 1, "name": "January" }, { "id": 2, "name": "February" }, { "id": 3, "name": "March" }, { "id": 4, "name": "April" }, { "id": 5, "name": "May" }, { "id": 6, "name": "June" }, { "id": 7, "name": "July" }, { "id": 8, "name": "August" }, { "id": 9, "name": "September" }, { "id": 10, "name": "October" }, { "id": 11, "name": "November" }, { "id": 12, "name": "December" }]
  years = [];
  selectedMonth = 0;
  selectedYear = 0;
  loadingIndicator = false;
  prevResponse = 0;
  totalAssetCount: any;
  currentCustomer: any = {};
  messageTypeColor = configMessage.billingMessageTypeColor;
  stackedChart: any;
  totalsDataset = [];
  chartLabels = [];
  stackedDataset = [];
  permissionModules = [];
  toDate: any;
  accountStatus: string;
  inActiveColor = "rgb(211, 37, 53)";
     activeColor = "rgb(211, 37, 53)";
     enrolledColor = "rgb(211, 37, 53)";
  expiredDate: boolean;
  totalMessagesCount: any;
  assetType: string;
  assetsList = ['Device','Vehicle']
  billingReportCSR: any;
  customersList: any[] = [];
  localImpersonate: any;
  //@Input() billingReportCSR: any;
  chart: any = null;

  constructor(
    private title: Title,
    private reportsService: GraphicReportsService,
    private sharedService: SharedService,
    private deviceService: DeviceService,
    private customerService: CustomerService
  ) { 
    this.billingReportCSR = localStorage.getItem("billingReportCSR");
    console.log("billingReportCSR", this.billingReportCSR)
    this.localImpersonate = localStorage.getItem('impersonate');
    if (this.localImpersonate === 'true') {
      this.billingReportCSR = 'false' 
    }
    this.getCustomers();
  }

  ngOnInit() {
    //this.billingReportCSR = false
    this.currentCustomer = JSON.parse(localStorage.getItem("currentUser"));
    

    //await this.getCustomers();
    //this.getTotalCountOfAsset("device");
    //this.getAccountStatus();
    //this.getTotalMessagesCount();

    this.title.setTitle("Billing Report" + environment.title_text);
    this.permissionModules = JSON.parse(
      localStorage.getItem("permissionModules")
    );
    var currentYear = new Date().getFullYear();

    for (var i = 0; i < 10; i++) {
      this.years.push(currentYear - i);
      this.years.push(currentYear + i);
    }
    this.years = [...new Set(this.years.sort())];
    this.selectedYear = currentYear;
    this.selectedMonth = new Date().getMonth() + 1;

    // this.selectedDate = this.datePipe.transform(this.selectedDate, 'yyyy-MM-dd');
    
    this.getDeviceUsageByMonthYear(this.selectedYear, this.selectedMonth);
  }


  getDeviceUsageByMonthYear(yr, mnth) {
    // this.reportsService.getUsageByMonthYear(2022, 3).subscribe(data => {
    // this.deviceList = [];
    this.loadingIndicator = true
    this.reportsService.getUsageByMonthYear(yr, mnth, this.page).subscribe(data => {
      console.log("data", data)
      this.prevResponse = data.length;
      if (this.page === 0) {
        this.deviceList = data;
      } else {
        this.deviceList = [...this.deviceList, ...data];
      }

      this.loadingIndicator = false;
      if (this.deviceList.length) {
        this.pageInfo.count = this.deviceList.length;
        const pagecount = this.pageInfo.count / this.pageInfo.limit;
        this.pageInfo.lastPage = Math.floor(pagecount);
        const decimal = pagecount - this.pageInfo.lastPage;
        if (!Number.isInteger(decimal)) {
          this.pageInfo.lastPage = this.pageInfo.lastPage + 1;
        }
        this.currentPageCount.start = (this.pageInfo.offset * this.pageInfo.pageSize) + 1;
        if (this.deviceList.length <= 10) {
          this.currentPageCount.end = this.pageInfo.count;
        } else {
          this.currentPageCount.end = (this.pageInfo.offset * this.pageInfo.pageSize) + this.pageInfo.limit;
        }
      }
    }, error => {
      this.loadingIndicator = false;
    })
  }
  getTotalCount($event) {
    this.getTotalCountOfAsset($event.target.value)
  }
  getTotalCountOfAsset(assetType: any, customerId?: any) {
    if (assetType === undefined) {
      this.assetType = "device"
    } else {
      this.assetType = assetType
    }
    this.reportsService.getTotalCountOfAsset(customerId).subscribe(data => {
    data.forEach(element => {
      if (this.assetType === "vehicle" && element.assetType ==="vehicle") {
        this.totalAssetCount = element.count
      } else if (this.assetType === "device" && element.assetType ==="device") {
        this.totalAssetCount = element.count
      }
    });
    }, error => {
      console.log("total vehicle/device count API", error)
    })
  }

  getAccountStatus(customerID?: any) {
    let assetType: any
    let customerId: any
    if (customerID != undefined && customerID != null) {
      customerId =customerID
    } else {
      customerId = this.currentCustomer.customerId;
    }
    
    if (customerId != undefined || customerId != null) {
       
    this.reportsService.getAccountStatus(customerId).subscribe(data => {
      console.log("total vehicle/device count API", data)
      if (data.customerId && data.customerId != null) {
        
        if (data.toDate && data.toDate != null) {
          this.convertLongToDate(data)
        } else {
          this.toDate = null
          this.accountStatus = "Active"
          this.expiredDate = true
        }
      } else if (assetType === "device" && data[0].assetType ==="others") {
        this.totalAssetCount = data[1].count
      }
      }, error => {
        console.log("total vehicle/device count API", error)
      })
    }
  }

  getTotalMessagesCount(customerId?:any) {
    this.reportsService.getTotalMessagesCount(customerId).subscribe((data) => {
      this.totalMessagesCount = data.totalMessagesCount
    }, error => {
      console.log("error", error)
    })
  }
  getUsageStats(customerId?: any) {
    this.reportsService.getUsageStats(customerId).subscribe((data) => {
      this.sortData(data)
    }, error => {
      console.log("error", error)
    })
  }

  sortData(res) {
    const labels = [];
    const keys = [];
    const backgroundColors = [];
    const dataset = [];
    const totalCounts = [];
     
    // find all the distinct messages
    res.forEach((item) => {
      for (var k in item) {
        if (!keys.includes(k) && k !== "date" && k !== "NO_DATA") {
          if (this.messageTypeColor[this.alterLabel(k)]) {
            backgroundColors.push(this.messageTypeColor[this.alterLabel(k)].colorOpaque);
          } else {
            backgroundColors.push(this.messageTypeColor["UNKNOWN"].colorOpaque);
          }
          keys.push(k);
        }
      }
    });
    // Obtain date labels and total counts for each day
    res.map((item) => {
      var datePipe = new DatePipe("en-US");
      // let eventDate = datePipe.transform(item.date, "EE, dd MMM");
      let eventDate = datePipe.transform(item.date, "MMM yyyy");
      labels.push(eventDate);
      let total = 0;
      for (let k in item) {
        if (k !== "date" && item[k]) {
          total += item[k];
        }
      }
      totalCounts.push(total);
    });
    // get message count and configure dataset
    const highestCountsArray = [];
    for (let i = 0; i < keys.length; i++) {
      const obj: any = {};
      const counts = [];
      res.map((item) => {
        if (item[keys[i]]) {
          counts.push(item[keys[i]]);
        } else {
          counts.push(0);
        }
      });
      obj.label = this.alterLabel(keys[i]);
      // obj.label = configMessage.messageType[keys[i]];
      obj.backgroundColor = backgroundColors[i];
      obj.data = counts;
      dataset.push(obj);
      highestCountsArray.push(Math.max(...counts));
    }
    this.chartLabels = labels;
    this.stackedDataset = dataset;
    this.totalsDataset = [
      {
        label: "Total Messages",
        backgroundColor: "rgb(54, 162, 235, 1)",
        data: totalCounts,
      },
    ];
    // this.drawStackedBarGraph(this.chartLabels, this.totalsDataset);
    this.drawStackedBarGraph(this.chartLabels, this.stackedDataset);
  }
  alterLabel(label) {
    if (label === "inactive") {
      return "In-Active";
    } else if (label === "active") {
      return "Active";
    } else {
      return "Enrolled";
    }
  }

  drawStackedBarGraph(labels, dataset) {
    if (this.stackedChart) {
      this.stackedChart.destroy();
    }
    var ctx = document.getElementById("stackedBarGraph");
    this.stackedChart = new Chart(ctx, {
      type: "bar",
      responsive: true,
      data: {
        labels: labels,
        datasets: dataset,
      },
      options: {
        legend: {
          position: "bottom",
          display: true,
          onHover: function (event, legendItem) {
            document.getElementById("canvas").style.cursor = "pointer";
          },
          onClick: function (e, legendItem) {
            var index = legendItem.datasetIndex;
            var ci = this.chart;
            var alreadyHidden =
              ci.getDatasetMeta(index).hidden === null
                ? false
                : ci.getDatasetMeta(index).hidden;
            var anyOthersAlreadyHidden = false;
            var allOthersHidden = true;
            // figure out the current state of the labels
            ci.data.datasets.forEach(function (e, i) {
              var meta = ci.getDatasetMeta(i);
              if (i !== index) {
                if (meta.hidden) {
                  anyOthersAlreadyHidden = true;
                } else {
                  allOthersHidden = false;
                }
              }
            });
            // if the label we clicked is already hidden
            // then we now want to unhide (with any others already unhidden)
            if (alreadyHidden) {
              ci.getDatasetMeta(index).hidden = null;
            } else {
              // otherwise, lets figure out how to toggle visibility based upon the current state
              ci.data.datasets.forEach(function (e, i) {
                var meta = ci.getDatasetMeta(i);
                if (i !== index) {
                  // handles logic when we click on visible hidden label and there is currently at least
                  // one other label that is visible and at least one other label already hidden
                  // (we want to keep those already hidden still hidden)
                  if (anyOthersAlreadyHidden && !allOthersHidden) {
                    meta.hidden = true;
                  } else {
                    // toggle visibility
                    meta.hidden = meta.hidden === null ? !meta.hidden : null;
                  }
                } else {
                  meta.hidden = null;
                }
              });
            }
            ci.update();
          },
        },
        scales: {
          xAxes: [
            {
              display: true,
              stacked: true,
            },
          ],
          yAxes: [
            {
              display: true,
              stacked: true,
              ticks: {
                suggestedMin: 0,
                suggestedMax: 10,
                // stepSize: this.highestCount > 9 ? null : 1,
              },
            },
          ],
        },
      },
    });
  }

  convertLongToDate(row:any) {

     if (row.toDate && row.toDate != null) {
      let tempDate = row.toDate
      var todate = new Date(row.toDate)
      var todayDate = new Date()
      if (!(todate < todayDate)) {
        this.accountStatus = "Active"
        this.expiredDate = false
      } else if (todate.getDate() === todayDate.getDate()) {
        this.accountStatus = "Active"
        this.expiredDate = false
      } else {
        this.accountStatus = "Inactive"
        this.expiredDate = true
      }
      this.toDate = tempDate
    }
   }

   getCustomers() {
    setTimeout(() => {
    this.customerService.getCustomers().subscribe(response => {
      this.customersList = response
      if (this.customersList) {
        //let customerId = this.customersList[0].customerId
        if (this.billingReportCSR == 'true') {
          this.getData(this.customersList[0].customerId)
        }
      }
    }, error => {
      console.log("error", error)
      this.getData()
    })
    }, 500);
   }
   getCustomerData(event: any) {
    this.getData(event.target.value)
   } 

   getData(customerId?: any) {
    this.getTotalCountOfAsset("device", customerId);
    this.getAccountStatus(customerId);
    this.getTotalMessagesCount(customerId);
    this.getUsageStats(customerId);
   }
}
