import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { SharedService } from '@shared/shared.service';
import { VehicleUploadService } from '@components/vehicle/vehicle-upload/vehicle-upload.service';

import { Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import {Angular5Csv} from 'angular5-csv/dist/Angular5-csv';
import { VehicleService } from '@components/vehicle/vehicle.service';
import * as FileSaver from 'file-saver';
import { DatatableComponent, TableColumn } from '@swimlane/ngx-datatable';
import { GroupsService } from '@myproject/components/device/groups/groups.service';
import { environment } from '@myenv/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { enMessage } from '@shared/en-us-message';

import { configMessage } from '@shared/config-message';
import {AdminService} from '@components/admin/admin.service';

@Component({
  selector: 'app-vehicle-view-report',
  templateUrl: './vehicle-view-report.component.html',
  styleUrls: ['./vehicle-view-report.component.scss']
})
export class VehicleViewReportComponent implements OnInit, AfterViewInit {
  selectedVehicle: any;
  map: google.maps.Map;
  @ViewChild('gmap', { static: false }) gmapElement: any;
  markers: any = [];
  public cmImageUrl = environment.cmImageUrl;
  public dateFormat = environment.largeDateFormat;
  public noSecondsTimeFormat = environment.noSecondsTimeFormat;
  // This to display activity property from config
  activityAttribute = configMessage.activityConfig;
  slectAllOption = true;
  responseMessage: any = {};
  listPage = true;
  disable = false;
  public vehicles: any = [];
  public vehicleActivity: any = [];
  loadmore = false;
  public vehicle: any = {};
  public deviceId = '';
  currentVehicle = '';
  isLoading = false;
  refreshing = false;
  loadingIndicator = true;
  serialNumber = '';
  vehicleHeader: any = [];
  vehicleFullString: any = [];
  /** This is used to display vehicle detail in pop up*/
  singleVehicleDetail: any = [];

  currentPageCount: any = {
    start: 0,
    end: 0
  };
  vehiclestaticHeader = [
    { name: 'assetName', displayName: 'Name', type: 'STRING' },
    { name: 'vin', displayName: 'VIN', type: 'STRING' },
    { name: 'serialNumber', displayName: 'Serial Number', type: 'STRING' },
    { name: 'groupName', displayName: 'Group Name', type: 'STRING' }
  ];
  noPrefColumns = ['assetName', 'vin', 'serialNumber', 'groupName'];
  public vehicleTags = environment.vehicleTags;
  vehicleNewHeader: any = [];
  vehicleSearchheader: any = [];
  @ViewChild('btnClose', { static: false }) btnClose: ElementRef;
  @ViewChild('btnCloseDelete', { static: false }) btnCloseDelete: ElementRef;
  @ViewChild('closeuploadVehicle_model', { static: false }) closeuploadVehicle_model: ElementRef;
  @ViewChild('closeaddVehicle_model', { static: false }) closeaddVehicle_model: ElementRef;
  @ViewChild('closeeditVehicle_modal', { static: false }) closeeditVehicle_modal: ElementRef;
  vehiclessArray$: Observable<SharedService[]>;
  moduleName = 'VEHICLE';
  permissionAuthorities: any = {};
  modules: any = [];
  public total: number;
  public itemsPerPage: any = 10; // we are showing 10 items per page
  public lastPage = 0;
  searchModel: any = {};
  @ViewChild('inputElement', { static: false }) inputOne: ElementRef;
  timeout = null;
  page = 0;
  pageLimit: number = environment.pageLimit;
  index: number;
  dateAttributes = configMessage.dateAttributes;

  @ViewChild('test', { static: false }) test: ElementRef;
  vehicleDetail: any = {};
  pageInfo = {
    offset: 0,
    count: 0,
    limit: 10,
    pageSize: 0,
    lastPage: 0,
  };
  prevResponse = 0;
  groups: any = [];
  message: any;
  vehicleTotalCount: any;
  @ViewChild('dataTable', { static: false }) public dataTable: DatatableComponent;
  vehicleFullHeader: any = [];
  tempSelection: any;
  sortParams: any = {};

  // Multi Select
  allColumns: any = [];
  columns: any = [];
  headerPref: any = [];
  finalHead: any = [];
  headSelSettings: any = {};
  finalSelected: any = [];
  allSelected = false;
  columnPopup = false;
  updatingPref = false;
  selectCount = 0;
  checkedItems: any = [];
  pageLoading = true;

  constructor(

    private groupsService: GroupsService,
    private sharedService: SharedService,
    private vehicleUploadService: VehicleUploadService,
    private title: Title,
    private vehicleService: VehicleService,
    private route: ActivatedRoute,
    private adminService: AdminService,
    private router: Router
    ) {
    this.modules = JSON.parse(localStorage.getItem('accessRoleModules'));
    this.permissionAuthorities = this.modules.find(module => module.name == this.moduleName);
  }

  ngOnInit() {
    this.modules = JSON.parse(localStorage.getItem('accessRoleModules'));
    this.headerPref = JSON.parse(localStorage.getItem('preferences')).vehicle.selectedFields;
    this.permissionAuthorities = this.modules.find(module => module.name == this.moduleName);
    this.message = this.adminService.getSideMenu();
    this.title.setTitle('Vehicles'+ environment.title_text);
    this.getVehicleHeader();
  }

  ngAfterViewInit() {
    this.initAutocomplete();
  }

  initAutocomplete() {
    var mapOptionObject = {
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      zoom: 4,
      minZoom: 4,
      maxZoom: 18,
      mapTypeControl: true,
      mapTypeControlOptions: {
        position: google.maps.ControlPosition.TOP_RIGHT
      },
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER
      },
      scaleControl: true,
      streetViewControl: false,
      fullscreenControl: false,
      clickableIcons: false,
      center: {
        lat: environment.lat,
        lng: environment.lng
      }
    }
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapOptionObject);
  }

  refresh() {
    this.refreshing = true;
    this.page = 0;
    this.pageInfo.offset = 0;
    if(this.searchModel.deviceId && this.searchModel.vehicleTypeVal) {
      this.onSearch();
    } else {
      this.getAllVehicles(this.page);
      this.getVehicleCount();
    }
  }

  // To get the header of Vehicles table
  getVehicleHeader() {
    this.sharedService.getVehicleHeader().subscribe(res => {
      // this.isLoading = false;
      this.vehicleFullHeader = res;
      // this.isLoading = true;
      this.setTableHeader(res);
      this.vehicleFullString = res.map(function (item) {
        return item.name;
      });
      this.vehicleHeader = res.filter(x => {
        // tslint:disable-next-line: max-line-length
        if (!(x.name == 'vin' || x.name.slice(-2) == 'Id' || x.name == 'assetName' || x.name == 'serialNumber' || x.name == 'lastCommunication' || x.name == 'groupName' || x.name === 'createdAt' || x.name === 'activatedOn' || x.name === 'tags')) {
          return x;
        }
      });
      this.vehicleNewHeader = res.filter(x => {
        // tslint:disable-next-line: max-line-length
        if (!(this.dateAttributes.includes(x.name) || x.name === 'updatedAt' || x.name === 'createdAt' || x.name == 'vin' || x.name.slice(-2) == 'Id' || x.name == 'assetName' || x.name == 'serialNumber' || x.name == 'groupName' || x.name == 'deviceId' || x.name == 'customerId' || x.name == 'companyId' || x.name == 'groupId')) {
          return x;
        }
      });
      // this.dtTrigger.next();
      // this.vehicleHeader = server;
      this.vehicleSearchheader = this.vehiclestaticHeader.concat(this.vehicleNewHeader);
      const queryParams = this.route.snapshot.queryParams;
      if (!((queryParams.searchBy && queryParams.value) || queryParams.serialNumber)) {
        this.searchModel['deviceId'] = this.vehicleSearchheader[0].name;
        this.page = 0;
        this.getAllVehicles(this.page);
        this.getVehicleCount();
      }
      this.tempSelection = this.searchModel['deviceId'];
    }, error => {
      this.pageLoading = false;
      this.sharedService.getErrorMsg(error);
    });
  }

  setTableHeader(res) {
    let pref = [];
    if (this.headerPref && this.headerPref.length) {
      pref = this.headerPref;
    } else {
      pref = this.noPrefColumns;
    }
    res.map(head => {
      if (pref.includes(head.name)) {
        head.checked = true;
        this.selectCount++;
        this.finalHead.push(head);
      }
    });
    if (this.selectCount == res.length) {
      this.allSelected = true;
    }
    this.allColumns = res;
    this.columns = res;
    let keyArray = [];
    this.allColumns.map(header => {
      if (header.checked) {
        keyArray.push(header.name);
      }
    });
    this.columns = this.allColumns;
    this.checkedItems = keyArray;
  }

  togglePopup() {
    if (this.columnPopup) {
      this.cancelSelection();
    } else {
      this.columnPopup = true;
      this.checkCustomSelected();
    }
  }

  checkCustomSelected() {
    this.selectCount = 0;
    if(this.searchModel && this.searchModel.columnVal) {
      this.searchModel.columnVal = '';
    }
    // let prefrences = JSON.parse(localStorage.getItem('preferences'));
    // this.allColumns = prefrences.device.selectedFields;
    this.allColumns.map(head => {
      if (this.checkedItems.includes(head.name)) {
        this.selectCount++;
        head.checked = true;
      } else {
        head.checked = false;
      }
      // if (this.noPrefColumns.includes(head.name)) {
      //   head.checked = true;
      //   this.selectCount++;
      // }
    });
    this.columns = this.allColumns;
    if( this.selectCount == this.checkedItems) {
      this.allSelected = true;
    }
  }

  selectItems(event, header, i) {
    if (header.name !== 'assetName') {
      if (event.target.checked) {
        this.selectCount++;
      } else {
        this.selectCount--;
      }
      this.allColumns.map(head => {
        if (head.name == header.name) {
          if(event.target.checked) {
            head.checked = true;
          } else {
            head.checked = false;
          }
        }
      });
      // this.allColumns[i].checked = event.target.checked;
    }
  }

  selectAllItems(event) {
    this.allSelected = event.target.checked;
    if (event.target.checked) {
      this.selectCount = this.allColumns.length;
    } else {
      this.selectCount = 0;
    }
    this.allColumns.map(head => {
      if (head.name === 'assetName') {
        head.checked = true;
      } else {
        head.checked = event.target.checked;
      }
    });
  }

  cancelSelection() {
    this.selectCount = 0;
    this.columnPopup = false;
  }

  updatePreference() {
    const tempArray = [];
    const keyArray = [];
    this.updatingPref = true;
    this.allColumns.map(header => {
      if (header.checked) {
        const obj = {
          name: header.name,
          displayName: header.displayName,
          checked: header.checked,
          type: header.type
        };
        tempArray.push(obj);
        keyArray.push(header.name);
      }
    });
    const payload = { vehicle: { selectedFields: keyArray } };
    this.sharedService.updatePreference(payload).subscribe(res => {
      this.finalHead = tempArray;
      this.headerPref = tempArray;
      this.checkedItems = keyArray;
      this.columnPopup = false;
      this.updatingPref = false;
      this.columns = this.allColumns;
      const updateStorage = JSON.parse(localStorage.getItem('preferences'));
      if (updateStorage.vehicle.selectedFields) {
        updateStorage.vehicle.selectedFields = keyArray;
        localStorage.setItem('preferences', JSON.stringify(updateStorage));
      }
    }, error => {
      this.sharedService.getErrorMsg(error);
      this.updatingPref = false;
    });
  }

  onPageChange(value) {
    if (this.lastPage === value) {
      const page = ++this.page;
      this.getAllVehicles(page);
    }
  }

  // To get all vehicles
  getAllVehicles(page) {
    // this.vehiclessArray$.push(this.sharedService.getAllVehicles(page));
    this.vehicleService.getAllVehicles(page, this.sortParams).subscribe(res => {
      // this.searchModel.deviceId = this.tempSelection;
      // this.isLoading = false;
      if (page == 0) { this.vehicles = res; } else {
        this.vehicles = [...this.vehicles, ...res];
      }
      this.loadingIndicator = false;
      this.isLoading = true;
      this.prevResponse = res.length;
      this.pageInfo.count = this.vehicles.length;
      const pagecount = this.pageInfo.count / this.pageInfo.limit;
      this.pageInfo.lastPage = Math.floor(pagecount);
      const decimal = pagecount - this.pageInfo.lastPage;

      if (!Number.isInteger(decimal)) {
        this.pageInfo.lastPage = this.pageInfo.lastPage + 1;
      }
      if (page == 0) {
        if (res.length) {
          this.currentPageCount.start = (this.pageInfo.offset * this.pageInfo.pageSize) + 1;
          if (this.vehicles.length <= 10) {
            this.currentPageCount.end = this.pageInfo.count;
          } else {
            this.currentPageCount.end = (this.pageInfo.offset * this.pageInfo.pageSize) + this.pageInfo.limit;
          }
        }
      }
      this.pageLoading = false;
      this.refreshing = false;
    }, error => {
      this.pageLoading = false;
      this.refreshing = false;
      this.sharedService.getErrorMsg(error);
    });
  }

  clearSearch(f) {
    this.tempSelection = this.searchModel.deviceId;
    this.searchModel.vehicleTypeVal = '';
    if (f.valid) {
      this.vehicles = [];
      f.resetForm();
      this.loadingIndicator = true;
      this.page = 0;
      this.pageInfo.offset = 0;
      this.getAllVehicles(this.page);
      this.getVehicleCount();
    }
  }

  onSort(event) {
    this.loadingIndicator = true;
    this.sortParams = event.sorts[0];
    if (this.searchModel.vehicleTypeVal) {
      let vehicleTypeValue = this.searchModel.vehicleTypeVal.trim();
      this.vehicleService.getAllVehicles(0, event.sorts[0], this.searchModel.deviceId, vehicleTypeValue).subscribe(res => {
        this.vehicles = res;
        this.loadingIndicator = false;
      }, error => {
        this.sharedService.getErrorMsg(error);
      });
    } else {
      this.vehicleService.getAllVehicles(0, event.sorts[0]).subscribe(res => {
        this.vehicles = res;
        this.loadingIndicator = false;
      }, error => {
        this.sharedService.getErrorMsg(error);
      });
    }

  }

  onColumnSearch() {
    this.columns = this.allColumns;
    const inputVal = this.searchModel.columnVal.trim();
    const val = inputVal.toLowerCase();
    if (val === '') {
      this.slectAllOption = true;
      this.columns = this.allColumns;
      return
    } else {
      if (this.allColumns.length) {
        this.slectAllOption = false;
        const temp = this.allColumns.filter((item, index) => {
          if (item.displayName.toLowerCase().includes(val)) {
            return this.allColumns[index];
          }
        });
        this.columns = temp;
      }
    }

  }

  // To download table data as a csv file
  downloadVehicleCSV() {
    this.vehicleService.downloadVehicleCSV(this.searchModel).subscribe(res => {
      let contentDisposition = res.headers.get('Content-Disposition');
      let filename;
      if (contentDisposition == null) {
        const date = new Date();
        filename = 'Vehicles_' + date + '.csv';
      } else {
        filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
      }
      const blob = new Blob([res.body], {
        type: 'text/csv;charset=utf-8'
      });
      FileSaver.saveAs(blob, filename);

    }, error => {

      this.sharedService.getErrorMsg(error);
    });
  }

  // To download table data as an XML file
  downloadVehicleXML() {
    this.vehicleService.downloadVehicleXML(this.searchModel).subscribe(res => {
      let contentDisposition = res.headers.get('Content-Disposition');
      let filename;
      if (contentDisposition == null) {
        const date = new Date();
        filename = 'Vehicles_' + date + '.xml';
      } else {
        filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
      }
      const blob = new Blob([res.body], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-16le'
      });
      FileSaver.saveAs(blob, filename);
    }, error => {
    });
  }

  // To get total vehicles count
  getVehicleCount() {
    this.vehicleService.getDeviceCount().subscribe(res => {
      this.vehicleTotalCount = res;
      // this.isLoading = true;
    }, error => {
    });
  }

  searchDataOnChange() {
    if(this.searchModel.searchValue && this.searchModel.searchId) {
      this.onSearch();
    }
  }

  selectInput() {
    const inputElem = <HTMLInputElement>this.inputOne.nativeElement;
    inputElem.select();
  }

  public inputValidator(event: any) {
    const term = event.target.value.replace(/[^_\-@.,a-zA-Z0-9\s]/g, "");
    this.searchModel.vehicleTypeVal = term.split(/[ ]+/).filter(function(v){return v!==''}).join(',');
    this.onSearch();
  }

  onSearch() {
    clearTimeout(this.timeout);
    let global = this;
    this.timeout = setTimeout(function () {
      if(global.searchModel.deviceId && global.searchModel.vehicleTypeVal) {
        let vehicleTypeValue = global.searchModel.vehicleTypeVal.trim();
        global.sharedService.onSearchVehicle(global.searchModel.deviceId, vehicleTypeValue).subscribe({
          next: res => {
            global.loadingIndicator = false;
            global.vehicles = res;
            if (res.length) {
              global.vehicleTotalCount = {};
              global.vehicleTotalCount['count'] = res.length;
              global.pageInfo.offset = 0;
              global.pageInfo.count = res.length;
              global.currentPageCount.start = (global.pageInfo.offset * global.pageInfo.pageSize) + 1;
              if (global.vehicles.length <= 10) {
                global.currentPageCount.end = global.pageInfo.count;
              } else {
                global.currentPageCount.end = (global.pageInfo.offset * global.pageInfo.pageSize) + global.pageInfo.limit;
              }
            } else {
              global.vehicleTotalCount = false;
            }
            global.isLoading = true;
            global.prevResponse = 0;
            global.refreshing = false;
          },
          error: error => {
            global.refreshing = false;
            global.sharedService.getErrorMsg(error);
            global.loadingIndicator = false;
          }
        });
      } else {
        global.page=0;
        global.pageInfo.offset = 0;
        global.getAllVehicles(0);
        global.getVehicleCount();
      }
    }, 1500);
  }

  // To get the vehicles for each page in the table
  pageCallback(value) {
    this.pageInfo.offset = value.offset;
    this.currentPageCount.start = (value.offset * value.pageSize) + 1;
    const lastPage = Math.floor(this.vehicles.length / 10);
    if (lastPage == value.offset) {
      this.currentPageCount.end = value.count;
    } else {
      this.currentPageCount.end = (value.offset * value.pageSize) + value.limit;
    }
    if (this.pageInfo.lastPage === (this.pageInfo.offset + 1)) {
      if (this.prevResponse) {
        this.page = ++this.page;
        this.getAllVehicles(this.page);
      }
    }
  }

  addMarker(lat, long, img) {
    const latlng = new google.maps.LatLng(lat, long);
    const marker = new google.maps.Marker({
      position: latlng,
      map: this.map,
      // label: eventDate,
      icon: img
    });
    this.setAnimation(marker);
    this.markers.push(marker);
    var bounds = new google.maps.LatLngBounds();
    bounds.extend(latlng);
    this.map.fitBounds(bounds);
    this.markers.push(marker);
  }

  // Set Animation
  setAnimation(marker) {
    marker.setAnimation(google.maps.Animation.BOUNCE);
    setTimeout(function () { marker.setAnimation(null); }, 750);
  }

  clearMarkers() {
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(null);
    }
  }

  loadMap(row) {
    this.selectedVehicle = row;
    let global = this;
    const imgEnd = '../../assets/img/rect836.png';
    if(this.markers.length) {
      this.clearMarkers();
    }
    this.addMarker(row.latitude, row.longitude, imgEnd);
    setTimeout(function() {
      google.maps.event.trigger(global.map, 'resize');
    }, 3000)
  }

}
