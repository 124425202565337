import { Directive, ElementRef, NgZone, OnDestroy, ViewChild, ViewChildren } from '@angular/core';
import { SharedService } from '@myproject/shared/shared.service';
declare var $: any;
import { TripsComponent } from './trips.component';
import { configMessage } from '@shared/config-message';

@Directive({
  selector: '[tripViewContainer], [messageViewContainer], [obdPidViewContainer]'
})
export class TripsDirective implements OnDestroy {
  observer: MutationObserver;
  // @ViewChild(TripsComponent, { static: false }) tripsComponent: TripsComponent;
  constructor(
    private elRef: ElementRef,
    private sharedService: SharedService,
    private ngZone: NgZone,
    private tripsComponent: TripsComponent
  ) { }

  ngOnInit() {
    this.registerDomChangedEvent(this.elRef.nativeElement);
  }

  registerDomChangedEvent(el) {
    const global = this;
    this.observer = new MutationObserver(list => {
      const tripsComponent = global.tripsComponent;
      const firstActiveSection = $('.left-sec')[0].style.display === 'block' ? $($('.left-sec')[0]) : $($('.left-sec')[1]);
      const activeViewHeight = firstActiveSection.height();
      const leftSection = $('#leftsctn').height();
      if (!tripsComponent.accordion.isPidManualCollapse && (activeViewHeight > 100 || tripsComponent.accordion.msg_view || tripsComponent.accordion.trip_view)) {
        const checkCanViewDataView = (leftSection - activeViewHeight);
        tripsComponent.accordion.isHavingEnoughSpace = checkCanViewDataView >= configMessage.minHeightReqDataView;
        tripsComponent.accordion.pid_view = tripsComponent.accordion.isHavingEnoughSpace;
      }
      const params = (tripsComponent.accordion.isHavingEnoughSpace ? (tripsComponent.tripViewToggle ? (tripsComponent.accordion.trip_view ? ['trip_expand', tripsComponent.accordion.pid_view ? 'data_expand' : 'data_collapse'] : ['trip_collapse', tripsComponent.accordion.pid_view ? 'data_expand' : 'data_collapse']) : (tripsComponent.accordion.msg_view ? ['msg_expand', tripsComponent.accordion.pid_view ? 'data_expand' : 'data_collapse'] : ['msg_collapse', tripsComponent.accordion.pid_view ? 'data_expand' : 'data_collapse'])) : (tripsComponent.accordion.trip_view ? 1 : tripsComponent.accordion.msg_view ? 2 : 3));
      global.sharedService.setOBDHeight(window.innerWidth, window.innerHeight, tripsComponent.accordion.isHavingEnoughSpace, params, tripsComponent.graphContainerParams ? tripsComponent.graphContainerParams.tabPos : 0);
    });
    const attributes = true;
    const childList = true;
    const subtree = true;
    this.observer.observe(el, { attributes, childList, subtree });
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }

}