import { PipeTransform } from '@angular/core';
var EllipsesPipe = /** @class */ (function () {
    function EllipsesPipe() {
    }
    EllipsesPipe.prototype.transform = function (val, args) {
        if (args === undefined) {
            return val;
        }
        if (val.length > args) {
            return val.substring(0, args) + '...';
        }
        else {
            return val;
        }
    };
    return EllipsesPipe;
}());
export { EllipsesPipe };
