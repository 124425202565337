import { OnInit } from '@angular/core';
import { SharedService } from '@shared/shared.service';
import { Title } from '@angular/platform-browser';
import { environment } from '@myenv/environment';
import { GraphicReportsService } from '@components/graphic-reports/graphic-reports.service';
var AlPlantModeReportComponent = /** @class */ (function () {
    function AlPlantModeReportComponent(reportsService, sharedService, title) {
        this.reportsService = reportsService;
        this.sharedService = sharedService;
        this.title = title;
        this.apiUrl = environment.apiUrl;
        this.reportsMenu = [];
        this.currentReport = {};
        this.isLoading = false;
        this.columMenu = [];
        this.reportId = null;
        this.report = [];
        this.disable = false;
        this.permissionModules = [];
        this.modules = [];
        this.permissionAuthorities = {};
        this.moduleName = 'REPORT';
    }
    AlPlantModeReportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.modules = JSON.parse(localStorage.getItem('accessRoleModules'));
        this.permissionAuthorities = this.modules.find(function (module) { return module.name === _this.moduleName; });
        this.permissionModules = JSON.parse(localStorage.getItem('permissionModules'));
        this.title.setTitle('Reports' + environment.title_text);
        this.setReport();
    };
    AlPlantModeReportComponent.prototype.getReport = function (report) {
        this.currentReport = report;
        this.setReport();
    };
    AlPlantModeReportComponent.prototype.setReport = function () {
        if (this.currentReport && this.currentReport.reportId) {
            localStorage.setItem('currentReport', JSON.stringify(this.currentReport));
            this.report.reportId = this.currentReport.reportId;
        }
        else {
            if (JSON.parse(localStorage.getItem('currentReport'))) {
                var localReport = JSON.parse(localStorage.getItem('currentReport'));
                if (localReport.downloadable) {
                    this.currentReport = localReport;
                    this.report.reportId = localReport.reportId;
                }
                else {
                    var reports = JSON.parse(localStorage.getItem('reports'));
                    var global_1 = this;
                    reports.forEach(function (item) {
                        if (item.downloadable && !global_1.currentReport.reportId) {
                            global_1.currentReport = item;
                        }
                    });
                    localStorage.setItem('currentReport', JSON.stringify(this.currentReport));
                }
            }
        }
        this.columMenu = this.currentReport.columns;
        this.title.setTitle(this.currentReport.name + environment.title_text);
    };
    // To download report as a CSV File
    AlPlantModeReportComponent.prototype.downloadReportCSV = function () {
        var _this = this;
        this.disable = true;
        this.reportsService.downloadalPlantModeReport(this.currentReport.reportId).subscribe(function (res) {
            var win = window.open(_this.apiUrl + 'reports/alPlantMode/download?access_key=' + res.token, '_blank');
            win.focus();
            _this.disable = false;
        }, function (error) {
            _this.sharedService.getErrorMsg(error);
            _this.disable = false;
        });
    };
    return AlPlantModeReportComponent;
}());
export { AlPlantModeReportComponent };
