<div class="btn-group btn-container ng-select-row" role="group" aria-label="Basic example">
  <div class="ng-select-searchby relative btn btn-custom p-0" role="button" *ngIf="selectSearchCategory">
    <i class="fa fa-list-ul pr-1 ng-select-icon"></i>
    <ng-select
      #searchBySelect
      class="text-left"
      placeholder="Select Category"
      bindLabel="displayName"
      bindValue="key"
      [items]="categories"
      [clearable]="false"
      [searchable]="false"
      [(ngModel)]="searchBy"
      >
    </ng-select>
  </div>
  <div class="ng-select-device relative btn btn-custom p-0" role="button">
    <i *ngIf="selectedDevices.assetType" class="fa fa-bus pr-1 ng-select-icon"></i>
    <i *ngIf="!selectedDevices.assetType || selectedDevices === 'dataLogger'" class="fa fa-microchip pr-1 ng-select-icon"></i>
    <ng-select
      #select
      class="text-left"
      placeholder="Select Device"
      typeToSearchText="Please enter 1 or more characters"
      [items]="devices"
      [addTag]="false"
      [multiple]="isMulti"
      [hideSelected]="false"
      [closeOnSelect]="closeOnSelect"
      [trackByFn]="trackByFn"
      [minTermLength]="0"
      [loading]="deviceLoading"
      [typeahead]="deviceInput$"
      [clearable]="clearable"
      [(ngModel)]="selectedDevices"
      (change)="selectDevice()"
      (open)="loadDevices()"
      (close)="select.blur(); select.focus(); deviceLoading=false"
      (paste)="onPaste($event)"
      (remove)="select.focus(); loadDevices(); pasted=false;"
      (clear)="loadDevices()"
      >
      <ng-template ng-label-tmp let-item="item" *ngIf="!isMulti">
        {{item[searchBy]}}
      </ng-template>
      <ng-template ng-label-tmp let-item="item" let-clear="clear" *ngIf="isMulti">
        <span class="ng-value-label">{{item[searchBy]}}</span>
        <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-index="index">
        <div class="row no-gutters align-items-center">
          <div class="col-1">
            <i *ngIf="item.assetType" class="fa fa-bus"></i>
            <i *ngIf="!item.assetType || item.assetType === 'dataLogger'" class="fa fa-microchip"></i>
          </div>
          <div class="col-11">
            <span>{{item[searchBy]}}&nbsp;</span>
            <span [class]="'badge badge-' + getLastKnownStatus(item.lastKnownStatus)">
              {{getLastKnownStatus(item.lastKnownStatus)}}
            </span>
            <div>
              <small *ngIf="item.lastCommunication">
                {{item.lastCommunication | date : dateFormat}} ({{item.lastCommunicationString}})
              </small>
            </div>
            <div *ngIf="item.simStatus">
              <span>
                <small>Sim Status: </small>
                <small class="badge badge-primary" [tooltip]="'Sim Status'" placement="top">{{item.simStatus}}</small>
              </span>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-select>
  </div>
</div>
