
import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';

import {environment} from '@myenv/environment';
import {HttpClient, HttpHeaders, HttpBackend} from '@angular/common/http';


@Injectable()
export class DestinationsService {
  public apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getMessageCount(vid, startTime, endTime): Observable<any> {
    return this.http.get(this.apiUrl + 'ups/deviceId/' + vid + '/count?startTime=' + startTime + '&endTime=' + endTime);
  }

  getKafkaLatency(vid, startTime, endTime, page): Observable<any> {
    return this.http.get(this.apiUrl + 'ups/latency?deviceId=' + vid + '&startTime=' + startTime + '&endTime=' + endTime + '&page=' + page + '&size=100');
  }

  getReports(vin, startTime, endTime): Observable<any> {
// tslint:disable-next-line: max-line-length
    return this.http.get(this.apiUrl + 'messages/vin/' + vin + '?attributes=eventTime,latitude,longitude,address&startTime=' + startTime + '&endTime' + endTime).pipe(
      catchError(this.handleError));
  }

  getSingleReportByReportId(reportId, deviceId, startTime, endTime, page): Observable<any> {
// tslint:disable-next-line: max-line-length
    return this.http.get(this.apiUrl + 'reports/' + reportId + '/generate?startTime=' + startTime + '&endTime=' + endTime + '&page=' + page + '&deviceId=' + deviceId).pipe(
      catchError(this.handleError));
  }

  getCorelationChartData(reportId, deviceId, startTime, endTime, data): Observable<any> {
    const httpOptions = {
      'responseType'  : 'arraybuffer' as 'json'
      // 'responseType'  : 'blob' as 'json'
      // This also worked
    };
    return this.http.post(this.apiUrl + 'reports/' + reportId + '/dataScience/download?startTime=' + startTime + '&endTime=' + endTime + '&deviceId=' + deviceId, data, {responseType: "blob"}).pipe(
      catchError(this.handleError));
  }

  getChartData(reportId, deviceId, startTime, endTime, page, limit, data): Observable<any> {
// tslint:disable-next-line: max-line-length
    return this.http.get(this.apiUrl + 'messages/device/' + deviceId + '?startTime=' + startTime + '&endTime=' + endTime + '&page=' + page + '&limit=' + limit + '&attributes=' + data + ',eventTime').pipe(
      catchError(this.handleError));
  }

  downloadReportCSV(reportId, deviceId, startTime, endTime): Observable<any> {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization':'Bearer '+token
    });
// tslint:disable-next-line: max-line-length
    return this.http.post(this.apiUrl + 'reports/' + reportId + '/download/token?startTime=' + startTime + '&endTime=' + endTime + '&deviceId=' + deviceId, {headers}).pipe(
      catchError(this.handleError));
  }

  getAttribute(data): Observable<any> {
    return this.http.get(this.apiUrl + 'reports/attribute/' + data).pipe(
      catchError(this.handleError))
  }

  handleError(error: any | any) {
    return observableThrowError(error.error || error.json() || error || 'Server error');
  }

}
