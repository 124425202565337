import { throwError as observableThrowError, BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '@myenv/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
var ToolsService = /** @class */ (function () {
    function ToolsService(http) {
        this.http = http;
        this.apiUrl = environment.apiUrl;
        // private httpClient: HttpClient;
        this.sideData = [
            {
                name: 'menu.CACHE',
                img: 'cubes',
                route: '/tools/cache'
            },
            {
                name: 'menu.REPUBLISH',
                img: 'paper-plane-o',
                route: '/tools/republish'
            }
            // {
            //   name : 'menu.MOVE',
            //   img: 'arrows',
            //   route: '/tools/move'
            // }
        ];
        this.messageSource = new BehaviorSubject(this.sideData);
        this.currentMessage = this.messageSource.asObservable();
    }
    // To rebuild cache
    ToolsService.prototype.getRebuildCache = function (serialNumber) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
        return this.http.get(this.apiUrl + 'devices/cache?serialNumber=' + serialNumber, { headers: headers }).pipe(catchError(this.handleError));
    };
    // To invalidate cache
    ToolsService.prototype.invalidateCache = function (serialNumber) {
        return this.http.delete(this.apiUrl + 'devices/cache?serialNumber=' + serialNumber).pipe(catchError(this.handleError));
    };
    // To Republish cache
    ToolsService.prototype.republishCache = function (cache, startTime, endTime) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        if (cache.messageType) {
            return this.http.post(this.apiUrl + "messages/publish?deviceId=" + cache.deviceId + "&messageType=" + cache.messageType + "&startTime=" + startTime + "&endTime=" + endTime, {}, { headers: headers }).pipe(catchError(this.handleError));
        }
        if (!cache.messageType) {
            return this.http.post(this.apiUrl + "messages/publish?deviceId=" + cache.deviceId + "&startTime=" + startTime + "&endTime=" + endTime, {}, { headers: headers }).pipe(catchError(this.handleError));
        }
    };
    ToolsService.prototype.getCustomerDevices = function (page, customerId, serialNumber) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'CUSTOM_X_LCM': 'true'
        });
        var tempUrl = this.apiUrl + ("devices/customer/" + customerId + "?page=" + page);
        if (serialNumber) {
            tempUrl += "&serialNumber=" + serialNumber;
        }
        return this.http.get(tempUrl, { headers: headers }).pipe(catchError(this.handleError));
    };
    ToolsService.prototype.shiftDevices = function (payload) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
        return this.http.post(this.apiUrl + "devices/shift", payload, { headers: headers }).pipe(catchError(this.handleError));
    };
    // To handle errors
    ToolsService.prototype.handleError = function (error) {
        console.log(error.error || error.json() || error);
        return observableThrowError(error.error || error.json() || error || 'Server error');
    };
    return ToolsService;
}());
export { ToolsService };
