
import { throwError as observableThrowError, Observable, BehaviorSubject, Subject } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '@myenv/environment';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class LiveService {

  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  public data$: BehaviorSubject<any> = new BehaviorSubject({});
  public apiUrl = environment.apiUrl;

  constructor(private http: HttpClient, private router: Router) {
  }

  // To get all vehicle messages
  getAllVehicleMessage(page, limit, data): Observable<any> {
    return this.http.get(this.apiUrl + 'messages/latest?page=' + page + '&limit=' + limit + '&&attributes=' + data).pipe(
      catchError(this.handleError));
  }

  getSnappedVehicle(deviceId, attributes?): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    let tempUrl = `messages/device/${deviceId}/snapped`;
    if (attributes) {
      tempUrl += `?attributes=${attributes}`;
    }
    return this.http.get(this.apiUrl + tempUrl, { headers }).pipe(
      catchError(this.handleError));
  }

  // To search a vehicle
  // searchVehicle(assetName) {
  //
  //   // tslint:disable-next-line:max-line-length
  //   return this.http.get(this.apiUrl + 'messages/search/'+assetName+'/snapped')
  //     .catch(this.handleError);
  // }
  // Search and filter api merged
  searchVehicle(messageType, assetName, searchKey, groupId, key?, placeCords?): Observable<any> {
    let tempURL = this.apiUrl + 'messages/latest/search?limit=3000';
    // tslint:disable-next-line:max-line-length
    if (key['key'] && key['value']) {
      tempURL += '&thresholdKey=' + key['key'] + '&thresholdValue=' + key['value'];
    }
    if (key['placeKey'] && key['placeValue']) {
      tempURL += '&' + key['placeKey'] + '=' + key['placeValue'];
    }
    if (messageType) {
      tempURL += '&status=' + messageType;
    }
    if (assetName) {
      tempURL += `&searchKey=${searchKey}&searchValue=${assetName}`;
    }
    if (groupId) {
      tempURL += '&groups=' + groupId;
    }
    if (placeCords && placeCords.circle.newCenter.lat != null) {
      tempURL += `&latitude=${placeCords.circle.newCenter.lat}&longitude=${placeCords.circle.newCenter.lng}&radius=${placeCords.circle.radius}`;
    }
    return this.http.get(tempURL).pipe(
      catchError(this.handleError));

    // else {
    // return this.http.get(this.apiUrl+'messages/snapped/search?messageType='+messageType+'&assetName='+assetName+'&limit=3000')
    //     .catch(this.handleError);
    // }
  }

  getallMessages(deviceId, startTime, endTime, attrubute1, attribute2?): Observable<any> {
    let tempUrl = `&attributes=eventTime,${attrubute1}&messageType=TripData&limit=3000`;
    if (attribute2) {
      tempUrl += `,${attribute2}`;
    }
    return this.http
      .get(
        this.apiUrl +
        'messages/device/' +
        deviceId +
        '?startTime=' +
        startTime +
        '&endTime=' +
        endTime + tempUrl
      ).pipe(
        catchError(this.handleError));
  }

  getSupportedPids(deviceId, startTime, endTime): Observable<any> {
    return this.http.get(this.apiUrl + 'messages/device/' + deviceId + '/trip/supported/pids?startTime=' + startTime + '&endTime=' + endTime)
      .pipe(catchError(this.handleError));
  }

  getOBD_GPS_Messages(deviceId, startTime, endTime, params): Observable<any> {
    return this.http.get(this.apiUrl + 'messages/device/' + deviceId + `?messageType=GPS_MESSAGE&page=0&attributes=eventTime,${params}&startTime=` + startTime + '&endTime=' + endTime + '&limit=5000')
      .pipe(catchError(this.handleError));
  }

  getVehicleDetail(deviceId): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http.get(this.apiUrl + `devices?key=deviceId&value=${deviceId}`).pipe(
      catchError(this.handleError));
  }

  // To filter the vehicles based on selected filter
  // messageFilter(filterItem) {
  //
  //   // tslint:disable-next-line:max-line-length
  //   return this.http.get(this.apiUrl + 'messages/snapped/searchBy/messageType/' + filterItem)
  //     .catch(this.handleError);
  // }

  // To filter the vehicles based on speed
  speedFilter(data): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http.get(this.apiUrl + 'messages/latest/searchBy?key=' + data.key + '&value=' + data.value).pipe(
      catchError(this.handleError));
  }

  // To get the alerts of vehicles
  getAlerts(deviceId, startTime, endTime): Observable<any> {

    // tslint:disable-next-line:max-line-length
    return this.http.get(this.apiUrl + 'alerts/device/' + deviceId + '/events/count?startTime=' + startTime + '&endTime=' + endTime).pipe(
      catchError(this.handleError));
  }

  // To get single vehicle info
  // getSingleVehicleMessage(deviceId, page, data, startTime, endTime, pagelimit, messageType?): Observable<any> {
  //   // tslint:disable-next-line:max-line-length
  //   return this.http.get(this.apiUrl + 'messages/vehicle/' + deviceId + '?page=' + page + '&attributes=' + data + '&startTime=' + startTime + '&endTime=' + endTime + '&limit=' + pagelimit).pipe(
  //     catchError(this.handleError));
  // }

  // To get single vehicle info
  getSingleVehicleMessage(deviceId, page, data, startTime, endTime, pagelimit, messageType?): Observable<any> {
    // tslint:disable-next-line:max-line-length
    let tempUrl = `messages/device/${deviceId}?page=${page}&attributes=${data}&startTime=${startTime}&endTime=${endTime}&limit=${pagelimit}`;

    if (messageType) {
      tempUrl = tempUrl + `&messageType=${messageType}`;
    }

    return this.http.get(this.apiUrl + tempUrl).pipe(
      catchError(this.handleError));
  }

  // To get single vehicle info for last 20 messages
  getSingleVehicleLastMessage(deviceId, page, pagelimit): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http.get(this.apiUrl + 'messages/device/' + deviceId + '?page=' + page + '&limit=' + pagelimit).pipe(
      catchError(this.handleError));
  }

  // To get single vehicle info for last 20 messages
  getBatteryEvent(deviceId): Observable<any> {
    return this.http.get(this.apiUrl + `analytics/device/${deviceId}/batteryevent`)
      .pipe(catchError(this.handleError));
  }

  // To get single vehicle info for last 20 messages
  getvPacket(deviceId, startTime, endTime): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http.get(this.apiUrl + `analytics/vPacket/deviceId/${deviceId}?startDate=${startTime}&endDate=${endTime}`).pipe(
      catchError(this.handleError));
  }

  // To get place filred vehicle info
  getfilteredPlaces(deviceId, page, data, startTime, endTime, pagelimit): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http.get(this.apiUrl + 'places/events/device/' + deviceId + '?startTime=' + startTime + '&endTime=' + endTime + '&page=' + page + '&limit=' + pagelimit).pipe(
      catchError(this.handleError));
  }

  // To get the polyline data for a vehicle
  getPolylineData(deviceId, startTime, endTime): Observable<any> {
    return this.http.get(this.apiUrl + 'messages/device/' + deviceId + '/sampled-location?startTime=' + startTime + '&endTime=' + endTime).pipe(
      catchError(this.handleError));
  }

  // To get the total History count
  getTestCount(deviceId, startTime, endTime, page, pagelimit, messageType?): Observable<any> {
    let tempUrl = `${this.apiUrl}` + 'messages/device/' + deviceId + '/count?startTime=' + startTime + '&endTime=' + endTime + '&page=' + page + '&limit=' + pagelimit;
    if (messageType) {
      tempUrl += `&messageType=${messageType}`;
    }
    return this.http.get(tempUrl).pipe(catchError(this.handleError));
  }

  // To get the packet History count
  getPacketCount(deviceId, startTime, endTime, messageType?): Observable<any> {
    let tempUrl = `${this.apiUrl}` + `messages/device/${deviceId}/count?startTime=${startTime}&endTime=${endTime}`;
    if (messageType) {
      tempUrl += `&messageType=${messageType}`;
    }
    return this.http.get(tempUrl).pipe(catchError(this.handleError));
  }

  // To get the places filter count
  getPlcesFilterCount(deviceId, startTime, endTime, page, pagelimit): Observable<any> {
    return this.http.get(this.apiUrl + 'places/events/device/' + deviceId + '/count?startTime=' + startTime + '&endTime=' + endTime + '&page=' + page + '&limit=' + pagelimit).pipe(
      catchError(this.handleError));
  }

  getVehicleCount(): Observable<any> {
    return this.http.get(this.apiUrl + 'messages/latest/count').pipe(
      catchError(this.handleError));
  }

  // To handle errors
  handleError(error: any | any) {
    return observableThrowError(error.error || error.json() || error || 'Server error');
  }

  getVehicleLocation(deviceId): Observable<any> {
    return this.http.get(this.apiUrl + 'messages/device/' + deviceId + '/latest').pipe(
      catchError(this.handleError));
  }
  getVehicleEvents(deviceId): Observable<any> {
    return this.http.get(this.apiUrl + 'messages/device/' + deviceId + '/snapped').pipe(
      catchError(this.handleError));
  }


  keepTrackingVehicle(device): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${device.token}`,
      'Content-Type': 'application/json'
    });
    return this.http.get(this.apiUrl + 'messages/device/' + device.deviceId + '/latest', { headers }).pipe(
      catchError(this.handleError));
  }

  getTrackingToken(key): Observable<any> {
    return this.http.get(this.apiUrl + `messages/track/key/${key}`).pipe(
      catchError(this.handleError));
  }

  trackVehicle(payload): Observable<any> {
    return this.http.post(
      this.apiUrl + `messages/track`, payload
    )
      .pipe(catchError(this.handleError));
  }

  // for trips
  getTrips(deviceId, endTime, startTime, sort): Observable<any> {
    return this.http.get(this.apiUrl + `trips/device/${deviceId}?startTime=${startTime}&endTime=${endTime}&sort=${sort}`);
  }

  getTripsPolylineData(deviceId, startTime, endTime): Observable<any> {
    return this.http
      .get(
        this.apiUrl +
        `messages/valid/device/${deviceId}` +
        `?attributes=eventTime,latitude,longitude,messageType,messageId,` +
        `maxSpeed,avgSpeed,initialSpeed,finalSpeed,maxBraking,initialSpeed,finalSpeed,maxAcceleration` +
        `&startTime=${startTime}&endTime=${endTime}&limit=10000`
      ).pipe(
        catchError(this.handleError));
  }

  downloadTripSummary(deviceId, startTime, endTime): Observable<any> {
    return this.http.get(
      this.apiUrl +
      `trips/device/${deviceId}/download?startTime=${startTime}&endTime=${endTime}`,
      { responseType: 'arraybuffer', observe: 'response' }
    )
      .pipe(catchError(this.handleError));
  }

  downloadLiveSelectedDevices(messageType, assetName, searchKey, groupId, key?, placeCords?): Observable<any> {
    let tempURL = this.apiUrl + 'messages/latest/download';
    if (key['key'] && key['value']) {
      const char = tempURL.includes('?') ? '&' : '?';
      tempURL += `${char}thresholdKey=${key['key']}&thresholdValue=${key['value']}`;
    }
    if (key['placeKey'] && key['placeValue']) {
      const char = tempURL.includes('?') ? '&' : '?';
      tempURL += `${char}${key['placeKey']}=${key['placeValue']}`;
    }
    if (messageType) {
      const char = tempURL.includes('?') ? '&' : '?';
      tempURL += `${char}status=${messageType}`;
    }
    if (assetName) {
      const char = tempURL.includes('?') ? '&' : '?';
      tempURL += `${char}searchKey=${searchKey}&searchValue=${assetName}`;
    }
    if (groupId) {
      const char = tempURL.includes('?') ? '&' : '?';
      tempURL += `${char}groups=${groupId}`;
    }
    if (placeCords && placeCords.circle.newCenter.lat != null) {
      const char = tempURL.includes('?') ? '&' : '?';
      tempURL += `${char}latitude=${placeCords.circle.newCenter.lat}&longitude=${placeCords.circle.newCenter.lng}&radius=${placeCords.circle.radius}`;
    }
    return this.http.get(tempURL, { responseType: 'arraybuffer', observe: 'response' }).pipe(catchError(this.handleError));
  }

  getVehicleHealthAndFuelScores(vin): Observable<any> {
    return this.http.get(this.apiUrl + 'analyticsProxy/dashboard/vehicleHealthAndFuelScore?vin=' + vin).pipe(
      catchError(this.handleError));
  }

}
