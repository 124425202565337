<app-notify-message></app-notify-message>
<div class="row users-body">

<div class="col-md-12 col-lg-2 pr-0 sidebar-shadow">
  <app-side-menu [sideData]="message"></app-side-menu>
</div>
<div  class="col-md-12 col-sm-12 col-lg-10 pl-0 fullheight main-padding md-main-padding">
  <section id="main_content" class="bg">
 <div class="col-12">
  <div class="row col-12 marketplace-container">
    <div class="col-2 marketplace-image">
      <img src="../../../../assets/img/marketplace/NHTSA.png" width="80" height="80">
    </div>
    <div class="col-8">
      <h5 class="mb-0">NHTSA</h5>
      <span class="marketplace-text">National Highway Traffic Safety Administration is an agency of the U.S. federal government, part of the Department of Transportation with a mission to "Save lives, prevent injuries, reduce vehicle-related crashes"</span>
    </div>
    <div class="col-2">
      <span class="fa fa-check border border-success marketplace-btn">
        <span class="marketplace-text">&nbsp;&nbsp;ENABLED</span>
      </span>
    </div>
  </div>
</div>
<div class="col-12">
  <div class="row col-12 marketplace-container">
    <div class="col-2 marketplace-image">
      <img src="../../../../assets/img/marketplace/danlaw_veco.png" width="80" height="80">
    </div>
    <div class="col-8">
      <h5 class="mb-0">Danlaw VECO</h5>
      <span class="marketplace-text">Danlaw's Vehicle Compatibility (VECO) services allow you to decode a VIN and find out the OBD Device compatibility with your vehicles.</span>
    </div>
    <div class="col-2">
      <span class="fa fa-check border border-success marketplace-btn">
        <span class="marketplace-text">&nbsp;&nbsp;ENABLED</span>
      </span>
    </div>
  </div>
</div>
<div class="col-12">
  <div class="row col-12 marketplace-container">
    <div class="col-2 marketplace-image">
      <img src="../../../../assets/img/marketplace/at_t.png" width="80" height="80">
    </div>
    <div class="col-8">
      <h5 class="mb-0">AT&T</h5>
      <span class="marketplace-text">AT&T is the world's largest telecommunications company and the largest provider of mobile telephone services in the U.S.</span>
    </div>
    <div class="col-2">
      <span class="fa fa-check border border-success marketplace-btn">
        <span class="marketplace-text">&nbsp;&nbsp;ENABLED</span>
      </span>
    </div>
  </div>
</div>
<div class="col-12">
  <div class="row col-12 marketplace-container">
    <div class="col-2 marketplace-image">
      <img src="../../../../assets/img/marketplace/bird_analytics.png" width="80" height="80">
    </div>
    <div class="col-8">
      <h5 class="mb-0">BIRD Analytics</h5>
      <span class="marketplace-text">BIRD Analytics is a user focused platform designed to acquire data from ANY source and transform it into decision-making and usable insights.</span>
    </div>
    <div class="col-2">
      <span class="fa fa-check border border-success marketplace-btn">
        <span class="marketplace-text">&nbsp;&nbsp;ENABLED</span>
      </span>
    </div>
  </div>
</div>
<div class="col-12">
  <div class="row col-12 marketplace-container">
    <div class="col-2 marketplace-image">
      <img src="../../../../assets/img/marketplace/danlaw_data_science.png" width="80" height="80">
    </div>
    <div class="col-8">
      <h5 class="mb-0">Danlaw Data Science</h5>
      <span class="marketplace-text">Danlaw Data Science provides pre-configured advanced analytics APIs for Driver Risk Scoring, Insurance Scoring, Fuel Economy Scoring, Vehicle Health Scoring, EV Battery Health Scoring, Crash Classification and Road Surface Classification.</span>
    </div>
    <div class="col-2">
      <span class="fa fa-check border border-success marketplace-btn">
        <span class="marketplace-text">&nbsp;&nbsp;ENABLED</span>
      </span>
    </div>
  </div>
</div>
</section>
</div>
</div>