import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { NgbDate, NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from '../shared.service';
import { environment } from '@myenv/environment';
declare var $: any;
// const now = new Date();

@Component({
  selector: 'app-date-time-calendar',
  templateUrl: './date-time-calendar.component.html',
  styleUrls: ['./date-time-calendar.component.scss']
})
export class DateTimeCalendarComponent implements OnInit, OnChanges, OnDestroy {
  @Output() timeChangeEvent = new EventEmitter();
  @Input() selectPreset: string;
  @Input() maxRangeDays: number;
  @Input() hideLinks: string[] = [];
  @Input() autoselectEndDate: boolean;
  @Input() clickOutside: boolean;
  @Input() timeSelection = true;
  @Input() validateHours: number;
  @Input() vehicle: string;
  @Input() singlySelectedVehicle: boolean;
  @Input() device: string;
  @Input() displayEventTime: { start, end };
  // variable created for message view in DataHub Visualization
  // as it supports only last 24hrs
  @Input() messageView = false;
  public dateFormat = environment.noSecondsTimeFormat;
  showPicker = false;
  displayText = '';
  displayDates = {
    start: null,
    end: null
  };
  invalidDateTime = false;
  errorText = '';
  customSelected = {
    startDate: null,
    endDate: null,
    startTime: { hour: 0, minute: 0 },
    endTime: { hour: 23, minute: 59 }
  };

  // model: NgbDateStruct;
  // date: Date;
  hoveredDate: NgbDate;

  fromDate: NgbDate;
  toDate: NgbDate;
  maxRange: NgbDate;

  fromtime = { hour: 0, minute: 0 };
  totime = { hour: 23, minute: 59 };
  date = new Date();
  today = {
    year: this.date.getFullYear(),
    month: this.date.getMonth() + 1,
    day: this.date.getDate()
  };
  meridian = true;
  // eventDates: any = [];
  // tripDates: any = [];
  eventClass: any = {};
  navigating = false;
  disableNavigation = false;
  subscribedReqs = [];
  showLoadingIcon = true;
  loading = {
    start: null,
    buff: [],
    end: null,
    dateRanges: {}
  };

  constructor(
    private calendar: NgbCalendar,
    private sharedService: SharedService
  ) {
    this.fromDate = calendar.getToday();
  }

  ngOnInit() {
    if (this.selectPreset) {
      if (this.selectPreset === 'eventTime') {
        this.displayText = 'Event Time';
        this.displayDates = this.displayEventTime;
      } else {
        this.onTimeChange(this.selectPreset);
      }
    } else { this.onTimeChange('month'); }
  }

  ngOnChanges(changes: SimpleChanges) {
    // setting value for hiding overlay
    if (changes.clickOutside && changes.clickOutside.previousValue !== undefined) {
      if (changes.clickOutside.previousValue !== changes.clickOutside.currentValue) {
        this.closePicker();
      }
    }
    // setting display date
    if (changes.displayEventTime && changes.displayEventTime.previousValue !== undefined) {
      if (changes.displayEventTime.currentValue !== null &&
        (changes.displayEventTime.previousValue !== changes.displayEventTime.currentValue)
      ) {
        this.displayDates = this.displayEventTime;
        if (this.displayDates.start && this.displayDates.end) {
          const startDate = new Date(this.displayDates.start);
          const endDate = new Date(this.displayDates.end);
          this.fromDate = new NgbDate(startDate.getFullYear(), (startDate.getMonth() + 1), (startDate.getDate()));
          this.toDate = new NgbDate(endDate.getFullYear(), (endDate.getMonth() + 1), (endDate.getDate()));
        }
        // this.displayText = 'Event Time';
      }
    }
    // setting vehicle on change
    if (changes.vehicle && changes.vehicle.previousValue !== undefined) {
      if (changes.vehicle.previousValue !== changes.vehicle.currentValue) {
        this.getVehicleEventDates(true);
      }
    }
    // for vehicle selected onload of component
    else if (changes.vehicle && this.singlySelectedVehicle) {
      if (changes.vehicle.currentValue) {
        this.getVehicleEventDates(true);
      }
    }
    // setting device on change
    if (changes.device && changes.device.previousValue !== undefined) {
      if (changes.device.previousValue !== changes.device.currentValue) {
        this.getVehicleEventDates(true);
      }
    }
  }

  ngOnDestroy() {
    this.hideLinks = [];
    this.vehicle = null;
    this.singlySelectedVehicle = false;
    this.device = null;
    this.resetLoadingIcon();
  }

  getVehicleEventDates(reset) {
    if (reset) { this.resetLoadingIcon(); }
    if (this.toDate) {
      // else { loadingRange = new Date(this.loading.start); this.loading.end = this.loading.buff[0]; }
      // this.loading.start = loadingRange.setDate(loadingRange.getDate() - 60);
      // this.loading.start = new Date(this.loading.start).getDate() >= 2 ? new Date(new Date(new Date(this.loading.start).setDate(1)).setHours(0, 0, 0, 0)).getTime() : new Date(new Date(this.loading.start).setHours(0, 0, 0, 0)).getTime();
      // const start = new Date(this.loading.start);
      // start.setDate(start.getDate() - 1);
      const start = new Date(this.fromDate.year, (this.fromDate.month - 1), (this.fromDate.day >= 2 ? 1 : this.fromDate.day), 0, 0, 0, 0);
      const end = new Date(this.toDate.year, (this.toDate.month - 1), this.toDate.day, 23, 59, 59);
      this.loading.start = start.getTime();
      this.loading.end = end.getTime();
      const Difference_In_Time = this.loading.end - this.loading.start;
      // To calculate the no. of days between two dates
      const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      if (Difference_In_Days < 60) {
        start.setDate(1);
        this.sendConcurrentReq(new Date(start.getTime()), new Date());
      } else {
        this.sendConcurrentReq(start, end);
      }
    } else {
      this.sendLast60IndicatorReq();
    }
  }

  setLoadingIcon(start, end) {
    const Difference_In_Time = end - start;
    // To calculate the no. of days between two dates
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    const date1 = new Date(start);
    const arr = [];
    for (let i = 0; i < Difference_In_Days; i++) {
      const stringDate = this.getStringDate(date1);
      const e = this.loading.dateRanges[stringDate];
      if (e !== 'loaded') {
        this.loading.dateRanges[stringDate] = 'loading';
        arr.push(date1.getTime());
      }
      date1.setDate(date1.getDate() + 1);
    }
    return arr;
  }

  sendConcurrentReq(start, end) {
    const Difference_In_Time = end - start;
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    // this.setLoadingIcon(this.loading.start, this.loading.end);
    const loop = Math.floor(Difference_In_Days / 10);
    for (let i = 1; i <= loop; i++) {
      const reqStart = start.setDate(start.getDate());
      const reqEnd = i === loop ? end : start.setDate(start.getDate() + 9);
      this.getSpecificEvent(reqStart, this.getEOD(reqEnd));
      start.setDate(start.getDate() + 1)
    }
  }

  getSpecificEvent(start, end) {
    let start_end = [];
    if (this.vehicle) {
      // checking whether data is already loaded for selected device to reduce api calls
      start_end = this.setLoadingIcon(start, end);
    }
    if (start_end.length && this.vehicle) {
      const req = this.sharedService.getVehicleEventDates(this.vehicle, start_end[0], (start_end.length >= 2 ? this.getEOD(start_end[start_end.length - 1]) : this.getEOD(end))).subscribe({
        next: res => {
          // this.eventDates = res.noTrips;
          // this.tripDates = res.trips;
          this.assignEventClass(res);
          this.hideLoadingIcon(new Date(res.startTime), new Date(res.endTime));
          // this.sharedService.initDestroyTimeOutPace();
        },
        error: error => {
          this.sharedService.getErrorMsg(error);
          // this.sharedService.initDestroyTimeOutPace();
        }
      });
      this.subscribedReqs.push(req);
    }
  }

  getDeviceEventDates() {
    const d = new Date();
    const end = Date.parse(d.toUTCString());
    d.setMonth(d.getMonth() - 12);
    const start = Date.parse(new Date(d.getFullYear(), d.getMonth(), 1).toUTCString());
    this.sharedService.getDeviceEventDates(this.device, start, end).subscribe({
      next: res => {
        // this.eventDates = res.noTrips;
        // this.tripDates = res.trips;
        this.assignEventClass(res);
      },
      error: error => {
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  assignEventClass(res) {
    // this.eventClass = {};
    if (res.noTrips.length) {
      res.noTrips.map(item => {
        const i = new Date(item);
        const attr = this.getStringDate(i);
        this.eventClass[attr] = 'messageEvent';
      });
    }
    if (res.trips.length) {
      res.trips.map(item => {
        const i = new Date(item);
        const attr = this.getStringDate(i);
        this.eventClass[attr] = 'tripEvent';
      });
    }
  }

  getEOD(dateEpoch) {
    const date = new Date(dateEpoch);
    return date.setHours(23, 59, 59, 999);
  }

  // hide loading icon as we got response from api
  hideLoadingIcon(s, e) {
    // To calculate the time difference of two dates
    const Difference_In_Time = new Date(new Date(e).setHours(23, 59, 59, 999)).getTime() - new Date(new Date(s).setHours(0, 0, 0, 0)).getTime();
    // To calculate the no. of days between two dates
    const Difference_In_Days = Math.ceil(Difference_In_Time / (1000 * 3600 * 24));
    for (let i = 0; i < Difference_In_Days; i++) {
      const stringDate = this.getStringDate(s);
      this.loading.dateRanges[stringDate] = 'loaded';
      s.setDate(s.getDate() + 1);
    }
  }

  resetLoadingIcon() {
    this.eventClass = {};
    this.loading.dateRanges = {};
    this.loading.start = null;
    this.loading.end = null;
    this.subscribedReqs.map((e) => {
      e.unsubscribe();
    });
  }

  getRangeMinMax(date: NgbDateStruct) {
    const attr = `${date.year}-${date.month}-${date.day}`;
    return this.loading.dateRanges[attr] === 'loading' ? true : false;
  }

  getStringDate(i) {
    return `${i.getFullYear()}-${i.getMonth() + 1}-${i.getDate()}`;
  }

  togglePicker() {
    this.showPicker = !this.showPicker;
  }

  closePicker() {
    if (this.showPicker) {
      if (this.displayText === 'Custom') {
        this.fromDate = this.customSelected.startDate;
        this.toDate = this.customSelected.endDate;
        this.fromtime = this.customSelected.startTime;
        this.totime = this.customSelected.endTime;
      }
      // else {
      //   this.fromDate = this.calendar.getToday();
      //   this.toDate = null;
      //   this.fromtime = { hour: 0, minute: 0 };
      //   this.totime = { hour: 23, minute: 59 };
      // }
      this.showPicker = false;
    }
  }

  onTimeChange(e) {
    if (this.hideLinks.includes(e)) {
      return;
    }
    let start = null;
    let end = null;
    const t = new Date();
    if (e === 'today') {
      this.displayText = 'Today';
      start = Date.parse(new Date(t.getFullYear(), t.getMonth(), t.getDate(), 0, 0, 0).toUTCString());
      end = Date.parse(new Date(t.getFullYear(), t.getMonth(), t.getDate(), 23, 59, 59).toUTCString());
    } else if (e === 'week') {
      this.displayText = 'This Week';
      const first = t.getDate() - t.getDay() + 1;
      const today = new Date();
      const firstday = new Date(today.setDate(first));
      start = Date.parse(new Date(firstday.getFullYear(), firstday.getMonth(), firstday.getDate(), 0, 0, 0).toUTCString());
      end = Date.parse(new Date(t.getFullYear(), t.getMonth(), t.getDate(), 23, 59, 59).toUTCString());
    } else if (e === 'month') {
      this.displayText = 'This Month';
      start = Date.parse(new Date(t.getFullYear(), t.getMonth(), 1, 0, 0, 0).toUTCString());
      end = Date.parse(new Date(t.getFullYear(), t.getMonth(), t.getDate(), 23, 59, 59).toUTCString());
    } else if (e === 'todaysofar') {
      this.displayText = 'Today so Far';
      start = Date.parse(new Date(t.getFullYear(), t.getMonth(), t.getDate(), 0, 0, 0).toUTCString());
      end = Date.parse(t.toUTCString());
    } else if (e === 'weektodate') {
      this.displayText = 'Week to Date';
      const first = t.getDate() - t.getDay() + 1;
      const today = new Date();
      const firstday = new Date(today.setDate(first));
      start = Date.parse(new Date(firstday.getFullYear(), firstday.getMonth(), firstday.getDate(), 0, 0, 0).toUTCString());
      end = Date.parse(t.toUTCString());
    } else if (e === 'monthtodate') {
      this.displayText = 'Month to Date';
      start = Date.parse(new Date(t.getFullYear(), t.getMonth(), 1, 0, 0, 0).toUTCString());
      end = Date.parse(t.toUTCString());
    } else if (e === '15mins') {
      this.displayText = 'Last 15 Minutes';
      start = Date.parse(t.toUTCString()) - (15 * 60 * 1000);
      end = Date.parse(t.toUTCString());
    } else if (e === '30mins') {
      this.displayText = 'Last 30 Minutes';
      start = Date.parse(t.toUTCString()) - (30 * 60 * 1000);
      end = Date.parse(t.toUTCString());
    } else if (e === '1hour') {
      this.displayText = 'Last 1 Hour';
      start = Date.parse(t.toUTCString()) - (60 * 60 * 1000);
      end = Date.parse(t.toUTCString());
    } else if (e === '4hours') {
      this.displayText = 'Last 4 Hours';
      start = Date.parse(t.toUTCString()) - (60 * 4 * 60 * 1000);
      end = Date.parse(t.toUTCString());
    } else if (e === '12hours') {
      this.displayText = 'Last 12 Hours';
      start = Date.parse(t.toUTCString()) - (60 * 12 * 60 * 1000);
      end = Date.parse(t.toUTCString());
    } else if (e === '24hours') {
      this.displayText = 'Last 24 Hours';
      start = Date.parse(t.toUTCString()) - (60 * 24 * 60 * 1000);
      end = Date.parse(t.toUTCString());
    } else if (e === '7days') {
      this.displayText = 'Last 7 Days';
      t.setDate(t.getDate() - 7);
      start = Date.parse(t.toUTCString());
      end = Date.parse(new Date().toUTCString());
    } else if (e === '30days') {
      this.displayText = 'Last 30 Days';
      t.setDate(t.getDate() - 30);
      start = Date.parse(t.toUTCString());
      end = Date.parse(new Date().toUTCString());
    } else if (e === '90days') {
      this.displayText = 'Last 90 Days';
      t.setDate(t.getDate() - 90);
      start = Date.parse(t.toUTCString());
      end = Date.parse(new Date().toUTCString());
    } else if (e === '180days') {
      this.displayText = 'Last 180 Days';
      t.setDate(t.getDate() - 180);
      start = Date.parse(t.toUTCString());
      end = Date.parse(new Date().toUTCString());
    } else if (e === '365days') {
      this.displayText = 'Last 365 Days';
      t.setDate(t.getDate() - 365);
      start = Date.parse(t.toUTCString());
      end = Date.parse(new Date().toUTCString());
    }
    this.timeChangeEvent.emit({ startTime: start, endTime: end, e });
    this.displayDates = { start: start, end: end };
    this.showPicker = false;
    if (this.validateHours) {
      this.fromDate = this.calendar.getToday();
      this.toDate = null;
    } else {
      const startDate = new Date(start);
      const endDate = new Date(end);
      this.fromDate = new NgbDate(startDate.getFullYear(), (startDate.getMonth() + 1), (startDate.getDate()));
      this.toDate = new NgbDate(endDate.getFullYear(), (endDate.getMonth() + 1), (endDate.getDate()));
    }
    this.navigating = false;
    this.disableNavigation = true;
    // this.getVehicleEventDates(false);
    if (this.vehicle && e !== 'Today' && e !== 'week' && e !== 'month' && e !== 'todaysofar' && e !== 'weektodate' && e !== 'monthtodate' && e !== '15mins' && e !== '30mins' && e !== '1hour' && e !== '4hours' && e !== '12hours' && e !== '24hours' && e !== '7days' && e !== '30days') {
      const startIndicator = new Date(new Date(start).setHours(0, 0, 0, 0));
      this.loading.start = (startIndicator.getDate() >= 2 ? new Date(startIndicator.setDate(1)).getTime() : new Date(startIndicator).getTime());
      const endDate = new Date(this.loading.start);
      endDate.setMonth(endDate.getMonth() + 1);
      const getDaysInMonth = this.getDaysInMonth((endDate.getMonth() + 1), endDate.getFullYear());
      this.loading.end = new Date(endDate.setDate(getDaysInMonth));
      this.loading.end.setHours(23, 59, 59, 999);
      this.loading.end = this.loading.end.getTime();
      this.sendConcurrentReq(new Date(this.loading.start), new Date(this.loading.end));
    } else if (this.vehicle) {
      this.sendLast60IndicatorReq();
    }
  }

  getDaysInMonth(m, y) {
    return m === 2 ? y & 3 || !(y % 25) && y & 15 ? 28 : 29 : 30 + (m + (m >> 3) & 1);
  }

  sendLast60IndicatorReq() {
    const t = new Date();
    t.setDate(t.getDate() - 60);
    t.setDate(1);
    t.setHours(0, 0, 0, 0);
    let start = Date.parse(t.toUTCString());
    let end = Date.parse(new Date().toUTCString());
    this.loading.start = start;
    this.loading.end = end;
    this.sendConcurrentReq(new Date(start), new Date(end));
  }


  setCustomDate() {
    let start = null;
    let end = null;
    if (this.checkValidity()) {
      if (this.fromDate != null) {
        if (this.toDate != null) {
          start = Date.parse(new Date(
            this.fromDate.year, this.fromDate.month - 1, this.fromDate.day,
            this.fromtime.hour, this.fromtime.minute, 0
          ).toUTCString());
          end = Date.parse(new Date(
            this.toDate.year, this.toDate.month - 1, this.toDate.day,
            this.totime.hour, this.totime.minute, 59
          ).toUTCString());
        } else {
          start = Date.parse(new Date(
            this.fromDate.year, this.fromDate.month - 1, this.fromDate.day,
            this.fromtime.hour, this.fromtime.minute, 0
          ).toUTCString());
          end = Date.parse(new Date(
            this.fromDate.year, this.fromDate.month - 1, this.fromDate.day,
            this.totime.hour, this.totime.minute, 59
          ).toUTCString());
        }
      }
      this.displayText = 'Custom';
      this.displayDates = { start: start, end: end };
      this.customSelected.startDate = this.fromDate;
      this.customSelected.endDate = this.toDate;
      this.customSelected.startTime = this.fromtime;
      this.customSelected.endTime = this.totime;
      this.timeChangeEvent.emit({ startTime: start, endTime: end });
      this.showPicker = false;
      this.navigating = false;
      this.disableNavigation = end >= Date.parse(new Date().toUTCString()) ? true : false;
      this.getSpecificEvent(start, end);
    }
  }

  navigate(dir) {
    this.navigating = true;
    // this.displayDates.start += 60000;
    this.displayDates.end += 60000;
    const diff = (this.displayDates.end - this.displayDates.start);
    if (dir === 'left') {
      this.disableNavigation = false;
      this.displayDates.end = this.displayDates.start - 60000;
      this.displayDates.start -= (diff);
      this.timeChangeEvent.emit({ startTime: this.displayDates.start, endTime: this.displayDates.end });
    } else if (dir === 'right') {
      const start = this.displayDates.end + 60000;
      const end = this.displayDates.end + diff;
      if (end >= Date.parse(new Date().toUTCString())) {
        this.disableNavigation = true;
      } else {
        this.disableNavigation = false;
        this.displayDates.start = start;
        this.displayDates.end = end;
        this.timeChangeEvent.emit({ startTime: start, endTime: end });
      }
    }
    // Set date and time in calendar
    if (this.displayText === 'Custom') { }
  }

  checkValidity(): boolean {
    // Validation to time < from time for same day
    if (this.toDate === null && !this.validateHours) {
      if (
        this.totime.hour < this.fromtime.hour ||
        (this.totime.hour === this.fromtime.hour && this.totime.minute < this.fromtime.minute)
      ) {
        this.invalidDateTime = true;
        this.errorText = 'End time cannot be after Start time.';
        return false;
      } else {
        this.invalidDateTime = false;
        return true;
      }
    } else if (this.toDate === null && this.validateHours) {
      const hours = this.validateHours * 60 * 60 * 1000;
      const start = Date.parse(new Date(
        this.fromDate.year, this.fromDate.month - 1, this.fromDate.day, this.fromtime.hour, this.fromtime.minute, 0
      ).toUTCString());
      const end = Date.parse(new Date(
        this.fromDate.year, this.fromDate.month - 1, this.fromDate.day, this.totime.hour, this.totime.minute, 0
      ).toUTCString());
      const diff = end - start;
      if (diff <= hours) {
        this.invalidDateTime = false;
        return true;
      } else {
        this.invalidDateTime = true;
        this.errorText = `Please select maximum ${this.validateHours} Hours.`;
        return false;
      }
    } else {
      // Validation for custom hours between two dates
      if (this.validateHours) {
        const hours = this.validateHours * 60 * 60 * 1000;
        const start = Date.parse(new Date(
          this.fromDate.year, this.fromDate.month - 1, this.fromDate.day, this.fromtime.hour, this.fromtime.minute, 0
        ).toUTCString());
        const end = Date.parse(new Date(
          this.toDate.year, this.toDate.month - 1, this.toDate.day, this.totime.hour, this.totime.minute, 0
        ).toUTCString());
        const diff = end - start;
        if (diff <= hours) {
          this.invalidDateTime = false;
          return true;
        } else {
          this.invalidDateTime = true;
          this.errorText = `Please select maximum ${this.validateHours} Hours.`;
          return false;
        }
      } else {
        this.invalidDateTime = false;
        return true;
      }
    }
  }

  navigationOnMonth(p1) {
    const nextMonth = new Date(p1.next.year, (p1.next.month - 1), 1).getTime();
    const currentMonth = p1.current ? new Date(p1.current.year, (p1.current.month + 1), 1).getTime() : p1.current;
    const todayDate = new Date();
    if (p1.current && nextMonth < this.loading.start) {
      this.loading.buff.unshift(nextMonth);
      const t = new Date(this.loading.start);
      t.setDate(t.getDate() - 60);
      t.setDate(1);
      const start = Date.parse(t.toUTCString());
      const end = new Date(this.loading.start);
      end.setDate(end.getDate() - 1);
      end.setHours(23, 59, 59, 999);
      this.loading.start = start;
      this.sendConcurrentReq(new Date(start), end);
    } else if (p1.current && currentMonth < todayDate && this.loading.end < currentMonth) {
      this.loading.buff.push(currentMonth);
      const start = new Date(this.loading.end);
      start.setDate(start.getDate() + 1);
      start.setHours(0, 0, 0, 0);
      const t = new Date(start);
      t.setMonth(t.getMonth() + 1);
      const getDaysInMonth = this.getDaysInMonth((t.getMonth() + 1), t.getFullYear());
      this.loading.end = new Date(t.setDate(getDaysInMonth));
      this.loading.end.setHours(23, 59, 59, 999);
      this.loading.end = this.loading.end.getTime();
      this.sendConcurrentReq((start), new Date(this.loading.end));
    }
  }

  // isWeekend(date: NgbDateStruct) {
  //   const d = new Date(date.year, date.month - 1, date.day);
  //   return d.getDay() === 0 || d.getDay() === 6;
  // }

  // isDisabled(date: NgbDateStruct, current: { month: number }) {
  //   return date.month !== current.month;
  // }

  // hasMessages(date: NgbDateStruct) {
  //   return this.dateHasMessages(date);
  // }

  // hasTrips(date: NgbDateStruct) {
  //   return this.dateHasTrips(date);
  // }

  // showTasks(date: NgbDateStruct) {
  //   if (this.dateHasTask(date)) {
  //     // TODO show popup
  //     alert(date);
  //   }
  // }

  getEventClass(date: NgbDateStruct) {
    const attr = `${date.year}-${date.month}-${date.day}`;
    if (this.eventClass[attr]) {
      return this.eventClass[attr];
    } else {
      return 'noEvent';
    }
  }

  // dateHasMessages(date: NgbDateStruct): boolean {
  //   for (let i = 0; i <= this.eventDates.length; i++) {
  //     const td = new Date(this.eventDates[i]);
  //     const day: number = td.getDate();
  //     const month: number = td.getMonth() + 1;
  //     const year: number = td.getFullYear();
  //     if (day === date.day && month === date.month && year === date.year) {
  //       return true;
  //     }
  //   }
  // }

  // dateHasTrips(date: NgbDateStruct): boolean {
  //   for (let i = 0; i <= this.tripDates.length; i++) {
  //     const td = new Date(this.tripDates[i]);
  //     const day: number = td.getDate();
  //     const month: number = td.getMonth() + 1;
  //     const year: number = td.getFullYear();
  //     if (day === date.day && month === date.month && year === date.year) {
  //       return true;
  //     }
  //   }
  // }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.maxRange = this.setMaxRange(date);
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      if (date.before(this.maxRange)) {
        this.toDate = date;
      } else {
        this.toDate = this.maxRange;
      }
    } else {
      this.fromDate = date;
      this.maxRange = this.setMaxRange(date);
      if (this.autoselectEndDate && !(this.maxRange.equals(this.today) || this.maxRange.after(this.today))) {
        this.toDate = this.maxRange;
      } else {
        this.toDate = null;
      }
    }
  }

  setMaxRange(date: NgbDate): NgbDate {
    if (this.maxRangeDays) {
      return this.calendar.getNext(date, 'd', this.maxRangeDays);
    } else {
      return this.calendar.getNext(date, 'd', 30);
    }
  }

  isHovered(date: NgbDate) {
    if (date.before(this.today) && date.before(this.maxRange)) {
      return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
    }
  }

  isRange(date: NgbDate) {
    const l = date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
    return l;
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

}
