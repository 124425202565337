import { Component, OnInit } from '@angular/core';
import { SharedService } from '@myproject/shared/shared.service';
import { GraphicReportsService } from '../graphic-reports.service';
import { environment } from '@myenv/environment';
import { configMessage } from '@shared/config-message';
import { DatePipe } from '@angular/common';
import Chart from 'chart.js';
import * as FileSaver from 'file-saver';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-c-trigger-report',
  templateUrl: './c-trigger-report.component.html',
  styleUrls: ['./c-trigger-report.component.scss']
})
export class CTriggerReportComponent implements OnInit {

  public dateFormat = environment.isoDateTimeFormat;
  currentuser = JSON.parse(localStorage.getItem('currentUser'));
  requestType = 'C';
  hideCalendar = false;
  isLoading = false;
  startTime = null;
  endTime = null;
  deviceCounts = {
    all: 0,
    inProgress: 0,
    validationFailed: 0,
    success: 0,
    failed: 0
  };
  selectedStatus = 'all';
  selectedCountView = 'Monthly';
  displaySelectedStatus = 'All Devices';
  errorCountChart = null;
  avgTimeChart = null;
  requestCountChart = null;
  requestCountChartData = {
    labels: {
      daily: [],
      weekly: [],
      monthly: []
    },
    counts: {
      daily: [],
      weekly: [],
      monthly: []
    }
  };
  devices: any = [];
  page = 0;
  pageInfo = {
    offset: 0,
    count: 0,
    limit: 10,
    pageSize: 0,
    lastPage: 0,
    prevResponse: 0
  };
  currentPageCount: any = {
    start: 0,
    end: 0
  };
  totalErrorCount = 0;

  constructor(
    private title: Title,
    private sharedService: SharedService,
    private reportService: GraphicReportsService
  ) { }

  ngOnInit() {
    this.title.setTitle('C-Trigger Report' + environment.title_text);
    // this.getStatusCounts(this.startTime, this.endTime);
    // this.getProvisioningErrorCounts(this.startTime, this.endTime, this.selectedStatus);
    // this.onStatusChange(this.selectedStatus);
  }

  onTimeChange(e) {
    this.startTime = e.startTime;
    this.endTime = e.endTime;
    this.getStatusCounts(this.startTime, this.endTime);
    this.getProvisioningErrorCounts(this.startTime, this.endTime, this.selectedStatus);
    this.onStatusChange(this.selectedStatus);
  }

  refresh() {
    this.getStatusCounts(this.startTime, this.endTime);
    this.getProvisioningErrorCounts(this.startTime, this.endTime, this.selectedStatus);
    this.onStatusChange(this.selectedStatus);
  }

  onStatusChange(status) {
    this.page = 0;
    this.pageInfo.offset = 0;
    this.selectedStatus = status;
    if (status === 'inProgress') {
      this.displaySelectedStatus = 'Devices In Progress';
    } else if (status === 'validationfailed') {
      this.displaySelectedStatus = 'Validation Failed Devices';
    } else if (status === 'success') {
      this.displaySelectedStatus = 'Devices Succeeded';
    } else if (status === 'failed') {
      this.displaySelectedStatus = 'Devices Failed';
    } else {
      this.displaySelectedStatus = 'All Devices';
    }
    // this.getStatusCounts(this.startTime, this.endTime);
    this.getAvgTimeData(this.startTime, this.endTime, this.selectedStatus);
    this.getRequestCount(this.startTime, this.endTime, this.selectedStatus);
    this.getDevicesWithFilter(this.startTime, this.endTime, this.selectedStatus, this.page);
  }

  getStatusCounts(startTime, endTime) {
    this.reportService.getAllStatusCount(this.requestType, this.currentuser.customerId, startTime, endTime).subscribe({
      next: res => {
        this.deviceCounts = res;
      },
      error: error => {
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  getProvisioningErrorCounts(startTime, endTime, status) {
    this.reportService.getProvisioningErrorCounts(this.requestType, status, this.currentuser.customerId, startTime, endTime).subscribe({
      next: res => {
        this.drawErrorCountChart(res);
      },
      error: error => {
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  getAvgTimeData(startTime, endTime, status) {
    this.reportService.getAvgTimeTaken(this.requestType, status, this.currentuser.customerId, startTime, endTime).subscribe({
      next: res => {
        this.drawAvgTimeChart(res);
      },
      error: error => {
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  getRequestCount(startTime, endTime, status) {
    this.reportService.getRequestCounts(this.requestType, status, this.currentuser.customerId, startTime, endTime).subscribe({
      next: res => {
        this.sortRequestCountChartData(res);
        // this.drawRequestCountChart(res);
      },
      error: error => {
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  getDevicesWithFilter(startTime, endTime, status, page) {
    this.reportService.getDevicesWithFilter(page, this.requestType, status, this.currentuser.customerId, startTime, endTime).subscribe({
      next: res => {
        if (page === 0) {
          this.devices = res;
        } else {
          this.devices = [...this.devices, ...res];
        }
        this.pageInfo.prevResponse = res.length;
        this.isLoading = false;
      },
      error: error => {
        this.isLoading = false;
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  pageCallback(value) {
    this.pageInfo.offset = value.offset;
    this.currentPageCount.start = (value.offset * value.pageSize) + 1;
    const lastPage = Math.floor(this.devices.length / 10);
    this.pageInfo.lastPage = lastPage;
    if (lastPage == value.offset) {
      this.currentPageCount.end = value.count;
    } else {
      this.currentPageCount.end = (value.offset * value.pageSize) + value.limit;
    }
    if (this.pageInfo.lastPage === (this.pageInfo.offset + 1)) {
      if (this.pageInfo.prevResponse) {
        this.page = ++this.page;
        this.getDevicesWithFilter(this.startTime, this.endTime, this.selectedStatus, this.page);
      }
    }
  }

  downloadCSV() {
    this.reportService.downloadCTriggerDevicesCSV(this.selectedStatus, this.currentuser.customerId).subscribe(res => {
      const contentDisposition = res.headers.get('Content-Disposition');
      let filename;
      if (contentDisposition == null) {
        const date = new Date();
        filename = 'Device_' + date + '.csv';
      } else {
        filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
      }
      const blob = new Blob([res.body], {
        type: 'text/csv;charset=utf-8'
      });
      FileSaver.saveAs(blob, filename);
    }, error => {
      this.sharedService.getErrorMsg(error);
    });
  }

  drawErrorCountChart(data) {
    const labels = ['Device Not Found', 'Validation Failed', 'Sensorise Error', 'Unknown'];
    const counts = [];
    counts.push(data.DeviceNotFound);
    counts.push(data.ValidationFailed);
    counts.push(data.SensoriseError);
    counts.push(data.Unknown);
    this.totalErrorCount = data.DeviceNotFound + data.ValidationFailed + data.SensoriseError + data.Unknown;
    if (this.errorCountChart) {
      this.errorCountChart.destroy();
    }
    var ctx = document.getElementById('pieGraph');
    this.errorCountChart = new Chart(ctx, {
      type: 'pie',
      responsive: true,
      data: {
        labels: labels,
        datasets: [{
          data: counts,
          backgroundColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
          ]
        }]
      },
      options: {
        legend: {
          position: 'bottom',
          display: true
        },
        scales: {
          xAxes: [{
            display: false,
            ticks: {
              beginAtZero: true
            }
          }],
          yAxes: [{
            display: false,
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    });
  }

  drawAvgTimeChart(data) {
    const labels = [];
    const days = [];
    let highestCount = 0;
    if (this.avgTimeChart) {
      this.avgTimeChart.destroy();
    }
    data.map(item => {
      const datePipe = new DatePipe('en-US');
      const eventDate = datePipe.transform(item.date, 'dd MMM');
      labels.push(eventDate);
      days.push(item.days);
    });
    highestCount = Math.max(...days);
    var ctx = document.getElementById('lineGraph');
    this.avgTimeChart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [{
          label: 'Days',
          data: days,
          backgroundColor: 'rgba(255, 159, 64, 0.2)',
          borderColor: 'rgba(255, 159, 64, 1)',
          borderWidth: 1,
          fill: false
        }]
      },
      options: {
        legend: {
          position: 'bottom',
          display: true
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              suggestedMin: 0,
              suggestedMax: 10,
              stepSize: highestCount > 9 ? null : 1
            }
          }],
          xAxes: [{
            ticks: {
              autoSkip: true,
              maxTicksLimit: 12
            }
          }]
        }
      }
    });
  }

  sortRequestCountChartData(data) {
    this.requestCountChartData.labels = { daily: [], weekly: [], monthly: [] };
    this.requestCountChartData.counts = { daily: [], weekly: [], monthly: [] };
    // Daily counts and dstinct months and week
    data.map(item => {
      const datePipe = new DatePipe('en-US');
      const eventDate = datePipe.transform(item.date, 'dd MMM');
      // const week = `Week ${new Date(item.date)}`;
      const month = datePipe.transform(item.date, 'MMM');
      // get the week nummber
      // const date1 = new Date(item.date);
      // const oneJan = new Date(date1.getFullYear(), 0, 1);
      // const numberOfDays = Math.floor((date1 - oneJan) / (24 * 60 * 60 * 1000));
      // const week = Math.ceil((date1.getDay() + 1 + numberOfDays) / 7);
      this.requestCountChartData.labels.daily.push(eventDate);
      // if (!this.requestCountChartData.labels.weekly.includes(week)) {
      //   this.requestCountChartData.labels.weekly.push(week);
      // }
      if (!this.requestCountChartData.labels.monthly.includes(month)) {
        this.requestCountChartData.labels.monthly.push(month);
      }
      this.requestCountChartData.counts.daily.push(item.count);
    });
    // monthly counts
    this.requestCountChartData.labels.monthly.map(item => {
      let count = 0;
      data.map(obj => {
        const datePipe = new DatePipe('en-US');
        const month = datePipe.transform(obj.date, 'MMM');
        if (item === month) { count += obj.count; }
      });
      this.requestCountChartData.counts.monthly.push(count);
    });
    this.selectViewType(this.selectedCountView);
  }

  selectViewType(view) {
    this.selectedCountView = view;
    if (view === 'Monthly') {
      this.drawRequestCountChart(this.requestCountChartData.labels.monthly, this.requestCountChartData.counts.monthly);
    } else {
      this.drawRequestCountChart(this.requestCountChartData.labels.daily, this.requestCountChartData.counts.daily);
    }
  }

  drawRequestCountChart(labels, counts) {
    // const labels = [];
    // const counts = [];
    // let highestCount = 0;
    if (this.requestCountChart) {
      this.requestCountChart.destroy();
    }
    // data.map(item => {
    //   const datePipe = new DatePipe('en-US');
    //   const eventDate = datePipe.transform(item.date, 'dd MMM');
    //   labels.push(eventDate);
    //   counts.push(item.count);
    // });
    // highestCount = Math.max(...counts);
    var ctx = document.getElementById('barGraph');
    this.requestCountChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{
          label: 'Count',
          data: counts,
          backgroundColor: 'rgb(54, 162, 235, 1)',
          borderColor: 'rgb(54, 162, 235, 1)',
          borderWidth: 1,
        }]
      },
      options: {
        legend: {
          position: 'bottom',
          display: true
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              suggestedMin: 0,
              suggestedMax: 10,
              // stepSize: highestCount > 9 ? null : 1
            }
          }],
          xAxes: [{
            ticks: {
              autoSkip: true,
              maxTicksLimit: 12
            }
          }]
        }
      }
    });
  }
}
