import { Component, OnInit } from '@angular/core';
import { ScoreService } from './score.service';
import { SharedService } from '@myproject/shared/shared.service';
import Chart from 'chart.js';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { environment } from '@myenv/environment';

@Component({
  selector: 'app-score',
  templateUrl: './score.component.html',
  styleUrls: ['./score.component.scss']
})
export class ScoreComponent implements OnInit {
  selecteddeviceId: string;
  vehicles: any[] = [];
  vehiclesLoading = false;
  scoreChart: any;
  titleText = '';
  dailyScore: any = {};
  monthlyScore: any = [];
  selectedScore = '';
  permissionModules = [];
  lastReportUrl: string;

  constructor(
    private scoreService: ScoreService,
    private sharedService: SharedService,
    private title: Title
  ) {
    const url = localStorage.getItem('lastReportUrl');
    this.lastReportUrl = url ? url : 'reports/system/live-status';
  }

  ngOnInit() {
    this.title.setTitle('Score' + environment.title_text);
    this.permissionModules = JSON.parse(localStorage.getItem('permissionModules'));
  }

  changeVehicle(e) {
    this.selecteddeviceId = e ? e.deviceId : null;
    this.getAllScores();
    this.getSingleScoreData('harshBraking');
  }

  getAllScores() {
    if (this.selecteddeviceId === null) { return; }
    // const endTime = Date.parse(new Date().toUTCString());
    // const startTime = endTime - (24 * 60 * 60 * 1000);
    this.scoreService.getVehicleScores(this.selecteddeviceId).subscribe({
      next: res => {
        if (res.length) {
          this.dailyScore = res[0];
          // this.getSingleScoreData('harshBraking');
        } else {
          this.dailyScore = {};
          this.dailyScore.harshBraking = 0;
          this.dailyScore.harshAcceleration = 0;
          this.dailyScore.overSpeed = 0;
          this.dailyScore.idling = 0;
          this.dailyScore.exposure = 0;
          this.dailyScore.behaviour = 0;
          this.dailyScore.risk = 0;
        }
      },
      error: error => {
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  getSingleScoreData(scoreType) {
    this.selectedScore = scoreType;
    const endTime = Date.parse(new Date().toUTCString());
    const startTime = endTime - (30 * 24 * 60 * 60 * 1000);
    this.scoreService.getVehicleScores(this.selecteddeviceId, startTime, endTime, scoreType).subscribe({
      next: res => {
        // console.log(res);
        if (res.length) {
          // this.monthlyScore = res;
          this.sortData(res, scoreType);
        } else {
          this.clearChart(scoreType);
        }
      },
      error: error => {
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  changeScore(scoreType) {
    if (this.scoreChart) {
      this.scoreChart.destroy();
    }
    this.getSingleScoreData(scoreType);
  }

  sortData(res, scoreType) {
    const labels = [];
    const data = [];
    res.reverse();
    res.map(item => {
      const datePipe = new DatePipe('en-US');
      const eventDate = datePipe.transform(item.localDate, 'dd MMM');
      labels.push(eventDate);
      data.push(item[scoreType]);
    });
    this.drawChart(labels, data, scoreType);
  }

  clearChart(scoreType?) {
    if (this.scoreChart) {
      this.scoreChart.destroy();
    }
    this.drawChart([], [], scoreType ? scoreType : '');
  }

  drawChart(labels, data, scoreType) {
    let label;
    if (scoreType === 'harshBraking') {
      label = 'Harsh Braking Score';
      this.titleText = 'Harsh Braking';
    } else if (scoreType === 'harshAcceleration') {
      label = 'Harsh Acceleration Score';
      this.titleText = 'Harsh Acceleration';
    } else if (scoreType === 'overSpeed') {
      label = 'Over Speeding Score';
      this.titleText = 'Over Speeding';
    } else if (scoreType === 'idling') {
      label = 'Idling Score';
      this.titleText = 'Idling';
    } else if (scoreType === 'exposure') {
      label = 'Exposure Score';
      this.titleText = 'Exposure';
    } else if (scoreType === 'behaviour') {
      label = 'Behavior Score';
      this.titleText = 'Behavior';
    } else if (scoreType === 'risk') {
      label = 'Risk Score';
      this.titleText = 'Risk';
    } else {
      label = 'Score';
      this.titleText = 'Score';
    }
    var ctx = document.getElementById('lineChart');
    this.scoreChart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [{
          label: label,
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgba(54, 162, 235, 1)',
          data: data
        }]
      },
      options: {
        legend: {
          position: 'bottom'
        },
        scales: {
          yAxes: [{
            gridLines: {
              drawBorder: false,
            },
            ticks: {
              suggestedMin: 0,
              suggestedMax: 100
            }
          }]
        },
        xAxes: [{
          gridLines: {
            drawBorder: false,
          },
        }]
      }
    });
  }
}
