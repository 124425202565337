import { Component, OnInit } from '@angular/core';
import { SharedService } from '@shared/shared.service';
import { DataService } from '@components/data/data.service';
import { environment } from '@myenv/environment';
import { configMessage } from '@shared/config-message';
import * as _ from 'lodash';
import * as FileSaver from 'file-saver';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.scss']
})
export class DataComponent implements OnInit {
  moduleName = 'DATA';
  subTitle = 'Messages';
  messageTypeArray: any = [];
  toggleEffect: String = 'all';
  messageSort = 'asc';
  tripSort = 'desc';
  tripMsgSort = 'asc';
  currentSort = 'asc';
  deviceAtr = environment.deviceAtr;
  platformAtr = environment.platformAtr;
  selected = [];
  selectedMessage = [];
  _: any = _;
  vehicles: any = [];
  trips: any = [];
  selecteddeviceId: string = null;
  selectedVehicle: any = {};
  allMessages: any = [];
  tsTime: any;
  teTime: any;
  selectedTrip: any;
  messagesValue: any = [];
  singleMessages: any;
  queryJson: any;
  startTime: any;
  endTime: any;
  messageFormattedString: any;
  searchModel: any = {};
  isLoading = false;
  public deviceDetails = [];
  public vehicleDetails = [];
  public platformDetails = [];
  public dateFormat = environment.smallDateFormat;
  configMessage = configMessage;
  objectKeys = Object.keys;
  table = true;
  disableNav = true;
  tripsLoading = false;
  messageTable = false;
  hideCalendar = false;
  pageInfo: any = {
    offset: 0,
    count: 0,
    limit: 15,
    pageSize: 0,
    lastPage: 0,
  };
  page = 0;
  prevResponse = 0;
  queryMessage: any = [];
  pageLoading = true;
  queryFormattedString: any;
  devicestartTime: any;
  deviceendTime: any;
  modules = [];
  permissionAuthorities = [];
  timeSeeker = {
    selected: false,
    ts: null,
    te: null,
    selectedVal: []
  };
  apiMessageData = [];
  selectedFlag: boolean = false;
  currentPageCount: any = {
    start: 0,
    end: 0,
    totalCount: 0
  };
  // pageVehicleHistory = 0;
  public resSizeenv = environment.resSize;
  readonly resSize = this.resSizeenv;
  constructor(
    private title: Title,
    private sharedService: SharedService,
    private dataService: DataService,
    public router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.title.setTitle('Data' + environment.title_text);
    this.modules = JSON.parse(localStorage.getItem('accessRoleModules'));
    this.permissionAuthorities = this.modules.find(module => module.name === this.moduleName);
    const param = this.route.snapshot.paramMap.get('tab');
    if (param === 'messages') {
      $('#messagesTab').click();
    } else if (param === 'trips') {
      this.toggleEffect = 'trips';
      this.getTrips('asc');
      $('#tripsTab').click();
    }
    // this.getVehicles();
    // this.anchortag();
    window["data"] = this;
  }

  onTimeChange(e) {
    this.resetTimeSeeker();
    this.startTime = e.startTime;
    this.endTime = e.endTime;
    this.queryMessage = [];
    this.queryFormattedString = {};
    this.searchModel.value = "";
    if (this.trips.length) {
      this.messageTable = true;
    } else {
      this.messageTable = false;
    }
    if (this.selecteddeviceId) {
      this.getMessagesTotalCount();
      this.getTripsMessage();
      // this.getTrips('asc');
    }
  }

  changeVehicle(e) {
    this.resetTimeSeeker();
    this.queryMessage = [];
    this.queryFormattedString = {};
    if (this.trips.length) {
      this.messageTable = true;
    } else {
      this.messageTable = false;
    }
    this.selecteddeviceId = e ? e.deviceId : null;
    this.selectedVehicle = e;
    this.messageTypeArray = [];
    this.searchModel.messageType = null;
    this.timeSeeker.selected = false;
    this.timeSeeker.selectedVal = [];
    this.startTime = null;
    this.endTime = null;
    this.sharedService.getRecentdeviceData(this.selecteddeviceId).subscribe((res: any) => {
      if (res.teTime || res.tsTime) {
        if (res.teTime) {
          this.devicestartTime = res.teTime - (60 * 24 * 60 * 1000);
          this.deviceendTime = res.teTime;
          this.startTime = this.devicestartTime;
          this.endTime = this.deviceendTime;
        } 
        else if (!res.teTime && res.tsTime) {
          this.devicestartTime = (new Date()).getTime() - (60 * 24 * 60 * 1000);
          this.deviceendTime = (new Date()).getTime();
          this.startTime = res.tsTime - (60 * 24 * 60 * 1000);
          this.endTime = this.deviceendTime;
        }  else {
          let start = null;
          let end = null;
          const t = new Date();
          start = Date.parse(new Date(t.getFullYear(), t.getMonth(), t.getDate(), 0, 0, 0).toUTCString());
          end = Date.parse(new Date(t.getFullYear(), t.getMonth(), t.getDate(), 23, 59, 59).toUTCString());
          this.devicestartTime = start;
          this.deviceendTime = end;
          this.startTime = this.devicestartTime;
          this.endTime = this.deviceendTime;
        }
        if (this.startTime && this.endTime) {
          this.getTripsMessage();
          // this.getTrips('asc');
        }
      } else {
        this.sharedService.getRecentdeviceDataAlternateAPI(this.selecteddeviceId).subscribe((res: any) => {
          if (res.eventTimeEpoch) {
            this.devicestartTime = res.eventTimeEpoch - (60 * 24 * 60 * 1000);
            this.deviceendTime = res.eventTimeEpoch;
            this.startTime = this.devicestartTime;
            this.endTime = this.deviceendTime;
          }
          else {
            let start = null;
            let end = null;
            const t = new Date();
            start = Date.parse(new Date(t.getFullYear(), t.getMonth(), t.getDate(), 0, 0, 0).toUTCString());
            end = Date.parse(new Date(t.getFullYear(), t.getMonth(), t.getDate(), 23, 59, 59).toUTCString());
            this.devicestartTime = start;
            this.deviceendTime = end;
            this.startTime = this.devicestartTime;
            this.endTime = this.deviceendTime;
          }
          if (this.startTime && this.endTime) {
            this.getTripsMessage();
            // this.getTrips('asc');
          }
        })
      }
    });
    this.searchModel.value = "";
  }

  onSideNavClick(p1) {
    if (p1 === 'trips') {
      $('#tripsTab').click();
      this.toggleEffect = 'trips';
      this.getTrips('asc');
    } else if (p1 === 'messages') {
      $('#messagesTab').click();
      this.toggleEffect = 'all'; 
      this.getallMessages('asc', '0');
    }
  }

  getTrips(sort) {
    this.pageLoading = true;
    if (this.selecteddeviceId !== null) {
      // this.checkDateValidity();
      this.dataService.getTrips(this.selecteddeviceId, this.endTime, this.startTime, sort).subscribe((res: any) => {
        this.trips = res;
        this.trips.map((item, index) => {
          item['index'] = index;
        });
        if (this.trips.length) {
          const lastActiveTrip = this.trips[0];
          this.tsTime = lastActiveTrip.tsTime;
          // if (!this.trips[0].teTime) {
          //   if (sort === 'asc' && this.trips[1]) {
          //     // this.teTime = this.trips[1].tsTime;
          //     this.teTime = this.trips[1].tsTime;
          //   } else {
          //     // let currentTime = new Date().toUTCString();
          //     // this.teTime = Date.parse(currentTime);
          //     this.teTime = this.endTime;
          //   }
          // }
          this.selected = [lastActiveTrip];
          this.selectedTrip = lastActiveTrip;
          if (!lastActiveTrip.hasOwnProperty('teTime')) {
            this.teTime = new Date().getTime();
          } else {
            this.teTime = lastActiveTrip.teTime;
          }
          this.getMessagesTotalCount();
          this.messageTable = true;
        } else {
          this.messageTable = false;
          this.allMessages = [];
          this.singleMessages = false;
          this.deviceDetails = [];
          this.platformDetails = [];
          this.vehicleDetails = [];
          this.messagesValue = [];
          this.tripsLoading = false;
        }
        this.page = 0;
        this.getallMessages(this.tripMsgSort, '0');
        this.pageLoading = false;
      }, error => {
        this.pageLoading = false;
        this.sharedService.getErrorMsg(error);
        this.tripsLoading = false;
      });
    } else {
      this.pageLoading = false;
    }
  }

  // navigateDates(dir) {
  //   this.tripsLoading = true;
  //   if (dir === 'left') {
  //     this.startDt = new Date(this.startDt.setDate(this.startDt.getDate() - 1));
  //     this.disableNav = false;
  //   } else if (dir === 'right') {
  //     this.startDt = new Date(this.startDt.setDate(this.startDt.getDate() + 1));
  //   }
  //   this.getTrips('asc');
  // }

  // checkDateValidity() {
  //   if (
  //     this.startDt.getDate() === this.maxDate.getDate() &&
  //     this.startDt.getMonth() === this.maxDate.getMonth() &&
  //     this.startDt.getFullYear() === this.maxDate.getFullYear()
  //   ) {
  //     this.disableNav = true;
  //   } else {
  //     this.disableNav = false;
  //   }
  // }

  getTripsMessage() {
    // if(this.trips.length) {
    //   this.messageTable = true;
    // } else {
    //   this.messageTable = false;
    // }
    if (this.toggleEffect === 'all') {
      this.page = 0;
      this.getallMessages(this.messageSort, '0');
      this.getMessagesTotalCount();
    } if (this.toggleEffect === 'trips') {
      this.getTrips(this.tripSort);
    } if (this.toggleEffect === 'query' && this.selectedVehicle.architectureType) {
      this.getMessageType();
    } else {
      // this.pageLoading = false;
      this.trips = [];
      this.allMessages = [];
      this.messagesValue = [];
    }
  }

  getallMessages(sort, page) {
    if (this.searchModel.value != "" && this.selectedFlag) {
      this.apiSearch(true);
    } else {
      this.pageLoading = true;
      this.currentSort = sort;
      let startTime;
      let endTime;
      if (this.toggleEffect === 'all') {
        startTime = this.startTime;
        endTime = this.endTime;
      } if (this.toggleEffect === 'trips') {
        if (this.trips.length) {
          startTime = this.tsTime;
          endTime = this.teTime;
        }
      }
      if (startTime && endTime) {
        if (this.timeSeeker.selected) {
          this.getMessagesData(this.selecteddeviceId, this.timeSeeker.ts, this.timeSeeker.te, this.currentSort, page);
        } else {
          this.getMessagesData(this.selecteddeviceId, startTime, endTime, this.currentSort, page);
        }
      } else {
        this.pageLoading = false;
        this.allMessages = [];
        this.singleMessages = false;
        this.tripsLoading = false;
        this.deviceDetails = [];
        this.platformDetails = [];
        this.vehicleDetails = [];
      }
    }
  }

  // pageCallback(value) {
  //   this.pageInfo.offset = value.offset;
  //   const lastPage = Math.floor(this.allMessages.length / 15);
  //   // this.pageInfo.lastPage = lastPage;
  //   if (this.pageInfo.lastPage === (this.pageInfo.offset + 1)) {
  //     if (this.prevResponse === 100) {
  //       this.page = ++this.page;
  //       this.getallMessages(this.currentSort, this.page);
  //     }
  //   }
  // }

  getMessages(event) {
    if (event.type === 'click' && event.cellIndex !== -1) {
      this.tsTime = event.row.tsTime;
      if (!event.row.teTime) {
        this.teTime = new Date().getTime();
      } else {
        this.teTime = event.row.teTime;
      }
      this.searchModel.value ? this.searchModel.value = "" : "";
      // if (!event.row.teTime) {
        // const rowIndex = event.row.index;
        // if (this.currentSort === 'asc') {
          // if (rowIndex !== this.trips.length - 1) {
          //   this.teTime = this.trips[rowIndex + 1].tsTime;
          // } else {
          //   // let currentTime = new Date().toUTCString();
          //   this.teTime = this.endTime;
          // }
        // } else {
          // if (rowIndex !== 0) {
          //   this.teTime = this.trips[rowIndex - 1].tsTime;
          // } else {
          //   // let currentTime = new Date().toUTCString();
          //   this.teTime = this.endTime;
          // }
      //   }
      // }
      this.page = 0;
      this.getallMessages(this.currentSort, this.page);
      this.getMessagesTotalCount();
      this.timeSeeker.selected = false;
    }
  }

  // getallMessages(row, sort) {
  //   this.dataService.getallMessages(this.selecteddeviceId, this.tsTime, this.teTime, sort).subscribe((res: any) => {
  //     res.map(item => {
  //       item['messageTypeText'] = this.configMessage.messageType[item.messageType]
  //     })
  //     this.allMessages = res;
  //     this.messagesValue = res;
  //     if (res.length) {
  //       this.getMessage(res[0].messageId)
  //       this.selectedMessage = [res[0]];
  //     } else {
  //       this.tripsLoading = false;
  //     }
  //   }, error => {
  //     this.allMessages = [];
  //     this.singleMessages = false;
  //     this.tripsLoading = false;
  //     this.deviceDetails = [];
  //     this.platformDetails = [];
  //     this.vehicleDetails = [];
  //     this.sharedService.getErrorMsg(error);
  //   });
  // }

  // getCache() {
  //   this.dataService.getCache().subscribe((res) => {
  //     this.cache = res;
  //     console.log(this.cache)
  //   }, error => {
  //     this.sharedService.getErrorMsg(error);
  //   });
  // }

  getMessage(messageId) {
    this.dataService.getMessage(messageId).subscribe((res: any) => {
      this.tripsLoading = false;
      this.singleMessages = res;
      this.messageFormattedString = this.sharedService.syntaxHighlight([res]);
      this.deviceDetails = [];
      this.platformDetails = [];
      this.vehicleDetails = [];
      Object.keys(this.singleMessages).forEach(key => {
        let value: any = this.singleMessages[key];
        // stringify it if the value for that key is arrar or array of object
        if (Array.isArray(value)) value = JSON.stringify(value);
        if (this.deviceAtr.includes(key)) {
          this.deviceDetails.push({ 'key': key, 'value': value });
        } else if (this.platformAtr.includes(key)) {
          this.platformDetails.push({ 'key': key, 'value': value });
        } else {
          this.vehicleDetails.push({ 'key': key, 'value': value });
        }
      });
    }, error => {
      this.tripsLoading = false;
      this.sharedService.getErrorMsg(error);
    });
  }

  getData(event) {
    if (event.type === 'click' && event.cellIndex !== -1) {
      this.getMessage(event.row.messageId);
    }
  }

  onSearch() {
    const inputVal = this.searchModel.value.trim();
    const val = inputVal.toLowerCase();
    // const temp = this.search(val, this.messagesValue);
    if (val === '') {
      this.allMessages = this.messagesValue;
      // this.messagesValue = this.messagesValue.splice(0,100);
      this.selectedFlag = false;
      // this.page = 0;
      // this.pageInfo.count = 100;
      // this.pageInfo.offset = 0;
      // this.pageInfo.lastPage = 7;
      // this.pageInfo.pageSize = 0;
      this.getTripsMessage();
    } else {
      // if (this.messagesValue.length) {
      //   const temp = this.messagesValue.filter((item, index) => {
      //     if (item.messageType && item.messageType.toLowerCase().includes(val)) {
      //       return this.messagesValue[index];
      //     } else if (item.messageTypeId && item.messageTypeId.toString().includes(val)) {
      //       return this.messagesValue[index];
      //     }
      //   });
      //   this.allMessages = temp;
      // }
    }
    // this.getMessagesTotalCount();

  }


  apiSearch(flag?) {
    if (!flag) {
      this.page = 0;
      this.pageInfo.offset = 0;
    }

    if (this.searchModel.value) {
      let url = `messages/device/${this.selecteddeviceId}/search?startTime=${this.startTime}&endTime=${this.endTime}&attributes=eventTime,messageTypeId,messageId,messageType,deviceCommProtocol&sort=ASC&page=${this.page}`
      if (this.timeSeeker.selected) {
        url = `messages/device/${this.selecteddeviceId}/search?startTime=${this.timeSeeker.ts}&endTime=${this.timeSeeker.te}&attributes=eventTime,messageTypeId,messageId,messageType,deviceCommProtocol&sort=ASC&page=${this.page}`;
      }

      if (this.toggleEffect === "trips") {
        url = `messages/device/${this.selecteddeviceId}/search?startTime=${this.tsTime}&endTime=${this.teTime}&attributes=eventTime,messageTypeId,messageId,messageType,deviceCommProtocol&sort=ASC&page=${this.page}`;
      }
      this.getMessagesTotalCountOnSearch();
      this.dataService.getMessageData(url, { "searchKey": this.searchModel.value }).subscribe(data => {
        this.prevResponse = data.length;
        if (this.page === 0) {
          this.allMessages = data;
        }
        else {
          this.allMessages = [...this.allMessages, ...data];
        }
        if (this.allMessages.length) {
          this.messageTable = true;
          this.getMessage(this.allMessages[0].messageId);
          this.selectedMessage = [this.allMessages[0]];
        } else {
          // this.messageTable = false;
          this.singleMessages = false;
        }
        // this.pageInfo.count = this.allMessages.length;
        // const pagecount = this.pageInfo.count / this.pageInfo.limit;
        // this.pageInfo.lastPage = Math.floor(pagecount);
        // const decimal = pagecount - this.pageInfo.lastPage;
        // if (!Number.isInteger(decimal)) {
        //   this.pageInfo.lastPage = this.pageInfo.lastPage + 1;
        // }
        this.pageLoading = false;
        this.setPageInfo();
      })
    }
  }

  /**
   *
   * @param serailNumber : String
   * @param type : String
   */

  //  Download JSON
  downloadJson() {
    let filename;
    const date = new Date();
    filename = 'data.json';
    const blob = new Blob([JSON.stringify(this.singleMessages)], {
      type: 'text/csv;charset=utf-8'
    });
    FileSaver.saveAs(blob, filename);
  }

  //  Download JSON
  downloadQueryJson() {
    let filename;
    const date = new Date();
    filename = 'data.json';
    const blob = new Blob([JSON.stringify(this.queryJson)], {
      type: 'text/csv;charset=utf-8'
    });
    FileSaver.saveAs(blob, filename);
  }

  // To download trips data as a csv file
  downloadTripCSV() {
    this.dataService.downloadCSV(this.selecteddeviceId, this.endTime, this.startTime).subscribe(res => {
      this.downloadCSV(res, 'data');
    }, error => {
      this.sharedService.getErrorMsg(error);
    });
  }

  // To download message data as a csv file
  downloadMsgCSV() {
    let startTime;
    let endTime;
    if (this.toggleEffect === 'all') {
      startTime = this.startTime;
      endTime = this.endTime;
    } if (this.toggleEffect === 'trips') {
      startTime = this.tsTime;
      endTime = this.teTime;
    }
    if (this.timeSeeker.selected) {
      startTime = this.timeSeeker.ts;
      endTime = this.timeSeeker.te;
    }
    this.dataService.downloadCSV(this.selecteddeviceId, endTime, startTime).subscribe(res => {
      this.downloadCSV(res, 'trip');
    }, error => {
      this.sharedService.getErrorMsg(error);
    });
  }

  // download api res to downloaded file
  downloadCSV(res, name) {
    const contentDisposition = res.headers.get('Content-Disposition');
    let filename;
    if (contentDisposition === null) {
      const date = new Date();
      filename = name + date + '.json.gz';
    } else {
      filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
    }
    const blob = new Blob([res.body], {
      type: 'application/tar+gzip;charset=utf-8'
    });
    FileSaver.saveAs(blob, filename);
  }

  sortTrips(e) {
    if (this.trips.length) {
      this.tripSort = e.newValue;
      this.currentSort = e.newValue;
      this.getTrips(e.newValue);
    }
  }

  sortMessages(e) {
    if (this.allMessages.length) {
      this.page = 0;
      this.messageSort = e.newValue;
      this.currentSort = e.newValue;
      this.getallMessages(e.newValue, '0');
    }
  }

  sortMessagesWithTrips(e) {
    if (this.trips.length && this.allMessages.length) {
      this.page = 0;
      this.tripMsgSort = e.newValue;
      this.currentSort = e.newValue;
      this.getallMessages(e.newValue, '0');
    }
  }

  getMessageType() {
    this.pageLoading = true;
    this.dataService.getMessageType(this.selectedVehicle.architectureType).subscribe({
      next: res => {
        if (res.length) {
          this.messageTypeArray = res;
          this.searchModel.messageType = this.messageTypeArray[0].messageType;
        } else {
          this.messageTypeArray = [];
          this.searchModel.messageType = null;
        }
        this.pageLoading = false;
      },
      error: error => {
        this.pageLoading = false;
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  refresh() {
    this.generateQueryMessage(true);
  }

  generateQueryMessage(isValid) {
    if (isValid) {
      const payload = {};
      payload['deviceId'] = this.selecteddeviceId;
      payload['endTime'] = this.endTime;
      payload['startTime'] = this.startTime;
      payload['messageType'] = this.searchModel.messageType;
      // payload['filter']  = JSON.stringify(this.searchModel.filter);
      payload['filter'] = this.searchModel.filter;
      if (this.searchModel.attributes) {
        payload['attributes'] = this.searchModel.attributes;
      }
      this.dataService.generateQueryMessage(payload).subscribe({
        next: res => {
          this.queryMessage = res;
          this.queryJson = res;
          Object.keys(this.queryJson).forEach(key => {
            if (this.deviceAtr.includes(key)) {
              this.deviceDetails.push({ 'key': key, 'value': this.queryJson[key] });
            } if (this.platformAtr.includes(key)) {
              this.platformDetails.push({ 'key': key, 'value': this.queryJson[key] });
            } else {
              this.vehicleDetails.push({ 'key': key, 'value': this.queryJson[key] });
            }
          });
          this.queryFormattedString = this.sharedService.syntaxHighlight(res);
        },
        error: error => {
          this.sharedService.getErrorMsg(error);
        }
      });
    }
  }
  onTimeSelection(val) {
    this.page = 0;
    this.pageInfo.offset = 0;
    this.searchModel.dataPresent = false;
    this.searchModel.value = "";
    this.searchModel.messageType = null;
    if (this.timeSeeker.selectedVal.includes(val.ts)) {
      const index = this.timeSeeker.selectedVal.indexOf(val.ts);
      this.timeSeeker.selectedVal.splice(index, 1);
      this.timeSeeker.selected = false;
      this.getallMessages(this.currentSort, this.page);
      this.getMessagesTotalCount();
    } else if (this.timeSeeker.selectedVal.length < 1 || this.timeSeeker.selectedVal.length === 1) {
      this.timeSeeker.selectedVal = [];
      this.timeSeeker.selected = true;
      this.timeSeeker.ts = val.ts;
      this.timeSeeker.te = val.te;
      this.getMessagesData(this.selecteddeviceId, val.ts, val.te, this.currentSort, this.page);
      this.getMessagesTotalCount();
      this.timeSeeker.selectedVal.push(val.ts);
    }
  }

  getMessagesData(deviceId, ts, te, sort, page) {
    this.dataService.getallMessages(deviceId, ts, te, sort, page).subscribe((res: any) => {
      if (res.length) {
        res.map(item => {
          item['messageTypeText'] = this.configMessage.messageType[item.messageType];
        });
        this.prevResponse = res.length;
        if (page == 0) {
          this.pageInfo.offset = 0;
          this.allMessages = res;
          this.messagesValue = res;
          if (res.length) {
            this.getMessage(res[0].messageId);
            this.selectedMessage = [res[0]];
          } else {
            this.tripsLoading = false;
          }
        } else {
          this.allMessages = [...this.allMessages, ...res];
          this.messagesValue = [...this.messagesValue, ...res];
          this.getMessage(this.allMessages[0].messageId);
          this.selectedMessage = [this.allMessages[0]];
        }
        if (this.allMessages.length) {
          this.messageTable = true;
        } else {
          this.messageTable = false;
        }
        this.setPageInfo();
        // this.pageInfo.count = this.allMessages.length;
        // const pagecount = this.pageInfo.count / this.pageInfo.limit;
        // this.pageInfo.lastPage = Math.floor(pagecount);
        // const decimal = pagecount - this.pageInfo.lastPage;

        // if (!Number.isInteger(decimal)) {
        //   this.pageInfo.lastPage = this.pageInfo.lastPage + 1;
        // }
        this.pageLoading = false;
      } else {
        this.pageLoading = false;
        this.messageTable = false;
        this.allMessages = [];
        this.singleMessages = false;
        this.deviceDetails = [];
        this.platformDetails = [];
        this.vehicleDetails = [];
        this.messagesValue = [];
      }

    }, error => {
      this.pageLoading = false;
      this.allMessages = [];
      this.singleMessages = false;
      this.tripsLoading = false;
      this.deviceDetails = [];
      this.platformDetails = [];
      this.vehicleDetails = [];
      this.sharedService.getErrorMsg(error);
    });
  }

  // Setting data limit for table in each page
  setPageInfo() {
    this.pageInfo.count = this.allMessages.length;
    let pagecount = this.pageInfo.count / this.pageInfo.limit;
    this.pageInfo.lastPage = Math.floor(pagecount);
    let decimal = pagecount - this.pageInfo.lastPage;

    if (!Number.isInteger(decimal)) {
      this.pageInfo.lastPage = this.pageInfo.lastPage + 1;
    }
    if (this.page == 0) {
      if (this.allMessages.length) {
        this.currentPageCount.start = (this.pageInfo.offset * this.pageInfo.pageSize) + 1;
        if (this.allMessages.length <= this.pageInfo.limit) {
          this.currentPageCount.end = this.pageInfo.count;
        } else {
          this.currentPageCount.end = (this.pageInfo.offset * this.pageInfo.pageSize) + this.pageInfo.limit;
        }
      }
    } else if (this.prevResponse >= 15) {
      this.currentPageCount.end = (this.pageInfo.offset * this.pageInfo.limit) + this.pageInfo.limit;
    }
  }

  pageCallback(value) {
    this.pageInfo.offset = value.offset;
    this.currentPageCount.start = (value.offset * value.pageSize) + 1;
    const lastPage = Math.floor(this.allMessages.length / this.pageInfo.limit);
    if (lastPage == value.offset) {
      this.currentPageCount.end = value.count;
    } else {
      this.currentPageCount.end = (value.offset * value.pageSize) + value.limit;
    }
    // if (this.pageCount == 100) {
    if (this.pageInfo.lastPage == (this.pageInfo.offset + 1)) {
      if (this.prevResponse == 100) {
        this.page = ++this.page;
        // if (this.usingEventTime) {
        //   this.getSingleVehicleView(this.vehicle.deviceId, this.vehicle.startTime, this.vehicle.endTime);
        // } else {
        if (this.timeSeeker.selected) {
          this.getallMessages(this.currentSort, this.page);
        } else {
          this.getallMessages(this.currentSort, this.page);
        }
        // }
      }
    }
    // }
  }

  getMessagesTotalCount() {
    let req = null;
    if (this.toggleEffect === "trips") {
      req = this.dataService.getMessagesCount(
        this.selecteddeviceId, this.tsTime, this.teTime, this.page, this.resSize, this.searchModel.messageType
      );
    } else {
      req = this.dataService.getMessagesCount(
        this.selecteddeviceId, this.startTime, this.endTime, this.page, this.resSize, this.searchModel.messageType
      );
    }
    if (this.timeSeeker.selected) {
      req = this.dataService.getMessagesCount(
        this.selecteddeviceId, this.timeSeeker.ts, this.timeSeeker.te, this.page, this.resSize, this.searchModel.messageType
      );
    }
    req.subscribe(res => {
      this.currentPageCount.totalCount = res.count;
    }, error => {
      this.sharedService.getErrorMsg(error);
    });
  }

  getMessagesTotalCountOnSearch() {
    let req = null;
    if (this.toggleEffect === "trips") {
      req = this.dataService.getMessagesCountOnSearch(
        this.selecteddeviceId, this.tsTime, this.teTime, this.page, this.resSize, this.searchModel.value
      );
    } else {
      req = this.dataService.getMessagesCountOnSearch(
        this.selecteddeviceId, this.startTime, this.endTime, this.page, this.resSize, this.searchModel.value
      );
    }
    if (this.timeSeeker.selected) {
      req = this.dataService.getMessagesCountOnSearch(
        this.selecteddeviceId, this.timeSeeker.ts, this.timeSeeker.te, this.page, this.resSize, this.searchModel.value
      );
    }
    req.subscribe(res => {
      this.currentPageCount.totalCount = res.count;
    }, error => {
      this.sharedService.getErrorMsg(error);
    });
  }
  resetTimeSeeker() {
    this.timeSeeker = {
      selected: false,
      ts: null,
      te: null,
      selectedVal: []
    };
  }
}
