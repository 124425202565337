import { Component, OnInit } from '@angular/core';
import {environment} from "@myenv/environment";

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  public showFooter = environment.showFooter;

  constructor() { }

  ngOnInit() {
  }

}
