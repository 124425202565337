import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '@myenv/environment';
var GroupsService = /** @class */ (function () {
    function GroupsService(http, router, handler) {
        this.http = http;
        this.router = router;
        this.handler = handler;
        this.apiUrl = environment.apiUrl;
        this.httpClient = new HttpClient(handler);
    }
    // To get groups
    GroupsService.prototype.getGroups = function () {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
        return this.http.get(this.apiUrl + 'groups?includeDevice=true', { headers: headers }).pipe(catchError(this.handleError));
    };
    // To get assigned groups
    GroupsService.prototype.getAssignedGroups = function () {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
        return this.http.get(this.apiUrl + 'groups?includeDevice=true', { headers: headers }).pipe(catchError(this.handleError));
    };
    // To create a group
    GroupsService.prototype.createGroup = function (data) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
        return this.http.post(this.apiUrl + 'groups', data, { headers: headers }).pipe(catchError(this.handleError));
    };
    // To update a group
    GroupsService.prototype.updateGroup = function (data) {
        return this.http.put(this.apiUrl + 'groups/' + data.groupId + '?groupName=' + data.name, data).pipe(catchError(this.handleError));
    };
    // To delete a group
    GroupsService.prototype.deleteGroup = function (groupId) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
        return this.http.delete(this.apiUrl + 'groups/' + groupId, { headers: headers }).pipe(catchError(this.handleError));
    };
    GroupsService.prototype.onSearchGroup = function (value, includeDevice) {
        return this.http.get(this.apiUrl + "groups?name=" + value + "&includeDevice=" + includeDevice).pipe(catchError(this.handleError));
    };
    GroupsService.prototype.handleError = function (error) {
        return observableThrowError(error.error || error.json() || error || 'Server error');
    };
    // getAssociatedVehicles(groupId, page, pageLimit) {
    //   return this.http.get(this.apiUrl + 'devices/groupId/' + groupId + '?page=' + page + '&pagelimit=' + pageLimit + '&attributes=assetName');
    // }
    GroupsService.prototype.getAssociatedVehicles = function (groupId, page, pageLimit) {
        return this.http.get(this.apiUrl +
            ("devices?sortingKey=lastCommunication&direction=desc&key=groupName&value=" + groupId + "&attributes=assetName&page=" + page + "&pagelimit=" + pageLimit + "&isAssociatedDevice=true"))
            .pipe(catchError(this.handleError));
    };
    GroupsService.prototype.addDevicesToGroup = function (groupId, devices, selectAll, filterCriteria) {
        var payload = { actionName: 'CG', selectAll: selectAll, deviceIds: devices, groupId: groupId };
        if (selectAll) {
            payload['filterCriteria'] = {};
            if (filterCriteria[1]) {
                payload['filterCriteria'][filterCriteria[0]] = filterCriteria[1];
            }
            payload['filterCriteria']['isAssociatedDevice'] = false;
            if (filterCriteria[3]) {
                payload['filterCriteria']['groupId'] = filterCriteria[3];
            }
            if (filterCriteria[4]) {
                payload['filterCriteria']['status'] = filterCriteria[4];
            }
        }
        return this.http.put(this.apiUrl + 'devices/bulkAction', payload).pipe(catchError(this.handleError));
    };
    GroupsService.prototype.addDevicesToTag = function (id, devices, selectAll, action, filterCriteria) {
        var payload = { actionName: action, selectAll: selectAll, deviceIds: devices, tags: [id] };
        if (selectAll) {
            payload['filterCriteria'] = {};
            if (filterCriteria[1]) {
                payload['filterCriteria'][filterCriteria[0]] = filterCriteria[1];
            }
            payload['filterCriteria']['isAssociatedDevice'] = false;
            if (filterCriteria[3]) {
                payload['filterCriteria']['groupId'] = filterCriteria[3];
            }
            if (filterCriteria[4]) {
                payload['filterCriteria']['status'] = filterCriteria[4];
            }
        }
        return this.http.put(this.apiUrl + 'devices/bulkAction', payload).pipe(catchError(this.handleError));
    };
    GroupsService.prototype.uploadFile = function (data) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this.httpClient.post(this.apiUrl + "groups/upload", data, { headers: headers }).pipe(catchError(this.handleError));
    };
    return GroupsService;
}());
export { GroupsService };
