import { Component, OnInit, Input } from '@angular/core';
import { environment } from '@myenv/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
  @Input() sideData: any;
  @Input() report: any;

  @Input() KeyUp: any;
  @Input() imgSrc: any;
  moduleName: string;
  modules: any = [];
  permissionAuthorities: any = {};
  public cmImageUrl = environment.cmImageUrl;
  constructor(public translate: TranslateService) { }

  ngOnInit() {
    var url = window.location.pathname;
    var array = url.split('/');
    this.moduleName = array[array.length-1].toUpperCase();
    this.modules = JSON.parse(localStorage.getItem('accessRoleModules'));
    this.permissionAuthorities = this.modules.find(module => module.name === this.moduleName);
  }

}
