import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SharedService } from '@shared/shared.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private sharedService: SharedService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const global = (<any>window);
        const intercomm = localStorage.getItem('intercomm');
        if (localStorage.getItem('currentUser') || (localStorage.getItem('token'))) {
            let obj = JSON.parse(localStorage.getItem('userInfo'));
            // if (obj && global.Intercom && intercomm !==) {
            //     this.sharedService.bootUpInterCom();
            // }
            // logged in so return true
            return true;
        }
        // if (global.Intercom) {
        //     this.sharedService.bootDownInterCom();
        // }
        this.sharedService.bootDownInterCom();
        this.router.navigate(['login']);
        return false;


    }
}
