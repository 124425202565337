<!-- <footer class="footer footer-inverse">
        <div class="container">
          <p class="text-center f-s-12"> &copy; {{footerText}}</p>
        </div>
</footer> -->

<footer class="footer container-fluid row pt-2 pb-0">
  <!-- <div class="col-md-4"> -->
    <!-- <ng-container *ngIf="footerLeft"> -->
     <!-- <span>{{buildInfo}}</span>-->
    <!-- </ng-container> -->
  <!-- </div> -->
  <div class="col-md-12 text-center">
    <ul class="mb-0">
      <!-- <li *ngIf="footerText1"><a [routerLink]="['/contact-us']">{{footerText1}}</a></li>
     <li *ngIf="footerText2"><a [routerLink]="['/faq']">{{footerText2}}</a></li>
     <li *ngIf="footerText3"><a href="https://servicedesk.drivewithdarby.com/servicedesk/customer/user/login?destination=portals" target="_blank">{{footerText3}}</a></li>
     <li>Contact us at: <a class="link-color cursor-pointer" [href]="'tel:'+supportNumber">{{supportNumber}}</a></li> -->
     <li> &copy; {{footerText}} Version : {{buildInfo}}</li>
    </ul>
  </div>
  <!-- <div class="col-md-4 text-right">
    <ng-container *ngIf="footerRight"> -->
    <!--<a (mouseenter)="changeImg=true" (mouseleave)="changeImg=false" href="https://drivewithdarby.com/" target="_blank" class="logo-container">-->
      <!-- <a href="#" class="logo-container">
        <img
          src="{{footerImageUrl}}assets/img/powered-by-logo.png?random={{randomNumber}}"
          alt="logo" class="darbySVGLogo"
        /> -->
        <!--<img *ngIf="changeImg" style="height: 30px; background-color: #0f82ff;"-->
             <!--src="{{footerImageUrl}}{{accountKey}}/assets/img/customer-logo.png?random={{randomNumber}}"-->
             <!--alt="logo" class="darbySVGLogo"-->
        <!--/>-->
      <!-- </a>
    </ng-container>
  </div> -->
</footer>
