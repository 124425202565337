import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@myenv/environment';
import {throwError as observableThrowError,  Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScoreService {
  public apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  getScores(deviceId, startTime?, endTime?, scoreType?): Observable<any> {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    let tempUrl = this.apiUrl + `scores/device/${deviceId}`;
    if (startTime && endTime) {
      tempUrl += `?startTime=${startTime}&endTime=${endTime}`;
    }
    if (scoreType) {
      tempUrl += `&scoreType=${scoreType}`;
    }
    return this.http.get(tempUrl, {headers})
    .pipe(catchError(this.handleError));
  }

  getVehicleScores(deviceId, startTime?, endTime?, scoreType?): Observable<any> {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    let tempUrl = this.apiUrl + `analyticsProxy/scores/device/${deviceId}`;
    if (startTime && endTime) {
      tempUrl += `?startTime=${startTime}&endTime=${endTime}`;
    }
    if (scoreType) {
      tempUrl += `&scoreType=${scoreType}`;
    }
    return this.http.get(tempUrl, {headers})
    .pipe(catchError(this.handleError));
  }

  handleError(error: any | any) {
    return observableThrowError(
      error.error || error.json() || error || 'Server error'
    );
  }
}
