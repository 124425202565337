import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;
@Component({
  selector: 'app-data-tab-header',
  templateUrl: './data-tab-header.component.html',
  styleUrls: ['./data-tab-header.component.scss']
})
export class DataTabHeaderComponent implements OnInit {
  @Input() moduleName: boolean;
  @Input() subTitleName: boolean;
  menu_title = '';
  menu_subtitle = '';
  modules: any;
  permissionmodules;
  routeParam = [];
  tabPos = 0;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public translate: TranslateService
  ) { }

  ngOnInit() {
    this.routeParam = (this.router.url).split('/');
    this.modules = JSON.parse(localStorage.getItem('accessRoleModules'));
    this.permissionmodules = JSON.parse(localStorage.getItem('permissionModules'));
  }
  ngOnChanges() {
    // this.permissionmodules = JSON.parse(localStorage.getItem('permissionModules'));
    // const url = this.router.url;
    // if (url.includes('destinations')) {
    //   this.tabPos = 1;
    // } else if (url.includes('data-simulator')) {
    //   this.tabPos = 2;
    // } else if (url.includes('live') || url.includes('trips') || url.includes('location-history') || url.includes('/data/messages')) {
    //   this.tabPos = 0;
    // }
  }
  goToTrips(p1) {
    this.router.navigateByUrl('/*', { skipLocationChange: true }).then(() => {
      this.router.navigate([`/data/${p1}`]);
    });
  }
  checkLinkActive() {
    const url = this.router.url;
    if (url.includes('destinations')) {
      this.tabPos = 1;
      return true;
    } else if (url.includes('data-simulator')) {
      this.tabPos = 2;
      return true;
    } else if (url.includes('live') || url.includes('trips') || url.includes('location-history') || url.includes('/data/messages')) {
      this.tabPos = 0;
      return true;
    }
    return false;
  }
}
