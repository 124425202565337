import { OnInit, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@layouts/login/authentication.service';
import { Title } from '@angular/platform-browser';
import { SharedService } from '@shared/shared.service';
import { environment } from '@myenv/environment';
var ResetPasswordComponent = /** @class */ (function () {
    function ResetPasswordComponent(sharedService, route, authenticationService, router, ngzone, title) {
        this.sharedService = sharedService;
        this.route = route;
        this.authenticationService = authenticationService;
        this.router = router;
        this.ngzone = ngzone;
        this.title = title;
        // public tenantId = environment.tenantId;
        this.cmImageUrl = environment.cmImageUrl;
        this.footerText = environment.footerText;
        this.userDetails = {};
        this.responseMessage = {};
        this.resetToken = '';
        this.confirmNewPassword = false;
        this.NewPassword = false;
        this.ConfirmPassword = false;
    }
    ResetPasswordComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.title.setTitle('Reset password' + environment.title_text);
        // this.resetToken = localStorage.getItem('resetToken');
        this.route.params.subscribe(function (params) {
            _this.resetToken = params.id;
        });
        this.sharedService.bootDownInterCom();
    };
    ResetPasswordComponent.prototype.onClickResetPassword = function (isValid) {
        var _this = this;
        if (isValid) {
            if (this.userDetails.newPassword !== this.userDetails.confirmPassword) {
                this.confirmNewPassword = true;
            }
            else {
                this.authenticationService.ResetPassword(this.userDetails, this.resetToken).subscribe(function (res) {
                    _this.ngzone.run(function () {
                        _this.router.navigate(['/login'], { queryParams: { msg: 2 } });
                    });
                }, function (error) {
                    _this.sharedService.getErrorMsg(error);
                });
            }
        }
        // else {
        //   this.confirmNewPassword = false;
        // }
    };
    ResetPasswordComponent.prototype.confirmPass = function () {
        if (this.userDetails.confirmPassword) {
            this.confirmNewPassword = false;
        }
    };
    ResetPasswordComponent.prototype.viewNewPassword = function () {
        this.NewPassword = !this.NewPassword;
    };
    ResetPasswordComponent.prototype.viewConfirmPassword = function () {
        this.ConfirmPassword = !this.ConfirmPassword;
    };
    return ResetPasswordComponent;
}());
export { ResetPasswordComponent };
