import { throwError as observableThrowError, BehaviorSubject, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '@myenv/environment';
var LiveService = /** @class */ (function () {
    function LiveService(http, router) {
        this.http = http;
        this.router = router;
        this.ngUnsubscribe = new Subject();
        this.data$ = new BehaviorSubject({});
        this.apiUrl = environment.apiUrl;
    }
    // To get all vehicle messages
    LiveService.prototype.getAllVehicleMessage = function (page, limit, data) {
        return this.http.get(this.apiUrl + 'messages/latest?page=' + page + '&limit=' + limit + '&&attributes=' + data).pipe(catchError(this.handleError));
    };
    LiveService.prototype.getSnappedVehicle = function (deviceId, attributes) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
        var tempUrl = "messages/device/" + deviceId + "/snapped";
        if (attributes) {
            tempUrl += "?attributes=" + attributes;
        }
        return this.http.get(this.apiUrl + tempUrl, { headers: headers }).pipe(catchError(this.handleError));
    };
    // To search a vehicle
    // searchVehicle(assetName) {
    //
    //   // tslint:disable-next-line:max-line-length
    //   return this.http.get(this.apiUrl + 'messages/search/'+assetName+'/snapped')
    //     .catch(this.handleError);
    // }
    // Search and filter api merged
    LiveService.prototype.searchVehicle = function (messageType, assetName, searchKey, groupId, key, placeCords) {
        var tempURL = this.apiUrl + 'messages/latest/search?limit=3000';
        // tslint:disable-next-line:max-line-length
        if (key['key'] && key['value']) {
            tempURL += '&thresholdKey=' + key['key'] + '&thresholdValue=' + key['value'];
        }
        if (key['placeKey'] && key['placeValue']) {
            tempURL += '&' + key['placeKey'] + '=' + key['placeValue'];
        }
        if (messageType) {
            tempURL += '&status=' + messageType;
        }
        if (assetName) {
            tempURL += "&searchKey=" + searchKey + "&searchValue=" + assetName;
        }
        if (groupId) {
            tempURL += '&groups=' + groupId;
        }
        if (placeCords && placeCords.circle.newCenter.lat != null) {
            tempURL += "&latitude=" + placeCords.circle.newCenter.lat + "&longitude=" + placeCords.circle.newCenter.lng + "&radius=" + placeCords.circle.radius;
        }
        return this.http.get(tempURL).pipe(catchError(this.handleError));
        // else {
        // return this.http.get(this.apiUrl+'messages/snapped/search?messageType='+messageType+'&assetName='+assetName+'&limit=3000')
        //     .catch(this.handleError);
        // }
    };
    LiveService.prototype.getallMessages = function (deviceId, startTime, endTime, attrubute1, attribute2) {
        var tempUrl = "&attributes=eventTime," + attrubute1 + "&messageType=TripData&limit=3000";
        if (attribute2) {
            tempUrl += "," + attribute2;
        }
        return this.http
            .get(this.apiUrl +
            'messages/device/' +
            deviceId +
            '?startTime=' +
            startTime +
            '&endTime=' +
            endTime + tempUrl).pipe(catchError(this.handleError));
    };
    LiveService.prototype.getSupportedPids = function (deviceId, startTime, endTime) {
        return this.http.get(this.apiUrl + 'messages/device/' + deviceId + '/trip/supported/pids?startTime=' + startTime + '&endTime=' + endTime)
            .pipe(catchError(this.handleError));
    };
    LiveService.prototype.getOBD_GPS_Messages = function (deviceId, startTime, endTime, params) {
        return this.http.get(this.apiUrl + 'messages/device/' + deviceId + ("?messageType=GPS_MESSAGE&page=0&attributes=eventTime," + params + "&startTime=") + startTime + '&endTime=' + endTime + '&limit=5000')
            .pipe(catchError(this.handleError));
    };
    LiveService.prototype.getVehicleDetail = function (deviceId) {
        // tslint:disable-next-line:max-line-length
        return this.http.get(this.apiUrl + ("devices?key=deviceId&value=" + deviceId)).pipe(catchError(this.handleError));
    };
    // To filter the vehicles based on selected filter
    // messageFilter(filterItem) {
    //
    //   // tslint:disable-next-line:max-line-length
    //   return this.http.get(this.apiUrl + 'messages/snapped/searchBy/messageType/' + filterItem)
    //     .catch(this.handleError);
    // }
    // To filter the vehicles based on speed
    LiveService.prototype.speedFilter = function (data) {
        // tslint:disable-next-line:max-line-length
        return this.http.get(this.apiUrl + 'messages/latest/searchBy?key=' + data.key + '&value=' + data.value).pipe(catchError(this.handleError));
    };
    // To get the alerts of vehicles
    LiveService.prototype.getAlerts = function (deviceId, startTime, endTime) {
        // tslint:disable-next-line:max-line-length
        return this.http.get(this.apiUrl + 'alerts/device/' + deviceId + '/events/count?startTime=' + startTime + '&endTime=' + endTime).pipe(catchError(this.handleError));
    };
    // To get single vehicle info
    // getSingleVehicleMessage(deviceId, page, data, startTime, endTime, pagelimit, messageType?): Observable<any> {
    //   // tslint:disable-next-line:max-line-length
    //   return this.http.get(this.apiUrl + 'messages/vehicle/' + deviceId + '?page=' + page + '&attributes=' + data + '&startTime=' + startTime + '&endTime=' + endTime + '&limit=' + pagelimit).pipe(
    //     catchError(this.handleError));
    // }
    // To get single vehicle info
    LiveService.prototype.getSingleVehicleMessage = function (deviceId, page, data, startTime, endTime, pagelimit, messageType) {
        // tslint:disable-next-line:max-line-length
        var tempUrl = "messages/device/" + deviceId + "?page=" + page + "&attributes=" + data + "&startTime=" + startTime + "&endTime=" + endTime + "&limit=" + pagelimit;
        if (messageType) {
            tempUrl = tempUrl + ("&messageType=" + messageType);
        }
        return this.http.get(this.apiUrl + tempUrl).pipe(catchError(this.handleError));
    };
    // To get single vehicle info for last 20 messages
    LiveService.prototype.getSingleVehicleLastMessage = function (deviceId, page, pagelimit) {
        // tslint:disable-next-line:max-line-length
        return this.http.get(this.apiUrl + 'messages/device/' + deviceId + '?page=' + page + '&limit=' + pagelimit).pipe(catchError(this.handleError));
    };
    // To get single vehicle info for last 20 messages
    LiveService.prototype.getBatteryEvent = function (deviceId) {
        return this.http.get(this.apiUrl + ("analytics/device/" + deviceId + "/batteryevent"))
            .pipe(catchError(this.handleError));
    };
    // To get single vehicle info for last 20 messages
    LiveService.prototype.getvPacket = function (deviceId, startTime, endTime) {
        // tslint:disable-next-line:max-line-length
        return this.http.get(this.apiUrl + ("analytics/vPacket/deviceId/" + deviceId + "?startDate=" + startTime + "&endDate=" + endTime)).pipe(catchError(this.handleError));
    };
    // To get place filred vehicle info
    LiveService.prototype.getfilteredPlaces = function (deviceId, page, data, startTime, endTime, pagelimit) {
        // tslint:disable-next-line:max-line-length
        return this.http.get(this.apiUrl + 'places/events/device/' + deviceId + '?startTime=' + startTime + '&endTime=' + endTime + '&page=' + page + '&limit=' + pagelimit).pipe(catchError(this.handleError));
    };
    // To get the polyline data for a vehicle
    LiveService.prototype.getPolylineData = function (deviceId, startTime, endTime) {
        return this.http.get(this.apiUrl + 'messages/device/' + deviceId + '/sampled-location?startTime=' + startTime + '&endTime=' + endTime).pipe(catchError(this.handleError));
    };
    // To get the total History count
    LiveService.prototype.getTestCount = function (deviceId, startTime, endTime, page, pagelimit, messageType) {
        var tempUrl = "" + this.apiUrl + 'messages/device/' + deviceId + '/count?startTime=' + startTime + '&endTime=' + endTime + '&page=' + page + '&limit=' + pagelimit;
        if (messageType) {
            tempUrl += "&messageType=" + messageType;
        }
        return this.http.get(tempUrl).pipe(catchError(this.handleError));
    };
    // To get the packet History count
    LiveService.prototype.getPacketCount = function (deviceId, startTime, endTime, messageType) {
        var tempUrl = "" + this.apiUrl + ("messages/device/" + deviceId + "/count?startTime=" + startTime + "&endTime=" + endTime);
        if (messageType) {
            tempUrl += "&messageType=" + messageType;
        }
        return this.http.get(tempUrl).pipe(catchError(this.handleError));
    };
    // To get the places filter count
    LiveService.prototype.getPlcesFilterCount = function (deviceId, startTime, endTime, page, pagelimit) {
        return this.http.get(this.apiUrl + 'places/events/device/' + deviceId + '/count?startTime=' + startTime + '&endTime=' + endTime + '&page=' + page + '&limit=' + pagelimit).pipe(catchError(this.handleError));
    };
    LiveService.prototype.getVehicleCount = function () {
        return this.http.get(this.apiUrl + 'messages/latest/count').pipe(catchError(this.handleError));
    };
    // To handle errors
    LiveService.prototype.handleError = function (error) {
        return observableThrowError(error.error || error.json() || error || 'Server error');
    };
    LiveService.prototype.getVehicleLocation = function (deviceId) {
        return this.http.get(this.apiUrl + 'messages/device/' + deviceId + '/latest').pipe(catchError(this.handleError));
    };
    LiveService.prototype.getVehicleEvents = function (deviceId) {
        return this.http.get(this.apiUrl + 'messages/device/' + deviceId + '/snapped').pipe(catchError(this.handleError));
    };
    LiveService.prototype.keepTrackingVehicle = function (device) {
        var headers = new HttpHeaders({
            'Authorization': "Bearer " + device.token,
            'Content-Type': 'application/json'
        });
        return this.http.get(this.apiUrl + 'messages/device/' + device.deviceId + '/latest', { headers: headers }).pipe(catchError(this.handleError));
    };
    LiveService.prototype.getTrackingToken = function (key) {
        return this.http.get(this.apiUrl + ("messages/track/key/" + key)).pipe(catchError(this.handleError));
    };
    LiveService.prototype.trackVehicle = function (payload) {
        return this.http.post(this.apiUrl + "messages/track", payload)
            .pipe(catchError(this.handleError));
    };
    // for trips
    LiveService.prototype.getTrips = function (deviceId, endTime, startTime, sort) {
        return this.http.get(this.apiUrl + ("trips/device/" + deviceId + "?startTime=" + startTime + "&endTime=" + endTime + "&sort=" + sort));
    };
    LiveService.prototype.getTripsPolylineData = function (deviceId, startTime, endTime) {
        return this.http
            .get(this.apiUrl +
            ("messages/valid/device/" + deviceId) +
            "?attributes=eventTime,latitude,longitude,messageType,messageId," +
            "maxSpeed,avgSpeed,initialSpeed,finalSpeed,maxBraking,initialSpeed,finalSpeed,maxAcceleration" +
            ("&startTime=" + startTime + "&endTime=" + endTime + "&limit=10000")).pipe(catchError(this.handleError));
    };
    LiveService.prototype.downloadTripSummary = function (deviceId, startTime, endTime) {
        return this.http.get(this.apiUrl +
            ("trips/device/" + deviceId + "/download?startTime=" + startTime + "&endTime=" + endTime), { responseType: 'arraybuffer', observe: 'response' })
            .pipe(catchError(this.handleError));
    };
    LiveService.prototype.downloadLiveSelectedDevices = function (messageType, assetName, searchKey, groupId, key, placeCords) {
        var tempURL = this.apiUrl + 'messages/latest/download';
        if (key['key'] && key['value']) {
            var char = tempURL.includes('?') ? '&' : '?';
            tempURL += char + "thresholdKey=" + key['key'] + "&thresholdValue=" + key['value'];
        }
        if (key['placeKey'] && key['placeValue']) {
            var char = tempURL.includes('?') ? '&' : '?';
            tempURL += "" + char + key['placeKey'] + "=" + key['placeValue'];
        }
        if (messageType) {
            var char = tempURL.includes('?') ? '&' : '?';
            tempURL += char + "status=" + messageType;
        }
        if (assetName) {
            var char = tempURL.includes('?') ? '&' : '?';
            tempURL += char + "searchKey=" + searchKey + "&searchValue=" + assetName;
        }
        if (groupId) {
            var char = tempURL.includes('?') ? '&' : '?';
            tempURL += char + "groups=" + groupId;
        }
        if (placeCords && placeCords.circle.newCenter.lat != null) {
            var char = tempURL.includes('?') ? '&' : '?';
            tempURL += char + "latitude=" + placeCords.circle.newCenter.lat + "&longitude=" + placeCords.circle.newCenter.lng + "&radius=" + placeCords.circle.radius;
        }
        return this.http.get(tempURL, { responseType: 'arraybuffer', observe: 'response' }).pipe(catchError(this.handleError));
    };
    LiveService.prototype.getVehicleHealthAndFuelScores = function (vin) {
        return this.http.get(this.apiUrl + 'analyticsProxy/dashboard/vehicleHealthAndFuelScore?vin=' + vin).pipe(catchError(this.handleError));
    };
    return LiveService;
}());
export { LiveService };
