import { OnInit } from '@angular/core';
import { SharedService } from '@shared/shared.service';
import { environment } from '@myenv/environment';
var PageNotFoundComponent = /** @class */ (function () {
    function PageNotFoundComponent(sharedService) {
        this.sharedService = sharedService;
        this.cmImageUrl = environment.cmImageUrl;
        this.isSession = false;
    }
    PageNotFoundComponent.prototype.ngOnInit = function () {
        if (localStorage.getItem('defaultUrl')) {
            this.defaultUrl = localStorage.getItem('defaultUrl');
        }
        else {
            this.defaultUrl = '/';
        }
        if (this.sharedService.getCurrentUser()) {
            this.isSession = true;
        }
    };
    return PageNotFoundComponent;
}());
export { PageNotFoundComponent };
