<app-notify-message></app-notify-message>
<app-reports-header></app-reports-header>
<div class="tab-content">
  <div role="tabpanel" class="tab-pane fade in active show relative" id="general">
    <div class="row no-gutters reports-page">
      <div class="p-0 col-sm-0" [ngClass]="{'col-md-2': !isSideMenuClose, 'no-width': isSideMenuClose}">
        <app-reports-side-menu (selectReport)="getReport($event)"></app-reports-side-menu>
      </div>
      <div class="reports-body col-sm-12 p-0" [ngClass]="{'col-md-10': !isSideMenuClose, 'col-md-12': isSideMenuClose}">
        <!-- Collapse/Expand reports side menu -->
        <div class="toggle-collapse-wrapper">
          <span
            *ngIf="!isSideMenuClose"
            tooltip="Collapse Side Panel"
            placement="right"
            (click)="onSideMenuToggle()"
            class="toggleBtn badge badge-secondary">
            <i class="fa fa-angle-double-left font-14" aria-hidden="true"></i>
          </span>
          <span
            *ngIf="isSideMenuClose"
            tooltip="Expand Side Panel"
            placement="right"
            (click)="onSideMenuToggle()"
            class="toggleBtn badge badge-secondary">
            <i class="fa fa-angle-double-right font-14" aria-hidden="true"></i>
          </span>
        </div>
        <!-- Reports content along with the action row -->
        <div class="row no-gutters action-row align-items-center pr-2 pl-2">
          <div class="col-md-3">
            <h4 class="report-heading page-heading"><i class="fa fa-bar-chart"></i> {{currentReport.name}}</h4>
          </div>
          <div class="col-md-9 text-right">
            <app-search-select-device
              *ngIf="currentReport.type !== 'VIN_CHANGE_2G2K' && currentReport.type !== 'DEVICE_COMMUNICATION_HEAT_MAP' && currentReport.type !== 'VPACKET_SYSTEM_REPORT'"
              [autoSelect]="true"
              [selectSearchCategory]="true"
              [querySelectDevice]="querySelectVehicle"
              (deviceChangeEvent)="changeVehicle($event)"
              >
            </app-search-select-device>
            <app-search-select-device
              *ngIf="currentReport.type == 'VIN_CHANGE_2G2K' && currentReport.type !== 'DEVICE_COMMUNICATION_HEAT_MAP' && currentReport.type !== 'VPACKET_SYSTEM_REPORT'"
              [autoSelect]="true"
              [selectSearchCategory]="true"
              [querySelectDevice]="querySelectDevice"
              (deviceChangeEvent)="changeDevice($event)"
              >
            </app-search-select-device>
            <div class="calendar ml-2 d-inline-block" (clickOutside)="hideCalendar=!hideCalendar;">
              <app-date-time-calendar
                *ngIf="currentReport.type !== 'DEVICE_COMMUNICATION_HEAT_MAP' && currentReport.type !== 'VPACKET_SYSTEM_REPORT'"
                [clickOutside]="hideCalendar"
                [selectPreset]="'today'"
                [vehicle]="searchModel.vehicle"
                [device]="searchModel.device"
                (timeChangeEvent)="onTimeChange($event)">
              </app-date-time-calendar>
              <app-date-time-calendar
                *ngIf="currentReport.type == 'DEVICE_COMMUNICATION_HEAT_MAP'"
                [hideLinks]="['today', '15mins', '30mins', '1hour', '4hours', '12hours', '24hours', 'todaysofar', '90days', '180days', '365days']"
                [selectPreset]="'7days'"
                [maxRangeDays]="365"
                [clickOutside]="hideCalendar"
                [selectPreset]="'today'"
                [vehicle]="searchModel.vehicle"
                [device]="searchModel.device"
                [timeSelection]="false"
                (timeChangeEvent)="onTimeChange($event)">
              </app-date-time-calendar>
            </div>
            <button
              *ngIf="currentReport.type !== 'DEVICE_COMMUNICATION_HEAT_MAP'"
              class="btn btn-icon ml-2"
              (click)="refresh()"
              tooltip="Refresh"
              placement="top"
              [disabled]="isLoading">
              <i class="fa fa-refresh" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <div class="row limit-width mx-0 align-items-center mt-2">
          <div class="col-12">
            <div class="row" *ngIf="reports.length">
              <div class="col-md-12">
                <!--<div class="w90 relative">-->
                <!-- <app-ngx-datatable [KeyUp]="pageCallback.bind(this)" [pageInfo]="pageInfo" [tableData]= "reports-old" [columnData]="columMenu"></app-ngx-datatable> -->
                <ngx-datatable
                  id="tableau" #dataTable
                  class="bootstrap alertTable registeredVehicle reportsTable wrapcell"
                  [loadingIndicator]="'true'"
                  [rows]="reports"
                  [columns]=""
                  [columnMode]="'force'"
                  [headerHeight]="50"
                  [footerHeight]="50"
                  [limit]="pageInfo.limit"
                  [offset]="pageInfo.offset"
                  [count]="pageInfo.count"
                  [rowHeight]="'auto'"
                  [summaryRow]="false"
                  [scrollbarH]="true"
                  [columnMode]="'force'"
                  [reorderable]="true">
                  <ngx-datatable-column name="#" [sortable]="false" [width]="50">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      {{row.index}}
                    </ng-template>
                  </ngx-datatable-column>
                  <ng-container *ngFor="let rm of columMenu">
                    <ngx-datatable-column [resizeable]="false" *ngIf="!rm.key.includes('reportId')"
                                          name="{{rm.displayName}}">
                      <ng-template ngx-datatable-header-template>
                        <span>{{rm.displayName}}</span>
                      </ng-template>
                      <!--<ng-template let-row="row" ngx-datatable-cell-template>
                        {{rm.key == 'eventTime'?(row[rm.key] | date : dateFormat) : row[rm.key] }}
                      </ng-template>-->
                      <ng-template let-row="row" ngx-datatable-cell-template>
                        <span *ngIf="dateAttributes.includes(rm.key)">{{row[rm.key] | date : dateFormat}}</span>
                        <span *ngIf="!dateAttributes.includes(rm.key)">{{row[rm.key]}}</span>
                        <!--{{rm.key == dateAttributes.includes(row[rm.key] | date : dateFormat) : row[rm.key])}}-->
                        <!--{{rm.key == 'eventTime'?(row[rm.key] | date : dateFormat) : row[rm.key] }}-->
                      </ng-template>
                    </ngx-datatable-column>
                  </ng-container>
                </ngx-datatable>
                <div class="total-count" *ngIf="totalMessageCount || this.currentReport.selectionType == 'GROUP'">
                  Showing
                  <span contenteditable="false" (input)="currentPageCount.start = $event.target.innerText">
                    {{currentPageCount.start}}
                  </span>
                  -
                  <span contenteditable="false" (input)="currentPageCount.end = $event.target.innerText">
                    {{currentPageCount.end}}
                  </span>
                  of <span *ngIf="this.currentReport.selectionType == 'GROUP'"> many</span> <span
                  *ngIf="this.currentReport.selectionType !== 'GROUP'"> {{(totalMessageCount.count)}}</span> records.
                </div>
                <!--</div>-->
              </div>
            </div>
            <div class="row" *ngIf="!reports.length">
              <div class="col-12 reports-download-btn text-center">
                <button
                  *ngIf="currentReport.type !== 'VIN_CHANGE_2G2K' && currentReport.type !== 'DEVICE_COMMUNICATION_HEAT_MAP'"
                  type="button" (click)="downloadReportCSV()"
                  [disabled]="disable"
                  class="btn btn-primary ml-2">
                  <i class="fa fa-cloud-download mr-2"></i> Download
                </button>
                <button
                  *ngIf="currentReport.type == 'VIN_CHANGE_2G2K' && currentReport.type !== 'DEVICE_COMMUNICATION_HEAT_MAP'"
                  style="min-width: 120px;" type="button"
                  (click)="downloadDeviceReportCSV()"
                  [disabled]="disable"
                  class="btn btn-primary ml-2">
                  <i class="fa fa-cloud-download mr-2"></i> Download
                </button>
                <button
                  *ngIf="currentReport.type == 'DEVICE_COMMUNICATION_HEAT_MAP'"
                  style="min-width: 120px;" type="button"
                  (click)="downloadHeatMapCSV()"
                  [disabled]="disable"
                  class="btn btn-primary ml-2">
                  <i class="fa fa-cloud-download mr-2"></i> Download
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
