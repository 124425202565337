import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '@myenv/environment';
var DataService = /** @class */ (function () {
    function DataService(http, router, handler) {
        this.http = http;
        this.router = router;
        this.handler = handler;
        this.apiUrl = environment.apiUrl;
        this.reportModule = [];
        this.httpClient = new HttpClient(handler);
        var permissionmodules = localStorage.getItem('permissionModules');
        this.reportModule = permissionmodules.includes('REPORT');
    }
    // To get the trips
    DataService.prototype.getTrips = function (deviceId, endTime, startTime, sort) {
        return this.http.get(this.apiUrl + ("trips/device/" + deviceId + "?startTime=" + startTime + "&endTime=" + endTime + "&sort=" + sort.toUpperCase()));
    };
    // To get the total History count
    DataService.prototype.getMessagesCount = function (deviceId, startTime, endTime, page, pagelimit, messageType) {
        var tempUrl = "" + this.apiUrl + 'messages/device/' + deviceId + '/count?startTime=' + startTime + '&endTime=' + endTime + '&page=' + page + '&limit=' + pagelimit;
        if (messageType) {
            tempUrl += "&messageType=" + messageType;
        }
        return this.http.get(tempUrl).pipe(catchError(this.handleError));
    };
    DataService.prototype.getMessagesCountOnSearch = function (deviceId, startTime, endTime, page, pagelimit, searchVal) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
        var tempUrl = "" + this.apiUrl + 'messages/device/' + deviceId + '/searchCount?startTime=' + startTime + '&endTime=' + endTime + "&attributes=eventTime,messageTypeId,messageId,messageType,deviceCommProtocol";
        return this.http.post(tempUrl, { searchKey: searchVal }, { headers: headers }).pipe(catchError(this.handleError));
    };
    // To download data
    DataService.prototype.downloadCSV = function (deviceId, endTime, startTime) {
        return this.http.get(this.apiUrl + 'messages/device/' + deviceId + '/download?startTime=' + startTime + '&endTime=' + endTime, { responseType: 'arraybuffer', observe: 'response' });
    };
    // To get all messages
    DataService.prototype.getallMessages = function (deviceId, startTime, endTime, sort, page) {
        return this.http.get(this.apiUrl + ("messages/device/" + deviceId + "?startTime=" + startTime + "&endTime=" + endTime) +
            ("&attributes=eventTime,messageTypeId,messageId,messageType,deviceCommProtocol&sort=" + sort.toUpperCase() + "&page=" + page)).pipe(catchError(this.handleError));
    };
    // To get the message
    DataService.prototype.getMessage = function (messageId) {
        return this.http.get(this.apiUrl + 'messages/' + messageId).pipe(catchError(this.handleError));
    };
    // To get the message
    DataService.prototype.generateQueryMessage = function (payload) {
        var tempUrl = "messages/device/" + payload.deviceId + "/filter?startTime=" + payload.startTime + "&endTime=" + payload.endTime + "&messageType=" + payload.messageType;
        if (payload.filter) {
            tempUrl = tempUrl + ("&filter=" + encodeURIComponent(payload.filter));
        }
        if (payload.attributes) {
            tempUrl = tempUrl + ("&attributes=" + (payload.attributes));
        }
        return this.http.get(this.apiUrl + tempUrl).pipe(catchError(this.handleError));
    };
    /**
     *
     */
    DataService.prototype.getMessageType = function (architectureType) {
        return this.http.get(this.apiUrl + ("messages/types?architectureType=" + architectureType)).pipe(catchError(this.handleError));
    };
    DataService.prototype.getMessageData = function (url, searchVal) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
        return this.http.post(this.apiUrl + url, searchVal, { headers: headers }).pipe(catchError(this.handleError));
    };
    DataService.prototype.handleError = function (error) {
        console.log(error.error || error.json() || error);
        return observableThrowError(error.error || error.json() || error || 'Server error');
    };
    return DataService;
}());
export { DataService };
