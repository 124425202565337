import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { SupportService } from '../../support.service';
import { SharedService } from '@myproject/shared/shared.service';
import { environment } from '@myenv/environment';
import { enMessage } from '@shared/en-us-message';

@Component({
  selector: 'app-provisioning',
  templateUrl: './provisioning.component.html',
  styleUrls: ['./provisioning.component.scss']
})
export class ProvisioningComponent implements OnInit {
  @Input() userData;
  @ViewChild('closeuploadModal', { static: false }) closeuploadModal: ElementRef;
  @ViewChild('closeRemarkModal', { static: false }) closeRemarkModal: ElementRef;
  @ViewChild('inputVaahanFile', { static: false }) inputVaahanFile: ElementRef;
  @ViewChild('inputBSNLFile', { static: false }) inputBSNLFile: ElementRef;
  public dateFormat = environment.isoDateTimeFormat;
  responseMessage: any = {};
  searchBySchema = [
    { displayName: 'Serial Number', key: 'serialNumber' },
    { displayName: 'SR ID', key: 'serviceRequestId' },
    { displayName: 'PSN', key: 'productSerialNumber' },
    { displayName: 'VIN', key: 'vin' },
    { displayName: 'Device Status', key: 'deviceStatus' },
    { displayName: 'Request ID', key: 'requestId' },
    { displayName: 'Request Flag', key: 'requestFlag' }
  ];
  deviceStatus = [
    { displayName: 'In Progress', key: 4 },
    { displayName: 'Request Failed', key: 5 },
    { displayName: 'Success', key: 6 },
    { displayName: 'Commercially Failed', key: 7 },
    { displayName: 'Retrigger', key: 8 }
  ];
  requestFlags = [
    { displayName: 'S', key: 'S' },
    { displayName: 'C', key: 'C' }
  ];
  searchModel = {
    searchBy: 'serialNumber',
    searchValue: '',
    remarks: ''
  };
  page = 0;
  devices = [];
  prevResponse = 0;
  pageLoading = false;
  pageInfo: any = {
    offset: 0,
    count: 0,
    limit: 10,
    pageSize: 0,
    lastPage: 0,
  };
  currentPageCount: any = {
    start: 0,
    end: 0
  };
  selectedDevice: any = {};
  vahanFileSelect = false;
  bsnlFileSelect = false;
  disable = false;
  activating = false;
  activationStatus = '';
  // currentUser: any = {};
  showConfirmModal = false;
  hideConfirmModal = false;
  confirmSelectDevice: any = {};
  @ViewChild('inputElement', { static: false }) inputOne: ElementRef;

  constructor(
    private supportService: SupportService,
    private sharedService: SharedService
  ) { }

  ngOnInit() {
    this.pageLoading = true;
    // this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.getProvisioningDevices(0);
  }

  searchDataOnChange() {
    if (['deviceStatus', 'requestFlag'].includes(this.searchModel.searchBy)) {
      if (this.searchModel.searchValue) {
        this.searchModel.searchValue = null;
        this.onSearch();
      } else {
        this.searchModel.searchValue = null;
      }
    } else if ([4, 5, 6, 7, 'S', 'C'].includes(this.searchModel.searchValue)) {
      this.searchModel.searchValue = '';
      this.onSearch();
    } else if (this.searchModel.searchValue === null) {
      this.searchModel.searchValue = '';
    }
    if (this.searchModel.searchValue && this.searchModel.searchBy) {
      this.onSearch();
    }
  }

  onSearch() {
    this.pageLoading = true;
    this.page = 0;
    this.pageInfo.offset = 0;
    if (!this.searchModel.searchValue) {
      this.getProvisioningDevices(0);
    } else {
      this.provisionDeviceSearch(0, this.searchModel.searchBy, this.searchModel.searchValue);
    }
  }

  provisionDeviceSearch(page, key, value) {
    this.supportService.provisionDeviceSearch(page, key, value, this.userData.customerId).subscribe({
      next: (res: []) => {
        if (page === 0) {
          this.devices = res;
        } else {
          this.devices = [...this.devices, ...res];
        }
        this.pageLoading = false;
        this.prevResponse = res.length;
      },
      error: error => {
        this.pageLoading = false;
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  selectInput() {
    const inputElem = <HTMLInputElement>this.inputOne.nativeElement;
    inputElem.select();
  }

  getProvisioningDevices(page) {
    this.supportService.getProvisioningDevices(page, this.userData.customerId).subscribe({
      next: (res: []) => {
        if (page === 0) {
          this.devices = res;
        } else {
          this.devices = [...this.devices, ...res];
        }
        this.pageLoading = false;
        this.prevResponse = res.length;
      },
      error: error => {
        this.pageLoading = false;
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  // To get the vehicles for each page in the table
  pageCallback(value) {
    this.pageInfo.offset = value.offset;
    this.currentPageCount.start = (value.offset * value.pageSize) + 1;
    const lastPage = Math.floor(this.devices.length / 10);
    this.pageInfo.lastPage = lastPage;
    if (lastPage == value.offset) {
      this.currentPageCount.end = value.count;
    } else {
      this.currentPageCount.end = (value.offset * value.pageSize) + value.limit;
    }
    if (this.pageInfo.lastPage === (this.pageInfo.offset + 1)) {
      if (this.prevResponse) {
        this.page = ++this.page;
        if (this.searchModel.searchValue) {
          this.provisionDeviceSearch(this.page, this.searchModel.searchBy, this.searchModel.searchValue);
        } else {
          this.getProvisioningDevices(this.page);
        }
      }
    }
  }

  downloadKYC(device) {
    this.supportService.downloadKYC(device.productSerialNumber).subscribe(data => {
      const blob = new Blob([data], {
        type: 'application/zip'
      });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  }

  onFileSelect(event, type) {
    if (event.target.files.length != 0) {
      if (type === 'vahan') { this.vahanFileSelect = false; }
      if (type === 'bsnl') { this.bsnlFileSelect = false; }
    } else {
      if (type === 'vahan') { this.vahanFileSelect = true; }
      if (type === 'bsnl') { this.bsnlFileSelect = true; }
    }
  }

  uploadCertificates(value: any) {
    const vahanFile = this.inputVaahanFile.nativeElement.files[0];
    const bsnlFile = this.inputBSNLFile.nativeElement.files[0];
    if (vahanFile && bsnlFile) {
      this.disable = true;
      const formData = new FormData();
      formData.append('vahanFile', vahanFile);
      formData.append('bsnlFile', bsnlFile);
      this.supportService.uploadCertificates(
        formData, this.selectedDevice.productSerialNumber, 'success'
      ).subscribe({
        next: res => {
          this.closeuploadModal.nativeElement.click();
          this.responseMessage = { success: true, message: enMessage.provisionUploadSuccess };
          this.sharedService.setResponseMessage(this.responseMessage);
          this.disable = false;
          this.page = 0;
          this.pageInfo.offset = 0;
        }, error: error => {
          this.sharedService.getErrorMsg(error);
          this.disable = false;
          // this.closeuploadModal.nativeElement.click();
        }
      });
    } else {
      this.disable = false;
      if (!vahanFile) { this.vahanFileSelect = true; }
      if (!bsnlFile) { this.bsnlFileSelect = true; }
    }
  }

  activateDevice(isValid) {
    if (isValid) {
      const device = this.selectedDevice;
      this.activating = true;
      this.supportService.activateDevice(
        device.requestId, device.productSerialNumber, this.activationStatus, 'yes', this.searchModel.remarks
      ).subscribe({
        next: res => {
          if (this.activationStatus === 'SUCCESS') {
            this.responseMessage = { success: true, message: enMessage.provisionActivateSuccess };
          } else if (this.activationStatus === 'FAILED') {
            this.responseMessage = { success: true, message: enMessage.provisionActivateFail };
          }
          this.sharedService.setResponseMessage(this.responseMessage);
          this.page = 0;
          this.pageInfo.offset = 0;
          this.searchModel.searchValue = null;
          this.getProvisioningDevices(0);
          this.closeRemarkModal.nativeElement.click();
          this.searchModel.remarks = '';
          this.activationStatus = '';
          this.activating = false;
        },
        error: error => {
          // this.activating = false;
          // this.activationStatus = '';
          // this.closeRemarkModal.nativeElement.click();
          this.sharedService.getErrorMsg(error);
        }
      });
    }
  }

  retriggerDevice() {
    this.disable = true;
    const d = this.confirmSelectDevice;
    this.supportService.retriggerDevice(d.requestId, d.productSerialNumber).subscribe({
      next: res => {
        this.disable = false;
        this.hideConfirmModal = !this.hideConfirmModal;
        this.responseMessage = { success: true, message: enMessage.successTriggerFlashDevice };
        this.sharedService.setResponseMessage(this.responseMessage);
        this.page = 0;
        this.pageInfo.offset = 0;
        this.searchModel.searchValue = null;
        this.getProvisioningDevices(0);
      },
      error: error => {
        this.disable = false;
        this.sharedService.getErrorMsg(error);
      }
    });
  }
}
