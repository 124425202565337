<div class="support-content">
  <div class="row">
    <div class="col-12">
      <ul class="nav nav-tabs detailed-tabs relative page-sub-heading" role="tablist">
        <li class="nav-item active">
          <a class="nav-link text-center mt-1 active ml-4" role="tab" data-toggle="tab" href="#search" (click)="loadSearch()">
            <i class="fa fa-search"></i> Account Search
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-center mt-1" role="tab" data-toggle="tab" href="#notReporting" (click)="loadNoReport()">
            <i class="fa fa-exclamation-triangle"></i> Not Reporting Devices
          </a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link text-center mt-1" role="tab" data-toggle="tab" href="#dashboard" (click)="loadDashboard()">
            <i class="fa fa-tachometer"></i> Dashboard
          </a>
        </li> -->
        <li *ngIf="permissionModules.includes('DEVICE-SHIFT')" class="nav-item">
          <a class="nav-link text-center mt-1 " role="tab" data-toggle="tab" href="#moveDevice" (click)="loadMoveDevices()">
            <i class="fa fa-tachometer"></i> Move Devices
          </a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link text-center mt-1 " role="tab" data-toggle="tab" href="#billingReport" (click)="loadBillingReport()">
            <i class="fa fa-tachometer"></i> Billing Report
          </a>
        </li> -->
        <!-- <li class="nav-item">
          <a class="nav-link text-center mt-1" role="tab" data-toggle="tab" href="#ota" (click)="loadOta()">
              <i class="fa fa-bolt"></i> OTA
          </a>
        </li> -->
      </ul>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div role="tabpanel" class="tab-pane fade in active show relative" id="search">
        <app-advanced-search *ngIf="searchTab"></app-advanced-search>
      </div>

      <div role="tabpanel" class="tab-pane fade in relative" id="notReporting">
        <app-not-communicating *ngIf="noReportTab"></app-not-communicating>
      </div>

      <!-- <div role="tabpanel" class="tab-pane fade in relative" id="dashboard">
        <app-support-dashboard *ngIf="dashboardTab"></app-support-dashboard>
      </div> -->

      <div role="tabpanel" class="tab-pane fade in relative" id="moveDevice">
        <app-move *ngIf="moveDeviceTab"></app-move>
      </div>

      <!-- <div role="tabpanel" class="tab-pane fade in relative" id="billingReport" *ngIf="billingReportTab">
        <app-billing-report></app-billing-report>
      </div> -->
      <!-- <div *ngIf="permissionModules?.includes('SUPPORT-OTA');" role="tabpanel" class="tab-pane fade in relative" id="ota">
        <app-support-campaign *ngIf="otaTab"></app-support-campaign>
      </div> -->
    </div>
  </div>
</div>
