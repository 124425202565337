<div class="sidebar-object">
  <div class="reportScrollSideMenu">
    <ul class="list-recent reportsSidemenu">
      <ng-container *ngFor="let data of sideData; let i=index;">
        <li class="clearfix ripple side-menu-list-item" routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}" *ngIf="data.name !== 'menu.MyNotifications'">
          <a class="pb-2 pt-2" routerLink="{{data.route}}" style="cursor: pointer; display: block;">
            <span class="sideMenuIconBox">
              <!-- <img src="{{cmImageUrl}}{{data.img}}" class="img-responsive side-menu-icon"> -->
              <i class="fa fa-{{data.img}} side-menu-icon"></i>
            </span>
            <span class="side-menu-text">{{data.name | translate}}</span>
          </a>
        </li>
      </ng-container>
    </ul>
  </div>
</div>