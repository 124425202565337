<app-notify-message></app-notify-message>
<app-reports-header></app-reports-header>
<div class="row no-gutters reports-page">
  <div class="col-md-2 col-sm-0 p-0">
    <app-reports-side-menu></app-reports-side-menu>
  </div>
  <div class="reports-body col-md-10 col-sm-12 p-0">
    <div class="row no-gutters action-row align-items-center px-2">
      <div class="col-md-3">
        <h4 class="report-heading page-heading mb-0">
          <i class="fa fa-bar-chart"></i> Device Detail
        </h4>
      </div>
      <div class="col-md-6">
        <!--<div class="row">-->
        <form (ngSubmit)="f.form.valid && searchResults()" #f="ngForm" novalidate class="search-wrapper width100">
          <div class="mt-1 d-flex">
            <div class="select-container text-left">
              <ng-select
                [items]="deviceSearchheader"
                bindLabel="displayName"
                bindValue="name"
                [multiple]="false"
                [closeOnSelect]="true"
                name="deviceId"
                (change)="searchResults(); selectInput()"
                [(ngModel)]="searchModel.deviceId"
                placeholder="Select Type"
                #deviceId="ngModel"
                required="">
              </ng-select>
              <div class="alert-container" *ngIf="f.submitted && deviceId.invalid">
                <small *ngIf="deviceId.errors.required" class="text-danger">
                  Please select type
                </small>
              </div>
            </div>
            <div class="container-input">
              <input
                #inputElement
                (keyup)="inputValidator($event)"
                placeholder="Search" type="text"
                name="deviceTypeVal" required
                [(ngModel)]="searchModel.deviceTypeVal"
                #deviceTypeVal="ngModel"
              />
              <i class="fa fa-search img-responsive search-icon cursor-pointer" aria-hidden="true"></i>
            </div>
          </div>
        </form>
      </div>
      <div class="col-md-3 d-flex justify-content-end mobile-design admin-columns">
        <div class="calendar d-inline-block ml-2">
          <button
            class="btn btn-icon small-screen-btn ml-2 mt-1 columns"
            tooltip="Configure Columns"
            placement="top"
            (click)="togglePopup()">
            <i class="fa fa-columns"></i>
            <span class="mob-access-text d-none">&nbsp;Columns</span>
          </button>
          <button
            class="btn btn-icon ml-2 mt-1 refresh"
            (click)="refreshPage()"
            tooltip="Refresh"
            placement="top"
            [disabled]="loadingIndicator">
            <i class="fa fa-refresh" aria-hidden="true"></i>
            <span class="mob-access-text d-none">&nbsp;Refresh</span>
          </button>
          <div class="dropdown d-inline filter-icons">
            <i id="dropdownDownload" tooltip="Download" placement="top"
               class="fa fa-download dropdown-toggle"
               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            </i>
          <!--<div id="dropdownDownload" class="btn btn-custom dropdown ml-2 mt-1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
            <!--<i tooltip="Download" placement="top"-->
               <!--class="fa fa-cloud-download dropdown-toggle">-->
            <!--</i>-->
            <div class="dropdown-menu" aria-labelledby="dropdownDownload">
              <a class="dropdown-item" (click)="downloadDeviceCSV()" href="javascript:void(0)"><i class="fa fa-download"></i> Download All</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" (click)="downloadSelectedDeviceCSV()" href="javascript:void(0)"><i class="fa fa-download"></i> Download Selected</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" data-toggle="modal" data-target="#userGroup_modal" (click)="getCustomDownloadKeysPref()" href="javascript:void(0)">
                <i class="fa fa-download"></i> Download Custom
              </a>
            </div>
          </div>
          <div class="selectColumnsDropdown" *ngIf="columnPopup">
            <!-- <div class="title">
              <h5>Columns</h5>
            </div> -->
            <div class="column-search">
              <input type="text" name="userTypeVal" required [(ngModel)]="searchModel.columnVal"
                     #columnVal="ngModel" (keyup)="onColumnSearch()" autofocus placeholder="Search">
              <span class="cross-icon" *ngIf="searchModel.columnVal" (click)="columns = allColumns; searchModel.columnVal=''">
                <i class="fa fa-times" aria-hidden="true"></i>
              </span>
              <span><i class="fa fa-search img-responsive search-icon" aria-hidden="true"></i></span>
            </div>
            <div *ngIf="columns.length" class="content">
              <div class="item" *ngIf="!this.searchModel.columnVal">
                <label class="box-container">Select All
                  <input type="checkbox" [checked]="selectCount===allColumns.length"
                         (change)="selectAllItems($event)">
                  <span class="checkmark"></span>
                </label>
              </div>
              <ng-container *ngFor="let header of columns; let i=index;">
                <div class="item"
                     *ngIf="header.visible"
                     [class.disabled]="this.noPrefColumns.includes(header.name)">
                  <label class="box-container">{{header.displayName}}
                    <input type="checkbox" [checked]="header.checked"
                           (change)="selectItems($event, header, i)"
                           [disabled]="this.noPrefColumns.includes(header.name)">
                    <span class="checkmark"></span>
                  </label>
                </div>
              </ng-container>
            </div>
            <div *ngIf="!columns.length" class="content">
              <div class="item text-center bold">
                No Data Found
              </div>
            </div>
            <div class="actions text-right">
              <button class="btn btn-outline-secondary small-screen-btn mr-1" (click)="cancelSelection()"
                      [disabled]="updatingPref">
                Cancel
              </button>
              <button class="btn btn-primary small-screen-btn" (click)="updatePreference()"
                      [disabled]="updatingPref">
                Apply
              </button>
            </div>
          </div>
          <!--<button-->
            <!--class="btn btn-custom ml-2 mt-1 download"-->
            <!--tooltip="Download"-->
            <!--placement="top"-->
            <!--(click)="downloadDeviceCSV()">-->
            <!--<i class="fa fa-cloud-download"></i>-->
            <!--<span class="mob-access-text d-none">&nbsp;Download</span>-->
          <!--</button>-->
        </div>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-md-12 px-2 py-3">
        <div class="report-item-container">
          <div class="page-loading" *ngIf="pageLoading">
            <img src="../../../assets/img/darby_loader_small.gif"/>
          </div>
          <div *ngIf="!devices.length && !pageLoading" class="text-center my-5 py-5">
            <img width="170" src="../../../assets/img/info_nodata_256.png"/>
            <h4 class="mt-3">No data to show</h4>
          </div>
          <ngx-datatable
            id="tableau" #myTable *ngIf="devices.length"
            class="bootstrap alertTable reportsTable wrapcell"
            [rows]="devices"
            [columns]="finalHead"
            [columnMode]="'force'"
            [headerHeight]="50"
            [footerHeight]="50"
            [rowHeight]="'auto'"
            [summaryRow]="false"
            [scrollbarH]="true"
            [loadingIndicator]="loadingIndicator"
            [limit]="pageInfo.limit"
            [offset]="pageInfo.offset"
            [count]="pageInfo.count"
            (page)="pageCallback($event)"
            [externalSorting]="true"
            (sort)="onSort($event)"
            [reorderable]="true">
            <!--[externalPaging]="true"-->
            <ngx-datatable-column [resizeable]="false" [sortable]="false" name="#" [width]="50">
              <ng-template let-rowIndex="rowIndex" ngx-datatable-cell-template>
                {{rowIndex+1}}
              </ng-template>
            </ngx-datatable-column>
            <ng-container *ngFor="let dh of finalHead">
              <ngx-datatable-column [resizeable]="false" name="{{dh.displayName}}" [sortable]="true"
                                    *ngIf="!dh.name.includes('customerId') && !dh.name.includes('companyId')  && dh.visible">
                <!-- <ng-template ngx-datatable-header-template>
                  <span>{{dh.displayName}}</span>
                </ng-template> -->
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <!--<span *ngIf="dateAttributes.includes(dh.name)">{{row[dh.name] | date : dateFormat}}</span>-->
                  <span *ngIf="dh.type=='DATE'">{{row[dh.name] | date : dateFormat}}</span>
                  <span [class]="'class-' + deviceStatusClass[row[dh.name]]" *ngIf="dh.name=='deviceStatus'">{{deviceStatus[row[dh.name]]}}</span>
                  <span
                    *ngIf="dh.type != 'DATE' && dh.name !== 'deviceStatus' && dh.name !== 'address'">{{row[dh.name]}}</span>
                  <a class="link-color" *ngIf="dh.name == 'address' && row['latitude'] && row['longitude']"
                      data-toggle="modal"
                      (click)="loadMap(row)"
                      data-target="#addNewUser_modal">
                    <span class="d-inline-block" [tooltip]="row[dh.name]" placement="top" *ngIf="row[dh.name]">{{row[dh.name] | ellipses:50}}</span>
                    <span *ngIf="!row[dh.name]">No address found</span>
                  </a>

                  <span *ngIf="dh.name == 'address' && (!row['latitude'] && !row['longitude'])">
                        No address found
                      </span>
                </ng-template>
              </ngx-datatable-column>
            </ng-container>
          </ngx-datatable>
          <div class="total-count" *ngIf="deviceTotalCount && deviceTotalCount.count">
            Showing
            <span contenteditable="false" (input)="currentPageCount.start = $event.target.innerText">
                {{currentPageCount.start}}
              </span>
            -
            <span contenteditable="false" (input)="currentPageCount.end = $event.target.innerText">
                {{currentPageCount.end}}
              </span>
            of {{(deviceTotalCount.count)}} devices.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!--start Popup custom Header Download-->
<div id="userGroup_modal" class="modal effect-flip-horizontal fade">
  <div class="modal-dialog" role="document">
    <div class="modal-content bd-0 bg-transparent overflow-hidden">
      <div class="modal-body p-0">
        <div class="row no-gutters">
          <div class="col-12">
            <span #closeHeaderPopup class="pull-right closeBtn" data-dismiss="modal">
              <i class="fa fa-times" aria-hidden="true"></i>
            </span>
            <div class="modal-box p-30">
              <div class="reply-header">
                <h5>Custom Download Keys</h5>
              </div>
              <!--<div *ngFor="let data of columns">-->
              <!--<p>{{data.displayName}}</p>-->
              <!--</div>-->
              <div class="row my-4">
                <div class="col-lg-10 seclectAlertVehicle seclectGeoVehicle">
                  <div class="input-group input-group--style-1">
                    <angular2-multiselect
                      #customHeader
                      [data]="visibleKeys"
                      [(ngModel)]="selectedHeaders"
                      name="name"
                      id="name"
                      #name="ngModel"
                      [settings]="headerSettings"
                      (onSelect)="onHeaderSelect($event)"
                      (onDeSelect)="onHeaderDeSelect($event)"
                      (onFilterSelectAll)="onSelectAllHeaders($event)"
                      (onFilterDeSelectAll)="onDeSelectAllHeaders($event)"
                      (onSelectAll)="onSelectAllHeaders($event)"
                      (onDeSelectAll)="onDeSelectAllHeaders($event)"
                      required>
                    </angular2-multiselect>
                  </div>
                  <label class="control-label multiselect-label">Select Keys to Download</label>
                </div>
                <div class="col-md-2 pl-0 text-right">
                  <button
                    class="btn btn-success width100 height100"
                    (click)="downloadCustomDeviceCSV()"
                    [disabled]="!selectedHeaders.length"
                  >
                    <i class="fa fa-download" style="font-size: 30px;"></i>
                  </button>
                </div>
              </div>
              <div class="mt-2 list-body">
                <div class="selectable-list animated fadeIn" *ngFor="let data of selectedHeaders">
                  <div class="item-name d-inline-block">
                    <i class="fa fa-object-ungroup" aria-hidden="true"></i>
                    &nbsp;{{data.displayName}}
                  </div>
                  <div class="item-actions d-inline-block"
                       (click)="removeHeader(data)">
                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--end Popup custom Header Download-->

<!--Latitude, longitude, map-->
<div data-keyboard="false" data-backdrop="static" id="addNewUser_modal" class="modal effect-flip-horizontal fade">
  <div class="modal-dialog addPlace-modal" role="document">
    <div class="modal-content bd-0 bg-transparent overflow-hidden">
      <div class="modal-body p-0">
        <div class="row no-gutters">
          <div class="col-12">
            <span #addNewUser_modal class="pull-right closeBtn" data-dismiss="modal">
              <i class="fa fa-times" aria-hidden="true"></i>
            </span>
            <div class="modal-box p-3 mt-4">
              <div class="reply-header">
                <h5>
                  Latest Location
                </h5>
              </div>
              <div class="row mt-3" *ngIf="selectedDevice">
                <div class="col-sm-5" *ngIf="selectedDevice.assetName">
                  <i class="fa fa-car mr-1"></i> <span class="break-word"> {{selectedDevice.assetName}}</span>
                </div>
                <div class="col-sm-5" *ngIf="selectedDevice.serialNumber">
                  <i class="fa fa-microchip mr-1"></i> <span class="break-word"> {{selectedDevice.serialNumber}}</span>
                </div>
              </div>
              <div class="login-content mt-3">
                <div id="map" #gmap style="width:100%;height:40vh;"></div>
              </div>
              <div class="row mt-3" *ngIf="selectedDevice">
                <div class="col-sm-10">
                  <span>Address:</span><br>
                  <span class="font-14" *ngIf="selectedDevice.address"> {{selectedDevice.address}}</span>
                  <span class="font-14" *ngIf="!selectedDevice.address"> No address found</span>
                </div>
              </div>
              <div class="row mt-3 mb-5" *ngIf="selectedDevice">
                <div class="col-sm-12">
                  <a class="font-14 text-blue-grey" [href]="'http://maps.google.com/?q='+selectedDevice.latitude+','+selectedDevice.longitude" target="_blank"><u>Latitude, longitude: {{selectedDevice.latitude}}, {{selectedDevice.longitude}} (Open in Google Maps)</u></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Latitude, longitude, map-->

