import {from as observableFrom, Observable} from 'rxjs';
import {Location} from '@angular/common';
import {Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';

import {Router, ActivatedRoute} from '@angular/router';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse} from '@angular/common/http';

export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  resetToken: string = '';

  constructor(private router: Router, private route: ActivatedRoute) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return observableFrom(this.handleAccess(request, next));

  }

  private async handleAccess(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    const token = await localStorage.getItem('token');
    const currentUser = localStorage.getItem('currentUser');
    const reportName = sessionStorage.getItem('reportName');
    // var x = JSON.parse(currentUser)

    const currentPath = window.location.pathname;
    let changedRequest = request;

    const headerSettings: any = {};
    for (const key of request.headers.keys()) {
      headerSettings[key] = request.headers.getAll(key);
    }
    if (!window.location.href.includes("tracking?key")) {
      if (token) {
        headerSettings['Authorization'] = 'Bearer ' + token;
      }
    }
    if (currentUser) {
      headerSettings['customInfo'] = currentPath + '/' + reportName;
    }
    // if (reportName) {
    //   headerSettings['reportName'] = reportName;
    // }
    this.route.params.subscribe(params => {
      this.resetToken = params.id;
    })
    if ((this.router.url === '/forgot-password')) {
      headerSettings['Content-Type'] = 'application/x-www-form-urlencoded';
    } else {
      headerSettings['Content-Type'] = 'application/json';
    }
    headerSettings['observe'] = 'response';
    const newHeader = new HttpHeaders(headerSettings);

    changedRequest = request.clone({
      headers: newHeader
    });
    return next.handle(changedRequest).toPromise();

  }

}
