<app-notify-message></app-notify-message>
<app-reports-header></app-reports-header>
<div class="row no-gutters">
  <div class="col-md-2 col-sm-0 p-0">
    <app-reports-side-menu></app-reports-side-menu>
  </div>
  <div class="reports-body col-md-10 col-sm-12 p-0">
    <div class="row no-gutters align-items-center action-row px-2">
      <div class="col-md-5">
        <h4 class="report-heading page-heading"><i class="fa fa-bar-chart"></i> Software Version</h4>
      </div>
      <div class="col-md-7 text-right">
        <div class="calendar d-inline-block ml-2" (clickOutside)="hideCalendar=!hideCalendar;">
          <app-date-time-calendar
            [clickOutside]="hideCalendar"
            [selectPreset]="'30days'"
            [timeSelection]="false"
            [hideLinks]="['today', '15mins', '30mins', '1hour', '4hours', '12hours']"
            (timeChangeEvent)="onTimeChange($event)"
            >
          </app-date-time-calendar>
          <button
            class="btn btn-icon ml-2 refresh"
            (click)="refresh()"
            tooltip="Refresh"
            placement="top"
            [disabled]="isLoading">
            <i class="fa fa-refresh" aria-hidden="true"></i>
            <span class="mob-access-text d-none">&nbsp;Refresh</span>
          </button>
        </div>
      </div>
    </div>
    <div class="row no-gutters pt-1">
      <div class="col-md-3 col-12 p-2">
        <div class="report-item-container animated fadeIn">
          <h6>Firmware</h6>
          <canvas id="pieChartFW" height="186" role="img"></canvas>
          <!-- Custom FW table with search start -->
          <div class="card table-container mt-3">
            <div class="category-search relative">
              <input
                type="text"
                name="fwSearch"
                #columnVal="ngModel"
                placeholder="Search Firmware"
                [(ngModel)]="searchModel.fwSearchVal"
                (keyup)="onFWSearch()"
              />
              <span class="cross-icon"
                *ngIf="searchModel.fwSearchVal"
                (click)="searchModel.fwSearchVal='';onFWSearch();">
                <i class="fa fa-times search-icon" aria-hidden="true"></i>
              </span>
              <span *ngIf="!searchModel.fwSearchVal">
                <i class="fa fa-search img-responsive search-icon" aria-hidden="true"></i>
              </span>
            </div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item"
                *ngFor="let data of searchModel.fwSearchedData;"
                [class.selected]="fwSelected.firmwareVersion===data.firmwareVersion"
                (click)="selectFirmware(data)">
                <div class="d-inline-block pr-1" style="width: 69%;">
                  <label>Firmware</label><br>
                  <span style="word-break: break-all;">{{data.firmwareVersion}}</span>
                </div>
                <div class="d-inline-block" style="width: 29%;">
                  <label>Count - %</label><br>
                  <span>{{data.count}} - {{data.percentage}}%</span>
                </div>
              </li>
              <li *ngIf="!searchModel.fwSearchedData.length && !isLoading" class="text-center">
                <span style="font-style: italic; opacity: 0.5;">No Data</span>
              </li>
            </ul>
          </div>
          <!-- Custom FW table with search end -->
        </div>
      </div>
      <div class="col-md-3 col-12 p-2">
        <div class="report-item-container animated fadeIn">
          <h6>Config</h6>
          <canvas id="pieChartCFG" height="186" role="img"></canvas>
          <!-- Custom Config table with search start -->
          <div class="card table-container mt-3">
            <div class="category-search relative">
              <input
                type="text"
                name="cfgSearch"
                #columnVal="ngModel"
                placeholder="Search Config"
                [(ngModel)]="searchModel.cfgSearchVal"
                (keyup)="onCFGSearch()"
              />
              <span class="cross-icon"
                *ngIf="searchModel.cfgSearchVal"
                (click)="searchModel.cfgSearchVal='';onCFGSearch();">
                <i class="fa fa-times search-icon" aria-hidden="true"></i>
              </span>
              <span *ngIf="!searchModel.cfgSearchVal">
                <i class="fa fa-search img-responsive search-icon" aria-hidden="true"></i>
              </span>
            </div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item"
                *ngFor="let data of searchModel.cfgSearchedData;"
                [class.selected]="cfgSelected.configVersion===data.configVersion"
                (click)="selectConfig(data)">
                <div class="d-inline-block pr-1" style="width: 69%;">
                  <label>Config</label><br>
                  <span style="word-break: break-all;">{{data.configVersion}}</span>
                </div>
                <div class="d-inline-block" style="width: 29%;">
                  <label>Count - %</label><br>
                  <span>{{data.count}} - {{data.percentage}}%</span>
                </div>
              </li>
              <li *ngIf="!searchModel.cfgSearchedData.length && !isLoading" class="text-center">
                <span style="font-style: italic; opacity: 0.5;">No Data</span>
              </li>
            </ul>
          </div>
          <!-- Custom Config table with search end -->
        </div>
      </div>
      <div class="col-md-6 col-12 p-2">
        <div class="report-item-container animated fadeIn">
          <h6 style="text-transform: capitalize;">Trend line</h6>
          <canvas id="areaGraph" height="177" role="img"></canvas>
        </div>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-12 p-2">
        <div class="report-item-container animated fadeIn" style="min-height: 25vh;">
          <app-reports-table
            [startTime]="startTime"
            [endTime]="endTime"
            [displayTitle]="'Devices'"
            [fromComponent]="'version'"
            [firmware]="fwSelected.firmwareVersion"
            [config]="fwSelected.configVersion"
            [isLoadingCFG]="isLoadingCFG"
            [firmwareDevicesCount]="firmwareDevicesCount"
            >
          </app-reports-table>
          <!-- [devicesList]="devices"
          [blockCall]="blockCall"
          [isLoadingFW]="isLoadingFW"
          [changeOffset]="resetOffset"
          (pageChangeEvent)="onPageChange($event)"
          (downloadEvent)="downloadFwConfigDevices($event)" -->
        </div>
      </div>
    </div>
  </div>
</div>
