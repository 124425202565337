
import { throwError as observableThrowError, Observable } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '@myenv/environment';

@Injectable()
export class DataService {
  public apiUrl = environment.apiUrl;
  reportModule: any = [];
  private httpClient: HttpClient;

  constructor(private http: HttpClient, private router: Router, private handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
    const permissionmodules = localStorage.getItem('permissionModules');
    this.reportModule = permissionmodules.includes('REPORT');
  }


  // To get the trips
  getTrips(deviceId, endTime, startTime, sort) {
    return this.http.get(
      this.apiUrl + `trips/device/${deviceId}?startTime=${startTime}&endTime=${endTime}&sort=${sort.toUpperCase()}`
    );
  }

  // To get the total History count
  getMessagesCount(deviceId, startTime, endTime, page, pagelimit, messageType?): Observable<any> {
    let tempUrl = `${this.apiUrl}` + 'messages/device/' + deviceId + '/count?startTime=' + startTime + '&endTime=' + endTime + '&page=' + page + '&limit=' + pagelimit;
    if (messageType) {
      tempUrl += `&messageType=${messageType}`;
    }
    return this.http.get(tempUrl).pipe(catchError(this.handleError));
  }
  getMessagesCountOnSearch(deviceId, startTime, endTime, page, pagelimit, searchVal): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    let tempUrl = `${this.apiUrl}` + 'messages/device/' + deviceId + '/searchCount?startTime=' + startTime + '&endTime=' + endTime + `&attributes=eventTime,messageTypeId,messageId,messageType,deviceCommProtocol`;
    return this.http.post(tempUrl, { searchKey: searchVal },
      { headers }).pipe(
        catchError(this.handleError));
  }
  // To download data
  downloadCSV(deviceId, endTime, startTime) {
    return this.http.get(this.apiUrl + 'messages/device/' + deviceId + '/download?startTime=' + startTime + '&endTime=' + endTime, { responseType: 'arraybuffer', observe: 'response' })
  }

  // To get all messages
  getallMessages(deviceId, startTime, endTime, sort, page) {
    return this.http.get(
      this.apiUrl + `messages/device/${deviceId}?startTime=${startTime}&endTime=${endTime}` +
      `&attributes=eventTime,messageTypeId,messageId,messageType,deviceCommProtocol&sort=${sort.toUpperCase()}&page=${page}`
    ).pipe(
      catchError(this.handleError));
  }

  // To get the message
  getMessage(messageId) {
    return this.http.get(this.apiUrl + 'messages/' + messageId).pipe(
      catchError(this.handleError));
  }

  // To get the message
  generateQueryMessage(payload) {
    let tempUrl = `messages/device/${payload.deviceId}/filter?startTime=${payload.startTime}&endTime=${payload.endTime}&messageType=${payload.messageType}`;
    if (payload.filter) {
      tempUrl = tempUrl + `&filter=${encodeURIComponent(payload.filter)}`;
    }
    if (payload.attributes) {
      tempUrl = tempUrl + `&attributes=${(payload.attributes)}`;
    }
    return this.http.get(this.apiUrl + tempUrl).pipe(
      catchError(this.handleError));
  }

  /**
   *
   */
  getMessageType(architectureType): Observable<any> {
    return this.http.get(this.apiUrl + `messages/types?architectureType=${architectureType}`).pipe(
      catchError(this.handleError));
  }

  getMessageData(url, searchVal): Observable<any> {

    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    return this.http.post(this.apiUrl + url, searchVal,
      { headers }).pipe(
        catchError(this.handleError));
  }

  handleError(error: any | any) {

    console.log(error.error || error.json() || error);
    return observableThrowError(error.error || error.json() || error || 'Server error');
  }

}
