import { OnInit } from '@angular/core';
import { environment } from "@myenv/environment";
var ContactUsComponent = /** @class */ (function () {
    function ContactUsComponent() {
        this.contactEmail = environment.contactEmail;
    }
    ContactUsComponent.prototype.ngOnInit = function () {
    };
    return ContactUsComponent;
}());
export { ContactUsComponent };
